import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import "../../../components/common/styles.scss";

interface Interval1DateRangeProps {
    onDateChange: (startDate: string, endDate: string) => void;
}

const Interval1DateRange: React.FC<Interval1DateRangeProps> = ({
    onDateChange,
}) => {
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
            endDate: new Date(),
            key: "selection",
        },
    ]);

    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [warningMessage, setWarningMessage] = useState<string | null>(null);
    const calendarRef = useRef<HTMLDivElement | null>(null);

    const handleSelect = useCallback(
        (ranges: any) => {
            const { startDate, endDate } = ranges.selection;
            const start = new Date(startDate);
            const end = new Date(endDate);
            const diffDays = Math.ceil(
                (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
            );

            if (diffDays > 6) {
                setWarningMessage("The maximum date range is 7 days.");
            } else {
                setWarningMessage(null);
                setDateRange([ranges.selection]);
                // Send start and end dates in YYYY-MM-DD format
                onDateChange(formatDate(start), formatDate(end));
            }
        },
        [onDateChange]
    );

    const toggleCalendar = () => {
        setIsCalendarOpen((prev) => !prev);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            calendarRef.current &&
            !calendarRef.current.contains(event.target as Node)
        ) {
            setIsCalendarOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    // Format date for onDateChange (YYYY-MM-DD)
    const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    // Format date for display (DD-MM-YYYY)
    const formatDisplayDate = (date: Date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        const { startDate, endDate } = dateRange[0];
        // Send start and end dates in YYYY-MM-DD format
        onDateChange(formatDate(startDate), formatDate(endDate));
    }, [dateRange, onDateChange]);

    return (
        <div style={{ position: "relative" }}>
            <div
                style={{
                    position: "absolute",
                    top: "-20px",
                    left: "5px",
                    background: "white",
                    padding: "0 4px",
                }}
            >
                <p style={{ margin: "0", fontSize: "12px" }}>Date Range</p>
            </div>
            <div
                onClick={toggleCalendar}
                style={{
                    border: "1px solid #ccc",
                    padding: "9px",
                    borderRadius: "4px",
                    cursor: "pointer",
                    backgroundColor: "white",
                }}
            >
                {`${formatDisplayDate(
                    dateRange[0].startDate
                )} - ${formatDisplayDate(dateRange[0].endDate)}`}
            </div>
            {isCalendarOpen && (
                <div
                    ref={calendarRef}
                    style={{
                        position: "absolute",
                        zIndex: 1000,
                        right: "0",
                        top: "100%",
                        background: "white",
                        border: "1px solid #ccc",
                        padding: "10px",
                    }}
                >
                    <DateRange
                        editableDateInputs={true}
                        onChange={handleSelect}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRange}
                        maxDate={new Date()}
                    />
                    {warningMessage && (
                        <div style={{ color: "red", marginTop: "10px" }}>
                            {warningMessage}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Interval1DateRange;
