import { Route, Routes, Outlet } from 'react-router-dom'
import Chat from './Chat'

const ChatRoutes = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <>
                    <Route path='chatrooms' element={<Chat />} />
                </>
            </Route>
        </Routes>
    )
}

export default ChatRoutes
