import React, { useState } from "react";
import { useSaveDespositionMutation } from "../../../../Services/general/General";
import { toast } from "react-toastify";
import { Loader } from "react-bootstrap-typeahead";
import { useCreateTeleDispositionMutation } from "../../../../Services/Telephony/Telephony";

interface IProps {
  closeModal: () => void;
  setData: React.Dispatch<React.SetStateAction<any[]>>;
  data: any[];
  SetLiftingAddDate: any;
  selectedTeam: any;
  refetch: any;
  process:any
}

const AddNewTeleDispositionModal: React.FC<IProps> = ({
  closeModal,
  setData,
  data,
  SetLiftingAddDate,
  selectedTeam,
  refetch,
  process
}) => {
  const [topic, setTopic] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [trigger, {isLoading}] = useCreateTeleDispositionMutation();

  const handleTopicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTopic(event.target.value);
  };

  const removeLevelProperty = (nodes: any[]) => {
    return nodes.map(({ level, sub_topics, ...rest }) => ({
      ...rest,
      sub_topics: sub_topics ? removeLevelProperty(sub_topics) : [],
    }));
  };

  const handleAdd = async () => {
    setIsSubmitting(true); // Disable the button and prevent multiple submissions
  
    // Create the payload dynamically
    const payload = {
      disposition: topic.trim(), // Use the entered topic as the disposition
      sub_disposition: [], // Initialize with an empty array
      team: selectedTeam, // Use the selectedTeam as the team
      type: process, // Hardcode or dynamically set the type as required
    };
  
    try {
      // Trigger the API with the constructed payload
      const response = await trigger(payload);
  
      if (response?.data) {
        toast.success("Added successfully");
        refetch();
      }
    } catch (error) {
      toast.error("An error occurred while adding Disposition");
    } finally {
      setIsSubmitting(false); // Re-enable the button
    }
  
    // Close the modal and reset the topic input
    closeModal();
    setTopic("");
  };
  return (
    <div>
      <div className="mt-4">
        <input
          type="text"
          className="form-control"
          placeholder="Topic"
          value={topic}
          onChange={handleTopicChange}
          disabled={isSubmitting} // Disable input while submitting
        />
      </div>
      <div className="d-flex justify-content-end mt-4">
        <button
          type="reset"
          className="btn btn-sm btn-white me-2"
          onClick={closeModal}
          disabled={isSubmitting} // Disable button while submitting
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={handleAdd}
          disabled={!topic.trim() || isSubmitting} // Disable button if topic is empty or submitting
        >
          {isLoading ? <>Adding... <Loader /></> : "Add"}
        </button>
      </div>
    </div>
  );
};

export default AddNewTeleDispositionModal;
