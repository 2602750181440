import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const customerApi = createApi({
  reducerPath: "customerApi",
  baseQuery: dynamicBaseQuery,

  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    tags: builder.query({
      query: () => ({
        url: "v1/customers/tags/",
        method: "GET",
      }),
    }),

    addTags: builder.mutation({
      query: (body) => ({
        url: "v1/customers/tags/",
        method: "POST",
        body,
      }),
    }),

    editTags: builder.mutation({
      query: ({ body, id }) => ({
        url: `v1/customers/tags/${id}/`,
        method: "PUT",
        body,
      }),
    }),

    deleteTags: builder.mutation({
      query: (id) => ({
        url: `v1/customers/tags/${id?.id}/`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useTagsQuery,
  useAddTagsMutation,
  useEditTagsMutation,
  useDeleteTagsMutation,
} = customerApi;
