import { Link, useLocation } from "react-router-dom";

let menuItem = [
  {
    icon: "bi bi-people-fill",
    title: "Users",
    to: "users",
  },
  {
    icon: "bi bi-bag-check-fill",
    title: "Customer",
    to: "customer",
  },
  {
    icon: "bi bi-sliders",
    title: "General",
    to: "general",
  },
  {
    icon: "bi bi-envelope",
    title: "Email",
    to: "email",
  },
  {
    icon: "bi bi-ticket-detailed",
    title: "Ticket",
    to: "ticket",
  },
  {
    icon: "bi bi-menu-up",
    title: "Leads",
    to: "campaign",
  },
  {
    icon: "bi bi-telephone",
    title: "Telephony",
    to: "telephony",
  },
  {
    icon: "bi bi-gear",
    title: "Configuration",
    to: "integration",
    role: "admin",
  },
];

const ListMenu = () => {
  const location = useLocation();
  const userRole = localStorage.getItem("user_role");

  // Filter menu items based on userRole
  const filteredMenu = menuItem.filter(
    (item) => !item?.role || item?.role === userRole
  );

  console.log(userRole, "userRole");

  return (
    <>
      <div className="card-body px-4">
        <div className="menu-item">
          <div className="menu-content pb-3">
            <span className="menu-section fw-bolder text-uppercase fs-3 ls-1">
              Settings Configuration
            </span>
          </div>
        </div>
        {filteredMenu.map((value, index) => (
          <div className="menu-item" key={index}>
            <div
              className={`menu-link without-sub ${
                location.pathname.includes(value.to) ? "text-primary" : ""
              }`}
            >
              <Link to={value.to} className="d-flex">
                <span className="menu-icon">
                  <i className={`${value.icon}`}></i>
                </span>
                <span
                  className={`menu-title fs-4 fw-bold text-hover-primary ${
                    location.pathname.includes(value.to)
                      ? "text-primary"
                      : "text-muted"
                  }`}
                >
                  {value.title}
                </span>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ListMenu;
