import React, { useState } from "react";
import ChannelHistory from "./ticketListInfo/ChannelHistory";
import LeadInformation from "./ticketListInfo/LeadInformation";
import Messages from "./ticketListInfo/Messages";
import Tasks from "./ticketListInfo/Tasks";
import Trail from "./ticketListInfo/Trail";
import Attachment from "./ticketListInfo/Attachments";
import { useAuditTrailQuery } from "../../Services/ticket/Audit-Trail";
import { useParams } from "react-router-dom";
import ChildTickets from "./ticketListInfo/ChildTickets";

interface IPROPS {
  tickets: any;
  refetch: any;
  taskData: any;
  refetchTask: any;
  isLoading: any;
  refetchAuditTrailForwardedData: any
  paneOpen: any
  setPaneOpen: any
  setConfirmationModalOpen:any
  confirmationModalOpen:any
  handleButtonClick:any
}

const TicketPaneOpen: React.FC<IPROPS> = ({
  tickets,
  refetch,
  taskData,
  refetchTask,
  isLoading,
  refetchAuditTrailForwardedData,
  paneOpen,
  setPaneOpen,
  setConfirmationModalOpen,
  confirmationModalOpen,
  handleButtonClick

}) => {
  const params = useParams();
  const uuid = params.uuid;

  const { data: auditTrailData, refetch: refetchAuditTrail } =
    useAuditTrailQuery(uuid);


  return (
    <>
      <div className="card">
        <div className="d-lg-flex row-col-5">
          <button
            className={`col btn btn-outline-primary ${paneOpen === "LeadInformation" ? "bg-primary" : ""
              } text-dark rounded-0 justify-content-center border`}
            onClick={() => setPaneOpen("LeadInformation")}
          >
            Ticket Information
          </button>
          <button
            className={`col btn btn-outline-primary ${paneOpen === "ChannelHistory" ? "bg-primary" : ""
              } text-dark rounded-0 justify-content-center border`}
            onClick={() => setPaneOpen("ChannelHistory")}
          >
            Channel History
          </button>
          <button
            className={`col btn btn-outline-primary ${paneOpen === "Messages" ? "bg-primary" : ""
              } text-dark rounded-0 justify-content-center border`}
            onClick={() => setPaneOpen("Messages")}
          >
            Messages
          </button>
          <button
            className={`col btn btn-outline-primary ${paneOpen === "Trail" ? "bg-primary" : ""
              } text-dark rounded-0 justify-content-center border`}
            onClick={() => setPaneOpen("Trail")}
          >
            Trail
          </button>
          <button
            className={`col btn btn-outline-primary ${paneOpen === "Tasks" ? "bg-primary" : ""
              } text-dark rounded-0 justify-content-center border`}
            onClick={() => setPaneOpen("Tasks")}
            disabled
          >
            Tasks
          </button>

          <button
            className={`col btn btn-outline-primary ${paneOpen === "Attachment" ? "bg-primary" : ""
              } text-dark rounded-0 justify-content-center border`}
            onClick={() => setPaneOpen("Attachment")}
          >
            Attachment
          </button>

          {/* {tickets?.is_child_ticket === false && (
            <button
              className={`col btn btn-outline-primary ${paneOpen === "childTicket" ? "bg-primary" : ""
                } text-dark rounded-0 justify-content-center border`}
              onClick={() => setPaneOpen("childTicket")}
            >
              Child Ticket
            </button>
          )} */}
        </div>
      </div>
      <div className="card mt-3">
        {paneOpen === "LeadInformation" ? (
          <LeadInformation
            ticket={tickets}
            refetch={refetch}
            uuid={uuid}
            refetch1={refetchAuditTrail}
            refetchAuditTrailForwardedData={refetchAuditTrailForwardedData}
            setConfirmationModalOpen={setConfirmationModalOpen}
            confirmationModalOpen={confirmationModalOpen}
            handleButtonClick={handleButtonClick}
          />
        ) : paneOpen === "ChannelHistory" ? (
          <ChannelHistory tickets={tickets} refetch={refetch} />
        ) : paneOpen === "Messages" ? (
          <Messages notesData={tickets} fetchingNotes={refetch} />
        ) : paneOpen === "Trail" ? (
          <Trail auditTrailData={auditTrailData} />
        ) : paneOpen === "Tasks" ? (
          <Tasks
            taskData={taskData}
            refetchTask={refetchTask}
            isLoading={isLoading}
          />
        ) : paneOpen === "Attachment" ? (
          <Attachment data={tickets} auditTrailData={auditTrailData} />
        ) : paneOpen === "childTicket" ? (
          <ChildTickets data={tickets} auditTrailData={auditTrailData} />
        ) : (
          <>SomethingElse</>
        )}
      </div>
    </>
  );
};

export default TicketPaneOpen;
