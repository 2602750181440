import React, { useEffect, useState } from "react";
import {
  useAllCampaignDataQuery,
  useCampaignWorkflowDetailsQuery,
  useSaveLeadUploadMutation,
} from "../../../../Services/campaign/CampaignMapping";
import { useDynamicFieldDefinitionsQuery } from "../../../../Services/settingsAPIs/CommonAPI";
import {
  InputLabel,
  TextField,
  Select,
  Checkbox,
  ListItemText,
  FormControl,
  MenuItem,
  Button,
  IconButton,
  Tooltip,
  OutlinedInput,
  Box,
  Chip,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InfoIcon from "@mui/icons-material/Info";
import { PhoneInput } from "react-international-phone";
import { capitalizeFirstLetter } from "../../../reusableComponent/CapitalLetter";
import { Loader } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";
import BulkLeadUploadCampaign from "./BulkLeadUploadCampaign";
import "../../../../pages/contact/styles.scss";
import { conforms, set } from "lodash";
import { useCampaignChangeStatusQuery } from "../../../../Services/campign/campignList";
import { v4 as uuidv4 } from 'uuid';

interface ErrorState {
  [key: string]: string;
}

const LeadUpload = () => {
  const [ticketFields, setTicketFields] = useState([]);

  const { data: allCampainData } = useAllCampaignDataQuery({});
  const [campaignData, setCampaignData] = useState<any>([]);
  const [workflowSelected, setWorkflowSelected] = useState<any>("");
  const [toggleSwitch, settoggleSwitch] = useState(false);
  const [errors, setErrors] = useState<ErrorState>({ phoneNumber: "" });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [files, setFiles] = useState([]);

  const [filteredDynamicData, setFilteredDynamicData] = useState<any>([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setemail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const userId = localStorage.getItem("User_Id");
  console.log(userId, "User_Id");
  const [workflowNames, setWorkflowNames] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState('');
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<number | null>(null);
  const [workflowStatuses, setWorkflowStatuses] = useState<any[]>([]);
  const [statusType, setStatusType] = useState<string>('');
  const [users, setUsers] = useState<string[]>([]);
  const [assigneeId, setAssigneeId] = useState('')
  const teamId = localStorage.getItem("teamId");
  const [statusOptions, setStatusOptions] = useState([])
  const [status, setStatus] = useState("");
  const [statusId, setStatusId] = useState("");
  const [selectedStatusId, setSelectedStatusId] = useState("")
  const {
    data: dynamicFieldsData,
    refetch,
    isLoading,
  } = useDynamicFieldDefinitionsQuery("campaign", {});

  const [triggerSaveLead] = useSaveLeadUploadMutation();

  const [dynamicData, setDynamicData] = useState<any[]>([]);
  const [dynamicFields, setDynamicFields] = useState<any>("");

  useEffect(() => {
    if (allCampainData) {
      console.log(allCampainData, "allCampainData");
      setCampaignData(allCampainData.results);
    }
  }, [allCampainData]);


  useEffect(() => {
    if (dynamicFieldsData) {
      setDynamicData(dynamicFieldsData);
    }
  }, [dynamicFieldsData]);

  useEffect(() => {
    const filteredDynamicData = dynamicData?.filter(
      (field: any) => field.campaign_name === workflowSelected
    );
    setFilteredDynamicData(filteredDynamicData);
  }, [dynamicData, workflowSelected]);

  useEffect(() => {
    if (workflowSelected) {
      const filteredFields = dynamicFieldsData.filter(
        (df: any) => df.campaign_name === workflowSelected
      );
      setTicketFields(filteredFields);
    }
  }, [workflowSelected, dynamicFieldsData]);

  const validateForm = () => {
    const newErrors: ErrorState = {};

    ticketFields.forEach((field: any) => {
      const fieldValue = dynamicFields[field.label];

      if (field.is_required && !fieldValue) {
        newErrors[field.label] = `${field.label} is required`;
      }

      if (field.is_required && field.field_type === "tel-phone" && fieldValue) {
        const isValidPhone =
          /^(?:\+?\d{1,3})?[ -]?\(?\d{1,4}?\)?[ -]?\d{1,4}[ -]?\d{1,4}(?: x\d{1,4})?$/.test(
            fieldValue
          );
        if (!isValidPhone) {
          newErrors[
            field.label
          ] = `${field.label} must be a valid phone number`;
        }
      }

      if (field?.field_type === "email" && field.is_required) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(dynamicFields[field.label])) {
          newErrors[field.label] = "Invalid email format";
        }
      }
    });

    // Check if the phoneNumber is required and empty
    if (!phoneNumber) {
      newErrors["phoneNumber"] = "Phone number is required";
    } else if (
      !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
        phoneNumber
      )
    ) {
      newErrors["phoneNumber"] = "Enter a 10 digit mobile number";
    }
    // if (!selectedWorkflow) { newErrors['workflow'] = 'Workflow is required'; }
    //  // Check if status is selected
    if (!status) {
      newErrors.status_type = "Status is required";
  }     setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };


  const handleFirstDropdownChange = (e: any) => {
    setWorkflowSelected(Number.parseInt(e.target.value));
    setemail(""); // Reset email field
    setPhoneNumber(""); // Reset phone number field
    setFirstName(""); // Reset first name field
    setLastName(""); // Reset last name field
    setDynamicFields({}); // Reset dynamic fields
    setErrors({}); // Reset errors
    setStatusType("");
    setStatus("")
    setSelectedStatusId("")
  };


  useEffect(() => {
    if (!workflowSelected) return;

    // Find the campaign that matches the selected workflow ID
    const matchingCampaign = campaignData.find(
      (el: any) => el.id === workflowSelected
    );


    // Extract usernames if a matching campaign is found
    const usernames = matchingCampaign?.users?.map((user: any) => user) || [];

    setUsers(usernames); // Update the state with the usernames
  }, [campaignData, workflowSelected]);



  const handletoggleSwitchChange = () => {
    settoggleSwitch(!toggleSwitch);
  };

  const handleInputChange = (label: any, value: any) => {
    setErrors({});
    setDynamicFields((prevState) => ({
      ...prevState,
      [label]: value,
    }));
  };

  const handleDeleteFile = (fileToDelete) => () => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };

  const getDataTimeFormatted = (date) => {
    if (date && date.length > 0) {
      const dateObj = new Date(date);

      // Manually format the date to ensure no timezone conversion issues
      const year = dateObj.getUTCFullYear();
      const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(dateObj.getUTCDate()).padStart(2, "0");
      const hours = String(dateObj.getUTCHours()).padStart(2, "0");
      const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
      return formattedDate;
    } else {
      return "";
    }
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<{ value: any }>,
    key: string
  ) => {
    setIsButtonDisabled(false);
    setErrors({});
    setDynamicFields((prevFields) => ({
      ...prevFields,
      [key]: event.target.value,
    }));
  };

  const handleFileChange = async (event) => {
    const newFiles = Array.from(event.target.files);
    const filePromises = newFiles.map((file: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const fileData = {
            file_name: file.name,
            file: e.target.result.split(",")[1], // Extracting Base64 part
          };
          resolve(fileData);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });

    try {
      const fileDataArray = await Promise.all(filePromises);
      setFiles((prevFiles) => {
        return [...prevFiles, ...fileDataArray];
      });
    } catch (error) {
      toast.error("An error occurred while uploading files. Please try again.");
    }
  };




  const HandleSave = async (e) => {
    e.preventDefault();

    // Check required fields and email validation
    const isFormValid = validateForm();
    let isEmailValid = true;
    let emailError = "";

    const emailFieldLabel = "email";
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailValue = dynamicFields[emailFieldLabel]?.trim();

    // Check if the email is valid
    if (emailValue && !emailPattern.test(emailValue)) {
      isEmailValid = false;
      emailError = "Invalid email address";
    }

    if (!isFormValid || !isEmailValid) {
      setIsButtonDisabled(true);

      if (!isFormValid) {
        toast.error("Please fill all required fields.");
      }
      if (!isEmailValid) {
        toast.error(emailError);
        setErrors((prevErrors) => ({
          ...prevErrors,
          [emailFieldLabel]: emailError,
        }));
      }

      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
      return; // Exit the function to prevent API call
    }

    setIsButtonDisabled(false);

    const dynamicFieldData = ticketFields.reduce((acc, field) => {
      const label = field.label;
      const value = dynamicFields[label] || "";

      // Check if the value is a number (or can be converted to a number)
      if (!isNaN(value) && value !== "") {
        acc[label] = parseFloat(value); // Convert the value to a number
      } else {
        acc[label] = value; // Keep it as a string if it's not a number
      }

      return acc;
    }, {});

    // let statusId = null;
    // let closedStatusId = null;
    // if (statusType === "wip") {
    //   statusId = workflowStatuses.find(status => status.status_type === "wip")?.status.id;
    // } else
    //   if (statusType === "closed") {
    //     closedStatusId = workflowStatuses.find(status => status.status_type === "closed")?.status.id;
    //   }
    const Obj = {
      account_name: accountName,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      dynamic_fields: dynamicFieldData,
      campaign: workflowSelected,
      status_type:status,
      attachments: files,
      assignee: Number(userId),
      status: status === "wip" ? statusId : null, // WIP
      closed_status: status === "closed" ? statusId : null, // closed

    };

    try {
      // Trigger the API call
      const response = await triggerSaveLead(Obj);

      if ("data" in response) {
        toast.success("Added Successfully");
        refetch();
        setAccountName("");
        setFirstName("");
        setLastName("");
        setemail("");
        setPhoneNumber("");
        setDynamicFields({});
        setFiles([]);
        setStatusType("");
        setStatus("")
        setSelectedStatusId("")

        // setWorkflowStatuses();
        // setSelectedWorkflow("");

      } else if ("error" in response) {
        console.log(response);

        const { data }: any = response.error;

        if (data && typeof data === "object") {
          Object.keys(data).forEach((key) => {
            const errorMessages = data[key]; // Retrieve the error messages for each key
            if (Array.isArray(errorMessages)) {
              errorMessages.forEach((message) => {
                // Display each error message in a toast
                toast.error(`${message}`);
              });
            }
          });
        } else {
          // Fallback for unexpected error structures
          toast.error("An unknown error occurred");
        }
      }
    } catch (err) {
      console.error("Error occurred while adding account:", err);
      toast.error("An unexpected error occurred");
    }
  };

  const {
    data: campaignWorkFlowDetails,
    refetch: refetchStatus,
  } = useCampaignChangeStatusQuery(
    workflowSelected
  );
 useEffect(() => {
         console.log("selectedStatusId", selectedStatusId)
 
     }, [selectedStatusId])
 
     const formatStatusData = (status) => {
         const generateUUID = () => uuidv4(); // Generate a UUID
 
         if (typeof status.status === "string") {
             // Open and Reopen statuses (no ID)
             return {
                 status_type: status.status_type,
                 status: status.status,
                 id: null, // No ID for statuses without ID
                 rid: generateUUID(), // Generate a UUID for rid
             };
         } else {
             // WIP and Closed statuses (with ID)
             return {
                 id: status?.status?.id,
                 status_type: status?.status_type,
                 status: status?.status?.status,
                 rid: generateUUID(), // Generate a UUID for rid
             };
         }
     };
     useEffect(() => {
         if (campaignWorkFlowDetails?.results[0]?.all_statuses) {
             const statusOptions1 = campaignWorkFlowDetails?.results[0]?.all_statuses?.map((status) =>
                 formatStatusData(status)
             );
             setStatusOptions(statusOptions1);
         }
 
 
     }, [campaignWorkFlowDetails]);
     console.log(campaignWorkFlowDetails, "campaignWorkFlowDetails...")
     const handleStatusChange = (value) => {
         console.log("selectedStatusId", value)
         if (value) {
             const checkStatus = statusOptions.find((status) => status.rid === value);
             if (checkStatus) {
                 setSelectedStatusId(value)
                 console.log("selectedStatusId", checkStatus)
                 setStatus(checkStatus.status_type)
                 setStatusId(checkStatus.id)
             }
         }
     }
     const activeStatuses = workflowStatuses.filter(status => status.status_type === "active");
     const wipStatuses = workflowStatuses.filter(status => status.status_type === "wip");
     const closedStatuses = workflowStatuses.filter(status => status.status_type === "closed");

  const handleAssigneeChange = (e) => {
    setAssigneeId(e.target.value)

  }
  return (
    <div className="custom-style">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="col" style={{ marginBottom: "20px" }}>
          <span className="input-group-text mt-2" id="basic-addon1">
            Lead uploads
          </span>
        </div>
        <div
          className="w-100 mw-300px"
          style={{ marginBottom: "20px", padding: "0 20px" }}
        >
          <label>Select campaign</label>
          <select className="form-select" onChange={handleFirstDropdownChange}>
            <option value="" disabled selected>
              Select a campaign
            </option>
            {campaignData &&
              campaignData.map((theworkflow: any) => (
                <option value={theworkflow.id}>{theworkflow.name}</option>
              ))}
          </select>
        </div>
        <div
          style={{
            display: "flex",
            width: "calc(100% - 30px)",
            alignItems: "center",
            marginBottom: "30px",
            padding: "0 20px",
          }}
        >
          <div
            className="col-md-9 d-flex align-items-center gap-1"
            style={{ width: "15%", gap: "20px" }}
          >
            <label className={`me-3 ${!toggleSwitch ? "text-primary" : ""}`}>
              Single
            </label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={toggleSwitch}
                onChange={handletoggleSwitchChange}
              />
            </div>
            <label className={`me-3 ${toggleSwitch ? "text-primary" : ""}`}>
              Bulk
            </label>
          </div>
        </div>

        {toggleSwitch && (
          <BulkLeadUploadCampaign
            filteredDynamicData={filteredDynamicData}
            refetch={refetch}
            workflowSelected={workflowSelected}
          />
        )}

        {!toggleSwitch && (
          <>
            <div className="row g-5 g-xl-8 mb-3">
              {workflowSelected && (
                <>
                  <div className="col-xl-3">
                    <InputLabel>Account Name</InputLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={accountName}
                      onChange={(e) => {
                        setIsButtonDisabled(false);
                        setAccountName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-xl-3">
                    <InputLabel>First Name</InputLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={firstName}
                      onChange={(e) => {
                        setIsButtonDisabled(false);
                        setFirstName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-xl-3">
                    <InputLabel>Last Name</InputLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={lastName}
                      onChange={(e) => {
                        setIsButtonDisabled(false);
                        setLastName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-xl-3">
                    <InputLabel>Email</InputLabel>
                    <TextField
                      type="email"
                      variant="outlined"
                      fullWidth
                      value={email}
                      onChange={(e) => {
                        setIsButtonDisabled(false);
                        setemail(e.target.value);
                      }}
                    />
                  </div>
                  {/* <div className="col-xl-3">
                    <InputLabel className="required"> Select Assignee</InputLabel>
                    <select className="form-select" onChange={handleAssigneeChange} style={{ padding: "15px" }}>
                      <option value="" disabled selected>
                        Select Assignee
                      </option>
                      {users &&
                        users?.map((assignee: any) => (
                          <option value={assignee.id}>{assignee.username}</option>
                        ))}
                    </select>

                  </div> */}
                  <div className="col-xl-3">
                    <InputLabel className="required">Phone Number</InputLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={phoneNumber}
                      onChange={(e) => {
                        setIsButtonDisabled(false);
                        setPhoneNumber(e.target.value);
                      }}
                    />
                    {errors.phoneNumber && (
                      <div className="mt-2" style={{ color: "red" }}>
                        {capitalizeFirstLetter(errors.phoneNumber)}
                      </div>
                    )}
                  </div>



                </>
              )}

              {ticketFields?.map((field: any) => (
                <React.Fragment key={field?.label}>
                  <div key={field.label} className="col-xl-3 mb-3">
                    <InputLabel htmlFor={`dynamic_fields.${field.label}`}>
                      {field.label}
                      {field.is_required && <span className="required"></span>}
                    </InputLabel>
                    {field.field_type === "text" && (
                      <TextField
                        //   disabled={isFieldDisabled(field?.is_editable)}
                        //   style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}

                        id={`dynamic_fields.${field.label}`}
                        variant="outlined"
                        fullWidth
                        // disabled={!field?.is_editable}
                        value={dynamicFields[field.label] || ""}
                        onChange={(e) =>
                          handleInputChange(field.label, e.target.value)
                        }
                        required={field.is_required}
                      />
                    )}
                    {field.field_type === "email" && (
                      <TextField
                        type="email"
                        id={`dynamic_fields.${field.label}`}
                        // disabled={!field?.is_editable}
                        variant="outlined"
                        fullWidth
                        value={dynamicFields[field?.label] || ""}
                        onChange={(e) =>
                          handleInputChange(field.label, e.target.value)
                        }
                        required={field.is_required}
                        className="form-control"
                        onBlur={(e) => {
                          if (field?.field_type === "email") {
                            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            const emailValue = e.target.value.trim();

                            if (!emailPattern.test(emailValue)) {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                [field?.label]: "Invalid email address",
                              }));
                            } else {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                [field?.label]: "",
                              }));
                            }
                          }
                        }}
                      />
                    )}
                    {field.field_type === "number" && (
                      <TextField
                        //   disabled={isFieldDisabled(field?.is_editable)}
                        //   style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}

                        type="number"
                        id={`dynamic_fields.${field.label}`}
                        // disabled={!field?.is_editable}
                        variant="outlined"
                        fullWidth
                        value={dynamicFields[field.label] || ""}
                        onChange={(e) =>
                          handleInputChange(field.label, e.target.value)
                        }
                        required={field.is_required}
                      />
                    )}
                    {field.field_type === "date" && (
                      <TextField
                        //   disabled={isFieldDisabled(field?.is_editable)}
                        //   style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}

                        type="date"
                        id={`dynamic_fields.${field.label}`}
                        // disabled={!field?.is_editable}
                        variant="outlined"
                        fullWidth
                        value={dynamicFields[field.label] || ""}
                        onChange={(e) =>
                          handleInputChange(field.label, e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                        required={field.is_required}
                      />
                    )}
                    {field.field_type === "time" && (
                      <TextField
                        //   disabled={isFieldDisabled(field?.is_editable)}
                        //   style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}

                        type="time"
                        id={`dynamic_fields.${field.label}`}
                        // disabled={!field?.is_editable}
                        variant="outlined"
                        fullWidth
                        value={dynamicFields[field.label] || ""}
                        onChange={(e) =>
                          handleInputChange(field.label, e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                        required={field.is_required}
                      />
                    )}
                    {field.field_type === "date-time" && (
                      <TextField
                        //   disabled={isFieldDisabled(field?.is_editable)}
                        //   style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}

                        type="datetime-local"
                        id={`dynamic_fields.${field.label}`}
                        // disabled={!field?.is_editable}
                        variant="outlined"
                        fullWidth
                        defaultValue={getDataTimeFormatted(
                          dynamicFields[field.label]
                        )}
                        onChange={(e) =>
                          handleInputChange(field.label, e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                        required={field.is_required}
                      />
                    )}
                    {field.field_type === "text-area" && (
                      <TextField
                        //   disabled={isFieldDisabled(field?.is_editable)}
                        //   style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}

                        id={`dynamic_fields.${field.label}`}
                        // disabled={!field?.is_editable}
                        variant="outlined"
                        fullWidth
                        multiline
                        value={dynamicFields[field.label] || ""}
                        onChange={(e) =>
                          handleInputChange(field.label, e.target.value)
                        }
                        required={field.is_required}
                      />
                    )}
                    {field.field_type === "drop-down" && (
                      <FormControl fullWidth>
                        <Select
                          //   disabled={isFieldDisabled(field?.is_editable)}
                          //   style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}

                          labelId={`select-label-${field.label}`}
                          // disabled={!field?.is_editable}
                          id={`select-${field.label}`}
                          value={dynamicFields[field.label] || ""}
                          onChange={(e) =>
                            handleInputChange(field.label, e.target.value)
                          }
                          required={field.is_required}
                        >
                          <MenuItem selected disabled>
                            Select
                          </MenuItem>
                          {field?.choices?.map((option: any) => (
                            <MenuItem key={option.id} value={option.choice}>
                              {option.choice}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {field.field_type === "check-box" && (
                      <FormControl fullWidth>
                        <Select
                          //   disabled={isFieldDisabled(field?.is_editable)}
                          //   style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}

                          labelId={`checkbox-label-${field.label}`}
                          // disabled={!field?.is_editable}

                          id={`checkbox-${field.label}`}
                          required={field.is_required}
                          multiple
                          value={dynamicFields[field.label] || []} // Ensure value is an array of IDs
                          onChange={(e: any) =>
                            handleCheckboxChange(e, field.label)
                          }
                          renderValue={(selected) => {
                            // Create an array of selected choice labels
                            const selectedLabels = (
                              field?.multiple_choices || []
                            )
                              .filter((choice) =>
                                selected.includes(String(choice.id))
                              )
                              .map((choice) => choice.choice);
                            return selectedLabels.join(", ");
                          }}
                        >
                          {field?.multiple_choices?.map((choice: any) => (
                            <MenuItem key={choice.id} value={String(choice.id)}>
                              <Checkbox
                                checked={dynamicFields[field.label]?.includes(
                                  String(choice.id)
                                )}
                              />
                              <ListItemText primary={choice.choice} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                    {field.field_type === "tel-phone" && (
                      <PhoneInput
                        //   disabled={isFieldDisabled(field?.is_editable)}
                        //   style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}

                        defaultCountry="IN"
                        value={dynamicFields[field.label] || ""}
                        // disabled={!field?.is_editable}

                        onChange={(value) =>
                          handleInputChange(field.label, value)
                        }
                        required={field.is_required}
                      />
                    )}
                    {errors[field.label] && (
                      <div className="mt-2" style={{ color: "red" }}>
                        {capitalizeFirstLetter(errors[field.label])}
                      </div>
                    )}
                  </div>
                </React.Fragment>
              ))}
            </div>
            {workflowSelected && (
              <div className="row g-5 g-xl-8 mb-4">
                <div className="col-xl-3">

                <label className="required" style={{ color: "rgba(0, 0, 0, 0.6)" }}>Status </label>
                            <select
                                className="form-select form-select-lg form-control-solid"
                                onChange={(e) => handleStatusChange(e.target.value)}
                                value={selectedStatusId || ""}
                            >
                                <option value="" style={{ color: "rgba(0, 0, 0, 0.6)" }} label="Select status" />
                                {statusOptions?.map((statusItem) => (
                                    <option
                                        key={statusItem.rid} 
                                        value={statusItem.rid} 
                                        label={statusItem.status} 
                                    >
                                        {statusItem.status}
                                    </option>
                                ))}
                            </select>
                            {errors.status_type && <div className="error-message" style={{ color: "red" }}>{errors.status_type}</div>}                          </div>
                        {/* )} */}
              </div>
            )}
            {!toggleSwitch && workflowSelected && (
              <div className="col-xl-6">
                <label>Attachments</label>
                <div className="mt-2">
                  <Button
                    // variant="contained"
                    component="label"
                    className="text-primary"
                    startIcon={<AttachFileIcon />}
                  >
                    Add Attachment
                    <input
                      type="file"
                      hidden
                      multiple
                      onChange={handleFileChange}
                    />
                  </Button>
                  <Tooltip
                    title="You can add attachments for the following file types: xls, pdf, csv, doc, jpg, png up to a maximum size of 15MB"
                    arrow
                  >
                    <IconButton
                      aria-label="info"
                      style={{
                        verticalAlign: "middle",
                        marginLeft: "11px",
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            )}

            {files.length > 0 && (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
                {files.map((file, index) => (
                  <Chip
                    key={index}
                    label={file.file_name}
                    onDelete={handleDeleteFile(file)}
                  />
                ))}
              </Box>
            )}
          </>
        )}

        {!toggleSwitch && (
          <div className="text-end mt-3 mb-3">
            <button
              type="submit"
              className="btn btn-sm btn-primary"
              onClick={HandleSave}
              disabled={isButtonDisabled || !workflowSelected}
            >
              {isLoading ? (
                <>
                  Submitting... <Loader />
                </>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeadUpload;