import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../../badRequestHandler/BadRequestHandler";

export const UsersByTeamId = createApi({
  reducerPath: "UsersByTeamId",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    UsersByTeamId: builder.query({
      query: (id) => ({
        url: `v1/users/users/?teams__id=${id}&`,
        method: "GET",
      }),
    }),
  }),
});

export const { useUsersByTeamIdQuery } = UsersByTeamId;
