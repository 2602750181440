import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const CreateTicket = createApi({
    reducerPath: "createTicket",
    baseQuery: dynamicBaseQuery,
    refetchOnMountOrArgChange: true,

    endpoints: (builder) => ({
        createTicket: builder.mutation({
            query: (body) => ({
                url: `v1/tickets/ticket/`,
                method: "POST",
                body,
            }),
        }),

        disposition: builder.query({
            query: (getTeamId) => ({
                url: `/v1/common/disposition/?team_id=${getTeamId}&`,
                method: "GET",
            }),
        }),

        getDispositionStatus: builder.query({
            query: (lastSelectedId) => ({
                url: `v1/common/disposition/${lastSelectedId}/lookup_by_disposition/?`,
                method: "GET",
            }),
        }),

        ticketDynamicFields: builder.query({
            query: () => ({
                url: "v1/common/dynamic-field-definitions/?model_name=ticket_name&",
                method: "GET",
            }),
        }),

        createScheduler: builder.mutation({
            query: (body) => ({
                url: `v1/tickets/auto-ticket-scheduler/`,
                method: "POST",
                body,
            }),
        }),
        updateScheduler: builder.mutation({
            query: ({ body, id }) => ({
                url: `v1/tickets/auto-ticket-scheduler/${id}/`,
                method: "PATCH",
                body,
            }),
        }),

        updateDisposition: builder.mutation({
          query: ({ body, id }) => ({
            url: `/v1/tickets/ticket/${id}/`,
            method: "PATCH",
            body,
          }),
        }),


        ticketDetailsAfterSelectingDisposition: builder.query({
            query: (id) => ({
                url: `v1/tickets/ticket/${id}/`,
                method: "GET",
            }),
        }),


        updateTicketWorkflow: builder.mutation({
            query: ({ id, body }) => ({
              url: `/v1/tickets/ticket/${id}/`,
              method: "PATCH",
              body,
            }),
          }),


    }),
});

export const {
    useUpdateTicketWorkflowMutation,
    useTicketDetailsAfterSelectingDispositionQuery,

    useUpdateDispositionMutation,
    useCreateTicketMutation,
    useDispositionQuery,
    useGetDispositionStatusQuery,
    useTicketDynamicFieldsQuery,
    useCreateSchedulerMutation,
    useUpdateSchedulerMutation,
} = CreateTicket;
