import React, {useState, useEffect} from 'react'
import { useAddQueueDataMutation } from '../../../../Services/Telephony/Telephony'
import { toast } from 'react-toastify'

interface IPROPS {
    closeModal:any
    refetch:any
}
const AddQueueCreation: React.FC<IPROPS> = ({closeModal, refetch}) => {
    const [queue, setQueue] = useState('')
    const [penalty, setPenalty] = useState('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [addExtensionTrigger] = useAddQueueDataMutation()

    const handleSubmit = async(e) => {
        e.preventDefault()

        const addExtensionObj = {
            name: queue,
            penalty: penalty
        }

        const response  = await addExtensionTrigger(addExtensionObj)

        try {

            if (response?.data) {
                toast.success("Extension added successfully")
                closeModal()
                refetch()

                setIsButtonDisabled(true);

                setTimeout(() => {
                  setIsButtonDisabled(false);
                }, 3000);

            }
            else if ("data" in response.error) {
                const errorData = response?.error?.data as { name?: string[]; penalty?: string[] }; // Use optional chaining for safety
                const errorMessage =
                  errorData?.name?.[0] ? "Name field may not be blank" : 
                  errorData?.penalty?.[0] ? "Penalty field may not be blank" :
                  "An unknown error occurred"; 
                toast.error(errorMessage);
                setIsButtonDisabled(true);
              
                setTimeout(() => {
                  setIsButtonDisabled(false);
                }, 3000);
              }
            
        } catch (error) {
            setIsButtonDisabled(true);

            setTimeout(() => {
              setIsButtonDisabled(false);
            }, 3000);

            
        }

    }
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <div>
            <label>Queue</label>
            <input
                className="form-control"
                value={queue}
                onChange={(e) => setQueue(e.target.value)}
            />
        </div>
        <div>
            <label>Penalty</label>
            <input
                className="form-control"
                value={penalty}
                onChange={(e) => setPenalty(e.target.value)}
            />
        </div>
        <div
            style={{
                width: "100%",
                display: "flex",
                gap: "10px",
                justifyContent: "end",
            }}
        >
            <button 
            type="reset"
                    className="btn btn-sm btn-white me-2"
            onClick={() => closeModal()}>Cancel</button>
            <button className="btn btn-primary" onClick={handleSubmit} disabled={isButtonDisabled}>
                Save
            </button>
        </div>
    </div>  )
}

export default AddQueueCreation