import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

interface IPROPS {
    overview: number[]; // Assuming overview is an array of numbers
}

interface ApexChartsValue {
    options: {
        labels: string[];
        dataLabels: {
            enabled: boolean;
            formatter?: (val: number, opts?: any) => string;
        };
        tooltip: {
            enabled: boolean;
        };
        title: {
            margin: number;
            offsetX: number;
            offsetY: number;
            floating: boolean;
        };
        colors: string[];
        legend: {
            show: boolean;
            position: "bottom" | "top" | "right" | "left";
            fontSize: string;
            horizontalAlign: "left" | "center" | "right";
            formatter?: (val: string, opts?: any) => string;
        };
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: boolean;
                        total: {
                            show: boolean;
                            label: string;
                            formatter: (w: any) => string;
                        };
                    };
                };
            };
        };
        responsive: {
            breakpoint: number;
            options: {
                chart: {
                    width: number;
                };
                legend: {
                    position: "bottom" | "top" | "right" | "left";
                };
            };
        }[];
    };
    series: number[];
}

const EmailPieChart: React.FC<IPROPS> = ({ overview }) => {
    const initialChartData: ApexChartsValue = {
        options: {
            labels: ["Active", "Closed", "Wip", "Reopen"],
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return `${Math.round(val)}%`;
                },
            },
            tooltip: {
                enabled: true,
            },
            title: {
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
            },
            colors: ["#00b300", "#F6A40A", "#E51803", "#E51803"],
            legend: {
                show: true,
                position: "right",
                fontSize: "16px",
                horizontalAlign: "center",
                formatter: function (seriesName, opts) {
                    const value = opts?.w?.globals?.series[opts.seriesIndex];
                    return `${seriesName}: ${value !== undefined ? value : 0}`;
                },
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                label: "Total",
                                formatter: function (w) {
                                    const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                                    if (total === 0) {
                                        return "0%";
                                    }
                                    const totalPercentage = (
                                        (total / w.globals.series.reduce((a, b) => a + b, 0)) *
                                        100
                                    ).toFixed(0);
                                    return `${totalPercentage}%`;
                                },
                            },
                        },
                    },
                },
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: "bottom",
                        },
                    },
                },
            ],
        },
        series: [0, 0, 0, 0],
    };
    const [chartData, setChartData] = useState<ApexChartsValue>(initialChartData);

    useEffect(() => {
        if (overview) {
            const isAllZero = overview.every((item) => item === 0);

            setChartData((prevData) => ({
                ...prevData,
                series: overview.length > 0 ? overview : [0, 0, 0, 0],
                options: {
                    ...prevData.options,
                    colors: isAllZero
                        ? ["#d3d3d3", "#d3d3d3", "#d3d3d3", "#d3d3d3"] // Grey colors when all values are zero
                        : ["#00b300", "#F6A40A", "#E51803", "#E51803"], // Default colors
                },
            }));
        }
    }, [overview]);

    return (
        <div className="card card-flush h-lg-100">
            <div className="card-header pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="fs-2 fw-bold text-gray-800 me-2 lh-1 ls-n2">Ticket by email</span>
                </h3>
            </div>
            <div className="row align-items-center mx-0 w-100">
                <div>
                    <div id="chart">
                        <Chart
                            options={chartData.options}
                            series={chartData.series}
                            type="donut"
                            height={250}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailPieChart;
