import React, { useState } from "react";
import { TextField, Checkbox, FormControlLabel, MenuItem, Select, Box, Chip } from "@mui/material";
import { CloseButton, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // React Quill CSS
import { toast } from "react-toastify";
import { useTaskCollectionQuery, useUpdateTaskMutation } from "../../../Services/campign/campignList";

interface IProps {
    closeModal: () => void;
    selectedRow: any;
    refetch: any;
}

const ViewTaskModal: React.FC<IProps> = ({ closeModal, selectedRow, refetch }) => {
    const [showReminderDropdown, setShowReminderDropdown] = useState(false);
    const [status, setStatus] = useState(selectedRow?.status); // To handle status dropdown value
    const [editorValue, setEditorValue] = useState(selectedRow?.event_description); // To handle React Quill editor value
    const [title, setTitle] = useState<any>(selectedRow?.title)
    const [date, setDate] = useState<any>(selectedRow?.event_date)
    const [time, setTime] = useState<any>(selectedRow?.start_time)
    const [remainder, setRemainder] = useState<any>(selectedRow?.reminder_time)
    const [files, setFiles] = useState([]);
    const collection = selectedRow?.lead
    const { data: taskCollection, isLoading: taskCollectionLoading, error: taskCollectionError } = useTaskCollectionQuery({ collection });
    const [updateTask] = useUpdateTaskMutation();

    console.log(selectedRow, "selectedRow")

    const options = [
        { value: '00:05:00', text: '5 mins Prior to Task' },
        { value: '00:15:00', text: '15 mins Prior to Task' },
        { value: '00:30:00', text: '30 mins Prior to Task' },
        { value: '01:00:00', text: '60 mins Prior to Task' },
    ];

    const statusOptions = [
        { value: 'active', text: 'Active' },
        { value: 'rescheduled', text: 'Re-Scheduled' },
        { value: 'cancelled', text: 'Cancelled' },
        { value: 'done', text: 'Done' },
    ];


    const handleDeleteFile = (fileToDelete) => () => {
        setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
    };
    const handleFileChange = async (event) => {
        const newFiles = Array.from(event.target.files);
        const filePromises = newFiles.map((file: any) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    const fileData = {
                        file_name: file.name,
                        file: e.target.result.split(",")[1], // Extracting Base64 part
                    };
                    resolve(fileData);
                };
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        });

        try {
            const fileDataArray = await Promise.all(filePromises);
            setFiles((prevFiles) => [...prevFiles, ...fileDataArray]);
        } catch (error) {
            console.error("Error reading files:", error);
            toast.error("An error occurred while uploading files. Please try again.");
        }
    };



    const taskUpdateHandler = async () => {
        const id = selectedRow?.id
        try {
            const payload =
            {
                "title": title,
                "event_date": date,
                "start_time": time,
                "reminder_time": remainder,
                "status": status,
                "event_description": editorValue,
                "lead": collection,
                "created_by": taskCollection?.created_by.id,
                "attachments": files
            }
            await updateTask({ body: payload, id });

            toast.success(`Task updated successfully`);
            refetch();
            closeModal();

        } catch (err) {
            toast.error("Failed to assign. Please try again.");
            console.error("Error assigning user:", err);
        } finally {

        }
    };
    return (
        <>
            <div className="p-4">
                {/* Modal Header */}
                <div className="d-flex justify-content-end">
                    <div>
                        <CloseButton onClick={closeModal} />
                    </div>
                    {/* Update Button */}

                </div>
                <div className="text-center mb-4">
                    <h5 className="fs-4 fw-bold text-dark">View Task</h5>
                </div>

                {/* Task Details */}
                <div className="">
                    <table className="table table-striped table-bordered">
                        <tbody>
                            <tr>
                                <td className="fw-bold">Account name</td>
                                <th colSpan={3}>
                                    {taskCollection?.account_name}
                                    <a
                                        href="/campaign/campaign-details/Collections170263189826"
                                        className="float-end text-decoration-none text-primary"
                                    >
                                        View lead details
                                    </a>
                                </th>
                            </tr>
                            <tr>
                                <td className="fw-bold" style={{ width: "30%" }}>
                                    Full name
                                </td>
                                <th style={{ width: "70%" }}>{taskCollection?.first_name} {taskCollection?.last_name}</th>

                            </tr>

                            <tr>
                                <td className="fw-bold" style={{ width: "30%" }}>
                                    Contact number
                                </td>
                                <th style={{ width: "70%" }}>{taskCollection?.phone_number}</th>
                            </tr>
                            <tr>
                                <td className="fw-bold">Email</td>
                                <th colSpan={3}>{taskCollection?.email}</th>
                            </tr>
                        </tbody>
                    </table>

                    {/* Subject Field */}
                    <div className="w-100 mb-4">
                        <label className="required">Title</label>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={title}
                            className="form-control form-control-lg form-control-solid"
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>

                    {/* Date and Time Fields */}
                    <div className="d-flex gap-3 mb-4">
                        <div className="flex-fill">
                            <label className="required">Date</label>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="date"
                                autoComplete="off"
                                InputLabelProps={{ shrink: true }}
                                className="form-control form-control-lg form-control-solid"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                            />
                        </div>

                        <div className="flex-fill">
                            <label className="required">Time</label>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="time"
                                autoComplete="off"
                                InputLabelProps={{ shrink: true }}
                                className="form-control form-control-lg form-control-solid"
                                value={time}
                                onChange={(e) => setTime(e.target.value)}
                            />
                        </div>
                    </div>

                    {/* Reminder Checkbox */}
                    <div className="w-100 mb-4">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={showReminderDropdown}
                                    onChange={(e) => setShowReminderDropdown(e.target.checked)} // Toggle dropdown visibility
                                />
                            }
                            label="Reminder At"
                            className="d-flex align-items-center"
                        />
                    </div>

                    {/* Reminder Time Dropdown */}
                    {showReminderDropdown && (
                        <div className="w-100 mb-4">
                            <label className="required">Reminder time</label>
                            <Select
                                fullWidth
                                value={remainder}
                                onChange={(e) => setRemainder(e.target.value)}
                                variant="outlined"
                                displayEmpty
                                className="form-control form-control-lg form-control-solid"
                                sx={{
                                    height: 56,
                                    "& .MuiSelect-select": {
                                        padding: "16.5px 14px",
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select reminder minute
                                </MenuItem>
                                {options.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.text}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    )}

                    {/* Status Dropdown */}
                    <div className="w-100 mb-4">
                        <label className="required">Status</label>
                        <Select
                            fullWidth
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            variant="outlined"
                            displayEmpty
                            className="form-control form-control-lg form-control-solid"
                            sx={{
                                height: 56,
                                "& .MuiSelect-select": {
                                    padding: "16.5px 14px",
                                },
                            }}
                        >
                            <MenuItem value="" disabled>
                                Select task status
                            </MenuItem>
                            {statusOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.text}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>

                    {/* React Quill Editor */}
                    <div className="w-100 mb-4">
                        <label className="required">Task description</label>
                        <ReactQuill
                            value={editorValue}
                            onChange={setEditorValue}
                            placeholder="Write task details here..."
                            theme="snow"
                            className=""
                            style={{ minHeight: "200px", height: "200px" }} // Increase the height of the editor
                        />
                    </div>

                    {/* Attachment Icon */}
                    {/* <div className="w-100 d-flex justify-content-end">
                    <IconButton color="primary" aria-label="attach file">
                        <AttachFileIcon onChange={handleFileChange} />
                    </IconButton>
                </div> */}
                </div>

            </div>


            <div className="d-flex justify-content-between align-items-center mt-5">

                <div className="d-flex">
                    <label
                        htmlFor="attachFileInput"
                        className={`btn btn-active-light-primary btn-icon `}
                        title="You can add attachments for the following file types: xls,pdf,csv,doc,jpg,png up to a maximum size of 15MB"
                    >
                        <i className="bi bi-paperclip align-self-center text-dark fs-2 mx-2 "></i>
                        <input
                            type="file"
                            id="attachFileInput"
                            className="d-none"
                            multiple
                            onChange={handleFileChange}
                        />
                    </label>
                </div>
            </div>
            {files.length > 0 && (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
                    {files.map((file, index) => (
                        <Chip
                            key={index}
                            label={file.file_name}
                            onDelete={handleDeleteFile(file)}
                        />
                    ))}
                </Box>
            )}

            <div className="text-end mt-3 mb-4">
                <button type="submit" className="btn btn-lg btn-primary" onClick={taskUpdateHandler}>Update</button>
            </div>
        </>
    );
};

export default ViewTaskModal;
