import React, { useEffect, useState } from "react";
import { CloseButton } from "react-bootstrap";
import { useCreateTaskMutation } from "../../../Services/ticket/CreateTask";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTeamsQuery } from "../../../Services/settingsAPIs/CommonAPI";
import { Autocomplete, TextField, Chip } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useGetSearchByEmailQuery } from "../../../Services/email/EmailTemplate";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import clsx from "clsx";

interface IProps {
    closeModal: any;
    ticket: any;
    createTask: any;
    refetchTask: any;
}

const CreateTaskModal: React.FC<IProps> = ({
    closeModal,
    ticket,
    createTask,
    refetchTask,
}) => {
    const { data: teamsData } = useTeamsQuery({});
    const [selectedTeam, setSelectedTeam] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const params = useParams();
    const uuid = params.uuid;

    const [emails, setEmails] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState("");
    const [queryParam, setQueryParam] = useState();
    const [emailOptions, setEmailOptions] = useState<any[]>([]);

    const { data, error } = useGetSearchByEmailQuery(queryParam, {
        skip: !queryParam,
    });

    useEffect(() => {
        if (teamsData) {
            setSelectedTeam(teamsData[0]?.id || ""); // Set default selected team
        }
    }, [teamsData]);

    const extractEmailOptions = (data: any) => {
        if (!data) return [];
        return data.map((item) => ({ label: item.email }));
    };

    useEffect(() => {
        if (data) {
            setEmailOptions(extractEmailOptions(data));
        }
    }, [data]);

    const handleInputChange = (event, newInputValue, setInputValue) => {
        if (event?.type === "click" || event?.type === "keydown") {
            setInputValue("");
            const emailOptionExists = emailOptions.some(
                (option) => option.label === newInputValue
            );
        }
        if (event?.type === "change") {
            const queryParams: any = {
                email: newInputValue,
            };

            setInputValue(newInputValue);
            setQueryParam(queryParams);
        }
    };

    const handleAddEmail = (email, setEmails, emails) => {
        if (email && isValidEmail(email)) {
            if (!emails.includes(email.trim())) {
                setEmails([...emails, email.trim()]);
                setInputValue("");
            }
        }
    };

    const handleDeleteEmail = (emailToDelete, setEmails, emails) => {
        setEmails(emails.filter((email) => email !== emailToDelete));
    };

    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const validationSchema = Yup.object({
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
        date: Yup.date().required("Date is required").nullable(),
        time: Yup.string().required("Time is required"),
    });

    return (
        <Formik
            initialValues={{
                title: "",
                description: "",
                date: "",
                time: "",
                guests: [],
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
                setIsLoading(true);
                try {
                    const response = await createTask({
                        title: values.title,
                        event_date: values.date,
                        start_time: values.time,
                        event_description: values.description,
                        ticket: uuid,
                        created_by: ticket.created_by.id,
                        team: selectedTeam,
                        guests: emails,
                    });

                    if (response.data) {
                        toast.success("Task created successfully!");
                        closeModal();
                        refetchTask();
                    }
                } catch (error) {
                    toast.error("Failed to assign. Please try again.");
                    console.error(error);
                } finally {
                    setIsLoading(false);
                    setSubmitting(false);
                }
            }}
        >
            {({ setFieldValue, values, errors, touched, isSubmitting }) => (
                <Form>
                    <div className="text-end">
                        <CloseButton onClick={closeModal} />
                    </div>
                    <div className="text-center mb-4">
                        <label className="form-label fs-4 fw-bolder text-dark card-title">
                            Create Task
                        </label>
                    </div>

                    <div className="w-100 mb-4">
                        <label className="required">Subject</label>
                        <Field
                            name="title"
                            type="text"
                            className={clsx(
                                "form-control form-control-lg form-control-solid",
                                {
                                    "is-invalid": touched.title && errors.title,
                                    "is-valid": touched.title && !errors.title,
                                }
                            )}
                            autoComplete="off"
                        />
                        <ErrorMessage
                            name="title"
                            component="div"
                            className="fv-plugins-message-container text-danger"
                        />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <label>Task Owner</label>
                        <input className="form-control" />
                    </div>

                    <div className="w-100 mb-4">
                        <label>Add Description</label>
                        <Field
                            as="textarea"
                            name="description"
                            rows={3}
                            className="form-control form-control-lg form-control-solid"
                        />
                        <ErrorMessage
                            name="description"
                            component="div"
                            className="text-danger"
                        />
                    </div>

                    <div className="w-100 mb-4">
                        <label>Due Date</label>
                        <Field
                            name="date"
                            type="date"
                            className="form-control form-control-lg form-control-solid"
                        />
                        <ErrorMessage
                            name="date"
                            component="div"
                            className="text-danger"
                        />
                    </div>

                    <div className="w-100 mb-4">
                        <label>Time</label>
                        <Field
                            name="time"
                            type="time"
                            className="form-control form-control-lg form-control-solid"
                        />
                        <ErrorMessage
                            name="time"
                            component="div"
                            className="text-danger"
                        />
                    </div>

                    <div className="w-100 mb-4">
                        <label>Guests</label>
                        <div style={{ width: "100%" }}>
                            <Autocomplete
                                freeSolo
                                options={emailOptions}
                                getOptionLabel={(option: any) => option.label}
                                onChange={(event, value) => {
                                    if (value && isValidEmail(value.label)) {
                                        handleAddEmail(
                                            value.label,
                                            setEmails,
                                            emails
                                        );
                                        setInputValue(""); // Clear the input
                                    }
                                }}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) =>
                                    handleInputChange(
                                        event,
                                        newInputValue,
                                        setInputValue
                                    )
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        sx={{ border: "none" }} // Remove border
                                        fullWidth
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleAddEmail(
                                                    inputValue,
                                                    setEmails,
                                                    emails
                                                );
                                                setInputValue(""); // Clear the input
                                                e.preventDefault(); // Prevent form submission or default behavior
                                            }
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    alignItems="center"
                                                    flexWrap="wrap" // Add this line to allow wrapping
                                                >
                                                    {emails.map(
                                                        (email, index) => (
                                                            <Chip
                                                                key={index}
                                                                label={email}
                                                                onDelete={() =>
                                                                    handleDeleteEmail(
                                                                        email,
                                                                        setEmails,
                                                                        emails
                                                                    )
                                                                }
                                                                className="mat-chip-custom"
                                                                style={{
                                                                    marginLeft: 5,
                                                                }}
                                                            />
                                                        )
                                                    )}
                                                </Stack>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <label>Status</label>
                        <select className="form-control">
                            <option></option>
                        </select>
                    </div>
                    <div>
                        <label>Remind At</label>
                        <select className="form-control">
                            <option>5 minutes</option>
                            <option>15 minutes</option>
                        </select>
                    </div>

                    <div className="text-end mt-3 mb-4">
                        <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            disabled={isSubmitting || isLoading}
                        >
                            <span className="indicator-label">Save</span>
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default CreateTaskModal;
