import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Button,
  IconButton,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useGetStatusesQuery } from "../../../Services/settingsAPIs/CommonAPI";
import { toast } from "react-toastify";
import {
  useReplyMailMutation,
  useTicketDynamicFieldsQuery,
} from "../../../Services/email/EmailInbox";
import { useCreateTicketByMailReplyMutation } from "../../../Services/email/EmailInbox";
import LoadingSpinner from "../../LoadingSpinner";
import { useGetDispositionStatusQuery } from "../../../Services/ticket/CreateTicket";

interface IProps {
  closeModal: () => void;
  lastSelectedDisposition: any;
  isOpen: any;
  emails: any;
  cc: any;
  bcc: any;
  editorHtml: any;
  otherMailDetails: any;
  refetchSpecificThreads: any;
  setShowReplyCard: any;
  files: any;
  templateAttachment: any;
}

const TicketsDetailsModal: React.FC<IProps> = ({
  closeModal,
  lastSelectedDisposition,
  isOpen,
  emails,
  cc,
  bcc,
  editorHtml,
  otherMailDetails,
  refetchSpecificThreads,
  setShowReplyCard,
  files,
  templateAttachment,
}) => {
  const [dynamicFields, setDynamicFields] = useState<any[]>([]);
  const [formValues, setFormValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const { data, error } = useGetStatusesQuery(lastSelectedDisposition.id);
  const { data: dynamicFieldData } = useTicketDynamicFieldsQuery({});

  const [loading, setIsLoading] = useState<any>(false);
  const [status, setStatus] = React.useState("");
  const [allStatus, setAllStatus] = useState<any>([]);
  const [statusSelected, setStatusSelected] = useState<any>("");
  const [createTicket] = useCreateTicketByMailReplyMutation();
  const [replyMail] = useReplyMailMutation();
  const selectedTicketId = otherMailDetails?.action?.ticket?.id;

  const handleStatusChange = (event) => {
    setStatusSelected(event.target.value);
  };
  const handleInputChange = (event, field) => {
    setFormValues({
      ...formValues,
      [field]: event.target.value,
    });
  };

  const handleCheckboxChange = (event, field) => {
    setFormValues({
      ...formValues,
      [field]: event.target.checked,
    });
  };

  useEffect(() => {
    if (dynamicFieldData) {
      const fields = dynamicFieldData?.filter(
        (field: any) =>
          field.ticket_name === lastSelectedDisposition.ticket_name
      );
      setDynamicFields(fields);
    }
  }, [dynamicFieldData]);

  useEffect(() => {
    if (data && data.all_statuses) {
      const filteredStatuses = data.all_statuses.filter((status) => {
        if (status.status_type !== "reopen") {
          return true;
        }
        return false;
      });

      const statusOptions = filteredStatuses.map((status) => {
        if (typeof status.status === "string") {
          // Open and Reopen statuses (no ID)
          return {
            status_type: status.status_type,
            status: status.status,
            id: null, // No ID for statuses without ID
          };
        } else {
          // WIP and Closed statuses (with ID)
          return {
            id: status.status.id,
            status_type: status.status_type,
            status: status.status.status,
          };
        }
      });

      setAllStatus(statusOptions);
    }
  }, [data]);

  if (error) {
    toast.error("No ticket name for the selected disposition");
    closeModal();
  }

  const validateForm = () => {
    let isValid = true;
    const newErrors: any = {};

    dynamicFields.forEach((field) => {
      if (field?.validators?.includes("required")) {
        if (
          !formValues[field?.identifier] ||
          (Array.isArray(formValues[field?.identifier]) &&
            formValues[field?.identifier].length === 0)
        ) {
          newErrors[field?.identifier] = `${field?.label} is required`;
          isValid = false;
        }
      }
    });

    if (!status) {
      newErrors["status"] = "Status is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSave = () => {
    setIsLoading(true);
    if (validateForm()) {
      const payload = {
        status_type: status,
        dynamic_fields: {
          ...formValues,
        },
      };
      const selectedEmailId = otherMailDetails?.id;
      let queryParam = {
        to: emails,
        html_content: editorHtml,
        bcc: bcc,
        cc: cc,
        attachments: files,
        reply_and_close: true,
        template_attachments: Array.isArray(templateAttachment)
          ? templateAttachment.map((attachment) => attachment.id)
          : [],
        is_replied: true,
      };

      // Call the mutation with the payload
      createTicket({ body: payload, selectedTicketId })
        .then((res) => {
          if (res) {
            return replyMail({ body: queryParam, selectedEmailId });
          }
          throw new Error("Failed to create ticket");
        })
        .then((resReply: any) => {
          // Check for specific response content or status
          if (resReply && resReply.data) {
            toast.success("Email reply successfully");
            refetchSpecificThreads();
            closeModal();
            setShowReplyCard(false);
          } else {
            throw new Error(
              resReply?.error?.data?.detail?.join(", ") ||
                "Failed to process email reply"
            );
          }
        })
        .catch((err) => {
          const errorMessage =
            err?.response?.data?.detail?.join(", ") ||
            err.message ||
            "An error occurred";
          toast.error(errorMessage);
          closeModal();
        })
        .finally(() => {
          setIsLoading(false);
          closeModal();
        });
    }
  };

  const truncateDescription = (description, maxLength) => {
    if (description?.length > maxLength) {
      return description?.slice(0, maxLength) + "...";
    }
    return description;
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 120,
      },
    },
  };

  const renderField = (field) => {
    const isRequired = field?.validators?.includes("required");
    const label = isRequired ? `${field?.label} *` : field?.label;
    const error = errors[field?.identifier];
    const value = formValues[field?.identifier] || "";

    switch (field?.field_type) {
      case "text":
      case "email":
      case "number":
      case "tel-phone":
        return (
          <TextField
            id={field?.identifier}
            label={label} // Use the dynamic label provided
            variant="outlined"
            type={field?.field_type === "tel-phone" ? "tel" : field?.field_type}
            required={isRequired}
            value={value}
            onChange={(event) => handleInputChange(event, field?.identifier)}
            helperText={error}
            error={!!error}
          />
        );

      case "text-area":
        return (
          <FormControl fullWidth error={!!error} key={field?.identifier}>
            <InputLabel htmlFor={field?.identifier}>{label}</InputLabel>
            <TextField
              id={field?.identifier}
              multiline
              rows={4}
              required={isRequired}
              value={value}
              onChange={(event) => handleInputChange(event, field?.identifier)}
              helperText={error}
              error={!!error}
            />
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        );

      case "drop-down":
        return (
          <FormControl
            fullWidth
            error={!!error}
            key={field?.identifier}
            className="mt-4"
          >
            <InputLabel htmlFor={field?.identifier}>{label}</InputLabel>
            <Select
              id={field?.identifier}
              value={value}
              onChange={(event) => handleInputChange(event, field?.identifier)}
              required={isRequired}
              label={label}
            >
              <MenuItem disabled value="">
                Select
              </MenuItem>
              {field?.choices?.map((choice) => (
                <MenuItem key={choice.id} value={choice.choice}>
                  {choice.choice}
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        );

      case "check-box":
        return (
          <FormControl
            component="fieldset"
            fullWidth
            error={!!error}
            key={field?.identifier}
          >
            <FormLabel component="legend">{label}</FormLabel>
            {field?.multiple_choices?.map((choice) => (
              <FormControlLabel
                key={choice.id}
                control={
                  <Checkbox
                    checked={
                      !!formValues[`${field?.identifier}_${choice?.choice}`]
                    }
                    onChange={(event) =>
                      handleCheckboxChange(
                        event,
                        `${field?.identifier}_${choice?.choice}`
                      )
                    }
                  />
                }
                label={choice.choice}
              />
            ))}
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        );

      case "date":
      case "date-time":
      case "time":
        return (
          <FormControl fullWidth error={!!error} key={field?.identifier}>
            <InputLabel htmlFor={field?.identifier}>{label}</InputLabel>
            <TextField
              id={field?.identifier}
              type={field?.field_type}
              required={isRequired}
              value={value}
              onChange={(event) => handleInputChange(event, field?.identifier)}
              helperText={error}
              error={!!error}
            />
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        );

      case "multi-level":
        return (
          <FormControl fullWidth error={!!error} key={field?.identifier}>
            <InputLabel htmlFor={field?.identifier}>{label}</InputLabel>
            <TextField
              id={field?.identifier}
              required={isRequired}
              value={value}
              onChange={(event) => handleInputChange(event, field?.identifier)}
              helperText={error}
              error={!!error}
            />
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        );

      default:
        return null;
    }
  };

  const statusChangeHandler = (statusValue: any) => {
    setStatus(statusValue);
  };

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="rgba(0, 0, 0, 0.1)"
      >
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          height="60%"
          width="30%"
          bgcolor="background.paper"
          p={2}
          borderRadius={2}
          boxShadow={24}
        >
          <IconButton
            onClick={closeModal}
            style={{ position: "absolute", top: 10, right: 10 }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
          <h2>Ticket Details</h2>
          {loading ? (
            <>
              <LoadingSpinner />
            </>
          ) : (
            <>
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                mt={2}
                gap={2}
                overflow="auto"
                height="calc(100% - 100px)"
              >
                {dynamicFields?.map((field) => renderField(field))}
                <TextField
                  id="internal_notes"
                  label="Internal Notes"
                  variant="outlined"
                  multiline
                  rows={4}
                  value={formValues["internal_notes"] || ""}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      ["internal_notes"]: e.target.value,
                    })
                  }
                  helperText={errors["internal_notes"]}
                  error={!!errors["internal_notes"]}
                />

                <FormControl fullWidth className="mt-2">
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    labelId="status-label"
                    id="status-select"
                    value={statusSelected}
                    label="Status"
                    onChange={handleStatusChange}
                    MenuProps={MenuProps}
                  >
                    {allStatus?.map((statusObj, index) => (
                      <MenuItem
                        key={index}
                        value={statusObj.status}
                        data-bs-toggle="tooltip"
                        title={statusObj?.status}
                        onClick={() =>
                          statusChangeHandler(statusObj.status_type)
                        }
                      >
                        {truncateDescription(statusObj.status, 50)}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors["status"] && (
                    <FormHelperText>{errors["status"]}</FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                width="100%"
                mt="auto"
              >
                <Button
                  className="w-100"
                  variant="contained"
                  color="primary"
                  style={{ marginBottom: 10 }}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default TicketsDetailsModal;
