import { useState, useEffect } from "react";
import ReactModal from "react-modal";
import {
  useBulkSortingMutation,
  useUserDynamicFieldsQuery,
} from "../../../../Services/settingsAPIs/CommonAPI";
import DeleteFieldsModal from "./DeleteFieldsModal";
import EditFieldModal from "./EditFieldModal";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableField from "./DragField";
import LoadingSpinner from "../../../LoadingSpinner";
import PagenationUtility from "../../common/PagenationUtility";
import NewFieldModal from "./NewFieldModal";
import { toast } from "react-toastify";
import { Loader } from "react-bootstrap-typeahead";

interface LiftingDropData {
  choice?: string;
  fieldId?: string;
}

const FieldsPane = () => {
  const {
    data: fieldTableData,
    refetch,
    isLoading,
  } = useUserDynamicFieldsQuery("userprofile");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fieldsData, setFieldsData] = useState<any>([]);
  const [isDeleteButtonClicked, setIsDeleteButtonClicked] =
    useState<boolean>(false);
  const [isEditFieldModalOpen, setIsEditFieldModalOpen] =
    useState<boolean>(false);
  const [storingFieldData, setStoringFieldData] = useState(null);
  const [liftingDropData, setLiftingDropData] = useState<LiftingDropData>({});
  const [editDropdownListItem, setEditDropdownListItem] = useState({
    enabled: false,
    forChoiceId: -1,
    currentVal: liftingDropData?.choice ? liftingDropData?.choice : "",
    fieldId: "",
  });
  const [isModified, setIsModified] = useState(false);
  const [triggerBulkSorting] = useBulkSortingMutation();

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    refetch();
    setIsModalOpen(false);
  };
  const closeDeleteModal = () => {
    refetch();
    setIsDeleteButtonClicked(false);
  };
  const closeEditFieldModal = () => {
    refetch();
    setIsEditFieldModalOpen(false);
  };

  useEffect(() => {
    if (fieldTableData) {
      fieldTableData.length && setFieldsData(fieldTableData);
    }
  }, [fieldTableData]);

  const [expandForMoreSpecifications, setExpandForMoreSpecifications] =
    useState({ expansionOn: -1, expanded: false });
  const customModalStyles: ReactModal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxHeight: "500px",
    },
  };

  const customModalStyles2: ReactModal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      width: "30%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const deleteCustomModalStyles: ReactModal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      width: "30%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const deleteDataHandler = async (field: any) => {
    setIsDeleteButtonClicked(true);
    setStoringFieldData(field);
  };

  const editFieldsHandler = (fields: any) => {
    setIsEditFieldModalOpen(true);
    setStoringFieldData(fields);
  };

  const moveField = (fromIndex, toIndex) => {
    const updatedFields = [...fieldsData];
    const [movedField] = updatedFields.splice(fromIndex, 1);
    updatedFields.splice(toIndex, 0, movedField);
    setFieldsData(updatedFields);
    setIsModified(true); // Set isModified to true when the fields are modified
  };

  const handleBulkSorting = async () => {
    try {
      const payload = fieldsData.map((field, index) => ({
        id: field.id,
        order_value: index + 1,
      }));
      await triggerBulkSorting({ body: payload });
      toast.success("Sequencing updated successfully");
      setIsModified(false); // Reset isModified to false after successful sorting
    } catch (error) {
      toast.error("Error occurred during sequencing sorting");
    }
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <span className="input-group-text mt-2" id="basic-addon1">
            User profile fields
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <span
            className="input-group-text mt-2 bg-white border-0"
            id="basic-addon1"
          >
            <div className="ms-auto">
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "120px",
                }}
                className="input-group-text form-control form-control align-items-center text-hover-primary fw-bold"
                onClick={openModal}
              >
                <i className="bi bi-plus-lg me-2"></i>
                <span>Option</span>
              </button>
            </div>
          </span>
          <ReactModal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            style={customModalStyles}
            contentLabel="Add a new Tag"
          >
            <NewFieldModal closeModal={closeModal} refetch={refetch} />
          </ReactModal>
        </div>
      </div>

      <div className="row mx-0 mb-2 rounded-3 py-4" style={{maxHeight: "60vh", marginBottom: "50px"}}>
          <div className="row" style={{  tableLayout: 'fixed' , borderCollapse: 'collapse', paddingRight: "0px"}}>
            <div
              className="col px-2"
              style={{ paddingRight: "5px", paddingLeft: "5px" }}
            >
              <div
                className="card px-2 my-1 min-w-600px"
                style={{
                  backgroundColor: "#E4E4E47F",
                }}
              >
                <div className="row align-items-center py-4">
                  <div className="col-4 text-start ps-lg-10 ps-4">
                    <strong>Label</strong>
                  </div>
                  <div
                    className="col text-start"
                    style={{ textAlign: "justify" }}
                  >
                    <strong>Field Type</strong>
                  </div>
                  <div className="col text-center">
                    <strong>Mandatory</strong>
                  </div>
                  <div className="col text-center">
                    <strong>Unique</strong>
                  </div>
                  <div className="col text-center">
                    <strong>Masking</strong>
                  </div>
                  <div className="col text-center">
                    <strong>Editable</strong>
                  </div>
                  <div className="col text-end me-2">
                    <strong style={{ paddingRight: "35px" }}>Actions</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{maxHeight: "50vh", overflow: "scroll"}}>

          <div
            className="card my-1 min-w-600px"
            draggable="true"
            style={{ cursor: "grab", padding: "7px 0px" }}
            >
            <div className="row align-items-center">
              <div
                className="col-4 text-start ps-lg-10 ps-4"
                style={{ justifyContent: "space-between", display: "flex" }}
              >
                <label>First Name</label>
                <div></div>
              </div>

              <div
                className="col-2 d-flex align-items-end dropdown"
                style={{
                  textAlign: "justify",
                  justifyContent: "start",
                  width: "10%",
                }}
              >
                <div className="w-100 mw-150px">
                  <label className="form-label">Text</label>
                </div>
              </div>

              <div className="col text-center" style={{ paddingLeft: "30px" }}>
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer", color: "black" }}
                ></i>
              </div>

              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>

              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi  bi-check2"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>

              <div className="col text-end d-flex justify-content-center">
                    {/* <button
                        className="text-hover-primary border-0 bg-white me-2"
                        title="Edit"
                        // onClick={() => editFieldsHandler(field)}
                    >
                        <i className="bi bi-pencil text-dark fs-4"></i>
                    </button> */}
                    {/* <button 

                    style={{visibility: "hidden"}}
                        className="text-hover-danger border-0 bg-white" 
                    
                    >
                        <i className="bi bi-trash  fs-4" style={{display: "hidden"}}></i>
                    </button> */}
                </div>
            </div>
          </div>

          <div
            className="card  min-w-600px"
            draggable="true"
            style={{ cursor: "grab", padding: "7px 0px" }}
          >
            <div className="row align-items-center">
              <div
                className="col-4 text-start ps-lg-10 ps-4"
                style={{ justifyContent: "space-between", display: "flex" }}
              >
                <label>Last Name</label>
                <div></div>
              </div>

              <div
                className="col-2 d-flex align-items-end dropdown"
                style={{
                  textAlign: "justify",
                  justifyContent: "start",
                  width: "10%",
                }}
              >
                <div className="w-100 mw-150px">
                  <label className="form-label">Text</label>
                </div>
              </div>

              <div className="col text-center" style={{ paddingLeft: "30px" }}>
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer", color: "black" }}
                ></i>
              </div>

              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>

              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>

              <div className="col text-center">
                <i
                  className="me-4 fs-2  bi-check2"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-end d-flex justify-content-center">
                    {/* <button
                        className="text-hover-primary border-0 bg-white me-2"
                        title="Edit"
                        onClick={() => editFieldsHandler(fieldsData)}
                    >
                        <i className="bi bi-pencil text-dark fs-4"></i>
                    </button> */}
                    {/* <button 

                    style={{visibility: "hidden"}}
                        className="text-hover-danger border-0 bg-white" 
                    
                    >
                        <i className="bi bi-trash  fs-4" style={{display: "hidden"}}></i>
                    </button> */}
                </div>
            </div>
          </div>

          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <DndProvider backend={HTML5Backend}>
              <div className="row mx-0">
                <div
                  className="col"
                  style={{ paddingLeft: "0", paddingRight: "0px" }}
                >
                  {fieldsData &&
                    fieldsData.map(
                      /* {fieldsData?.map( */
                      (field: any, index: any) => (
                        <DraggableField
                          key={index}
                          field={field}
                          index={index}
                          moveField={moveField}
                          expandForMoreSpecifications={
                            expandForMoreSpecifications
                          }
                          setExpandForMoreSpecifications={
                            setExpandForMoreSpecifications
                          }
                          setEditDropdownListItem={setEditDropdownListItem}
                          editDropdownListItem={editDropdownListItem}
                          refetch={refetch}
                          editFieldsHandler={editFieldsHandler}
                          deleteDataHandler={deleteDataHandler}
                          setLiftingDropData={setLiftingDropData}
                          liftingDropData={liftingDropData}
                        />
                      )
                    )}
                </div>
              </div>
            </DndProvider>
          )}
        </div>

        <div className="text-end mt-3 mb-4">
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          onClick={handleBulkSorting}
          disabled={!isModified || isLoading} // Disable the button if there are no modifications or if it's loading
        >
          {isLoading ? (
            <>
              Saving... <Loader />
            </>
          ) : (
            "Save"
          )}
        </button>
      </div>
      </div>
    
      <ReactModal
        isOpen={isDeleteButtonClicked}
        onRequestClose={closeDeleteModal}
        style={deleteCustomModalStyles}
      >
        <DeleteFieldsModal
          closeModal={closeDeleteModal}
          setFieldsData={setFieldsData}
          fieldsData={fieldsData}
          storingFieldData={storingFieldData}
        />
      </ReactModal>

      <ReactModal
        isOpen={isEditFieldModalOpen}
        onRequestClose={closeEditFieldModal}
        style={customModalStyles2}
      >
        <EditFieldModal
          closeModal={closeEditFieldModal}
          setFieldsData={setFieldsData}
          fieldsData={fieldsData}
          storingFieldData={storingFieldData}
          refetch={refetch}
        />
      </ReactModal>
    </>
  );
};

export default FieldsPane;
