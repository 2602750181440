import React from "react";
import { Routes, Route, NavLink, Navigate } from "react-router-dom";
import ServerConfiguration from "../../components/settings/telephony/ServerConfiguration";
import ExtensionCreation from "../../components/settings/telephony/ExtensionCreation/ExtensionCreation";
import QueueCreation from "../../components/settings/telephony/QueueCreation/QueueCreation";
import PauseCode from "../../components/settings/telephony/PauseCodeCreation/PauseCode";
import AgentMapping from "../../components/settings/telephony/AgentMapping/AgentMapping";
import DispositionSetting from "../../components/settings/telephony/Disposition.tsx/Disposition";
import DID from "../../components/settings/telephony/DID/DID";

const TelephonySettings = () => {
    const tabTitles = [
        { title: "Telephony server configuration", path: "server-configuration" },
        { title: "Extension creation", path: "extension-creation" },
        { title: "Queue creation", path: "queue-creation" },
        { title: "Pause code", path: "pause-code" },
        { title: "Agent mapping", path: "agent-mapping" },
        { title: "DID", path: "DID" },
        { title: "Disposition", path: "disposition" },
    ];

    return (
        <div className="text-start">
            <ul className="nav nav-stretch nav-line-tabs text-primary border-transparent d-flex flex-nowrap mb-5">
                {tabTitles.map((tab) => (
                    <li className="nav-item" key={tab.path}>
                        <NavLink
                            to={tab.path}
                            className={({ isActive }) =>
                                `nav-link cursor-pointer fs-4 fw-bold ${
                                    isActive ? "active fw-bolder" : ""
                                }`
                            }
                            role="tab"
                        >
                            {tab.title}
                        </NavLink>
                    </li>
                ))}
            </ul>

            <Routes>
                <Route index element={<Navigate to="server-configuration" />} />
                <Route
                    path="server-configuration"
                    element={<ServerConfiguration />}
                />
                <Route
                    path="extension-creation"
                    element={<ExtensionCreation />}
                />
                <Route path="queue-creation" element={<QueueCreation />} />
                <Route path="pause-code" element={<PauseCode />} />
                <Route path="agent-mapping" element={<AgentMapping />} />
                <Route path="disposition" element={<DispositionSetting />} />
                <Route path="DID" element={<DID />} />
                <Route
                    path="*"
                    element={
                        <div className="text-center">
                            <p>Page Not Found</p>
                        </div>
                    }
                />
            </Routes>
        </div>
    );
};

export default TelephonySettings;
