import React from 'react'


export const capitalizeFirstLetter = (value: any) => {
  if (typeof value !== 'string' || value.trim() === '') {
      // Return an empty string or a default value if the input is not a valid string
      return '';
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
};

   export  const capitalizeAndFormat = (string: any) => {
        return string
            .split('-')
            .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
