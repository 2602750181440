import React, { useEffect, useState } from "react";
import {
  useClientEditInfoQuery,
  useEditClientInfoMutation,
} from "../../../Services/accountLogin/AccountLogin";
import { toast } from "react-toastify";
import { Loader } from "react-bootstrap-typeahead";

interface IPROPS {
  closeModal: any;
  clientData: any;
  refetch: any;
}

const EditClientModal: React.FC<IPROPS> = ({
  closeModal,
  clientData,
  refetch,
}) => {
  const [accounts, setAccounts] = useState([]);

  const [name, setName] = useState<any>(clientData?.fields?.username);
  const [password, setPassword] = useState<any>(clientData?.fields?.password);
  const [account, setAccount] = useState<any>(clientData?.account?.id);

  const { data } = useClientEditInfoQuery({});
  const [trigger, { isLoading }] = useEditClientInfoMutation();

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const passwordHandler = (e: any) => {
    setPassword(e.target.value);
  };

  const accountHandler = (e: any) => {
    setAccount(e.target.value);
  };

  useEffect(() => {
    if (data) {
      setAccounts(data);
    }
  }, [data]);

  let id = clientData?.fields?.id;

  const registerClientInfo = async (e: any) => {
    e.preventDefault();

    const clientData = {
      account: account,
      password: password,
      username: name,
    };

    try {
      const response = await trigger({ body: clientData, id: id });
      if (response?.data) {
        toast.success("Edited Successfully");
        refetch();
        closeModal(true);
      } else if (response?.error) {
        toast.error("An error occured");
      }
    } catch (error) {
      toast.error("An error occured");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h2>Register</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label className="required" style={{ fontSize: "14px" }}>
            Email/Username
          </label>
          <input className="form-control" onChange={nameHandler} value={name} />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label className="required" style={{ fontSize: "14px" }}>
            Password
          </label>
          <input
            className="form-control"
            onChange={passwordHandler}
            value={password}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label className="required" style={{ fontSize: "14px" }}>
            Select Account
          </label>
          <select
            className="form-select"
            onChange={accountHandler}
            value={account}
          >
            {accounts?.map((account) => (
              <option value={account.id}>{account?.name}</option>
            ))}
          </select>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <button
          className="btn btn-primary"
          style={{ width: "100%" }}
          onClick={registerClientInfo}
        >
          {isLoading ? (
            <>
              Registering... <Loader />
            </>
          ) : (
            "Register"
          )}
        </button>
      </div>
    </div>
  );
};

export default EditClientModal;
