import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  useLogoutMutation,
  useResetPasswordMutation,
} from "../../../Services/Auth/Auth";

const initialValues = {
  oldPassword: "",
  password: "",
  changepassword: "",
};

const registrationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Password is required"),

  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
    )
    .required("Password is required"),
  changepassword: Yup.string()
    .required("Confirm password is required")
    .when("password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and Confirm Password didn't match"
      ),
    }),
});

const ResetPassword = () => {

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loadingOL, setLoading] = useState(false);

  const [trigger, {status}] = useResetPasswordMutation();
  const [triggerLogOut] = useLogoutMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (status === "pending") {
      setLoading(true)
    }
    else {
      setLoading(false)
    }

  }, [status])

  
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values) => {
      const resetPassPayload = {
        new_password: values.password,
        old_password: values.oldPassword,
      };
   
      try {
       const response =  await trigger(resetPassPayload);


       if (response?.data) {

        toast.success("Password updated successfully");
        navigate("/auth/login");
        await triggerLogOut({});
       }

       else if ("error" in response) {
        const errorMessage =
          (response.error as { data?: { detail?: string } })?.data?.detail ||
          "An error occurred. Please try again.";
        toast.error(errorMessage);
      }

      } catch (error) {
        toast.error("An error occured");
      }
    },
  });

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="mb-10 text-center">
        <h1 className="text-dark mb-3">Reset Password</h1>

        <div className="text-gray-400 fw-bold fs-4">
          We are excited to have you
        </div>
      </div>

      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">
          Old Password
        </label>
        <input
          placeholder="Old password"
          type="password"
          autoComplete="off"
          {...formik.getFieldProps("oldPassword")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid":
                formik.touched.oldPassword && formik.errors.oldPassword,
            },
            {
              "is-valid":
                formik.touched.oldPassword && !formik.errors.oldPassword,
            }
          )}
        />
        {formik.touched.oldPassword && formik.errors.oldPassword && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.oldPassword}</span>
            </div>
          </div>
        )}
      </div>

      <div className="mb-10 fv-row">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark fs-6">
            New Password
          </label>
          <div className="position-relative mb-3 d-flex">
            <input
              type={showNewPassword ? "text" : "password"}
              placeholder="Password"
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
              style={{
                borderRadius: "5px 0px 0px 5px",
              }}
            />
            <div className="input-group-append">
              <span
                className="form-control form-control-lg form-control-solid"
                id="togglePassword"
                onClick={toggleNewPasswordVisibility}
                style={{
                  cursor: "pointer",
                  borderRadius: "0px 5px 5px 0px",
                }}
              >
                {showNewPassword ? (
                  <i className="bi bi-eye-slash text-dark"></i>
                ) : (
                  <i className="bi bi-eye text-dark"></i>
                )}
              </span>
            </div>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="fv-row mb-5">
        <label className="form-label fw-bolder text-dark fs-6">
          Confirm Password
        </label>
        <div className="position-relative mb-3 d-flex">
          <input
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm password"
            autoComplete="off"
            {...formik.getFieldProps("changepassword")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid":
                  formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                "is-valid":
                  formik.touched.changepassword &&
                  !formik.errors.changepassword,
              }
            )}
            style={{
              borderRadius: "5px 0px 0px 5px",
            }}
          />
          <div className="input-group-append">
            <span
              className="form-control form-control-lg form-control-solid"
              id="togglePassword"
              onClick={toggleConfirmPasswordVisibility}
              style={{
                cursor: "pointer",
                borderRadius: "0px 5px 5px 0px",
              }}
            >
              {showConfirmPassword ? (
                <i className="bi bi-eye-slash text-dark"></i>
              ) : (
                <i className="bi bi-eye text-dark"></i>
              )}
            </span>
          </div>
        </div>
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>

      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loadingOL && <span className="indicator-label">Submit</span>}
          {loadingOL && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_signup_form_cancel_button"
            className="btn btn-lg btn-light-primary w-100 mb-5"
          >
            Cancel
          </button>
        </Link>
      </div>
    </form>
  );
};

export default ResetPassword;
