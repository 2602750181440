import React, { useEffect, useState } from "react";
import { CloseButton } from "react-bootstrap";
import BulkInviteModal from "./BulkInviteModal";
import {
    useTeamsQuery,
    useUsersDynamicFieldsQuery,
} from "../../Services/settingsAPIs/CommonAPI";
import { useInviteUsersMutation } from "../../Services/user/Users";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { toast } from "react-toastify";
import clsx from "clsx";
import moment from "moment";
import axios from "axios";
import {
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
} from "@mui/material";
import { Loader } from "react-bootstrap-typeahead";
import { capitalizeFirstLetter } from "../reusableComponent/CapitalLetter";
import './styles.scss'

interface IProps {
    closeModal: () => void;
    isBulk: any;
    setIsBulk: any;
    refetch: any;
}

interface ErrorState {
    [key: string]: string;
}

const UserInviteModal: React.FC<IProps> = ({
    closeModal,
    isBulk,
    setIsBulk,
    refetch,
}) => {
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);

    const { data: DynamicFieldsData } = useUsersDynamicFieldsQuery({});

    const [triggerInviteUsers, { isLoading }] = useInviteUsersMutation({});
    const [selectedOption, setSelectedOption] = useState<any>("");
    const [selectedTeams, setSelectedTeams] = useState<any[]>([]);
    const [theTeams, setTheTeams] = useState([]);
    const [branchUser, setBranchUser] = useState("false");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [username, setUsername] = useState("");
    const [] = useState(true);

    const [dynamicData, setDynamicData] = useState<any[]>([]);
    const [dynamicFields, setDynamicFields] = useState<any>("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [errors, setErrors] = useState<ErrorState>({
        first_name: "",
        last_name: "",
        username: "",
    });

    const { data: teamsData } = useTeamsQuery({});
    useEffect(() => {
        if (teamsData) {
            setTheTeams(teamsData);
        }
    }, [teamsData]);

    useEffect(() => {
        if (DynamicFieldsData) {
            setDynamicData(DynamicFieldsData);
        }
    }, [DynamicFieldsData]);

    const handleToggleChange = () => {
        setIsBulk(!isBulk);
        setSelectedFiles(null);
    };

    const handleDropdownChange = (e: any) => {
        setIsButtonDisabled(false);
        setSelectedOption(e.target.value);
        setErrors({});
    };

    const apiPort = process.env.REACT_APP_API_PROTOCOL;

    const handleInputChange = (label, value) => {
        setIsButtonDisabled(false);
        setErrors({});
        setDynamicFields((prevState) => ({
            ...prevState,
            [label]: value,
        }));
    };
    
    const handleCheckboxChange = (
        event: React.ChangeEvent<{ value: any }>,
        key: string
    ) => {
        setIsButtonDisabled(false);

        setErrors({});
        setDynamicFields((prevFields) => ({
            ...prevFields,
            [key]: event.target.value,
        }));
    };

    const handleTeamsChange = (event: any) => {
        setIsButtonDisabled(false);

        const {
            target: { value },
        } = event;

        if (value.includes("all")) {
            setSelectedTeams(
                selectedTeams.length === theTeams.length
                    ? []
                    : theTeams.map((team) => team.id)
            );
        } else {
            // Convert the value to an array of strings
            setSelectedTeams(
                typeof value === "string" ? value.split(",") : value
            );
        }

        setErrors({});
    };

    // Function to check if all teams are selected
    const isAllSelected = selectedTeams.length === theTeams.length;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const validateForm = () => {
        const newErrors: ErrorState = {};

        dynamicData.forEach((field: any) => {
            const fieldValue = dynamicFields[field.label];

            if (field.is_required && !fieldValue) {
                newErrors[field.label] = `${field.label} is required`;
            }

            // Example: Add phone number validation logic here
            if (
                field.is_required &&
                field.field_type === "tel-phone" &&
                fieldValue
            ) {
                // If you have specific phone validation, you could add it here
                const isValidPhone =
                    /^(?:\+?\d{1,3})?[ -]?\(?\d{1,4}?\)?[ -]?\d{1,4}[ -]?\d{1,4}(?: x\d{1,4})?$/.test(
                        fieldValue
                    );
                if (!isValidPhone) {
                    newErrors[
                        field.label
                    ] = `${field.label} must be a valid phone number`;
                }
            }

            // if (field?.field_type === "email" && field.is_required) {
            //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            //   if (!emailRegex.test(dynamicFields[field.label])) {
            //     newErrors[field.label] = "Invalid email format";
            //   }
            // }

            if (!username) {
                newErrors["username"] = "Username/Email is required";
            }

            if (
                field.field_type === "check-box" &&
                field.is_required &&
                !fieldValue.length
            ) {
                newErrors[field.label] = `${field.label} is required`;
            }

            if (!selectedTeams.length) {
                newErrors["teams"] = "Please select at least one team";
            }

            // Add validation for role
            if (!selectedOption) {
                newErrors["role"] = "Please select a role";
            }

            if (!firstName) {
                newErrors["first_name"] = "First name is required";
            }

            if (!lastName) {
                newErrors["last_name"] = "Last name is required";
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const submitHandler = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            toast.error("Please fill all required fields.");
            setIsButtonDisabled(true);
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, 3000);
            return;
        }

        setIsButtonDisabled(true);

        const dynamicFieldData = Object.keys(dynamicFields).reduce(
            (acc, key) => {
                const field = dynamicData.find((field) => field.label === key);
                if (field?.field_type === "date") {
                    const formattedDate = new Date(dynamicFields[key])
                        .toISOString()
                        .replace("Z", "+00:00");
                    acc[key] = formattedDate;
                } else {
                    acc[key] = dynamicFields[key] || "";
                }
                return acc;
            },
            {}
        );
        const fetchingTenant = window.location.hostname.split(".")[0];

        try {
            const obj = {
                first_name: firstName,
                last_name: lastName,
                role: selectedOption,
                username: username,
                dynamic_fields: dynamicFieldData,
                avatar: null,
                phone: null,
                teams: selectedTeams,
                branch_user: branchUser,
            };

            const response = await axios.post(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/users/users/`,
                obj,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "auth-token"
                        )}`,
                    },
                }
            );

            if (response.data) {
                closeModal();
                toast.success("User invited successfully!");
                refetch();
            } else {
              
            }
        } catch (error) {
            console.error("API Error:", error);

            if (error?.response?.data) {
                Object.keys(error.response.data).forEach((key) => {
                    const messages = error.response.data[key];
                    const capitalizedKey =
                        key.charAt(0).toUpperCase() + key.slice(1);

                    if (Array.isArray(messages)) {
                        messages.forEach((message) => {
                            toast.error(`${capitalizedKey}: ${message}`);
                        });
                    } else {
                        toast.error(`${capitalizedKey}: ${messages}`);
                    }
                });
            } else {
                toast.error("Failed to invite user. Please try again.");
            }
        } finally {
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, 3000);
        }
    };

    const getDataTimeFormatted = (date) => {
        if (date && date.length > 0) {
            const dateObj = new Date(date);

            // Manually format the date to ensure no timezone conversion issues
            const year = dateObj.getUTCFullYear();
            const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
            const day = String(dateObj.getUTCDate()).padStart(2, "0");
            const hours = String(dateObj.getUTCHours()).padStart(2, "0");
            const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");

            const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
            return formattedDate;
        } else {
            return "";
        }
    };

    return (
        <div className="custom-style">
            <div className="text-end">
                <CloseButton onClick={closeModal} />
            </div>
            <div className="text-center mb-4">
                <label className="form-label fs-4 fw-bolder text-dark card-title">
                    Add user
                </label>
            </div>
            <div className="d-flex justify-content-center mb-5">
                <label className="form-check-label mx-2">Single</label>
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={isBulk}
                        onChange={handleToggleChange}
                    />
                    <label className="card-text">Bulk</label>
                </div>
            </div>

            {isBulk ? (
                <BulkInviteModal closeModal={closeModal} refetch={refetch} dynamicData={dynamicData} teamsData={teamsData} />
            ) : (
                <>
                    <div className="row g-5 g-xl-8 mb-4">
                        <div className="col-xl-4">
                            <InputLabel className="required">
                                First Name
                            </InputLabel>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={firstName}
                                onChange={(e) => {
                                    setIsButtonDisabled(false);

                                    setErrors(errors?.first_name ? {} : errors);
                                    setFirstName(e.target.value);
                                }}
                            />
                            {errors.first_name && (
                                <div className="mt-2" style={{ color: "red" }}>
                                    {capitalizeFirstLetter(errors.first_name)}
                                </div>
                            )}
                        </div>

                        <div className="col-xl-4">
                            <InputLabel className="required">
                                Last Name
                            </InputLabel>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={lastName}
                                onChange={(e) => {
                                    setIsButtonDisabled(false);

                                    setErrors(errors?.last_name ? {} : errors);
                                    setLastName(e.target.value);
                                }}
                            />
                            {errors.last_name && (
                                <div className="mt-2" style={{ color: "red" }}>
                                    {capitalizeFirstLetter(errors.last_name)}
                                </div>
                            )}
                        </div>

                        <div className="col-xl-4">
                            <InputLabel className="required">
                                Username/Email
                            </InputLabel>
                            <TextField
                                type="email"
                                variant="outlined"
                                fullWidth
                                value={username}
                                onChange={(e) => {
                                    setIsButtonDisabled(false);

                                    setErrors(errors?.username ? {} : errors);
                                    setUsername(e.target.value);
                                }}
                            />
                            {errors.username && (
                                <div className="mt-2" style={{ color: "red" }}>
                                    {capitalizeFirstLetter(errors.username)}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row g-5 g-xl-8 mb-4">
                        <div className="col-xl-4">
                            <InputLabel className="required">Role</InputLabel>
                            <FormControl fullWidth>
                                <Select
                                    value={selectedOption}
                                    onChange={handleDropdownChange}
                                    required
                                >
                                    <MenuItem value="" disabled selected>
                                        Select
                                    </MenuItem>
                                    <MenuItem value="agent">Agent</MenuItem>
                                    <MenuItem value="manager">Manager</MenuItem>
                                </Select>
                                {errors.role && (
                                    <div
                                        className="mt-2"
                                        style={{ color: "red" }}
                                    >
                                        {capitalizeFirstLetter(errors.role)}
                                    </div>
                                )}
                            </FormControl>
                        </div>

                        <div className="col-xl-4">
                            <FormControl fullWidth>
                                <label className="required">Teams</label>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={selectedTeams}
                                    onChange={handleTeamsChange}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        if (isAllSelected) {
                                            return <div>All</div>;
                                        }
                                        return (
                                            <div>
                                                {selected
                                                    .map((teamId) => {
                                                        const team =
                                                            theTeams.find(
                                                                (t) =>
                                                                    t.id ===
                                                                    teamId
                                                            );
                                                        return team
                                                            ? team.name
                                                            : null;
                                                    })
                                                    .join(", ")}
                                            </div>
                                        );
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem
                                        value="all"
                                        onClick={() =>
                                            setSelectedTeams(
                                                isAllSelected
                                                    ? []
                                                    : theTeams.map(
                                                          (team) => team.id
                                                      )
                                            )
                                        }
                                    >
                                        <Checkbox
                                            checked={isAllSelected}
                                            indeterminate={
                                                selectedTeams.length > 0 &&
                                                !isAllSelected
                                            }
                                        />
                                        <ListItemText primary="All" />
                                    </MenuItem>
                                    {theTeams.map((team: any) => (
                                        <MenuItem key={team.id} value={team.id}>
                                            <Checkbox
                                                checked={
                                                    selectedTeams.indexOf(
                                                        team.id
                                                    ) > -1
                                                }
                                            />
                                            <ListItemText primary={team.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.teams && (
                                    <div
                                        className="mt-2"
                                        style={{ color: "red" }}
                                    >
                                        {capitalizeFirstLetter(errors.teams)}
                                    </div>
                                )}
                            </FormControl>
                        </div>

                        <div className="col-xl-4">
                            <label>Branch User</label>
                            <select
                                style={{ height: "50px" }}
                                className="form-select"
                                value={branchUser}
                                onChange={(e) => setBranchUser(e.target.value)}
                            >
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>

                        {/* Render dynamic fields */}
                        {dynamicData?.map((field: any) => (
                            <React.Fragment key={field?.label}>
                                <div 
                                    key={field.label}
                                    className="col-xl-4 mb-3"
                                >
                                    <InputLabel
                                        htmlFor={`dynamic_fields.${field.label}`}
                                    >
                                        {field.label}
                                        {field.is_required && (
                                            <span className="required"></span>
                                        )}
                                    </InputLabel>
                                    {field.field_type === "text" && (
                                        <TextField
                    //   disabled={isFieldDisabled(field?.is_editable)}
                    //   style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}


                                            id={`dynamic_fields.${field.label}`}
                                            variant="outlined"
                                            fullWidth
                                            // disabled={!field?.is_editable}
                                            value={
                                                dynamicFields[field.label] || ""
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.label,
                                                    e.target.value
                                                )
                                            }
                                            required={field.is_required}
                                        />
                                    )}
                                    {field.field_type === "email" && (
                                        <TextField
                    //   disabled={isFieldDisabled(field?.is_editable)}
                    //   style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}


                                            type="email"
                                            id={`dynamic_fields.${field.label}`}
                                            // disabled={!field?.is_editable}
                                            variant="outlined"
                                            fullWidth
                                            value={
                                                dynamicFields[field.label] || ""
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.label,
                                                    e.target.value
                                                )
                                            }
                                            required={field.is_required}
                                        />
                                    )}
                                    {field.field_type === "number" && (
                                        <TextField
                    //   disabled={isFieldDisabled(field?.is_editable)}
                    //   style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}


                                            type="number"
                                            id={`dynamic_fields.${field.label}`}
                                            // disabled={!field?.is_editable}
                                            variant="outlined"
                                            fullWidth
                                            value={
                                                dynamicFields[field.label] || ""
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.label,
                                                    e.target.value
                                                )
                                            }
                                            required={field.is_required}
                                        />
                                    )}
                                    {field.field_type === "date" && (
                                        <TextField
                    //   disabled={isFieldDisabled(field?.is_editable)}
                    //   style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}


                                            type="date"
                                            id={`dynamic_fields.${field.label}`}
                                            // disabled={!field?.is_editable}
                                            variant="outlined"
                                            fullWidth
                                            value={
                                                dynamicFields[field.label] || ""
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.label,
                                                    e.target.value
                                                )
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            required={field.is_required}
                                        />
                                    )}
                                    {field.field_type === "time" && (
                                        <TextField
                    //   disabled={isFieldDisabled(field?.is_editable)}
                    //   style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}


                                            type="time"
                                            id={`dynamic_fields.${field.label}`}
                                            // disabled={!field?.is_editable}
                                            variant="outlined"
                                            fullWidth
                                            value={
                                                dynamicFields[field.label] || ""
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.label,
                                                    e.target.value
                                                )
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            required={field.is_required}
                                        />
                                    )}
                                    {field.field_type === "date-time" && (
                                        <TextField
                    //   disabled={isFieldDisabled(field?.is_editable)}
                    //   style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}


                                            type="datetime-local"
                                            id={`dynamic_fields.${field.label}`}
                                            // disabled={!field?.is_editable}
                                            variant="outlined"
                                            fullWidth
                                            defaultValue={getDataTimeFormatted(
                                                dynamicFields[field.label]
                                            )}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.label,
                                                    e.target.value
                                                )
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            required={field.is_required}
                                        />
                                    )}
                                    {field.field_type === "text-area" && (
                                        <TextField
                    //   disabled={isFieldDisabled(field?.is_editable)}
                    //   style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}


                                            id={`dynamic_fields.${field.label}`}
                                            // disabled={!field?.is_editable}
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            value={
                                                dynamicFields[field.label] || ""
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.label,
                                                    e.target.value
                                                )
                                            }
                                            required={field.is_required}
                                        />
                                    )}
                                    {field.field_type === "drop-down" && (
                                        <FormControl fullWidth>
                                            <Select
                    //   disabled={isFieldDisabled(field?.is_editable)}
                    //   style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}


                                                labelId={`select-label-${field.label}`}
                                                // disabled={!field?.is_editable}
                                                id={`select-${field.label}`}
                                                value={
                                                    dynamicFields[
                                                        field.label
                                                    ] || ""
                                                }
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        field.label,
                                                        e.target.value
                                                    )
                                                }
                                                required={field.is_required}
                                            >
                                                <MenuItem selected disabled>
                                                    Select
                                                </MenuItem>
                                                {field?.choices?.map(
                                                    (option: any) => (
                                                        <MenuItem
                                                            key={option.id}
                                                            value={
                                                                option.choice
                                                            }
                                                        >
                                                            {option.choice}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                                    {field.field_type === "check-box" && (
                                        <FormControl fullWidth>
                                            <Select
                    //   disabled={isFieldDisabled(field?.is_editable)}
                    //   style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}

                                                labelId={`checkbox-label-${field.label}`}
                                                // disabled={!field?.is_editable}

                                                id={`checkbox-${field.label}`}
                                                required={field.is_required}
                                                multiple
                                                value={
                                                    dynamicFields[
                                                        field.label
                                                    ] || []
                                                } // Ensure value is an array of IDs
                                                onChange={(e: any) =>
                                                    handleCheckboxChange(
                                                        e,
                                                        field.label
                                                    )
                                                }
                                                renderValue={(selected) => {
                                                    // Create an array of selected choice labels
                                                    const selectedLabels = (
                                                        field?.multiple_choices ||
                                                        []
                                                    )
                                                        .filter((choice) =>
                                                            selected.includes(
                                                                String(
                                                                    choice.id
                                                                )
                                                            )
                                                        )
                                                        .map(
                                                            (choice) =>
                                                                choice.choice
                                                        );
                                                    return selectedLabels.join(
                                                        ", "
                                                    );
                                                }}
                                            >
                                                {field?.multiple_choices?.map(
                                                    (choice: any) => (
                                                        <MenuItem
                                                            key={choice.id}
                                                            value={String(
                                                                choice.id
                                                            )}
                                                        >
                                                            <Checkbox
                                                                checked={dynamicFields[
                                                                    field.label
                                                                ]?.includes(
                                                                    String(
                                                                        choice.id
                                                                    )
                                                                )}
                                                            />
                                                            <ListItemText
                                                                primary={
                                                                    choice.choice
                                                                }
                                                            />
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}

                                    {field.field_type === "tel-phone" && (
                                        <div className="telephony_container">

                                        <PhoneInput  
                                        
                    //   disabled={isFieldDisabled(field?.is_editable)}
                    //   style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}


                                            defaultCountry="IN"
                                            value={
                                                dynamicFields[field.label] || ""
                                            }
                                            // disabled={!field?.is_editable}

                                            onChange={(value) =>
                                                handleInputChange(
                                                    field.label,
                                                    value
                                                )
                                            }
                                            required={field.is_required}
                                        />
                                        </div>

                                    )}
                                    {errors[field.label] && (
                                        <div
                                            className="mt-2"
                                            style={{ color: "red" }}
                                        >
                                            {capitalizeFirstLetter(
                                                errors[field.label]
                                            )}
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>
                        ))}
                    </div>

                    <div className="text-end mt-3 mb-4">
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          onClick={submitHandler}
          disabled={isButtonDisabled} 
        >
          {isLoading ? (
            <>
              Submitting... <Loader />
            </>
          ) : (
            "Submit"
          )}
        </button>
      </div>
                </>
            )}
        </div>
    );
};

export default UserInviteModal;
