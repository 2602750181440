import React from "react";

interface IProps {
  onSelectTemplate: any;
  setSelectedDescriptionTemplate: any;
  storeTemplateData: any;
  setAllAttachment: any;
}

const EmailTemplateLists: React.FC<IProps> = ({
  onSelectTemplate,
  setSelectedDescriptionTemplate,
  storeTemplateData,
  setAllAttachment,
}) => {
  const handleTemplate = (template: any) => {
    onSelectTemplate();

    let updatedTemplate = { ...template }; // Create a shallow copy of the template object
    setAllAttachment(template?.attachments);
    if (template.content) {
      updatedTemplate.content = template.content.replace(/<\/?p>/g, "");
    }

    setSelectedDescriptionTemplate(updatedTemplate);
  };

  return (
    <div>
      {storeTemplateData?.results?.map((template: any, index: any) => (
        <div key={index} className="card mb-2">
          <div className="card-body" onClick={() => handleTemplate(template)}>
            {template.subject}
          </div>
        </div>
      ))}
    </div>
  );
};

export default EmailTemplateLists;
