import { useState, useEffect } from "react";
import PieChartRTM from "./charts/PieChartRTM";
import { DataGrid } from "@mui/x-data-grid";
import { CardContent } from "@mui/material";
import CustomDate from "../../../components/common/CustomDate";
import axios from "axios";
import { TELEPHONEURL } from "../../../constants";
import LoadingSpinner from "../../../components/LoadingSpinner";

type Member = {
  AgentName: string;
};

type QueueData = {
  ncalls: string;
  Answered: string;
  Abandoned: string;
  ServiceLevel: string;
  ServiceLevelIn: string;
  Members?: { [key: number]: Member };
};

type Data = {
  [key: string]: QueueData;
};

interface RowType {
  id: any;
  queuename: any;
  user: any;
  status: any;
  pausecode: any;
  extension: any;
  callduration: any;
  listen: any;
  whisper: any;
  forcelogout: any;
}

const RTMDashboard = () => {
  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [getCallDetails, setGetCallDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const statusCards = [
    {
      iconClass: "bi-door-open",
      label: "Logged In",
      arrowClass: "bi-arrow-down",
      count: 18,
    },
    {
      iconClass: "fa-phone-volume",
      label: "On Call",
      arrowClass: "bi-arrow-down",
      count: 6,
    },
    {
      iconClass: "fa-phone",
      label: "Ready",
      arrowClass: "bi-arrow-down",
      count: 2,
    },
    { iconClass: "fa-phone-slash", label: "Paused", arrowClass: "", count: 2 },
    {
      iconClass: "bi-person-workspace",
      label: "ACW",
      arrowClass: "",
      count: 6,
    },
    {
      iconClass: "fa-blender-phone",
      label: "Call Waiting",
      arrowClass: "",
      count: 2,
    },
  ];

  const gettingTelephonyData = localStorage.getItem(TELEPHONEURL);
  const telephoneData = JSON.parse(gettingTelephonyData);

  const [isHover, setIsHover] = useState({ hovered: false, cardIndex: -1 });
  const [isPressed, setIsPressed] = useState({
    pressState: false,
    cardIndex: -1,
  });

  const handleMouseEnter = (index: number) => {
    setIsHover({ hovered: true, cardIndex: index });
  };

  const handleMouseLeave = (index: number) => {
    setIsHover({ hovered: false, cardIndex: index });
  };

  const handleMouseClick = (index: number) => {
    setIsPressed({
      ...isPressed,
      pressState: !isPressed.pressState,
      cardIndex: index,
    });
  };

  const boxStyle = (index: number) => {
    return {
      backgroundColor:
        isPressed.cardIndex === index && isPressed.pressState
          ? "#3C94FD"
          : isHover.cardIndex === index && isHover.hovered
          ? "#e9edf1"
          : "white",
      color:
        isPressed.cardIndex === index && isPressed.pressState
          ? "white"
          : isHover.cardIndex === index && isHover.hovered
          ? "#303030"
          : "#959595",
      /* color: isHover.cardIndex === index && isHover.hovered ? '#303030': '#A2A2A2', */
      cursor: "pointer",
      transition: "background-color 0.5s",
    };
  };

  // Function to render channel icons
  const renderChannelIcon = (value) => {
    switch (value) {
      case "mail":
        return <i className="fs-3 bi bi-envelope"></i>;
      case "phone":
        return <i className="fs-3 bi bi-telephone"></i>;
      case "internet":
        return <i className="fs-3 bi bi-globe"></i>;
      case "whatsapp":
        return <i className="fs-3 bi bi-whatsapp"></i>;
      case "message":
        return <i className="fs-3 bi bi-chat-left-text"></i>;
      default:
        return null;
    }
  };

  const renderLogoutButton = (value) => {
    switch (value) {
      case "happy":
        return (
          <>
            <button className="btn btn-link">
              <i className="fa-solid fa fa-headphones"></i>
            </button>
            <button className="btn btn-outline-light bg-primary">Logout</button>
          </>
        );
      case "angry":
        return (
          <>
            <button className="btn btn-link">
              <i className="fa-solid fa fa-headphones"></i>
            </button>
            <button className="btn btn-outline-light bg-primary">Logout</button>
          </>
        );
      default:
        return (
          <>
            <button className="btn btn-outline-light bg-primary">Logout</button>
          </>
        );
    }
  };

  const columns = [
    { field: "id", headerName: "Sr no.", width: 150 },
    { field: "queuename", headerName: "Queue Name.", width: 150 },
    { field: "user", headerName: "User", width: 220 },
    { field: "status", headerName: "Status", width: 220 },
    { field: "pausecode", headerName: "Pausecode", width: 180 },
    { field: "extension", headerName: "Extension", width: 250 },
    { field: "callduration", headerName: "Call Duration", width: 220 },
    {
      field: "listen",
      headerName: "Listen",
      width: 40,
      renderCell: (params: any) => (
        <button className="btn btn-link" onClick={() => activateListen(params.row)}>
          <i className="text-dark fs-2x fa-solid fa fa-headphones"></i>
        </button>
      ),
    },
    {
      field: "whisper",
      headerName: "Whisper",
      width: 60,
      renderCell: (params: any) => (
        <button className="btn btn-link mx-1" onClick={() => activateWhisper(params.row)}>
          <i className="text-dark fs-2x fa-solid fa fa-headset"></i>
        </button>
      ),
    },
    {
      field: "forcelogout",
      headerName: "Force Logout",
      width: 220,
      renderCell: (params: any) => renderLogoutButton(params.value),
    },
  ];

  // const rows = [
  //   {
  //     id: 1,
  //     queuename: "English",
  //     user: "Govind",
  //     status: "ACW",
  //     pausecode: "",
  //     extension: "7036",
  //     callduration: "",
  //     listen: "Listen",
  //     whisper: "whisper",
  //     forcelogout: "Logout",
  //   },
  //   {
  //     id: 2,
  //     queuename: "English",
  //     user: "Dheeraj",
  //     status: "ACW",
  //     pausecode: "",
  //     extension: "7037",
  //     callduration: "",
  //     listen: "Listen",
  //     whisper: "whisper",
  //     forcelogout: "Logout",
  //   },
  //   {
  //     id: 3,
  //     queuename: "English",
  //     user: "Megha",
  //     status: "On Call",
  //     pausecode: "",
  //     extension: "7038",
  //     callduration: "00:10:11",
  //     listen: "Listen",
  //     whisper: "whisper",
  //     forcelogout: "Logout",
  //   },
  //   {
  //     id: 4,
  //     queuename: "Support",
  //     user: "Ashish",
  //     status: "Paused",
  //     pausecode: "505",
  //     extension: "7039",
  //     callduration: "",
  //     listen: "Listen",
  //     whisper: "whisper",
  //     forcelogout: "Logout",
  //   },
  //   {
  //     id: 9,
  //     queuename: "English",
  //     user: "Vinod",
  //     status: "ACW",
  //     pausecode: "",
  //     extension: "7040",
  //     callduration: "",
  //     listen: "Listen",
  //     whisper: "whisper",
  //     forcelogout: "Logout",
  //   },
  //   {
  //     id: 12,
  //     queuename: "English",
  //     user: "Archana",
  //     status: "ACW",
  //     pausecode: "",
  //     extension: "7041",
  //     callduration: "",
  //     listen: "Listen",
  //     whisper: "whisper",
  //     forcelogout: "Logout",
  //   },
  //   {
  //     id: 23,
  //     queuename: "English",
  //     user: "Abhinev",
  //     status: "On Call",
  //     pausecode: "",
  //     extension: "7042",
  //     callduration: "00:06:26",
  //     listen: "Listen",
  //     whisper: "whisper",
  //     forcelogout: "Logout",
  //   },
  //   {
  //     id: 41,
  //     queuename: "Support",
  //     user: "Shivam",
  //     status: "On Call",
  //     pausecode: "",
  //     extension: "7043",
  //     callduration: "00:04:06",
  //     listen: "Listen",
  //     whisper: "whisper",
  //     forcelogout: "Logout",
  //   },
  //   {
  //     id: 17,
  //     queuename: "English",
  //     user: "Dhruv",
  //     status: "ACW",
  //     pausecode: "",
  //     extension: "7044",
  //     callduration: "",
  //     listen: "Listen",
  //     whisper: "whisper",
  //     forcelogout: "Logout",
  //   },
  //   {
  //     id: 21,
  //     queuename: "Hindi",
  //     user: "Dharampal",
  //     status: "ACW",
  //     pausecode: "",
  //     extension: "7045",
  //     callduration: "",
  //     listen: "Listen",
  //     whisper: "whisper",
  //     forcelogout: "Logout",
  //   },
  //   {
  //     id: 32,
  //     queuename: "Hindi",
  //     user: "Umesh",
  //     status: "Call Waiting",
  //     pausecode: "",
  //     extension: "7046",
  //     callduration: "",
  //     listen: "Listen",
  //     whisper: "whisper",
  //     forcelogout: "Logout",
  //   },
  //   {
  //     id: 33,
  //     queuename: "Hindi",
  //     user: "Anjali",
  //     status: "On Call",
  //     pausecode: "",
  //     extension: "7047",
  //     callduration: "00:05:20",
  //     listen: "Listen",
  //     whisper: "whisper",
  //     forcelogout: "Logout",
  //   },
  //   {
  //     id: 34,
  //     queuename: "English",
  //     user: "Shikha",
  //     status: "Paused",
  //     pausecode: "711",
  //     extension: "7048",
  //     callduration: "",
  //     listen: "Listen",
  //     whisper: "whisper",
  //     forcelogout: "Logout",
  //   },
  //   {
  //     id: 36,
  //     queuename: "English",
  //     user: "Kiran",
  //     status: "On Call",
  //     pausecode: "",
  //     extension: "7081",
  //     callduration: "00:16:16",
  //     listen: "Listen",
  //     whisper: "whisper",
  //     forcelogout: "Logout",
  //   },
  //   {
  //     id: 81,
  //     queuename: "English",
  //     user: "Shivani",
  //     status: "Call Waiting",
  //     pausecode: "",
  //     extension: "7091",
  //     callduration: "",
  //     listen: "Listen",
  //     whisper: "whisper",
  //     forcelogout: "Logout",
  //   },
  //   {
  //     id: 74,
  //     queuename: "Support",
  //     user: "Priya",
  //     status: "On Call",
  //     pausecode: "",
  //     extension: "7092",
  //     callduration: "00:31:44",
  //     listen: "Listen",
  //     whisper: "whisper",
  //     forcelogout: "Logout",
  //   },
  //   {
  //     id: 55,
  //     queuename: "English",
  //     user: "Pooja",
  //     status: "Ready",
  //     pausecode: "",
  //     extension: "7136",
  //     callduration: "",
  //     listen: "Listen",
  //     whisper: "whisper",
  //     forcelogout: "Logout",
  //   },
  //   {
  //     id: 66,
  //     queuename: "Arabic",
  //     user: "Angrez",
  //     status: "Ready",
  //     pausecode: "",
  //     extension: "7140",
  //     callduration: "",
  //     listen: "Listen",
  //     whisper: "whisper",
  //     forcelogout: "Logout",
  //   },
  // ];

  useEffect(() => {
    // Call API immediately when the component mounts
    fetchingCallData();

    // Set interval to call the API every 8 seconds
    const timer = setInterval(() => {
      fetchingCallData();
    }, 8000);

    // Clean up the interval on component unmount
    return () => clearInterval(timer);
  }, []); // Empty dependency array to run only once when the component mounts

  const fetchingCallData = async () => {
    setLoader(true);
    try {
      const response = await axios.get(
        `https://tunnel.unifyweb.net/click2call/QueueRealTimeReport.php`
      );
      console.log(response?.data, "response");
      setGetCallDetails(response?.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error fetching call data:", error);
    }
  };
  const currentExt = localStorage.getItem("extension");
  const activateListen = async (row) => {
    setLoader(true);
    try {
      const response = await axios.get(
        `http://usunifyweb.dyndns.org/click2call/Transfer.php?Number=${row.extension}&TransferNumber=${currentExt}&Type=listen&SecKey=2c41c5e95e052e9d03f423e9f578b9b3`
      );
    } catch (error) {
      setLoader(false);
      console.error("Error in activating the listen", error);
    }
  };

  const activateWhisper = async (row) => {
    setLoader(true);
    try {
      const response = await axios.get(
        `http://usunifyweb.dyndns.org/click2call/Transfer.php?Number=${row.extension}&TransferNumber=${currentExt}&Type=whisper&SecKey=2c41c5e95e052e9d03f423e9f578b9b3`
      );
    } catch (error) {
      setLoader(false);
      console.error("Error in activating the listen", error);
    }
  };

  // const membersData = Object.entries(getCallDetails).reduce<{ [key: string]: string[] }>((result, [key, value]) => {
  //   if (value.Members) {
  //     result[key] = value.Members;
  //     // result[key] = Object.values(value.Members).map(member => member.AgentName);
  //   }
  //   return result;
  // }, {});

  console.log(getCallDetails, "getCalldetaills");

  const membersData = Object.entries(getCallDetails).reduce(
    (result, [key, value]) => {
      if (value.Members) {
        result[key] = value.Members;
      }
      return result;
    },
    {}
  );

  // console.log(membersData, "mememmm")

  // const result = Object.entries(membersData).map((el, ind) => console.log(el, "ellll"))

  const rowss: RowType[] = [];
  let index = 0; 

  // Iterate over the membersData
  Object.entries(membersData).forEach(([queueName, queueData]) => {
    Object.entries(queueData).forEach(([memberId, memberDetails]) => {
      index++
      // Create a new row for each member

      console.log(memberDetails, "memberDetails.name");
      const row = {
        id: index, 
        queuename: queueName,
        user: memberDetails.AgentName, // This should already be a string (from the members data)
        status: memberDetails?.Status, // Placeholder status
        pausecode: memberDetails.PauseCode, // Placeholder pausecode
        extension: memberDetails.Exten, // Placeholder extension (you may want to customize this)
        callduration: "", // Placeholder call duration
        listen: "Listen", // Placeholder for listen action
        whisper: "whisper", // Placeholder for whisper action
        forcelogout: "Logout", // Placeholder for logout action
      };


      // In id I want index + 1
      // Add the row to the rows array
      rowss.push(row);
    });
  });

  console.log(rowss, "SDcsdcdscsc");

  // Got this output. Now I want to map them dynamically as I dont't know how many Members it can get

  return (
    <div id="kt_app_content_container" className="app-container mx-5">
      {/*begin::Dashboard*/}
      <div className="card card-flush">
        {/*begin::Card header*/}
        <div className="card-header align-items-center py-1 my-1 gap-2 gap-md-5">
          {/*begin::Card title*/}
          <div className="card-title">
            {/*begin::Search*/}
            <div className="d-flex align-items-center position-relative my-1">
              <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
              <h2>Telephony Dashboard</h2>
            </div>
            {/*end::Search*/}
          </div>
          {/* <TelephonyFrontend /> */}
          {/*end::Card title*/}

          {/*begin::Card toolbar*/}
          <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
            <div className="w-100 mw-200px" style={{ whiteSpace: "nowrap" }}>
              <CustomDate dateRange={dateRange} setDateRange={setDateRange} />
            </div>
            <div className="w-100 mw-150px">
              {/*begin::Select2*/}
              <select
                className="form-select select2-hidden-accessible"
                data-control="select2"
                data-hide-search="true"
                data-placeholder="Status"
                data-kt-ecommerce-product-filter="status"
                data-select2-id="select2-data-9-cpv9"
                tabIndex={-1}
                aria-hidden="true"
                data-kt-initialized="1"
              >
                <option data-select2-id="select2-data-11-qb6b"></option>
                <option value="select queue" selected>
                  Select Queue
                </option>
                <option value="queue 01">Queue 01</option>
                <option value="queue 02">Queue 02</option>
                <option value="queue 03">Queue 03</option>
              </select>
              {/*end::Select2*/}
            </div>
            <div className="w-100 mw-150px">
              {/*begin::Select2*/}
              <select
                className="form-select select2-hidden-accessible"
                data-control="select2"
                data-hide-search="true"
                data-placeholder="Status"
                data-kt-ecommerce-product-filter="status"
                data-select2-id="select2-data-9-cpv9"
                tabIndex={-1}
                aria-hidden="true"
                data-kt-initialized="1"
              >
                <option data-select2-id="select2-data-11-qb6b"></option>
                <option value="team" selected>
                  Select Team
                </option>
                <option value="team 01">Team 01</option>
                <option value="team 02">Team 02</option>
                <option value="team 03">Team 03</option>
              </select>
              {/*end::Select2*/}
            </div>
            <div className="w-100 mw-150px">
              {/*begin::Select2*/}
              <select
                className="form-select select2-hidden-accessible"
                data-control="select2"
                data-hide-search="true"
                data-placeholder="Status"
                data-kt-ecommerce-product-filter="status"
                data-select2-id="select2-data-9-cpv9"
                tabIndex={-1}
                aria-hidden="true"
                data-kt-initialized="1"
              >
                <option data-select2-id="select2-data-11-qb6b"></option>
                <option value="select queue" selected>
                  Individual
                </option>
                <option value="queue 01">Botgo</option>
                <option value="queue 02">Circle One</option>
              </select>
              {/*end::Select2*/}
            </div>
          </div>
          {/*end::Card toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body pt-0">
          <section className="section dashboard">
            <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
              {/*<!-- Start RTM cardset -->*/}
              <div className="col-lg-9 d-flex flex-column justify-content-center">
                <div className="row row-cols-lg-3">
                  {statusCards.map((sc, index) => (
                    <div className="col">
                      <div
                        className="card d-flex align-items-center my-2 text-dark"
                        style={boxStyle(index)}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={() => handleMouseLeave(index)}
                        onClick={() => handleMouseClick(index)}
                      >
                        <div className="card-body d-flex">
                          <i
                            className={`text-dark fa fa-solid ${sc.iconClass} fa-2x mx-6 pt-2`}
                            style={{ transform: "rotate(360deg)" }}
                          ></i>
                          <div className="d-flex flex-column align-items-center">
                            <span className="fw-bold fs-2 d-block">
                              {sc.label}
                            </span>
                            <span className="fw-semibold fs-1">{sc.count}</span>
                          </div>
                          <i
                            className={`bi ${sc.arrowClass} fa-2x text-dark`}
                          ></i>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/*<!-- End RTM cardset -->*/}

              {/*<!-- Start distribution chart-->*/}
              <div className="col-lg-3">
                <div className="row">
                  <div className="col-xxl-12 col-md-12">
                    <div className="card info-card sales-card py-5">
                      <PieChartRTM />
                    </div>
                  </div>
                </div>
              </div>
              {/*<!-- End distribution chart-->*/}
            </div>
            <div className="h6 mt-3">Real time agent status</div>

            {loader ? (
              <LoadingSpinner />
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="card mt-2">
                    <div className="card-body">
                      <CardContent style={{ width: "100%" }}>
                        <div>
                          <DataGrid
                            rows={rowss}
                            columns={columns}
                            initialState={{
                              pagination: {
                                paginationModel: {
                                  pageSize: 10,
                                },
                              },
                            }}
                            pageSizeOptions={[10]}
                            checkboxSelection
                            disableRowSelectionOnClick
                          />
                        </div>
                      </CardContent>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row mt-2">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-row-bordered table-row-gray-300  align-middle gs-10">
                        <thead className="table-light fw-bolder text-center">
                          <tr className="bg-primary">
                            <th scope="col">Offerred</th>
                            <th scope="col">Answered</th>
                            <th scope="col">Abandoned</th>
                            <th scope="col">Service Level %</th>
                            <th scope="col">Abandoned %</th>
                          </tr>
                        </thead>
                        <tbody className="table-group-divider text-center">
                          <tr>
                            <th scope="row">32</th>
                            <td>21</td>
                            <td>11</td>
                            <td>65</td>
                            <td>35</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Dashboard*/}
    </div>
  );
};

export default RTMDashboard;
