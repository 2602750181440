import React from "react";
import GaugeChart from "react-gauge-chart";

interface IProps {
}

  const AverageResolutionTime: React.FC<IProps> = () => {
  // const averageResolutionTime = data?.ticket?.average_response_time ?? 0;
  const goalTime = 480;

  return (
    <div className="card card-flush border-0 mb-8" style={{ height: "200px", position: "relative" }}>
      {/*begin::Header*/}
      <div className="d-flex align-items-center card-header">
        {/*begin::Title*/}

        <span className="fs-2 fw-bold text-gray-800 me-2 lh-1 ls-n2">
          Average resolution time
        </span>
      </div>
      {/*end::Header*/}
      {/*begin::Body*/}
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
        <div style={{ width: 250, height: 200 }}>
          <GaugeChart
            id="gauge-chart1"
            nrOfLevels={6}
            percent={0.5}
            hideText={false}
            textColor="transparent"
            needleBaseColor="#F35725"
            arcPadding={0.015}
            cornerRadius={0}
            arcWidth={0.19}
            formatTextValue={(value) => `${value}%`}
            needleColor="#F35725"
            colors={["#FCD9B2", "#FBC192", "#F9A974", "#F79054", "#F57732", "#F35825"]}
            arcsLength={[0.05, 0.05, 0.05, 0.05, 0.05, 0.15]}
          />
        </div>
      </div>
      {/*end::Body*/}
    </div>
  );
}

export default AverageResolutionTime;