import React, { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "../../../reusableComponent/CapitalLetter";
import { Checkbox, FormControlLabel } from "@mui/material";
import {
  useCampaignTeamsDataQuery,
  useCampaignTeamsQuery,
  useCampaignWorflowNameQuery,
  useWorkflowAssignMutation,
} from "../../../../Services/campaign/CampaignMapping";
import { toast } from "react-toastify";

const CampaignMapping = () => {
  const { data: teamsData } = useCampaignTeamsQuery({});
  const [theTeams, setTheTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState<Number>();

  const { data: campaignTeamsData, refetch: refetchTheDispositions } =
    useCampaignTeamsDataQuery(selectedTeam, { skip: !selectedTeam });

  const { data: campaignWorkflow } = useCampaignWorflowNameQuery(selectedTeam, {
    skip: !selectedTeam,
  });

  const userIds = Array.from(
    new Set(
      campaignTeamsData?.results?.flatMap((item) =>
        item.users?.map((user) => user?.id)
      )
    )
  );

  const [triggerWorkflowAssign] = useWorkflowAssignMutation();

  const [selectAll, setSelectAll] = useState(false);
  const [theworkflows, setTheWorkflows] = useState<any>([]);
  const [workflowSelected, setWorkflowSelected] = useState<number>();

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  useEffect(() => {
    if (workflowSelected && campaignWorkflow && campaignWorkflow.results) {
      const selectedWorkflow = campaignWorkflow.results.find(
        (workflow: any) => workflow.id === workflowSelected
      );
      if (selectedWorkflow) {
        setStartTime(
          selectedWorkflow.campaign.start_time
            ? new Date(
              Date.parse(selectedWorkflow.campaign.start_time)
            ).toISOString()
            : null
        );
        setEndTime(
          selectedWorkflow.campaign.end_time
            ? new Date(
              Date.parse(selectedWorkflow.campaign.end_time)
            ).toISOString()
            : null
        );
      }
    }
  }, [workflowSelected, campaignWorkflow]);


  const [cardsData, setCardData] = useState(null);
  const [isAnyCardSelected, setIsAnyCardSelected] = useState(false);

  const handleFirstDropdownChange = (event: any) => {
    setSelectedTeam(Number.parseInt(event.target.value));
  };

  useEffect(() => {
    setTheTeams(teamsData);
  }, [teamsData]);

  useEffect(() => {
    campaignWorkflow && setTheWorkflows(campaignWorkflow.results);
  }, [campaignWorkflow]);

  useEffect(() => {
    setCardData(
      campaignTeamsData?.results?.map((disposition: any) => ({
        id: disposition.id,
        label: capitalizeFirstLetter(disposition.name),
        isChecked: false, // Initialize isChecked to false
        disposition: disposition,
      }))
    );
  }, [campaignTeamsData]);

  useEffect(() => {
    if (campaignTeamsData && campaignWorkflow) {
      const updatedCardsData = campaignTeamsData.results.map(
        (disposition: any) => {
          const campaign = campaignWorkflow.results?.find(
            (campaign: any) => campaign.id === disposition.campaign_name
          );

          const matchingCampaigns = campaignWorkflow.results?.filter(
            (campaign: any) => disposition.id === campaign.campaign?.id
          ) || [];
          return {
            id: disposition.id,
            label: capitalizeFirstLetter(disposition.name),
            campaignName: campaign ? campaign.name : "",
            isChecked: false, // Initialize isChecked to false
            option: matchingCampaigns,
            disposition: disposition
          };
        }
      );
      setCardData(updatedCardsData);
    }
  }, [campaignTeamsData, campaignWorkflow]);

  useEffect(() => {
    const isAnySelected = cardsData?.some((card) => card.isChecked);
    setIsAnyCardSelected(isAnySelected);
  }, [cardsData]);


  const handleOptionChange = (cardIndex, selectedOptionValue, selectedIndex) => {

    let payloadForUpdate = {
      campaign_name: selectedOptionValue === "" ? "" : `${cardsData[cardIndex]?.option[selectedIndex]?.id}`,
      end_time: cardsData[cardIndex]?.disposition?.end_time,
      start_time: cardsData[cardIndex]?.disposition?.start_time,
      name: cardsData[cardIndex]?.disposition?.name,
      team: cardsData[cardIndex]?.disposition?.team?.id,
      users: cardsData[cardIndex]?.disposition?.users?.map(user => user.id) // Extracting only the 'id' values
    }


    triggerWorkflowAssign({
      body: payloadForUpdate,
      disposition: cardsData[cardIndex]?.disposition?.id,
    }).then((res: any) => {
      if (res.data) {
        toast.success(`Successful campaign mapping`);
        setSelectAll(false); // Add this line to reset the selectAll state
        setCardData(null);
        refetchTheDispositions();
      } else {
        toast.error(`Error mapping dispositions`);
        // Reset cardsData to its initial value with all checkboxes unchecked


      }
    });

  };

  return (
    <div className="card-toolbar flex-row-fluid justify-content-end gap-5 mx-5">
      <div className="row">
        <div className="col-2 mw-200px">
          <label>Select team</label>
          <select
            className="form-select select2-hidden-accessible"
            onChange={handleFirstDropdownChange}
          >
            <option value="" selected disabled>
              Select team
            </option>
            {theTeams &&
              theTeams.map((team, index) => (
                <option key={index} value={team.id}>
                  {team.name}
                </option>
              ))}
          </select>
        </div>

        {selectedTeam && isAnyCardSelected && (
          <div className="col-2 mw-200px">
            <label>Select campaign</label>
            <select
              className="form-select"
              onChange={(e) => setWorkflowSelected(Number.parseInt(e.target.value))}
            >
              <option value="" selected disabled>
                Select a campaign
              </option>
              {theworkflows &&
                theworkflows.map((theworkflow: any) => (
                  <option value={theworkflow.id} key={theworkflow.id}>
                    {theworkflow.name}
                  </option>
                ))}
            </select>
          </div>
        )}
      </div>

      {selectedTeam && (
        <div className="row w-100">
          <div className="input-group-text bg-secondary mt-5 mx-2">
            <div className="col-4 text-start">
              <strong>Campaign mapping</strong>
            </div>
            <div className="col-4 text-center">
              <strong>Workflow</strong>
            </div>
            <div className="col-4 text-center">
              <strong>Mapped to workflow</strong>
            </div>
          </div>
        </div>
      )}

      <div className="row mt-5">
        {cardsData &&
          cardsData.map((card, index) => (
            selectedTeam && (
              <div key={index} className="col-12 mt-2">
                <div
                  className={`card p-4 ${card.label === "label" ? "bg-secondary" : ""}`}
                  style={{ minHeight: "50px" }}
                >
                  <div className="row align-items-center">
                    <div className="col-4 text-start">
                      <span className="h6">{card.label}</span>
                    </div>

                    <div className="col-4 text-center">{card.campaignName}</div>

                    <div className="col-4 text-center">
                      <select
                        className="form-select mx-auto"
                        style={{ width: "50%" }}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          const selectedIndex = card.option.findIndex(option => option.name === selectedValue);
                          handleOptionChange(index, selectedValue, selectedIndex); // Store both value and index
                        }}
                        value={card.campaignName || ''} // Display the selected value
                      >
                        <option value="">Select</option>

                        {card.option && card.option.map((option, optionIndex) => (
                          <option key={optionIndex} value={option.name}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )
          ))}
      </div>
      {!cardsData?.length && (
        <div className="container d-flex justify-content-center align-items-center">
          <p className="d-flex flex-column align-items-center">
            <span className="fs-2">No data to show</span>
            <span>
              <i className="bi bi-box2-fill fs-2x"></i>
            </span>
          </p>
        </div>
      )}


    </div>

  );
};

export default CampaignMapping;
