import React from "react";
import { toast } from "react-toastify";
import LoadingSpinner from "../../components/LoadingSpinner";
import EmptyRows from "../../components/reusableComponent/EmptyRows";
import { Pagination, PaginationItem } from "@mui/material";

const Activate = ({
  usersData,
  getRandomColor,
  getInitials,
  capitalizeFirstLetter,
  formatDate,
  isResetPassModal,
  isEditModal,
  isDeleteModal,
  isDeactivateAccountModal,
  handleReleaseSessionClick,
  currentPage,
  totalPages,
  setCurrentPage,
  sortingLoading,
  usersLoading,
}) => {
  const handleReleaseSession = (id) => {
    try {
      handleReleaseSessionClick(id);
      toast.success("User logout successfully!");
    } catch (error) {
      toast.error("Failed to release session.");
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <div className="row">
        <div className="col">
          {usersLoading || sortingLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {usersData?.length ? (
                <>
                  {usersData?.map((user: any, index: any) => (
                    <div className="card mt-2 my-1 p-4" key={index}>
                      <div className="row align-items-start">
                        <div className="col text-start">
                          <div className="symbol symbol-25px symbol-circle fs-2 me-3">
                            <span
                              className={`symbol-label ${getRandomColor()} text-inverse-warning fw-bold`}
                            >
                              {getInitials(user.username)}
                            </span>
                          </div>
                          <label htmlFor="">
                            {capitalizeFirstLetter(user.username.split("@")[0])}
                          </label>
                        </div>
                        <div className="col text-start">
                          <label
                            htmlFor=""
                            style={{
                              paddingLeft: "10px",
                            }}
                          >
                            {user.id}
                          </label>
                        </div>
                        <div className="col text-start">
                          <label htmlFor="">{formatDate(user.created)}</label>
                        </div>
                        <div className="col text-start">
                          <label htmlFor="">
                            {user.last_updated
                              ? formatDate(user.last_updated)
                              : ""}
                          </label>
                        </div>
                        <div className="col text-start">
                          <label htmlFor="">
                            {capitalizeFirstLetter(user.role)}
                          </label>
                        </div>
                        <div className="col text-start">
                          <label htmlFor="">
                            {user?.branch_user === true ? (
                              <span
                                style={{
                                  padding: "0 20px",
                                  color: "green",
                                }}
                              >
                                Yes
                              </span>
                            ) : (
                              <span
                                style={{
                                  padding: "0 20px",
                                  color: "red",
                                }}
                              >
                                No
                              </span>
                            )}
                          </label>
                        </div>
                        <div className="col text-start d-flex justify-content-around">
                          <button
                            className="text-hover-danger border-0 bg-white me-2"
                            title="Reset password"
                            onClick={() => isResetPassModal(user)}
                          >
                            <i className="bi bi-person-lock text-dark fs-4"></i>
                          </button>
                          <button
                            className="text-hover-primary border-0 bg-white me-2"
                            title="Edit"
                            onClick={() => isEditModal(user)}
                          >
                            <i className="bi bi-pencil text-dark fs-4"></i>
                          </button>
                          <button
                            className="text-hover-danger border-0 bg-white me-2"
                            title="Delete"
                            onClick={() => isDeleteModal(user)}
                          >
                            <i className="bi bi-trash text-dark fs-4"></i>
                          </button>

                          <button
                            className="text-hover-primary border-0 bg-white me-2"
                            title="Deactivate account"
                            onClick={() => isDeactivateAccountModal(user)}
                          >
                            <i className="bi bi-person-slash text-dark fs-4"></i>
                          </button>

                          <button
                            className="text-hover-primary border-0 bg-white me-2"
                            title="Release session"
                            onClick={() => handleReleaseSession(user.id)}
                          >
                            <i className="bi bi-lock text-dark fs-4"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <EmptyRows value={""} />
              )}
            </>
          )}
        </div>
      </div>

      {usersData?.length > 0 ? (
        <>
          {/* Render your data here */}

          {/* MUI Pagination */}
          <Pagination
            sx={{
              display: "flex",
              justifyContent: "end",
              color: "blue",
            }}
            count={totalPages} // Total number of pages
            page={currentPage} // Current page number
            onChange={handlePageChange} // Page change handler
            color="primary" // Adjust as needed
            renderItem={(item) => {
              // Custom rendering logic for Pagination items
              if (item.type === "page") {
                if (
                  (currentPage === 1 && item.page <= 3) || // Show first 3 pages if on page 1
                  (currentPage === totalPages && item.page >= totalPages - 2) || // Show last 3 pages if on the last page
                  (item.page >= currentPage - 1 && item.page <= currentPage + 1) // Show current page and 1 sibling on each side
                ) {
                  return <PaginationItem {...item} />;
                }
                return null;
              }
              return <PaginationItem {...item} />;
            }}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Activate;
