import React, { useState } from "react";
import WorkFlow from "../../components/settings/ticket/workflow";
import TicketMapping from "../../components/settings/ticket/ticketMapping";
import Notifications from "../../components/settings/ticket/notification";
import Scheduler from "../../components/settings/ticket/scheduler";
import SLA from "../../components/settings/ticket/sla";
import TicketFields from "../../components/settings/ticket/ticketFields/TicketFields";
import AccessControl from "../../components/settings/ticket/accessControl/AccessControl";

const TicketSettings = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const tabTitles = [
    "Workflow",
    "Ticket mapping",
    "Notification",
    "Access control",
    "Scheduler",
    "SLA",
    "Ticket fields",
  ];

  return (
    <div className="text-start" style={{height: "100%"}}>
      <div>
        <ul className="nav nav-stretch nav-line-tabs text-primary border-transparent d-flex mb-5">
          {tabTitles.map((title, index) => (
            <li className="nav-item" key={Math.random()}>
              <span
                className={`nav-link cursor-pointer fs-4 fw-bold ${
                  activeTabIndex === index ? "active fw-bolder" : ""
                }`}
                onClick={() => {
                  setActiveTabIndex(index);
                }}
                role="tab"
              >
                {title}
              </span>
            </li>
          ))}
        </ul>
      </div>

      {activeTabIndex === 0 && <WorkFlow />}

      <div className="scroll-y">
        {activeTabIndex === 1 && <TicketMapping />}
      </div>

      {activeTabIndex === 2 && <Notifications />}
      {activeTabIndex === 3 && <AccessControl />}


      {activeTabIndex === 4 && <Scheduler />}

      {activeTabIndex === 5 && <SLA />}

      {activeTabIndex === 6 && <TicketFields />}
    </div>
  );
};

export default TicketSettings;
