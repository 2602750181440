import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useEditParticularFieldDIDDataMutation } from "../../../../Services/Telephony/Telephony";

interface IPROPS {
    closeModal: any;
    refetch: any;
    didId: any;
}

const EditDID: React.FC<IPROPS> = ({ closeModal, refetch, didId }) => {
    const [name, setName] = useState("");
    const [nnumber, setNNumber] = useState("");
    const [code, setCode] = useState("");
    const [country, setCountry] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const [errors, setErrors] = useState({
        name: false,
        nnumber: false,
        code: false,
        country: false,
    });

    const [editDIDTrigger] = useEditParticularFieldDIDDataMutation();

    useEffect(() => {
        if (didId) {
            setName(didId?.name || "");
            setNNumber(didId?.phone_no || "");
            setCode(didId?.std_code || "");
            setCountry(didId?.country_code || "");
        }
    }, [didId]);

    const validateFields = () => {
        const newErrors = {
            name: !name,
            nnumber: !nnumber,
            code: !code,
            country: !country,
        };

        setErrors(newErrors);

        if (Object.values(newErrors).some((error) => error)) {
            toast.error("Please fill all required fields");
            return false;
        }
        return true;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!validateFields()) return; // Stop if validation fails

        const obja = {
            country_code: country,
            name: name,
            phone_no: nnumber,
            std_code: code,
        };

        try {
            const response = await editDIDTrigger({ body: obja, id: didId?.id });

            if (response?.data) {
                toast.success("Edited successfully");
                closeModal();
                refetch();
                setIsButtonDisabled(true);

                setTimeout(() => {
                    setIsButtonDisabled(false);
                }, 3000);
            } else if ("data" in response?.error) {
                const errorData = response?.error?.data as {
                    country_code?: string[];
                    name?: string[];
                    phone_no?: string[];
                    std_code?: string[];
                };
                const errorMessage =
                    errorData?.country_code?.[0] ||
                    errorData?.name?.[0] ||
                    errorData?.phone_no?.[0] ||
                    errorData?.std_code?.[0] ||
                    "An error occurred";
                toast.error(errorMessage);

                setIsButtonDisabled(true);

                setTimeout(() => {
                    setIsButtonDisabled(false);
                }, 3000);
            }
        } catch (error) {
            toast.error("An error occurred");
            setIsButtonDisabled(true);

            setTimeout(() => {
                setIsButtonDisabled(false);
            }, 3000);
        }
    };

    return (
        <div style={{display: "flex", flexDirection: "column", gap: "20px"}}>
            <div style={{display: "flex", flexDirection: "column", gap: "20px"}}>
                <div>
                    <label className="required">DID name</label>
                    <input
                        className="form-control"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                            setIsButtonDisabled(false)
                        }}
                        style={{
                            borderColor: errors.name ? "red" : "",
                        }}
                    />
                </div>
                <div>
                    <label className="required">DID number</label>
                    <input
                        className="form-control"
                        value={nnumber}
                        onChange={(e) => {
                            setNNumber(e.target.value)
                            setIsButtonDisabled(false)
                        
                        }}
                        style={{
                            borderColor: errors.nnumber ? "red" : "",
                        }}
                    />
                </div>
                <div>
                    <label className="required">Std code</label>
                    <input
                        className="form-control"
                        value={code}
                        onChange={(e) => {
                            setCode(e.target.value)
                            setIsButtonDisabled(false)
                        
                        }}
                        style={{
                            borderColor: errors.code ? "red" : "",
                        }}
                    />
                </div>
                <div>
                    <label className="required">Country code</label>
                    <input
                        className="form-control"
                        value={country}
                        onChange={(e) => {
                            setCountry(e.target.value)
                            setIsButtonDisabled(false)
                        
                        }}
                        style={{
                            borderColor: errors.country ? "red" : "",
                        }}
                    />
                </div>
            </div>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "end",
                }}
            >
                <button 
                type="reset"
                    className="btn btn-sm btn-white me-2"
                    onClick={closeModal}>
                    Cancel
                </button>
                <button
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    disabled={isButtonDisabled}
                >
                    Svae
                </button>
            </div>
        </div>
    );
};

export default EditDID;
