const TrailTab = () => {
  return (
    <div className="d-flex flex-column">
        <div className="ms-auto">
          <button className="btn btn-outline-primary"><i className="bi bi-arrow-bar-up"></i> Export</button>
        </div>
        <div className="table-responsive">
          <table className="table table-row-bordered table-row-gray-300 text-muted align-middle gs-10">
            <thead className="table-light fw-bolder">
              <tr className="bg-primary">
                <th scope="col">Date</th>
                <th scope="col">Time</th>
                <th scope="col">Updates</th>
                <th scope="col">Status</th>
                <th scope="col">Assignee</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              <tr>
                <th scope="row">16 Jun 21</th>
                <td>18:40</td>
                <td>Ticket Created</td>
                <td>New</td>
                <td>raj</td>
                <td> <span className="text-primary">New ticket created</span></td>
              </tr>
              <tr>
                <th scope="row">06/15/21</th>
                <td>14:25</td>
                <td>Field Updated</td>
                <td>Contacted</td>
                <td>Shan</td>
                <td>Contact No-987643210<i className="bi bi-arrow-right-short"></i><span className="text-primary">9643210789</span>
                  <span className="d-block">Emailid: archana@gmail.com <i className="bi bi-arrow-right-short"></i></span>
                </td>
              </tr>
              <tr>
                <th scope="row">06/15/21</th>
                <td>14:25</td>
                <td>Field Updated</td>
                <td>Interested</td>
                <td>Shan</td>
                <td>Emailid: archana@gmail.com<i className="bi bi-arrow-right-short"></i>
                  <span className="text-primary d-block">archana@bluewhirl.io, <button className="btn btn-link fw-semibold fs-7 py-0">view more</button></span>
                </td>
              </tr>
              <tr>
                <th scope="row">06/15/21</th>
                <td>14:25</td>
                <td>Field Updated</td>
                <td>Interested</td>
                <td>Shan</td>
                <td>Emailid: archana@gmail.com<i className="bi bi-arrow-right-short"></i>
                  <span className="text-primary d-block">archana@bluewhirl.io, <button className="btn btn-link fw-semibold fs-7 py-0">view more</button></span>
                </td>
              </tr>
              <tr>
                <th scope="row">06/15/21</th>
                <td>14:25</td>
                <td>Field Updated</td>
                <td>Interested</td>
                <td>Shan</td>
                <td>Emailid: archana@gmail.com<i className="bi bi-arrow-right-short"></i>
                  <span className="text-primary d-block">archana@bluewhirl.io, <button className="btn btn-link fw-semibold fs-7 py-0">view more</button></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  )
}

export default TrailTab
