import { format } from "date-fns";
import React from "react";
import ReactApexChart from "react-apexcharts";

const Sent = ({ intervalData }) => {
  // Ensure intervalData.results exists and contains data
  const emailResults = intervalData?.results || [];

  // Get the hours for the x-axis (assuming each day has the same hours)
  const hours = Array.from({ length: 24 }, (_, i) => i.toString());

  // Create series dynamically for each date
  const series = emailResults.map(result => ({
    name:  format(new Date(result.date), "dd-MM-yyyy"), // Use the date as the series name
    data: hours.map(hour => result.hourly_count[hour] || 0), // Map hourly counts to ensure all hours are included
  }));

  // Define chart options
  const options: any = {
    chart: {
      type: "line",
      height: 600,
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
        export: {
          svg: {
            filename: "sent_email",
          },
          png: {
            filename: "send_email",
          },
          csv: {
            filename: "sent_email",
          },
        },
      },
    },
    xaxis: {
      type: "category",
      categories: hours, // Use hours as x-axis categories
      title: {
        text: "Hour of Day",
        style: {
          fontWeight: "normal"
        
        }
      },
    },
    stroke: {
      curve: "smooth",
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
    },
    yaxis: {
      title: {
        text: "Sent Email Count",
        style: {
          fontWeight: "normal"
        }
      },
      labels: {
        formatter: function (val) {
          return Math.floor(val); // Ensure that only whole numbers are shown
        },
      },
      min: 0, // Ensure minimum is 
      forceNiceScale: true,
    },
    tooltip: {
      x: {
        show: true,
        formatter: function (val) {
          return `Hour: ${val}`;
        },
      },
    },
    colors: [
      "#FF5733", // Red-orange
      "#33FF57", // Green
      "#3357FF", // Blue
      "#F39C12", // Orange
      "#8E44AD", // Purple
      "#FFFF00", // Yellow
      "#3498DB", // Light Blue
    ],
  };

  return <ReactApexChart options={options} series={series} type="line" height={600} />;
};

export default Sent;
