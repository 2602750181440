import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import { useCreateCampaignTaskMutation } from "../../../Services/campign/CampaignDetail";
import { toast } from "react-toastify";
import { Loader } from "react-bootstrap-typeahead";

interface IPROPS {
    taskInfo: any;
    close: any;
    liftingTaskDataRefetch: any;
    campaignData: any;
}

const CreateTask: React.FC<IPROPS> = ({
    taskInfo,
    close,
    liftingTaskDataRefetch,
    campaignData,
}) => {
    const [haveValues, setHaveValues] = useState("");
    const [errorMessage, setErrorMessage] = useState(false);
    const [createTaskInfo, setCreateTaskInfo] = useState<{
        subject: string;
        attachments: { file_name: string; file: string }[];
        date: string;
        time: string;
        eventDate: string;
        EventDescription: string;
        Lead: string;
        reminderTime: string;
        StartTime: string;
        status: string;
    }>({
        subject: "",
        date: "",
        time: "",
        eventDate: "",
        EventDescription: "",
        Lead: "",
        reminderTime: "",
        StartTime: "",
        status: "",
        attachments: [],
    });
    const [checkBoxSelected, setCheckboxSelected] = useState(false);
    const [taskFormLoader, setTaskFormLoader] = useState(false);
    const [campaignUserData, setCampaignUserData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(""); // State for the selected user

    const reminderTimeArry = [
        { label: "5 mins prior to task", id: "00:05:00" },
        { label: "15 mins prior to task", id: "00:15:00" },
        { label: "30 mins prior to task", id: "00:30:00" },
        { label: "60 mins prior to task", id: "01:00:00" },
    ];

    const StatusArry = [
        { label: "Active", id: "active" },
        { label: "Re-Scheduled", id: "rescheduled" },
        { label: "Cancelled", id: "cancelled" },
        { label: "Done", id: "done" },
    ];

    const [createTaskTrigger, { status: createTaskStatus }] =
        useCreateCampaignTaskMutation();

    useEffect(() => {
        if (createTaskStatus === "pending") {
            setTaskFormLoader(true);
        } else {
            setTaskFormLoader(false);
        }
    }, [createTaskStatus]);

    useEffect(() => {
        if (campaignData?.results) {
            setCampaignUserData(campaignData?.results);
        }
    }, [campaignData?.results]);

    useEffect(() => {
        // Find if taskInfo.assignee.first_name matches any campaignUser.username
        const matchedUser = campaignData?.results
            ?.flatMap((user) => user?.campaign?.users)
            ?.find((campaignUser) => campaignUser?.username === taskInfo?.assignee?.first_name);

        // Set the selectedUser to matched username or fallback to taskInfo.assignee.first_name
        setSelectedUser(matchedUser?.username || taskInfo?.assignee?.first_name || "");
    }, [campaignData?.results, taskInfo?.assignee?.first_name]);

   const  userName = localStorage.getItem("circleone-username")

    // Handle selection change
    useEffect(() => {
        if (campaignUserData) {
          const matchingUser = campaignUserData
            ?.flatMap((user) => user?.campaign?.users || [])
            .find((campaignUser) => campaignUser?.username === userName);
    
          if (matchingUser) {
            setSelectedUser(matchingUser.id); // Set the matching user's ID as selected
          }
        }
      }, [campaignUserData, userName]);
    
      const handleSelectChange = (e) => {
        setSelectedUser(e.target.value);
      };

    console.log(userName, "selectette")

    const saveHandler = async (e) => {
        e.preventDefault();

        if (
            !createTaskInfo.subject ||
            !createTaskInfo.date ||
            !createTaskInfo.time ||
            !createTaskInfo.EventDescription ||
            !createTaskInfo?.status
        ) {
            toast.error("Fields are required");
            setErrorMessage(true);
            return;
        }

        const createTaskObj = {
            attachments: createTaskInfo?.attachments,
            created_by: selectedUser,
            event_date: createTaskInfo?.date,
            event_description: createTaskInfo?.EventDescription,
            lead: taskInfo?.id,
            reminder_time: createTaskInfo?.reminderTime,
            start_time: createTaskInfo?.time,
            status: createTaskInfo?.status,
            team: "",
            template_attachments: [],
            title: createTaskInfo?.subject,
        };

        const response = await createTaskTrigger(createTaskObj);

        try {
            if (response?.data) {
                toast.success("Added successfully");
                close();
                liftingTaskDataRefetch();
            } else if ("data" in response?.error) {
                const errorData = response?.error.data as {
                    status: string[];
                    message: string[];
                };
                console.log(errorData, "errorDatatta");
                const errorMessage =
                    errorData.message[0] || errorData.status[0];
                toast.error(errorMessage);
            }
        } catch (error) {}
    };

    const handleFileChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newFiles = Array.from(event.target.files as FileList); // Explicitly cast to FileList

        const filePromises = newFiles.map((file) => {
            return new Promise<{ file_name: string; file: string }>(
                (resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        if (e.target?.result) {
                            const fileData = {
                                file_name: file.name, // No error because file is of type File
                                file: (e.target.result as string).split(",")[1], // Extracting Base64 part
                            };
                            resolve(fileData);
                        } else {
                            reject(new Error("File reading error"));
                        }
                    };
                    reader.onerror = (error) => reject(error);
                    reader.readAsDataURL(file);
                }
            );
        });

        try {
            const fileDataArray = await Promise.all(filePromises);
            setCreateTaskInfo((prevState) => ({
                ...prevState,
                attachments: [...prevState.attachments, ...fileDataArray],
            }));
            const fileNames = fileDataArray
                .map((file) => file.file_name)
                .join(", ");
            setHaveValues(fileNames);
        } catch (error) {
            console.error(error);
            toast.error(
                "An error occurred while uploading files. Please try again."
            );
        }
    };

    console.log(createTaskInfo?.attachments, "sdcdsccd");

    // taskInfo.assignee.first_name

    const deleteImageHandler = (index: number) => {
        setCreateTaskInfo((prevState) => {
            const updatedAttachments = prevState.attachments.filter(
                (_, i) => i !== index
            );

            // Update `haveValues` with the new list of file names
            const updatedFileNames = updatedAttachments
                .map((file) => file.file_name)
                .join(", ");
            setHaveValues(updatedFileNames);

            return {
                ...prevState,
                attachments: updatedAttachments,
            };
        });
    };

    return (
        <div>
            <div style={{ display: "flex", width: "100%" }}>
                <span
                    style={{
                        width: "60%",
                        display: "flex",
                        justifyContent: "end",
                        fontSize: "17px",
                    }}
                >
                    Create task
                </span>
                <span
                    style={{
                        flex: "1",
                        display: "flex",
                        justifyContent: "end",
                    }}
                >
                    <i
                        className="bi bi-x"
                        style={{ fontSize: "20px", cursor: "pointer" }}
                        onClick={close}
                    ></i>
                </span>
            </div>
            <div
                style={{
                    marginTop: "20px",
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                }}
            >
                <div>
                    <label style={{ fontSize: "15px" }} className="required">
                        Subject
                    </label>
                    <input
                        value={createTaskInfo.subject}
                        className="form-control"
                        onChange={(e) => {
                            setCreateTaskInfo((prevState) => ({
                                ...prevState,
                                subject: e.target.value,
                            }));
                            setHaveValues(e.target.value);
                        }}
                    />
                    {errorMessage && !createTaskInfo.subject ? (
                        <p style={{ color: "red", margin: "0" }}>
                            Field is required
                        </p>
                    ) : (
                        ""
                    )}
                </div>
                <div>
            <label>Task owner</label>
            <select
                className="form-control"
                value={selectedUser} // Bind the state to the value of the select
                onChange={handleSelectChange} // Update state on change
            >
                {/* Map over all users in the campaign data */}
                {campaignUserData?.flatMap((user, index) =>
                    user?.campaign?.users?.map((campaignUser, userIndex) => (
                        <option
                        key={`${index}-${userIndex}`}
                        value={campaignUser?.id || ""}
                        >
                            {campaignUser?.username || ""}
                        </option>
                    ))
                )}
            </select>
        </div>
                <div>
                    <label style={{ fontSize: "15px" }} className="required">
                        Date
                    </label>
                    <input
                        type="date"
                        value={createTaskInfo.date}
                        className="form-control"
                        onChange={(e) => {
                            setCreateTaskInfo((prevState) => ({
                                ...prevState,
                                date: e.target.value,
                            }));
                            setHaveValues(e.target.value);
                        }}
                    />
                    {errorMessage && !createTaskInfo.date ? (
                        <p style={{ color: "red", margin: "0" }}>
                            Field is required
                        </p>
                    ) : (
                        ""
                    )}
                </div>
                <div>
                    <label style={{ fontSize: "15px" }} className="required">
                        Time
                    </label>
                    <input
                        type="time"
                        className="form-control"
                        value={createTaskInfo.time}
                        onChange={(e) => {
                            setCreateTaskInfo((prevState) => ({
                                ...prevState,
                                time: e.target.value,
                            }));
                            setHaveValues(e.target.value);
                        }}
                    />
                    {errorMessage && !createTaskInfo.time ? (
                        <p style={{ color: "red" }}>Field is required</p>
                    ) : (
                        ""
                    )}
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                    <input
                        type="checkbox"
                        onChange={() => setCheckboxSelected(!checkBoxSelected)}
                    />
                    <label>Remind at</label>
                </div>
                {checkBoxSelected && (
                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                        }}
                    >
                        <div>
                            <label style={{ fontSize: "15px" }}>
                                Select reminder minute
                            </label>
                            <select
                                className="form-control"
                                value={createTaskInfo?.reminderTime}
                                onChange={(e) => {
                                    setCreateTaskInfo((prevState) => ({
                                        ...prevState,
                                        reminderTime: e.target.value,
                                    }));
                                    setHaveValues(e.target.value);
                                }}
                            >
                                {reminderTimeArry?.map((time, index) => (
                                    <option value={time.id} key={time.id}>
                                        {time?.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
                <div>
                    <label style={{ fontSize: "15px" }} className="required">
                        Select status
                    </label>
                    <select
                        className="form-control"
                        value={createTaskInfo?.status}
                        onChange={(e) => {
                            setCreateTaskInfo((prevState) => ({
                                ...prevState,
                                status: e.target.value,
                            }));
                            setHaveValues(e.target.value);
                        }}
                    >
                        <option selected disabled value="">
                            Select status
                        </option>
                        {StatusArry?.map((status, index) => (
                            <option value={status?.id} key={status?.id}>
                                {status?.label}
                            </option>
                        ))}
                    </select>
                    {errorMessage && !createTaskInfo.status ? (
                        <p style={{ color: "red", margin: "0" }}>
                            Field is required
                        </p>
                    ) : (
                        ""
                    )}
                </div>
                <div>
                    {/* <div> */}
                    <ReactQuill
                        theme="snow"
                        value={createTaskInfo?.EventDescription}
                        onChange={(e) => {
                            setCreateTaskInfo((prevState) => ({
                                ...prevState,
                                EventDescription: e,
                            }));
                            setHaveValues(e);
                        }}
                        // modules={modules}
                        // formats={formats}
                        // style={{ height: '150px' }}
                    />
                    {/* </div> */}
                    {errorMessage && !createTaskInfo?.EventDescription ? (
                        <p style={{ color: "red", margin: "0" }}>
                            Field is required
                        </p>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    marginTop: "10px",
                }}
            >
                {createTaskInfo?.attachments.map((fileName, index) => (
                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                            background: "#e3e0e0",
                            borderRadius: "5px",
                            padding: "5px",
                        }}
                    >
                        <span>
                            <i
                                className="bi bi-x-circle"
                                onClick={() => deleteImageHandler(index)}
                                style={{ color: "black" }}
                            ></i>
                        </span>
                        <span>{fileName?.file_name}</span>
                    </div>
                ))}
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                    alignItems: "center  ",
                }}
            >
                <div style={{ display: "flex", gap: "20px" }}>
                    <span
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Campaign templates"
                    >
                        <i
                            className="fas fa-mail-bulk fs-3"
                            style={{ fontSize: "20px" }}
                        ></i>
                    </span>
                    <span
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Add attachments"
                    >
                        <input
                            type="file"
                            style={{
                                opacity: 0,
                                position: "absolute",
                                width: "50px",
                                border: "1px solid black",
                            }}
                            // value={createTaskInfo?.attachments}
                            multiple
                            onChange={handleFileChange}
                        />
                        <i
                            className="bi bi-paperclip fs-2"
                            style={{ fontSize: "20px" }}
                        ></i>
                    </span>
                </div>
                <div>
                    <button
                        className="btn btn-primary"
                        onClick={saveHandler}
                        disabled={!haveValues}
                    >
                        {taskFormLoader ? (
                            <>
                                Saving...
                                <Loader />
                            </>
                        ) : (
                            "Save"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateTask;
