import React, { useState } from "react";
// import ReactWordcloud from "react-wordcloud";

const WordCloud = () => {
    const [isFeatureAvailable, setIsFeatureAvailable] = useState(false); // Set this to true when the AI feature is ready
    const [words, setWords] = useState([
        { text: 'React', value: 100 },
        { text: 'JavaScript', value: 80 },
        { text: 'Component', value: 60 },
        { text: 'Library', value: 50 },
        { text: 'Wordcloud', value: 40 },
        { text: 'State', value: 30 },
        { text: 'Props', value: 20 },
        { text: 'useState', value: 10 },
        { text: 'HTML', value: 90 },
        { text: 'CSS', value: 70 },
        { text: 'Node.js', value: 65 },
        { text: 'Express', value: 55 },
        { text: 'MongoDB', value: 45 },
        { text: 'API', value: 35 },
        { text: 'JSON', value: 25 },
        { text: 'AJAX', value: 15 },
        // Add more words here...
        { text: 'Web', value: 85 },
        { text: 'Framework', value: 75 },
        { text: 'Database', value: 70 },
        { text: 'Server', value: 60 },
        { text: 'Client', value: 50 },
        { text: 'Frontend', value: 45 },
        { text: 'Backend', value: 40 },
        { text: 'UI', value: 35 },
        { text: 'UX', value: 30 },
        { text: 'API', value: 25 },
        { text: 'REST', value: 20 },
        { text: 'GraphQL', value: 15 },
        { text: 'Bootstrap', value: 10 },
        { text: 'Material UI', value: 5 },
        // Add more words as needed...
        { text: 'React', value: 100 },
        { text: 'JavaScript', value: 80 },
        { text: 'Component', value: 60 },
        { text: 'Library', value: 50 },
        { text: 'Wordcloud', value: 40 },
        { text: 'State', value: 30 },
        { text: 'Props', value: 20 },
        { text: 'useState', value: 10 },
        { text: 'HTML', value: 90 },
        { text: 'CSS', value: 70 },
        { text: 'Node.js', value: 65 },
        { text: 'Express', value: 55 },
        { text: 'MongoDB', value: 45 },
        { text: 'API', value: 35 },
        { text: 'JSON', value: 25 },
        { text: 'AJAX', value: 15 },
        { text: 'React', value: 100 },
        { text: 'JavaScript', value: 80 },
        { text: 'Component', value: 60 },
        { text: 'Library', value: 50 },
        { text: 'Wordcloud', value: 40 },
        { text: 'State', value: 30 },
        { text: 'Props', value: 20 },
        { text: 'useState', value: 10 },
        { text: 'HTML', value: 90 },
        { text: 'CSS', value: 70 },
        { text: 'Node.js', value: 65 },
        { text: 'Express', value: 55 },
        { text: 'MongoDB', value: 45 },
        { text: 'API', value: 35 },
        { text: 'JSON', value: 25 },
        { text: 'AJAX', value: 15 },
        { text: 'React', value: 100 },
        { text: 'JavaScript', value: 80 },
        { text: 'Component', value: 60 },
        { text: 'Library', value: 50 },
        { text: 'Wordcloud', value: 40 },
        { text: 'State', value: 30 },
        { text: 'Props', value: 20 },
        { text: 'useState', value: 10 },
        { text: 'HTML', value: 90 },
        { text: 'CSS', value: 70 },
        { text: 'Node.js', value: 65 },
        { text: 'Express', value: 55 },
        { text: 'MongoDB', value: 45 },
        { text: 'API', value: 35 },
        { text: 'JSON', value: 25 },
        { text: 'AJAX', value: 15 },
        { text: 'React', value: 100 },
        { text: 'JavaScript', value: 80 },
        { text: 'Component', value: 60 },
        { text: 'Library', value: 50 },
        { text: 'Wordcloud', value: 40 },
        { text: 'State', value: 30 },
        { text: 'Props', value: 20 },
        { text: 'useState', value: 10 },
        { text: 'HTML', value: 90 },
        { text: 'CSS', value: 70 },
        { text: 'Node.js', value: 65 },
        { text: 'Express', value: 55 },
        { text: 'MongoDB', value: 45 },
        { text: 'API', value: 35 },
        { text: 'JSON', value: 25 },
        { text: 'AJAX', value: 15 },
    ]);


    const options: any = {
        fontFamily: 'Arial',
        fontSizes: [15, 15],
        fontStyle: 'normal',
        fontWeight: 'bold',
        padding: 1,
        rotations: 2,
        rotationAngles: [-90, 0],
    };
    return (
        <div className="card card-flush h-lg-100">
            <div className="card-header pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="fs-2 fw-bold text-gray-800 me-2 lh-1 ls-n2">Word Cloud</span>
                </h3>
            </div>
            <div className="row align-items-center mx-0 w-100">
                {isFeatureAvailable ? (
                    // <ReactWordcloud words={words} options={options} />
                    <></>
                ) : (
                    <div className="coming-soon d-flex flex-column justify-content-center align-items-center w-100">
                        <img
                            className="mw-100 w-100px w-lg-200px mb-10 mb-lg-10"
                            src="https://preview.keenthemes.com/metronic8/demo39/assets/media/auth/agency.png"
                            alt="Coming Soon"
                        />
                        <h4 className="text-center ">Coming Soon</h4>
                        <p className="text-center mb-10">This feature is currently under development. Please check back later.</p>
                    </div>
                )}
            </div>
        </div>

    );
};

export default WordCloud;
