import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useSaveDespositionMutation } from "../../../../Services/general/General";
import { capitalizeFirstLetter } from "../../../reusableComponent/CapitalLetter";
import AddSubMenuModal from "../../General/DispositionSettingsModals/AddSubMenuModal";
import UpdateTopicModal from "../../General/DispositionSettingsModals/UpdateTopicModal";
import DeleteMenuModal from "../../General/DispositionSettingsModals/DeleteTopicModal";
import AddNewDispositionModal from "../../General/DispositionSettingsModals/AddNewDispositionModal";
import LoadingSpinner from "../../../LoadingSpinner";
import {
    useEmaildispositionQuery,
    useTeamsQuery,
} from "../../../../Services/settingsAPIs/CommonAPI";
import {
    useCreateTeleDispositionMutation,
    useGetAllDispositionDataQuery,
    useGetAllTeamsDataQuery,
    useGetParticularDispositionDataQuery,
} from "../../../../Services/Telephony/Telephony";
import AddNewTeleDispositionModal from "./AddNewTeleDispositionModal";
import AddSubTeleDisposition from "./AddSubTeleDisposition";
import UpdateTeleDisposition from "./UpdatedTeleDisposition";
import DeleteDispositionModal from "./DeleteDispositionModal";
import ReactModal from "react-modal";
import './styles.scss'

const DispositionSetting = () => {
    const { data: teamsfetched, isLoading } = useTeamsQuery({});
    const [theTeams, setTheTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState<number>();
    // const {
    //     data: dispositionData,
    //     isLoading: despositionLoading,
    //     refetch,
    // } = useEmaildispositionQuery(selectedTeam, { skip: !selectedTeam });

    const getTeamsId = localStorage.getItem("User_Id");

    const [storingDespositionData, setStoringDespositionData] = useState([]);
    const [selectedDisposition, setSelectedDisposition] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [expandedNodes, setExpandedNodes] = useState([]);
    const [currentNode, setCurrentNode] = useState(null);
    const [isNewDispositionModalOpen, setIsNewDispositionModalOpen] =
        useState(false);
    const [liftingAddData, SetLiftingAddDate] = useState([]);
    const [getTeams, setGetTeams] = useState([]);
    const [process, setProcess] = useState("");
    const [teamSelected, setTeamSelected] = useState(false);
    const [dispositionLoading, setDispositionLoading] = useState(false);

    const [trigger] = useCreateTeleDispositionMutation();

    const { data: teamsData } = useGetAllTeamsDataQuery(getTeamsId);
    const {
        data: dispositionDataa,
        isLoading: despositionLoading,
        refetch,
        status: dispositionStatus,
    } = useGetAllDispositionDataQuery(
        { id: selectedTeam, process: process },
        { skip: !selectedTeam || !process }
    );
    const { data: particularDIspositionData } =
        useGetParticularDispositionDataQuery(currentNode?.id, {
            skip: !currentNode?.id,
        });

    useEffect(() => {
        if (dispositionStatus === "pending") {
            setDispositionLoading(true);
        } else {
            setDispositionLoading(false);
        }
    }, [dispositionStatus]);

    useEffect(() => {
        if (teamsData) {
            setGetTeams(teamsData?.teams);
        }
    }, [teamsData]);

    const openDispositionModal = () => {
        setIsNewDispositionModalOpen(true);
    };

    const closeDispositionModal = () => {
        setIsNewDispositionModalOpen(false);
    };

    const openModal = (node) => {
        setCurrentNode(node);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentNode(null);
    };

    const openDeleteModal = (node) => {
        setCurrentNode(node);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
    };
    const openUpdateModal = (node) => {
        setCurrentNode(node);
        setIsUpdateModalOpen(true);
    };

    const closeUpdateModal = () => {
        setIsUpdateModalOpen(false);
    };

    const handleFirstDropdownChange = (event) => {
        setSelectedDisposition(event.target.value);
    };
    const toggleNode = (level: string) => {
        if (expandedNodes.includes(level)) {
            setExpandedNodes(expandedNodes.filter((n) => n !== level));
        } else {
            setExpandedNodes([...expandedNodes, level]);
        }
    };

    const updateModalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "30%",
        maxHeight: "450px",
        // height: "400px",
        minHeight: "400px",
        overflow: "hidden",
        bgcolor: "background.paper",
        border: "1px solid transparent",
        // boxShadow: 2,
        borderRadius: "5px",
        p: 4,
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "30%",
        // height: "30%",
        overflow: "hidden",
        bgcolor: "background.paper",
        border: "1px solid transparent",
        // boxShadow: 2,
        borderRadius: "5px",
        p: 4,
    };

    const Deletestyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "25%",
        // height: "30%",
        overflow: "hidden",
        bgcolor: "background.paper",
        border: "1px solid transparent",
        // boxShadow: 2,
        borderRadius: "5px",
        p: 4,
    };

    function alignFormat(arr: any[]) {
        function addLevels(subTopics: any, levelPrefix: string) {
            return subTopics.map((item: any, index: number) => {
                let newItem = { ...item, level: `${levelPrefix}${index}` };
                if (newItem.sub_topics && newItem.sub_topics.length > 0) {
                    newItem.sub_topics = addLevels(
                        newItem.sub_topics,
                        `${newItem.level}:`
                    );
                }
                return newItem;
            });
        }

        return arr.map((item, index) => {
            let newItem = { ...item, level: `${index}` };
            if (newItem.sub_topics && newItem.sub_topics.length > 0) {
                newItem.sub_topics = addLevels(newItem.sub_topics, `${index}:`);
            }
            return newItem;
        });
    }

    const removeLevels = (arr: any[]) => {
        return arr.map((item) => {
            let newItem = { ...item };
            delete newItem.level;
            if (newItem.sub_topics && newItem.sub_topics.length > 0) {
                newItem.sub_topics = removeLevels(newItem.sub_topics);
            }
            return newItem;
        });
    };

    const cleanedData = removeLevels(liftingAddData);
    const despositionSaveHandler = async (e) => {
        e.preventDefault();
        if (cleanedData.length < 1) {
            toast.error("Nothing to save");
            return;
        }

        try {
            const response = await trigger({
                body: cleanedData,
                id: selectedTeam,
            });
            if (response.data) {
                toast.success("Added successfully");
                refetch();
                SetLiftingAddDate([]);
            }
        } catch (error) {
            toast.error("An error occured");
        }
    };

    const handleTeamDropdownChange = (event: any) => {
        setSelectedTeam(Number(event.target.value));
        setTeamSelected(true);
    };

    useEffect(() => {
        if (dispositionDataa) {
            setStoringDespositionData(dispositionDataa?.results);
        }
    }, [dispositionDataa]);

    useEffect(() => {
        dispositionDataa?.results.length &&
            setStoringDespositionData(alignFormat(dispositionDataa.results));
    }, [dispositionDataa]);

    useEffect(() => {
        setTheTeams(teamsfetched);
    }, [teamsfetched]);

    const renderTreeNode = (node: any) => {
        const subDispositionNodes =
            node.sub_disposition?.map((sub: string, index: number) => ({
                level: `${node.level}-${index}`, // Create a unique level for each sub-node
                disposition: sub,
                parentId: node.id, // Attach parent's ID
                sub_disposition: [], // Sub-nodes won't have further sub-dispositions
            })) || [];

        return (
            <div key={node.level} className="ms-3">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <div
                        className="d-flex align-items-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => toggleNode(node.level)}
                    >
                        <i
                            className={`bi ${
                                node.sub_disposition?.length
                                    ? "bi-chevron-" +
                                      (expandedNodes.includes(node.level)
                                          ? "down"
                                          : "right")
                                    : "bi-dash"
                            } me-2`}
                        ></i>
                        <span>{capitalizeFirstLetter(node.disposition)}</span>
                    </div>
                    <div className="MOdal_class" style={{display: "flex"}} >
                        <button
                            className="btn text-hover-primary border-0 bg-white me-2"
                            onClick={() => openModal(node)}
                        >
                            <i className="bi bi-plus text-dark fs-2"></i>
                        </button>

                        <Modal
                            open={isModalOpen}
                            onClose={closeModal}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-description"
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0,0,0,0.05)", // Light black with 20% opacity
                                },
                            }}
                        >
                            <Box sx={{ ...style, position: "absolute" }}>
                                <IconButton
                                    aria-label="close"
                                    onClick={closeModal}
                                    sx={{
                                        position: "fixed",
                                        top: 16,
                                        right: 16,
                                        zIndex: 1301,
                                        background: "white",
                                    }}
                                >
                                    <GridCloseIcon />
                                </IconButton>
                                <Typography
                                    id="modal-title"
                                    variant="h6"
                                    component="h2"
                                    sx={{ mb: 2 }}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    Add sub disposition
                                </Typography>

                                <Box>
                                    <AddSubTeleDisposition
                                        closeModal={closeModal}
                                        setData={setStoringDespositionData}
                                        data={storingDespositionData}
                                        currentNode={currentNode}
                                        SetLiftingAddDate={SetLiftingAddDate}
                                        selectedTeam={selectedTeam}
                                        dispositionId={currentNode?.id}
                                        refetch={refetch}
                                    />
                                </Box>
                            </Box>
                        </Modal>

                        <button
                            className="btn text-hover-primary border-0 bg-white me-2"
                            onClick={() => openUpdateModal(node)}
                        >
                            <i className="bi bi-pencil-fill text-dark fs-4"></i>
                        </button>

                      

                        <Modal
                            open={isUpdateModalOpen}
                            onClose={closeUpdateModal}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-description"
                            // hideBackdrop={false}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0,0,0,0.05)", // Light black with 20% opacity
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    ...updateModalStyle,
                                    position: "absolute",
                                }}
                            >
                                <IconButton
                                    aria-label="close"
                                    onClick={closeUpdateModal}
                                    sx={{
                                        position: "fixed",
                                        top: 16,
                                        right: 16,
                                        zIndex: 1301,
                                        background: "white",
                                    }}
                                >
                                    <GridCloseIcon />
                                </IconButton>
                                <Typography
                                    id="modal-title"
                                    variant="h6"
                                    component="h2"
                                    // sx={{ mb: 2 }}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    Update Disposition
                                </Typography>

                                <Box>
                                    <UpdateTeleDisposition
                                        closeUpdateModal={closeUpdateModal}
                                        setData={setStoringDespositionData}
                                        data={storingDespositionData}
                                        currentNode={currentNode}
                                        SetLiftingAddDate={SetLiftingAddDate}
                                        selectedTeam={selectedTeam}
                                        refetch={refetch}
                                        dispositionId={currentNode?.id}
                                    />
                                </Box>
                            </Box>
                        </Modal>

                        <button
                            className="btn text-hover-danger border-0 bg-white"
                            onClick={() => openDeleteModal(node)}
                        >
                            <i className="bi bi-trash-fill text-dark fs-4"></i>
                        </button>

                        <Modal
                            open={isDeleteModalOpen}
                            onClose={closeDeleteModal}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-description"
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0,0,0,0.05)", // Light black with 20% opacity
                                },
                            }}
                        >
                            <Box sx={{ ...Deletestyle, position: "absolute" }}>
                                <IconButton
                                    aria-label="close"
                                    onClick={closeDeleteModal}
                                    sx={{
                                        position: "fixed",
                                        top: 16,
                                        right: 16,
                                        zIndex: 1301,
                                        background: "white",
                                    }} // Ensure it's above the modal content
                                >
                                    <GridCloseIcon />
                                </IconButton>
                                <Typography
                                    id="modal-title"
                                    variant="h6"
                                    component="h2"
                                    sx={{ mb: 2 }}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    {/* Delete Disposition */}
                                </Typography>

                                <Box>
                                    <DeleteDispositionModal
                                        closeDeleteModal={closeDeleteModal}
                                        data={storingDespositionData}
                                        setData={setStoringDespositionData}
                                        currentNode={currentNode}
                                        SetLiftingAddDate={SetLiftingAddDate}
                                        refetch={refetch}
                                        selectedTeam={selectedTeam}
                                    />
                                </Box>
                            </Box>
                        </Modal>
                    </div>
                </div>

                {/* {expandedNodes.includes(node.level) && (
        <div className="ms-4 border-start ps-2">
          {subDispositionNodes.map((subNode: any) => (
            <div key={subNode.level} className="d-flex align-items-center justify-content-between mb-2">
              <div
                className="d-flex align-items-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  openModal(subNode); // Pass the child node
                }}
              >
                <i className="bi bi-dash me-2"></i>
                <span>{capitalizeFirstLetter(subNode.disposition)}</span>
              </div>
            </div>
          ))}
        </div>
      )} */}

                {expandedNodes.includes(node.level) && (
                    <div className="ms-4 border-start ps-2">
                        {subDispositionNodes.map((subNode: any) => (
                            <div key={subNode.level}>
                                {/* Render sub-node without icons */}
                                <div className="d-flex align-items-center mb-2">
                                    <span>
                                        {capitalizeFirstLetter(
                                            subNode.disposition
                                        )}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="card-toolbar flex-row-fluid justify-content-end gap-5 p-5">
            <div style={{ display: "flex", gap: "20px" }}>
                <div className="w-100 mw-200px mb-5">
                    <select
                        className="form-select select2-hidden-accessible"
                        onChange={handleTeamDropdownChange}
                        value={selectedTeam}
                    >
                        <option value="" disabled selected>
                            Select team
                        </option>
                        {getTeams &&
                            getTeams?.map((team, index) => (
                                <option key={index} value={team.id}>
                                    {capitalizeFirstLetter(team?.name)}
                                </option>
                            ))}
                    </select>
                </div>

                <div className="w-100 mw-200px mb-5">
                    {/* <label>Select Process</label> */}
                    <select
                        value={process}
                        onChange={(e) => setProcess(e.target.value)}
                        className="form-control"
                        disabled={!teamSelected}
                    >
                        <option selected value="" disabled>
                            Select process
                        </option>
                        <option value="IB">Inbound</option>
                        <option value="OB">Outbound</option>
                    </select>
                </div>

                {selectedTeam && (
                    <div className="w-100 mw-200px">
                        <button
                            className="form-control form-control text-hover-primary fw-bold"
                            onClick={openDispositionModal}
                        >
                            <i className="bi bi-plus-lg me-2"></i>
                            <span className="ms-auto">New disposition</span>
                        </button>

                        <Modal
                            open={isNewDispositionModalOpen}
                            onClose={closeDispositionModal}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-description"
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.5)", 
                                },
                            }}
                        >
                            <Box sx={{ ...Deletestyle, position: "absolute" }}>
                                <IconButton
                                    aria-label="close"
                                    onClick={closeDispositionModal}
                                    sx={{
                                        position: "fixed",
                                        top: 16,
                                        right: 16,
                                        zIndex: 1301,
                                        background: "white",
                                    }} // Ensure it's above the modal content
                                >
                                    <GridCloseIcon />
                                </IconButton>
                                <Typography
                                    id="modal-title"
                                    variant="h6"
                                    component="h2"
                                    sx={{ mb: 2 }}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    Add disposition
                                </Typography>

                                <Box>
                                    <AddNewTeleDispositionModal
                                        closeModal={closeDispositionModal}
                                        setData={setStoringDespositionData}
                                        SetLiftingAddDate={SetLiftingAddDate}
                                        process={process}
                                        //   currentLevel={node.level}
                                        data={storingDespositionData}
                                        selectedTeam={selectedTeam}
                                        refetch={refetch}
                                    />
                                </Box>
                            </Box>
                        </Modal>
                    </div>
                )}
            </div>

            {/* DISPOSITION TABLE DATA */}
            {despositionLoading || dispositionLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    {storingDespositionData &&
                        storingDespositionData?.length > 0 && (
                            <div style={{ overflow: "auto" }}>
                                {storingDespositionData?.map((tree, index) => (
                                    <div
                                        key={index}
                                        className="card p-3 fs-5 mb-2"
                                    >
                                        {renderTreeNode(tree)}
                                    </div>
                                ))}
                            </div>
                        )}
                </>
            )}
        </div>
    );
};

export default DispositionSetting;
