import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useUpdateDynamicFieldsMutation,
  useUpdateUsernameMutation,
} from "../../../Services/ticket/TicketDetail";
import { useAuditTrailQuery } from "../../../Services/ticket/Audit-Trail";
import { toast } from "react-toastify";
import { useTicketDynamicFieldsQuery } from "../../../Services/ticket/CreateTicket";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Button,
} from "@mui/material";
import { PhoneInput } from "react-international-phone";
import { isFieldDisabled } from "../../reusableComponent/FieldDisabled";
import ReactModal from "react-modal";
import ConfirmationModal from "../../common/tabbedPanel/ConfirmationModal";

interface IProps {
  ticket: any;
  refetch: any;
  uuid: any;
  refetch1: any;
  refetchAuditTrailForwardedData:any
  setConfirmationModalOpen:any
  confirmationModalOpen:any
  handleButtonClick:any
}

const LeadInformation: React.FC<IProps> = ({
  ticket,
  refetch,
  uuid,
  refetch1,
  refetchAuditTrailForwardedData,
  setConfirmationModalOpen,
  confirmationModalOpen,
  handleButtonClick
}) => {
  const { data: ticketDynamicFields } = useTicketDynamicFieldsQuery({});
  const [dynamicFields, setDynamicFields] = useState<any>({});
  const [dynamicFieldData, setDynamicFieldData] = useState<any>([]);
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const { data } = useAuditTrailQuery(uuid);

  const [updateDynamicFields] = useUpdateDynamicFieldsMutation();



  const handleInputChange = (key: string, value: any) => {
    setDynamicFields((prevFields) => ({
      ...prevFields,
      [key]: value,
    }));
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<{ value: any }>,
    key: string
  ) => {
    setDynamicFields((prevFields) => ({
      ...prevFields,
      [key]: event.target.value,
    }));
  };

  useEffect(() => {
    if (ticket?.dynamic_fields) {
      setDynamicFields(ticket.dynamic_fields);
    }
  }, [ticket]);

  // const handleSave = async () => {
  //   if (isLoading) return; // Prevent saving if already loading
  //   setIsLoading(true);
  //   try {
  //     await updateDynamicFields({
  //       variables: {
  //         dynamic_fields: dynamicFields,
  //       },
  //       uuid,
  //     });
  //     toast.success("Dynamic fields updated successfully!");
  //     refetch();
  //     refetch1();
  //   } catch (error) {
  //     toast.error("Error updating dynamic fields:", error);
  //   } finally {
  //     setIsLoading(false); // Reset loading state
  //   }
  // };

  const handleSubmit = async () => {
    setIsLoading(true);

    const editedFields = Object.keys(dynamicFields).reduce((acc, key) => {
      const originalValue = ticket.dynamic_fields[key];
      const currentValue = dynamicFields[key];

      // Check if the field has been changed
      if (originalValue !== currentValue) {
          acc[key] = currentValue;
      }

      return acc;
  }, {});
    try {
      const response = await updateDynamicFields({
        variables: {
          dynamic_fields: editedFields,
        },
        uuid,
      });

      if (response.data) {
        setIsLoading(false); // Reset loading state
        toast.success("Dynamic fields updated successfully!");
        refetch();
        refetch1();
        refetchAuditTrailForwardedData()
        setConfirmationModalOpen(true)
      } else if (response.error) {
        // Extract error details from the response
        const { status, data }: any = response.error;

        if (data?.detail) {
          // Show detailed error messages dynamically
          data.detail.forEach((message) => {
            toast.error(` ${message}`); // Display each error message
          });
        } else {
          toast.error(
            `Error ${status}: Something went wrong. Please try again.`
          );
        }

        setIsLoading(false); 
      }
    } catch (error) {
      setIsLoading(false); // Reset loading state
      toast.error("Failed to assign. Please try again. "); // Show error toast
      console.error(error); // Log the error
    }
  };

  useEffect(() => {
    if (ticket?.action?.disposition?.workflow?.id) {
      const filterDataById = ticketDynamicFields?.filter(
        (name) =>
          name?.ticket_name === ticket?.action?.disposition?.workflow?.id
      );
      setDynamicFieldData(filterDataById);
    }
  }, [ticketDynamicFields, ticket?.action?.disposition?.workflow?.id]);

  const showInitialCheckbox = (dynamicFieldsIds, checkboxFiledsOption) => {
    let data = ["ss"];
    return data;
  };


  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false)
  }

  const customModalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "450px",
    },
};


  return (
    <div className="card card-body">
      <form>
        <div className="row row-cols-3">
          {dynamicFieldData?.map((field: any) => (
            <div key={field.label} className="col-xl-6 mb-3">
              <InputLabel htmlFor={`dynamic_fields.${field.label}`}>
                {field.label}
                {field.is_required && <span className="required"></span>}
              </InputLabel>
              {field.field_type === "text" && (
                <TextField
                  id={`dynamic_fields.${field.label}`}
                  variant="outlined"
                  disabled={isFieldDisabled(field?.is_editable)}
                  fullWidth
                  value={dynamicFields[field.label] || ""}
                  onChange={(e) =>
                    handleInputChange(field.label, e.target.value)
                  }
                  required={field.is_required}
                />
              )}
              {field.field_type === "email" && (
                <TextField
                  type="email"
                  id={`dynamic_fields.${field.label}`}
                  disabled={isFieldDisabled(field?.is_editable)}
                  variant="outlined"
                  fullWidth
                  value={dynamicFields[field.label] || ""}
                  onChange={(e) =>
                    handleInputChange(field.label, e.target.value)
                  }
                  required={field.is_required}
                />
              )}
              {field.field_type === "number" && (
                <TextField
                  type="number"
                  id={`dynamic_fields.${field.label}`}
                  disabled={isFieldDisabled(field?.is_editable)}
                  variant="outlined"
                  fullWidth
                  value={dynamicFields[field.label] || ""}
                  onChange={(e) =>
                    handleInputChange(field.label, e.target.value)
                  }
                  required={field.is_required}
                />
              )}
              {field.field_type === "date" && (
                <TextField
                  type="date"
                  id={`dynamic_fields.${field.label}`}
                  disabled={isFieldDisabled(field?.is_editable)}
                  variant="outlined"
                  fullWidth
                  value={dynamicFields[field.label] || ""}
                  onChange={(e) =>
                    handleInputChange(field.label, e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                  required={field.is_required}
                />
              )}
              {field.field_type === "time" && (
                <TextField
                  type="time"
                  id={`dynamic_fields.${field.label}`}
                  disabled={isFieldDisabled(field?.is_editable)}
                  variant="outlined"
                  fullWidth
                  value={dynamicFields[field.label] || ""}
                  onChange={(e) =>
                    handleInputChange(field.label, e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                  required={field.is_required}
                />
              )}
              {field.field_type === "text-area" && (
                <TextField
                  id={`dynamic_fields.${field.label}`}
                  disabled={isFieldDisabled(field?.is_editable)}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={dynamicFields[field.label] || ""}
                  onChange={(e) =>
                    handleInputChange(field.label, e.target.value)
                  }
                  required={field.is_required}
                />
              )}
              {field.field_type === "drop-down" && (
                <FormControl fullWidth>
                  <Select
                    labelId={`select-label-${field.label}`}
                    disabled={isFieldDisabled(field?.is_editable)}
                    id={`select-${field.label}`}
                    value={dynamicFields[field.label] || ""}
                    onChange={(e) =>
                      handleInputChange(field.label, e.target.value)
                    }
                    required={field.is_required}
                  >
                    {field?.choices?.map((option: any) => (
                      <MenuItem key={option.id} value={option.choice}>
                        {option.choice}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {field.field_type === "check-box" && (
                <FormControl fullWidth>
                  <Select
                    labelId={`checkbox-label-${field.label}`}
                    disabled={isFieldDisabled(field?.is_editable)}
                    id={`checkbox-${field.label}`}
                    multiple
                    value={dynamicFields[field.label] || []}
                    onChange={(e: any) => handleCheckboxChange(e, field.label)}
                    renderValue={(selected) => {
                      // Create an array of selected choice labels
                      const selectedLabels = (field?.multiple_choices || [])
                        .filter((choice:any) =>
                          selected.includes(String(choice.id))
                        )
                        .map((choice) => choice.choice);
                      return selectedLabels.join(", ");
                    }}
                  >
                    {field?.multiple_choices?.map((choice: any) => (
                      <MenuItem key={choice.id} value={String(choice.id)}>
                        <Checkbox
                          checked={dynamicFields[field.label]?.includes(
                            String(choice.id)
                          )}
                        />
                        <ListItemText primary={choice.choice} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {field.field_type === "tel-phone" && (
                <div style={{ display: "flex", width: "100%" }}>
                  <PhoneInput
                    style={{ width: "100%" }}
                    disabled={isFieldDisabled(field?.is_editable)}
                    defaultCountry="IN"
                    value={dynamicFields[field.label] || ""}
                    onChange={(value) => handleInputChange(field.label, value)}
                  />
                </div>
              )}
              {field.field_type === "date-time" && (
                <TextField
                  type="datetime-local"
                  id={`dynamic_fields.${field.label}`}
                  disabled={isFieldDisabled(field?.is_editable)}
                  variant="outlined"
                  fullWidth
                  value={dynamicFields[field.label] || ""}
                  onChange={(e) =>
                    handleInputChange(field.label, e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                  required={field.is_required}
                />
              )}
            </div>
          ))}
        </div>
        <div className="row mt-5">
          <div className="d-flex">
            <Button
              type="button"
              className="btn btn-primary ms-auto"
              onClick={handleSubmit}
              disabled={isLoading} // Disable button when loading
            >
              {isLoading ? "Saving..." : "Save"} {/* Loading indicator */}
            </Button>
          </div>
        </div>
      </form>

      <ReactModal
                isOpen={confirmationModalOpen}
                onRequestClose={closeConfirmationModal}
                style={customModalStyles}
                contentLabel="Change Status"
            >
                <ConfirmationModal handleButtonClick={handleButtonClick} closeModal={closeConfirmationModal}/>
            </ReactModal>
    </div>
  );
};

export default LeadInformation;
