import Chart from "react-apexcharts";
import { useState } from 'react';

const initialData = {
    options: {
        chart: {
          id: "basic-bar",
          type: 'bar',
          toolbar: {
            show: false
          },
          offsetX: -3
        },
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        },
        legend: {
          show: false
        },
        labels: {
          show: false,
        }
      },
      series: [
        {
          name: "series-1",
          data: [30, 40, 45, 50, 49, 60, 70, 91]
        }
      ]
    };
const BarChart = () => {
const [state, setState] = useState<any>(initialData)
  return (
    <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={state.options}
              series={state.series}
              type="bar"
              height="240px"
            />
          </div>
        </div>
      </div>
  )
}

export default BarChart
