import { useEffect, useState } from 'react'
import { useTeamsQuery } from '../../../../Services/settingsAPIs/CommonAPI';
import { useTicketRulesByTeamMutation, useUdpateTicketRulesByTeamMutation } from '../../../../Services/settingsAPIs/TicketSettingsAPI';
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { toast } from 'react-toastify';

/* {
  "ticket_resolution_sla_emails": [
      "yash@yahoo.co.in"
  ],
  "sla_rules": {
      "low": 7800000,
      "medium": 4800000,
      "high": 2400000,
      "urgent": 1200000
  }
} */
const msToTime = (milliSecond: number) => {
  if (milliSecond && milliSecond !== null) {
    var ms = milliSecond % 1000;
    milliSecond = (milliSecond - ms) / 1000;
    var secs = milliSecond % 60;
    milliSecond = (milliSecond - secs) / 60;
    var mins = milliSecond % 60;
    var hrs = (milliSecond - mins) / 60;
    return {
      time: hrs,
      minutes: mins
    }
  }
  else
    return {
      time: 0,
      minutes: 0
    }
}

const PriorityBasedSLA = () => {
    const [selectedTeam, setSelectedTeam] = useState("");
    const [triggerUpdateOnTicketRulesByTeamId] = useUdpateTicketRulesByTeamMutation();
    const {data:teamsData, isLoading: isLoadingTeams} = useTeamsQuery({});
    const [theTeams, setTheTeams] = useState([]);
    const [slasPrioritiesBased, setSlasPrioritiesBased] = useState<any>([
        {tag:'Urgent', hours:1,minutes:0, mailid:'prajakta@bluewhirl.io, prajaktakulkarni@gmail.com'},
        {tag:'High', hours:2,minutes:0, mailid:''},
        {tag:'Medium', hours:3,minutes:0, mailid:''},
        {tag:'Low', hours:4,minutes:0, mailid:''},
      ]);
    const [emails, setEmails] = useState<any[]>([])
    const [triggerTicketRulesByTeamId] = useTicketRulesByTeamMutation();
    const handleSelectTeamChange = (event: any) => { setSelectedTeam(event.target.value); };
    async function fetchTicketRules() { 
      triggerTicketRulesByTeamId(selectedTeam)
        .then((res:any)=>{
          if(res.data && res.data!==undefined) {
           
            setSlasPrioritiesBased((prev:any)=>prev.map((x:any)=>{
              switch(x.tag) {
                case 'Urgent': x.hours=msToTime(res.data.sla_rules.urgent).time; x.minutes=msToTime(res.data.sla_rules.urgent).minutes; x.mailid=res.data.ticket_resolution_sla_emails.join(';');break;
                case 'High': x.hours=msToTime(res.data.sla_rules.high).time; x.minutes=msToTime(res.data.sla_rules.high).minutes; x.mailid=res.data.ticket_resolution_sla_emails.join(';');break;
                case 'Medium': x.hours=msToTime(res.data.sla_rules.medium).time; x.minutes=msToTime(res.data.sla_rules.medium).minutes; x.mailid=res.data.ticket_resolution_sla_emails.join(';');break;
                case 'Low': x.hours=msToTime(res.data.sla_rules.low).time; x.minutes=msToTime(res.data.sla_rules.low).minutes; x.mailid=res.data.ticket_resolution_sla_emails.join(';');break;
              }
              return x;
            }))
            setEmails([...res.data.ticket_resolution_sla_emails])
          }
        })
        .catch((err:any)=>console.log(err))
      }

    const handleSave = (evt:any) => {
      const slatimes = slasPrioritiesBased.map(x=>x.hours*60*60000+x.minutes*60000)
      const payload = {
        "ticket_resolution_sla_emails": emails,
        "sla_rules": {
            "low": slatimes[3],
            "medium": slatimes[2],
            "high": slatimes[1],
            "urgent": slatimes[0]
        }
      }
      triggerUpdateOnTicketRulesByTeamId({teamId:selectedTeam, body:payload}).then((res:any)=>res.data && toast.success('The priority based ticket rule has been successfully updated.')).catch(err=>toast.error(err))
    }
    useEffect(() => { setTheTeams(teamsData); }, [teamsData]);
    useEffect(() => { fetchTicketRules(); }, [selectedTeam]);
    return (
    <div>
      <div className="card-toolbar flex-row-fluid justify-content-end gap-5 p-5">
                <div className="row mb-2">
                  <div className="w-100 mw-200px">
                    <select
                      className="form-select select2-hidden-accessible"
                      onChange={handleSelectTeamChange}
                      value={selectedTeam}
                    >
                      <option value="">Select Team</option>
                      {theTeams && theTeams.map((team, index)=>( <option key={index} value={index+1}>{team.name}</option> ))}
                    </select>
                  </div>
                </div>
                {selectedTeam !== '' ? (<>
                  <div className="row">
                    <div className="col">
                      <div className="card my-1 min-h-40px justify-content-center" style={{backgroundColor: 'rgba(228, 228, 228, 0.498)'}}>
                        <div className="row align-items-center">
                          <div className="col-4 text-start ps-10"><strong>Priority</strong></div>
                          <div className="col-1 text-start"><strong>Hours</strong></div>
                          <div className="col-1 text-start"><strong>Minutes</strong></div>
                          <div className="col-6 text-start"><strong>Mail ID</strong></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {slasPrioritiesBased.map((sla: any)=> (<>
                    <div className="row">
                      <div className="col">
                        <div className="input-group-text mt-2 bg-white">
                        <div className="row w-100 align-items-center">
                          <div className="col-4 text-start">{sla.tag}</div>
                          <div className="col-1">
                            <div className="d-flex justify-content-start align-items-center">
                              <input type="number"
                                className="form-control border-0 py-0" 
                                value={sla.hours}
                                min={0}
                                onChange={(evt)=>setSlasPrioritiesBased((prev:any)=>prev.map((x:any)=>x.tag===sla.tag?{...x,hours:evt.target.value}:x))}
                                />
                            </div>
                          </div>
                          <div className="col-1" d-flex>
                            <div className="d-flex justify-content-start align-items-center">
                              <input type="number"
                                className="form-control border-0 py-0" 
                                value={sla.minutes}
                                min={0}
                                onChange={(evt)=>setSlasPrioritiesBased((prev:any)=>prev.map((x:any)=>x.tag===sla.tag?{...x,minutes:evt.target.value}:x))}
                                />
                            </div>
                          </div>
                          <div className="col-6 text-start">
                            <ReactMultiEmail
                              placeholder="Input your Email Address"
                              emails={emails}
                              style={{border:"none"}}
                              onChange={(_emails: string[]) => {setEmails(_emails)}}
                              getLabel={(
                                email: string,
                                index: number,
                                removeEmail: (index: number) => void
                              ) => {
                                return (
                                  <div data-tag key={index}>
                                    {email}
                                    <span data-tag-handle onClick={() => removeEmail(index)}> × </span>
                                  </div>
                                );
                              }}
                            />
                            {/* <input type="text" 
                              className="form-control border-0 py-0" 
                              value={sla.mailid} id=""
                              onChange={(evt)=>setSlasPrioritiesBased((prev:any)=>prev.map((x:any)=>x.tag===sla.tag?{...x,mailid:evt.target.value}:x))} 
                              /> */}
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </>))}
                  <div className="row">
                    <div className="col text-end mt-2">
                      <button className="btn btn-primary ms-4 min-w-100px" onClick={handleSave}>Save</button>
                    </div>
                  </div>  
                </>): (<></>)}
              </div>
    </div>
  )
}

export default PriorityBasedSLA
