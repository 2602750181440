import React from "react";
import { toast } from "react-toastify";
import { useDeleteUserDynamicFieldsMutation } from "../../../../Services/settingsAPIs/CommonAPI";

interface IPROPS {
      closeModal: any;
      setFieldsData: any;
      fieldsData: any;
      storingFieldData: any;
}

const DeleteFieldsModal: React.FC<IPROPS> = ({
      closeModal,
      fieldsData,
      setFieldsData,
      storingFieldData,
}) => {
      const [trigger, {}] = useDeleteUserDynamicFieldsMutation();
      const deleteFieldHandler = async () => {
            const id = storingFieldData.id;
            if (id) {
                  try {
                        /* setFieldsData(
                              fieldsData.filter((item: any) => item.id !== id)
                        ); */
                      const response =  await trigger({ id });
                  //     if (response?.data) {
                            toast.success("Data deleted successfully");
                            closeModal(true);
                        // }
                        // else {
                        //       toast.error("An error occured")
                        // }
                  } catch (error) {
                        console.error("Error deleting data:", error);
                        toast.error(
                              "An error occurred while deleting the data"
                        );
                  }
            } else {
                  console.error("No valid ID provided for deletion");
                  toast.error("Invalid ID, cannot delete data");
            }
      };
      return (
            <div
                  style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "center",
                  }}
            >
                  <div
                        style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "end",
                        }}
                  >
                        <i
                              style={{
                                    fontSize: "26px",
                                    color: "black",
                                    cursor: "pointer",
                              }}
                              className="bi bi-x"
                              onClick={() => closeModal(true)}
                        ></i>
                  </div>
                  <div style={{ color: "#3071e4", fontSize: "16px" }}>
                        Are you sure?
                  </div>
                  <div style={{ marginBottom: "15px" }}>
                        Do you really want to delete these records? This process
                        cannot be undone.
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                        <button
                              className="btn btn-danger"
                              onClick={deleteFieldHandler}
                        >
                              Delete
                        </button>
                        <button
                              className="btn btn-light"
                              onClick={() => closeModal(true)}
                        >
                              Cancel
                        </button>
                  </div>
            </div>
      );
};

export default DeleteFieldsModal;