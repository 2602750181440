import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const generalApi = createApi({
  reducerPath: "generalApi",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    saveDesposition: builder.mutation({
      query: ({ body, id }) => ({
        url: `v1/common/disposition/${id}`,
        method: "PUT",
        body,
      }),
    }),

    DeleteDesposition: builder.mutation({
      query: (id) => ({
        url: `v1/common/disposition/${id?.id}/`,
        method: "DELETE",
      }),
    }),

    despositionData: builder.query({
      query: (id) => ({
        url: `v1/common/disposition/?team_id=${id}&`,
        method: "GET",
      }),
    }),

    businessHoursData: builder.query({
      query: (id) => {
        return {
          url: `v1/common/business-hours/?team=${id}&`,
          method: "GET",
        };
      },
    }),

    updatedBusinessHoursData: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `v1/common/business-hours/?team=${id}&`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});

export const {
  useDeleteDespositionMutation,
  useSaveDespositionMutation,
  useDespositionDataQuery,
  useBusinessHoursDataQuery,
  useUpdatedBusinessHoursDataMutation,
} = generalApi;
