import { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useTeamsQuery } from "../../../../Services/settingsAPIs/CommonAPI";
import {
  useCampaignTeamsDataQuery,
  useCampaignWorkflowDetailsQuery,
  useDeleteCampaignWorkFlowMutation,
  useDeleteWorkflowStatusMutation,
} from "../../../../Services/campaign/CampaignMapping";
import LoadingSpinner from "../../../LoadingSpinner";
import { toast } from "react-toastify";
import NewWorkflowModal from "./NewWorkflowModal";
import DeleteModal from "./DeleteModal";
import EditWipModal from "./Modal/EditWipModal";
import AddNewWipModal from "./Modal/AddNewWipModal";

const customModalStyles1 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
  },
};

const CampaignWorkFlow = () => {
  const [workflowSelected, setWorkflowSelected] = useState<any>(-1);

  const [selectedTeam, setSelectTeam] = useState<number | string>("-1");
  const [theTeams, setTheTeams] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState<number | string>(
    "-1"
  );
  const [theCampaig, setTheCampaign] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [statusForDeletion, setStatusForDeletion] = useState<any>();
  const [selectedWip, setSelectedWip] = useState<any>("");
  const [wipEditModalOpen, setWipEditModalOpen] = useState<boolean>(false);
  const [wipAddModalOpen, setWipAddModalOpen] = useState<boolean>(false);
  const [showWipAddButton, setShowWipAddButton] = useState<boolean>(false);
  const userRole = localStorage.getItem("user_role");

  // Workflow add and edit
  const [workflowForEditAndCreate, setWorkflowForEditAndCreate] = useState<any>(
    { workflowEditEnabled: false, workflowData: null }
  );
  const [isNewWFModalOpen, setIsNewWFModalOpen] = useState<boolean>(false);

  //  delete workflow
  const [isDeleteWFModalOpen, setIsDeleteWFModalOpen] =
    useState<boolean>(false);

  //delete workflow status
  const [isDeleteStatusModalOpen, setIsDeleteStatusModalOpen] =
    useState<boolean>(false);
  // API calls
  const { data: teams, status: teamStatus } = useTeamsQuery({});
  const { data: campaignTeamsData } = useCampaignTeamsDataQuery(selectedTeam, {
    skip: !selectedTeam,
  });
  const {
    data: campaignWorkFlowDetails,
    status: workflowDetailsStatus,
    refetch,
  } = useCampaignWorkflowDetailsQuery(
    { selectedTeam, selectedCampaign },
    {
      skip: !selectedTeam || !selectedCampaign,
    }
  );
  const [triggerDeleteWFMutation] = useDeleteCampaignWorkFlowMutation();
  const [triggerDeleteInProgressStatusMutation] =
    useDeleteWorkflowStatusMutation();
  const colorCode = (stage: any) => {
    switch (stage.status_type) {
      case "active": {
        return "border-success";
        break;
      }
      case "wip": {
        return "border-warning";
        break;
      }
      case "closed": {
        return "border-danger";
        break;
      }
      case "reopen": {
        return "border-success";
        break;
      }
      default: {
        return "border-secondary";
        break;
      }
    }
  };

  //  modal functions
  const openAddNewWorkflowModal = () => {
    if (!selectedTeam || selectedTeam === "-1") {
      toast.error("Please select a valid team before adding.");
      return;
    }

    if (!selectedCampaign || selectedCampaign === "-1") {
      toast.error("Please select a valid campaign before adding.");
      return;
    }

    // If both are valid, open the modal
    setIsNewWFModalOpen(true);
  };
  const closeAddNewWorkflowModal = () => {
    setIsNewWFModalOpen(false);
    setWorkflowForEditAndCreate({
      ...workflowForEditAndCreate,
      workflowEditEnabled: false,
    });
  };

  const initiateWorkflowEdit = (stage: any) => {
    setWorkflowForEditAndCreate({
      workflowEditEnabled: true,
      workflowData: campaignWorkFlowDetails?.results?.find(
        (x: any) => x.id === workflowSelected
      ),
    });
    openAddNewWorkflowModal();
  };
  const editWipModal = (stage: any) => {
    setSelectedWip(stage);
    openWipEditModal();
  };
  const openWipEditModal = () => {
    setWipEditModalOpen(true);
  };
  const closeWipEditModal = () => {
    setWipEditModalOpen(false);
  };

  const addWipModal = (stage: any) => {
    openWipAddModal();
  };
  const openWipAddModal = () => {
    setWipAddModalOpen(true);
  };
  const closeWipAddModal = () => {
    setWipAddModalOpen(false);
  };

  const openDeleteWFModal = () => {
    setIsDeleteWFModalOpen(true);
  };
  const closeDeleteWFModal = () => {
    setIsDeleteWFModalOpen(false);
  };

  const openDeleteStatusModal = () => {
    setIsDeleteStatusModalOpen(true);
  };
  const closeDeleteStatusModal = () => {
    setIsDeleteStatusModalOpen(false);
  };

  const DeleteMessageBody = ({ bodyParams }) => {
    return (
      <div>
        {bodyParams.descriptionPromptEnabled ? (
          <>
            <p className="fs-5 fw-bold">
              Are you sure you want to delete the following{" "}
              {bodyParams.deletionOf}?
            </p>
            <div className="card-text">
              Title: <strong>{bodyParams.deletionItemTitle}</strong>
            </div>
            <div className="card-text">
              Description: <strong>{bodyParams.deletionItemDescription}</strong>
            </div>
          </>
        ) : (
          <>
            <p className="fs-5 fw-bold">Are you sure?</p>
            <div className="card-text">
              <span className="text-danger">Caution</span>:{" "}
              <strong>
                Please confirm to delete the {bodyParams.deletionOf}{" "}
                {bodyParams?.deletionItemCautionMsg}
              </strong>
            </div>
          </>
        )}
      </div>
    );
  };
  const deleteStatus = async (stage: any) => {
    setStatusForDeletion(stage);
    openDeleteStatusModal();
  };

  // Effects
  useEffect(() => {
    setTheTeams(teams || []);
  }, [teams]);

  useEffect(() => {
    setTheCampaign(campaignTeamsData?.results || []);
    if (
      !campaignTeamsData?.results?.length &&
      selectedTeam != "-1" &&
      selectedCampaign != "-1"
    ) {
      toast.warn("Please select a campaign.", {
        position: "top-right",
      });
    }
  }, [campaignTeamsData]);

  useEffect(() => {
    setIsLoading(
      teamStatus === "pending" || workflowDetailsStatus === "pending"
    );
  }, [teamStatus, workflowDetailsStatus]);

  useEffect(() => {
    if (campaignWorkFlowDetails && workflowSelected > 0) {
      const allStatus = campaignWorkFlowDetails?.results?.find(
        (x: any) => x.id === workflowSelected
      )?.all_statuses;
    }
  }, [campaignWorkFlowDetails]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="card-body px-0 px-lg-10 py-10 py-lg-0">
          <div className="row my-4">
            <div className="col-12">
              <div className="card-toolbar d-lg-flex gap-5">
                {/* Team Selector */}
                <div className="d-flex flex-column">
                  <label>Select team</label>
                  <div className="min-w-250px">
                    <select
                      className="form-select"
                      onChange={(e) => {
                        setSelectTeam(Number.parseInt(e.target.value)); // Update selected team
                        setSelectedCampaign("-1"); // Reset the selected campaign
                        setWorkflowSelected("-1");
                      }}
                      value={selectedTeam}
                    >
                      <option value="-1" disabled>
                        Choose a team
                      </option>
                      {theTeams.map((team) => (
                        <option key={team.id} value={team.id}>
                          {team.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Campaign Selector */}
                <div className="d-flex flex-column">
                  <label>Select campaign</label>
                  <div className="min-w-250px">
                    <select
                      className="form-select"
                      onChange={(e) => {
                        setSelectedCampaign(Number.parseInt(e.target.value));
                        setWorkflowSelected("-1");
                      }}
                      value={selectedCampaign}
                    >
                      <option value={"-1"} disabled>
                        Choose a campaign
                      </option>
                      {theCampaig.map((theworkflow: any) => (
                        <option key={theworkflow.id} value={theworkflow.id}>
                          {theworkflow.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* workflow Selector */}
                {theCampaig?.length > 0 && (
                  <div className="d-flex flex-column">
                    <label>Select workflow</label>
                    <div className="min-w-250px">
                      <select
                        className="form-select"
                        onChange={(e) =>
                          setWorkflowSelected(Number.parseInt(e.target.value))
                        }
                        value={workflowSelected}
                      >
                        <option value={-1} disabled>
                          Choose a workflow
                        </option>
                        {campaignWorkFlowDetails?.results?.map(
                          (theworkflow: any) => (
                            <option key={theworkflow.id} value={theworkflow.id}>
                              {theworkflow.name}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                )}

                <span className="input-group-text bg-white border-0 ms-auto align-self-start">
                  <div className="ms-auto d-flex">
                    <button
                      className="input-group-text form-control btn-sm form-control align-items-center text-hover-primary fw-bold min-w-150px"
                      onClick={openAddNewWorkflowModal}
                    >
                      <i className="bi bi-plus-lg me-2"></i>
                      <span>New workflow</span>
                    </button>
                  </div>
                </span>
              </div>
            </div>
          </div>

          {/*  Add workflow and edit workflow modal */}
          <ReactModal
            isOpen={isNewWFModalOpen}
            onRequestClose={closeAddNewWorkflowModal}
            style={customModalStyles1}
            contentLabel="Add a new workflow"
          >
            <NewWorkflowModal
              closeModal={closeAddNewWorkflowModal}
              workflowForEditAndCreate={workflowForEditAndCreate}
              refetch={refetch}
              selectedTeam-={selectedTeam}
              selectedCampaign={selectedCampaign}
              selectedTeam={selectedTeam}
            />
          </ReactModal>

          <ReactModal
            isOpen={isDeleteWFModalOpen}
            onRequestClose={closeDeleteWFModal}
            style={customModalStyles1}
            contentLabel="Delete a selected workflow"
          >
            <DeleteModal
              closeModal={closeDeleteWFModal}
              deleteAPItrigger={triggerDeleteWFMutation}
              deletionIdentifier={workflowSelected}
              messageBody={
                <DeleteMessageBody
                  bodyParams={{
                    descriptionPromptEnabled: false,
                    deletionOf: "workflow",
                  }}
                />
              }
              toastSuccessMessage="Workflow deleted successfully!"
              refetch={refetch}
            />
          </ReactModal>
          <ReactModal
            isOpen={isDeleteStatusModalOpen}
            onRequestClose={closeDeleteStatusModal}
            style={customModalStyles1}
            contentLabel="Delete a selected status"
          >
            <DeleteModal
              closeModal={closeDeleteStatusModal}
              deleteAPItrigger={
                statusForDeletion?.status_type !== "closed" &&
                triggerDeleteInProgressStatusMutation
              }
              deletionIdentifier={statusForDeletion?.status.id}
              messageBody={
                <DeleteMessageBody
                  bodyParams={{
                    descriptionPromptEnabled: false,
                    deletionOf: "stage",
                  }}
                />
              }
              toastSuccessMessage="Stage deleted successfully!"
              refetch={refetch}
            />
          </ReactModal>

          <ReactModal
            isOpen={wipEditModalOpen}
            onRequestClose={closeWipEditModal}
            style={customModalStyles1}
            contentLabel="Edit wip "
          >
            <EditWipModal
              closeModal={closeWipEditModal}
              selectedWip={selectedWip}
              refetch={refetch}
            />
          </ReactModal>

          <ReactModal
            isOpen={wipAddModalOpen}
            onRequestClose={closeWipAddModal}
            style={customModalStyles1}
            contentLabel="Edit wip "
          >
            <AddNewWipModal
              closeModal={closeWipAddModal}
              workflowSelected={workflowSelected}
              refetch={refetch}
            />
          </ReactModal>

          {/* Status Toggles */}
          <div className="row my-6">
            <div className="col d-flex">
              <input
                type="checkbox"
                style={{
                  outline: "1px solid green",
                  border: "none",
                  backgroundPosition: "center",
                  borderRadius: 0,
                }}
                disabled
                className="form-check-input"
              />
              <label htmlFor="" className="form-label ms-4">
                Open
              </label>
              <input
                type="checkbox"
                style={{
                  outline: "1px solid yellow",
                  border: "none",
                  backgroundPosition: "center",
                  borderRadius: 0,
                }}
                disabled
                className="ms-4 form-check-input"
              />
              <label htmlFor="" className="form-label ms-4">
                In progress
              </label>
              <input
                type="checkbox"
                style={{
                  outline: "1px solid red",
                  border: "none",
                  backgroundPosition: "center",
                  borderRadius: 0,
                }}
                disabled
                className="ms-4 form-check-input"
              />
              <label htmlFor="" className="form-label ms-4">
                Close
              </label>
            </div>
          </div>

          <div className="ms-lg-10 row my-6">
            {campaignWorkFlowDetails &&
              campaignWorkFlowDetails?.results
                ?.find((x: any) => x.id === workflowSelected)
                ?.all_statuses.map((stage: any, index: number) => (
                  <>
                    <div className="col-auto d-flex mt-10">
                      {stage.status_type !== "active" && index != 1 ? (
                        <>
                          <div className="mx-4 d-flex flex-column pb-2">
                            <label>Assigned to</label>
                            <div className="min-w-250px">
                              <select
                                className="form-select form-select-sm"
                                value={
                                  stage.status?.assignment_team
                                    ? stage.status.assignment_team.id
                                    : 0
                                }
                              >
                                <option
                                  value="select team"
                                  defaultValue={0}
                                ></option>
                                {theTeams.map((theTeam: any) => (
                                  <option value={theTeam.id}>
                                    {theTeam.name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="d-flex">
                              <i className="bi bi-dash-lg fs-2 text-dark"></i>
                              <i className="bi bi-dash-lg fs-2 text-dark"></i>
                              <i className="bi bi-dash-lg fs-2 text-dark"></i>
                              <i className="bi bi-dash-lg fs-2 text-dark"></i>
                              <i className="bi bi-dash-lg fs-2 text-dark"></i>
                              <i className="bi bi-dash-lg fs-2 text-dark"></i>
                              <i className="bi bi-dash-lg fs-2 text-dark"></i>
                              <i className="bi bi-dash-lg fs-2 text-dark"></i>
                              <i className="bi bi-dash-lg fs-2 text-dark"></i>
                              <i className="bi bi-dash-lg fs-2 text-dark"></i>
                              <i className="bi bi-dash-lg fs-2 text-dark"></i>
                              <i className="bi bi-dash-lg fs-2 text-dark"></i>
                              <i className="text-dark bi bi-arrow-right fs-2"></i>
                            </div>
                            <label>Visible to</label>
                            <div className="min-w-250px">
                              <select
                                className="form-select form-select-sm"
                                value={
                                  stage.status?.assignment_team
                                    ? stage.status.assignment_team.id
                                    : 0
                                }
                              >
                                <option value="select team"></option>
                                {theTeams.map((theTeam: any) => (
                                  <option value={theTeam.id}>
                                    {theTeam.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </>
                      ) : (
                        index === 1 && (
                          <>
                            {campaignWorkFlowDetails &&
                              campaignWorkFlowDetails?.results?.find(
                                (x: any) => x.id === workflowSelected
                              )?.all_statuses.length === 2 && (
                                <>
                                  {" "}
                                  <button
                                    className="btn btn-link"
                                    onClick={() => addWipModal(stage)}
                                  >
                                    <i className="ps-1 bi text-hover-primary bi-plus-lg fs-2 py-2"></i>
                                  </button>{" "}
                                </>
                              )}
                            <div className="d-flex align-items-center p-4">
                              <i className="bi bi-dash-lg fs-2 text-dark"></i>
                              <i className="bi bi-dash-lg fs-2 text-dark"></i>
                              <i className="bi bi-dash-lg fs-2 text-dark"></i>
                              <i className="text-dark bi bi-arrow-right fs-2"></i>
                              {/* <i className="bi bi-caret-right-fill  fs-1"></i> */}
                            </div>
                          </>
                        )
                      )}
                      <div
                        className={`border ${colorCode(
                          stage
                        )} d-flex flex-column justify-content-center align-items-center`}
                        style={{ width: "150px", height: "150px" }}
                      >
                        <span
                          className="text-center px-4"
                          style={{ overflowWrap: "anywhere" }}
                        >
                          {stage.status_type === "active" ||
                          stage.status_type === "reopen"
                            ? stage.status
                            : stage.status.status}
                        </span>
                        <div className="d-flex">
                          {stage.status_type === "active" ||
                          stage.status_type === "reopen" ||
                          stage.status_type === "closed" ? (
                            <button
                              className="btn btn-link"
                              onClick={() => initiateWorkflowEdit(stage)}
                            >
                              <i className="bi text-hover-primary bi-pencil-fill fs-2 py-2"></i>
                            </button>
                          ) : (
                            <>
                              <button
                                className="btn btn-link"
                                onClick={() => editWipModal(stage)}
                              >
                                <i className="bi text-hover-primary bi-pencil-fill fs-2 py-2"></i>
                              </button>
                              <button
                                className="btn btn-link"
                                onClick={() => addWipModal(stage)}
                              >
                                <i className="ps-1 bi text-hover-primary bi-plus-lg fs-2 py-2"></i>
                              </button>
                              <button
                                className="btn btn-link"
                                onClick={() => deleteStatus(stage)}
                              >
                                <i className="ps-1 bi text-hover-primary bi-trash-fill fs-2 py-2"></i>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
          </div>

          {/* Trash Icon */}
          <div className="row my-6">
            {userRole !== "agent" ? (
              <div className="col text-end">
                <i
                  className={`bi bi-trash text-end fa-2x text-danger px-lg-10 px-0 ${
                    workflowSelected === -1 ? "d-none" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={openDeleteWFModal}
                ></i>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default CampaignWorkFlow;
