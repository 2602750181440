import React from 'react'

const DispositionCard = () => {
    return (
        <>
            <div className='p-2'>
                <span className="text-muted mb-1">
                    Disposition
                </span>
            </div>
            <div className="w-100 mw-200px">
                {/*begin::Select2*/}
                <select
                    className="form-select select2-hidden-accessible"
                >
                    <option value="select team" selected hidden>Select Type</option>
                    <option value="Team 01">team 01</option>
                    <option value="Team 02">team 02</option>
                    <option value="Team 03">team 03</option>
                </select>
                {/*end::Select2*/}
            </div>
        </>
    )
}

export default DispositionCard