import { createRef, useRef, useState, useEffect } from "react";
import TasksActionMenu from "./TasksActionMenu";
import { useCampaignLeadEventQuery } from "../../../Services/campign/campignList";
import { capitalizeFirstLetter } from "../../reusableComponent/CapitalLetter";
import { formatDatee } from "../../reusableComponent/DateFormat";

interface IPROPS {
  tasks:any
  setLiftingTaskData:any
}

const TasksTab: React.FC<IPROPS> = ({tasks, setLiftingTaskData}) => {

  console.log(tasks, 'tasksks')

  const [taskData, setTaskData] = useState([])

  const {data: campaignTaskData, isLoading: campaignTaskLoading, status: campaignStatus, refetch: campaignTaskRefetch} = useCampaignLeadEventQuery(tasks?.id)
    // const taskRes = [
    //     { taskname: 'folow up on payment', duedate: '12/1/23', frequency: 'monthly', owner: 'Archana', status: 'pending' },
    //     { taskname: 'folow up on payment', duedate: '12/1/23', frequency: 'monthly', owner: 'Archana', status: 'pending' },
    //     { taskname: 'folow up on payment', duedate: '12/1/23', frequency: 'monthly', owner: 'Archana', status: 'pending' },
    //     { taskname: 'folow up on payment', duedate: '12/1/23', frequency: 'monthly', owner: 'Archana', status: 'pending' },
    //   ];
    
    //   const taskResActionMenuRef = useRef<any[]>([])
    //   taskResActionMenuRef.current = taskRes.map(
    //     (item: any, i: number) => taskResActionMenuRef.current[i] ?? createRef()
    //   )

      console.log(campaignTaskData, "campaiiidi")
    
      useEffect(() => {
        if (campaignTaskData) {
          setTaskData(campaignTaskData)

        }

      }, [campaignTaskData])

      useEffect(() => {
        setLiftingTaskData(() => campaignTaskRefetch)

      }, [campaignTaskRefetch, setLiftingTaskData])

      return (
        <>
          <div className="table-responsive">
            <table className="table table-row-bordered table-row-gray-300 text-muted align-middle gs-10">
              <thead className="table-light fw-bolder">
                <tr className="bg-primary">
                  <th scope="col">Title</th>
                  <th scope="col">Created on</th>
                  <th scope="col">Due date</th>
                  <th scope="col">Time due</th>
                  <th scope="col">Task description</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody className="table-group-divider">
             
             {taskData?.map((tasks, index) => (
               <tr style={{width: "100%"}}>
                  <th scope="row">{tasks?.title}</th>
                  <td>{formatDatee(tasks?.created)}</td>
                  <td>{tasks?.event_date}</td>
                  <td>{tasks?.start_time}</td>
                  <td data-toggle="tooltip" data-placement="top" title={tasks.event_description?.replace(/<\/?[^>]+(>|$)/g, "")} style={{maxWidth: "100px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>{tasks.event_description?.replace(/<\/?[^>]+(>|$)/g, "")}</td>
                  <td>
                    {capitalizeFirstLetter(tasks?.status)}
                    {/* <div
                      id={`parser-result-3`}
                      ref={taskResActionMenuRef.current[3]}
                      >
                      <TasksActionMenu
                        taskResActionMenuRef={taskResActionMenuRef}
                        user={taskRes[3]}
                        index={3}
                        dditems={["Edit", "Status"]}
                        />
                    </div> */}
                  </td>
                </tr>
                      ))}
              </tbody>
            </table>
          </div>
        </>
      );
}

export default TasksTab
