import React, { useState } from "react";
import { useSaveDespositionMutation } from "../../../../Services/general/General";
import { toast } from "react-toastify";
import { Loader } from "react-bootstrap-typeahead";
import { useAddSubTeleDispositionMutation } from "../../../../Services/Telephony/Telephony";

interface IProps {
    closeModal: any;
    setData: any;
    data: any;
    currentNode: any;
    SetLiftingAddDate: any;
    selectedTeam: any;
    refetch: any;
    dispositionId:any
}

const AddSubTeleDisposition: React.FC<IProps> = ({
    closeModal,
    setData,
    data,
    currentNode,
    SetLiftingAddDate,
    selectedTeam,
    refetch,
    dispositionId
}) => {
    const [topic, setTopic] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [trigger, { isLoading }] = useAddSubTeleDispositionMutation();

    const handleTopicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTopic(event.target.value);

    };


    const removeLevelProperty = (node: any) => {
        const { level, ...rest } = node;
        if (rest.sub_topics && rest.sub_topics.length > 0) {
            rest.sub_topics = rest.sub_topics.map((subNode: any) =>
                removeLevelProperty(subNode)
            );
        }
        return rest;
    };


const handleAdd = async () => {
    setIsSubmitting(true); // Disable the button to prevent multiple submissions

    // Construct the payload dynamically
    const updatedSubDispositions = [
        ...(currentNode?.sub_disposition || []),
        topic.trim(),
    ];

    // Construct the payload dynamically
    const payload = {
        disposition: currentNode?.disposition || "", // Use the current node's disposition
        sub_disposition: updatedSubDispositions, // Add existing and new sub-dispositions
        team: selectedTeam, // Use the selected team
        type: currentNode?.type, // Retain the type
    };

    try {
        // Trigger the API call with the constructed payload
        const response = await trigger({ body: payload, id:currentNode?.id });

        if (response?.data) {
            toast.success("Sub-Disposition added successfully");
            refetch();
            closeModal();
        }
    } catch (error) {
        toast.error("Failed to add sub-disposition");
    } finally {
        setIsSubmitting(false); // Re-enable the button after submission
    }
};

    return (
        <div>
            <div className="mt-4">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Topic"
                    value={topic}
                    onChange={handleTopicChange}
                    disabled={isSubmitting}
                />
                <input type="hidden" value={currentNode?.topic} />
            </div>
            <div className="d-flex justify-content-end mt-4">
                <button
                    type="reset"
                    className="btn btn-sm btn-white  me-2"
                    onClick={closeModal}
                    disabled={isSubmitting}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={handleAdd}
                    disabled={isSubmitting || topic.trim().length < 1}
                >
                    {isLoading ? (
                        <>
                            Adding... <Loader />
                        </>
                    ) : (
                        "Add"
                    )}
                </button>
            </div>
        </div>
    );
};

export default AddSubTeleDisposition;