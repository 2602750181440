import React from "react";
import { useDeleteCampaignMutation } from "../../Services/campaign/CampaignMapping";
import { CloseButton } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDeleteBulkLeadMutation } from "../../Services/campign/campignList";

interface IProps {
  selectedCampaign: any;
  closeDeleteCampaignModal: () => void;
  refetch: any;
}

const DeleteBulkLeadModel: React.FC<IProps> = ({
  selectedCampaign,
  closeDeleteCampaignModal,
  refetch,
}) => {
  const [triggerDeleteBulkCampaign] = useDeleteBulkLeadMutation();

  const handleDeleteCampaign = async () => {
    try {
      const payload = {
      campaign_id:selectedCampaign
      };
      await triggerDeleteBulkCampaign({ body: payload,selectedCampaign});
      toast.success(`Lead successfully deleted`);
      closeDeleteCampaignModal();
      refetch();
    } catch (err) {
      toast.error("Failed to delete. Please try again.");
      console.error("Error delete:", err);
    } finally {
    }
  };

  return (
    <>
      <div className="text-end">
        <CloseButton onClick={closeDeleteCampaignModal} />
      </div>

      <div className="card-body">
        <p className="fs-5 fw-bold">
          Are you sure you want to delete the following lead?
        </p>

        <div className="d-flex justify-content-end mt-10">
          <button
            type="reset"
            className="btn btn-sm btn-white me-2"
            onClick={() => closeDeleteCampaignModal()}
          >
            Cancel
          </button>

          <button
            type="submit"
            className="btn btn-sm btn-danger"
            onClick={handleDeleteCampaign}
          >
            Delete
          </button>
        </div>
      </div>
    </>
  );
};

export default DeleteBulkLeadModel;
