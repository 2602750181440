import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import AccountModal from "../../components/contact/accounts/AccountModal";
import ContactModal from "../../components/contact/contact/ContactModal";
import AccountCards from "../../components/contact/accounts/AccountCards";
import ContactCards from "../../components/contact/contact/ContactCards";

import {
  Box,
  Button,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import "./styles.scss";
import * as XLSX from "xlsx";
import { formatDate } from "../../components/reusableComponent/DateFormat";
import ContactFilterModal from "./ContactFilterModal";
import {
  useAccountDownloadqueryQuery,
  useAccountqueryQuery,
  useContactqueryDownloadQuery,
  useContactqueryQuery,
} from "../../Services/customer/FrontendCustomer";
import { Loader } from "react-bootstrap-typeahead";
import AccountFilterModal from "./AccountFilterModal";
import SortingTableContact from "../../components/reusableComponent/SortingTableContact";
import { toast } from "react-toastify";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useTelephony } from "../../components/context/TelephonyContext";
import {
  AUTH_TOKEN,
  CALL,
  INCOMING_CALL,
  OUTGOING_CALL,
} from "../../constants";
import axios from "axios";

const CustomerPage = () => {
  const [showAccountButton, setShowAccountButton] = useState<boolean>(true);
  const [isAccountModalOpen, setIsAccountModalOpen] = useState<boolean>(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false);
  const [selectAccount, setSelectAccount] = useState<any>("");
  const [liftingColumn, setLiftingColumn] = useState<any>("name");
  const [downloadButtonClicked, setDownloadButtonClicked] =
    useState<boolean>(false);
  const [accountDownloadButtonClicked, setAccountDownloadButtonClicked] =
    useState<boolean>(false);
  const [isFilterModalOpen, setFilterIsModalOpen] = useState<boolean>(false);
  const [isAccountFilterModalOpen, setIsAccountFilterIsModalOpen] =
    useState<boolean>(false);
  const [liftingContactFilterData, setLiftingContactFilterData] = useState<any>(
    {}
  );
  const [liftingAccountFilterData, setLiftingAccountFilterData] = useState<any>(
    {}
  );
  const [page, setPage] = useState<any>(1);
  const [accountPage, setAccountPage] = useState<any>(1);
  const [contactData, setContactData] = useState<any>([]);
  const [accountData, setAccountData] = useState<any>([]);
  const [contactCardLoading, setContactCardLoading] = useState<boolean>(false);
  const [accountCardLoading, setAccountCardLoading] = useState<boolean>(false);
  const [contactDownloadLoading, setContactDownloadLoading] =
    useState<boolean>(false);
  const [accountDownloadLoading, setAccountDownloadLoading] =
    useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<any>(0);
  const [ContactDownloadedData, setContactDownloadedData] = useState<any>([]);
  const [accountDownloadedData, setAccountDownloadedData] = useState<any>([]);
  const [liftingContactLocationData, setLiftingContactLocationData] = useState(
    []
  );

  const navigate = useNavigate();
  const location = useLocation();

  const { setLifitinMobileNumber, liftingMobileNumber } = useTelephony();

  //Account API
  const {
    data: accountDataa,
    isLoading: AccountQueryLoading,
    refetch: accountRefetch,
    status: accountStatus,
  } = useAccountqueryQuery(
    { page: accountPage, sort: liftingColumn, liftingAccountFilterData },
    { skip: !showAccountButton }
  );

  const {
    data: downloadAccountData,
    isLoading: downloadAccountLoading,
    status: downloadAccountStatus,
  } = useAccountDownloadqueryQuery(
    {
      page: accountPage,
      sort: liftingColumn,
      liftingAccountFilterData,
      accountDownloadButtonClicked,
    },
    { skip: !accountDownloadButtonClicked }
  );

  //Contac API
  const mobileNumber = localStorage.getItem("liftingMobileNumber");

  const {
    data,
    isLoading: contactQueryLoading,
    refetch: contactRefetch,
    status,
  } = useContactqueryQuery(
    { page: page, sort: liftingColumn, liftingContactFilterData },
    { skip: showAccountButton || !!mobileNumber }
  );
  const {
    data: downloadData,
    isLoading: downloadLoading,
    status: downloadStatus,
  } = useContactqueryDownloadQuery(
    {
      page: page,

      sort: liftingColumn,
      liftingContactFilterData,
      downloadButtonClicked,
    },
    { skip: !downloadButtonClicked }
  );

  const fetchingTenant = window.location.hostname.split(".")[0];
  const apiPort = process.env.REACT_APP_API_PROTOCOL;

  useEffect(() => {
    // Retrieve the saved number from localStorage
    const savedNumber = localStorage.getItem("liftingMobileNumber");
    if (savedNumber) {
      setLifitinMobileNumber(savedNumber);
    }
  }, []);

  const {
    setTelephonyContactLoading,
    storedIncomingCallContactData,
    inboundCount,
  } = useTelephony();

  // Outgoing Call Data

  const gettingUserDataResponse = async () => {
    if (outGoingCall) {
      setTelephonyContactLoading(true);
      try {
        const response = await axios.get(
          `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/customers/contacts/?phone_number__endswith=${liftingMobileNumber}&`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
            },
          }
        );
        setTelephonyContactLoading(false);

        setContactData(response?.data);
      } catch (error) {
        setTelephonyContactLoading(false);
        console.error("Error fetching user data:", error);
      }
    }
  };

  // Incoming Call Data

  const incomingCall = localStorage.getItem(INCOMING_CALL) === "true";
  const outGoingCall = localStorage.getItem(OUTGOING_CALL) === "true";

  useEffect(() => {
    if (incomingCall) {
      // Incoming call takes priority
      setContactData(storedIncomingCallContactData);
      setTotalCount(inboundCount || 0);
    } else if (outGoingCall) {
      // Outgoing call is next priority
      gettingUserDataResponse();
    } else if (status === "fulfilled" && data) {
      // Initial data as the fallback
      setContactData(data?.results || []);
      setTotalCount(data?.count || 0);
      setContactCardLoading(false);
    }
  }, [storedIncomingCallContactData, status, data, incomingCall, outGoingCall]);

  useEffect(() => {
    if (!showAccountButton) {
      gettingUserDataResponse();
    }
  }, [!showAccountButton]);

  //Contact Download API useEffect
  useEffect(() => {
    if (downloadStatus === "pending") {
      setContactDownloadLoading(true);
      setDownloadButtonClicked(true);
    } else if (downloadStatus === "fulfilled" && downloadData) {
      setContactDownloadLoading(false);
      setContactDownloadedData(downloadData);
      // toast.success("Document downloaded successfully")
    }
  }, [downloadData, downloadStatus]);

  useEffect(() => {
    if (
      downloadStatus === "fulfilled" &&
      downloadButtonClicked &&
      ContactDownloadedData?.length >= 0
    ) {
      setDownloadButtonClicked(false);

      setContactDownloadLoading(false);
      downloadContactData();
    }
  }, [
    downloadStatus,
    downloadButtonClicked,
    ContactDownloadedData,
    contactDownloadLoading,
  ]);

  //   Contact API
  useEffect(() => {
    if (status === "pending") {
      setContactCardLoading(true);
    } else {
      setContactCardLoading(false);
    }
  }, [status, contactCardLoading]);

  useEffect(() => {
    if (status === "fulfilled" && data) {
      setContactData(data?.results || []);
      setTotalCount(data?.count || 0);

      setContactCardLoading(false);
    }
  }, [data, status]);

  // Account API

  useEffect(() => {
    if (accountStatus === "pending") {
      setAccountCardLoading(true);
    } else {
      setAccountCardLoading(false);
    }
  }, [accountStatus, contactCardLoading]);

  useEffect(() => {
    if (accountStatus === "fulfilled" && accountDataa) {
      // setAccountPage(1)
      setAccountData(accountDataa.results || []);
      setTotalCount(accountDataa?.count || 0);

      setAccountCardLoading(false);
    }
  }, [accountDataa, accountStatus]);

  // Donwload Account API

  useEffect(() => {
    if (downloadAccountStatus === "pending") {
      setAccountDownloadLoading(true);
      setAccountDownloadButtonClicked(true);
    } else {
      setAccountDownloadLoading(false);
    }
  }, [downloadAccountStatus]);

  useEffect(() => {
    if (downloadAccountStatus === "fulfilled" && downloadAccountData) {
      setAccountDownloadedData(downloadAccountData);
    }
  }, [downloadAccountData, downloadAccountStatus]);

  useEffect(() => {
    if (
      downloadAccountStatus === "fulfilled" &&
      accountDownloadButtonClicked &&
      accountDownloadedData?.length >= 0
    ) {
      setAccountDownloadButtonClicked(false);

      setAccountDownloadLoading(false);
      downloadAccountsData();
    }
  }, [
    downloadAccountStatus,
    accountDownloadButtonClicked,
    accountDownloadedData,
  ]);

  const openAccountModal = () => {
    setIsAccountModalOpen(true);
  };

  const openContactModal = () => {
    setIsContactModalOpen(true);
  };

  const closeModal = () => {
    setIsAccountModalOpen(false);
    setIsContactModalOpen(false);
  };

  const handleSwitchToggle = () => {
    setShowAccountButton(!showAccountButton);
    // Update the route based on the toggle
    if (!showAccountButton) {
      navigate("account");
    } else {
      navigate("contact");
    }
  };

  useEffect(() => {
    if (location.pathname.includes("/account")) {
      setShowAccountButton(true);
    } else if (location.pathname.includes("/contact")) {
      setShowAccountButton(false);
    }
  }, [location.pathname]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    overflow: "scroll",
    bgcolor: "background.paper",
    border: "1px solid transparent",
    boxShadow: 2,
    borderRadius: "5px",
    p: 4,
  };

  const downloadAccountsData = async () => {
    setAccountDownloadLoading(true);
    setAccountDownloadButtonClicked(true);

    if (accountDownloadedData && accountDownloadedData.length > 0) {
      const csvData = accountDownloadedData?.map((user) => ({
        Name: user.name,
        Tags: user?.tags?.map((tag) => tag.name).join(","),
        "Created date": user.created ? formatDate(user.created) : "",
      }));

      const worksheet = XLSX.utils.json_to_sheet(csvData);

      const csvOutput = XLSX.utils.sheet_to_csv(worksheet);

      const blob = new Blob([csvOutput], {
        type: "text/csv;charset=utf-8;",
      });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "Accounts_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setAccountDownloadLoading(false);
      setAccountDownloadButtonClicked(false);
      setAccountDownloadedData([]);
    } else {
      setAccountDownloadLoading(false);
    }
  };

  const downloadContactData = async () => {
    setDownloadButtonClicked(true);
    setContactDownloadLoading(true);

    if (ContactDownloadedData && ContactDownloadedData.length > 0) {
      const csvData = ContactDownloadedData.map((user) => ({
        Name: user?.name,
        Email: user?.email,
        Tags: user.tags?.map((tag) => tag.name).join(","),
        "Created date": user.created ? formatDate(user.created) : "",
        "Last interaction date": user?.last_interaction_date
          ? formatDate(user?.last_interaction_date)
          : "",
        Account: user?.account?.name,
      }));

      const worksheet = XLSX.utils.json_to_sheet(csvData);
      const csvOutput = XLSX.utils.sheet_to_csv(worksheet);
      const blob = new Blob([csvOutput], {
        type: "text/csv;charset=utf-8;",
      });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);

      link.setAttribute("href", url);
      link.setAttribute("download", "Contact_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setContactDownloadLoading(false);
      setDownloadButtonClicked(false);
      setContactDownloadedData([]);
    } else {
      setContactDownloadLoading(false);
    }
  };

  const openFilterModal = () => {
    setFilterIsModalOpen(true);
  };

  const closeFilterModal = () => {
    setFilterIsModalOpen(false);
  };

  const openAccountsFilterModal = () => {
    setIsAccountFilterIsModalOpen(true);
  };

  const closeAccountsFilterModal = () => {
    setIsAccountFilterIsModalOpen(false);
  };

  const customFilterModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100%", // Default to full width
      maxWidth: "30%",
      margin: "auto",
      Overflow: "hidden",
      OverflowY: "auto",
    },
  };

  useEffect(() => {
    setLiftingAccountFilterData({
      name: "",
      tags: "",
      createdDate: "",
    });

    setLiftingContactFilterData({
      name: "",
      email: "",
      tags: "",
      createdDate: "",
    });
  }, [showAccountButton]);

  const tableHeader = ["Accounts", "Created Date", "Tag", "Action"];
  const contactHeader = [
    "Name",
    "Email",
    "Created Date",
    "Last Interaction Date",
    "Tags",
    "Account",
    "Action",
  ];

  useEffect(() => {
    setPage(1);
    setAccountPage(1);
  }, [showAccountButton]);

  const handleBackButtonClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="custom-style">
      <div id="kt_app_content_container" className="app-container mx-5">
        <div className="card card-flush">
          <div className="card-header align-items-center py-1 gap-2 gap-md-5">
            <div className="d-flex">
              <button
                className="btn btn-link mb-2"
                onClick={handleBackButtonClick}
              >
                <i className="bi bi-arrow-90deg-left"     style={{
                                        fontSize: "20px",
                                        color: "black",
                                        cursor: "pointer",
                                    }}></i> {/* Arrow icon */}
              </button>
              <h2 className="p-4">
                {showAccountButton ? "Account" : "Customer"}
              </h2>
            </div>
            <div>
              {showAccountButton ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    // marginBottom: "10px",
                  }}
                >
                  <Tooltip title="Download Accounts Data">
                    <IconButton
                      onClick={() => {
                        setAccountDownloadButtonClicked(true);

                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        downloadAccountsData;
                      }}
                    >
                      {accountDownloadLoading ? (
                        <Loader />
                      ) : (
                        <i
                          className="bi bi bi-download"
                          style={{
                            fontSize: "25px",
                            color: "black",
                          }}
                        ></i>
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Tooltip title="Download Contact Data">
                    <IconButton
                      disabled={downloadContactData?.length < 0}
                      onClick={() => {
                        setDownloadButtonClicked(true);
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        downloadContactData;
                      }}
                    >
                      {contactDownloadLoading ? (
                        <Loader />
                      ) : (
                        <i
                          className="bi bi bi-download"
                          style={{
                            fontSize: "25px",
                            color: "black",
                          }}
                        ></i>
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>

          <div className="card-body" style={{ paddingBottom: "0px" }}>
            <div className="row">
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                {/* <div style={{ display: "flex", gap: "20px" }}> */}

                {/* toggle button */}

                {/* Search filter */}
                <div
                  style={{
                    marginRight: "20px",
                    width: "40%",
                  }}
                >
                  <Button
                    variant="outlined"
                    className="text-hover-primary"
                    onClick={
                      !showAccountButton
                        ? openFilterModal
                        : openAccountsFilterModal
                    }
                    sx={{
                      height: 40,
                      width: "25%",
                      border: "1px solid #ccc",
                      display: "flex",
                      gap: "10px",
                      overflow: "hidden",
                    }}
                  >
                    <i className="bi bi-filter text-dark fs-2" />
                    <span
                      className=" me-2 text-dark"
                      style={{
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        width: "100%",
                        textTransform: "capitalize",
                      }}
                    >
                      Search filter
                    </span>
                  </Button>
                </div>
                {/* </div> */}

                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    justifyContent: "end",
                    gap: "40px",
                  }}
                >
                  <div
                    style={{
                      // width: "10%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <label
                      className="form-check-label mx-2"
                      style={{
                        fontSize: "16px",
                        color: showAccountButton ? "#009ef7" : "",
                      }}
                    >
                      {showAccountButton} Account
                    </label>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={handleSwitchToggle}
                        checked={!showAccountButton}
                      />
                    </div>
                    <label
                      className="card-text"
                      style={{
                        fontSize: "16px",
                        color: !showAccountButton ? "#009ef7" : "",
                      }}
                    >
                      {!showAccountButton} Contact
                    </label>
                  </div>
                  <div style={{ width: "20%" }}>
                    {showAccountButton ? (
                      <button
                        className="form-control form-control align-items-center bg-secondary text-hover-primary fw-bold max-w-150px min-w-100px"
                        onClick={openAccountModal}
                      >
                        <i className="bi bi-plus-lg me-2" />
                        <span className="ms-auto" style={{ fontSize: "16px" }}>
                          Account
                        </span>
                      </button>
                    ) : (
                      <button
                        className="form-control form-control align-items-center bg-secondary text-hover-primary fw-bold max-w-150px min-w-100px"
                        onClick={openContactModal}
                        // style={{width: "100%"}}
                      >
                        <i className="bi bi-plus-lg me-2" />
                        <span className="ms-auto" style={{ fontSize: "16px" }}>
                          Contact
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-1 d-flex align-items-center justify-content-center"></div> */}

              {/* Modals */}

              <Modal
                open={isAccountModalOpen}
                onClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                BackdropProps={{
                  style: {
                    backgroundColor: "rgba(0, 0, 0, 0.2)", // Adjust the opacity here
                  },
                }}
              >
                <Box sx={{ ...style, position: "absolute" }}>
                  <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    sx={{
                      position: "fixed",
                      top: 16,
                      right: 16,
                      zIndex: 1301,
                      background: "white",
                    }} // Ensure it's above the modal content
                  >
                    <GridCloseIcon />
                  </IconButton>
                  <Typography
                    id="modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mb: 2 }}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Account
                  </Typography>

                  <Box>
                    <AccountModal
                      closeModal={closeModal}
                      refetchingData={accountRefetch}
                    />
                  </Box>
                </Box>
              </Modal>

              <Modal
                open={isContactModalOpen}
                onClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                BackdropProps={{
                  style: {
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <Box sx={{ ...style, position: "absolute" }}>
                  <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    sx={{
                      position: "fixed",
                      top: 16,
                      right: 16,
                      zIndex: 1301,
                      background: "white",
                    }}
                  >
                    <GridCloseIcon />
                  </IconButton>
                  <Typography
                    id="modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mb: 2 }}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Contact
                  </Typography>

                  <Box>
                    <ContactModal
                      closeModal={closeModal}
                      refetchingData={contactRefetch}
                    />
                  </Box>
                </Box>
              </Modal>

              <ReactModal
                isOpen={isFilterModalOpen}
                onRequestClose={closeFilterModal}
                style={customFilterModalStyles}
                contentLabel="Ticket Filter Modal"
              >
                <ContactFilterModal
                  closeFilterModal={closeFilterModal}
                  setSelectAccount={setSelectAccount}
                  selectAccount={selectAccount}
                  setLiftingContactFilterData={setLiftingContactFilterData}
                  setPage={setPage}
                  contactDataa={contactData}
                  liftingContactFilterData={liftingContactFilterData}
                  contactCardLoading={contactCardLoading}
                />
              </ReactModal>

              <ReactModal
                isOpen={isAccountFilterModalOpen}
                onRequestClose={closeAccountsFilterModal}
                style={customFilterModalStyles}
                contentLabel="Ticket Filter Modal"
              >
                <AccountFilterModal
                  closeFilterModal={closeAccountsFilterModal}
                  setLiftingContactFilterData={setLiftingAccountFilterData}
                  setPage={setAccountPage}
                  contactDataa={contactData}
                  liftingContactFilterData={liftingAccountFilterData}
                />
              </ReactModal>
            </div>

            <div className="gap-5">
              {showAccountButton ? (
                <div className="row">
                  <div
                    className="col"
                    style={{
                      marginBottom: "10px",
                      padding: "5px",
                      border: "0px",
                    }}
                  >
                    <div
                      className="card mb-n5 my-1 p-4"
                      style={{
                        backgroundColor: "#E4E4E47F",
                      }}
                    >
                      <div className="row align-items-center">
                        {tableHeader?.map((el, index) => (
                          <div className="col text-center" key={index}>
                            <label
                              htmlFor=""
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: el !== "Accounts" && "center",
                              }}
                            >
                              <strong
                                style={{
                                  display: "flex",
                                  gap: "2px",
                                  // justifyContent:
                                  //     el === "Action"
                                  //         ? "center"
                                  //         : "",
                                  // padding: el === "Action" ? "0 70px" : ""
                                }}
                              >
                                {el}
                                {el !== "Tag" && el !== "Action" && (
                                  <SortingTableContact
                                    label={
                                      el === "Accounts"
                                        ? "name"
                                        : el === "Created date"
                                        ? "created"
                                        : el
                                    }
                                    setLiftingColumn={setLiftingColumn}
                                  />
                                )}
                              </strong>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div
                    className="col"
                    style={{
                      marginBottom: "10px",
                      padding: "5px",
                      border: "0px",
                    }}
                  >
                    <div
                      className="card mb-n5 my-1 p-4"
                      style={{
                        backgroundColor: "#E4E4E47F",
                      }}
                    >
                      <div className="row align-items-center">
                        {contactHeader?.map((el, index) => (
                          <div className="col text-start" key={index}>
                            <label
                              htmlFor=""
                              style={{
                                width: "100%",
                              }}
                            >
                              <strong
                                style={{
                                  display: "flex",
                                  gap: "2px",
                                  justifyContent:
                                    el === "Action" ? "center" : "",
                                  paddingRight: el === "Action" ? "20px" : "",
                                }}
                              >
                                {el}
                                {el !== "Account" &&
                                  el !== "Action" &&
                                  el !== "Tags" &&
                                  el !== "Last interaction date" && (
                                    <SortingTableContact
                                      label={
                                        el === "Name"
                                          ? "name"
                                          : el === "Email"
                                          ? "email"
                                          : el === "Created date"
                                          ? "created"
                                          : el === "Last interaction date"
                                          ? "last_interaction_date"
                                          : el
                                      }
                                      setLiftingColumn={setLiftingColumn}
                                    />
                                  )}
                              </strong>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <Routes>
                <Route
                  path="account"
                  element={
                    <AccountCards
                      setLiftingColumn={setLiftingColumn}
                      accountCardLoading={accountCardLoading}
                      accountData={accountData}
                      totalCount={totalCount}
                      refetch={accountRefetch}
                      setPage={setAccountPage}
                      page={accountPage}
                      AccountQueryLoading={AccountQueryLoading}
                    />
                  }
                />
                <Route
                  path="contact"
                  element={
                    <ContactCards
                      setLiftingColumn={setLiftingColumn}
                      setPage={setPage}
                      page={page}
                      contactDataa={contactData}
                      refetch={
                        mobileNumber ? gettingUserDataResponse : contactRefetch
                      }
                      contactCardLoading={contactCardLoading}
                      contactQueryLoading={contactQueryLoading}
                      totalCount={totalCount}
                      setLiftingContactLocationData={
                        setLiftingContactLocationData
                      }
                    />
                  }
                />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerPage;
