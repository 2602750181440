import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CloseButton } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import clsx from "clsx";
import { useFormik } from "formik";
import ReactModal from "react-modal";
import { Button, IconButton, Tooltip } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InfoIcon from "@mui/icons-material/Info";
import {
  useCreateSchedulerMutation,
  useDispositionQuery,
  useGetDispositionStatusQuery,
  useTicketDynamicFieldsQuery,
  useUpdateSchedulerMutation,
} from "../../../../Services/ticket/CreateTicket";

import moment from "moment";
import { useTeamsQuery } from "../../../../Services/settingsAPIs/CommonAPI";
import axios from "axios";
import {
  Autocomplete,
  Checkbox,
  MenuItem,
  Select,
  TextField,
  FormControl,
  Box,
  Chip,
} from "@mui/material";
import EmailTemplateModal from "../../../email/EmailTemplateModal";
import { AUTH_TOKEN, USER_TEAM_ID } from "../../../../constants";
import LoadingSpinner from "../../../LoadingSpinner";
import { Loader } from "react-bootstrap-typeahead";
import "react-phone-number-input/style.css";
import { PhoneInput } from "react-international-phone";
import AddNewTicketContactModal from "../../../ticket/AddNewTicketContactModal";
interface IProps {
  closeNewScheduleModal: () => void;
  scheduleForEdit: any;
  selectedTeam: any;
}

const NewScheduleModal: React.FC<IProps> = ({
  closeNewScheduleModal,
  scheduleForEdit,
  selectedTeam,
}) => {
  const [createSchedule] = useCreateSchedulerMutation();
  const [loadingOl, setIsLoading] = useState<boolean>(false);
  const [isAddNewContactModalOpen, setAddNewContactModalOpen] = useState(false);
  const { data: teamsData } = useTeamsQuery({});
  const [theTeams, setTheTeams] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedContact, setSelectedContact] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [emailNotPresent, setEmailNotPresent] = useState<any>(false);
  const [selectedTemplateDescription, setSelectedTemplateDescription] =
    useState<any>("");
  const [ticketName, setTicketName] = useState<any>("");
  const [storeTemplateData, setStoreTemplateData] = useState<any>([]);
  const [templateAttachment, setTemplateAttachment] = useState<any[]>([]);
  const [externalNotetextareaValue, setExternalNoteTextareaValue] = useState(
    []
  );
  const [internalNoteValue, setInternalNoteValue] = useState([]);
  const [fetchTemplate, setFetchTemplates] = useState(false);

  const [showAdditionalField, setShowAdditionalField] = useState(false);
  const [dynamicFieldData, setDynamicFieldData] = useState<any[]>([]);
  const { data: ticketDynamicFields } = useTicketDynamicFieldsQuery({});
  const [updateScheduleMutation] = useUpdateSchedulerMutation();
  const [checkValue, setCheckValue] = useState<any>([]);
  const [files, setFiles] = useState([]);
  const [storeNewEmailId, setStoreNewEmailId] = useState("");

  const CustomTelInput = React.forwardRef((props, ref) => (
    <>
      <input
        className={clsx("form-control form-control-lg form-control-solid")}
        type="tel"
        autoComplete="off"
      />
    </>
  ));
  const scheduleEditEnabled = scheduleForEdit?.scheduleEditEnabled;
  const scheduleData = scheduleForEdit?.scheduleData;

  useEffect(() => {
    if (ticketName) {
      let filterDataById = ticketDynamicFields.filter(
        (name) => name?.ticket_name === ticketName
      );
      setDynamicFieldData(filterDataById);
    }
  }, [ticketDynamicFields, ticketName]);

  const getTeamId = localStorage.getItem(USER_TEAM_ID);

  const { data: Disposition } = useDispositionQuery(getTeamId);
  const [selectedIds, setSelectedIds] = useState([]);
  const [options, setOptions] = useState(Disposition);
  const [lastSelectedId, setLastSelectedId] = useState(null);
  const [skipDispositionQuery, setSkipDispositionQuery] =
    useState<boolean>(true);

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "700px",
      height: "600px",
    },
  };
  const {
    data: fetchDispositionStatus,
    isLoading,
    error,
  } = useGetDispositionStatusQuery(lastSelectedId, {
    skip: skipDispositionQuery,
  });

  const apiPort = process.env.REACT_APP_API_PROTOCOL;

  useEffect(() => {
    setCheckValue(Disposition);
  }, [Disposition]);

  useEffect(() => {
    if (lastSelectedId) {
      const dispositionItem = checkValue?.find(
        (item) =>
          item.id === lastSelectedId ||
          item.sub_topics.some((sub) => sub.id === lastSelectedId)
      );
      setTicketName(dispositionItem?.ticket_name);

      if (dispositionItem) {
        // If the lastSelectedId matches a disposition or a sub-topic,
        // find the specific sub-topic if lastSelectedId is within sub-topics
        if (dispositionItem?.sub_topics.length > 0) {
          const matchingSubTopic = dispositionItem?.sub_topics;
          setCheckValue(matchingSubTopic);
          setSkipDispositionQuery(true);
          setShowAdditionalField(false);
        } else {
          setSkipDispositionQuery(false);
          setShowAdditionalField(true);
        }
      }
    }
  }, [lastSelectedId]);

  const createTicketSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    customerName: Yup.string().required("Customer Name is required"),
    ticketChannel: Yup.string().required("Ticket Channel is required"),
    disposition: Yup.string().required("Disposition is required"),
    dispositionDescription: Yup.string().required(
      "Disposition Description is required"
    ),
    status: Yup.string().required("Status is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: scheduleEditEnabled ? scheduleData?.title || "" : "",
      description: scheduleEditEnabled ? scheduleData?.description || "" : "",
      contact: scheduleEditEnabled ? scheduleData?.contact?.[0]?.id || "" : "",
      ticketChannel: scheduleEditEnabled
        ? scheduleData?.ticket_channel || ""
        : "",
      team: scheduleEditEnabled ? scheduleData?.team?.[0]?.id || "" : "",
      disposition: scheduleEditEnabled
        ? scheduleData?.disposition?.[0]?.id || ""
        : "",
      dispositionDescription: scheduleEditEnabled
        ? scheduleData?.disposition_description || ""
        : "",
      status: scheduleEditEnabled ? scheduleData?.status_type || "" : "",
      status_id: scheduleEditEnabled ? scheduleData?.status || "" : "",
      internalNotes: [],
      customerNotes: [],
      attachments: [],
      dynamic_fields: (dynamicFieldData || []).reduce((acc, field) => {
        acc[field.label] = scheduleEditEnabled
          ? scheduleData.dynamic_fields?.[field.label] ||
            (field.field_type === "check-box" ? [] : "")
          : field.field_type === "check-box"
          ? []
          : "";
        return acc;
      }, {}),
      date_from: scheduleEditEnabled
        ? scheduleData?.date_from
          ? new Date(scheduleData?.date_from).toISOString().split("T")[0]
          : ""
        : "",
      date_to: scheduleEditEnabled
        ? scheduleData?.date_to
          ? new Date(scheduleData?.date_to).toISOString().split("T")[0]
          : ""
        : "",
      daily_time: scheduleEditEnabled ? scheduleData?.daily_time || "" : "",
      schedule_timer: scheduleEditEnabled
        ? scheduleData?.schedule_timer || ""
        : "",
    },
    // validationSchema: combinedSchema,
    onSubmit: async (values) => {
      // Ensure status_id is set for status types that require it
      if (values.status === "wip" && !values.status_id) {
        // Handle missing status_id for WIP status type
        toast.error("Please specify status ID for WIP status type.");
        return;
      }

      // Format date-time fields
      const formattedDynamicFields = { ...values.dynamic_fields };
      dynamicFieldData.forEach((field) => {
        if (field.field_type === "date" && values.dynamic_fields[field.label]) {
          formattedDynamicFields[field.label] = moment(
            values.dynamic_fields[field.label]
          ).format("YYYY-MM-DDTHH:mm:ssZ");
        }
      });

      setIsLoading(true); // Set loading state
      try {
        // Call the createTicket mutation function with the form values
        if (scheduleEditEnabled) {
          const response = await updateScheduleMutation({
            body: {
              title: values.title,
              description: values.description,
              contact: selectedContact.id,
              ticket_channel: values.ticketChannel,
              team: selectedTeam,
              disposition: lastSelectedId,
              disposition_description: values.dispositionDescription,
              dynamic_fields: formattedDynamicFields,
              status: values.status === "wip" ? values.status_id : null, // WIP
              closed_status:
                values.status === "closed" ? values.status_id : null, // closed
              status_type: values.status, // Use status type
              customer_notes: externalNotetextareaValue,
              internal_notes: internalNoteValue,
              attachments: files,
              template_attachments: templateAttachment?.map(
                (attachment) => attachment?.id
              ),
              date_from: new Date(values.date_from).toISOString(),
              date_to: new Date(values.date_to).toISOString(),
              daily_time: values.daily_time,
              schedule_timer: values.schedule_timer,
            },
            id: scheduleData?.id,
          });

          // Handle successful creation
          if ("data" in response) {
            setIsLoading(false); // Reset loading state
            closeNewScheduleModal(); // Close modal
            toast.success("Ticket updated successfully!");
          } else if ("error" in response) {
            // Error response handling
            const errorData = response.error as any;

            // Check if error data exists and contains the 'data' field
            if (errorData?.data) {
              // Get the first error message from the error data
              const errorMessages = Object.entries(errorData.data).map(
                ([field, message]: any) => `${field}: ${message.join(", ")}`
              );

              // Show the error messages in the toast (join multiple messages if needed)
              setIsLoading(false); // Reset loading state

              toast.error(errorMessages.join(" | ") || "An error occurred.");
            } else {
              toast.error("An error occurred.");
            }
          } else {
            toast.error("An unexpected error occurred.");
          }
        } else {
          const response = await createSchedule({
            title: values.title,
            description: values.description,
            contact: selectedContact.id,
            ticket_channel: values.ticketChannel,
            team: selectedTeam,
            disposition: lastSelectedId,
            disposition_description: values.dispositionDescription,
            dynamic_fields: formattedDynamicFields,
            status: values.status === "wip" ? values.status_id : null, // WIP
            closed_status: values.status === "closed" ? values.status_id : null, // closed
            status_type: values.status, // Use status type
            customer_notes: externalNotetextareaValue,
            internal_notes: internalNoteValue,
            attachments: files,
            template_attachments: templateAttachment?.map(
              (attachment) => attachment?.id
            ),
            date_from: new Date(values.date_from).toISOString(),
            date_to: new Date(values.date_to).toISOString(),
            daily_time: values.daily_time,
            schedule_timer: values.schedule_timer,
          });

          // Handle successful creation
          if ("data" in response) {
            setIsLoading(false); // Reset loading state
            closeNewScheduleModal(); // Close modal
            toast.success("Ticket scheduled successfully!"); // Show success toast
          } else if ("error" in response) {
            // Error response handling
            const errorData = response.error as any;

            // Check if error data exists and contains the 'data' field
            if (errorData?.data) {
              // Get the first error message from the error data
              const errorMessages = Object.entries(errorData.data).map(
                ([field, message]: any) => `${field}: ${message.join(", ")}`
              );

              // Show the error messages in the toast (join multiple messages if needed)
              setIsLoading(false); // Reset loading state

              toast.error(errorMessages.join(" | ") || "An error occurred.");
            } else {
              toast.error("An error occurred.");
            }
          } else {
            toast.error("An unexpected error occurred.");
          }
        }
      } catch (error) {
        setIsLoading(false); // Reset loading state
        if (error.response?.status === 400) {
          const errorMessage =
            error.response.data?.account_pattern?.[0] ||
            "Failed to create ticket. Please check all required fields.";
          toast.error(errorMessage);
        } else {
          toast.error(
            "Failed to create ticket. Please check all required fields."
          );
        }
      }
    },
  });

  const fetchingTenant = window.location.hostname.split(".")[0];

  const searchContactByMailID = (skey: string) => {
    axios
      .get(
        `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/customers/contacts/search-by-email/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
          },
          params: {
            email: skey,
          },
        }
      )
      .then((res: any) => {
        if (res.status === 200) setSuggestions(res.data);
      })
      .catch((err) => console.log(err));
  };

  const openContactModal = () => {
    setAddNewContactModalOpen(true);
  };

  const closeContactModal = () => {
    setAddNewContactModalOpen(false);
  };

  useEffect(() => {
    teamsData && setTheTeams(teamsData);
  }, [teamsData]);

  const openTemplateModal = async () => {
    setIsModalOpen(true);
    setFetchTemplates(true);

    const response = await axios.get(
      `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/emails/templates/?page=1&`,
      {
        headers: {
          Authorization: `BEARER ${localStorage.getItem(AUTH_TOKEN)}`,
        },
      }
    );
    setStoreTemplateData(response?.data);
  };

  const closeTemplateModal = () => {
    setIsModalOpen(false);
  };

  //External Note

  const externalNotehandler = useCallback(
    (e: any) => {
      const values = e.target.value
        .split("\n")
        .map((line: any) => ({ content: line, attachments: [] }));
      setExternalNoteTextareaValue(values);
    },
    [setExternalNoteTextareaValue]
  );

  useEffect(() => {
    if (selectedTemplateDescription && selectedTemplateDescription.content) {
      setExternalNoteTextareaValue(
        Array.isArray(selectedTemplateDescription.content)
          ? selectedTemplateDescription.content.map((item) => ({
              content: item,
            }))
          : [{ content: selectedTemplateDescription.content, attachments: [] }]
      );
    }
  }, [selectedTemplateDescription]);

  const internalNotehandler = (e) => {
    const newValue = e.target.value;

    if (newValue.trim() === "") {
      setInternalNoteValue([]);
    } else {
      // Otherwise, update the state with the new value
      const updatedNotes = [
        {
          content: newValue,
          attachments: [],
        },
      ];
      setInternalNoteValue(updatedNotes);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const level = parseInt(name.replace("Level", ""));

    const newSelectedIds = [...selectedIds];
    newSelectedIds[level] = parseInt(value);
    setSelectedIds(newSelectedIds);
    // Update last selected ID
    setLastSelectedId(parseInt(value));

    // Determine options for the next level
    let currentOptions = Disposition;
    for (let i = 0; i < level; i++) {
      const selectedId = newSelectedIds[i];
      if (selectedId !== undefined) {
        const found = currentOptions.find((item) => item.id === selectedId);
        if (found) {
          currentOptions = found.sub_topics;
        } else {
          currentOptions = [];
          break;
        }
      }
    }
    setOptions(currentOptions);
  };

  const renderDropdowns = (data, level) => {
    // Avoid rendering if there are no data items
    if (!data || data.length === 0) {
      return null;
    }

    return (
      <>
        {level > 0 && (
          <label className="required mt-4">Select Disposition</label>
        )}
        <select
          name={`Level${level}`}
          className="form-select form-select-lg form-select-solid"
          onChange={handleChange}
          value={selectedIds[level] || ""}
        >
          <option value="" disabled>
            Select
          </option>
          {data.map((option) => (
            <option key={option.id} value={option.id}>
              {option.topic}
            </option>
          ))}
        </select>
        {selectedIds[level] &&
          renderDropdowns(
            data.find((item) => item.id === selectedIds[level])?.sub_topics ||
              [],
            level + 1
          )}
      </>
    );
  };

  const handleDeleteFile = (fileToDelete) => () => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };

  const formatStatusData = (status) => {
    if (typeof status.status === "string") {
      // Open and Reopen statuses (no ID)
      return {
        status_type: status.status_type,
        status: status.status,
        id: null, // No ID for statuses without ID
      };
    } else {
      // WIP and Closed statuses (with ID)
      return {
        id: status.status.id,
        status_type: status.status_type,
        status: status.status.status,
      };
    }
  };

  const statusOptions = fetchDispositionStatus?.all_statuses?.map((status) =>
    formatStatusData(status)
  );

  const handleFileChange = async (event) => {
    const newFiles = Array.from(event.target.files);
    const filePromises = newFiles.map((file: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const fileData = {
            file_name: file.name,
            file: e.target.result.split(",")[1], // Extracting Base64 part
          };
          resolve(fileData);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });

    try {
      const fileDataArray = await Promise.all(filePromises);
      setFiles((prevFiles) => [...prevFiles, ...fileDataArray]);
    } catch (error) {
      console.error("Error reading files:", error);
      toast.error("An error occurred while uploading files. Please try again.");
    }
  };

  useEffect(() => {
    if (error !== undefined) {
      toast.error("No ticket name for the selected disposition");
    }
  }, [error]);

  const handleEmailChange = (event) => {
    const email = event.target.value;
    searchContactByMailID(email);
    setEmailNotPresent(false);
    setStoreNewEmailId(email);

    // Check if the current email matches any suggestion
    // const emailExists = suggestions.some(
    //   (suggestion) => suggestion.email === email
    // );
    // setEmailNotPresent(!emailExists);
  };

  const handleEmailBlur = (event) => {
    const email = event.target.value;
    // Show error if the email is not selected from suggestions
    const emailExists = suggestions.some(
      (suggestion) => suggestion.email === email
    );

    if (!emailExists) {
      setEmailNotPresent(true);
    }
  };
  useEffect(() => {
    if (scheduleForEdit?.scheduleEditEnabled && scheduleData?.contact?.[0]) {
      const initialContact = scheduleData.contact[0];
      setSelectedContact(initialContact);
      formik.setFieldValue("email", initialContact.email);
      formik.setFieldValue("customerName", initialContact.name);
      setEmailNotPresent(false);
    }
  }, [scheduleForEdit, scheduleData]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="text-end">
        <CloseButton onClick={closeNewScheduleModal} />
      </div>
      <div className="text-center mb-4">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          {scheduleForEdit?.scheduleEditEnabled
            ? "Update Ticket Schedule"
            : "Schedule a Ticket"}
        </label>
      </div>

      <div className="w-100 mb-4">
        <label className="required">Title</label>
        <input
          {...formik.getFieldProps("title")}
          className="form-control form-control-lg form-control-solid" // {clsx(
          //   {
          //     "is-invalid": formik.touched.title && formik.errors.title,
          //   },
          //   {
          //     "is-valid": formik.touched.title && !formik.errors.title,
          //   }
          // )}
          type="text"
          autoComplete="off"
        />
        {/* {formik.touched.title && formik.errors.title && (
        <div className="fv-plugins-message-container text-danger">
          <span role="alert">{formik.errors.title}</span>
        </div>
      )} */}
      </div>

      <div className="w-100 mb-4">
        <label className="">Ticket Description</label>
        <textarea
          {...formik.getFieldProps("description")}
          className="form-control form-control-lg form-control-solid" // {clsx(
          //   {
          //     "is-invalid":
          //       formik.touched.description && formik.errors.description,
          //   },
          //   {
          //     "is-valid":
          //       formik.touched.description && !formik.errors.description,
          //   }
          // )}
          rows={3}
          autoComplete="off"
        />
        {/* {formik.touched.description && formik.errors.description && (
        <div className="fv-plugins-message-container text-danger">
          <span role="alert">{formik.errors.description}</span>
        </div>
      )} */}
      </div>

      <div className="text-center mb-3">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Customer Details
        </label>
      </div>

      <div className="row g-5 g-xl-8 mb-4">
        <div className="col-xl-6">
          <label className="required">Email id</label>
          <Autocomplete
            freeSolo
            options={suggestions.map((option) => option.email)}
            value={selectedContact ? selectedContact.email : ""}
            onInputChange={(event, newInputValue) => {
              searchContactByMailID(newInputValue);
            }}
            onChange={(event, newValue) => {
              const selectedSuggestion = suggestions.find(
                (suggestion) => suggestion.email === newValue
              );
              setSelectedContact(selectedSuggestion ? selectedSuggestion : 0);
              formik.setFieldValue(
                "customerName",
                selectedSuggestion ? selectedSuggestion.name : ""
              );
              formik.setFieldValue("email", newValue);

              // Reset email not present error when an option is selected
              setEmailNotPresent(false);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className="form-control form-control-lg form-control-solid"
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                error={emailNotPresent}
              />
            )}
          />
          {emailNotPresent && storeNewEmailId !== "" ? (
            <div className="fv-plugins-message-container text-danger">
              <span role="alert">
                Email is not present. Please create a new contact.
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="col-xl-6">
          <label className="required">Customer Name</label>
          <input
            {...formik.getFieldProps("customerName")}
            className="form-control form-control-lg form-control-solid" // {clsx(
            //   {
            //     "is-invalid":
            //       formik.touched.customerName && formik.errors.customerName,
            //   },
            //   {
            //     "is-valid":
            //       formik.touched.customerName && !formik.errors.customerName,
            //   }
            // )}
            type="text"
            autoComplete="off"
            value={emailNotPresent ? "" : selectedContact?.name}
            disabled
          />
          {/* {formik.touched.customerName && formik.errors.customerName && (
          <div className="fv-plugins-message-container text-danger">
            <span role="alert">{formik.errors.customerName}</span>
          </div>
        )} */}
        </div>
        {emailNotPresent && storeNewEmailId !== "" && (
          <div className="text-end mt-6 mb-4">
            <button
              className="btn btn-sm btn-secondary"
              onClick={openContactModal}
              type="button"
            >
              <i className="bi bi-plus fs-2 me-2"></i>
              Create Contact
            </button>
          </div>
        )}
        <ReactModal
          isOpen={isAddNewContactModalOpen}
          onRequestClose={() => setAddNewContactModalOpen(false)}
          style={customModalStyles}
          contentLabel="New Contact"
        >
          <AddNewTicketContactModal
            closeModal={() => setAddNewContactModalOpen(false)}
            emailNotPresent={emailNotPresent}
            setSelectedContact={setSelectedContact}
            storeNewEmailId={storeNewEmailId}
            setEmailNotPresent={setEmailNotPresent}
          />
        </ReactModal>
      </div>

      <div className="text-center mb-3">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Channel
        </label>
      </div>

      <div className="row g-5 g-xl-8 mb-8">
        <div className="col-xl-6">
          <label className="required">Select Channel</label>
          <select
            {...formik.getFieldProps("ticketChannel")}
            className="form-select form-select-lg form-select-solid" // {clsx(
            //   {
            //     "is-invalid":
            //       formik.touched.ticketChannel && formik.errors.ticketChannel,
            //   },
            //   {
            //     "is-valid":
            //       formik.touched.ticketChannel && !formik.errors.ticketChannel,
            //   }
            // )}
          >
            <option value="" label="Select" />
            <option value="inbound_phone">Inbound Phone</option>
            <option value="outbound_phone">Outbound Phone</option>
            <option value="app">App</option>
            <option value="Email">Email</option>
            <option value="Chat">Chat</option>
            <option value="Social-Media">Social Media</option>
            <option value="Whatsapp">WhatsApp</option>
          </select>
          {/* {formik.touched.ticketChannel && formik.errors.ticketChannel && (
          <div className="fv-plugins-message-container text-danger">
            <span role="alert">{formik.errors.ticketChannel}</span>
          </div>
        )} */}
        </div>
        {/* <div className="col-xl-6">
        <label>Assign to Team</label>
        <select
          className="form-select form-select-lg form-select-solid"
          value={selectedTeams}
          onChange={(e: any) => setSelectedTeams(e.target.value)}
        >
          <option value="">Select User</option>
          {theTeams.map((team: any) => (
            <option key={team.id} value={team.id}>
              {team.name}
            </option>
          ))}
        </select>
      </div> */}
      </div>

      <div className="text-center mb-3">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Disposition
        </label>
      </div>

      <div className="row g-5 g-xl-8 mb-6">
        <div className="col-xl-6">
          <label className="required">Disposition</label>
          {renderDropdowns(Disposition, 0)}
        </div>

        <div className="col-xl-6">
          <label className="required">Remarks</label>
          <textarea
            {...formik.getFieldProps("dispositionDescription")}
            className="form-control form-control-lg form-control-solid" // {clsx(
            //   {
            //     "is-invalid":
            //       formik.touched.dispositionDescription &&
            //       formik.errors.dispositionDescription,
            //   },
            //   {
            //     "is-valid":
            //       formik.touched.dispositionDescription &&
            //       !formik.errors.dispositionDescription,
            //   }
            // )}
            rows={1}
            autoComplete="off"
          />
          {/* {formik.touched.dispositionDescription &&
          formik.errors.dispositionDescription && (
            <div className="fv-plugins-message-container text-danger">
              <span role="alert">{formik.errors.dispositionDescription}</span>
            </div>
          )} */}
        </div>
      </div>

      <div className="text-center mb-3">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Ticket Details
        </label>
      </div>

      <div className="row g-5 g-xl-8 mb-4">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {showAdditionalField &&
              error === undefined &&
              dynamicFieldData.map((field: any) => (
                <div key={field.label} className="col-xl-6">
                  <label htmlFor={`dynamic_fields.${field.label}`}>
                    {field.label}
                    {field.is_required && <span className="required"></span>}
                  </label>
                  {/* Add validation for text input */}
                  {field.field_type === "text" && field.is_editable && (
                    <div>
                      <input
                        {...formik.getFieldProps(
                          `dynamic_fields.${field.label}`
                        )}
                        id={`dynamic_fields.${field.label}`}
                        className="form-control form-control-lg form-control-solid" // {clsx(
                        //   {
                        //     "is-invalid":
                        //       formik.touched.dynamic_fields?.[field.label] &&
                        //       formik.errors.dynamic_fields?.[field.label],
                        //   },
                        //   {
                        //     "is-valid":
                        //       formik.touched.dynamic_fields?.[field.label] &&
                        //       !formik.errors.dynamic_fields?.[field.label],
                        //   }
                        // )}
                        type="text"
                        autoComplete="off"
                        // required={field.is_required}
                      />
                      {/* Display validation error message */}
                      {/* {formik.touched.dynamic_fields?.[field.label] &&
                      formik.errors.dynamic_fields?.[field.label] && (
                        <div className="fv-plugins-message-container text-danger">
                          <span role="alert">
                            {formik.errors.dynamic_fields[field.label]}
                          </span>
                        </div>
                      )} */}
                    </div>
                  )}
                  {field.field_type === "email" && (
                    <div>
                      <input
                        {...formik.getFieldProps(
                          `dynamic_fields.${field.label}`
                        )}
                        className="form-control form-control-lg form-control-solid" // {clsx(
                        //   {
                        //     "is-invalid":
                        //       formik.touched.dynamic_fields?.[field.label] &&
                        //       formik.errors.dynamic_fields?.[field.label],
                        //   },
                        //   {
                        //     "is-valid":
                        //       formik.touched.dynamic_fields?.[field.label] &&
                        //       !formik.errors.dynamic_fields?.[field.label],
                        //   }
                        // )}
                        type="email"
                        autoComplete="off"
                      />
                      {/* Display validation error message */}
                      {/* {formik.touched.dynamic_fields &&
                      formik.errors.dynamic_fields &&
                      formik.errors.dynamic_fields[field?.label] && (
                        <div className="fv-plugins-message-container text-danger">
                          <span role="alert">
                            {formik.errors.dynamic_fields[field?.label]}
                          </span>
                        </div>
                      )} */}
                    </div>
                  )}
                  {/* Add validation for number input */}
                  {field.field_type === "number" && (
                    <div>
                      <input
                        {...formik.getFieldProps(
                          `dynamic_fields.${field.label}`
                        )}
                        className="form-control form-control-lg form-control-solid" // {clsx(
                        //   {
                        //     "is-invalid":
                        //       formik.touched.dynamic_fields?.[field.label] &&
                        //       formik.errors.dynamic_fields?.[field.label],
                        //   },
                        //   {
                        //     "is-valid":
                        //       formik.touched.dynamic_fields?.[field.label] &&
                        //       !formik.errors.dynamic_fields?.[field.label],
                        //   }
                        // )}
                        type="number"
                        autoComplete="off"
                      />
                      {/* Display validation error message */}
                      {/* {formik.touched.dynamic_fields &&
                      formik.errors.dynamic_fields &&
                      formik.errors.dynamic_fields[field?.label] && (
                        <div className="fv-plugins-message-container text-danger">
                          <span role="alert">
                            {formik.errors.dynamic_fields[field?.label]}
                          </span>
                        </div>
                      )} */}
                    </div>
                  )}
                  {/* Add validation for date input */}
                  {field.field_type === "date" && (
                    <div>
                      <input
                        {...formik.getFieldProps(
                          `dynamic_fields.${field.label}`
                        )}
                        className="form-control form-control-lg form-control-solid" // {clsx(
                        //   {
                        //     "is-invalid":
                        //       formik.touched.dynamic_fields?.[field.label] &&
                        //       formik.errors.dynamic_fields?.[field.label],
                        //   },
                        //   {
                        //     "is-valid":
                        //       formik.touched.dynamic_fields?.[field.label] &&
                        //       !formik.errors.dynamic_fields?.[field.label],
                        //   }
                        // )}
                        type="date"
                        autoComplete="off"
                      />
                      {/* Display validation error message */}
                      {/* {formik.touched.dynamic_fields &&
                      formik.errors.dynamic_fields &&
                      formik.errors.dynamic_fields[field?.label] && (
                        <div className="fv-plugins-message-container text-danger">
                          <span role="alert">
                            {formik.errors.dynamic_fields[field?.label]}
                          </span>
                        </div>
                      )} */}
                    </div>
                  )}
                  {/* Add validation for time input */}
                  {field.field_type === "time" && (
                    <div>
                      <input
                        {...formik.getFieldProps(
                          `dynamic_fields.${field.label}`
                        )}
                        className="form-control form-control-lg form-control-solid" // {clsx(
                        //   {
                        //     "is-invalid":
                        //       formik.touched.dynamic_fields?.[field.label] &&
                        //       formik.errors.dynamic_fields?.[field.label],
                        //   },
                        //   {
                        //     "is-valid":
                        //       formik.touched.dynamic_fields?.[field.label] &&
                        //       !formik.errors.dynamic_fields?.[field.label],
                        //   }
                        // )}
                        type="time"
                        autoComplete="off"
                      />
                      {/* Display validation error message */}
                      {/* {formik.touched.dynamic_fields &&
                      formik.errors.dynamic_fields &&
                      formik.errors.dynamic_fields[field?.label] && (
                        <div className="fv-plugins-message-container text-danger">
                          <span role="alert">
                            {formik.errors.dynamic_fields[field?.label]}
                          </span>
                        </div>
                      )} */}
                    </div>
                  )}
                  {/* Add validation for text area input */}
                  {field.field_type === "text-area" && (
                    <div>
                      <textarea
                        {...formik.getFieldProps(
                          `dynamic_fields.${field.label}`
                        )}
                        className="form-control form-control-lg form-control-solid" // {clsx(
                        //   {
                        //     "is-invalid":
                        //       formik.touched.dynamic_fields?.[field.label] &&
                        //       formik.errors.dynamic_fields?.[field.label],
                        //   },
                        //   {
                        //     "is-valid":
                        //       formik.touched.dynamic_fields?.[field.label] &&
                        //       !formik.errors.dynamic_fields?.[field.label],
                        //   }
                        // )}
                        rows={1}
                        autoComplete="off"
                      />
                      {/* Display validation error message */}
                      {/* {formik.touched.dynamic_fields &&
                      formik.errors.dynamic_fields &&
                      formik.errors.dynamic_fields[field?.label] && (
                        <div className="fv-plugins-message-container text-danger">
                          <span role="alert">
                            {formik.errors.dynamic_fields[field?.label]}
                          </span>
                        </div>
                      )} */}
                    </div>
                  )}
                  {/* Add validation for drop-down input */}
                  {field.field_type === "drop-down" && (
                    <div>
                      <select
                        {...formik.getFieldProps(
                          `dynamic_fields.${field.label}`
                        )}
                        className="form-select form-control-lg form-control-solid"
                        autoComplete="off"
                      >
                        <option selected disabled>
                          Select
                        </option>
                        {field?.choices?.map((option) => (
                          <option key={option.id} value={option.choice}>
                            {option.choice}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {/* Add validation for check-box input */}
                  {field.field_type === "check-box" && (
                    <FormControl
                      className="form-control form-control-lg"
                      fullWidth
                      // error={
                      //   !!(
                      //     formik.touched.dynamic_fields &&
                      //     formik.errors.dynamic_fields &&
                      //     formik.errors.dynamic_fields[field?.label]
                      //   )
                      // }
                    >
                      <Select
                        labelId={`dropdown-${field?.label}`}
                        id={`dropdown-${field?.label}`}
                        multiple
                        value={formik.values.dynamic_fields[field?.label] || []}
                        onChange={(event) => {
                          formik.setFieldValue(
                            `dynamic_fields.${field?.label}`,
                            event.target.value
                          );
                        }}
                        renderValue={(selected) => (
                          <div>
                            {selected
                              .map(
                                (value) =>
                                  field?.multiple_choices.find(
                                    (choice) => choice.id === value
                                  )?.choice
                              )
                              .join(", ")}
                          </div>
                        )}
                        // className={clsx({
                        //   "is-invalid":
                        //     formik.touched.dynamic_fields &&
                        //     formik.errors.dynamic_fields &&
                        //     formik.errors.dynamic_fields[field?.label],
                        // })}
                      >
                        {field?.multiple_choices &&
                          field.multiple_choices.map((option) => (
                            <MenuItem key={option?.id} value={option?.id}>
                              <Checkbox
                                checked={formik.values.dynamic_fields[
                                  field?.label
                                ]?.includes(option?.id)}
                                onChange={(event) => {
                                  const selectedValues =
                                    formik.values.dynamic_fields[
                                      field?.label
                                    ] || [];
                                  if (event.target.checked) {
                                    formik.setFieldValue(
                                      `dynamic_fields.${field?.label}`,
                                      [...selectedValues, option?.id]
                                    );
                                  } else {
                                    formik.setFieldValue(
                                      `dynamic_fields.${field?.label}`,
                                      selectedValues.filter(
                                        (id) => id !== option?.id
                                      )
                                    );
                                  }
                                }}
                              />
                              {option.choice}
                            </MenuItem>
                          ))}
                      </Select>
                      {/* Display validation error message */}
                      {/* {formik.touched.dynamic_fields &&
                      formik.errors.dynamic_fields &&
                      formik.errors.dynamic_fields[field?.label] && (
                        <FormHelperText>
                          {formik.errors.dynamic_fields[field?.label]}
                        </FormHelperText>
                      )} */}
                    </FormControl>
                  )}

                  {field.field_type === "tel-phone" && (
                    <div style={{ display: "flex", width: "100%" }}>
                      <PhoneInput
                        style={{ width: "100%" }}
                        defaultCountry="IN"
                        value={formik.values.dynamic_fields[field.label]}
                        onChange={(value) => {
                          formik.setFieldValue(
                            `dynamic_fields.${field.label}`,
                            value
                          );
                        }}
                      />
                    </div>
                  )}
                  {field.field_type === "date-time" && (
                    <div>
                      <input
                        {...formik.getFieldProps(
                          `dynamic_fields.${field.label}`
                        )}
                        className="form-control form-control-lg form-control-solid" // {clsx(
                        //   {
                        //     "is-invalid":
                        //       formik.touched.dynamic_fields?.[field.label] &&
                        //       formik.errors.dynamic_fields?.[field.label],
                        //   },
                        //   {
                        //     "is-valid":
                        //       formik.touched.dynamic_fields?.[field.label] &&
                        //       !formik.errors.dynamic_fields?.[field.label],
                        //   }
                        // )}
                        type="datetime-local"
                        autoComplete="off"
                      />
                      {/* Display validation error message */}
                      {/* {formik.touched.dynamic_fields &&
                      formik.errors.dynamic_fields &&
                      formik.errors.dynamic_fields[field?.label] && (
                        <div className="fv-plugins-message-container text-danger">
                          <span role="alert">
                            {formik.errors.dynamic_fields[field?.label]}
                          </span>
                        </div>
                      )} */}
                    </div>
                  )}
                </div>
              ))}
          </>
        )}
        <div className="col-xl-6">
          <label>Attachments</label>
          <div className="mt-2">
            <Button
              // variant="contained"
              component="label"
              className="text-primary"
              startIcon={<AttachFileIcon />}
            >
              Add Attachment
              <input type="file" hidden multiple onChange={handleFileChange} />
            </Button>
            <Tooltip
              title="You can add attachments for the following file types: xls, pdf, csv, doc, jpg, png up to a maximum size of 15MB"
              arrow
            >
              <IconButton
                aria-label="info"
                style={{
                  verticalAlign: "middle",
                  marginLeft: "11px",
                }}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        {files.length > 0 && (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
            {files.map((file, index) => (
              <Chip
                key={index}
                label={file.file_name}
                onDelete={handleDeleteFile(file)}
              />
            ))}
          </Box>
        )}

        {/* {templateAttachment?.length > 0 && (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
          {templateAttachment?.map((file, index) => (
            <Chip
              key={index}
              label={file.file_name}
              onClick={() => handleDownloadFile(file.file)}
              onDelete={handleDeleteAttachmentFile(file.id)}
              sx={{ cursor: "pointer" }} // Adds a pointer cursor on hover
            />
          ))}
        </Box>
      )} */}
      </div>

      <div className="text-center mb-3">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Notes
        </label>
      </div>
      <div className="row g-5 g-xl-8 mb-4">
        <div className="col-xl-6">
          <label>Internal Notes</label>
          <textarea
            className="form-control form-control-lg form-control-solid"
            rows={1}
            autoComplete="off"
            onChange={internalNotehandler}
            value={internalNoteValue[0]?.content}
          />
        </div>
        <div className="col-xl-6" style={{ position: "relative" }}>
          <label>External Notes</label>
          <div className="textarea-container">
            <textarea
              className="form-control form-control-lg form-control-solid"
              rows={1}
              autoComplete="off"
              value={externalNotetextareaValue
                .map((item) => item.content)
                .join("\n")}
              onChange={externalNotehandler}
            />

            <i
              data-toggle="tooltip"
              data-placement="top"
              title="Select Template"
              className="bi bi-pen"
              style={{
                position: "absolute",
                top: "40px",
                right: "30px",
                cursor: "pointer",
              }}
              onClick={openTemplateModal}
            ></i>
          </div>
        </div>
      </div>

      <div className="text-center mb-3">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Status
        </label>
      </div>

      <div className="row g-5 g-xl-8 mb-4">
        <div className="col-xl-6">
          <label className="required">Status</label>
          <select
            {...formik.getFieldProps("status")}
            onChange={(e) => {
              const selectedStatus = statusOptions.find(
                (option) => option.status_type === e.target.value
              );
              formik.setFieldValue("status", e.target.value);
              formik.setFieldValue(
                "status_id",
                selectedStatus ? selectedStatus.id : ""
              );
            }}
            className="form-select form-select-lg form-select-solid"
          >
            <option value="" label="Select" />
            {statusOptions?.map((statusItem) => (
              <option
                key={statusItem.status_type}
                value={statusItem.status_type}
                label={statusItem.status}
              >
                {statusItem.status}
              </option>
            ))}
          </select>

          {/* {formik.touched.status && formik.errors.status && (
          <div className="fv-plugins-message-container text-danger">
            <span role="alert">{formik.errors.status}</span>
          </div>
        )} */}
        </div>
      </div>

      <div className="text-center mb-3">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Schedule
        </label>
      </div>

      <div className="row g-5 g-xl-8 mb-4">
        <div className="row g-5 g-xl-8 mb-6">
          <div className="col-xl-6">
            <label className="required">Start</label>
            <input
              id="date_from"
              name="date_from"
              {...formik.getFieldProps("date_from")}
              className="form-control form-control-lg form-control-solid"
              type="date"
              placeholder="Custom Date"
            />
          </div>
          <div className="col-xl-6">
            <label className="required">End</label>
            <input
              id="date_to"
              name="date_to"
              {...formik.getFieldProps("date_to")}
              className="form-control form-control-lg form-control-solid"
              type="date"
              placeholder="Custom Date"
            />
          </div>

          <div className="col-xl-6">
            <label className="required">Select frequency</label>
            <select
              className="form-select form-select-lg form-select-solid"
              id="schedule_timer"
              name="schedule_timer"
              {...formik.getFieldProps("schedule_timer")}
            >
              <option value="select team" defaultValue={"select team"} hidden>
                Select
              </option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>

          <div className="col-xl-6 ">
            <label className="required">Time</label>
            <input
              id="daily_time"
              name="daily_time"
              {...formik.getFieldProps("daily_time")}
              className="form-control form-control-lg form-control-solid"
              type="time"
              placeholder="Custom Time"
            />
          </div>
        </div>
      </div>

      <div className="text-end">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary mb-1"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loadingOl && (
            <span className="indicator-label">
              {scheduleEditEnabled ? "Update" : "Create"}
            </span>
          )}
          {loadingOl && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait... <Loader />
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>

      <ReactModal
        isOpen={isModalOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeTemplateModal}
        style={customModalStyles}
        contentLabel="Email templates"
      >
        <EmailTemplateModal
          closeModal={closeTemplateModal}
          setSelectedTemplateDescription={setSelectedTemplateDescription}
          storeTemplateData={storeTemplateData}
          setTemplateAttachment={setTemplateAttachment}
        />
      </ReactModal>
    </form>
  );
};
export default NewScheduleModal;
