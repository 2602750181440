import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const emailTemplate = createApi({
  reducerPath: "template",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    passwordCheck: builder.query({
      query: () => ({
        url: "v1/users/me/",
        method: "GET",
      }),
    }),

    getTemplate: builder.query({
      query: () => ({
        url: `v1/emails/templates/?page=1&`,
        method: "GET",
      }),
    }),

    getSearchByEmail: builder.query({
      query: ({ email }) => ({

        url: `v1/customers/contacts/search-by-email/?email=${email}`,
        method: "GET",
        params: { email, for_team: "active" },
      }),
    }),
    getDynamicFieldForContact: builder.query({
      query: () => ({
        url: `v1/common/dynamic-field-definitions/?model_name=contact&`,
        method: "GET",
      }),
    }),
    getAllAccounts: builder.query({
      query: () => ({
        url: `v1/customers/accounts/?is_lead_account=false`,
        method: "GET",
      }),
    }),
    getCustomersTags: builder.query({
      query: () => ({
        url: `v1/customers/tags/ `,
        method: "GET",
      }),
    }),

    getAllTeamsById: builder.query({
      query: (id) => ({
        url: `v1/users/users/${id}/ `,
        method: "GET",
      }),
    }),

    submitContacts: builder.mutation({
      query: (body) => {
        return {
          url: "v1/customers/contacts/",
          method: "POST",
          body: body,
        };
      },
    }),


    getLookupByEmail: builder.query({
      query: (email) => ({
        url: `v1/customers/contacts/lookup-by-email/?email=${email}&is_lead_contact=false&`,
        method: "GET",
      }),
    }),

    threads: builder.mutation({
      query: (body) => {

        return {
          url: "v1/emails/threads/",
          method: "POST",
          body: body,
        };
      },
    }),



    allThreads: builder.query({
      query: () => ({
        url: "v1/emails/threads/?page=1&status__contains=active&ordering=-updated&",
        method: "GET",
      }),
    }),

    

    specificThreads: builder.query({
      query: (id) => ({
        url: `v1/emails/threads/${id}/`,
        method: "GET",
      }),
    }),

    reply: builder.query({
      query: ({ replyText, uuid, mailDetails }) => {
        const replyTextValue = replyText === "replyAll" ? 'reply-all-email-list' : 'reply-email-list'
        return {
          url: `v1/emails/${replyTextValue}/${uuid}/${mailDetails[mailDetails.length - 1].id}`,
          method: "GET",
        }
      }
    }),




  }),
});

export const { usePasswordCheckQuery, useGetTemplateQuery, useGetSearchByEmailQuery, useGetDynamicFieldForContactQuery, useGetAllAccountsQuery, useSubmitContactsMutation, useGetCustomersTagsQuery, useGetAllTeamsByIdQuery, useGetLookupByEmailQuery, useThreadsMutation,
  useReplyQuery, useSpecificThreadsQuery, useAllThreadsQuery
} = emailTemplate;
