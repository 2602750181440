import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

interface IProps {
  overview: (number | string)[]; // Accepts numbers or empty strings
}

interface ApexChartsValue {
  options: {
    labels: string[];
    dataLabels: {
      enabled: boolean;
      formatter?: (val: number, opts?: any) => string;
    };
    tooltip: {
      enabled: boolean;
    };
    title: {
      margin: number;
      offsetX: number;
      offsetY: number;
      floating: boolean;
    };
    colors: string[];
    legend: {
      show: boolean;
      position: "bottom" | "top" | "right" | "left";
      fontSize: string;
      horizontalAlign: "left" | "center" | "right";
      formatter?: (val: string, opts?: any) => string;
    };
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: boolean;
            total: {
              show: boolean;
              label: string;
              formatter: (w: any) => string;
            };
          };
        };
      };
    };
    responsive: {
      breakpoint: number;
      options: {
        chart: {
          width: number;
        };
        legend: {
          position: "bottom" | "top" | "right" | "left";
        };
      };
    }[];
  };
  series: number[];
}

const PieChart: React.FC<IProps> = ({ overview }) => {
  const [chartData, setChartData] = useState<ApexChartsValue>({
    options: {
      labels: ["Active", "Closed", "Wip", "Reopen"],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return `${Math.round(val)}%`; // Displaying percentage
        },
      },
      tooltip: {
        enabled: true,
      },
      title: {
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
      },
      colors: ["#00b300", "#F6A40A", "#E51803", "#00b300"],
      legend: {
        show: true,
        position: "right",
        fontSize: "16px",
        horizontalAlign: "center",
        formatter: function (seriesName, opts) {
          const count = overview[opts.seriesIndex];
          return `${seriesName}: ${count || 0}`;
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: "Total Tickets",
                formatter: function () {
                  const totalCount = overview.reduce(
                    (acc:any, curr:any) => acc + (Number(curr) || 0), 
                    0
                  );
                  return `${totalCount} Tickets`;
                },
              },
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: [], // Initially empty
  });

  console.log(overview)
  const [isData, setIsData] = useState(true);

  useEffect(() => {
    // Calculate the total number of tickets
    const totalTickets = overview.reduce((acc:any, curr:any) => acc + (Number(curr) || 0), 0); // Ensure type conversion

    if (totalTickets > 0) {
      // Calculate percentages based on ticket counts
      const percentageData = overview.map((count) => {
        const numCount = Number(count) || 0; // Ensure type conversion
        return (numCount / totalTickets) * 100;
      });

      setChartData((prevData) => ({
        ...prevData,
        series: percentageData,
        options: {
          ...prevData.options,
          labels: ["Active", "Closed", "Wip", "Reopen"],
          dataLabels: {
            ...prevData.options.dataLabels,
            enabled: true,
          },
          tooltip: {
            ...prevData.options.tooltip,
            enabled: true,
          },
          colors: ["#00b300", "#F6A40A", "#E51803", "#E51803"],
        },
      }));
      setIsData(false);
    } else {
      // No data case
      setChartData((prevData) => ({
        ...prevData,
        options: {
          ...prevData.options,
          labels: ["No Data Available"],
          dataLabels: {
            ...prevData.options.dataLabels,
            enabled: false,
          },
          tooltip: {
            ...prevData.options.tooltip,
            enabled: false,
          },
          colors: ["#808080"],
        },
      }));
      setIsData(true);
    }
  }, [overview]);

  return (
    <div className="card card-flush h-lg-100">
      <div className="card-header pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="fs-2 fw-bold text-gray-800 me-2 lh-1 ls-n2">
            Ticket Status
          </span>
        </h3>
      </div>
      <div className="row align-items-center mx-0 w-100">
        <div>
          <div id="chart">
            <Chart
              options={chartData.options}
              series={chartData.series}
              type="donut"
              height={250}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieChart;
