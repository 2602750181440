import React from "react";

const VerificationLink = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
            <span style={{ fontSize: "20px", fontWeight: "400" }}>
                A verification link has been sent to your email account
            </span>
            <span style={{ fontSize: "20px", fontWeight: "400", lineHeight: "28px" }}>Please click on the link to verify your email</span>
        </div>
    );
};

export default VerificationLink;
