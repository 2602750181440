import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const CreateTicket = createApi({
  reducerPath: "createTicket",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    customerNotes: builder.mutation({
      query: (body) => ({
        url: `v1/tickets/customer-note/`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
    useCustomerNotesMutation
} = CreateTicket;
