import React, { useEffect, useState } from "react";
import { Button, CloseButton, Modal, Row, Col, Alert } from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";

interface IProps {
  closeFilterModal: () => void;
  selectStaredFilterAndValue: any;
  setSelectStaredFilterAndValue: any;
  handleSubmit: (selectStaredFilterAndValueValues: any) => void;
  setIsLoadingPageChange: any;
}

const StarredFilterModal: React.FC<IProps> = ({
  closeFilterModal,
  selectStaredFilterAndValue,
  setSelectStaredFilterAndValue,
  handleSubmit,
  setIsLoadingPageChange,
}) => {
  const [selectStarredFilters, setSelectStarredFilters] = useState<string[]>(
    []
  );
  const [dateTo, setDateTo] = useState<string>("");
  const [suggestions, setSuggestions] = useState([]);
  const [assigneeSuggestions, setAssigneeSuggestions] = useState([]);
  const [error, setError] = useState<string | null>(null);

  const filters = [
    { label: "Customer Email", value: "action__contact__email__icontains" },
    { label: "Agent Name", value: "action__assignee__username__icontains" },
    { label: "Subject", value: "original_subject__icontains" },
    { label: "Date", value: "created" },
  ];

  // Load filter state from sessionStorage when the component mounts
  useEffect(() => {
    const savedFilters = sessionStorage.getItem("selectStarredFilters");
    const savedFilterValues = sessionStorage.getItem(
      "selectStaredFilterAndValue"
    );

    if (savedFilters && savedFilterValues) {
      setSelectStarredFilters(JSON.parse(savedFilters));
      setSelectStaredFilterAndValue(JSON.parse(savedFilterValues));
      setDateTo(JSON.parse(savedFilterValues).created || "");
    } else {
      const appliedFilters = Object.keys(selectStaredFilterAndValue).filter(
        (key) => selectStaredFilterAndValue[key]
      );
      setSelectStarredFilters(appliedFilters);
      setDateTo(selectStaredFilterAndValue.created || "");
    }
  }, [setSelectStaredFilterAndValue]);

  // Save filter state to sessionStorage when it changes
  useEffect(() => {
    sessionStorage.setItem(
      "selectStarredFilters",
      JSON.stringify(selectStarredFilters)
    );
    sessionStorage.setItem(
      "selectStaredFilterAndValue",
      JSON.stringify(selectStaredFilterAndValue)
    );
  }, [selectStarredFilters, selectStaredFilterAndValue]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectStarredFilters((prevSelectedFilters) => {
      let updatedFilters;
      if (prevSelectedFilters.includes(value)) {
        updatedFilters = prevSelectedFilters.filter(
          (filter) => filter !== value
        );
        setSelectStaredFilterAndValue((prev) => ({
          ...prev,
          [value]: "",
        }));
      } else {
        updatedFilters = [...prevSelectedFilters, value];
      }
      handleSubmit(
        updatedFilters.reduce((acc, filter) => {
          acc[filter] = selectStaredFilterAndValue[filter] || "";
          return acc;
        }, {})
      );
      return updatedFilters;
    });
  };

  const handleFilterValueChange = (value: string, filter: string) => {
    setSelectStaredFilterAndValue((prevFilterValues) => ({
      ...prevFilterValues,
      [filter]: value,
    }));
  };

  const handleDateToChange = (event: any) => {
    const value = event.target.value;
    setDateTo(value);
    setSelectStaredFilterAndValue((prev) => ({
      ...prev,
      created: value,
    }));
  };

  const resetFilters = () => {
    setSelectStarredFilters([]);
    const emptyFilters = filters.reduce((acc, filter) => {
      acc[filter.value] = "";
      return acc;
    }, {});
    setSelectStaredFilterAndValue(emptyFilters);
    setDateTo("");
    setError(null);
    sessionStorage.removeItem("selectStarredFilters");
    sessionStorage.removeItem("selectStaredFilterAndValue");
    handleSubmit({});
  };

  const isFilterApplied = () =>
    selectStarredFilters.some(
      (filter) =>
        (filter === "created" && dateTo) || selectStaredFilterAndValue[filter]
    );

  const apiPort = process.env.REACT_APP_API_PROTOCOL;
  const fetchingTenant = window.location.hostname.split(".")[0];

  const searchCustomerEmail = (skey: string) => {
    axios
      .get(
        `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/customers/contacts/search-by-email/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
          },
          params: { email: skey },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setSuggestions(res.data);
          setError(null);
        }
      })
      .catch(() => {
        setError("Failed to fetch customer email suggestions.");
      });
  };

  const searchAssigneeSuggestions = (skey: string) => {
    axios
      .get(
        `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/users/users/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
          },
          params: { username__icontains: skey },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setAssigneeSuggestions(res.data);
          setError(null);
        }
      })
      .catch(() => {
        setError("Failed to fetch assignee suggestions.");
      });
  };

  const handleApplyFilters = () => {
    handleSubmit(selectStaredFilterAndValue);
    closeFilterModal();
    setIsLoadingPageChange(true);
  };

  return (
    <Modal show={true} onHide={closeFilterModal} centered>
      <Modal.Header>
        <Modal.Title className="fw-bold">Filters</Modal.Title>
        <CloseButton onClick={closeFilterModal} />
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>} {/* Display error */}
        <div className="px-3 py-2">
          {filters.map((filter) => (
            <Row key={filter.value} className="mb-3 align-items-center">
              <Col xs={12} md={4} className="text-nowrap">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectStarredFilters.includes(filter.value)}
                      onChange={handleFilterChange}
                      value={filter.value}
                      color="primary"
                    />
                  }
                  label={filter.label}
                />
              </Col>
              <Col xs={12} md={8}>
                {selectStarredFilters.includes(filter.value) && (
                  <>
                    {filter.value === "created" ? (
                      <TextField
                        id="date-to"
                        label="Select Date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputProps: {
                            style: { textTransform: "uppercase" }, // Optional: uppercase input text
                          },
                        }}
                        fullWidth
                        value={dateTo}
                        onChange={handleDateToChange}
                      />
                    ) : filter.value === "action__contact__email__icontains" ? (
                      <Autocomplete
                        freeSolo
                        options={suggestions.map((option) => option.email)}
                        value={selectStaredFilterAndValue[filter.value] || ""}
                        onInputChange={(event, newInputValue) => {
                          searchCustomerEmail(newInputValue);
                        }}
                        onChange={(event, newValue) => {
                          const selectedSuggestion = suggestions.find(
                            (suggestion) => suggestion.email === newValue
                          );

                          setSelectStaredFilterAndValue((prev) => ({
                            ...prev,
                            action__contact__email__icontains:
                              selectedSuggestion
                                ? selectedSuggestion.email
                                : "",
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={filter.label}
                            variant="outlined"
                          />
                        )}
                      />
                    ) : filter.value ===
                      "action__assignee__username__icontains" ? (
                      <Autocomplete
                        freeSolo
                        options={assigneeSuggestions.map(
                          (option) => option.username
                        )}
                        value={selectStaredFilterAndValue[filter.value] || ""}
                        onInputChange={(event, newInputValue) => {
                          searchAssigneeSuggestions(newInputValue);
                        }}
                        onChange={(event, newValue) => {
                          const selectedSuggestion = assigneeSuggestions.find(
                            (suggestion) => suggestion.username === newValue
                          );

                          setSelectStaredFilterAndValue((prev) => ({
                            ...prev,
                            action__assignee__username__icontains:
                              selectedSuggestion
                                ? selectedSuggestion.username
                                : "",
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={filter.label}
                            variant="outlined"
                          />
                        )}
                      />
                    ) : (
                      <TextField
                        label={filter.label}
                        fullWidth
                        value={selectStaredFilterAndValue[filter.value] || ""}
                        onChange={(e) =>
                          handleFilterValueChange(e.target.value, filter.value)
                        }
                      />
                    )}
                  </>
                )}
              </Col>
            </Row>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={resetFilters}>
          Reset
        </Button>
        <Button variant="primary" onClick={handleApplyFilters}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StarredFilterModal;
