import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const Task = createApi({
  reducerPath: "Task",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    Task: builder.query({
      query: (uuid) => {
        return {
          url: `v1/tickets/ticket-event/?ticket=${uuid}&`,
          method: "GET",
        };
      },
    }),
    getTask: builder.query({
      query: (id) => {
        return {
          url: `/v1/tickets/ticket-event/${id}/`,
          method: "GET",
        };
      },
    }),
    updateTask: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `v1/tickets/ticket-event/${id}/`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});

export const { useTaskQuery, useGetTaskQuery, useUpdateTaskMutation } = Task;
