import React, { useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../LoadingSpinner";
import { MultiSelect } from "react-multi-select-component";
import CsvDownloader from "react-csv-downloader";
import { toast } from "react-toastify";
import { useGetAllTeamsListQuery, useGetCampaignDispositionReportQuery,useGetCampaignByTeamIdQuery, useGetWorkflowByTeamIdAndCampaignIdQuery, useGetStatusByTeamIdAndCampaignIdWorkflowIdQuery ,useGetAllAssigneeBySelectedTeamIdQuery} from "../../../../Services/reports/tickets/ReportTickets";
import { set } from "lodash";

const CampaignDispositionReport = () => {
  const navigate = useNavigate();

  const [localLoading, setLocalLoading] = useState(false);
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [params, setParams] = useState<any>(null);
  const [allTeam, setAllTeam] = useState<any>([])
  const [selectTeam, setSelectTeam] = useState<any>("")
  const [allCampaign, setAllCampaign] = useState<any>([])
  const [selectedCampaign, setSelectedCampaign] = useState<any>("")
  const [allWorkflow, setAllWorkflow] = useState<any>([])
  const [selectedWorkflow, setSelectedWorkflow] = useState<any>("")

  const [selectStatus, setSelectStatus] = useState<any>("")
  const [allStatus, setAllStatus] = useState<any>([])
  const [optionsStatus, setOptionsStatus] = useState<any[]>([])
  const [optionsAssignee, setOptionsAssignee] = useState<any[]>([]);
  const [selectedAssignee, setSelectedAssignee] = useState<string | null>(null);
  const [leadId, setLeadId] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<any>([]);
  const [workflowId,setWorkflowId] = useState<any>('')


  const { data: teamsData, isLoading: teamsLoading } = useGetAllTeamsListQuery({});
  const { data: campaignData, isLoading: campaignLoading } = useGetCampaignByTeamIdQuery(
    { selectTeam },
    {
      skip: !selectTeam,
    }
  );

  const { data: workflowData, isLoading: workflowLoading } = useGetWorkflowByTeamIdAndCampaignIdQuery(
    { selectTeam, selectedCampaign },
    {
      skip: !selectTeam || !selectedCampaign,
    }
  );

  const { data: statusData, isLoading: statusDataLoading } = useGetStatusByTeamIdAndCampaignIdWorkflowIdQuery(
    { selectTeam, selectedCampaign, selectedWorkflow },
    {
      skip: !selectTeam || !selectedCampaign || !selectedWorkflow,
    }
  );
  const { data: assigneeData, isLoading: assigneeLoading } =
    useGetAllAssigneeBySelectedTeamIdQuery(selectTeam, {
      skip: !selectTeam,
    });


    const {
      data: campaignDispositionReportData,
      isLoading: campaignDispositionReportLoading,
      error: campaignDispositionReportError,
    } = useGetCampaignDispositionReportQuery(params, {
      skip: !params,
    });

    useEffect(() => {
      if (assigneeData) {
        const transformedAssignees = assigneeData.map((assignee: any) => ({
          label: `${assignee.username}`,
          value: assignee.id,
        }));
        setOptionsAssignee(transformedAssignees);
      }
    }, [assigneeData]);
  const downloaderRef = useRef(null);
  console.log(selectTeam)
  const columns: GridColDef[] = [
    { field: "date", headerName: "Date", flex: 1, align: 'center', headerAlign: 'center' },
    { field: "leadId", headerName: "Lead Id", flex: 1, align: 'center', headerAlign: 'center' },
    { field: "customerName", headerName: "Customer Name", flex: 1, align: 'center', headerAlign: 'center' },
    { field: "email", headerName: "Email", flex: 1, align: 'center', headerAlign: 'center' },
    { field: "mobileNo", headerName: "Mobile No", flex: 1, align: 'center', headerAlign: 'center' },
    { field: "status", headerName: "Status", flex: 1, align: 'center', headerAlign: 'center' },
    { field: "assignee", headerName: "Assignee", flex: 1, align: 'center', headerAlign: 'center' },
    { field: "disposition", headerName: "Disposition", flex: 1, align: 'center', headerAlign: 'center' },
  ];

  useEffect(() => {
    const dropdownHeadings = document.querySelectorAll(".dropdown-heading");
    dropdownHeadings.forEach((heading: any) => {
      heading.style.height = "50px";
    });
  }, []);

  useEffect(() => {
    const transformedData1 = teamsData?.map((user) => ({
      id: user.id,
      name: user.name,
    }));
    if (Array.isArray(transformedData1)) {
      setAllTeam(transformedData1);
    } else {
      setAllTeam([]); // Fallback to an empty array if it's not an array
    }

  }, [teamsData]);

  useEffect(() => {
    const transformedData1 = campaignData?.results?.map((campaign) => ({
      id: campaign.id,
      name: campaign.name,
    }));
    if (Array.isArray(transformedData1)) {
      setAllCampaign(transformedData1);
    } else {
      setAllCampaign([]); // Fallback to an empty array if it's not an array
    }

  }, [campaignData]);



  useEffect(() => {
    const transformedData1 = workflowData?.results?.map((campaign) => ({
      id: campaign.id,
      name: campaign.name,
    }));
    if (Array.isArray(transformedData1)) {
      setAllWorkflow(transformedData1);
    } else {
      setAllWorkflow([]); // Fallback to an empty array if it's not an array
    }

  }, [workflowData]);

  useEffect(() => {
    console.log(statusData?.results);

    // Transform the data
    const transformedData1 = statusData?.results[0]?.all_statuses?.map((campaign, index) => ({
      id: campaign?.status_type,
      name:
        campaign?.status_type === 'wip'
          ? campaign?.status?.status
          : campaign?.status_type === 'closed'
          ? campaign?.name?.status
          : campaign?.status,
          status_type: campaign?.status_type
    }));

    // Set all statuses
    if (Array.isArray(transformedData1)) {
      setAllStatus(transformedData1);

      // Transform into options for MultiSelect
      const options = transformedData1.map(({ id, name }) => ({
        label: name, // Display name
        value: id,   // Unique identifier
      }));
      setOptionsStatus(options);
    } else {
      setAllStatus([]); // Fallback to an empty array if it's not an array
      setOptionsStatus([]); // Reset options
    }
  }, [statusData])

  useEffect(() => {
    if (campaignDispositionReportData?.results?.length) {
      setDataRows(campaignDispositionReportData.results);
    } else {
      setDataRows([]); // Fallback to an empty array if data is not available
    }
  }, [campaignDispositionReportData]);
useEffect(()=>{
  if (selectedWorkflow) {
    const selectedWorkflowData = allWorkflow?.find(
      (workflow) => workflow.name === selectedWorkflow
    );

    if(!!selectedWorkflow){
      setWorkflowId(selectedWorkflowData?.id)
    }
  }

},[selectedWorkflow])

useEffect(() => {
  if (campaignDispositionReportError) {
    // Type guard to check if it's a FetchBaseQueryError with a data property
    if ('data' in campaignDispositionReportError && typeof campaignDispositionReportError.data === 'object') {
      const errorData = campaignDispositionReportError.data as Record<string, string[]>;

      Object.entries(errorData).forEach(([field, messages]) => {
        const formattedField = field.replace(/_/g, " ");

        if (Array.isArray(messages)) {
          messages.forEach((message) => {
            toast.error(`${formattedField}: ${message}`);
          });
        } else {
          toast.error(`${formattedField}: ${messages}`);
        }
      });
    } else {
      // Handle other types of errors
      toast.error("An unexpected error occurred. Please try again.");
    }
  }
}, [campaignDispositionReportError]);




  const handlePaginationChange = (newPaginationModel: any) => {
    setLocalLoading(true);
    setPaginationModel(newPaginationModel);
    setTimeout(() => {
      setLocalLoading(false);
    }, 1000);
  };
  const handleDateFromChange = (event: any) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event: any) => {
    setDateTo(event.target.value);
  };

  const handleSubmit = () => {
    if (!dateFrom) {
      toast.error('Please select "From Date"');
      return;
    }
    if (!dateTo) {
      toast.error('Please select "To Date"');
      return;
    }
    if (!selectTeam) {
      toast.error('Please select Team');
      return;
    }
  
    const queryParams: any = {
      gte: dateFrom,
      lte: dateTo,
      campaign__team: selectTeam,
      campaign: selectedCampaign,
    };
  
    // Add `selectedStatus` labels as a comma-separated string if available
    if (selectedStatus.length > 0) {
      console.log(selectedStatus)
      const statusLabels = selectedStatus.map((status: any) => status.value).join(", ");
      queryParams.status_type = statusLabels;
    }
  
    // Only add `selectedAssignee` if it's not "all" or null
    if (selectedAssignee && selectedAssignee !== "all") {
      queryParams.assignee = selectedAssignee;
    }
  
    // Only add `campaign__campaign_name` if `selectedWorkflow` is not "all" or null
    if (selectedWorkflow && selectedWorkflow !== "all") {
      queryParams.campaign__campaign_name = workflowId;
    }
  
    // Only add `id__icontains` if `leadId` is not null or undefined
    if (leadId) {
      queryParams.id__icontains = leadId;
    }
  
    // Remove any undefined or null values from the query parameters
    const sanitizedQueryParams:any = Object.fromEntries(
      Object.entries(queryParams).filter(([_, value]) => value != null)
    );
  
    setParams(sanitizedQueryParams);
  
    // Optional: Log the query string for debugging
    const queryString = new URLSearchParams(sanitizedQueryParams).toString();
    console.log("Generated Query String:", queryString);
  };
  
  
  

  const handleTeamChange = (event: any) => {
    setSelectTeam(event.target.value);
  };

  const handleCampaginChange = (event: any) => {
    setSelectedCampaign(event.target.value);
  }
  const handleAssigneeChange = (event: any) => {
    setSelectedAssignee(event.target.value);
  };

  const ITEM_HEIGHT = 60;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  return (
    <div id="kt_app_content_container" className="app-container mx-5">
      <div className="card card-flush">

        {/* Card Header */}
        <div className="card-header align-items-center py-1 gap-2 gap-md-5" style={{ justifyContent: 'flex-start' }}>
          {/* Back button */}
          <img
            src="https://insurance.circleonecrm.com/assets/arrow_back.svg"
            alt="Back"
            style={{ marginBottom: '-2px', cursor: 'pointer' }}
            className="ng-star-inserted"
            onClick={() => {
              navigate('/reports/telephony/reports-list')
            }}
          />
          <span className="fs-3 text-gray-800 fw-bold ms-3"> Campaign Dispositions</span>
        </div>

        {/* Toolbar */}
        <div className="card-toolbar flex-row-fluid justify-content-start gap-5 d-flex mb-10" style={{ marginLeft: "30px" }}>
          {/* Date Filters */}
          {/* Date Filters */}
          <div className="w-100 mw-150px">
            <TextField
              id="date-from"
              label="From"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateFrom}
              onChange={handleDateFromChange}
            />
          </div>
          <div className="w-100 mw-150px">
            <TextField
              id="date-to"
              label="To"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateTo}
              onChange={handleDateToChange}
            />
          </div>
          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="Assignee-select-label">Select Team</InputLabel>
              <Select
                labelId="team-select-label"
                id="team-select"
                label="Select Team"
                MenuProps={MenuProps}
                value={selectTeam}
                onChange={handleTeamChange}
              >

                {allTeam?.map((team: any) => (
                  <MenuItem key={team.id} value={team.id}>
                    {team.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="Assignee-select-label">Select Campaign</InputLabel>
              <Select
                labelId="team-select-label"
                id="team-select"
                label="Select Team"
                MenuProps={MenuProps}
                value={selectedCampaign}
                onChange={handleCampaginChange}
              >
                <MenuItem key="all" value="All">
                  All
                </MenuItem>
                {allCampaign?.map((campaign: any) => (
                  <MenuItem key={campaign.id} value={campaign.id}>
                    {campaign.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="Assignee-select-label">Workflow</InputLabel>
              <Select
                labelId="team-select-label"
                id="team-select"
                label="Select Team"
                MenuProps={MenuProps}
                value={selectedWorkflow}
                onChange={(e) => setSelectedWorkflow(e.target.value)}
              >
                <MenuItem key="all" value="All">
                  All
                </MenuItem>
                {allWorkflow?.map((workflow: any) => (
                  <MenuItem key={workflow.id} value={workflow.name}>
                    {workflow.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <MultiSelect
                options={optionsStatus}
                value={selectedStatus}
                onChange={setSelectedStatus}
                labelledBy='Select'
              />
            </FormControl>
          </div>

          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="Assignee-select-label">Assignee</InputLabel>
              <Select
                labelId="Assignee-select-label"
                id="Assignee-select"
          
                value={selectedAssignee}
                onChange={handleAssigneeChange}
                label="Assignee"
                MenuProps={MenuProps}
              >
                <MenuItem key="all" value="All">
                  All
                </MenuItem>
                {optionsAssignee.map(
                  (assignee) =>
                    assignee.label.length > 1 && (
                      <MenuItem key={assignee.value} value={assignee.value}>
                        {assignee.label}
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
          </div>

          <div className="w-100 mw-150px">
            <TextField
              id="lead-id"
              label="Enter Lead Id"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setLeadId(e.target.value)}
              fullWidth
            />
          </div>

          <div className="ms-auto d-flex gap-3" style={{ marginRight: "20px" }}>
            <button className='btn btn-primary' style={{ height: '50px', whiteSpace: 'nowrap' }}>
              Add Email Id
            </button>
            <button className='btn btn-primary' onClick={handleSubmit} style={{ height: '50px', whiteSpace: 'nowrap' }}>
              Submit
            </button>
            <button className='btn btn-primary' style={{ height: '50px', whiteSpace: 'nowrap' }}>
              Download
            </button>
            <button className="btn btn-link" style={{ height: '40px', display: 'flex', marginTop: "5px" }}>
              <i className="bi bi-funnel fs-2 text-dark"></i>
            </button>
          </div>

        </div>

        {/* DataGrid */}
        <CardContent style={{ width: "100%" }}>
          {localLoading || teamsLoading || campaignLoading || workflowLoading  || assigneeLoading || campaignDispositionReportLoading ? (
            <LoadingSpinner />
          ) : (
            <DataGrid
              rows={dataRows}
              columns={
                columns
              }
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              loading={localLoading}
              disableColumnMenu
              pageSizeOptions={[5, 10, 15, 20]}
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "lightgray",
                  "&:last-child": {
                    justifyContent: "flex-end",
                  },
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-columnsContainer": {
                  backgroundColor: "lightgray",
                },
                // Target the pagination select and actions by stable component classes
                "& .MuiTablePagination-select, .MuiTablePagination-actions": {
                  marginBottom: "10px",
                },
                // Target the DataGrid overlay wrapper with its stable class
                "& .MuiDataGrid-overlayWrapper": {
                  height: "50px",
                },
              }}
            />
          )}
        </CardContent>

      </div>
    </div>
  );
}

export default CampaignDispositionReport
