import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { EXTENSION, TELEPHONEURL } from "../../constants";
import { useTelephony } from "../context/TelephonyContext";
import LoadingSkeleton from "../LoadingSkeleton";
import ButtonLoadingSkeleton from "../ButtonsLoadingSkeleton";



interface IPROPS {
    setIsConferenceCallActive: any;
    conferenceNumber: any;
    isConferenceCallActive: any;
    call: any;
    callExtensionRefetch: any;
    setConferenceCallApiCalled: any;
    onClick?: () => void;
    style?: React.CSSProperties; // Add this line
    setCheckConferenceCallNumberEntered:any
    checkConferenceCallNumberEntered:any
    setIsCallPause:any

}

const Conference: React.FC<IPROPS> = ({
    setIsConferenceCallActive,
    conferenceNumber,
    isConferenceCallActive,
    call,
    callExtensionRefetch,
    setConferenceCallApiCalled,
    onClick,
     style,
     setCheckConferenceCallNumberEntered,
     setIsCallPause

}) => {

    const {setIsLoading, disconnectCallLoader, setDisconnectCallLoader} =  useTelephony()


    const gettingTelephonyData = localStorage.getItem(TELEPHONEURL);

    const telephoneData = JSON.parse(gettingTelephonyData);
    const gettingExtension = localStorage.getItem(EXTENSION);

    const mobileNumber = localStorage.getItem('liftingMobileNumber')

    useEffect(() => {
        if (gettingExtension && call && isConferenceCallActive) {
            const timer = setInterval(() => {
                callExtensionRefetch();
            }, 2000);
            return () => clearInterval(timer);
        }
    }, [call, callExtensionRefetch, isConferenceCallActive]);


    const conferenceCallhandler = async () => {
        setIsConferenceCallActive(true);
        if (!conferenceNumber) {
            toast.warning("Enter Mobile Number");
            return;
        }

        if (conferenceNumber.length > 10 || conferenceNumber.length > 10) {
            toast.error("Please enter valid number")
            return 

        }

        if (conferenceNumber === mobileNumber) {
            toast.error("Conference number shall be different")
            return
        }

        setDisconnectCallLoader(true)


        try {
            const conferenceResponse = await axios.post(`
                https://${telephoneData?.domain}/click2call/Transfer.php?Number=${gettingExtension}&TransferNumber=91${conferenceNumber}&Type=attended&SecKey=2c41c5e95e052e9d03f423e9f578b9b3`);
                
                setConferenceCallApiCalled(true);
                const gettingConferenceCallStatus =  conferenceResponse?.data?.desc
                localStorage.setItem("conference-call-status",gettingConferenceCallStatus)

                toast.success("Call Added Successfully")
        setCheckConferenceCallNumberEntered(true)


        setIsLoading(false)
        setIsCallPause(false)
        setDisconnectCallLoader(false)
        } catch (error) {
        setDisconnectCallLoader(false)

            setIsLoading(false)
        setCheckConferenceCallNumberEntered(false)

            toast.error("An error occured")
            
        }
    // }
        
    };
    return (
        <div>
            {disconnectCallLoader ? <ButtonLoadingSkeleton /> : 
            <div onClick={onClick} style={style}>
                <i
                    onClick={conferenceCallhandler}
                    style={{ fontSize: "15px", background: "#d1d150", borderRadius: "50%", color: "white", padding: "8px" }}
                    className="bi bi-plus-square"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Conference"
                ></i>
            </div>
}
        </div>
    );
};

export default Conference;
