import React, { useState } from "react";

const SentinmentsCard = () => {
  const [isFeatureAvailable, setIsFeatureAvailable] = useState(false); // Set this to true when the AI feature is ready
  const sentimentsData = [
    { emoji: "😊", content: "Satisfied", counts: "566", percentage: "58.29%" },
    { emoji: "😐", content: "Neutral", counts: "167", percentage: "17.20%" },
    {
      emoji: "😞",
      content: "Dissatisfied",
      counts: "238",
      percentage: "24.51%",
    },
  ];

  return (
    <div className="card card-flush mb-xl-10 mb-5">
      {/*begin::Header*/}
      <div className="card-header pt-5 mx-n4">
        <h3 className="card-title align-items-start flex-column">
          <div className="d-flex align-items-center mb-2">
            <span className="fs-2 fw-bold text-dark lh-1 ls-n2">
            Customer Sentiments
            </span>
          </div>
        </h3>
      </div>
      {/*end::Header*/}
      {/*begin::Body*/}
      {isFeatureAvailable ? (
        <>
        <div className="table-responsive fs-2 py-0 mx-5">
        <table className="table">
          <thead className="table-secondary">
            <tr>
              <th scope="col">Emoji</th>
              <th scope="col">Content</th>
              <th scope="col">Counts</th>
              <th scope="col">Percentage</th>
            </tr>
          </thead>
          <tbody>
            {sentimentsData.map((sentiment, index) => (
              <tr key={index}>
                <td>{sentiment.emoji}</td>
                <td>{sentiment.content}</td>
                <td>{sentiment.counts}</td>
                <td>{sentiment.percentage}</td>
              </tr>
            ))}

            
          </tbody>
        </table>
      </div>
        
        </>

    ) : (
                    <div className="coming-soon d-flex flex-column justify-content-center align-items-center w-100">
                        <img
                            className="mw-100 w-100px w-lg-200px mb-10 mb-lg-10"
                            src="https://preview.keenthemes.com/metronic8/demo39/assets/media/auth/agency.png"
                            alt="Coming Soon"
                        />
                        <h4 className="text-center ">Coming Soon</h4>
                        <p className="text-center mb-10">This feature is currently under development. Please check back later.</p>
                    </div>
                )}
      
      {/*end::Body*/}
    </div>
  );
};

export default SentinmentsCard;
