import React from "react";
import { useNavigate } from "react-router-dom";
const EmailHeader = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const handleBackButtonClick = () => {
    navigate(-1); // Navigate back to the previous page
  };
  return (
    <div className="card-header align-items-center py-1 my-1 ">
      <div className="d-flex">
        <button className="btn btn-link mb-2" onClick={handleBackButtonClick}>
        <i className="bi bi-arrow-90deg-left"     style={{
                                        fontSize: "20px",
                                        color: "black",
                                        cursor: "pointer",
                                    }}></i>
        </button>
        <h2 className="p-4">Email</h2>
      </div>
    </div>
  );
};

export default EmailHeader;
