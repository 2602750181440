import React, { useEffect, useState } from "react";
import {
  CardContent,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../LoadingSpinner";
import {
  useGetAllUserListQuery,
  useGetLoginAndLogoutReportQuery,
} from "../../../../Services/reports/tickets/ReportTickets";
import { toast } from "react-toastify";
import CsvDownloader from "react-csv-downloader";

const UserLoginReport = () => {
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [params, setParams] = useState<any>(null);
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [selectedUserParam, setSelectedUserParam] = useState<string[]>([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [localLoading, setLocalLoading] = useState(false);
  const [dataRows, setDataRows] = useState<any[]>([]);
  const { data: userData, isLoading: userLoading } = useGetAllUserListQuery({});
  const [loading, setLoading] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState<string>("");

  const handleChange: any = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedAssignee(event.target.value as string);
  };



  const handleDownloadClick = () => {
    setLoading(true);

    // Simulate download completion (due to CsvDownloader's lack of a completion callback)
    setTimeout(() => {
      setLoading(false);
    }, 500); // Small delay to simulate user interaction feedback
  };

  const {
    data: userLoginLogoutReport,
    isLoading: userLoginLogoutReportLoading,
  } = useGetLoginAndLogoutReportQuery(params, {
    skip: !params,
  });
  const [theUser, setTheUser] = useState<any>([]);
  useEffect(() => {
    // Debugging UserData

    console.log(userData)
    const transformedData = userData?.map((user) => ({
      id: user.id,
      username: user.username,
    }));
    if (Array.isArray(userData)) {
      setTheUser(transformedData);
    } else {
      setTheUser([]); // Fallback to an empty array if it's not an array
    }
  }, [userData]);
  useEffect(() => {
    if (params) {
      setLocalLoading(true);
    }
  }, [params]);

  useEffect(() => {
    const dropdownHeadings = document.querySelectorAll(".dropdown-heading");
    dropdownHeadings.forEach((heading: any) => {
      heading.style.height = "50px";
    });
  }, []);
  const columns: GridColDef[] = [
    {
      field: "srNo",
      headerName: "Sr.No",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "agentName",
      headerName: "User Name",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "department",
      headerName: "Department Name",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "agentRole",
      headerName: "User Type",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "campaignName",
      headerName: "Queue Name",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "loginDate",
      headerName: "Login Date",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "loginTime",
      headerName: "Login Time",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "logoutDate",
      headerName: "Logout Date",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "logoutTime",
      headerName: "Logout Time",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },


    {
      field: "duration",
      headerName: "Total login duration",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "logoutReason",
      headerName: "Reason",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];


  console.log(selectedAssignee)
  function formatDate(datetime) {
    if (!datetime) return "No data found";
    const dateObj = new Date(datetime);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = String(dateObj.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  }

  function formatTime(datetime) {
    if (!datetime) return "No data found";
    const dateObj = new Date(datetime);
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }
  useEffect(() => {
    if (Array.isArray(userLoginLogoutReport)) {
      const transformedRows = userLoginLogoutReport.map((item: any, index: number) => ({
        id: index + 1,
        srNo: index + 1,
        agentName: item.agent_name || "No data found",
        department: item.team_name || "No data found",
        agentRole: item.agent_role || "No data found",
        campaignName: item.campaigns ? item.campaigns.split(",").join(", ") : "No data found", // Format campaigns
        loginDate: formatDate(item.login_time_display) || "No data found",
        loginTime: formatTime(item.login_time_display) || "No data found",
        logoutDate: formatDate(item.logout_time_display) || "No data found",
        logoutTime: formatTime(item.logout_time_display) || "No data found",
        duration: item.login_duration || "No data found",
        logoutReason: item.logout_reason || "No data found",
      }));

      setDataRows(transformedRows); // Set the transformed rows
    } else {
      setDataRows([]); // Set to an empty array if not an array
    }

    setLocalLoading(false);
  }, [userLoginLogoutReport]);

  const handleDateFromChange = (event: any) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event: any) => {
    setDateTo(event.target.value);
  };
  const handleSubmit = () => {
    if (!dateFrom) {
      toast.error('Please select "From Date"');
      return;
    }
    if (!dateTo) {
      toast.error('Please select "To Date"');
      return;
    }
    if (!selectedAssignee) {
      toast.error('Please select Assignee');
      return;
    }

    const queryParams: any = {
      gte: dateFrom,
      lte: dateTo,
    };

    if (selectedAssignee !== "All") {
      queryParams.user_id = selectedAssignee; // Add user_id when an assignee is selected
    }
    setParams(queryParams);
  };

  const resetFilters = () => {
    setDateFrom("");
    setDateTo("");
    setParams(null);
    setSelectedUser([]);
    setDataRows([]);
  };

  const handlePaginationChange = (newPaginationModel: any) => {
    setLocalLoading(true);
    setPaginationModel(newPaginationModel);
    setTimeout(() => {
      setLocalLoading(false);
    }, 1000);
  };



  console.log(userData)

  useEffect(() => {
    setSelectedUserParam(selectedUser);
  }, [selectedUser]);

  const ITEM_HEIGHT = 60;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };
  const isAllSelected = selectedUser.length === theUser.length;


  return (
    <div id="kt_app_content_container" className="app-container mx-5">
      <div className="card card-flush">
        <div
          className="card-header align-items-center py-1 gap-2 gap-md-5"
          style={{ justifyContent: "flex-start" }}
        >
          <img
            src="https://insurance.circleonecrm.com/assets/arrow_back.svg"
            alt="Back"
            style={{ marginBottom: "-2px", cursor: "pointer" }}
            className="ng-star-inserted"
            onClick={() => {
              navigate("/reports/telephony/reports-list");
            }}
          />
          <span className="fs-3 text-gray-800 fw-bold ms-3">
            User Login/Logout
          </span>
        </div>

        <div
          className="card-toolbar flex-row-fluid justify-content-start gap-5 d-flex mb-10"
          style={{ marginLeft: "30px" }}
        >
          <div className="w-100 mw-150px">
            <TextField
              id="date-from"
              label="From"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  max: new Date().toISOString().split("T")[0], // Restrict to today or earlier
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateFrom}
              onChange={handleDateFromChange}
            />
          </div>
          <div className="w-100 mw-150px">
            <TextField
              id="date-to"
              label="To"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  max: new Date().toISOString().split("T")[0], // Restrict to today or earlier
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateTo}
              onChange={handleDateToChange}
            />
          </div>

          <div className="w-100 mw-150px">
            <FormControl sx={{ width: "200px" }}>
              <InputLabel id="Assignee-select-label">Assignee</InputLabel>
              <Select labelId="Assignee-select-label" id="Assignee-select" label="Assignee" MenuProps={MenuProps} value={selectedAssignee}
                onChange={handleChange}>
                <MenuItem key="all" value="All">
                  All
                </MenuItem>
                {theUser?.map((assignee: any) => (
                  <MenuItem key={assignee.id} value={assignee.id}>
                    {assignee.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>


          <div className="ms-auto d-flex gap-3" style={{ marginRight: "20px" }}>
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>

            <button className="btn btn-primary" onClick={handleDownloadClick} disabled={loading}>
              {loading ? 'Loading...' : (
                <CsvDownloader
                  filename="Login-logout-report"
                  datas={dataRows}
                  columns={columns.map((col) => ({
                    id: col.field,
                    displayName: col.headerName,
                  }))}
                  separator=","
                  wrapColumnChar={`"`}
                >
                  Download
                </CsvDownloader>
              )}
            </button>

            <button className="btn btn-link" onClick={resetFilters}>
              <i className="bi bi-funnel fs-2 text-dark"></i>
            </button>
          </div>
        </div>

        <CardContent style={{ width: "100%" }}>
          {userLoading || userLoginLogoutReportLoading || localLoading ? (
            <LoadingSpinner />
          ) : (
            <DataGrid
              rows={dataRows}
              columns={columns}
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              loading={localLoading}
              disableColumnMenu
              pageSizeOptions={[5, 10, 15, 20]}
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "lightgray",
                  "&:last-child": {
                    justifyContent: "flex-end",
                  },
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-columnsContainer": {
                  backgroundColor: "lightgray",
                },
                // Target the pagination select and actions by stable component classes
                "& .MuiTablePagination-select, .MuiTablePagination-actions": {
                  marginBottom: "10px",
                },
                // Target the DataGrid overlay wrapper with its stable class
                "& .MuiDataGrid-overlayWrapper": {
                  height: "50px",
                },
              }}
            />
          )}
        </CardContent>
      </div>
    </div>
  );
};

export default UserLoginReport
  ;
