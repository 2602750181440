import clsx from 'clsx';
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { useLayout } from '../../layout/core';
import { toAbsoluteUrl } from '../../helpers';
import TopRightTools from './top-right/TopRightTools';
import HeaderContents from './HeaderContents';

const HeaderWrapper: React.FC = () => {
  const { config, classes, attributes } = useLayout();
  const { header, aside } = config;
  const data = localStorage.getItem("circleone-user")
  const showVictorinox = data === "victorinox"



  return (
    // <div  style={{display: "flex", flexDirection: "column"}}>
    <div
    
      id='kt_header'
      className='header align-items-stretch justify-content-between d-none d-lg-flex'
    
      style={{ left: 0, display: "flex", flexDirection: "column" }}
      {...attributes.headerMenu}
    >
      {/* begin::Wrapper */}
      <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
        {/* begin::Navbar */}
        <div className="app-header-menu app-header-mobile-drawer align-items-stretch ms-2" style={{display: "flex", alignItems: "center"}}>
        <Link to={showVictorinox ? '/ticket/lists' : '/dashboard/email-dashboard'}>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/CircleOne-Logo-With-Change.png')}
              className='h-60px'
            />
          </Link>
        <HeaderContents />
        </div>

        {/* {!closeTopRightTool && */}

      <div className='d-flex align-items-stretch flex-shrink-0 mx-2'>
        <TopRightTools />
      </div>
      </div>
  
      

    </div>

    // </div>


  );
};

export default HeaderWrapper;
