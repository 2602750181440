import React, { useState } from "react";
import { useSaveDespositionMutation } from "../../../../Services/general/General";
import { toast } from "react-toastify";
import { Loader } from "react-bootstrap-typeahead";

interface IProps {
  closeModal: () => void;
  setData: React.Dispatch<React.SetStateAction<any[]>>;
  data: any[];
  SetLiftingAddDate: any;
  selectedTeam: any;
  refetch: any;
}

const AddNewDispositionModal: React.FC<IProps> = ({
  closeModal,
  setData,
  data,
  SetLiftingAddDate,
  selectedTeam,
  refetch,
}) => {
  const [topic, setTopic] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [trigger, {isLoading}] = useSaveDespositionMutation();

  const handleTopicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTopic(event.target.value);
  };

  const removeLevelProperty = (nodes: any[]) => {
    return nodes.map(({ level, sub_topics, ...rest }) => ({
      ...rest,
      sub_topics: sub_topics ? removeLevelProperty(sub_topics) : [],
    }));
  };

  const handleAdd = async () => {
    setIsSubmitting(true); // Disable the button and prevent multiple submissions

    const newTopic = {
      topic: topic,
      sub_topics: [], // Initialize sub_topics as an empty array
    };

    // Create a new array with the added topic
    const updatedData = [...data, newTopic];
    const updatedDataWithoutLevel = removeLevelProperty(updatedData);

    try {
      const response = await trigger({
        body: updatedDataWithoutLevel,
        id: selectedTeam,
      });

      if (response?.data) {
        toast.success("Added successfully");
        refetch();
      }
    } catch (error) {
      toast.error("An error occurred while adding Disposition");
    }

    // Update the state with the new data
    setData(updatedDataWithoutLevel);

    // Close the modal after adding the topic
    closeModal();
    setTopic("");
    SetLiftingAddDate(updatedDataWithoutLevel);
  };

  return (
    <div>
      <div className="mt-4">
        <input
          type="text"
          className="form-control"
          placeholder="Topic"
          value={topic}
          onChange={handleTopicChange}
          disabled={isSubmitting} // Disable input while submitting
        />
      </div>
      <div className="d-flex justify-content-end mt-4">
        <button
          type="reset"
          className="btn btn-sm btn-white text-hover-danger me-4"
          onClick={closeModal}
          disabled={isSubmitting} // Disable button while submitting
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={handleAdd}
          disabled={!topic.trim() || isSubmitting} // Disable button if topic is empty or submitting
        >
          {isLoading ? <>Adding... <Loader /></> : "Add"}
        </button>
      </div>
    </div>
  );
};

export default AddNewDispositionModal;
