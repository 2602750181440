import LeadCountCard from "./cards/LeadCountCard";
import BarChart from "./charts/BarChart";
import PieChart from "./charts/PieChart";
import HighChartFunnel from "./charts/HighChartFunnel";
import CampaignDispositionCard from "./cards/CampaignDispositionCard";
import CustomDate from "../../../components/common/CustomDate";
import { useEffect, useState } from "react";
import { useDashboardQuery } from "../../../Services/dashboard/dashboard";
import { useMeeQuery } from "../../../Services/settingsAPIs/CommonAPI";

const CampaignDashboard = () => {
  const [selectedTeam, setSelectedTeam] = useState(1);
  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [theTeams, setTheTeams] = useState([]);

  const { data: teamsData } = useMeeQuery({});


  const { data } = useDashboardQuery(selectedTeam);

  useEffect(() => {
    if (teamsData) {
        // Extracting teams from the user's teams data
        const userTeams = teamsData.teams;
        setTheTeams(userTeams);
    
    }
}, [teamsData]);

  const handleTeamChange = (e) => {
    setSelectedTeam(e.target.value);
    // Handle team selection logic here
};
  return (
    <div id="kt_app_content_container" className="app-container mx-5">
      {/*begin::Dashboard*/}
      <div className="card card-flush">
        {/*begin::Card header*/}
        <div className="card-header align-items-center py-1 my-1 gap-2 gap-md-5" style={{width: "100%"}}>
          {/*begin::Card title*/}
          <div className="card-title">
            {/*begin::Search*/}
            <div className="d-flex align-items-center position-relative my-1">
              <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
              <h2 className="">Leads Dashboard</h2>
            </div>
            {/*end::Search*/}
          </div>
          {/*end::Card title*/}

          {/*begin::Card toolbar*/}
          <div className="d-flex align-items-center gap-2 gap-lg-10" style={{width: "33%", gap: "10px"}}>
                <div className="w-100 mw-200px" style={{whiteSpace: "nowrap"}}>
                  <CustomDate dateRange={dateRange} setDateRange={setDateRange}/>
                </div>
                <div className="w-100 mw-250px" style={{position: "relative"}}>
                                <div
                                        style={{
                                            position: "absolute",
                                            top: "-9px",
                                            left: "5px",
                                            background: "white",
                                        }}
                                    >
                                        <p style={{ margin: "0", fontSize: "12px" }}>
                                             Team
                                        </p>
                                    </div>
                                    {/*begin::Select2*/}
                                    <select
                                        className="form-select"
                                        onChange={handleTeamChange}
                                        value={selectedTeam}
                                    >
                                    
                                        {theTeams.map((team) => (
                                            <option
                                                key={team.id}
                                                value={team.id}
                                            >
                                                {team.name}
                                            </option>
                                        ))}
                                    </select>
                                    {/*end::Select2*/}
                                </div>
            <div className="w-100 mw-350px">
              {/*begin::Select2*/}
              <select
                className="form-select select2-hidden-accessible"
                data-control="select2"
                data-hide-search="true"
                data-placeholder="Status"
                data-kt-ecommerce-product-filter="status"
                data-select2-id="select2-data-9-cpv9"
                tabIndex={-1}
                aria-hidden="true"
                data-kt-initialized="1"
              >
                <option data-select2-id="select2-data-11-qb6b"></option>
                <option value="campaign" selected>
                   Campaign
                </option>
                <option value="campaign 01">Campaign 01</option>
                <option value="campaign 02">Campaign 02</option>
                <option value="campaign 03">Campaign 03</option>
              </select>
              {/*end::Select2*/}
            </div>
          </div>
          {/*end::Card toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body pt-0">
          <section className="section dashboard">

            <div className="row">
              {/*<!-- Start Left side columns -->*/}
              <div className="col-lg-8">
                <div className="row">
                  <LeadCountCard/>

                  {/*<!-- End Reports -->*/}
                </div>
              </div>
              {/*<!-- End Left side columns -->*/}
              {/*<!-- Start Right side columns -->*/}
              <div className="col-lg-4">
                <div className="row">
                  {/*<!-- BarcChart Card -->*/}
                  <div className="col-xxl-6 col-md-6 pb-2">
                    <div className="card info-card sales-card">
                      <BarChart />
                    </div>
                  </div>
                  {/*<!-- End Sales Card -->*/}

                  {/*<!-- Revenue Card -->*/}
                  <div className="col-xxl-6 col-md-6 pb-2">
                    <div className="card info-card revenue-card">
                      <BarChart />
                    </div>
                  </div>
                  {/*<!-- End Revenue Card -->*/}
                </div>
                <div className="row">
                  {/*<!-- BarcChart Card -->*/}
                  <div className="col-xxl-6 col-md-6 pt-2">
                    <div className="card info-card sales-card">
                      <BarChart />
                    </div>
                  </div>
                  {/*<!-- End Sales Card -->*/}

                  {/*<!-- Revenue Card -->*/}
                  <div className="col-xxl-6 col-md-6 pt-2">
                    <div className="card info-card revenue-card">
                      <BarChart />
                    </div>
                  </div>
                  {/*<!-- End Revenue Card -->*/}
                </div>
              </div>
              {/*<!-- End Right side columns -->*/}
            </div>

            <div className="row">
              {/*<!-- Start Left side columns -->*/}
              <div className="col-lg-6 pt-2">
                <div className="row">
                  <div className="card mb-3">
                    <div className="row g-0">
                      <div className="col-md-12">
                        <PieChart />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <CampaignDispositionCard/>
                </div>
              </div>
              {/*<!-- End Left side columns -->*/}
              {/*<!-- Start Right side columns -->*/}
              <div className="col-lg-6 pt-2">
                <div className="row">
                  <div className="card mx-2">
                    {/* <div className="card-body pb-0">
                      <FunnelChart />
                    </div> */}

                  <div className="card-body pb-0">
                      <h5 className="card-title">
                        Campaign funnel
                      </h5>

                      <div
                        id="FunnelChart"
                        style={{ minHeight: "450px", userSelect: "none" }}
                        className="echart"
                      >
                        <div
                          style={{
                            position: "relative",
                            padding: "0px",
                            margin: "0px",
                            borderWidth: "0px",
                            cursor: "default",
                          }}
                        >
                          <HighChartFunnel />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*<!-- End Right side columns -->*/}
            </div>
          </section>
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Dashboard*/}
    </div>
  );
};

export default CampaignDashboard;
