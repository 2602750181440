import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

// const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = "http://localhost:8000/api/"

export const Lookup_by_disposition = createApi({
  reducerPath: "lookup_by_disposition",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    Lookup_by_disposition: builder.query({
      query: ({body, teamId}) => {
        return {
          url: `v1/common/disposition/${body}/lookup_by_disposition/?Team_id=${teamId}&`,
          method: "GET",
        }
      },
    }),
  }),
});

export const { useLookup_by_dispositionQuery } = Lookup_by_disposition;
