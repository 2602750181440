const AddTheTagBasedSLAModal = ({closeModal}) => {
    return(<>
      <div className="d-flex flex-column align-items-center w-300px w-lg-600px">
        <div className="row">
          <div className="col">
            <label className= "fs-4" htmlFor="">Add level</label>
          </div>
        </div>      
        <div className="row min-w-1000px">
          <div className="col">
            <div className="input-group-text mt-2 bg-white">
              <div className="row w-100 align-items-center">
                <div className="col-lg-4 col text-start"><input className='form-control border-0' type="text" value="Escalation level 01"/></div>
                <div className="col-1">
                    <div className="d-flex justify-content-start align-items-center">
                      <label htmlFor="">00</label>
                      <div className="d-flex flex-column ms-2">
                        <i className="bi bi-caret-up-fill"></i>
                        <i className="bi bi-caret-down-fill"></i>
                      </div>
                    </div>
                </div>
                <div className="col-1">
                    <div className="d-flex justify-content-start align-items-center">
                      <label htmlFor="">00</label>
                      <div className="d-flex flex-column ms-2">
                        <i className="bi bi-caret-up-fill"></i>
                        <i className="bi bi-caret-down-fill"></i>
                      </div>
                    </div>
                </div>
                <div className="col-lg-6 col text-start"><input className='form-control border-0' type="text" placeholder="mail id"/></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col text-end">
          <button className="btn btn-primary" onClick={closeModal}>Save</button>
        </div>
      </div>
    </>)
}

export default AddTheTagBasedSLAModal