import React, { useState } from "react";
import { CloseButton } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { usePasswordResetMutation } from "../../Services/user/Users";
import { toast } from "react-toastify";
import clsx from "clsx";

interface IProps {
  closeResetPasswordModal: () => void;
  resetPass: any;
  refetch: () => void;
}

const changePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .max(50, "Maximum 50 characters allowed")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one special character")
    .required("Password is required"),
});

const ResetPasswordModal: React.FC<IProps> = ({
  closeResetPasswordModal,
  resetPass,
  refetch,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [resetPassword] = usePasswordResetMutation(resetPass.username);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    password: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: async (values) => {
      try {
        const obj = {
          username: resetPass.username,
          password: values.password,
        };

        const response = await resetPassword({
          body: obj,
          username: resetPass.username,
        });

        if (response.data) {
          toast.success("Password updated successfully");
          closeResetPasswordModal();
          refetch();
        } else {
          const errorData = response.error as any;
          toast.error(errorData.data?.detail || "An error occurred.");
        }
      } catch (error) {
        toast.error("Failed to reset password");
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="text-end">
          <CloseButton onClick={closeResetPasswordModal} />
        </div>
        <div className="text-center mb-5">
          <label className="form-label fs-4 fw-bolder text-dark card-title">
            Reset Password
          </label>
        </div>

        <div className="w-100 mb-4">
          <div className="input-group">
            <input
              type={showPassword ? "text" : "password"}
              autoComplete="off"
              placeholder="Enter your password"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <div className="input-group-append">
              <span
                className="form-control form-control-lg form-control-solid"
                id="togglePassword"
                onClick={togglePasswordVisibility}
                style={{
                  cursor: "pointer",
                  borderRadius: "0px 5px 5px 0px",
                }}
              >
                {showPassword ? (
                  <i className="bi bi-eye-slash text-dark"></i>
                ) : (
                  <i className="bi bi-eye text-dark"></i>
                )}
              </span>
            </div>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container text-danger mt-2">
              <span className="mx-2" role="alert">
                {formik.errors.password}
              </span>
            </div>
          )}
        </div>
        <div className="row text-end">
          <div className="col">
            <button type="submit" className="btn btn-primary mt-5">
              Reset password
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ResetPasswordModal;
