import React, { useEffect, useState } from "react";
import { useUpdateSMTPMailConfigMutation } from "../../../../Services/settingsAPIs/EmailConfiguration";
import { toast } from "react-toastify";

const AutoNotificationOn = ({
  smtpMailConfigurations,
  selectedSMTPEmailConfig,
  setSelectedSMTPEmailConfig,
  refetchSMTPConfigs,
}) => {
  const [textareaContent, setTextareaContent] = useState("");
  const [autoResponseEnabled, setAutoResponseEnabled] = useState(false);
  const [triggerUpdateSMTPmailConfigMutation] =
    useUpdateSMTPMailConfigMutation();
  const openEditModal = (configId: number) => {};
  const openDeleteModal = (configId: number) => {};
  const handleSelectEmailChange = (e: any) => {
    setSelectedSMTPEmailConfig(Number(e.target.value));
  };
  const handleButtonClick = (text) => {
    setTextareaContent((prevContent) => prevContent + `{{${text}}}`);
  };
  const handleSaveAR = () => {
    triggerUpdateSMTPmailConfigMutation({
      configId: selectedSMTPEmailConfig,
      body: {
        email_auto_response_enabled: autoResponseEnabled,
        email_auto_response_template: textareaContent,
      },
    })
      .then((res: any) => {
        res.data && toast.success("Updated the auto response");
        refetchSMTPConfigs();
      })
      .catch((err: any) => toast.error(err));
  };

  useEffect(() => {
    const configDataFound = smtpMailConfigurations?.find(
      (smtpEmailConfig: any) => smtpEmailConfig.id === selectedSMTPEmailConfig
    );
    configDataFound !== undefined &&
    configDataFound.email_auto_response_template
      ? setTextareaContent(configDataFound.email_auto_response_template)
      : setTextareaContent("");
    configDataFound !== undefined
      ? setAutoResponseEnabled(configDataFound.email_auto_response_enabled)
      : setAutoResponseEnabled(false);
  }, [selectedSMTPEmailConfig]);

  return (
    <>
      <div>
        <div className="row">
          <div className="col-2">
            <select
              className="form-select select2-hidden-accessible min-w-250px"
              onChange={handleSelectEmailChange}
              value={selectedSMTPEmailConfig}
            >
              <option selected disabled value={null}>
                Select Email Id
              </option>
              {smtpMailConfigurations?.map((smtpEmailConfig: any) => (
                <option value={smtpEmailConfig?.id} key={smtpEmailConfig?.id}>
                  {smtpEmailConfig?.username}
                </option>
              ))}
            </select>
          </div>
        </div>
        {smtpMailConfigurations.map((smtpEmailConfig: any) => (
          <div className="row mt-1 d-none">
            <div className="col-12">
              <span className="input-group-text bg-white mt-2">
                {smtpEmailConfig.username}
                <i
                  className="ms-auto text-dark bi bi-pencil-fill fs-2"
                  onClick={() => openEditModal(smtpEmailConfig.id)}
                ></i>
                <i
                  className="bi text-dark bi-trash-fill fs-2 mx-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => openDeleteModal(smtpEmailConfig.id)}
                ></i>
              </span>
            </div>
          </div>
        ))}

        <div className="row mt-2">
          <div className="col d-flex justify-content-between">
            <div
              className={`d-flex align-items-baseline ${
                !selectedSMTPEmailConfig ? "d-none" : ""
              }`}
            >
              <p
                style={{
                  margin: "0",
                  fontSize: "14px",
                  fontWeight: "600",
                  width: "120px",
                }}
              >
                {" "}
                Auto response{" "}
              </p>
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <span style={{ fontSize: "16px" }}>Off</span>
                <div className="form-check form-switch">
                  <input
                    id="autorestoggler"
                    className="form-check-input"
                    type="checkbox"
                    disabled={!selectedSMTPEmailConfig}
                    checked={autoResponseEnabled}
                    onChange={() =>
                      setAutoResponseEnabled(!autoResponseEnabled)
                    }
                  />
                </div>
                <span style={{ fontSize: "16px" }}>On</span>
              </div>
            </div>
            <div
              className={`d-flex flex-column ${
                !selectedSMTPEmailConfig ? "d-none" : ""
              }`}
            >
              <button
                className="btn btn-secondary"
                onClick={() => handleButtonClick("Sender Name")}
              >
                Sender name
              </button>
              <button
                className="btn btn-secondary mt-2"
                onClick={() => handleButtonClick("Ticket Id")}
              >
                Ticket id
              </button>
            </div>
          </div>
        </div>
        <div className={`row ${!selectedSMTPEmailConfig ? "d-none" : ""}`}>
          <div className="col mt-2 flex-column">
            {/* <span className="input-group-text bg-white rounded-0">Send to - {sendTo ? sendTo : "Not assigned"}</span> */}
            <textarea
              className="w-100 border"
              name=""
              id=""
              cols={30}
              rows={10}
              value={textareaContent}
              onChange={(e) => setTextareaContent(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className={`row mt-2 ${!selectedSMTPEmailConfig ? "d-none" : ""}`}>
          <div className="col">
            <button
              className="btn btn-primary min-w-150px float-end"
              onClick={handleSaveAR}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoNotificationOn;
