import CampaignField from "./CampaignField"

const CampaignIndex = () => {
    return (
        <div>
            <CampaignField />
        </div>
    )
}

export default CampaignIndex