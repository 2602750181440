import "./NestedDD.css";
import { useEffect, useRef, useState } from "react";

interface NestedDDProps {
  menuItemsData: Array<any>;
  setSelectedDisposition: any;
}
interface MenuItemsProps {
  items: any;
  depthLevel: number;
  setSelectedDisposition: any;
}
interface DropdownProps {
  submenus: any;
  dropdown: any;
  depthLevel: number;
  setSelectedDisposition: any;
}

const Dropdown: React.FC<DropdownProps> = ({
  submenus,
  dropdown,
  depthLevel,
  setSelectedDisposition,
}) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";

  return (
    <ul
      className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}
      style={{ width: "max-content" }}
    >
      {submenus.map((submenu: any, index: number) => (
        <MenuItems
          items={submenu}
          key={index}
          depthLevel={depthLevel}
          setSelectedDisposition={setSelectedDisposition}
        />
      ))}
    </ul>
  );
};
const MenuItems: React.FC<MenuItemsProps> = ({
  items,
  depthLevel,
  setSelectedDisposition,
}) => {
  const [dropdown, setDropdown] = useState(false);
  let ref = useRef<any>();

  useEffect(() => {
    const handler = (event: any) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    setDropdown(true);
  };
  const onMouseLeave = () => {
    setDropdown(false);
  };
  const toggleDropdown = () => {
    setDropdown((prev) => !prev);
  };
  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
    >
      {items.topic && items.sub_topics?.length ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => toggleDropdown()}
          >
            <a href="#">{items.topic}</a>
            {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.sub_topics}
            dropdown={dropdown}
            setSelectedDisposition={setSelectedDisposition}
          />
        </>
      ) : !items.topic && items.sub_topics.length ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
          >
            {items.topic}
            {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.sub_topics}
            dropdown={dropdown}
            setSelectedDisposition={setSelectedDisposition}
          />
        </>
      ) : (
        <button type="button" onClick={() => setSelectedDisposition(items)}>
          {" "}
          {items.topic}{" "}
        </button>
      )}
    </li>
  );
};
const NestedDD: React.FC<NestedDDProps> = ({
  menuItemsData = [],
  setSelectedDisposition,
}) => {
  const rootMenu = [
    {
      topic: "Menu",
      sub_topics: Array.isArray(menuItemsData) ? [...menuItemsData] : [],
    },
  ];

  const depthLevel = 0;
  return (
    <div>
      <header>
        <div className="nav-area p-0">
          {/* for large screens */}
          <nav className="desktop-nav">
            <ul className="menus m-0 p-0">
              {rootMenu?.map((menu: any, index: number) => {
                return (
                  <MenuItems
                    items={menu}
                    key={index}
                    depthLevel={depthLevel}
                    setSelectedDisposition={setSelectedDisposition}
                  />
                );
              })}
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default NestedDD;
