import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


interface ButtonProps {
    onClick: (event: any, direction: "previous" | "next" | "turn-left") => void;
}

const PrevNextActions: React.FC<ButtonProps> = ({ onClick }) => {
    return (
        <div
            style={{ display: "flex", justifyContent: "end", width: "100%", gap: "20px" }}
        >
            <span onClick={(event) => onClick(event, "previous")} data-toggle="tooltip" data-placement="top" title="Previous">
                <i className="bi bi-arrow-left-square" style={{ fontSize: "20px", cursor: "pointer" }}></i>
            </span>
        
            <span data-toggle="tooltip" data-placement="top" title="Next"
                onClick={(event) => onClick(event, "next")}
            >
                <i className="bi bi-arrow-right-square" style={{ fontSize: "20px", cursor: "pointer",  }}></i>

            </span>
        </div>
    );
};

export default PrevNextActions;
