import React from 'react'

const PhoneCard = () => {
    return (
        <>
            <div>
                <p>Good morning/afternoon/evening, how may i help you today.
                    <br>
                    </br>
                    Simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,when an unknown printer took a galley</p>
            </div>
        </>
    )
}

export default PhoneCard