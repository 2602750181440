import React, { useEffect, useState } from "react";
import {
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../LoadingSpinner";
import {
  useGetAllTeamsQuery,
  useGetAllWorkflowSelectedTeamIdQuery,
  useGetLatestStatusReportQuery,
} from "../../../../Services/reports/tickets/ReportTickets";
import { toast } from "react-toastify";
import CsvDownloader from "react-csv-downloader";
import { MultiSelect } from "react-multi-select-component";

const LatestStatusReport = () => {
  const navigate = useNavigate();
  const {
    data: teamsData,
    error: teamsError,
    isLoading: teamsLoading,
  } = useGetAllTeamsQuery({});
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null);
  const [params, setParams] = useState<any>(null);
  const [optionsTeams, setOptionsTeams] = useState<any[]>([]);
  const [optionsWorkflow, setOptionsWorkflow] = useState<any[]>([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState<string | null>(null);
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<any[]>([]);
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any>([]);
  const [localLoading, setLocalLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const { data: workflowData, isLoading: workflowLoading } =
    useGetAllWorkflowSelectedTeamIdQuery(selectedTeamId, {
      skip: !selectedTeamId,
    });

  const {
    data: latestStatusReportReport,
    error

  } = useGetLatestStatusReportQuery(params, {
    skip: !params,
  });
   const [loading, setLoading] = useState(false);

  const handleDownloadClick = () => {
    setLoading(true);

    // Simulate the completion of the download after interaction
    setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust or remove this delay as needed
  };


  const optionsStatus: any[] = [
    { label: "Active", value: "active" },
    { label: "Closed", value: "closed" },
    { label: "Reopen", value: "reopen" },
    { label: "In Progress", value: "wip" },
  ];

  useEffect(() => {
    if (teamsData && teamsData.teams?.length > 0) {
      const transformedOptions = teamsData.teams.map((team: any) => ({
        label: team.name,
        value: team.id,
      }));
      setOptionsTeams(transformedOptions);
    }
  }, [teamsData]);

  useEffect(() => {
    if (workflowData && Array.isArray(workflowData.results)) {
      const transformedWorkflows = workflowData.results.map(
        (workflow: any) => ({
          label: workflow.name,
          value: workflow.id,
        })
      );
      setOptionsWorkflow(transformedWorkflows);
    }
  }, [workflowData]);

  useEffect(() => {
    if (latestStatusReportReport && latestStatusReportReport.length > 0) {
      const keys = Object.keys(latestStatusReportReport[0]);
      const newColumns = keys.map((key) => ({
        field: key,
        headerName: key.replace(/_/g, " "), // Replace underscores with spaces for header names
        align: "center",
        headerAlign: "center",
      }));
      setColumns(newColumns);

      const newRows = latestStatusReportReport.map((row, index) => {
        const processedRow = {};
        keys.forEach((key) => {
          // Replace empty strings or null values with "No data found"
          processedRow[key] =
            row[key] === "" ||
              row[key] === null ||
              row[key] === "-" ||
              row[key] === "--"
              ? "No data found"
              : row[key];
        });
        return {
          id: index, // Add a unique id field to each row
          ...processedRow,
        };
      });

      setDataRows(newRows);
      setLocalLoading(false);
    } else {
      // Handle the case when `latestStatusReportReport` is empty or not available
      setColumns([]);
      setDataRows([]);
      setLocalLoading(false);
    }
  }, [latestStatusReportReport]);

  useEffect(() => {
    const dropdownHeadings = document.querySelectorAll(".dropdown-heading");
    dropdownHeadings.forEach((heading: any) => {
      heading.style.height = "50px";
    });
  }, []);

  const handleTeamChange = (event: any) => {
    setSelectedTeamId(event.target.value);
  };

  const handleWorkflowChange = (event: any) => {
    setSelectedWorkflow(event.target.value);
  };

  const handleDateFromChange = (event: any) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event: any) => {
    setDateTo(event.target.value);
  };

  const handleSubmit = () => {
    if (!dateFrom) {
      toast.error('Please select "From Date"');
      return;
    }
    if (!dateTo) {
      toast.error('Please select "To Date"');
      return;
    }

    if (!selectedTeamId) {
      toast.error("Please select a team");
      return;
    }

    if (!selectedWorkflow) {
      toast.error("Please select a workflow");
      return;
    }

    if (!selectedStatus.length) {
      toast.error("Please select a status");
      return;
    }
    const queryParams: any = {
      gte: dateFrom,
      lte: dateTo,
      status_type: "",
    };

    if (selectedTeamId !== "All") {
      queryParams.team_id = selectedTeamId;
    }

    if (selectedWorkflow) {
      queryParams.workflow = selectedWorkflow;
    }

    if (selectedStatus.length > 0) {
      queryParams.status_type = selectedStatus.map((status) => status.value);
    }

    setParams(queryParams);
    setLocalLoading(true); // Start loading when submitting


  };

  const handlePaginationChange = (newPaginationModel: any) => {
    setLocalLoading(true);
    setPaginationModel(newPaginationModel);
    setTimeout(() => {
      setLocalLoading(false);
    }, 1000);
  };

  const resetFilters = () => {
    setSelectedTeamId(null);
    setSelectedWorkflow(null);
    setDateFrom("");
    setDateTo("");
    setParams(null);
    setSelectedStatus([]);
    setDataRows([]);
  };

  if (error) {
    setLocalLoading(false);
  }

  return (
    <div id="kt_app_content_container" className="app-container mx-5">
      <div className="card card-flush">
        {/* Card Header */}
        <div
          className="card-header align-items-center py-1 gap-2 gap-md-5"
          style={{ justifyContent: "flex-start" }}
        >
          <img
            src="https://insurance.circleonecrm.com/assets/arrow_back.svg"
            alt="Back"
            style={{ marginBottom: "-2px", cursor: "pointer" }}
            className="ng-star-inserted"
            onClick={() => navigate("/reports/ticket/reports-list")}
          />
          <span className="fs-3 text-gray-800 fw-bold ms-3">
            Ticket Status Summary
          </span>
        </div>

        {/* Toolbar */}
        <div
          className="card-toolbar flex-row-fluid justify-content-start gap-5 d-flex mb-10"
          style={{ marginLeft: "30px" }}
        >
          {/* Date Filters */}
          <div className="w-100 mw-150px">
            <TextField
              id="date-from"
              label="From"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateFrom}
              onChange={handleDateFromChange}
            />
          </div>
          <div className="w-100 mw-150px">
            <TextField
              id="date-to"
              label="To"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateTo}
              onChange={handleDateToChange}
            />
          </div>
          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="team-select-label">Select Teams</InputLabel>
              <Select
                labelId="team-select-label"
                id="team-select"
                value={selectedTeamId || ""}
                onChange={handleTeamChange}
                label="Select Teams"
              >
                {optionsTeams.map((team) => (
                  <MenuItem key={team.value} value={team.value}>
                    {team.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="workflow-select-label">Workflow</InputLabel>
              <Select
                labelId="workflow-select-label"
                id="workflow-select"
                value={selectedWorkflow || ""}
                onChange={handleWorkflowChange}
                label="Workflow"
              >
                {optionsWorkflow.map((workflow) => (
                  <MenuItem key={workflow.value} value={workflow.value}>
                    {workflow.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <MultiSelect
                options={optionsStatus}
                value={selectedStatus}
                onChange={setSelectedStatus}
                labelledBy="Select"
              />
            </FormControl>
          </div>

          <div className="ms-auto d-flex gap-3" style={{ marginRight: "20px" }}>
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>
            <button className="btn btn-primary" onClick={handleDownloadClick} disabled={loading}>
              {loading ? 'Loading...' : (
                <CsvDownloader
                  filename="Ticket Status Summary"
                  datas={dataRows}
                  columns={columns.map((col) => ({
                    id: col.field,
                    displayName: col.headerName,
                  }))}
                  separator=","
                  wrapColumnChar={`"`}
                >
                  Download
                </CsvDownloader>
              )}
            </button>
            <button className="btn btn-link" onClick={resetFilters}>
              <i className="bi bi-funnel fs-2 text-dark"></i>
            </button>
          </div>
        </div>

        {/* DataGrid */}
        <CardContent style={{ width: "100%" }}>
          {
            localLoading ? (
              <LoadingSpinner />
            ) : (
              <DataGrid
                rows={dataRows}
                columns={columns}
                pagination
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationChange}
                disableColumnMenu
                pageSizeOptions={[5, 10, 15, 20]}
                localeText={{
                  noRowsLabel: "No rows found",
                }}
                sx={{
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "lightgray",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: "lightgray",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "bold",
                    overflow: "visible",
                    whiteSpace: "normal",
                    textOverflow: "initial",
                    lineHeight: "normal",
                    textAlign: "center",
                  },
                  "& .MuiDataGrid-cell": {
                    textAlign: "center",
                  },
                  // Target the pagination select and actions by stable component classes
                  "& .MuiTablePagination-select, .MuiTablePagination-actions": {
                    marginBottom: "10px",
                  },
                  // Target the DataGrid overlay wrapper with its stable class
                  "& .MuiDataGrid-overlayWrapper": {
                    height: "50px",
                  },
                }}
              />
            )}
        </CardContent>
      </div>
    </div>
  );
};

export default LatestStatusReport;
