import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import {
    useGetSearchByEmailQuery,
    useGetLookupByEmailQuery,
    useThreadsMutation,
    useGetTemplateQuery,
    useReplyQuery,
} from "../../../Services/email/EmailTemplate";
import EmailTemplateModal from "../EmailTemplateModal";
import ContactDetailsFill from "../composeEmailModal/ContactDetailsFill";
import { toast } from "react-toastify";
import TicketsDetailsModal from "../inboxEmailModal/TicketsDetailsModal";
import { Box, IconButton } from "@mui/material";
import AddNewTicketContactModal from "../../ticket/AddNewTicketContactModal";
import DispositionModal from "../../../components/ticket/DispositionModal";
import {
    useReplyMailMutation,
    useUpdateDispositionMutation,
} from "../../../Services/email/EmailInbox";
import LoadingSpinner from "../../LoadingSpinner";
import WorkflowModal from "../../ticket/WorkflowModal";
import { Loader } from "react-bootstrap-typeahead";
import DispositionTicketModal from "../../ticket/DispositionTicketModal";

interface IProps {
    otherMailDetails: any;
    mailDetails: any;
    replyText: any;
    uuid: any;
    refetchSpecificThreads: any;
    setShowReplyCard: any;
    storedMailHistory: any;
    storedTicketId: any;
}

const ReplyCard: React.FC<IProps> = ({
    otherMailDetails,
    mailDetails,
    replyText,
    uuid,
    refetchSpecificThreads,
    setShowReplyCard,
    storedMailHistory,
    storedTicketId,
}) => {
    const [showCCField, setShowCCField] = useState(replyText === "replyAll");
    const [showBccField, setShowBccField] = useState(replyText === "replyAll");
    const [emails, setEmails] = useState<any>([]);
    const [cc, setCc] = useState<any>([]);
    const [bcc, setBcc] = useState<any>([]);
    const [subject, setSubject] = useState<any>("");
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isModalOpenContactDetailsFill, setIsModalOpenContactDetailsFill] =
        useState<boolean>(false);
    const [selectedTemplateDescription, setSelectedTemplateDescription] =
        useState<any>("");
    const [storeTemplateData, setStoreTemplateData] = useState<any>([]);
    const [editorHtml, setEditorHtml] = useState<any>(
        selectedTemplateDescription.content
    );
    const [inputValue, setInputValue] = useState("");
    const [ccInputValue, setCcInputValue] = useState("");
    const [bccInputValue, setBccInputValue] = useState("");
    const [queryParam, setQueryParam] = useState();
    const [emailOptions, setEmailOptions] = useState<any[]>([]);
    const [lookupEmail, setLookupEmail] = useState("");
    const [dispositionModal, setDispositionModal] = useState<boolean>(false);
    const [ticketDetailsModal, setTicketDetailsModal] =
        useState<boolean>(false);
    const [selectedTopics, setSelectedTopics] = useState<any[]>([]);
    const [lastSelectedDisposition, setLastSelectedDisposition] =
        useState<any>("");
    const [emailNotPresent, setEmailNotPresent] = useState<any>("");
    const [files, setFiles] = useState([]);
    const [templateAttachment, setTemplateAttachment] = useState<any>();
    const [isAddNewContactModalOpen, setAddNewContactModalOpen] =
        useState(false);
    const [selectedContact, setSelectedContact] = useState<any>();
    const [storeNewEmailId, setStoreNewEmailId] = useState("");
    const [liftingReplyLoader, setLiftingReplyLoader] = useState(false);
    const [checkingForReplyApi, setCheckingForReplyApi] = useState(false);
    const [openNonValueDispositionModal, setOpenNonValueDispositionModal] = useState(false)
    const [trigger, { isLoading: loading }] = useUpdateDispositionMutation();
    const [replyMail, { status: replyStatus }] = useReplyMailMutation();

    const { data } = useGetSearchByEmailQuery(queryParam, {
        skip: !queryParam,
    });
    const { data: lookupEmailData } = useGetLookupByEmailQuery(lookupEmail, {
        skip: !lookupEmail,
    });
    const { data: getTemplate } = useGetTemplateQuery({});

    const { data: getReplyMail } = useReplyQuery({
        replyText,
        uuid,
        mailDetails,
    });
    const [thredsEmail] = useThreadsMutation();
    // Extract email options from the data

    const extractEmailOptions = (data) => {
        if (!data) return [];
        return data.map((item) => ({ label: item.email }));
    };

    console.log(otherMailDetails?.action?.ticket, "storedMailHistory");

    useEffect(() => {
        if (data) {
            setEmailOptions(extractEmailOptions(data));
        }
    }, [data]);

    useEffect(() => {
        if (getTemplate) {
            setStoreTemplateData(getTemplate);
        }
    }, [getTemplate]);
    useEffect(() => {
        if (selectedTemplateDescription) {
            setSubject(selectedTemplateDescription.subject);
            setEditorHtml(selectedTemplateDescription.content);
        }
    }, [selectedTemplateDescription]);

    const handleInputChange = (event, newInputValue, setInputValue) => {
        if (event?.type === "click" || event?.type === "keydown") {
            setInputValue("");
            const emailOptionExists = emailOptions.some(
                (option) => option.label === newInputValue
            );

            if (!emailOptionExists) {
                setEmailNotPresent(false);
                openContactModal();
                setStoreNewEmailId(event.target.value);
            }
        }
        if (event?.type === "change") {
            const queryParams: any = {
                email: newInputValue,
            };

            setInputValue(newInputValue);
            setQueryParam(queryParams);
        }
    };

    const handleAddEmail = (email, setEmails, emails) => {
        if (email && isValidEmail(email)) {
            if (!emails.includes(email.trim())) {
                setEmails([...emails, email.trim()]);
                setInputValue("");
            }
        }
    };

    const handleDeleteEmail = (emailToDelete, setEmails, emails) => {
        setEmails(emails.filter((email) => email !== emailToDelete));
    };

    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const handleFileChange = async (event) => {
        const newFiles = Array.from(event.target.files);
        const filePromises = newFiles.map((file: any) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    const fileData = {
                        file_name: file.name,
                        file: e.target.result.split(",")[1], // Extracting Base64 part
                    };
                    resolve(fileData);
                };
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        });

        try {
            const fileDataArray = await Promise.all(filePromises);
            setFiles((prevFiles) => [...prevFiles, ...fileDataArray]);
        } catch (error) {
            console.error("Error reading files:", error);
            toast.error(
                "An error occurred while uploading files. Please try again."
            );
        }
    };
    const customModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "450px",
            // height: "600px",
        },
    };
    const customModalStyles1 = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "700px",
            height: "600px",
        },
    };

    const customModalStyles2 = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            maxHeight: "500px",
        },
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const afterOpenModal = () => {
        // subtitle.style.color = '#f00'
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const closeNonDispositionValueModal = () => {
        setOpenNonValueDispositionModal(false)
    }

    const handleEditorChange = (content) => {
        setEditorHtml(content);
    };

    const openModalContactDetailsFill = () => {
        setIsModalOpenContactDetailsFill(true);
    };

    const afterOpenModalContactDetailsFill = () => {
        // subtitle.style.color = '#f00'
    };

    const closeModalContactDetailsFill = () => {
        setIsModalOpenContactDetailsFill(false);
    };

    const openDispositionModal = () => {
        setDispositionModal(true);
    };

    const afterOpenDispositionModal = () => {
        // subtitle.style.color = '#f00'
    };

    const closeDispositionModal = () => {
        setDispositionModal(false);
    };

    const openContactModal = () => {
        setAddNewContactModalOpen(true);
    };

    const closeContactModal = () => {
        setAddNewContactModalOpen(false);
    };

    const toggleCCField = () => {
        setShowCCField(!showCCField);
    };

    const toggleBccField = () => {
        setShowBccField(!showBccField);
    };

    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "align",
        "script",
        "background",
        "blockquote",
        "code-block",
        "header",
        "indent",
        "list",
        "script",
    ];

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }, { font: [] }],
            ["bold", "italic", "underline", "strike"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            ["link", "image", "blockquote", "code-block"],
            [{ color: [] }, { background: [] }, { align: [] }],
            ["clean"],
        ],
    };

    useEffect(() => {
        setEditorHtml(selectedTemplateDescription.description);
    }, [selectedTemplateDescription]);

    useEffect(() => {
        if (
            selectedTemplateDescription &&
            selectedTemplateDescription.content
        ) {
            setSubject(selectedTemplateDescription.subject || "");
            setEditorHtml(selectedTemplateDescription.content);
        }
    }, [selectedTemplateDescription]);

    useEffect(() => {
        if (getReplyMail) {
            if (replyText === "reply") {
                const toEmails = Array.from(new Set(getReplyMail.to));
                setEmails(toEmails);
                setCc([]);
                setBcc([]);
            } else if (replyText === "replyAll") {
                const toEmails = Array.from(new Set(getReplyMail.to));
                const ccEmails = Array.from(new Set(getReplyMail.cc || []));
                const bccEmails = Array.from(new Set(getReplyMail.bcc || []));
                setEmails(toEmails);
                setCc(ccEmails);
                setBcc(bccEmails);
            }
        }
    }, [replyText, getReplyMail]);

    console.log(otherMailDetails, "otherMailsDetailsl")

    const handleSubmit = async () => {
        const showAssignError = otherMailDetails?.action?.assignee;

        if (editorHtml === undefined) {
            return toast.error("Text field may not be blank");
        }
        if (!showAssignError) {
            return toast.error("Please assign this email");
        }

        if (!!showAssignError) {
            openDispositionModal();
        }

        if (otherMailDetails?.action?.ticket === null && otherMailDetails?.action?.disposition) {
            const selectedEmailId = otherMailDetails?.id;
            let queryParam = {
                to: emails,
                html_content: editorHtml,
                bcc: bcc,
                cc: cc,
                attachments: files,
                reply_and_close: true,
                template_attachments: Array.isArray(templateAttachment)
                    ? templateAttachment.map((attachment) => attachment.id)
                    : [],
                is_replied: true,
            };

            const replyResponse = await replyMail({
                body: queryParam,
                selectedEmailId,
            });

            if (replyResponse && replyResponse.data) {
                toast.success("Email sent successfully");
                setCheckingForReplyApi(true);
                refetchSpecificThreads();
                closeModal();
            } else if ("data" in replyResponse?.error) {
                const errorData = replyResponse.error?.data as {
                    detail?: string[];
                };
                const errorMessage = errorData?.detail?.[0];

                toast.error(errorMessage);
            }
        }
        else if (otherMailDetails?.action?.ticket === null && !otherMailDetails?.action?.disposition) {
            setOpenNonValueDispositionModal(true)

        }

        // if (emails.length > 0) {
        //     const lastEmail = emails[emails.length - 1];
        //     setLookupEmail(lastEmail);
        // }
    };

    const handleSave = () => {
        closeDispositionModal();
        setLastSelectedDisposition(selectedTopics[selectedTopics.length - 1]);
        setTicketDetailsModal(true);
    };

    useEffect(() => {
        if (lookupEmailData) {
            let queryParam = {
                contact: lookupEmailData.id,
                original_subject: subject,
                html_content: editorHtml,
                to: emails,
                bcc: bcc,
                cc: cc,
                attachments: files,
                disposition: null,
                status: null,
                template_attachments: templateAttachment.map(
                    (attachment) => attachment.id
                ),
            };
            try {
                const response = thredsEmail(queryParam);
                if (response) {
                    toast.success("Email Send successfully");
                    setBcc([]);
                    setEmails([]);
                    setCc([]);
                    setEditorHtml("");
                    setSubject("");
                    setTemplateAttachment([]);
                } else {
                    toast.error("An error occurred");
                }
            } catch (error) {
                toast.error("Unable to edit");
            }
        }
    }, [lookupEmailData]);

    const handleDeleteFile = (fileToDelete) => () => {
        setFiles((prevFiles) =>
            prevFiles.filter((file) => file !== fileToDelete)
        );
    };

    const handleDownloadFile = (fileId) => {
        // Implement the download logic here
        // For example, using an API call to get the file or redirect to the file URL
        window.open(`${fileId}`, "_blank");
    };
    const handleDeleteAttachmentFile = (fileToDeleteId) => async () => {
        // Directly update the local state after the API call
        setTemplateAttachment((prev) => {
            // Filter out the attachment with the matching fileToDeleteId
            const updatedAttachments = prev.filter(
                (file) => String(file.id) !== String(fileToDeleteId)
            );

            return updatedAttachments;
        });
    };

    useEffect(() => {
        setCcInputValue("");
        setInputValue("");
        setBccInputValue("");
        setEditorHtml("");
    }, [checkingForReplyApi]);

    const closeStatusModal = "";
    const ticketId = "";
    const lastSelectedId = "";
    const ticketName = "";
    const dynamicData = "";
    const fetchDispositionStatus = "";

    const value = "nonDispositionValue"



    console.log(storedMailHistory, "storedMailHistory")
    return (
        <>
            {liftingReplyLoader ? (
                <LoadingSpinner />
            ) : (
                <div id="kt_inbox_reply_form" className="rounded border mt-10">
                    <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between py-3">
                            <h2 className="card-title m-0">Message</h2>
                            <IconButton
                                style={{ color: "inherit" }}
                                onClick={() => setShowReplyCard(false)}
                            >
                                X
                            </IconButton>
                        </div>
                        <div className="card-body p-0">
                            <div id="kt_inbox_compose_form">
                                <div className="d-block">
                                    <div className="d-flex align-items-center border-bottom px-8 min-h-50px">
                                        <div className="text-dark fw-bold w-50px">
                                            To:
                                        </div>
                                        <div style={{ width: "100%" }}>
                                            <Autocomplete
                                                freeSolo
                                                options={emailOptions}
                                                getOptionLabel={(option: any) =>
                                                    option.label
                                                }
                                                onChange={(event, value) => {
                                                    if (
                                                        value &&
                                                        isValidEmail(
                                                            value.label
                                                        )
                                                    ) {
                                                        handleAddEmail(
                                                            value.label,
                                                            setEmails,
                                                            emails
                                                        );
                                                        setInputValue(""); // Clear the input
                                                    }
                                                }}
                                                inputValue={inputValue}
                                                onInputChange={(
                                                    event,
                                                    newInputValue
                                                ) =>
                                                    handleInputChange(
                                                        event,
                                                        newInputValue,
                                                        setInputValue
                                                    )
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        sx={{ border: "none" }} // Remove border
                                                        fullWidth
                                                        onKeyDown={(e) => {
                                                            if (
                                                                e.key ===
                                                                "Enter"
                                                            ) {
                                                                handleAddEmail(
                                                                    inputValue,
                                                                    setEmails,
                                                                    emails
                                                                );
                                                                setInputValue(
                                                                    ""
                                                                ); // Clear the input
                                                                e.preventDefault(); // Prevent form submission or default behavior
                                                            }
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startAdornment: (
                                                                <Stack
                                                                    direction="row"
                                                                    spacing={1}
                                                                    alignItems="center"
                                                                    flexWrap="wrap"
                                                                >
                                                                    {emails?.map(
                                                                        (
                                                                            email,
                                                                            index
                                                                        ) => (
                                                                            <Chip
                                                                                key={
                                                                                    index
                                                                                }
                                                                                label={
                                                                                    email
                                                                                }
                                                                                onDelete={() =>
                                                                                    handleDeleteEmail(
                                                                                        email,
                                                                                        setEmails,
                                                                                        emails
                                                                                    )
                                                                                }
                                                                                className="mat-chip-custom"
                                                                                style={{
                                                                                    marginLeft: 5,
                                                                                }}
                                                                            />
                                                                        )
                                                                    )}
                                                                </Stack>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="w-75px text-end">
                                            <span
                                                className="text-muted fs-bold cursor-pointer text-hover-primary me-2"
                                                onClick={toggleCCField}
                                            >
                                                Cc
                                            </span>
                                            <span
                                                className="text-muted fs-bold cursor-pointer text-hover-primary"
                                                onClick={toggleBccField}
                                            >
                                                Bcc
                                            </span>
                                        </div>
                                    </div>
                                    {showCCField && (
                                        <div className="d-flex align-items-center border-bottom px-8 min-h-50px">
                                            <div className="text-dark fw-bold w-50px">
                                                Cc:
                                            </div>
                                            <div style={{ width: "100%" }}>
                                                <Autocomplete
                                                    freeSolo
                                                    options={emailOptions}
                                                    getOptionLabel={(
                                                        option: any
                                                    ) => option.label}
                                                    onChange={(
                                                        event,
                                                        value
                                                    ) => {
                                                        if (
                                                            value &&
                                                            isValidEmail(
                                                                value.label
                                                            )
                                                        ) {
                                                            handleAddEmail(
                                                                value.label,
                                                                setCc,
                                                                cc
                                                            );
                                                            setCcInputValue(""); // Clear the input
                                                        }
                                                    }}
                                                    inputValue={ccInputValue}
                                                    onInputChange={(
                                                        event,
                                                        newInputValue
                                                    ) =>
                                                        handleInputChange(
                                                            event,
                                                            newInputValue,
                                                            setCcInputValue
                                                        )
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            size="small"
                                                            fullWidth
                                                            onKeyDown={(e) => {
                                                                if (
                                                                    e.key ===
                                                                    "Enter"
                                                                ) {
                                                                    handleAddEmail(
                                                                        ccInputValue,
                                                                        setCc,
                                                                        cc
                                                                    );
                                                                    setCcInputValue(
                                                                        ""
                                                                    );
                                                                    e.preventDefault(); // Prevent form submission or default behavior
                                                                }
                                                            }}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                startAdornment:
                                                                    (
                                                                        <Stack
                                                                            direction="row"
                                                                            spacing={
                                                                                1
                                                                            }
                                                                            alignItems="center"
                                                                            flexWrap="wrap"
                                                                        >
                                                                            {cc.map(
                                                                                (
                                                                                    email,
                                                                                    index
                                                                                ) => (
                                                                                    <Chip
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                        label={
                                                                                            email
                                                                                        }
                                                                                        onDelete={() =>
                                                                                            handleDeleteEmail(
                                                                                                email,
                                                                                                setCc,
                                                                                                cc
                                                                                            )
                                                                                        }
                                                                                        className="mat-chip-custom"
                                                                                        style={{
                                                                                            marginLeft: 5,
                                                                                        }}
                                                                                    />
                                                                                )
                                                                            )}
                                                                        </Stack>
                                                                    ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="ms-auto w-75px text-end">
                                                <span
                                                    className="fs-bold cursor-pointer"
                                                    onClick={toggleCCField}
                                                >
                                                    Hide Cc
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {showBccField && (
                                        <div className="d-flex align-items-center border-bottom px-8 min-h-50px">
                                            <div className="text-dark fw-bold w-50px">
                                                Bcc:
                                            </div>
                                            <div style={{ width: "100%" }}>
                                                <Autocomplete
                                                    freeSolo
                                                    options={emailOptions}
                                                    getOptionLabel={(
                                                        option: any
                                                    ) => option.label}
                                                    onChange={(
                                                        event,
                                                        value
                                                    ) => {
                                                        if (
                                                            value &&
                                                            isValidEmail(
                                                                value.label
                                                            )
                                                        ) {
                                                            handleAddEmail(
                                                                value.label,
                                                                setBcc,
                                                                bcc
                                                            );
                                                            setBccInputValue(
                                                                ""
                                                            ); // Clear the input
                                                        }
                                                    }}
                                                    inputValue={bccInputValue}
                                                    onInputChange={(
                                                        event,
                                                        newInputValue
                                                    ) =>
                                                        handleInputChange(
                                                            event,
                                                            newInputValue,
                                                            setBccInputValue
                                                        )
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            size="small"
                                                            fullWidth
                                                            onKeyDown={(e) => {
                                                                if (
                                                                    e.key ===
                                                                    "Enter"
                                                                ) {
                                                                    handleAddEmail(
                                                                        bccInputValue,
                                                                        setBcc,
                                                                        bcc
                                                                    );
                                                                    setBccInputValue(
                                                                        ""
                                                                    ); // Clear the input
                                                                    e.preventDefault(); // Prevent form submission or default behavior
                                                                }
                                                            }}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                startAdornment:
                                                                    (
                                                                        <Stack
                                                                            direction="row"
                                                                            spacing={
                                                                                1
                                                                            }
                                                                            alignItems="center"
                                                                            flexWrap="wrap"
                                                                        >
                                                                            {bcc.map(
                                                                                (
                                                                                    email,
                                                                                    index
                                                                                ) => (
                                                                                    <Chip
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                        label={
                                                                                            email
                                                                                        }
                                                                                        onDelete={() =>
                                                                                            handleDeleteEmail(
                                                                                                email,
                                                                                                setBcc,
                                                                                                bcc
                                                                                            )
                                                                                        }
                                                                                        className="mat-chip-custom"
                                                                                        style={{
                                                                                            marginLeft: 5,
                                                                                        }}
                                                                                    />
                                                                                )
                                                                            )}
                                                                        </Stack>
                                                                    ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="ms-auto w-75px text-end">
                                                <span
                                                    className="fs-bold cursor-pointer"
                                                    onClick={toggleBccField}
                                                >
                                                    Hide Bcc
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* begin::Message */}
                                <div className="card-body p-5">
                                    <ReactQuill
                                        theme="snow"
                                        value={editorHtml}
                                        onChange={handleEditorChange}
                                        modules={modules}
                                        formats={formats}
                                        style={{ height: "200px" }}
                                    />
                                </div>
                                <div
                                    className="p-5"
                                    style={{ marginTop: "70px" }}
                                >
                                    {files.length > 0 && (
                                        <>
                                            {files.map((file, index) => (
                                                <Chip
                                                    key={index}
                                                    label={file.file_name}
                                                    onDelete={handleDeleteFile(
                                                        file
                                                    )}
                                                />
                                            ))}
                                        </>
                                    )}

                                    {templateAttachment?.length > 0 && (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                gap: 1,
                                                mt: 2,
                                            }}
                                        >
                                            {templateAttachment?.map(
                                                (file, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={file.file_name}
                                                        onClick={() =>
                                                            handleDownloadFile(
                                                                file.file
                                                            )
                                                        }
                                                        onDelete={handleDeleteAttachmentFile(
                                                            file.id
                                                        )}
                                                        sx={{
                                                            cursor: "pointer",
                                                        }} // Adds a pointer cursor on hover
                                                    />
                                                )
                                            )}
                                        </Box>
                                    )}
                                </div>

                                {/* end::Message */}
                                {/* begin::Footer */}
                                <div className="d-flex flex-stack flex-wrap gap-2 py-5 ps-8 pe-5 border-top mt-1">
                                    <div className="d-flex align-items-center me-3">
                                        <div className="btn-group me-4">
                                            <button
                                                className="btn btn-primary fs-bold px-6"
                                                data-kt-inbox-form="send"
                                                onClick={handleSubmit}
                                            >
                                                <span className="indicator-label">
                                                    {replyStatus ===
                                                        "pending" ? (
                                                        <>
                                                            Sending...{" "}
                                                            <Loader />
                                                        </>
                                                    ) : (
                                                        "Send"
                                                    )}
                                                </span>
                                                <span className="indicator-progress">
                                                    Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                                </span>
                                            </button>
                                        </div>
                                        <label
                                            htmlFor="attachFileInput"
                                            className="btn btn-active-light-primary btn-icon"
                                            title="Attach"
                                        >
                                            <i className="bi bi-paperclip fs-2"></i>
                                            <input
                                                type="file"
                                                id="attachFileInput"
                                                className="d-none"
                                                multiple
                                                onChange={handleFileChange}
                                            />
                                        </label>
                                        <div
                                            className="btn btn-active-light-primary btn-icon fs-bold px-6"
                                            onClick={openModal}
                                        >
                                            <i className="fas fa-mail-bulk fs-3"></i>
                                        </div>
                                        <ReactModal
                                            isOpen={isModalOpen}
                                            onAfterOpen={afterOpenModal}
                                            onRequestClose={closeModal}
                                            style={customModalStyles}
                                            contentLabel="Email templates"
                                        >
                                            <EmailTemplateModal
                                                closeModal={closeModal}
                                                setSelectedTemplateDescription={
                                                    setSelectedTemplateDescription
                                                }
                                                storeTemplateData={
                                                    storeTemplateData
                                                }
                                                setTemplateAttachment={
                                                    setTemplateAttachment
                                                }
                                            />
                                        </ReactModal>

                                        <ReactModal
                                            isOpen={
                                                isModalOpenContactDetailsFill
                                            }
                                            onAfterOpen={
                                                afterOpenModalContactDetailsFill
                                            }
                                            onRequestClose={
                                                closeModalContactDetailsFill
                                            }
                                            style={customModalStyles1}
                                            contentLabel="Email templates"
                                        >
                                            <ContactDetailsFill
                                                closeModalContactDetailsFill={
                                                    closeModalContactDetailsFill
                                                }
                                                emailNotPresent={
                                                    emailNotPresent
                                                }
                                            />
                                        </ReactModal>
                                    </div>
                                </div>
                                {/* end::Footer */}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <ReactModal
                isOpen={isAddNewContactModalOpen}
                onRequestClose={() => setAddNewContactModalOpen(false)}
                style={customModalStyles}
                contentLabel="New Contact"
            >
                <AddNewTicketContactModal
                    closeModal={() => setAddNewContactModalOpen(false)}
                    emailNotPresent={emailNotPresent}
                    setSelectedContact={setSelectedContact}
                    storeNewEmailId={storeNewEmailId}
                    setEmailNotPresent={setEmailNotPresent}
                />
            </ReactModal>

            {ticketDetailsModal && (
                <TicketsDetailsModal
                    closeModal={() => setTicketDetailsModal(false)}
                    lastSelectedDisposition={lastSelectedDisposition}
                    isOpen={ticketDetailsModal}
                    emails={emails}
                    cc={cc}
                    bcc={bcc}
                    editorHtml={editorHtml}
                    otherMailDetails={otherMailDetails}
                    refetchSpecificThreads={refetchSpecificThreads}
                    setShowReplyCard={setShowReplyCard}
                    files={files}
                    templateAttachment={templateAttachment}
                />
            )}

            <ReactModal
                isOpen={openNonValueDispositionModal}
                onRequestClose={closeNonDispositionValueModal}
                style={customModalStyles}
                contentLabel="New Disposition"
            >
                <DispositionTicketModal
                    isLoading={loading}
                    trigger={trigger}
                    closeModal={closeNonDispositionValueModal}
                    storedDisposition={storedMailHistory}
                    refetch={refetchSpecificThreads}
                    ticketId={storedTicketId}
                    value={value}
                />
            </ReactModal>



            {otherMailDetails?.action?.ticket === null ? (
                <></>
            ) : (
                <ReactModal
                    isOpen={dispositionModal}
                    onAfterOpen={afterOpenDispositionModal}
                    onRequestClose={closeDispositionModal}
                    style={customModalStyles2}
                    contentLabel="Disposition"
                >
                    <DispositionModal
                        isLoading={loading}
                        trigger={trigger}
                        closeModal={closeDispositionModal}
                        storedDisposition={storedMailHistory}
                        refetch={refetchSpecificThreads}
                        ticketId={storedTicketId || uuid}
                        otherMailDetails={otherMailDetails}
                        emails={emails}
                        editorHtml={editorHtml}
                        cc={cc}
                        bcc={bcc}
                        templateAttachment={templateAttachment}
                        files={files}
                        setCheckingForReplyApi={setCheckingForReplyApi}
                        setLiftingReplyLoader={setLiftingReplyLoader}
                    />
                </ReactModal>
            )}
        </>
    );
};

export default ReplyCard;
