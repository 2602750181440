import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const campaignDetailApi = createApi({
  reducerPath: "campaignDetailApi",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    updateCamapign: builder.mutation({
      query: (body) => {
        return {
          url: `v1/common/lead/${body.id}/`,
          method: "PATCH",
          body,
        };
      },
    }),
    detailCamapign: builder.query({
      query: (uuid) => {
        return {
          url: `v1/common/lead/${uuid}`,
          method: "GET",
        };
      },
    }),
    updateCamapignDynamicFields: builder.mutation({
      query: (uuid) => {
        return {
          url: `v1/common/lead/${uuid.uuid}`,
          method: "PATCH",
          body: uuid.variables,
        };
      },
    }),
    InternalNotesCampaign: builder.mutation({
      query: (body) => ({
        url: "v1/common/lead-internal-note/",
        method: "POST",
        body,
      }),
    }),
    updateCamapignUsername: builder.mutation({
      query: (body) => {
        return {
          url: `v1/common/lead/${body.id}/`,
          method: "PATCH",
          body,
        };
      },
    }),

    createCampaignTask: builder.mutation({
      query: (body) =>  ({
        url: "v1/common/lead-event/",
        method: "POST",
        body

      })
    }),
    AddAttachments: builder.mutation({
      query: (uuid) => {
        return {
          url: `v1/common/lead/${uuid.uuid}`,
          method: "PATCH",
          body: uuid.variables,
        };
      },
    }),
    deleteAttachment: builder.mutation({
      query: ({ body }) => {
        return {
          url: `v1/common/lead/delete/`,
          method: "DELETE",
          body,
        };
      },
    }),
  }),
});

export const {
  useCreateCampaignTaskMutation,
useUpdateCamapignMutation,
useDetailCamapignQuery,
useUpdateCamapignDynamicFieldsMutation,
useInternalNotesCampaignMutation,
useUpdateCamapignUsernameMutation,
useAddAttachmentsMutation,
useDeleteAttachmentMutation
} = campaignDetailApi;
