import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";
import { useAddAccountMutation } from "../customer/FrontendCustomer";
import { useSaveDespositionMutation } from "../general/General";
import { add, update } from "lodash";

export const CampaignMapping = createApi({
  reducerPath: "TicketMapping",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    campaignTeams: builder.query({
      query: () => {
        return {
          url: `v1/users/teams/`,
          method: "GET",
        };
      },
    }),

    campaignTeamsData: builder.query({
      query: (selectedTeam) => {
        return {
          url: `v1/common/campaigns/?team=${selectedTeam}&`,
          method: "GET",
        };
      },
    }),

    campaignWorflowName: builder.query({
      query: (selectedTeam) => {
        return {
          url: `v1/common/campaign-name/?team=${selectedTeam}&`,
          method: "GET",
        };
      },
    }),

    workflowAssign: builder.mutation({
      query: ({ body, disposition }) => ({
        url: `v1/common/campaigns/${disposition}/`,
        method: "PUT",
        body,
      }),
    }),

    campaignData: builder.query({
      query: ({ currentPage }) => {
        return {
          url: `v1/common/campaigns/?page=${currentPage}`,
          method: "GET",
        };
      },
    }),

    allCampaignData: builder.query({
      query: ({ currentPage }) => {
        return {
          url: `v1/common/campaigns/`,
          method: "GET",
        };
      },
    }),

    AddNewCampaign: builder.mutation({
      query: ({ body }) => ({
        url: `v1/common/campaigns/`,
        method: "POST",
        body,
      }),
    }),

    updateCampaign: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `v1/common/campaigns/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    deleteCampaign: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `v1/common/campaigns/${id}`,
          method: "DELETE",
          body,
        };
      },
    }),

    dynamicFieldsCampaign: builder.query({
      query: () => {
        return {
          url: `v1/common/dynamic-field-definitions/?model_name=campaign&`,
          method: "GET",
        };
      },
    }),

    createDynamicField: builder.mutation({
      query: () => {
        return {
          url: `v1/common/dynamic-field-definitions/`,
          method: "POST",
        };
      },
    }),

    campaignConfiguration: builder.query({
      query: (selectedCampaign) => {
        console.log(selectedCampaign, "selectedCampaign");
        return {
          url: `v1/campaign/campaign-configuration/?campaign=${selectedCampaign}&`,
          method: "GET",
        };
      },
    }),
    createCampaignConfiguration: builder.mutation({
      query: (body) => {
        return {
          url: `v1/campaign/campaign-configuration/`,
          method: "POST",
          body,
        };
      },
    }),

    deleteCampaignConfiguration: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `v1/campaign/campaign-configuration/${id}/`,
          method: "DELETE",
          body,
        };
      },
    }),

    updateCampaignConfiguration: builder.mutation({
      query: ({ body, id }) => {
        console.log(id, "id");
        return {
          url: `v1/campaign/campaign-configuration/${id}/`,
          method: "PATCH",
          body,
        };
      },
    }),

    saveLeadUpload: builder.mutation({
      query: (body) => {
        return {
          url: `v1/common/lead/`,
          method: "POST",
          body,
        };
      },
    }),

    bulkUploadCampaign: builder.mutation({
      query: ({body}) => {
        return {
          url: `v1/common/lead/bulk-upload/`,
          method: "POST",
          body,
        };
      },
    }),

    campaignWorkflowDetails: builder.query({
      query: ({ selectedTeam, selectedCampaign }) => {

        return {
          url: `v1/common/campaign-name/?team=${selectedTeam}&campaign=${selectedCampaign}&`,
          method: "GET",
        };
      },
    }),

    getTelephonyPri: builder.query({
      query: () => {
        return {
          url: `v1/telephony/telephony-pri/`,
          method: "GET",
        };
      },
    }),

    createWorkflowCamapaign: builder.mutation({
      query: (body) => {
        return {
          url: `v1/common/campaign-name/`,
          method: "POST",
          body,
        };
      },
    }),


    deleteCampaignWorkFlow: builder.mutation({
      query: (id) => ({
        url: `v1/common/campaign-name/${id}/`,
        method: "DELETE",
      }),
    }),

    updateCampaignWorkFlow: builder.mutation({
      query: (body) => {
        return {
          url: `v1/common/campaign-name/${body.id}/`,
          method: "PUT",
          body,
        };
      },
    }),

    deleteWorkflowStatus: builder.mutation({
      query: (id) => ({
        url: `v1/common/campaign-progress-status/${id}/`,
        method: "DELETE",
      }),
    }),

    editWipWorkFlow: builder.mutation({
      query: ({ body}) => ({
        url: `v1/common/campaign-progress-status/${body.campaign_name}`,
        method: "PUT",
        body,
      }),
    }),



    addNewWipWorkFlow: builder.mutation({
      query: ({body}) => {
        return {
          url: `v1/common/campaign-progress-status/`,
          method: "POST",
          body,
        };
      },
    }),



  }),
});
export const {
  useCampaignTeamsQuery,
  useCampaignTeamsDataQuery,
  useCampaignWorflowNameQuery,
  useWorkflowAssignMutation,
  useCampaignDataQuery,
  useAllCampaignDataQuery,
  useAddNewCampaignMutation,
  useUpdateCampaignMutation,
  useDeleteCampaignMutation,
  useDynamicFieldsCampaignQuery,
  useCreateDynamicFieldMutation,
  useCampaignConfigurationQuery,
  useCreateCampaignConfigurationMutation,
  useDeleteCampaignConfigurationMutation,
  useUpdateCampaignConfigurationMutation,
  useSaveLeadUploadMutation,
  useBulkUploadCampaignMutation,
  useCampaignWorkflowDetailsQuery,
  useGetTelephonyPriQuery,
  useCreateWorkflowCamapaignMutation,
  useDeleteCampaignWorkFlowMutation,
  useUpdateCampaignWorkFlowMutation,
  useDeleteWorkflowStatusMutation,
  useEditWipWorkFlowMutation,
  useAddNewWipWorkFlowMutation
} = CampaignMapping;
