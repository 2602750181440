import React, { useEffect, useState } from "react";
import "./clientStyles.scss";
import ClientLoginHeader from "./ClientLoginHeader";
import Charts from "./Charts";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../helpers";
import { useLayout } from "../../layout/core";
import ClientListView from "./ClientListView";
import TicketModal from "../../components/ticket/TicketModal";
import ClientTicketModal from "./ClientTicketModal";
import Footer from "../../sections/Footer";
import ReactModal from "react-modal";

const ClientLogin = () => {
  const [liftindTicketData, setLiftingTicketData] = useState();
  const [refetchingData, setRefetchingData] = useState([]);
  const [liftingRefetchWorkflow, setLiftingRefetchWorkFlow] = useState([]);
  const [liftingSelectWorkflow, setLiftingSelectWorkFlow] = useState("");

  const { config, classes, attributes } = useLayout();

  const [currentPage, setCurrentPage] = useState(1);

  const [isTicketModalOpen, setIsTicketModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const openTicketModal = () => {
    setIsTicketModalOpen(true);
  };

  const closeTicketModal = () => {
    setIsTicketModalOpen(false);
  };

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100%", // Default to full width
      maxWidth: "850px", // Max width for larger screens
      height: "80%",
      margin: "auto",
      Overflow: "hidden",
      OverflowY: "auto",
    },
  };

  return (
    <>
      <div
        className="header align-items-stretch justify-content-between d-none d-lg-flex"
        style={{ left: 0 }}
        {...attributes.headerMenu}
      >
        {/* begin::Wrapper */}
        <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
          {/* begin::Navbar */}
          <div className="app-header-menu app-header-mobile-drawer align-items-stretch ms-2">
            <Link to="/client-login">
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/logos/CircleOne-Logo-With-Change.png"
                )}
                className="h-60px"
              />
            </Link>
          </div>
        </div>
      </div>
      <ClientLoginHeader />

      <div
        style={{
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
        className="except-header"
      >
        <Charts
          openTicketModal={openTicketModal}
          setLiftingTicketData={setLiftingTicketData}
          currentPage={currentPage}
          setRefetchingData={setRefetchingData}
        />
        <ClientListView
          setLiftingTicketData={setLiftingTicketData}
          ticket={liftindTicketData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setLiftingRefetchWorkFlow={setLiftingRefetchWorkFlow}
          setRefetchingData={setRefetchingData}
        />
        <Footer />
      </div>

      <ClientTicketModal />

      <ReactModal
        isOpen={isTicketModalOpen}
        onRequestClose={closeTicketModal}
        style={customModalStyles}
        contentLabel="New Ticket"
      >
        <TicketModal
          closeModal={closeTicketModal}
          setIsLoading={setIsLoading}
          refetch={refetchingData}
          selectedWorkflow={liftingSelectWorkflow}
          refetchWorkflow={liftingRefetchWorkflow}
        />
      </ReactModal>
    </>
  );
};

export default ClientLogin;
