import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const ticketDetailApi = createApi({
  reducerPath: "ticketDetail",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    detailTicket: builder.query({
      query: (uuid) => {
        return {
          url: `v1/tickets/ticket/${uuid}`,
          method: "GET",
        };
      },
    }),
    updateUsername: builder.mutation({
      query: (body) => {
        return {
          url: `v1/tickets/ticket/${body.id}/`,
          method: "PATCH",
          body,
        };
      },
    }),
    updateDynamicFields: builder.mutation({
      query: (uuid) => {
        return {
          url: `v1/tickets/ticket/${uuid.uuid}`,
          method: "PATCH",
          body: uuid.variables,
        };
      },
    }),
  }),
});

export const {
  useDetailTicketQuery,
  useUpdateUsernameMutation,
  useUpdateDynamicFieldsMutation,
} = ticketDetailApi;
