import React from 'react'
import { Route, Routes } from 'react-router-dom'
import TicketRoute from './ticket/TicketRoute'
import TelephonyRoute from './Telephony/TelephonyRoute'
import EmailRoute from './email/EmailRoute'
import ChatRoute from './chat/ChatRoute'
import CampaignRoute from './Campaign/CampaignRoute'


const Index = () => {
    return (
        <Routes>

            <Route
                path='/email/*'
                element={
                    <>
                        <EmailRoute />

                    </>
                }
            />
            <Route
                path='/ticket/*'
                element={
                    <>

                        <TicketRoute />
                    </>

                }
            />

            <Route
                path='/telephony/*'
                element={
                    <>
                        <TelephonyRoute />
                    </>

                }
            />
            <Route
                path='/campaign/*'
                element={
                    <>
                        <CampaignRoute />
                    </>
                }
            />
            <Route
                path='/chat/*'
                element={
                    <>
                        <ChatRoute />

                    </>
                }
            />



        </Routes >
    )
}

export default Index
