export const createInitials = (name: string) => {
    let initials = { label: '', state: 'danger' }
    const states = ['warning', 'danger', 'primary', 'success', 'info']
    const random = Math.floor(Math.random() * states.length)
    initials.state = states[random] // Assign random colour
    if (name.length > 0) {
      initials.label = name
        .split(' ')
        .map((substr: string, index: number) => {
          if (index < 2 && substr.length > 0) {
            return substr[0].toLocaleUpperCase()
          } else {
            return null
          }
        })
        .join('')
    }
    return initials
  }
