import React, { useRef, useState } from "react";
import Modal from "react-modal";
import TaskEditModal from "./TaskEditModal";
import DeleteTaskModal from "./DeleteTaskModal";

Modal.setAppElement("#root");

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%", // Default to full width
    maxWidth: "550px", // Max width for larger screens
    margin: "auto",
    Overflow: "hidden",
    OverflowY: "auto",
  },
};

const TaskActionMenu = ({ task, index ,refetchTask}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const menuRefs = useRef([]);

  const toggleMenu = (index) => {
    const menu = menuRefs.current[index];
    if (menu) {
      menu.style.display = menu.style.display === "block" ? "none" : "block";
    }
  };

  const openEditModal = () => {
    setIsEditModalOpen(true);
    toggleMenu(false);
  };

  const afterOpenEditModal = () => {
    // subtitle.style.color = '#f00'
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
    toggleMenu(false);
  };

  const afterOpenDeleteModal = () => {
    // subtitle.style.color = '#f00'
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <div className="position-relative">
      <button
        onClick={() => toggleMenu(index)}
        className="btn btn-sm btn-icon btn-active-light-primary"
      >
        <i className="bi bi-three-dots-vertical fs-3"></i>
      </button>
      <div
        ref={(el) => (menuRefs.current[index] = el)}
        className="dropdown-menu show"
        style={{ display: "none" }}
      >
        <button
          onClick={() => {
            toggleMenu(index);
            openEditModal();
          }}
          className="dropdown-item"
        >
          Edit
        </button>
        <Modal
          isOpen={isEditModalOpen}
          onAfterOpen={afterOpenEditModal}
          onRequestClose={closeEditModal}
          style={customModalStyles}
          contentLabel="Edit User"
        >
          <TaskEditModal task={task} closeEditModal={closeEditModal}refetchTask={refetchTask} />
        </Modal>
        <button
          onClick={() => {
            toggleMenu(index);
            openDeleteModal();
          }}
          className="dropdown-item"
        >
          Delete
        </button>

        <Modal
          isOpen={isDeleteModalOpen}
          onAfterOpen={afterOpenDeleteModal}
          onRequestClose={closeDeleteModal}
          style={customModalStyles}
          contentLabel="delete User"
        >
          <DeleteTaskModal closeDeleteModal={closeDeleteModal} />
        </Modal>
      </div>
    </div>
  );
};

export default TaskActionMenu;
