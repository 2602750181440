import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingSpinner from "../../LoadingSpinner";
import { toAbsoluteUrl } from "../../../helpers";
import { Loader } from "react-bootstrap-typeahead";

const VerificationStatusEnum = {
  pending: "pending",
  success: "success",
  failure: "failure",
};

const UserVerifiedPage = () => {
  const [verificationStatus, setVerificationStatus] = useState(
    VerificationStatusEnum.pending
  );

  const navigate = useNavigate();
  const apiPort = process.env.REACT_APP_API_PROTOCOL;

  const [username, setUsername] = useState(null);
  const [verificationToken, setVerificationToken] = useState<any>(null);
  const [verificationFailedMessage, setVerificationFailedMessage] =
    useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const extractedUsername = params.get("username");
    const extractedVerificationToken = params.get("verification_token");

    setUsername(extractedUsername);
    setVerificationToken(extractedVerificationToken);
  }, []);

  useEffect(() => {
    if (verificationToken !== null && username !== null) {
      axios
        .get(
          `${apiPort}://${process.env.REACT_APP_BASE_URL}/v1/signup-verification`,
          {
            params: {
              username,
              verification_token: verificationToken,
            },
          }
        )
        .then((res) => {
          setVerificationStatus(VerificationStatusEnum.success);
          window.location.href = res?.data.redirect_url;
        })
        .catch((err) => {
          setVerificationStatus(VerificationStatusEnum.failure);

          const timeoutId = setTimeout(() => {
            setVerificationFailedMessage(true);
          }, 30000);
          return () => clearTimeout(timeoutId);
        });
    }

  }, [username, verificationToken]);

  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          "/media/illustrations/dozzy-1/14.png"
        )})`,

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <a href="#" className="mb-12 position-absolute top-0 start-0">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/CircleOne.png")}
            className="h-100px"
          />
        </a>
        <div className="centered">
          <div className="auth-dialog-box-body form-width">
            {verificationStatus === VerificationStatusEnum.pending && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <h1>Wait till the sign-up is completely verified!</h1>
                <h3>Thank you for your patience.</h3>
              </div>
            )}
            {verificationStatus === VerificationStatusEnum.success && (
              <h1>Verification is completed!</h1>
            )}

            {verificationStatus === VerificationStatusEnum.failure &&
              !verificationFailedMessage && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <h1>Error in Verification!</h1>
                  <h4>
                    Re-initiating verification... <Loader />
                  </h4>
                </div>
              )}
            {verificationStatus === VerificationStatusEnum.failure &&
              verificationFailedMessage && <h1>Verification Failed</h1>}
          </div>

          {verificationStatus === VerificationStatusEnum.pending && (
            <div className="loading-overlay">
              <div className="spinner">
                <LoadingSpinner />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="d-flex flex-center flex-column-auto p-10">
        <div className="d-flex align-items-center fw-bold fs-6">
          <a href="#" className="text-muted text-hover-primary px-2">
            About
          </a>
          <a href="#" className="text-muted text-hover-primary px-2">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default UserVerifiedPage;
