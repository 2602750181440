// import React, { useEffect, useState } from "react";

// interface IPROPS {
//   label: string;
//   setLiftingColumn: (columns: string[]) => void;
// }

// const SortingTableContact: React.FC<IPROPS> = ({ label, setLiftingColumn }) => {
//   // Store columns and their sorting order in a map
//   const [storedColumns, setStoredColumns] = useState<Record<string, boolean>>({
//     name: false, // `false` represents descending (e.g., `-name`), `true` is ascending.
//   });

//   const switchArrows = (id: string) => {
//     setStoredColumns((prevColumns) => {
//       const newColumns = { ...prevColumns };

//       // Toggle sorting direction if column already exists
//       if (id in newColumns) {
//         newColumns[id] = !newColumns[id];
//       } else {
//         // Add new column, default it to ascending sort
//         newColumns[id] = true;
//       }

//       return newColumns;
//     });
//   };

//   useEffect(() => {
//     // Convert the storedColumns object into an array of sorted columns with directions
//     const sortingColumns = Object.keys(storedColumns).map(
//       (key) => `${storedColumns[key] ? "" : "-"}${key}`
//     );

//     setLiftingColumn(sortingColumns); // Pass the sorting columns back to parent
//   }, [storedColumns, setLiftingColumn]);

//   return (
//     <div>
//       <i
//         style={{ color: "black", fontSize: "14px", cursor: "pointer" }}
//         className={`bi bi-arrow${storedColumns[label] ? "-up" : "-down"}`}
//         onClick={() => switchArrows(label)}
//         data-placement="top"
//         title={
//           storedColumns[label]
//             ? "Sort to descending"
//             : "Sort to ascending"
//         }
//       ></i>
//     </div>
//   );
// };

// export default SortingTableContact;



import React, { useEffect, useState } from "react";

interface IPROPS {
  label: any;
  setLiftingColumn: any;
}

const SortingTableContact: React.FC<IPROPS> = ({
  label,
  setLiftingColumn,
}) => {
  const [storedLabel, setStoredLabel] = useState("name");
  const [tableSorted, setTableSorted] = useState(true);


  const switchArrows = (id: string) => {
    if (storedLabel === id) {
      setTableSorted(!tableSorted);
    } else {
      setStoredLabel(id);
      setTableSorted(true);
    }
  };

  useEffect(() => {
    setLiftingColumn(`${tableSorted ? "" : "-"}${storedLabel}`);
  }, [storedLabel, tableSorted, setLiftingColumn]);

  return (
    <div>
      <i
        style={{ color: "black", fontSize: "14px", cursor: "pointer" }}
        className={`bi bi-arrow${tableSorted ? "-up" : "-down"}`}
        onClick={() => switchArrows(label)}
        data-placement="top"
        title={
          storedLabel === label
            ? tableSorted
              ? "Sort to descending"
              : "Sort to ascending"
            : "Sort"
        }
      ></i>
    </div>
  );
};

export default SortingTableContact;
