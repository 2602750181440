import React from "react";
import { useDeleteUserMutation } from "../../Services/user/Users";
import { toast } from "react-toastify";

interface IProps {
  closeDeleteModal: any;
  userData: any;
  refetch: any;
}

const DeleteUserModal: React.FC<IProps> = ({
  closeDeleteModal,
  userData,
  refetch,
}) => {
  const [deleteUser] = useDeleteUserMutation(userData.id);

  const handleDelete = async () => {
    try {
      await deleteUser(userData.id).unwrap();
      // Handle successful deletion (e.g., show a success message, refresh the user list, etc.)
      toast.success("User deleted successfully!"); // Show success toast
      closeDeleteModal();
      refetch();
    } catch (error) {
      // Handle error (e.g., show an error message)
      toast.error("Failed to delete user. Please try again."); // Show error toast
      console.error("Failed to delete user: ", error);
    }
  };

  return (
    <div className="card-body">
      <p className="fs-5 fw-bold">
        Are you sure you want to delete the following user
        <p className="fs-6"> {userData.username}?</p>
      </p>

      <div className="d-flex justify-content-end mt-10">
        <button
          type="reset"
          className="btn btn-sm btn-white btn-active-light-primary me-2"
          onClick={() => closeDeleteModal()}
        >
          Cancel
        </button>

        <button
          type="submit"
          className="btn btn-sm btn-danger"
          onClick={handleDelete}
        >
          <span className="indicator-label">Delete</span>
        </button>
      </div>
    </div>
  );
};

export default DeleteUserModal;
