import React, { useState } from "react";
import { useSaveDespositionMutation } from "../../../../Services/general/General";
import { toast } from "react-toastify";
import { Loader } from "react-bootstrap-typeahead";

interface IProps {
    closeModal: any;
    setData: any;
    data: any;
    currentNode: any;
    SetLiftingAddDate: any;
    selectedTeam: any;
    refetch: any;
}

const AddSubMenuModal: React.FC<IProps> = ({
    closeModal,
    setData,
    data,
    currentNode,
    SetLiftingAddDate,
    selectedTeam,
    refetch,
}) => {
    const [topic, setTopic] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [trigger, { isLoading }] = useSaveDespositionMutation();

    const handleTopicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTopic(event.target.value);
    };

    const removeLevelProperty = (node: any) => {
        const { level, ...rest } = node;
        if (rest.sub_topics && rest.sub_topics.length > 0) {
            rest.sub_topics = rest.sub_topics.map((subNode: any) =>
                removeLevelProperty(subNode)
            );
        }
        return rest;
    };

    const handleAdd = async () => {
        setIsSubmitting(true); // Disable the button and prevent multiple submissions

        const addSubTopic = (node: any, targetNode: any) => {
            let newNode = { ...node };

            if (newNode.topic === targetNode.topic) {
                if (!newNode.sub_topics) {
                    newNode.sub_topics = [];
                } else {
                    newNode.sub_topics = [...newNode.sub_topics];
                }
                newNode.sub_topics.push({ topic });
            } else if (newNode.sub_topics && newNode.sub_topics.length > 0) {
                newNode.sub_topics = newNode.sub_topics.map((subNode: any) =>
                    addSubTopic(subNode, targetNode)
                );
            }
            return newNode;
        };

        const updatedData = data.map((node: any) =>
            addSubTopic(node, currentNode)
        );
        const cleanedData = updatedData.map((node: any) =>
            removeLevelProperty(node)
        );


        try {
            const response = await trigger({
                body: cleanedData,
                id: selectedTeam,
            });

            if (response?.data) {
                toast.success("Added Successfully");
                refetch();
                // setData(cleanedData);
                closeModal();
            }
        } catch (error) {
            toast.error("Disposition not added");
            setIsSubmitting(false); // Re-enable the button in case of an error
        }
    };

    return (
        <div>
            <div className="mt-4">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Topic"
                    value={topic}
                    onChange={handleTopicChange}
                    disabled={isSubmitting}
                />
                <input type="hidden" value={currentNode?.topic} />
            </div>
            <div className="d-flex justify-content-end mt-4">
                <button
                    type="reset"
                    className="btn btn-sm btn-white text-hover-danger me-4"
                    onClick={closeModal}
                    disabled={isSubmitting}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={handleAdd}
                    disabled={isSubmitting || topic.trim().length < 1}
                >
                    {isLoading ? (
                        <>
                            Adding... <Loader />
                        </>
                    ) : (
                        "Add"
                    )}
                </button>
            </div>
        </div>
    );
};

export default AddSubMenuModal;
