import React, { useState, useEffect } from "react";
import { CloseButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTelephony } from "../context/TelephonyContext";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface IPROPS {
    inboundData: any;
    closeModal: any;
}

const CustomerInfo: React.FC<IPROPS> = ({ inboundData, closeModal }) => {
    const [gettingRouteValue, setGettingRouteValue] = useState("");

    const { setIsLoading } = useTelephony();

    const navigate = useNavigate();
    const routeArry = [
        { name: "Ticket", key: "ticket" },
        { name: "Email", key: "email" },
        { name: "Contact", key: "contact" },
    ];

    const routeHandler = (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value as string; // Cast value to string
        setGettingRouteValue(selectedValue); // Update state
    };

    console.log(gettingRouteValue, "dcdscsdcs")

    useEffect(() => {
        if (gettingRouteValue === "email") {
            setIsLoading(true);
            navigate("/email/inbox");
        closeModal(); 

            setIsLoading(false);
        } else if (gettingRouteValue === "ticket") {
            setIsLoading(true);
            navigate("/ticket/lists");
        closeModal(); 

            setIsLoading(false);
        } else if (gettingRouteValue === "contact") {
        closeModal(); 

            navigate("/contact/customer/contact");

        }
    }, [gettingRouteValue, navigate, setIsLoading]);

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            {/* <div style={{ display: "flex", width: "100%", borderBottom: "1px solid #e5e0e0"}}>
                <div style={{width: "60%", justifyContent: "end", display: "flex"}}>
                  <span style={{fontSize: "20px"}}>Customer details</span>
                </div>
                <div style={{flex: "1", justifyContent: "end", display: "flex"}}>
                    <CloseButton onClick={closeModal}/>
                </div>
            </div> */}

            {/* 1st div */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                }}
            >
                <div style={{ display: "flex", flexDirection: "column", padding: "0 50px" }}>
                     
                        <span style={{ display: "flex", justifyContent: "center",    fontSize: "16px", }}>
                        Queue
                    </span>
                    <span style={{ border: "1px solid #dfdddd", padding:"10px", maxWidth:"100%", display: "flex"}}>
                        {inboundData[0]?.queue_name}
                    </span>
                </div>
                <div
                    style={{
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    {/* <span style={{ width: "30%", fontSize: "16px" }}>Name</span> */}
                    <span style={{ flex: "1", display:"flex", justifyContent: "center" , fontSize: "15px"}}>
                        {inboundData[0]?.contacts[0]?.name}
                    </span>
                </div>
            </div>

            {/* 2nd div */}

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    background: "#ebeaea", 
                    padding:"20px"
                }}
            >
                <div style={{width: "100%", display: "flex"}}>
                    <span style={{display: "flex", width: "100%", justifyContent: "center"}}>
                         <i className="bi bi-person-circle" style={{fontSize: "50px"}}></i>
                        </span>
                         </div>

                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <span style={{ width: "40%", fontSize: "16px", display: "flex", justifyContent: "end" }}>
                        Caller
                    </span>
                    <span style={{ flex: "1", display: "flex", justifyContent: "flex-start", fontSize: "15px" }}>
                        +{inboundData[0]?.customer_phone}
                    </span>
                </div>
            </div>
            {/* <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                <span style={{ width: "30%", fontSize: "16px" }}>Queue</span>
                <span style={{ flex: "1" }}>{inboundData[0]?.queue_name}</span>
            </div> */}
            <div
                style={{
                    display: "flex",
                    // gap: "10px",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0 50px"
                }}
            >
                <label style={{fontSize: "16px", }}>Route</label>
             <FormControl fullWidth>
                {/* <InputLabel id="route-label">Route</InputLabel> */}
                {inboundData[0]?.contacts && (
                    <Select
                        labelId="route-label"
                        value={gettingRouteValue}
                        onChange={routeHandler}
                        fullWidth
                        displayEmpty
                    >
                        <MenuItem value="">
                            {/* <em>Module</em> */}
                        </MenuItem>
                        {routeArry.map((route, index) => (
                            <MenuItem key={index} value={route.key}>
                                {route.name}
                            </MenuItem>
                        ))}
                    </Select>
                )}
            </FormControl>
            </div>
        </div>
    );
};

export default CustomerInfo;
