import React, { useEffect, useState } from "react";
import {
  useAllCampaignDataQuery,
  useCampaignTeamsQuery,
  useCreateCampaignConfigurationMutation,
} from "../../../../Services/campaign/CampaignMapping";
import { CloseButton, FormLabel } from "react-bootstrap";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useUsersByTeamIdQuery } from "../../../../Services/ticket/users/UsersByTeamId";
import { Loader } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";

interface IProps {
  closeModal: any;
  refetch: any;
}

const RoutingAddModal: React.FC<IProps> = ({ closeModal, refetch }) => {
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignData, setCampaignData] = useState([]);
  const [theUser, setTheUser] = useState([]);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [theTeams, setTheTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState<Number>();
  const [valueSelected, setValueSelected] = useState<string>("");
  const [selectedRadio, setSelectedRadio] = useState<string>("");
  const [selectedThreshold, setSelectedThreshold] = useState('')

  const { data: teamsData } = useCampaignTeamsQuery({});
  const { data: AllCampaignData } = useAllCampaignDataQuery({});

  const { data: usersData, status: usersStatus } = useUsersByTeamIdQuery(
    selectedTeam,
    {
      skip: !selectedTeam,
    }
  );

  const [triggerCampaignConfiguration] =
    useCreateCampaignConfigurationMutation();

  useEffect(() => {
    setCampaignData(AllCampaignData?.results);
  }, [campaignData]);


  console.log(campaignData, "campaignDaata")
  useEffect(() => {
    setTheTeams(teamsData);
  }, [teamsData]);

  useEffect(() => {
    if (teamsData?.length > 0) {
      if (teamsData[0]?.round_robin === true) {
        setSelectedRadio("round_robin");
      } else if (teamsData[0]?.FIFO === true) {
        setSelectedRadio("FIFO");
      } else if (teamsData[0]?.default_routing === true) {
        setSelectedRadio("default_routing");
      } else if (teamsData[0]?.manual_routing === true) {
        setSelectedRadio("manual_routing");
      } else if (teamsData[0]?.manual_routing === true) {
        setSelectedRadio('load_balancer')

      }
      
      else {
        setSelectedRadio("");
      }
    }
  }, [teamsData]);

  useEffect(() => {
    if (usersData) {
      setTheUser(usersData);
    }
  }, [usersData]);

  useEffect(() => {
    if (usersStatus === "pending") {
      setIsUserLoading(true);
    } else {
      setIsUserLoading(false);
    }
  }, [usersStatus]);

  const handleTeamDropdown = (event: any) => {
    const selectedTeamId = Number.parseInt(event.target.value);
    setSelectedTeam(selectedTeamId);
  };

  const handleFirstDropdownChange2 = (event: any) => {
    const selectedCampaignId = Number.parseInt(event.target.value);
    setSelectedCampaign(selectedCampaignId);
  };

  const radioChangeHandler = (e: any) => {
    setValueSelected(e.target.value);
    setSelectedRadio(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Directly passing values without creating an object
      const response = await triggerCampaignConfiguration({
        campaign: selectedCampaign,
        FIFO: selectedRadio === "FIFO",
        default_routing: selectedRadio === "default_routing",
        manual_routing: selectedRadio === "manual_routing",
        round_robin: selectedRadio === "round_robin",
        load_balancer: selectedRadio === "load_balancer",
        load_balancer_threshold: +selectedThreshold,
        user: selectedUser,
      });

      if (response?.data) {
        toast.success("Campaign updated Successfully");
        setValueSelected("");
        refetch();
        closeModal();
      }
    } catch (error) {
      toast.error("An unexpected error occurred");
      console.error(error);
    }
  };

  return (
    <>
      <div className="text-end">
        <CloseButton onClick={closeModal} />
      </div>
      <div className="text-center mb-4">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Add Campaign
        </label>
      </div>
      <div style={{ display: "flex", gap: "30px", flexDirection: "column" }}>
        <select
          className="form-select select2-hidden-accessible"
          onChange={handleTeamDropdown}
        >
          <option value="" selected disabled>
            Select Team
          </option>
          {theTeams &&
            theTeams.map((team, index) => (
              <option key={index} value={team.id}>
                {team.name}
              </option>
            ))}
        </select>

        <select className="form-select" onChange={handleFirstDropdownChange2}>
          <option value="" selected>
            Select a Campaign
          </option>
          {campaignData?.map((campaign, index) => (
            <option key={index} value={campaign.id}>
              {campaign.name}
            </option>
          ))}
        </select>
      </div>

      <div className="mt-5">
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={selectedRadio}
            name="radio-buttons-group"
            onChange={radioChangeHandler}
          >
            <div style={{ display: "flex", gap: "16px" }}>
              <div style={{ flex: 1 }}>
                <FormControlLabel
                  value="round_robin"
                  control={<Radio />}
                  label="Round Robin"
                />
              </div>
              <div style={{ flex: 1 }}>
                <FormControlLabel
                  value="default_routing"
                  control={<Radio />}
                  label="Team Assignment"
                />
              </div>
            </div>

            <div style={{ display: "flex", gap: "16px" }}>
              <div style={{ flex: 1 }}>
                <FormControlLabel
                  value="FIFO"
                  control={<Radio />}
                  label="Active Logins (RR)"
                />
              </div>

              <div style={{ marginLeft: "16px" }}>
                <FormControlLabel
                  value="manual_routing"
                  control={<Radio />}
                  label="Individual Assignment"
                />
              </div>
            </div>
              {/* <div >
                <FormControlLabel
                  value="load_balancer"
                  control={<Radio />}
                  label="Load balancer"
                />
              </div> */}
          </RadioGroup>
        </FormControl>
      </div>

      {selectedRadio === "manual_routing" && (
        <div className="row mt-3">
          <div className="col">
            <label className="form-label mx-2">User</label>
            {isUserLoading ? (
              <Loader />
            ) : (
              <select
                className="form-select"
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
              >
                <option value="" disabled>
                  Select User
                </option>
                {theUser.map((user: any) => (
                  <option key={user.id} value={user.id}>
                    {user.username}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
      )}

      {/* {selectedRadio === 'load_balancer' && 
        <div className="row mt-3">
        <div className="col">
          <label className="form-label mx-2">Threshold</label>
          <input className="form-control" onChange={(e) => setSelectedThreshold(e.target.value)}/>
          
        </div>
      </div>} */}

      <div className="text-end mt-5 mb-4">
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default RoutingAddModal;
