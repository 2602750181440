import React, { createRef, useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import TasksActionMenu from "../common/tabbedPanel/TasksActionMenu";
import {
  Box,
  CardContent,
  Checkbox,
  Pagination,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip, Typography, Card
} from "@mui/material";
import { capitalizeFirstLetter } from "../../components/reusableComponent/CapitalLetter";
import { formatDate } from "../../components/reusableComponent/DateFormat";
import LoadingSpinner from "../../components/LoadingSpinner";
import EmptyRows from "../../components/reusableComponent/EmptyRows";
import CampaignActionMenu from "./CampaignActionMenu";
import { maskEmail, maskPhoneNumber } from "../reusableComponent/MaskedFields";
interface Lead {
  isSelected: boolean | string;
  leadId: string;
  fname: string;
  lname: string;
  company: string;
  mailid: string;
  mobileno: string;
  status: string;
}

interface IProps {
  listOfLeads: any;
  taskRes: any;
  CampaignWorkflow: any;
  setStoreKey: any;
  setValue: any;
  value: any;
  currentPage,
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  useData: any;
  campign: any;
  setSelectedTickets,
  selectedTickets: any;
  selectedOptions:any;
  checkTenant
}

const CampaignCardView: React.FC<IProps> = ({ listOfLeads,checkTenant, taskRes, CampaignWorkflow, setStoreKey,
  setValue, value, currentPage, setCurrentPage, campign, setSelectedTickets, selectedTickets,selectedOptions }) => {
  const taskResActionMenuRef = useRef<any[]>([]);
  taskResActionMenuRef.current = taskRes.map(
    (item: any, i: number) => taskResActionMenuRef.current[i] ?? createRef()
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(5);
  // const [totalPages, setTotalPages] = useState<number>(0);
  const [selectAll, setSelectAll] = useState(false);
  const [uiData, setUiData] = useState([]);
  const [keyState, setKeyState] = useState([]);
  const [ticketTabLoading, setTicketTabLoading] = useState<boolean>(false);
  const ticketCount = campign?.count ? campign?.count : 0;

  const checkUserRole = localStorage.getItem('user_role')


  const navigate = useNavigate();
  const rows =
    campign?.results?.map((campign: any) => ({
      broadCast :campign?.broadcast_msg,
      
      id: campign?.id,
      status_type: campign?.status_type,
      displayName: campign?.status_display,
      account_name: campign?.account_name,
      created: campign?.created,
      assignee: campign?.assignee?.username,
      raisedBy: campign?.created_by?.username,
      mobile: campign?.phone_number,
      email: campign?.email,
    })) || [];
  const handleSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const newSelectedTickets = event.target.checked
      ? [...selectedTickets, id]
      : selectedTickets.filter((ticketId) => ticketId !== id);

    setSelectedTickets(newSelectedTickets);

    // Uncheck "Select All" if any checkbox is unchecked
    if (!event.target.checked) {
      setSelectAll(false);
    }
  };
  const getStatusStyle = (status_type: string) => {
    switch (status_type) {
      case "active":
        return { backgroundColor: "#56d85b", color: "white" }; // $green-300
      case "closed":
        return { backgroundColor: "#f44336", color: "white" };    // $yellow-200 // $cyan-300     { backgroundColor: "#90dcf9", color: "white" }; 
      case "reopen":
        return { backgroundColor: "#56d85b", color: "white" };; // $red-200   { backgroundColor: "#f44336", color: "white" };
      case "wip":
        return { backgroundColor: "#ffeb3b", color: "black" }; // $yellow-200  { backgroundColor: "#ffeb3b", color: "black" }; 
      default:
        return { backgroundColor: "#6c757d", color: "white" }; // Default color
    }
  };

  const truncateDescription = (description, maxLength) => {
    if (description?.length > maxLength) {
      return description?.slice(0, maxLength) + "...";
    }
    return description;
  };
  const processData = async () => {
    const uiFormattedData = [];
    const keysForState = [];

    // const [selectedTab, setSelectedTab] = useState<number>(0);
    if (CampaignWorkflow && typeof CampaignWorkflow === "object") {
      await Object.entries(CampaignWorkflow)?.forEach(([key, value]) => {
        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          // Handle objects like { Open: 8 }
          Object.entries(value).forEach(([nestedKey, nestedValue]) => {
            uiFormattedData.push({
              key: nestedKey,
              value: nestedValue,
            });
            keysForState.push(key);
          });
        } else if (Array.isArray(value)) {
          // Handle arrays of objects [{ Closed: 1 }, { ss: 0 }]
          value.forEach((item) => {
            if (item && typeof item === "object") {
              Object.entries(item).forEach(([nestedKey, nestedValue]) => {
                uiFormattedData.push({
                  key: nestedKey,
                  value: nestedValue,
                });
                keysForState.push(key);
              });
            }
          });
        }
      });

      setUiData(uiFormattedData);
      setKeyState(keysForState);
    } else {
      console.error("Campign is undefined or null");
    }

  };
  useEffect(() => {
    processData();
  }, [CampaignWorkflow]);
  const totalPages = Math.ceil(ticketCount / pageSize);
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const generatePaginationNumbers = () => {
    const numbers = [];
    const maxVisibleNumbers = 5;

    if (totalPages <= maxVisibleNumbers) {
      for (let i = 1; i <= totalPages; i++) {
        numbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      for (let i = startPage; i <= endPage; i++) {
        numbers.push(i);
      }
    }

    return numbers;
  };

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setLoading(true); // Set loading to true when component mounts or updates
    setTimeout(() => {
      setLoading(false); // Simulate loading completion after a delay (replace with actual data fetching)
    }, 3000);
  }, [currentPage]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
    if (event.target.checked && campign?.results.length > 0) {     
      setSelectedTickets(campign?.results.map((row) => row.id));
    } else {
      setSelectedTickets([]);
    }
  };
  useEffect(() => {
    if (campign && campign.results) {
    // Check if all rows are selected to update the "Select All" checkbox
    if (campign?.results.length > 0 && selectedTickets.length === campign.results.length) {    
      setSelectAll(true);
    } 
    else {
      setSelectAll(false);
    }
  }else {
     setSelectAll(false); 
    }
  }, [selectedTickets,campign]);

  useEffect(() => {
    setTicketTabLoading(true); // Set tab loading to true when the component mounts
    setTimeout(() => {
      setTicketTabLoading(false); // Simulate tab loading completion after a delay (replace with actual data fetching)
    }, 3000);
  }, [value]); // Add value as a dependency to re-run the effect when the tab changes
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div
            className="nav nav-stretch nav-line-tabs text-primary border-transparent d-flex mb-5"
            style={{ padding: "0 2.25rem", display: "flex", }}
          >

            <Box
              sx={{
                width: "100%",
                background: "#FFFFFF",
                borderRadius: "6px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)", // subtle shadow for better elevation
                marginLeft: "25px"
              }}
            >
              <Tabs
                value={uiData.findIndex((item) => item.key === value)}
                onChange={(event, newValue) => {
                  setStoreKey(keyState[newValue]);
                  setValue(uiData[newValue].key);
                  setCurrentPage(1);
                }}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                sx={{
                  "& .MuiTab-root": {
                    minWidth: 0, // Set minimum width to 0
                    flexGrow: 1, // Add flexGrow to make tabs responsive
                    padding: "12px 24px",
                    textTransform: "none",
                    fontWeight: 500,
                    fontSize: "12px",
                  },
                  "& .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabScrollButton-horizontal.Mui-disabled.MuiTabs-scrollButtons.MuiTabs-scrollButtonsHideMobile.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root":
                  {
                    display: "none",
                  },
                  "& .MuiTab-root:not(:last-child)": {
                    borderRight: "1px solid #e0e0e0", // Thicker, more pronounced divider
                  },
                  "& .MuiTab-root:hover": {
                    backgroundColor: "#f5f5f5", // Adds a hover effect for better interaction
                  },
                  "& .Mui-selected": {
                    fontWeight: 600, // Highlights selected tab more
                  },
                }}
              >
                {uiData?.map((ticketsInfo, index) => (
                  <Tooltip key={index} title={`${ticketsInfo.key} (${ticketsInfo.value})`} arrow>
                  
                  <Tab
                    key={index}
                    label={truncateDescription(
                      `${ticketsInfo.key} (${ticketsInfo.value})`,
                      25
                    )}
                    sx={{ 
                      width: "20%", // Ensure all tabs take full width 
                     maxWidth: "none", // Remove max width restriction 
                     display: "flex", // Ensure flexibility 
                    justifyContent: "center", // Center the text
                     }}
                  />
                  </Tooltip>
                ))}
              </Tabs>
            </Box>
          </div>

          {ticketTabLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="tab-content d-flex" style={{position: "relative", width: "100%", overflow: "scroll"}}>
              <div className="row g-9">
                <div className="col-md-12">
                  <div
                    style={{ marginLeft: "40px",display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr", }}
                  >
                    <input  type="checkbox" className=" mb-xl-9 h-100" style={{ marginTop: "18px",position: "absolute",  top: "-43%", left: "14px",backgroundColor:"black",cursor:"pointer",width:"15px",borderColor: 'black',borderWidth: '4px',borderStyle: 'solid', }}
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />

                    {rows?.map((row: any) => (
                      <div key={row.id} className="flex-grow-1 mx-3 p-2" style={{ flex: "1 1 300px", minWidth: "280px" }}
>
                        <Link
                          // to={`/ticket/information/${row.id}`}
                          to={`/campaign/lead/${row.id}`}
                          className="text-hover-primary fs-5 fw-bold flex-grow-1"
                        >
                          <Card key={row.id} className="mb-6 mb-xl-9"
                            style={{
                              minHeight: "350px",
                              display: "flex",
                              flexDirection: "column",
                            }}>
                            <CardContent>
                              <div className="d-flex align-items-center mb-3">
                                <Checkbox
                                  checked={selectedTickets.includes(row.id)}
                                  onChange={(event) =>
                                    handleSelect(event, row.id)
                                  }
                                  onClick={(e) => e.stopPropagation()}

                                />

                                <Typography className="fs-6 fw-semibold text-gray-600">
                                  Ref ID: {row?.id}
                                </Typography>
                                {checkTenant === "meta" &&
                                <div>
                                  {console.log(row, "SDccscs")}
                                                                            {row?.broadCast ===
                                                                            true ? (
                                                                                <i
                                                                                    className="bi bi-check-circle-fill text-success"
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "1.2rem",
                                                                                    }}
                                                                                    title="Message Sent Successfully"
                                                                                ></i>
                                                                            ) : row.broadCast ===
                                                                              "false" ? (
                                                                                <i
                                                                                    className="bi bi-x-circle-fill text-danger"
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "1.2rem",
                                                                                    }}
                                                                                    title="Message Sending Failed"
                                                                                ></i>
                                                                            ) : (
                                                                                <i
                                                                                    className="bi bi-dash-circle-fill text-muted"
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "1.2rem",
                                                                                    }}
                                                                                    title="Message Not Sent"
                                                                                ></i>
                                                                            )}
                                                                        </div>
  }
                              </div>


                              <div
                                className="badge badge-light mb-3"
                                style={{
                                  ...getStatusStyle(row.status_type),
                                }}
                                data-bs-toggle="tooltip"
                                title={row.status_display}
                              >
                                <span className="fw-bold">
                                  Status:{" "}
                                  {truncateDescription(row.displayName, 10)}
                                </span>
                              </div>

                              {/* {row.account_name && (
                              <Typography
                                className="fs-6 fw-semibold text-gray-600 mb-3"
                                title={row.account_name}
                              >
                               Account Name:
                                
                                  {truncateDescription(row.account_name, 10)}
                              
                              </Typography>
                            )} */}
                              <Typography className="fs-6 fw-semibold text-gray-600 mb-3">
                                Account name: {truncateDescription(row.account_name, 10)}
                              </Typography>
                              <Typography className="fs-6 fw-semibold text-gray-600 mb-3">
                                Created at: {formatDate(row.created)}
                              </Typography>
                              <Typography className="fs-6 fw-semibold text-gray-600 mb-3">
                                Assignee: {capitalizeFirstLetter(row.assignee === undefined ? "Unassigned" : row.assignee)}
                              </Typography>
                              <Typography className="fs-6 fw-semibold text-gray-600 mb-3">
                                Created by: {capitalizeFirstLetter(row.raisedBy)}
                              </Typography>
                              <Typography className="fs-6 fw-semibold text-gray-600 mb-3">
                                Mobile no: {checkUserRole === "agent" ? maskPhoneNumber(row.mobile) : row.mobile}
                              </Typography>
                              <Typography className="fs-6 fw-semibold text-gray-600 mb-5">
                                Email: {checkUserRole === "agent" ? maskEmail(row.email) : capitalizeFirstLetter(row.email)}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <div className="row mx-4 pt-2 mb-2">
        {/* Page information */}
        <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
          <span>
            Page {currentPage} of {totalPages}
          </span>
        </div>

        <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
          <div
            className="dataTables_paginate paging_simple_numbers"
            id="kt_table_users_paginate"
          >
            <ul className="pagination">
              {/* Previous button */}
              <li
                className={`paginate_button page-item ${currentPage === 1 ? "disabled" : ""
                  }`}
              >
                <button
                  type="button"
                  aria-controls="kt_table_users"
                  data-dt-idx="0"
                  className="btn primary page-link"
                  onClick={prevPage}
                >
                  <i className="previous"></i>
                </button>
              </li>

              {/* Page numbers */}
              {generatePaginationNumbers().map((pageNumber) => (
                <li
                  key={pageNumber}
                  className={`paginate_button page-item ${currentPage === pageNumber ? "active" : ""
                    }`}
                >
                  <button
                    type="button"
                    aria-controls="kt_table_users"
                    data-dt-idx={pageNumber}
                    className="btn primary page-link"
                    onClick={() => paginate(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                </li>
              ))}

              {/* Next button */}
              <li
                className={`paginate_button page-item ${currentPage === totalPages ? "disabled" : ""
                  }`}
              >
                <button
                  type="button"
                  aria-controls="kt_table_users"
                  data-dt-idx="0"
                  className="btn primary page-link"
                  onClick={nextPage}
                >
                  <i className="next"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </>
  );
};

export default CampaignCardView;