import React from "react";
import { Skeleton, Box, Typography } from "@mui/material";

const SkeletonLoader = () => {
  return (
    <div style={{display: "flex", width: "100%", height: "50px"}}>
    {/* Box 1 */}
    <Box
    
      sx={{
        width: "60%",
        height: "100%",
        padding: 2,
        display: "flex",
        gap: "15px",
        flexWrap: "wrap", // Ensures proper alignment if content overflows
      }}
    >
      {/* Title Loader */}
      <Skeleton animation="wave" variant="circular" width="3%" height={25} />
  
      {/* Row Loaders */}
      <Skeleton animation="wave" variant="rectangular" width="10%" height={25} />
      <Skeleton animation="wave" variant="rectangular" width="10%" height={25} />
      <Skeleton animation="wave" variant="rectangular" width="10%" height={25} />
      <Skeleton animation="wave" variant="text" width="2%" height={25} />

    </Box>
  
    {/* Box 2 */}
    <Box
      sx={{
        flex: 1,
        padding: 2,
        height: "100%",
        display: "flex",
        justifyContent: "end",
        marginRight: "30px",
        gap: "10px",
        flexWrap: "wrap", // Optional for layout control
      }}
    >
      {/* Row Loaders */}
      <Skeleton animation="wave" variant="rectangular" width="15%" height={25} />
      <Skeleton animation="wave" variant="rectangular" width="15%" height={25} />
    </Box>
  </div>
  
  );
};

export default SkeletonLoader;
