import { toast } from "react-toastify";
interface IProps {
  closeModal: any
  deletionIdentifier: any
  messageBody: any
  deleteAPItrigger: any
  toastSuccessMessage?: string
  refetch: any
}

const DeleteModal: React.FC<IProps> = ({ closeModal, deleteAPItrigger, deletionIdentifier, messageBody, toastSuccessMessage, refetch }) => {
  const confirmDeleteSchedule = async (event: any) => {
    const response = await deleteAPItrigger(deletionIdentifier);
    if (response) {
      if (response.data === true) {
        if (toastSuccessMessage) {
          toast.success(toastSuccessMessage);
          refetch();
        } else {
          toast.success('Deletion successful!');
          refetch();
        }
        closeModal();
      }
    };
  }

  return (<>
    <div className='card-body'>
      {messageBody}
      <div className='d-flex justify-content-end mt-10'>
        <button type='reset' className='btn btn-sm btn-white btn-active-light-primary me-2' onClick={() => closeModal()}> Cancel </button>
        <button type='submit' className='btn btn-sm btn-danger' onClick={confirmDeleteSchedule}> Delete </button>
      </div>
    </div>
  </>)
}

export default DeleteModal