import React from "react";

interface LeadEventData {
    lead: string;
    title: string;
    description: string;
    event_date: string;
    start_time: string;
}

interface LeadEventModalProps {
    leadEventData: LeadEventData | null;
    onClose: () => void;
}

const LeadEventModal: React.FC<LeadEventModalProps> = ({
    leadEventData,
    onClose,
}) => {
    if (!leadEventData) return null;

    const { lead, title, description, event_date, start_time } = leadEventData;

    return (
        <div
            style={
                {
                  display: "flex", 
                  flexDirection: "column", 
                  // gap: "20px",
                  justifyContent: "space-between", 
                  height: "100%"
                    // width: "400px",
                    // margin: "50px auto",
                    // // padding: "20px",
                    // backgroundColor: "#fff",
                    // borderRadius: "8px",
                    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    // fontFamily: "Arial, sans-serif",
                }
            }
        >
            <h2
                style={{
                    fontSize: "24px",
                    marginBottom: "16px",
                    textAlign: "center",
                    color: "#333",
                }}
            >
                Reminder
            </h2>
            <ul
                style={{
                    listStyle: "none",
                    padding: 0,
                    // margin: "0 0 20px 0",
                    margin: "0",
                    display: "flex", 
                    flexDirection: "column",
                    gap: "5px"


                }}
            >
                <li
                    style={{
                        marginBottom: "8px",
                        fontSize: "16px",
                        color: "#555",
                        display: "flex",
                        width: "100%"
                    }}
                >
                    <strong style={{ color: "#000", width: "35%" }}>Lead:</strong> <span style={{flex: "1"}}>{lead}</span>
                </li>
                <li
                    style={{
                        marginBottom: "8px",
                        fontSize: "16px",
                        color: "#555",
                          display: "flex",
                        width: "100%"
                    }}
                >
                    <strong style={{ color: "#000", width: "35%" }}>Title:</strong>
                    <span style={{flex: "1"}}> {title}</span>
                </li>
                <li
                    style={{
                        marginBottom: "8px",
                        fontSize: "16px",
                        color: "#555",
                          display: "flex",
                        width: "100%"
                    }}
                >
                    <strong style={{ color: "#000", width: "35%" }}>Description:</strong>
                    <span style={{flex: "1"}}>
                    {description}
                    </span>
                      
                </li>
                <li
                    style={{
                        marginBottom: "8px",
                        fontSize: "16px",
                        color: "#555",
                          display: "flex",
                        width: "100%"
                    }}
                >
                    <strong style={{ color: "#000", width: "35%" }}>Event Date:</strong>
                  <span style={{flex: "1"}}>
                      {event_date}
                    </span>
                </li>
                <li
                    style={{
                        marginBottom: "8px",
                        fontSize: "16px",
                        color: "#555",
                          display: "flex",
                        width: "100%"
                    }}
                >
                    <strong style={{ color: "#000", width: "35%" }}>Start Time:</strong>
                   <span style={{flex: "1"}}>
                     {start_time}
                    </span>
                </li>
            </ul>
            <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
            <button
              className="btn btn-secondary"
                onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = "#0056b3")
                }
                onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = "#007bff")
                }
                onClick={onClose}
            >
                Close
            </button>
            </div>
        </div>
    );
};

export default LeadEventModal;
