import React, { useState } from "react";
import { useBulkUploadMutation } from "../../Services/user/Users";
import { toast } from "react-toastify";
import ExcelJS from "exceljs";
import * as XLSX from "xlsx";
import { Box, LinearProgress } from "@mui/material";

interface IProps {
  closeModal: any;
  refetch: any;
  dynamicData: any;
  teamsData: any;
}

const BulkInviteModal: React.FC<IProps> = ({
  closeModal,
  refetch,
  dynamicData,
  teamsData,
}) => {
  const [selectedCsvFiles, setSelectedCsvFiles] = useState<File[]>([]);
  const [fileToDownload, setFileToDownload] = useState<File | null>(null);
  const [failDetails, setFailDetails] = useState<any[]>([]); // Store fail details here
    const [showingLoader, setShowingLoader] = useState(false)
      const [progress, setProgress] = React.useState(null);
    
  
  const [triggerBulkUpload] = useBulkUploadMutation({});

  const handleCsvFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      setSelectedCsvFiles(files);
      setFileToDownload(files[0]);
    }
  };

  const handleRemoveCsvFile = (index: number) => {
    const newFiles = Array.from(selectedCsvFiles);
    newFiles.splice(index, 1);
    setSelectedCsvFiles(newFiles);
    if (index === 0 && newFiles.length > 0) {
      setFileToDownload(newFiles[0]); // Update the file to be downloaded if the first file was removed
    } else if (index === 0) {
      setFileToDownload(null); // Clear file to download if no files are left
    }
  };

  const handleCsvDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const ws: any = workbook.addWorksheet("Test Worksheet");

    let header = [
      "user_first_name",
      "user_last_name",
      "user_role",
      "user_username",
      "user_teams",
      "user_branch_user",
    ];

    // Check if dynamicData and fields exist
    if (dynamicData && dynamicData.length) {
      // Map over the dynamicData to create header fields
      dynamicData.forEach((field) => {
        let csvKey = field.label.replace(/ /g, "_"); // Replace spaces with underscores
        header.push(`user_${csvKey}`); // Append the prefix 'user_'
      });
    }

    // Add header row to the worksheet
    ws.addRow(header);

    // Set column widths
    ws.columns.map((col) => (col.width = 18));

    dynamicData.forEach((field, index) => {
      if (field.field_type === "check-box") {
        const checkboxOptions = field.multiple_choices
          .map((choice) => choice.choice)
          .join(",");

        // Determine the column index for this field in the header
        const columnIndex = header.length - dynamicData.length + index + 1; // Adjust for the current dynamic field
        const columnLetter = String.fromCharCode(64 + columnIndex);

        // Add dropdown validation for this field's column
        for (let i = 2; i <= 100; i++) {
          // Apply validation to rows 2-100
          ws.getCell(`${columnLetter}${i}`).dataValidation = {
            type: "list",
            allowBlank: false,
            formulae: [`"${checkboxOptions}"`], // Using all options without truncation
          };
        }
      } else if (field.field_type === "drop-down") {
        const fieldOptions = field?.choices.map((option: any) => option.choice);

        // Determine the column index for this field in the header
        const columnIndex = header.length - dynamicData.length + index + 1; // Adjust for the current dynamic field
        const columnLetter = String.fromCharCode(64 + columnIndex);

        // Add dropdown validation for this field's column
        for (let i = 2; i <= 100; i++) {
          // Apply validation to rows 2-100
          ws.getCell(`${columnLetter}${i}`).dataValidation = {
            type: "list",
            allowBlank: false,
            formulae: [`"${fieldOptions}"`], // Using all options without truncation
          };
        }
      }
    });

    // Prepare a hidden worksheet for dropdown options
    const hiddenSheet = workbook.addWorksheet("DropdownOptions", {
      state: "hidden",
    });

    // Add roles (Agent, Manager) to the hidden sheet
    hiddenSheet.getCell("A1").value = "agent";
    hiddenSheet.getCell("A2").value = "manager";

    // Add branch_user options (True, False) to the hidden sheet
    hiddenSheet.getCell("B1").value = "Yes";
    hiddenSheet.getCell("B2").value = "No";

    // Add team names in the hidden sheet starting from column C
    teamsData.forEach((team, index) => {
      hiddenSheet.getCell(`C${index + 1}`).value = team.name;
    });

    // Define ranges for dropdowns
    const userRoleRange = "DropdownOptions!$A$1:$A$2"; // Dropdown for user_role (Agent, Manager)
    const branchUserRange = "DropdownOptions!$B$1:$B$2"; // Dropdown for branch_user (Yes, No)
    const teamRange = `DropdownOptions!$C$1:$C$${teamsData.length}`; // Dropdown for user_teams

    // Set data validation for the user_role dropdown (e.g., column C)
    ws.dataValidations.add("C2:C99999", {
      type: "list",
      allowBlank: false,
      formulae: [userRoleRange], // Dropdown list for user roles
    });

    // Set data validation for the user_teams dropdown (e.g., column E)
    ws.dataValidations.add("E2:E99999", {
      type: "list",
      allowBlank: false,
      formulae: [teamRange], // Dropdown list from team names
    });

    // Set data validation for the branch_user dropdown (e.g., column F)
    ws.dataValidations.add("F2:F99999", {
      type: "list",
      allowBlank: false,
      formulae: [branchUserRange], // Dropdown list for branch_user (Yes, No)
    });

    // Style the header row with blue fill
    ws.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFADD8E6" },
    };

    // Apply specific red fill style to headers
    const redHeaders = [
      "user_first_name",
      "user_last_name",
      "user_role",
      "user_username",
      "user_teams",
      "user_branch_user",
    ];
    redHeaders.forEach((headerName) => {
      const colIndex = header.indexOf(headerName) + 1; // Find column index for each red header
      ws.getCell(1, colIndex).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF0000" }, // Red color for specific headers
      };
    });

    // Apply general styles to all cells
    ws.eachRow((row) => {
      row.eachCell((cell) => {
        cell.font = {
          name: "Inter",
          size: 8,
        };
        cell.alignment = {
          horizontal: "center",
        };
      });
    });

    // Check if dynamicData and fields exist
    if (dynamicData && dynamicData.length) {
      dynamicData.forEach((field, index) => {
        if (field.is_required) {
          const columnIndex = header.length - dynamicData.length + index + 1; // Adjust for the current dynamic field
          const columnLetter = String.fromCharCode(64 + columnIndex);
          ws.getCell(`${columnLetter}1`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF0000" }, // Red color
          };
        }
      });
    }

    // Generate the Excel file as a blob
    const excelBlob = await workbook.xlsx.writeBuffer();
    const excelUrl = URL.createObjectURL(
      new Blob([excelBlob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );

    // Create a link to download the Excel file
    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = "User_template.xlsx";
    document.body.appendChild(link);
    link.click();

    // Clean up
    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);
  };

  const handleDownloadFailDetails = async () => {
    const workbook = new ExcelJS.Workbook();
    const ws = workbook.addWorksheet("Failed Uploads");

    // Prepare header for static fields
    const header = [
      "Username", // Static Field
      "Role", // Static Field
      "Teams", // Static Field
      "Branch User", // Static Field
      // Dynamic field headers will be added dynamically
    ];

    // Dynamically add dynamic field headers
    if (dynamicData && dynamicData.length) {
      dynamicData.forEach((field) => {
        const csvKey = field.label.replace(/ /g, "_"); // Replace spaces with underscores
        header.push(`user_${csvKey}`); // Dynamic field label in header
      });
    }

    // Errors column is added at the last
    header.push("Errors");

    // Add headers to the worksheet
    ws.addRow(header);

    // Set column widths
    ws.columns.map((col) => (col.width = 18));

    // Iterate through failDetails to populate rows
    failDetails.forEach((fail) => {
      const { username, role, teams, branch_user, dynamic_fields, error } =
        fail;

      // Prepare dynamic fields data in the same structure as CSV
      const dynamicFieldValues = dynamicData.map((field) => {
        const fieldKey = field.label.replace(/ /g, "_");
        return dynamic_fields[fieldKey] || ""; // Return 'N/A' if value is missing
      });

      // Convert errors into string for easier display in Excel
      const errorString = Array.isArray(error)
        ? error.join(", ")
        : JSON.stringify(error);

      ws.getRow(1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFADD8E6" },
      };

      // Apply styles to all cells
      ws.eachRow((row) => {
        row.eachCell((cell) => {
          cell.font = {
            name: "Inter",
            size: 8,
          };
          cell.alignment = {
            horizontal: "center",
          };
        });
      });

      // Add the row with dynamic fields and the error at the end
      ws.addRow([
        username, // Username
        role, // Role
        teams.join(", "), // Teams as a comma-separated string
        branch_user ? "Yes" : "No", // Branch user value (Yes/No)
        ...dynamicFieldValues, // Spread dynamic field values here
        errorString, // Errors at the end
      ]);
    });

    // Apply specific red fill style to headers
    const redHeaders = [
      "Username", // Static Field
      "Role", // Static Field
      "Teams", // Static Field
      'Branch User'
    ];
    redHeaders.forEach((headerName) => {
      const colIndex = header.indexOf(headerName) + 1; // Find column index for each red header
      ws.getCell(1, colIndex).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF0000" }, // Red color for specific headers
      };
    });

    // Check if dynamicData and fields exist
    if (dynamicData && dynamicData.length) {
      dynamicData.forEach((field, index) => {
        if (field.is_required) {
          const columnIndex = header.indexOf(`user_${field.label.replace(/ /g, "_")}`) + 1; // Find the correct column index
          ws.getCell(1, columnIndex).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF0000" }, // Red color
          };
        }
      });
    }

    // Create the Excel file and initiate the download
    const excelBlob = await workbook.xlsx.writeBuffer();
    const excelUrl = URL.createObjectURL(
      new Blob([excelBlob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );

    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = "failed_uploads.xlsx";
    document.body.appendChild(link);
    link.click();

    // Clean up temporary URLs and DOM elements
    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);
    closeModal();
  };

  const cleanCsvData = (csvContent: string, percentage: number) => {
    const rows = csvContent.split("\n").map((row) => row.split(","));
    const cleanedRows = rows.filter((row) => row.some((cell) => cell.trim() !== ""));
    const [headers, ...dataRows] = cleanedRows;
  
    const totalRows = Math.ceil((dataRows.length * percentage) / 100);
    const limitedRows = dataRows.slice(0, totalRows);
  
    const cleanedData = limitedRows.map((row) =>
      headers.reduce((acc, header, index) => {
        acc[header.trim()] = row[index]?.trim() || "";
        return acc;
      }, {})
    );
  
    return cleanedData;
  };

  const createCsvContent = (data: any[]) => {
    if (data.length === 0) return "";
    const headers = Object.keys(data[0]);
    const rows = data.map((row) => headers.map((header) => row[header]).join(","));
    return [headers.join(","), ...rows].join("\n");
  };

 const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  
    if (selectedCsvFiles.length > 0) {
    setShowingLoader(true)

      const file = selectedCsvFiles[0];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
  
      const reader = new FileReader();
  
      reader.onload = async (e) => {
        let csvContent: string | undefined;
  
        if (e.target?.result) {
          if (fileExtension === "csv") {
            csvContent = e.target.result as string;
          } else if (fileExtension === "xlsx") {
            const binaryStr = e.target.result as string;
            const workbook = XLSX.read(binaryStr, { type: "binary" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            csvContent = XLSX.utils.sheet_to_csv(worksheet);
          }
  
          if (csvContent) {
            const cleanedData = cleanCsvData(csvContent, 100);
  
            const partSize = Math.ceil(cleanedData.length / 5);
            const dividedData = [];
            for (let i = 0; i < cleanedData.length; i += partSize) {
              dividedData.push(cleanedData.slice(i, i + partSize));
            }
  
            try {
              let combinedFailDetails: any[] = [];
              const totalParts = dividedData.length;
              let allSuccess = false;
  
              for (let i = 0; i < totalParts; i++) {
                const part = dividedData[i];
                const csvContentPart = createCsvContent(part);
  
                const requestObj = {
                  file_name: `Part_${i + 1}_${file.name}`,
                  csv_file: `data:text/csv;base64,${btoa(csvContentPart)}`,
                };
  
                const response = await triggerBulkUpload({ body: requestObj });
  
                // Log response details
                console.log(response?.data?.fail_count || response?.data?.success_count, "cdscdscscc");

                // Loop exitt
  
                if (
                  !response?.data?.fail_count &&
                  !response?.data?.success_count
                ) {
                  console.warn("No meaningful response; exiting loop.");
                  allSuccess = false;
                  break;
                }
  
                if (response) {
                  combinedFailDetails = [
                    ...combinedFailDetails,
                    ...(response?.data?.fail_details || []),
                  ];

                  allSuccess = true
                } else {
                }
  
                // Update progress dynamically
                setProgress(((i + 1) / totalParts) * 100);
              }
  
              setFailDetails(combinedFailDetails);
              setSelectedCsvFiles([]);
              refetch();
  
              // Display toast based on overall success
              if (allSuccess) {
                toast.success("File uploaded successfully!");
              } else {
                toast.error("No data found.");
              }
              setShowingLoader(false)
            } catch (error) {
              setShowingLoader(false)

              toast.error("Upload failed. Please try again.");
            }
          }
        }
      };
  
      if (fileExtension === "csv") {
        reader.readAsText(file);
      } else if (fileExtension === "xlsx") {
        reader.readAsBinaryString(file);
      } else {
        toast.error(
          "Unsupported file format. Please upload a CSV or XLSX file."
        );
      }
    }

  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="mb-4">
        <div className="d-flex justify-content-evenly mt-2 mb-8 mx-1">
          {/* CSV Upload Button */}
          <label
            htmlFor="upload-csv-button"
            className="form-control d-flex align-items-center justify-content-center bg-secondary fw-bold mx-2 px-3 py-2 rounded mw-170px"
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-file-earmark-spreadsheet text-dark fs-4 me-2"></i>
            CSV/XLSX Upload
          </label>
          <input
            type="file"
            id="upload-csv-button"
            accept=".csv, .xlsx"
            style={{ display: "none" }}
            onChange={handleCsvFileChange}
          />

          {/* CSV Download Button */}
          <button
            type="button"
            className="form-control d-flex align-items-center justify-content-center bg-secondary fw-bold mx-2 px-3 py-2 rounded mw-150px"
            onClick={handleCsvDownload}
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-download text-dark fs-4 me-2"></i>
            Download File
          </button>
        </div>

        {showingLoader ? 

<Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
    : ""
        }

        <div className="mt-3 mb-3">
          <label className="fs-6 fw-bolder mb-2">Important Instructions:</label>
          <br />
          <label className="text-danger">
            &bull; Please upload a valid CSV or XLSX file format only.
          </label>
          <br />
          <label className="text-danger">
            &bull; Headers highlighted in red are mandatory fields.
          </label>
        </div>

        {failDetails?.length > 0 && (
          <button
            type="button"
            className="form-control d-flex align-items-center justify-content-center bg-secondary fw-bold mx-6 px-3 py-2 rounded mw-150px"
            onClick={handleDownloadFailDetails}
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-download text-dark fs-4 me-2"></i>
            Error Data
          </button>
        )}
        {/* Display selected CSV files */}
        {selectedCsvFiles?.length > 0 && (
          <div className="mb-4">
            <h5>Selected CSV files:</h5>
            <ul className="list-group mt-3">
              {selectedCsvFiles.map((file, index) => (
                <li
                  key={index}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <span>{file.name}</span>
                  <button
                    type="button"
                    className="btn btn-link text-danger p-0"
                    onClick={() => handleRemoveCsvFile(index)}
                  >
                    <i className="bi bi-trash text-danger fs-5"></i>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-end mb-4 pt-2">
        <button
          type="button"
          className="btn btn-sm btn-white btn-active-light-danger me-2"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button type="submit" className="btn btn-sm btn-primary">
          Submit
        </button>
      </div>
    </form>
  );
};

export default BulkInviteModal;
