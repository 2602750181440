import React, { useEffect, useState } from "react";
import { CardContent } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../LoadingSpinner";
import {
  useGetAllTeamsQuery,
  useGetAllAssigneeBySelectedTeamIdQuery,
} from "../../../../Services/reports/tickets/ReportTickets";
import { toast } from "react-toastify";
import CsvDownloader from "react-csv-downloader";

const EmailAgentPerformanceReport = () => {
  const navigate = useNavigate();
  const [optionsTeams, setOptionsTeams] = useState<any[]>([]);
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [params, setParams] = useState<any>(null);
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null);
  const [selectedTeamsParam, setSelectedTeamsParam] = useState<string[]>([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [localLoading, setLocalLoading] = useState(false);
  const [filterByOption, setFilterByOption] = useState<any>([
    { label: "Created Date", value: "Created Date" },
    { label: "Updated Date", value: "Updated-date" },
  ]);
  const [selectedFilter, setSelectedFilter] = useState<any>();
  const [optionsAssignee, setOptionsAssignee] = useState<any[]>([]);
  const [selectedAssignee, setSelectedAssignee] = useState<string | null>(null);
  const { data: teamsData, isLoading: teamsLoading } = useGetAllTeamsQuery({});
  const { data: assigneeData } = useGetAllAssigneeBySelectedTeamIdQuery(
    selectedTeamId,
    {
      skip: !selectedTeamId,
    }
  );

  useEffect(() => {
    if (teamsData && teamsData.teams?.length > 0) {
      const transformedOptions = teamsData.teams.map((team: any) => ({
        label: team.name,
        value: team.id,
      }));
      setOptionsTeams(transformedOptions);
    }
  }, [teamsData]);

  useEffect(() => {
    if (params) {
      setLocalLoading(true);
    }
  }, [params]);

  useEffect(() => {
    if (assigneeData) {
      const transformedAssignees = assigneeData.map((assignee: any) => ({
        label: `${assignee.first_name} ${assignee.last_name}`,
        value: assignee.id,
      }));
      setOptionsAssignee(transformedAssignees);
    }
  }, [assigneeData]);
  const columns: GridColDef[] = [
    {
      field: "agent",
      headerName: "Agent",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "mails_allocated",
      headerName: "Mails Allocated",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "active",
      headerName: "Active",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "closed",
      headerName: "Closed",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "frt",
      headerName: "FRT",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ftr_percentage",
      headerName: "FTR %",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const dummyRows: any = [
    {
      id: 1,
      agent: "John Doe",
      mails_allocated: 100,
      active: 20,
      closed: 80,
      frt: "2 hours",
      ftr_percentage: "75%",
    },
    {
      id: 2,
      agent: "Jane Smith",
      mails_allocated: 150,
      active: 30,
      closed: 120,
      frt: "1.5 hours",
      ftr_percentage: "80%",
    },
    {
      id: 3,
      agent: "Robert Johnson",
      mails_allocated: 200,
      active: 50,
      closed: 150,
      frt: "3 hours",
      ftr_percentage: "70%",
    },
  ];

  const handleDateFromChange = (event: any) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event: any) => {
    setDateTo(event.target.value);
  };
  const handleTeamChange = (event: any) => {
    setSelectedTeamId(event.target.value);
  };

  const handleAssigneeChange = (event: any) => {
    setSelectedAssignee(event.target.value);
  };
  const handleSubmit = () => {
    if (!dateFrom) {
      toast.error('Please select "From Date"');
      return;
    }
    if (!dateTo) {
      toast.error('Please select "To Date"');
      return;
    }

    const queryParams: any = {
      gte: dateFrom,
      lte: dateTo,
    };

    if (selectedTeamsParam.length > 0) {
      queryParams.team_id = selectedTeamsParam;
    }

    setParams(queryParams);
  };

  const resetFilters = () => {
    setDateFrom("");
    setDateTo("");
    setParams(null);
  };

  const handlePaginationChange = (newPaginationModel: any) => {
    setLocalLoading(true);
    setPaginationModel(newPaginationModel);
    setTimeout(() => {
      setLocalLoading(false);
    }, 1000);
  };

  const handleFilterOptionChange = (event: any) => {
    setSelectedFilter(event.target.value);
  };

  return (
    <div id="kt_app_content_container" className="app-container mx-5">
      <div className="card card-flush">
        <div
          className="card-header align-items-center py-1 gap-2 gap-md-5"
          style={{ justifyContent: "flex-start" }}
        >
          <img
            src="https://insurance.circleonecrm.com/assets/arrow_back.svg"
            alt="Back"
            style={{ marginBottom: "-2px", cursor: "pointer" }}
            className="ng-star-inserted"
            onClick={() => {
              navigate("/reports/email/reports-list");
            }}
          />
          <span className="fs-3 text-gray-800 fw-bold ms-3">
            {" "}
            Email Agent Performance Report
          </span>
        </div>

        <div
          className="card-toolbar flex-row-fluid justify-content-start gap-5 d-flex mb-10"
          style={{ marginLeft: "30px" }}
        >
          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="filter-by-select-label">Filter By</InputLabel>
              <Select
                labelId="filter-by-select-label"
                id="filter-by-select"
                value={selectedFilter || ""}
                onChange={handleFilterOptionChange}
                label="Filter By"
              >
                {filterByOption.map((filterOption) => (
                  <MenuItem key={filterOption.value} value={filterOption.value}>
                    {filterOption.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="w-100 mw-150px">
            <TextField
              id="date-from"
              label="From"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateFrom}
              onChange={handleDateFromChange}
            />
          </div>
          <div className="w-100 mw-150px">
            <TextField
              id="date-to"
              label="To"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateTo}
              onChange={handleDateToChange}
            />
          </div>
          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="team-select-label">Select Teams</InputLabel>
              <Select
                labelId="team-select-label"
                id="team-select"
                value={selectedTeamId}
                onChange={handleTeamChange}
                label="Select Teams"
              >
                <MenuItem key="all" value="All">
                  All
                </MenuItem>
                {optionsTeams.map((team) => (
                  <MenuItem key={team.value} value={team.value}>
                    {team.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="Assignee-select-label">Assignee</InputLabel>
              <Select
                labelId="Assignee-select-label"
                id="Assignee-select"
                value={selectedAssignee}
                onChange={handleAssigneeChange}
                label="Assignee"
              >
                <MenuItem key="all" value="All">
                  All
                </MenuItem>
                {optionsAssignee.map((assignee) => (
                  <MenuItem key={assignee.value} value={assignee.value}>
                    {assignee.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="ms-auto d-flex gap-3" style={{ marginRight: "20px" }}>
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>

            <button className="btn btn-primary">
              <CsvDownloader
                filename="Email Agent Performance Report"
                datas={dummyRows}
                columns={columns.map((col) => ({
                  id: col.field,
                  displayName: col.headerName,
                }))}
              >
                Download
              </CsvDownloader>
            </button>

            <button className="btn btn-link" onClick={resetFilters}>
              <i className="bi bi-funnel fs-2 text-dark"></i>
            </button>
          </div>
        </div>

        <CardContent style={{ width: "100%" }}>
          {teamsLoading || localLoading ? (
            <LoadingSpinner />
          ) : (
            <DataGrid
              rows={dummyRows}
              columns={columns}
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              loading={localLoading}
              disableColumnMenu
              pageSizeOptions={[5, 10, 15, 20]}
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "lightgray",
                  "&:last-child": {
                    justifyContent: "flex-end",
                  },
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-columnsContainer": {
                  backgroundColor: "lightgray",
                },
                "& .css-16c50h-MuiInputBase-root-MuiTablePagination-select ,.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions":
                  {
                    marginBottom: "10px",
                  },
              }}
            />
          )}
        </CardContent>
      </div>
    </div>
  );
};

export default EmailAgentPerformanceReport;
