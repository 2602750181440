import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useEmaildispositionQuery,
  useLookupByDispositionAndTeamIdMutation,
  useTeamsQuery,
} from "../../../../Services/settingsAPIs/CommonAPI";
import { useLookup_by_dispositionQuery } from "../../../../Services/disposition/Lookup_by_disposition";
import { toast } from "react-toastify";
import clsx from "clsx";

import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { Loader } from "react-bootstrap-typeahead";
import {
  useEmailConfigEditMutation,
  useEmailServerConfigMutation,
} from "../../../../Services/settingsAPIs/EmailConfiguration";
import DispositionSelector from "../../common/dispositionSelector";
import { capitalize } from "lodash";
import { useUsersByTeamIdQuery } from "../../../../Services/ticket/users/UsersByTeamId";

interface IPROPS {
  closeModal: any;
  refetch: any;
  mailConfigForEdit: any;
}

interface DispositionErrorData {
  disposition: string[];
}

const isDispositionErrorData = (data: any): data is DispositionErrorData => {
  return data && Array.isArray(data.disposition);
};

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .test(
      "generic-domains",
      "Generic email domains are not allowed",
      function (value) {
        const genericDomains = [];
        const enteredDomain = value?.split("@")[1];
        return !genericDomains.includes(enteredDomain);
      }
    )
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Minimum 8 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  hostname: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Hostname is required"),
  port: Yup.number()
    .required("Port is required")
    .typeError("Port must be a number"),
  server: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Server is required"),
  ssl: Yup.string().required("SSL is required"),

  team: Yup.array()
    .of(Yup.string())
    .min(1, "Team is required")
    .required("Team is required"),
  // client_id: Yup.string().required("Client Id is required"),
  // client_secret: Yup.string().required("Client Secret is required"),
  // server_tenant_id: Yup.string().required("Server Tenant Id is required"),
});

const ModalEmailConfig: React.FC<IPROPS> = ({
  closeModal,
  refetch,
  mailConfigForEdit,
}) => {
  const [loading, setLoading] = useState(false);
  const [getTeams, setGetTeams] = useState<any>([]);
  const [selectedTeam, setSelectedTeam] = useState<any>("");
  const [selectedDisposition, setSelectedDisposition] = useState<any>(null);
  const [ticketAssignmentType, setTicketAssignmentType] = useState<any>("");
  const [dispositionVisible, setDespositionVisible] = useState<boolean>(false);
  const [userSelection, setUserSelection] = useState<number>();
  const [userSelectionVisiblity, setUserSelectionVisiblity] =
    useState<boolean>(false);
    const [mailServer, setMailServer] = useState<string>("gmail");
  const [lookUpDispositionVisible, setLookUpDespositionVisible] =
    useState<boolean>(false);
  const [workflowSelect, setWorkflowSelect] = useState<any>('');
  const { data } = useTeamsQuery({});
  const { data: emailDisposData } = useEmaildispositionQuery(selectedTeam, {
    skip: !selectedTeam,
  });
  const {
    data: lookUpData,
    isLoading,
    isError,
    error,
  } = useLookup_by_dispositionQuery(
    { body: selectedDisposition, teamId: selectedTeam },
    { skip: !lookUpDispositionVisible }
  );
  const [
    trigger,
    { isLoading: emailConfigLoading, isError: emailConfigError },
  ] = useEmailServerConfigMutation();
  const [triggerEmailConfigEditMutation] = useEmailConfigEditMutation();
  const [triggerLookupByDispositionAndTeamID] =
    useLookupByDispositionAndTeamIdMutation();
  const [theWorkflows, setTheWorkflows] = useState<any>();
  const { data: usersData } = useUsersByTeamIdQuery(selectedTeam, {
    skip: !selectedTeam,
  });

  const initialValues = {
    email: mailConfigForEdit.mailConfigEditEnabled
      ? mailConfigForEdit.mailConfigData.username
      : "",
    password: mailConfigForEdit.mailConfigEditEnabled
      ? mailConfigForEdit.mailConfigData.password
      : "",
    hostname: mailConfigForEdit.mailConfigEditEnabled
      ? mailConfigForEdit.mailConfigData.hostname
      : "",
    port: mailConfigForEdit.mailConfigEditEnabled
      ? mailConfigForEdit.mailConfigData.port
      : "",
    server: mailConfigForEdit.mailConfigEditEnabled
      ? mailConfigForEdit.mailConfigData.server
      : "",
    ssl: mailConfigForEdit.mailConfigEditEnabled
      ? mailConfigForEdit.mailConfigData.ssl
      : "",
    emailServer: mailConfigForEdit.mailConfigEditEnabled
      ? mailConfigForEdit.mailConfigData.emailServer
      : "",
    team: mailConfigForEdit.mailConfigEditEnabled
      ? mailConfigForEdit.mailConfigData.team
      : "",
    selectedDisposition: mailConfigForEdit.mailConfigEditEnabled
      ? mailConfigForEdit.mailConfigData.disposition
      : {},
    workflow: mailConfigForEdit.mailConfigEditEnabled
      ? mailConfigForEdit.mailConfigData.workflow
      : "",
    user: mailConfigForEdit.mailConfigEditEnabled
      ? mailConfigForEdit.mailConfigData.user
      : [null],
    round_robin: mailConfigForEdit.mailConfigEditEnabled
      ? mailConfigForEdit.mailConfigData.round_robin
      : false,
    FIFO: mailConfigForEdit.mailConfigEditEnabled
      ? mailConfigForEdit.mailConfigData.FIFO
      : false,
    client_id: mailConfigForEdit.mailConfigEditEnabled
      ? mailConfigForEdit.mailConfigData.client_id
      : "",
    client_secret: mailConfigForEdit.mailConfigEditEnabled
      ? mailConfigForEdit.mailConfigData.client_secret
      : "",
    server_tenant_id: mailConfigForEdit.mailConfigEditEnabled
      ? mailConfigForEdit.mailConfigData.server_tenant_id
      : "",
  };

  const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError => {
    return typeof error?.status === "number";
  };

  const resetForm = () => {
    formik.setFieldValue("hostname", ""); // Reset IMAP server address
    formik.setFieldValue("server", ""); // Reset server
    formik.setFieldValue("ssl", ""); // Reset SSL
    formik.setFieldValue("team", ""); // Reset team
    formik.setFieldValue("port", ""); // Reset port
    setWorkflowSelect('');
    setSelectedTeam(""); // Reset selected team
    setTicketAssignmentType(""); // Reset ticket assignment type
    setTicketAssignmentType(""); // Reset ticket assignment type
    // Reset validation states
    formik.setErrors({});
    formik.setTouched({});
  };

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      const emailConfigPayload = {
        FIFO: ticketAssignmentType === "Active Login(RR)",
        SSL: values?.ssl,
        client_id: values?.client_id,
        client_secret: values?.client_secret,
        disposition: selectedDisposition?.id,
        email_server: values.emailServer,
        host_name: values.hostname,
        password: values?.password,
        port: values?.port,
        round_robin: ticketAssignmentType === "Round Robin",
        server: values.server,
        server_tenant_id: values?.server_tenant_id,
        team: values?.team,
        user: userSelection ? [userSelection] : [null],
        username: values.email,
        workflow: workflowSelect,
      };

      try {
        mailConfigForEdit.mailConfigEditEnabled
          ? triggerEmailConfigEditMutation({
              id: mailConfigForEdit.mailConfigData.id,
              data: emailConfigPayload,
            }).then((res: any) => {
              if (res.data) {
                toast.success(
                  "udpated the IMAP/POP mail configuration successfully"
                );
                closeModal();
              } else alert("failed to update");
            })
          : trigger(emailConfigPayload).then((res: any) => {
              if (res.data) {
                toast.success(
                  "A new IMAP based email configuration has been created successfully"
                );
                refetch();
                closeModal(true);
              } else if (res.error) {
                if ("status" in res.error && res.error.status === 400) {
                  if (
                    typeof res.error.data === "object" &&
                    res.error.data !== null
                  ) {
                    const errorData = res.error.data as { username?: string[] };
                    if (errorData.username && errorData.username.length > 0) {
                      toast.error(errorData.username[0]);
                    } else {
                      toast.error("An error occurred");
                    }
                  }
                } else {
                  console.error("Unhandled error:");
                }
              }
            });
      } catch (error) {
        console.error("Error making the POST request:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const overflowSelectHandler = (e: any) => {
    setWorkflowSelect(Number(e.target.value));
  };

  const assignmentOptions = [
    "Individual Assessment",
    "Round Robin",
    "Active Login(RR)",
    "Team Assignment",
  ];
  const servers = ["imap", "pop3"];
  const SSL_OPTIONS = [
    { id: "true", label: "True" },
    { id: "false", label: "False" },
  ];
  const emailServer = ["gmail", "microsoft"];

  useEffect(() => {
    if (data) {
      setGetTeams(data);
    }
  }, [data]);

  const teamSelectHandler = (e: any) => {
    const value = parseInt(e.target.value, 10);
    setSelectedTeam(value);
    formik.setFieldValue("team", value ? [value] : []);

    setDespositionVisible(true);
  };

  const dispositionSelectHandler = (e: any) => {
    const value = parseInt(e.target.value, 10);
    setSelectedDisposition(value);
    formik.setFieldValue("selectDesposition", value);
    setLookUpDespositionVisible(true);
  };

  useEffect(() => {
    if (isError) {
      if (isFetchBaseQueryError(error)) {
        if (error.status === 400) {
          const errorData = error.data;
          if (isDispositionErrorData(errorData)) {
            toast.error(errorData.disposition[0]);
          } else {
            toast.error("An error occurred");
          }
        }
      } else {
        toast.error("An Error occured");
      }
    }
  }, [isError, error]);

  const updateTicketAssignmentType = (e: any) => {
    const value = e.target.value;
    setTicketAssignmentType(e.target.value);
    if (value === "Individual Assessment") setUserSelectionVisiblity(true);
    else {
      setUserSelectionVisiblity(false);
      setUserSelection(null);
    }
  };

  useEffect(() => {
    selectedDisposition &&
      triggerLookupByDispositionAndTeamID({
        teamId: selectedTeam,
        disposition: selectedDisposition?.id,
      }).then((res: any) => res.data && setTheWorkflows(res.data));
  }, [selectedDisposition]);
  useEffect(() => {
    if (mailConfigForEdit.mailConfigEditEnabled) {
      setSelectedTeam(mailConfigForEdit.mailConfigData.team[0]);
      setSelectedDisposition(
        mailConfigForEdit.mailConfigData.selectDesposition
      );
      setWorkflowSelect(mailConfigForEdit.mailConfigData.workflow);
      mailConfigForEdit.mailConfigData.round_robin &&
        setTicketAssignmentType("Round Robin");
      setDespositionVisible(true);
    }
  }, []);
  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <button
          onClick={closeModal}
          type="button"
          className="close"
          aria-label="Close"
          style={{
            border: "none",
            background: "transparent",
            fontSize: "20px",
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <p
          style={{
            textAlign: "center",
            width: "100%",
            fontSize: "15px",
            fontWeight: "500",
          }}
        >
          IMAP/POP3
        </p>
      </div>
      {/* Email servet */}
      <div className="mt-2">
            <label htmlFor="email_server">Email server</label>
            <select
              className="form-control form-control-lg form-control-solid"
              name="email_server"
              id="email_server"
              onChange={(evt) => {
                setMailServer(evt.target.value);
                resetForm();
              }}
              value={mailServer}
            >
              <option value="gmail">Gmail</option>
              <option value="microsoft">Microsoft</option>
            </select>
          </div>
      {mailServer === "gmail" ? (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="required" style={{ fontSize: "14px" }}>
              Email
            </label>
            <input
              {...formik.getFieldProps("email")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid": formik.touched.email && formik.errors.email,
                },
                {
                  "is-valid": formik.touched.email && !formik.errors.email,
                }
              )}
              style={{ padding: "10px" }}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert" style={{ color: "red" }}>
                    {formik.errors.email}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="required" style={{ fontSize: "14px" }}>
              Password
            </label>
            <input
              style={{ padding: "10px" }}
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />

            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert" style={{ color: "red" }}>
                    {formik.errors.password}
                  </span>
                </div>
              </div>
            )}
          </div>
        </>
      ) : mailServer === "microsoft" ? (
        <>
          {/* Client ID Field */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="required" style={{ fontSize: "14px" }}>
              Client id
            </label>
            <input
              {...formik.getFieldProps("client_id")}
              className=
                "form-control form-control-lg form-control-solid"
               
              style={{ padding: "10px" }}
            />
            {/* {formik.touched.client_id && formik.errors.client_id && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert" style={{ color: "red" }}>
                    {formik.errors.client_id}
                  </span>
                </div>
              </div>
            )} */}
          </div>

          
          {/* Secret ID Field */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="required" style={{ fontSize: "14px" }}>
              Secret id
            </label>
            <input
              {...formik.getFieldProps("client_secret")}
              className=
                "form-control form-control-lg form-control-solid"
              //   {
              //     "is-invalid":
              //       formik.touched.client_secret && formik.errors.client_secret,
              //   },
              //   {
              //     "is-valid":
              //       formik.touched.client_secret &&
              //       !formik.errors.client_secret,
              //   }
              // )}
              style={{ padding: "10px" }}
            />
             {/* {formik.touched.client_secret && formik.errors.client_secret && (
               <div className="fv-plugins-message-container">
                 <div className="fv-help-block">
                   <span role="alert" style={{ color: "red" }}>
                     {formik.errors.client_secret}
                   </span>
                 </div>
               </div>
             )} */}
          </div>

          {/* Tenant ID Field */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="required" style={{ fontSize: "14px" }}>
              Tenant id
            </label>
            <input
              {...formik.getFieldProps("server_tenant_id")}
              className=
                "form-control form-control-lg form-control-solid"
              //   {
              //     "is-invalid":
              //       formik.touched.server_tenant_id &&
              //       formik.errors.server_tenant_id,
              //   },
              //   {
              //     "is-valid":
              //       formik.touched.server_tenant_id &&
              //       !formik.errors.server_tenant_id,
              //   }
              // )}
              style={{ padding: "10px" }}
            />
            {/* {formik.touched.server_tenant_id &&
              formik.errors.server_tenant_id && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert" style={{ color: "red" }}>
                      {formik.errors.server_tenant_id}
                    </span>
                  </div>
                </div>
              )} */}
          </div>
        </>
      ) : null}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label className="required" style={{ fontSize: "14px" }}>
          Host Name
        </label>
        <input
          style={{ padding: "10px" }}
          {...formik.getFieldProps("hostname")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid": formik.touched.hostname && formik.errors.hostname,
            },
            {
              "is-valid": formik.touched.hostname && !formik.errors.hostname,
            }
          )}
        />

        {formik.touched.hostname && formik.errors.hostname && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert" style={{ color: "red" }}>
                {formik.errors.hostname}
              </span>
            </div>
          </div>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label className="required" style={{ fontSize: "14px" }}>
          Port{" "}
        </label>
        <input
          style={{ padding: "10px" }}
          {...formik.getFieldProps("port")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid": formik.touched.port && formik.errors.port,
            },
            {
              "is-valid": formik.touched.port && !formik.errors.port,
            }
          )}
        />
        {formik.touched.port && formik.errors.port && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert" style={{ color: "red" }}>
                {formik.errors.port}
              </span>
            </div>
          </div>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label className="required" style={{ fontSize: "14px" }}>
          Server
        </label>
        <select
          style={{ padding: "10px" }}
          {...formik.getFieldProps("server")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid": formik.touched.server && formik.errors.server,
            },
            {
              "is-valid": formik.touched.server && !formik.errors.server,
            }
          )}
        >
          <option value="" selected disabled>
            {" "}
            select server
          </option>
          {servers?.map((el: any) => (
            <option>{el}</option>
          ))}
        </select>
        {formik.touched.server && formik.errors.server && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert" style={{ color: "red" }}>
                {formik.errors.server}
              </span>
            </div>
          </div>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label className="required" style={{ fontSize: "14px" }}>
          SSL
        </label>
        <select
          style={{ padding: "10px" }}
          {...formik.getFieldProps("ssl")}
          onChange={(e) => {
            const value = e.target.value === "true";
            formik.setFieldValue("ssl", value);
          }}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid": formik.touched.ssl && formik.errors.ssl,
            },
            {
              "is-valid": formik.touched.ssl && !formik.errors.ssl,
            }
          )}
        >
          <option value="" selected disabled>
            Choose SSL
          </option>
          {SSL_OPTIONS?.map((el: any) => (
            <option value={el?.id} key={el.id}>
              {el.label}
            </option>
          ))}
        </select>
        {formik.touched.ssl && formik.errors.ssl && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert" style={{ color: "red" }}>
                {formik.errors.ssl}
              </span>
            </div>
          </div>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label className="required" style={{ fontSize: "14px" }}>
          Team
        </label>
        <select
          onChange={teamSelectHandler}
          value={selectedTeam}
          style={{ padding: "10px" }}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid": formik.touched.team && formik.errors.team,
            },
            {
              "is-valid": formik.touched.team && !formik.errors.team,
            }
          )}
        >
          <option disabled selected value="">
            Choose Team
          </option>
          {getTeams?.map((t: any) => (
            <option key={t.id} value={t.id}>
              {t.name}
            </option>
          ))}
        </select>
        {formik.touched.team && formik.errors.team && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert" style={{ color: "red" }}>
                {formik.errors.team}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="row g-5 g-xl-8 mb-6">
        {/*disposition feed*/}
        <div className={`col-xl-12 d-flex justify-content-between`}>
          {dispositionVisible && (
            <div className="d-flex flex-column">
              <label className="required" style={{ fontSize: "14px" }}>
                Disposition
              </label>
              <DispositionSelector
                theDispositions={emailDisposData}
                selectedDisposition={selectedDisposition}
                setSelectedDisposition={setSelectedDisposition}
              />
              {selectedDisposition === 0 && (
                <div className="fv-plugins-message-container text-danger">
                  <span role="alert">Disposition has not been provided</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label>Select Workflow</label>
          <select
            className="form-control form-control-lg form-control-solid"
            value={workflowSelect}
            onChange={overflowSelectHandler}
          >
            <option value={-1}>select a workflow</option>
            {Array.isArray(theWorkflows) ? (
              theWorkflows?.map((workflow: any) => (
                <>
                  <option value={workflow.id}>{workflow.name}</option>
                </>
              ))
            ) : (
              <option value={theWorkflows?.id}>{theWorkflows?.name}</option>
            )}
          </select>
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "10px",
          marginTop: "10px",
        }}
      >
        {assignmentOptions?.map((el: any) => (
          <div>
            <input
              className="form-check-input"
              type="radio"
              value={el}
              onChange={updateTicketAssignmentType}
              checked={el === ticketAssignmentType}
            />
            <label
              className="form-check-label mx-2"
              style={{ whiteSpace: "nowrap" }}
            >
              {el}
            </label>
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col">
          {userSelectionVisiblity && (
            <div className="d-flex flex-column">
              <label>Select User</label>
              <select
                className="form-control form-control-lg form-control-solid"
                value={userSelection}
                onChange={(evt) => setUserSelection(Number(evt.target.value))}
              >
                <option value={-1}>select a user</option>
                {Array.isArray(usersData) &&
                  usersData?.map((user: any) => (
                    <>
                      <option value={user.id}>{user.username}</option>
                    </>
                  ))}
              </select>
              {userSelection === undefined && usersData?.length > 0 && (
                <div className="fv-plugins-message-container text-danger">
                  <span role="alert">user has not been provided</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <button
          type="submit"
          style={{ width: "100%", textAlign: "center" }}
          className="btn btn-primary"
        >
          {emailConfigLoading ? (
            <>
              Submitting... <Loader />
            </>
          ) : (
            "Submit"
          )}
        </button>
      </div>{" "}
    </form>
  );
};

export default ModalEmailConfig;
