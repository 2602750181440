import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

// const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = "http://localhost:8000/api/"

export const loginApi = createApi({
  reducerPath: "login",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: "v1/auth/",
        method: "POST",
        body,
      }),
    }),

    passwordCheck: builder.query({
      query: () => ({
        url: "v1/users/me/",
        method: "GET",
      }),
    }),

    resetPassword: builder.mutation({
      query: (body) => ({
        url: "v1/users/change-password/",
        method: "POST",
        body,
      }),
    }),

    logout: builder.mutation({
      query: (body) => ({
        url: "v1/logout/",
        method: "DELETE",
        body,
      }),
    }),

    forgotPassword: builder.mutation({
      query: (body) => ({
        url: "v1/forgot-password",
        method: "POST",
        body,
      }),
    }),

    selfReleaseSession: builder.query({
      query: (selectedEditId) => {
        return {
          url: `/v1/users/release-session/?id=${selectedEditId}&`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  usePasswordCheckQuery,
  useResetPasswordMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useLoginMutation,
  useSelfReleaseSessionQuery,
} = loginApi;
