import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const ticketListApi = createApi({
  reducerPath: "ticketList",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    userTicket: builder.query({
      query: (teamIdFromLocalStorage) => ({
        url: `v1/tickets/ticket-name/?team_id=${teamIdFromLocalStorage}`,
        method: "GET",
      }),
    }),
    ticketPagination: builder.query({
      query: ({
        currentPage,
        selectedTeamId,
        selectedWorkFlow,
        selectedFilterAndValue1,
        value,
        storeKey,
      }) => {
        const baseUrl = `v1/tickets/ticket/?page=${currentPage}&ordering=-created&action__disposition__get_ticket_name=${selectedWorkFlow}&`;
        const teamParam =
          selectedTeamId === "My Team"
            ? ""
            : `&action__assignee=${selectedTeamId}`;
        const statusParam = getStatusParam(storeKey, value);
        const filterParams = getFilterParams(selectedFilterAndValue1);

        return `${baseUrl}${teamParam}${statusParam}${filterParams}`;
      },
    }),

    telephonyTicketPagination: builder.query({
      query: ({
        currentPage,
        selectedTeamId,
        selectedWorkFlow,
        selectedFilterAndValue1,
        value,
        storeKey,
        mobileNumber
      }) => {
        const baseUrl = `v1/tickets/ticket/?page=${currentPage}&ordering=-created&action__disposition__get_ticket_name=${selectedWorkFlow}&action__contact__phone_number__exact=${mobileNumber}&get_tickets_for=telephony&`;
        const teamParam =
          selectedTeamId === "My Team"
            ? ""
            : `&action__assignee=${selectedTeamId}`;
        const statusParam = getStatusParam(storeKey, value);
        const filterParams = getFilterParams(selectedFilterAndValue1);

        return `${baseUrl}${teamParam}${statusParam}${filterParams}`;
      },
    }),


    ticketPagination2: builder.query({
      query: ({
        currentPage,
        selectedTeamId,
        selectedWorkFlow,
        selectedFilterAndValue1,
      }) => {
        const baseUrl = `v1/tickets/ticket/?page=${currentPage}&ordering=-created&action__disposition__get_ticket_name=${selectedWorkFlow}&`;
        const teamParam =
          selectedTeamId === "My Team"
            ? ""
            : `&action__assignee=${selectedTeamId}`;
        const filterParams = getFilterParams(selectedFilterAndValue1);

        return `${baseUrl}${teamParam}${filterParams}`;
      },
    }),
    ticketWorkflow: builder.query({
      query: ({ selectedWorkFlow, selectedFilterAndValue1 }) => {
        const baseUrl = `v1/tickets/bin-count/workflow/${selectedWorkFlow}/?`;
        const filterParams = getFilterParams(selectedFilterAndValue1);

        return `${baseUrl}${filterParams}`;
      },
    }),
  }),
});

export const {
  useTelephonyTicketPaginationQuery,
  useUserTicketQuery,
  useTicketPaginationQuery,
  useTicketPagination2Query,
  useTicketWorkflowQuery,
} = ticketListApi;

// Helper functions
function getStatusParam(storeKey: string, statusValue: string) {
  switch (storeKey) {
    case "All":
      return "";
    case "active":
    case "reopen":
      return `&status_type__contains=${storeKey}`;
    case "wip":
      return `&status__status__contains=${statusValue}`;
    case "closed":
      return `&closed_status__status__contains=${statusValue}`;
    default:
      return `&status__status__contains=${statusValue}`;
  }
}

function getFilterParams(selectedFilterAndValue1: object) {
  const filterParams = Object.entries(selectedFilterAndValue1)
    .filter(([key, value]) => value !== "")
    .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  return filterParams ? `&${filterParams}` : "";
}
