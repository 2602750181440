import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const dashboard = createApi({
    reducerPath: "dashboard",
    baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        dashboard: builder.query({
            query: (selectedTeam) => {
                return {
                    url: `v1/common/dashboard/?team=${selectedTeam}&`,
                    method: "GET",
                };
            },
        }),

        workflow: builder.query({
            query: (selectedWorkFlow) => {
                return {
                    url: `v1/tickets/bin-count/workflow/${selectedWorkFlow}/?`,
                    method: "GET",
                };
            },
        }),

        channelWiseTicket: builder.query({
            query: ({
                selectTeam,
                selectWorkFlow,
                graphStartDate,
                graphEndDate,
            }) => {
                return {
                    url: `/v1/common/ticket-channel-count/?type=ticket_to_channel&team=${selectTeam}&workflow=${selectWorkFlow}&start_date=${graphStartDate}&end_date=${graphEndDate}&`,
                    method: "GET",
                };
            },
        }),

        ticketInterval: builder.query({
            query: ({
                selectedTeam,
                selectedWorkFlow,
                graphStartDate,
                graphEndDate,
        
            }) => {
                return {
                    url: `v1/common/dashboard/ticket-volume/?team=${selectedTeam}&workflow=${selectedWorkFlow}&start_date=${graphStartDate}&end_date=${graphEndDate}&`,
                    method: "GET",
                };
            },
        }),

        ticketWorkflow: builder.query({
            query: (selectedTeam) => {
                return {
                    url: `v1/tickets/ticket-name/?team=${selectedTeam}&`,
                    method: "GET",
                };
            },
        }),

        ticketWorkflowId: builder.query({
            query: (selectedTeam) => {
                return {
                    url: `v1/tickets/ticket-name/?team_id=${selectedTeam}&`,
                    method: "GET",
                };
            },
        }),

        // Email Dashboard
        emailVolume: builder.query({
            query: ({
                email_type = "active", // default to "active" if not provided
                start_date,
                end_date,
                team
            }) => {
                return {
                    url: `v1/common/dashboard/email-volume/`,
                    method: "GET",
                    params: {
                        email_type: email_type === "active" ? "sent" : "received", // conditional check
                        start_date,
                        end_date,
                        team
                    },
                };
            },
        }),

        ticketVolume: builder.query({
            query: ({
                email_type,
                start_date,
                end_date,
                team_id

            }) => {
                return {
                    url: `v1/common/dashboard/ticket-volume/`,
                    method: "GET",
                    params: {
                        email_type,
                        start_date,
                        end_date,
                        team_id
                    },
                };
            },
        }),
     
      getAllDashboardData: builder.query({
            query: ({ start_date, end_date, team,dashboard_type }) => {
                return {
                    url: `v1/common/new-dashboard/`,
                    method: "GET",
                    params: {
                        start_date,
                        end_date,
                        team,
                        dashboard_type
                    },
                };
            },
        }),

        disposition: builder.query({
            query: (teamId) => ({
                url: `v1/common/dashboard/top_dispositions/?&team_id=${teamId}`,
                method: "GET"
            })
        }),

    }),
});

export const {
    useTicketWorkflowIdQuery,
    useDashboardQuery,
    useWorkflowQuery,
    useChannelWiseTicketQuery,
    useTicketIntervalQuery,
    useTicketWorkflowQuery,
    useEmailVolumeQuery,
    useGetAllDashboardDataQuery,
    useDispositionQuery
} = dashboard;
