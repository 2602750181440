import { Route, Routes, Outlet } from "react-router-dom";
import TicketLists from "./Tickets";
import TicketListInfo from "./ticketLists/TicketListInfo";

const TicketIndex = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path="lists" element={<TicketLists />} />
        <Route
          path="/information/:uuid"
          element={
            <>
              <TicketListInfo />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default TicketIndex;
