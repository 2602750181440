import React, { createContext, useContext, useState } from 'react';

const NotificationContext = createContext<any>(null);

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [socketData, setSocketData] = useState(null);

  const openNotificationModal = (data: any) => {
    setSocketData(data);
    setIsNotificationModalOpen(true);
  };

  const closeNotificationModal = () => {
    setIsNotificationModalOpen(false);
    setSocketData(null);
  };

  return (
    <NotificationContext.Provider
      value={{ isNotificationModalOpen, socketData, openNotificationModal, closeNotificationModal }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
