import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const ChannelHistory = createApi({
  reducerPath: "channelHistory",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    channelHistory: builder.query({
      query: (id) => {
        return {
          url: `v1/customers/actions/?contact=${id}&ordering=-created&`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useChannelHistoryQuery } = ChannelHistory;
