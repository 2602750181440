import ReactApexChart from "react-apexcharts";
import { useState } from 'react';

const initialData = {
    series: [14, 22, 20, 28, 10],
    options: {
        chart: {
            width: '50%',
            type: 'pie',
        },
        labels: ["Logged In", "On Call", "Ready", "Paused", "ACW"],
        theme: {
            monochrome: {
                enabled: false
            }
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    offset: -5
                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter(val, opts) {
                return val.toFixed(0) + '%';
            },
            textAnchor: 'middle',
            distributed: false,
            offsetX: 0,
            offsetY: 0,
            style: {
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: undefined
            },
            background: {
                enabled: true,
                foreColor: '#fff',
                padding: 4,
                borderRadius: 2,
                borderWidth: 0,
                borderColor: '#fff',
                opacity: 0.9,
                dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    color: '#000',
                    opacity: 0.45
                }
            },
            dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45
            }
        },
        legend: {
            show: true,
            position: 'bottom', // or 'top', 'left', 'right'
            horizontalAlign: 'center', // or 'left', 'right'
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            labels: {
                colors: '#000', // legend label color
                useSeriesColor: true
            },
            markers: {
                width: 10,
                height: 10,
                strokeWidth: 0,
                radius: 12,
                offsetX: 0,
                offsetY: 0
            },
            itemMargin: {
                horizontal: 5,
                vertical: 5
            }
        }
    }
};

const PieChartRTM = () => {
    const [state, setState] = useState<any>(initialData);

    return (
        <div>
            <div id="chart">
                <ReactApexChart options={state.options} series={state.series} type="pie" height="250px" />
            </div>
            <div id="html-dist"></div>
        </div>
    );
};

export default PieChartRTM;