import React from 'react'
import { Skeleton, Box, Typography } from "@mui/material";


const LoadingSkeleton = () => {
    return (
        // <Box sx={{ width: "100%", padding: 2, display: "flex", gap: "20px"
        //  }}>
   
          
  
        //   {/* <Skeleton variant="rectangular" width="10%" height={10}  /> */}
  
        // </Box>
          <Skeleton animation="wave" variant="text" width="150%" height={30} />
          
      );
    };

export default LoadingSkeleton