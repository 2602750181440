import { toast } from "react-toastify";
interface IProps {
  closeModal: any;
  deletionIdentifier: any;
  messageBody: any;
  deleteAPItrigger: any;
}


export const DeleteMessageBody = ({ bodyParams }) => {
  return (
    <div>
      {bodyParams.descriptionPromptEnabled ? (
        <>
          <p className="fs-5 fw-bold">
            Are you sure you want to delete the following{" "}
            {bodyParams.deletionOf}?
          </p>
          <div className="card-text">
            Title: <strong>{bodyParams.deletionItemTitle}</strong>
          </div>
          <div className="card-text">
            Description: <strong>{bodyParams.deletionItemDescription}</strong>
          </div>
        </>
      ) : (
        <>
          <p className="fs-5 fw-bold">Are you sure?</p>
          <div className="card-text">
            <span className="text-danger">Caution</span>:{" "}
            <strong>
              Please confirm to delete the {bodyParams.deletionOf}{" "}
              {bodyParams?.deletionItemCautionMsg}
            </strong>
          </div>
        </>
      )}
    </div>
  );
};

const CommonDeleteModal: React.FC<IProps> = ({
  closeModal,
  deleteAPItrigger,
  deletionIdentifier,
  messageBody,
}) => {
  const confirmDeleteItem = async (event: any) => {
    const response = await deleteAPItrigger(deletionIdentifier);
    if (response) {
      response.data === true && toast.success("deletion successful");
      closeModal();
    }
  };
console.log(deletionIdentifier, "Dcsdcdsc")

  return (
    <>
      <div className="card-body">
        {messageBody}
        <div className="d-flex justify-content-end mt-10">
          <button
            type="reset"
            className="btn btn-sm btn-white btn-active-light-primary me-2"
            onClick={() => closeModal()}
          >
            {" "}
            Cancel{" "}
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-danger"
            onClick={confirmDeleteItem}
          >
            Delete
          </button>
        </div>
      </div>
    </>
  );
};

export default CommonDeleteModal;
