import { Route, Routes, Outlet, Navigate, useLocation } from 'react-router-dom'
import Mail from './inbox/Mail'
import Inbox from './inbox/Inbox'
import EmailListMenu from './EmailListMenu'
import EmailHeader from '../../components/email/EmailHeader'
import Bin from './Bin'
import Compose from './Compose'
import Sent from './sent/Sent'
import Starred from './starred/Starred'

const EmailIndex = () => {
    const location = useLocation();
    const isMailRoute = location.pathname.includes('/inbox/');

    return (
        <div id="kt_app_content_container" className="app-container mx-5 mb-2">
            {/*begin::Products*/}
            <div className="card card-flush">
                {/*begin::Card header*/}
                <EmailHeader />
                <div className={isMailRoute ? '' : 'd-flex flex-column flex-lg-row p-6'}>
                    <Routes>
                        <Route path='inbox/:uuid' element={<Mail />} />
                        <Route element={<EmailLayout />}>
                            <Route path='bin' element={<Bin />} />
                            <Route path='inbox' element={<Inbox />} />
                            <Route path='starred' element={<Starred />} />
                            <Route path='compose' element={<Compose />} />
                            <Route path='sent' element={<Sent />} />
                            <Route index element={<Navigate to='inbox' />} />
                            
                        </Route>
                    </Routes>
                </div>
            </div>
        </div>
    )
}

const EmailLayout = () => (
    <>
        <EmailListMenu />
        <div className='flex-lg-row-fluid '>
            <Outlet />
        </div>
    </>
)

export default EmailIndex
