import React, { useState } from "react";
import Modal from "react-modal";
import TrailEditModal from "./TrailEditModal";

const customModalStyles:any = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "100%",
    maxHeight: "600px",
    width: "100%", // Default to full width
    maxWidth: "600px", // Max width for larger screens
    margin: "auto",
    overflow: "hidden",
    overflowY: "auto",
  },
};

interface IProps {
  index: number;
  id: string; // Use appropriate type based on your data
  auditTrailData: any;
  toggleMenu:any
  openMenuIndex:any
  dropdownRef:any
  setOpenMenuIndex:any
}

const TrailActionMenu: React.FC<IProps> = ({ index, id, auditTrailData,toggleMenu,openMenuIndex,dropdownRef,setOpenMenuIndex}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);


  const openEditModal = () => {
    setIsEditModalOpen(true);
    setOpenMenuIndex(null);

  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  return (
    <div className="position-relative">
      <button
        onClick={() => toggleMenu(index)}
        className="btn btn-sm btn-icon btn-active-light-primary"
      >
        <i className="bi bi-three-dots-vertical fs-3"></i>
      </button>
      {openMenuIndex === index && (
        <div className="dropdown-menu" ref={dropdownRef}  style={{ display: "block" }}>
          <button onClick={openEditModal} className="dropdown-item">
            Preview
          </button>
        </div>
      )}
      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={closeEditModal}
        style={customModalStyles}
        contentLabel="Edit Trail"
      >
        <TrailEditModal
          closeEditModal={closeEditModal}
          auditTrailData={auditTrailData}
          id={id}
        />
      </Modal>
    </div>
  );
};

export default TrailActionMenu;
