import React, { useEffect, useState } from "react";
import { Button, CloseButton, Modal, Row, Col, Alert } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { Checkbox, FormControlLabel } from "@mui/material";

interface IProps {
    closeFilterModal: () => void;
    selectedFilter: any; // Ensuring correct type
    setSelectedFilter: any;
    handleSubmit: (selectedFilterValues: Record<string, string>) => void;
    setSelectedFilters1:any
}

const CampaignFilter: React.FC<IProps> = ({
    closeFilterModal,
    selectedFilter,
    setSelectedFilter,
    handleSubmit,
    setSelectedFilters1
}) => {
    const [error, setError] = useState<string | null>(null);
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  

    // Handle checkbox toggle for filters
    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        console.log(`Checkbox changed: ${value}`); // Debug log

        setSelectedFilters((prevSelectedFilters) => {
            let updatedFilters;
            if (prevSelectedFilters.includes(value)) {
                // Remove filter
                updatedFilters = prevSelectedFilters.filter(
                    (filter) => filter !== value
                );
                console.log("Removing filter", updatedFilters); // Debug log
                setSelectedFilter((prev: any) => {
                    const newSelectedFilter = { ...prev };
                    delete newSelectedFilter[value]; // Remove the filter value from selectedFilter
                    return newSelectedFilter;
                });
            } else {
                // Add filter
                updatedFilters = [...prevSelectedFilters, value];
                console.log("Adding filter", updatedFilters); // Debug log
                setSelectedFilter((prev) => ({
                    ...prev,
                    [value]: "", // Add the key with an empty value when adding the filter
                }));
            }
            return updatedFilters;
        });
    };

    
    // Handle text input changes for specific filters
    const handleFilterValueChange = (key: string, value: string) => {
        console.log(`Value changed for ${key}: ${value}`); // Debug log
        setSelectedFilter((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    // Reset all filters
    const resetFilters = () => {
        setSelectedFilters([]);
        setSelectedFilter({
            title__icontains: "",
            event_description__icontains: "",
        });
        setSelectedFilters1([]);
     
        setError(null);
    };

    // Apply filters and validate input
    const handleApplyFilters = () => {
        handleSubmit(selectedFilter);
    };


    useEffect(() => {
        if (selectedFilter) {
            const filters = Object.entries(selectedFilter)
                .filter(([key, value]) => value !== "") // Only keep key-value pairs where the value is not empty
                .map(([key]) => key); // Extract only the keys
    
            setSelectedFilters(filters); // Update the selectedFilters state with the filtered keys
        }
    }, []);

    const filters = [
        { label: "Title Name", value: "title__icontains" },
        { label: "Description", value: "event_description__icontains" },
    ];

    return (
        <Modal show={true} onHide={closeFilterModal} centered>
            <Modal.Header>
                <Modal.Title className="fw-bold">Filters</Modal.Title>
                <CloseButton onClick={closeFilterModal} />
            </Modal.Header>
            <Modal.Body>
                {error && <Alert variant="danger">{error}</Alert>}
                <div className="px-3 py-2">
                    {filters.map((filter) => (
                        <Row key={filter.value} className="mb-3 align-items-center">
                            <Col xs={12} md={4} className="text-nowrap">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedFilters.includes(filter.value)}
                                            onChange={handleFilterChange}
                                            value={filter.value}
                                            color="primary"
                                        />
                                    }
                                    label={filter.label}
                                />
                            </Col>
                            <Col xs={12} md={8}>
                                {(selectedFilters.includes(filter.value) || selectedFilter[filter.value]?.trim() !== "") && (
                                    <TextField
                                        label={filter.label}
                                        fullWidth
                                        value={selectedFilter[filter.value] || ""}
                                        onChange={(e) =>
                                            handleFilterValueChange(
                                                filter.value,
                                                e.target.value
                                            )
                                        }
                                        variant="outlined"
                                    />
                                )}
                            </Col>
                        </Row>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={resetFilters}>
                    Reset
                </Button>
                <Button variant="primary" onClick={handleApplyFilters}>
                    Apply
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CampaignFilter;
