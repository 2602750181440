import { setupListeners } from "@reduxjs/toolkit/query";
import { configureStore } from "@reduxjs/toolkit";
import { loginApi } from "../Services/Auth/Auth";
import { userSettingsApi } from "../Services/settingsAPIs/UserSettingsAPI";
import { commonAPI } from "../Services/settingsAPIs/CommonAPI";
import { ticketSettingsApi } from "../Services/settingsAPIs/TicketSettingsAPI";
import { ticketDetailApi } from "../Services/ticket/TicketDetail";
import { CreateTicket } from "../Services/ticket/CreateTicket";
import { ticketListApi } from "../Services/ticket/TicketList";
import { Lookup_by_disposition } from "../Services/disposition/Lookup_by_disposition";
import { UsersByTeamId } from "../Services/ticket/users/UsersByTeamId";
import { dashboard } from "../Services/dashboard/dashboard";
import { GetTemplate } from "../Services/ticket/Templates";
import { MessagesApi } from "../Services/Messages/Messages";
import { emailConfigSettingsApi } from "../Services/settingsAPIs/EmailConfiguration";
import { AuditTrail } from "../Services/ticket/Audit-Trail";
import { ChannelHistory } from "../Services/ticket/ChannelHistory";
import { createTask } from "../Services/ticket/CreateTask";
import { Task } from "../Services/ticket/Task";
import { accountLogin } from "../Services/accountLogin/AccountLogin";
import { GetUsers } from "../Services/user/Users";
import { customerApi } from "../Services/customer/Customer";
import { generalApi } from "../Services/general/General";
import { frontEndCustomerApi } from "../Services/customer/FrontendCustomer";
import { getReportTicketsApi } from "../Services/reports/tickets/ReportTickets";
import { emailTemplate } from "../Services/email/EmailTemplate";
import { emailInbox } from "../Services/email/EmailInbox";
import { BulkAssign } from "../Services/ticket/BulkAssign";
import { TicketAccessControl } from "../Services/ticketAccessControl/TicketAccessControl";
import { Activity_Active } from "../Services/activity/Active";
import { RecentTasks } from "../Services/activity/Recent";
import { Telephony } from "../Services/Telephony/Telephony";
import { CampaignMapping } from "../Services/campaign/CampaignMapping";
import { TelephonyFrontend } from "../Services/Telephony/TelephonyFrontend";import { campaignListApi } from "../Services/campign/campignList";
import { campaignDetailApi } from "../Services/campign/CampaignDetail";



export const store = configureStore({
  reducer: {
    [loginApi.reducerPath]: loginApi.reducer,
    [userSettingsApi.reducerPath]: userSettingsApi.reducer,
    [commonAPI.reducerPath]: commonAPI.reducer,
    [ticketSettingsApi.reducerPath]: ticketSettingsApi.reducer,
    [ticketListApi.reducerPath]: ticketListApi.reducer,
    [ticketDetailApi.reducerPath]: ticketDetailApi.reducer,
    [CreateTicket.reducerPath]: CreateTicket.reducer,
    [Lookup_by_disposition.reducerPath]: Lookup_by_disposition.reducer,
    [UsersByTeamId.reducerPath]: UsersByTeamId.reducer,
    [dashboard.reducerPath]: dashboard.reducer,
    [GetTemplate.reducerPath]: GetTemplate.reducer,
    [MessagesApi.reducerPath]: MessagesApi.reducer,
    [emailConfigSettingsApi.reducerPath]: emailConfigSettingsApi.reducer,
    [AuditTrail.reducerPath]: AuditTrail.reducer,
    [ChannelHistory.reducerPath]: ChannelHistory.reducer,
    [createTask.reducerPath]: createTask.reducer,
    [Task.reducerPath]: Task.reducer,
    [accountLogin.reducerPath]: accountLogin.reducer,
    [GetUsers.reducerPath]: GetUsers.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [getReportTicketsApi.reducerPath]: getReportTicketsApi.reducer,
    [generalApi.reducerPath]: generalApi.reducer,
    [frontEndCustomerApi.reducerPath]: frontEndCustomerApi.reducer,
    [emailTemplate.reducerPath]: emailTemplate.reducer,
    [emailInbox.reducerPath]: emailInbox.reducer,
    [BulkAssign.reducerPath]: BulkAssign.reducer,
    [TicketAccessControl.reducerPath]: TicketAccessControl.reducer,
    [Activity_Active.reducerPath]: Activity_Active.reducer,
    [Telephony.reducerPath]: Telephony.reducer,
    [RecentTasks.reducerPath]: RecentTasks.reducer,
    [CampaignMapping.reducerPath]: CampaignMapping.reducer,
    [TelephonyFrontend.reducerPath]: TelephonyFrontend.reducer,
    [campaignListApi.reducerPath]: campaignListApi.reducer,
    [campaignDetailApi.reducerPath]: campaignDetailApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(loginApi.middleware)
      .concat(userSettingsApi.middleware)
      .concat(commonAPI.middleware)
      .concat(ticketSettingsApi.middleware)
      .concat(ticketListApi.middleware)
      .concat(ticketDetailApi.middleware)
      .concat(CreateTicket.middleware)
      .concat(Lookup_by_disposition.middleware)
      .concat(UsersByTeamId.middleware)
      .concat(dashboard.middleware)
      .concat(GetTemplate.middleware)
      .concat(MessagesApi.middleware)
      .concat(emailConfigSettingsApi.middleware)
      .concat(AuditTrail.middleware)
      .concat(ChannelHistory.middleware)
      .concat(createTask.middleware)
      .concat(Task.middleware)
      .concat(accountLogin.middleware)
      .concat(GetUsers.middleware)
      .concat(customerApi.middleware)
      .concat(generalApi.middleware)
      .concat(frontEndCustomerApi.middleware)
      .concat(getReportTicketsApi.middleware)
      .concat(emailTemplate.middleware)
      .concat(emailInbox.middleware)
      .concat(BulkAssign.middleware)
      .concat(TicketAccessControl.middleware)
      .concat(Activity_Active.middleware)
      .concat(Telephony.middleware)
      .concat(RecentTasks.middleware)
      .concat(TelephonyFrontend.middleware)
      .concat(CampaignMapping.middleware)
      .concat(campaignListApi.middleware)
      .concat(campaignDetailApi.middleware)


});
setupListeners(store.dispatch);
