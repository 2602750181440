import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";
import { get, update } from "lodash";

export const commonAPI = createApi({
  reducerPath: "common",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    teams: builder.query({
      query: () => ({
        url: "v1/users/teams/",
        method: "GET",
      }),
    }),

    teamsPage: builder.query({
      query: ({ rowsPerPage, page, search }) => ({
        url: `v1/users/teams/?name__icontains=${search}&page_size=${rowsPerPage}&page=${page}`,
        method: "GET",
      }),
    }),



    teamsPagination: builder.query({
      query: ({ page, rows }) => ({
        url: `v1/users/teams/?paginate=true&page=${page}&page_size=${rows}`,
        method: "GET",
      }),
    }),

    teamConfig: builder.query({
      query: (teamId) => ({
        url: `v1/common/smtp-server-configuration/team-config/${teamId}/`,
        method: "GET",
      }),
    }),
    teamsAdd: builder.mutation({
      query: (body) => ({
        url: "v1/users/teams/",
        method: "POST",
        body,
      }),
    }),

    team: builder.mutation({
      query: (team) => ({
        url: `v1/users/teams/${team}`,
        method: "GET",
      }),
    }),

    setTeamInfo: builder.mutation({
      query: ({ teamId, body }) => ({
        url: `v1/users/teams/${teamId}`,
        method: "PUT",
        body,
      }),
    }),

    teamQ: builder.query({
      query: (team) => ({
        url: `v1/users/teams/${team}`,
        method: "GET",
      }),
    }),

    me: builder.mutation({
      query: () => ({
        url: "v1/users/me/",
        method: "GET",
      }),
    }),

    meLogin: builder.query({
      query: (body) => ({
        url: "v1/users/me/",
        method: "GET",
      }),
    }),

    mee: builder.query({
      query: () => ({
        url: "v1/users/me/",
        method: "GET",
      }),
    }),

    workflows: builder.mutation({
      query: () => ({
        url: "v1/tickets/ticket-name/",
        method: "GET",
      }),
    }),

    workflowsQ: builder.query({
      query: () => ({
        url: "v1/tickets/ticket-name/",
        method: "GET",
      }),
    }),

    disposition: builder.mutation({
      query: (teamId) => ({
        url: `v1/common/disposition/?team_id=${teamId}`,
        method: "GET",
      }),
    }),

    newContact: builder.mutation({
      query: (body) => ({
        url: `v1/customers/contacts/`,
        method: "POST",
        body,
      }),
    }),

    updateTeam: builder.mutation({
      query: ({ teamId, body }) => ({
        url: `v1/users/teams/${teamId}/`,
        method: "PUT",
        body,
      }),
    }),

    Emaildisposition: builder.query({
      query: (teamId) => ({
        url: `v1/common/disposition/?team_id=${teamId}`,
        method: "GET",
      }),
    }),

    dynamicFields: builder.mutation({
      query: () => ({
        url: "v1/common/dynamic-field-definitions/?model_name=ticket_name&",
        method: "GET",
      }),
    }),
    searchContactByEmail: builder.mutation({
      query: (email) => ({
        url: `v1/customers/contacts/search-by-email/?email=${email}`,
        method: "GET",
      }),
    }),
    lookupByDispositionAndTeamId: builder.mutation({
      query: ({ teamId, disposition }) => ({
        url: `v1/common/disposition/${disposition}/lookup_by_disposition/?team_id=${teamId}&`,
        method: "GET",
      }),
    }),
    statuses: builder.mutation({
      query: (dispositionId) => ({
        url: `v1/common/disposition/${dispositionId}/lookup_by_disposition/`,
        method: "GET",
      }),
    }),
    getStatuses: builder.query({
      query: (dispositionId) => ({
        url: `v1/common/disposition/${dispositionId}/lookup_by_disposition/`,
        method: "GET",
      }),
    }),
    status: builder.query({
      query: (workflowId) => ({
        url: `v1/tickets/ticket-name/${workflowId}/`,
        method: "GET",
      }),
    }),

    tags: builder.query({
      query: () => ({
        url: `v1/customers/tags/`,
        method: "GET",
      }),
    }),

    customer: builder.query({
      query: (id) => {
        return {
          url: `/v1/customers/contacts/${id}/`,
          method: "GET",
        };
      },
    }),

    customerUpdate: builder.mutation({
      query: (body) => {
        return {
          url: `/v1/customers/contacts/${body?.id}/`,
          method: "PATCH",
          body,
        };
      },
    }),
    accountsUpdate: builder.mutation({
      query: (body) => {
        return {
          url: `/v1/customers/accounts/${body?.id}/`,
          method: "PUT",
          body,
        };
      },
    }),

    userDeleteTeam: builder.mutation({
      query: (body) => {
        return {
          url: `/v1/users/teams/${body?.id}/`,
          method: "DELETE",
        };
      },
    }),

    deleteUserDynamicFields: builder.mutation({
      query: (id) => {
        return {
          url: `v1/common/dynamic-field-definitions/${id.id}/`,
          method: "DELETE",
        };
      },
    }),

    editUserDynamicFields: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `v1/common/dynamic-field-definitions/${id}/`,

          method: "PUT",
          body,
        };
      },
    }),

    userDynamicFied: builder.mutation({
      query: (body) => ({
        url: "v1/common/dynamic-field-definitions/",
        method: "POST",
        body,
      }),
    }),

    userDynamicFields: builder.query({
      query: (name) => {
        return {
          url: `v1/common/dynamic-field-definitions/?model_name=${name}&`,
          method: "GET",
        };
      },
    }),

    usersTeam: builder.mutation({
      query: (body) => ({
        url: "v1/users/teams/",
        method: "POST",
        body,
      }),
    }),

    changeStatus: builder.query({
      query: (id) => {
        return {
          url: `v1/common/disposition/${id}/lookup_by_disposition/?`,
          method: "GET",
        };
      },
    }),
    dynamicFieldDefinitions: builder.query({
      query: (modelName) => {
        return {
          url: `v1/common/dynamic-field-definitions/?model_name=${modelName}&`,
          method: "GET",
        };
      },
    }),
    updateDynamicFieldDefinitions: builder.mutation({
      query: (body) => {
        return {
          url: `v1/common/dynamic-field-definitions/`,
          method: "POST",
          body,
        };
      },
    }),
    UsersDynamicFields: builder.query({
      query: () => {
        return {
          url: `v1/common/dynamic-field-definitions/?model_name=userprofile&`,
          method: "GET",
        };
      },
    }),
    getTicketTagSLADetails: builder.query({
      query: (tagId) => {
        return {
          url: `v1/common/tag-name/${tagId}/ticket_tag`,
          method: "GET",
        };
      },
    }),
    getTicketTagSLAEscalationLevels: builder.query({
      query: (tagId) => {
        return {
          url: `v1/common/tag-level/${tagId}/ticket_tag/`,
          method: "GET",
        };
      },
    }),

    createCheckBoxLevel: builder.mutation({
      query: (body) => {
        return {
          url: `v1/common/dynamic-field-checkbox_multiple_choices/`,
          method: "POST",
          body,
        };
      },
    }),

    pieChartAPI: builder.query({
      query: (body) => {
        return {
          url: `v1/common/dashboard/tickets-by-status/?start_date=${body?.startDate}&end_date=${body?.endDate}`,
          method: "GET",
        };
      },
    }),

    editDropwDown: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `v1/common/dynamic-field-choices/${id}/`,
          method: "PUT",
          body,
        };
      },
    }),

    editCheckBox: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `v1/common/dynamic-field-checkbox_multiple_choices/${id}/`,
          method: "PUT",
          body,
        };
      },
    }),

    bulkSorting: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `v1/common/dynamic-field-definitions/bulk-sort/`,
          method: "POST",
          body,
        };
      },
    }),

    //WhatsApp Configuration
    getTenantData: builder.query({
      query: () => {
        return {
          url: `v1/common/tenant-update/`,
          method: "GET",
        };
      },
    }),
    updateTenantData: builder.mutation({
      query: (body) => {
        return {
          url: `v1/common/tenant-update/`,
          method: "PATCH",
          body,
        };
      },
    }),
    whatsAppConfiguration: builder.mutation({
      query: (body) => {
        return {
          url: `v1/common/whatsapp-config/`,
          method: "POST",
          body,
        };
      },
    }),

    getWhatsAppConfiguration: builder.query({
      query: ({ }) => {
        return {
          url: `v1/common/whatsapp-config/`,
          method: "GET",
        };
      },
    }),
    deleteWhatsAppConfiguration: builder.mutation({
      query: ({ fieldsData }) => {
        return {
          url: `v1/common/whatsapp-config/${fieldsData}`,
          method: "DELETE",

        };
      },
    }),
    updateWhatsAppConfiguration: builder.mutation({
      query: ({body,id}) => {
        return {
          url: `v1/common/whatsapp-config/${id}/`,
          method: "PATCH",
          body,
        };
      },
    })



  }),
});

export const {
  useTeamsPageQuery,
  useEditCheckBoxMutation,
  useEditDropwDownMutation,
  usePieChartAPIQuery,
  useCreateCheckBoxLevelMutation,
  useTeamsPaginationQuery,
  useTeamMutation,
  useTeamQQuery,
  useSetTeamInfoMutation,
  useTeamConfigQuery,
  useMeMutation,
  useWorkflowsMutation,
  useWorkflowsQQuery,
  useDispositionMutation,
  useSearchContactByEmailMutation,
  useStatusesMutation,
  useTeamsQuery,
  useNewContactMutation,
  useUpdateTeamMutation,
  useLookupByDispositionAndTeamIdMutation,
  useGetStatusesQuery,
  useDynamicFieldsMutation,
  useEmaildispositionQuery,
  useMeLoginQuery,
  useMeeQuery,
  useStatusQuery,
  useTeamsAddMutation,
  useTagsQuery,
  useCustomerQuery,
  useCustomerUpdateMutation,
  useAccountsUpdateMutation,
  useChangeStatusQuery,
  useUserDeleteTeamMutation,
  useDeleteUserDynamicFieldsMutation,
  useEditUserDynamicFieldsMutation,
  useUserDynamicFiedMutation,
  useUserDynamicFieldsQuery,
  useUsersTeamMutation,
  useUpdateDynamicFieldDefinitionsMutation,
  useDynamicFieldDefinitionsQuery,
  useUsersDynamicFieldsQuery,
  useGetTicketTagSLADetailsQuery,
  useLazyGetTicketTagSLAEscalationLevelsQuery,
  useBulkSortingMutation,
  useGetTenantDataQuery,
  useUpdateTenantDataMutation,
  useWhatsAppConfigurationMutation,
  useGetWhatsAppConfigurationQuery,
  useDeleteWhatsAppConfigurationMutation,
  useUpdateWhatsAppConfigurationMutation
} = commonAPI;
