import React from "react";
import { CloseButton } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDeleteAttachmentMutation } from "../../../Services/campign/CampaignDetail";

interface IProps {
  selectedAttachment: any;
  closeDeleteAttachmentModal: () => void;
  refetch: any;
}

const DeleteAttchment: React.FC<IProps> = ({
  selectedAttachment,
  closeDeleteAttachmentModal,
  refetch,
}) => {
  const [triggerDeleteAttachment] = useDeleteAttachmentMutation();

  const handleDeleteCampaign = async () => {
    try {
      const payload = {
        attachments: [
          {
            id: selectedAttachment.id,
            created: selectedAttachment.created,
            file: selectedAttachment.file,
            file_name: selectedAttachment.file_name
          }
        ]
      };
      await triggerDeleteAttachment({ body: payload, selectedAttachment });
      toast.success(`Attachment successfully deleted`);
      closeDeleteAttachmentModal();
      refetch();
    } catch (err) {
      toast.error("Failed to delete. Please try again.");
      console.error("Error delete:", err);
    } finally {
    }
  };

  return (
    <>
      <div className="text-end">
        <CloseButton onClick={closeDeleteAttachmentModal} />
      </div>

      <div className="card-body">
        <p className="fs-5 fw-bold">
          Are you sure you want to delete the following attchment?
        </p>

        <div className="d-flex justify-content-end mt-10">
          <button
            type="reset"
            className="btn btn-sm btn-white me-2"
            onClick={() => closeDeleteAttachmentModal()}
          >
            Cancel
          </button>

          <button
            type="submit"
            className="btn btn-sm btn-danger"
            onClick={handleDeleteCampaign}
          >
            Delete
          </button>
        </div>
      </div>
    </>
  );
};

export default DeleteAttchment;
