import { useEffect } from "react";
import { toast } from "react-toastify";
import { useLogoutMutation } from "../../Services/Auth/Auth";

export function Logout() {
  const [trigger] = useLogoutMutation();


  const logoutAPI = async () => {


    
    try {
      const response = await trigger({});

      if (response.data.success) {
        localStorage.clear();
        window.location.replace("/auth/login");
        toast.success(response?.data?.success[0]);
      } else if (response.error) {
        localStorage.clear();
        window.location.replace("/auth/login");
      }
    } catch (error) {}
  };

  useEffect(() => {

 
    logoutAPI();
  }, []);

  return <h1>See you soon!!</h1>;
}
