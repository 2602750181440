import React, { createRef, useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactModal from "react-modal";
// import TasksActionMenu from "../common/tabbedPanel/TasksActionMenu";
import {
    Box,
    Button,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Tooltip,
} from "@mui/material";

import { formatDate } from "../../reusableComponent/DateFormat";
import LoadingSpinner from "../../LoadingSpinner";
import EmptyRows from "../../reusableComponent/EmptyRows";
import { capitalizeFirstLetter } from "../../reusableComponent/CapitalLetter";
import { useLeadEventCountQuery, useLeadEventQuery } from "../../../Services/campign/campignList";
import { result } from "lodash";
import ViewTaskModal from "./ViewTaskModal";
import CampaignFilter from "./CampaignFilter";

const CampaignTask: React.FC<any> = ({ }) => {
    const [liftUiData, setLiftUiData] = useState(0);
    const [tabSelectedParam, setTabSelected] = useState<any>([]);

    const [value, setValue] = useState(liftUiData ? "active" : "all");
    const [storeKey, setStoreKey] = useState(liftUiData ? "active" : "all");
    const [uiData, setUiData] = useState([]);
    const [keyState, setKeyState] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedFilter, setSelectedFilter] = useState<any>({
        title__icontains: "",
        event_description__icontains: "",

    });

    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

    const { data: campaignLeadCount, isLoading: leadCountLoading, error: leadCountError } = useLeadEventCountQuery({});

    const [row, setRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
    const { data: campaignLeadData, isLoading: leadDataLoading, error: leadDataError, refetch } = useLeadEventQuery({ selectedFilters, tabSelectedParam, currentPage });

    const openFilterModal = () => {
        setIsFilterModalOpen(true);
    };

    const closeFilterModal = () => {
        setIsFilterModalOpen(false);
    };
    const customFilterModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "100%", // Default to full width
            maxWidth: "50px", // Max width for larger screens
            margin: "auto",
            Overflow: "hidden",
            OverflowY: "auto",
        },
    };


    const processData = async () => {
        const uiFormattedData = [];
        const keysForState = [];

        if (campaignLeadCount && typeof campaignLeadCount === "object") {
            // Iterate through the flat object
            Object.entries(campaignLeadCount).forEach(([key, value]) => {
                uiFormattedData.push({
                    key,    // Use the top-level key
                    value,  // Use the corresponding value
                });
                keysForState.push(key); // Collect the key for state
            });

            // Sort data to prioritize "all" first, then alphabetically
            uiFormattedData.sort((a, b) => {
                if (a.key === "all") return -1;
                if (b.key === "all") return 1;
                return a.key.localeCompare(b.key);
            });

            // Sort keys to prioritize "all" first, then alphabetically
            keysForState.sort((a, b) => {
                if (a === "all") return -1;
                if (b === "all") return 1;
                return a.localeCompare(b);
            });

            // Set the state with sorted data
            setUiData(uiFormattedData);
            setKeyState(keysForState);
        } else {
            console.error("Campaign is undefined or null");
        }
    };


    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };


    const generatePaginationNumbers = () => {
        const numbers = [];
        const maxVisibleNumbers = 5;

        if (totalPages <= maxVisibleNumbers) {
            for (let i = 1; i <= totalPages; i++) {
                numbers.push(i);
            }
        } else {
            const middleIndex = Math.ceil(maxVisibleNumbers / 2);
            let startPage = currentPage - middleIndex + 1;
            let endPage = currentPage + middleIndex - 1;

            if (startPage < 1) {
                endPage = startPage + maxVisibleNumbers - 1;
                startPage = 1;
            } else if (endPage > totalPages) {
                startPage = endPage - maxVisibleNumbers + 1;
                endPage = totalPages;
            }

            for (let i = startPage; i <= endPage; i++) {
                numbers.push(i);
            }
        }

        return numbers;
    };

    const paginate = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        processData();
    }, [campaignLeadCount]);

    useEffect(() => {
        const todayDate = new Date().toISOString().split("T")[0]; // Get today's date in "YYYY-MM-DD" format

        switch (value) {
            case "all":
                // Reset to an empty array
                setTabSelected([]);
                break;

            case "active":
                // Set parameters for active items
                setTabSelected({
                    status: "active",
                    is_escalated: false,
                    event_date: todayDate,
                });
                break;

            case "upcoming":
                // Set parameters for upcoming events
                setTabSelected({
                    event_date__gt: todayDate,
                    status: "active",
                });
                break;

            case "done":
                // Set parameters for completed events
                setTabSelected({
                    status: "done",
                });
                break;

            case "cancelled":
                // Set parameters for cancelled events
                setTabSelected({
                    status: "cancelled",
                });
                break;

            case "overdue":
                // Set parameters for overdue events
                setTabSelected({
                    event_date__lte: todayDate,
                    status: "active",
                });
                break;

            case "rescheduled":
                // Set parameters for rescheduled events
                setTabSelected({
                    status: "rescheduled",
                });
                break;

            default:
                // Reset to an empty array for unhandled cases
                setTabSelected([]);
        }
    }, [value]);




    useEffect(() => {
        if (campaignLeadData) {
            // Set total pages based on count and desired page size
            const totalItems = campaignLeadData.count || 0; // Ensure `count` exists
            setTotalPages(Math.ceil(totalItems / pageSize)); // Calculate pages dynamically

            // Set rows to match the current page's data
            if (campaignLeadData.results?.length > 0) {
                setRows(campaignLeadData.results); // Populate rows with current page data
            } else {
                setRows([]); // Reset rows if no results
            }
        }
    }, [campaignLeadData, pageSize]); // Dependency on campaignLeadData and pageSize


    const truncateDescription = (text, maxLength) => {
        if (!text || text.length <= maxLength) {
            return text;
        }
        return `${text.substring(0, maxLength)}...`;
    };

    const customModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "75%", // Default to full width
            maxWidth: "750px", // Max width for larger screens
            height: "75%",
            margin: "auto",
            Overflow: "hidden",
            OverflowY: "auto",
        },
    };


    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleRowClick = (row) => {
        setSelectedRow(row);
        openModal()
    }


    const handleSubmit = (filterValues) => {
        setSelectedFilters(filterValues);

        closeFilterModal();


    };

    return (
        <div id="kt_app_content_container" className="app-container mx-5">
            <div className="card card-flush min-h-700px">
                {/*begin::Card header*/}
                <div className="card-header align-items-center py-1 my-1 gap-2 gap-md-5">
                    {/*begin::Card title*/}

                    <div className="card-title">
                        <h2 className="p-4">Tasks</h2>
                    </div>
                    {/*end::Card title*/}


                    {/*end::Card toolbar*/}
                </div>
                {/*end::Card header*/}

                {/*begin::Card header*/}
                <div className="card-header align-items-center py-1 gap-2 gap-md-5">
                    {/*begin::Card toolbar*/}
                    <div className="card-toolbar flex-row-fluid justify-content-end gap-5">

                        <div>
                            <Link to="/campaign/list" className="form-control form-control align-items-center text-hover-primary fw-bold ">
                                <i className="text-dark bi bi-list me-2"></i>
                                <span className="ms-auto">Leads</span>
                            </Link>
                        </div>

                        <div
                            className="w-100 mw-150px"
                            style={{ marginRight: "20px", marginLeft: "8px" }}
                        >
                            <Button
                                variant="outlined"
                                className="text-hover-primary fw-bold"
                                onClick={openFilterModal}
                                sx={{
                                    height: 40,
                                    width: 140,
                                    border: "1px solid #ccc",
                                    gap: "5px",
                                    alignItems: "center",
                                    display: "flex",
                                }}
                            >
                                <i className="bi bi-filter text-dark fs-2" />
                                <span
                                    className="ms-auto me-2 text-dark"
                                    style={{
                                        textTransform: "none", /* Ensure text-transform does not override the intended casing */
                                        whiteSpace: "nowrap",
                                        fontSize: "14px",
                                    }}
                                >
                                    Search filter
                                </span>
                            </Button>
                        </div>

                        <ReactModal
                            isOpen={isFilterModalOpen}
                            onRequestClose={closeFilterModal}
                            style={customFilterModalStyles}
                            contentLabel="Ticket Filter Modal"
                        >
                            <CampaignFilter
                                closeFilterModal={closeFilterModal}
                                selectedFilter={selectedFilter}
                                setSelectedFilter={setSelectedFilter}
                                handleSubmit={handleSubmit}
                                setSelectedFilters1={setSelectedFilters}

                            />
                        </ReactModal>


                        {/*end::Add product*/}
                    </div>
                    {/*end::Card toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Hover dropdown*/}
                <div className="card-body pt-5">

                    <div
                        className="nav nav-stretch nav-line-tabs text-primary border-transparent d-flex mb-5"
                        // style={{ padding: "0 2.25rem", display: "flex", }}
                        style={{ marginLeft: "-23px" }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                background: "#FFFFFF",
                                borderRadius: "6px",
                                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)", // subtle shadow for better elevation
                                marginLeft: "25px",
                            }}
                        >
                            <Tabs
                                value={uiData.findIndex((item) => item.key === value)}
                                onChange={(event, newValue) => {
                                    setStoreKey(keyState[newValue]);
                                    setValue(uiData[newValue].key);
                                }}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                                sx={{
                                    "& .MuiTab-root": {
                                        minWidth: 0, // Set minimum width to 0
                                        flexGrow: 1, // Add flexGrow to make tabs responsive
                                        padding: "12px 24px",
                                        textTransform: "none",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                    },
                                    "& .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabScrollButton-horizontal.Mui-disabled.MuiTabs-scrollButtons.MuiTabs-scrollButtonsHideMobile.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root": {
                                        display: "none",
                                    },
                                    "& .MuiTab-root:not(:last-child)": {
                                        borderRight: "1px solid #e0e0e0", // Thicker, more pronounced divider
                                    },
                                    "& .MuiTab-root:hover": {
                                        backgroundColor: "#f5f5f5", // Adds a hover effect for better interaction
                                    },
                                    "& .Mui-selected": {
                                        fontWeight: 600, // Highlights selected tab more
                                    },
                                }}
                            >
                                {uiData?.map((ticketsInfo, index) => (
                                    <Tab
                                        key={index}
                                        label={truncateDescription(
                                            `${capitalizeFirstLetter(ticketsInfo.key)} (${ticketsInfo.value})`,
                                            25
                                        )}
                                    />
                                ))}
                            </Tabs>
                        </Box>
                    </div>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            textAlign: "center", // Center-align header text
                                        }}
                                    >
                                        Title
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            textAlign: "center", // Center-align header text
                                        }}
                                    >
                                        Created on
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            textAlign: "center", // Center-align header text
                                        }}
                                    >
                                        Due date
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            textAlign: "center", // Center-align header text
                                        }}
                                    >
                                        Time due
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            textAlign: "center", // Center-align header text
                                        }}
                                    >
                                        Task description
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            textAlign: "center", // Center-align header text
                                        }}
                                    >
                                        Status
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {loading || leadDataLoading ? (
                                    <TableRow>
                                        <TableCell colSpan={6} style={{ textAlign: "center" }}>
                                            <LoadingSpinner />
                                        </TableCell>
                                    </TableRow>
                                ) : row?.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={6} style={{ textAlign: "center" }}>
                                            <EmptyRows value={""} />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    row?.map((row: any) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ "&:last-child td, &:last-child th ": { border: 0 } }}
                                            onClick={() => handleRowClick(row)}
                                            className="cursor-pointer"
                                        >
                                            <TableCell style={{ textAlign: "center" }}>
                                                {capitalizeFirstLetter(row.title)}
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center" }}>
                                                {formatDate(row.created)}
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center" }}>
                                                {formatDate(row.event_date)}
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center" }}>
                                                {capitalizeFirstLetter(row?.start_time)}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    textAlign: "center",
                                                    maxWidth: "150px",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                }}
                                            >
                                                <Tooltip
                                                    title={capitalizeFirstLetter(row?.event_description) || ""}
                                                    arrow
                                                >
                                                    <span>{capitalizeFirstLetter(row?.event_description)}</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center" }}>
                                                {capitalizeFirstLetter(row.status)}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>


                </div>

                {!loading && (
                    <div className="row mx-4 pt-2 mb-2 mt-3">
                        <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                        </div>
                        <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                            <div
                                className="dataTables_paginate paging_simple_numbers"
                                id="kt_table_users_paginate"
                            >
                                <ul className="pagination">
                                    <li
                                        className={`paginate_button page-item ${currentPage === 1 ? "disabled" : ""
                                            }`}
                                    >
                                        <button
                                            type="button"
                                            className="btn primary page-link"
                                            onClick={prevPage}
                                        >
                                            <i className="previous"></i>
                                        </button>
                                    </li>

                                    {generatePaginationNumbers().map((pageNumber) => (
                                        <li
                                            key={pageNumber}
                                            className={`paginate_button page-item ${currentPage === pageNumber ? "active" : ""
                                                }`}
                                        >
                                            <button
                                                type="button"
                                                className="btn primary page-link"
                                                onClick={() => paginate(pageNumber)}
                                            >
                                                {pageNumber}
                                            </button>
                                        </li>
                                    ))}

                                    <li
                                        className={`paginate_button page-item ${currentPage === totalPages ? "disabled" : ""
                                            }`}
                                    >
                                        <button
                                            type="button"
                                            className="btn primary page-link"
                                            onClick={nextPage}
                                        >
                                            <i className="next"></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </div>



            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                style={customModalStyles}
                contentLabel="View Task"
            >
                <ViewTaskModal
                    closeModal={closeModal}
                    selectedRow={selectedRow}
                    refetch={refetch}

                />
            </ReactModal>

        </div>
    );
};

export default CampaignTask;
