import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { CloseButton } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../../reusableComponent/CapitalLetter";
import {
  useCampaignTeamsQuery,
  useGetTelephonyPriQuery,
  useUpdateCampaignMutation,
} from "../../../../Services/campaign/CampaignMapping";
import { useAllUsersDataQuery } from "../../../../Services/user/Users";
import { toast } from "react-toastify";
import { Loader } from "react-bootstrap-typeahead";
import LoadingSpinner from "../../../LoadingSpinner";
import { useUsersByTeamIdQuery } from "../../../../Services/ticket/users/UsersByTeamId";

interface IProps {
  selectedCampaign: any;
  closeEditCampaignModal: () => void;
  refetch: any;
  isLoading: any;
}

interface ErrorState {
  [key: string]: string;
}

const EditCampaignModal: React.FC<IProps> = ({
  selectedCampaign,
  closeEditCampaignModal,
  refetch,
  isLoading,
}) => {
  const [theTeams, setTheTeams] = useState<any[]>([]); // Ensured it is an array
  const [selectedTeams, setSelectedTeams] = useState<any>(
    selectedCampaign.team?.id || ""
  );
  const [userList, setUserList] = useState<any[]>([]);
  const [campaignName, setCampaignName] = useState<string>(
    selectedCampaign.name || ""
  );

  //User
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [startTime, setStartTime] = useState<string>(
    selectedCampaign.start_time || ""
  );
  const [isUserLoading, setIsUserLoading] = useState(false);

  const [endTime, setEndTime] = useState<string>(
    selectedCampaign.end_time || ""
  );
  const [DiallerType, setDiallerType] = useState<string>(
    selectedCampaign.dialler_type || ""
  );
  const [campaignToggle, setCampaignToggle] = useState<boolean>(
    selectedCampaign.is_active || false
  ); // Toggle state

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errors, setErrors] = useState<ErrorState>({});

  //Telephony
  const [telephonyData, setTelephonyData] = useState<any>([]);
  const [selectedTelephony, setselectedTelephony] = useState<any>([]);

  const { data: usersData, status: usersStatus } = useUsersByTeamIdQuery(
    selectedTeams,
    {
      skip: selectedTeams.length === 0,
    }
  );
  const { data: teamsData } = useCampaignTeamsQuery({});
  const { data: telephonyPriData } = useGetTelephonyPriQuery({});
  const [triggerUpdateCampaign] = useUpdateCampaignMutation(
    selectedCampaign.id
  );

  // Setting teams data
  useEffect(() => {
    if (teamsData && Array.isArray(teamsData)) {
      setTheTeams(teamsData);
    }
  }, [teamsData]);

  // Setting telephony data
  useEffect(() => {
    setTelephonyData(telephonyPriData?.results);
  }, [telephonyPriData]);

  // Setting user list
  useEffect(() => {
    if (usersData && Array.isArray(usersData)) {
      setUserList(usersData);
      console.log(usersData, "usersData");
    }
  }, [usersData]);

  useEffect(() => {
    if (usersStatus === "pending") {
      setIsUserLoading(true);
    } else {
      setIsUserLoading(false);
    }
  }, [usersStatus]);

  // Handling the campaign data
  useEffect(() => {
    if (selectedCampaign) {
      setCampaignName(selectedCampaign.name || "");
      setDiallerType(selectedCampaign.dialler_type || "");
      setSelectedTeams(selectedCampaign.team?.id || "");
      setSelectedUser(
        Array.isArray(selectedCampaign.users)
          ? selectedCampaign.users.map((user) => user.id)
          : []
      );
      setStartTime(formatEditDateTime(selectedCampaign.start_time || ""));
      setEndTime(formatEditDateTime(selectedCampaign.end_time || ""));
      setCampaignToggle(selectedCampaign.is_active || false); // Set initial toggle state
      setselectedTelephony(
        selectedTelephony.length === selectedCampaign.pris.length
          ? []
          : selectedCampaign.pris.map((tel) => tel.id)
      );
    }
  }, [selectedCampaign]);

  const validateForm = () => {
    const newErrors: ErrorState = {};

    //Campaign Name validation
    if (!campaignName) {
      newErrors["campaignName"] = "Campaign Name is required";
    }
    // Teams validation
    if (!selectedTeams || selectedTeams.length === 0) {
      newErrors["selectedTeams"] = "Please select at least one team";
    }

    // DID selection validation
    if (!selectedTelephony || selectedTelephony.length === 0) {
      newErrors["selectedTelephony"] = "Please select at least one DID";
    }
    // User List validation
    if (!selectedUser) {
      newErrors["selectedUser"] = "Please select at least one user";
    }

    // Start Time validation
    if (!startTime) {
      newErrors["startTime"] = "Start Time is required";
    }

    // End Time validation
    if (!endTime) {
      newErrors["endTime"] = "End Time is required";
    } else if (
      startTime &&
      endTime &&
      new Date(startTime) >= new Date(endTime)
    ) {
      newErrors["endTime"] = "End Time must be later than Start Time";
    }

    // Dialler Type validation
    if (!DiallerType) {
      newErrors["DiallerType"] = "Please select a Dialler type";
    }

    // Set errors in state
    setErrors(newErrors);

    // Return whether the form is valid (no errors)
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill all required fields.");
      setIsButtonDisabled(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
      return;
    }

    try {
      setIsButtonDisabled(true);
      const obj = {
        id: selectedCampaign.id,
        name: campaignName,
        team: selectedTeams,
        users: selectedUser.filter((id) =>
          userList.some((user) => user.id === id)
        ),
        start_time: startTime,
        end_time: endTime,
        dialler_type: DiallerType,
        is_active: campaignToggle,
        pris: selectedTelephony,
      };

      const response = await triggerUpdateCampaign({
        body: obj,
        id: selectedCampaign.id,
      });

      if (response?.data) {
        closeEditCampaignModal();
        toast.success("Campaign updated successfully!");
        refetch();
      }
    } catch (error) {
      toast.error("An unexpected error occurred");
      console.error(error);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const handleToggleChange = () => {
    setIsButtonDisabled(false);
    setCampaignToggle((prevState) => !prevState); // Toggle the value
  };

  const formatEditDateTime = (dateTime: string) => {
    if (!dateTime) return "";
    const date = new Date(dateTime);

    // Convert UTC to IST by subtracting 5 hours and 30 minutes
    const istOffset = 5 * 60 + 30; // Offset in minutes
    const istDate = new Date(date.getTime() - istOffset * 60 * 1000);

    const year = istDate.getFullYear();
    const month = String(istDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(istDate.getDate()).padStart(2, "0");
    const hours = String(istDate.getHours()).padStart(2, "0");
    const minutes = String(istDate.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const isAllSelected = selectedUser?.length === userList?.length;

  const handleUsersChange = (event: any) => {
    setIsButtonDisabled(false);
    const {
      target: { value },
    } = event;

    if (value.includes("all")) {
      setSelectedUser(isAllSelected ? [] : userList.map((user) => user.id));
    } else {
      setSelectedUser(Array.isArray(value) ? value : value.split(","));
    }
    setErrors({});
  };

  const isAllTelephonySelected =
    selectedTelephony?.length === telephonyData?.length;

  const handleDIDChange = (event: any) => {
    setIsButtonDisabled(false);
    const {
      target: { value },
    } = event;

    if (value.includes("all")) {
      setselectedTelephony(
        isAllTelephonySelected ? [] : telephonyData.map((tel) => tel.id)
      );
    } else {
      setselectedTelephony(value); // Update this line to accept an array of values
    }
    setErrors({});
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div className="text-end">
        <CloseButton onClick={closeEditCampaignModal} />
      </div>
      <div className="text-center mb-4">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Edit Campaign
        </label>
      </div>
      <div className="d-flex justify-content-center mb-5">
        <label className="form-check-label mx-2">Off</label>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            checked={campaignToggle}
            onChange={handleToggleChange}
          />
          <label className="card-text">On</label>
        </div>
      </div>

      <div className="row g-5 g-xl-8 mb-4">
        {/* Campaign Name */}
        <div className="col-xl-6">
          <label className="required">Campaign name</label>
          <TextField
            variant="outlined"
            fullWidth
            name="campaignName"
            value={campaignName}
            onChange={(e) => {
              setIsButtonDisabled(false);

              setErrors(errors?.campaign_name ? {} : errors);
              setCampaignName(e.target.value);
            }}
          />
          {errors.campaignName && (
            <div className="mt-2" style={{ color: "red" }}>
              {capitalizeFirstLetter(errors.campaignName)}
            </div>
          )}
        </div>

        <div className="col-xl-6">
          <FormControl fullWidth>
            <label className="required">Teams</label>
            <Select
              labelId="team-label"
              id="team-select"
              value={selectedTeams}
              onChange={(e) => {
                setIsButtonDisabled(false);

                setErrors(errors?.selectedTeams ? {} : errors);
                setSelectedTeams(e.target.value);
              }}
              MenuProps={MenuProps}
            >
              <MenuItem value="" disabled>
                Select Team
              </MenuItem>
              {theTeams
                ?.slice() // Create a shallow copy of teams to avoid mutating the original array
                .sort((a: any, b: any) => a.name.localeCompare(b.name)) // Sort teams alphabetically by name
                .map((team) => (
                  <MenuItem key={team.id} value={team.id}>
                    {team.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {errors.selectedTeams && (
            <div className="mt-2" style={{ color: "red" }}>
              {capitalizeFirstLetter(errors.selectedTeams)}
            </div>
          )}
        </div>

        {/* User List */}
        <div className="col-xl-6">
          <FormControl fullWidth>
            <label className="required">Users</label>
            {selectedTeams && usersStatus === "pending" ? (
              <Loader />
            ) : (
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedUser}
                onChange={handleUsersChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (isAllSelected) {
                    return <div>All</div>;
                  }
                  return (
                    <div>
                      {selected
                        .map((userId: any) => {
                          const user = userList?.find(
                            (user) => user.id === userId
                          );
                          return user ? user.username : null; // Use username instead of name
                        })
                        .join(", ")}
                    </div>
                  );
                }}
                MenuProps={MenuProps}
              >
                <MenuItem
                  value="all"
                  onClick={() =>
                    setSelectedUser(
                      isAllSelected ? [] : userList?.map((user) => user?.id)
                    )
                  }
                >
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={selectedUser?.length > 0 && !isAllSelected}
                  />
                  <ListItemText primary="All" />
                </MenuItem>
                {userList
                  .slice() // Create a shallow copy of users to avoid mutating the original array
                  .sort((a: any, b: any) =>
                    a.username.localeCompare(b.username)
                  ) // Sort users alphabetically by username
                  .map((user: any) => (
                    <MenuItem key={user.id} value={user.id}>
                      <Checkbox checked={selectedUser.indexOf(user.id) > -1} />
                      <ListItemText primary={user.username} />
                    </MenuItem>
                  ))}
              </Select>
            )}
            {errors.selectedUser && (
              <div className="mt-2" style={{ color: "red" }}>
                {capitalizeFirstLetter(errors.selectedUser)}
              </div>
            )}
          </FormControl>
        </div>

        {/* Start Time */}
        <div className="col-xl-6">
          <label className="required">Start date and time</label>
          <TextField
            type="datetime-local"
            fullWidth
            value={startTime}
            onChange={(e) => {
              setIsButtonDisabled(false);
              setErrors(errors?.startTime ? {} : errors);
              setStartTime(e.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
          {errors.startTime && (
            <div className="mt-2" style={{ color: "red" }}>
              {capitalizeFirstLetter(errors.startTime)}
            </div>
          )}
        </div>

        {/* End Time */}
        <div className="col-xl-6">
          <label className="required">End date and time</label>
          <TextField
            type="datetime-local"
            fullWidth
            value={endTime}
            onChange={(e) => {
              setIsButtonDisabled(false);
              setErrors(errors?.endTime ? {} : errors);
              setEndTime(e.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
          {errors.endTime && (
            <div className="mt-2" style={{ color: "red" }}>
              {capitalizeFirstLetter(errors.endTime)}
            </div>
          )}
        </div>

        {/* Dialler Type */}
        <div className="col-xl-6">
          <label className="required">Dialer type</label>
          <FormControl fullWidth>
            <Select
              value={DiallerType}
              onChange={(e) => {
                setIsButtonDisabled(false);
                setErrors(errors?.DiallerType ? {} : errors);
                setDiallerType(e.target.value);
              }}
              MenuProps={MenuProps}
            >
              <MenuItem disabled selected>
                Select
              </MenuItem>
              <MenuItem value="manual">Manual Dial</MenuItem>
              <MenuItem value="autodial">Auto-Dial</MenuItem>
            </Select>
            {errors.DiallerType && (
              <div className="mt-2" style={{ color: "red" }}>
                {capitalizeFirstLetter(errors.DiallerType)}
              </div>
            )}
          </FormControl>
        </div>

        {/* Select DID */}

        <div className="col-xl-6">
          <FormControl fullWidth>
            <label className="required">Select DID</label>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedTelephony}
              onChange={handleDIDChange}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (isAllTelephonySelected) {
                  return <div>All</div>;
                }
                return (
                  <div>
                    {selected
                      .map((id: any) => {
                        const telephony = telephonyData?.find(
                          (tel) => tel?.id === id
                        );
                        return telephony ? telephony?.name : null; // Use username instead of name
                      })
                      .join(", ")}
                  </div>
                );
              }}
              MenuProps={MenuProps}
            >
              <MenuItem
                value="all"
                onClick={() =>
                  setselectedTelephony(
                    isAllTelephonySelected
                      ? []
                      : telephonyData?.map((tel) => tel?.id)
                  )
                }
              >
                <Checkbox
                  checked={isAllTelephonySelected}
                  indeterminate={
                    selectedTelephony?.length > 0 && !isAllTelephonySelected
                  }
                />
                <ListItemText primary="All" />
              </MenuItem>
              {telephonyData?.map((tel: any) => (
                <MenuItem key={tel?.id} value={tel?.id}>
                  <Checkbox checked={selectedTelephony?.indexOf(tel.id) > -1} />
                  <ListItemText primary={tel?.name} />
                </MenuItem>
              ))}
            </Select>
            {errors.selectedTelephony && (
              <div className="mt-2" style={{ color: "red" }}>
                {capitalizeFirstLetter(errors.selectedTelephony)}
              </div>
            )}
          </FormControl>
        </div>

        <div className="text-end mt-5">
          <button
            type="submit"
            className="btn btn-sm btn-secondary mx-2"
            onClick={closeEditCampaignModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-primary"
            onClick={handleSubmit}
            disabled={isButtonDisabled}
          >
            {isLoading ? (
              <>
                Saving... <Loader />
              </>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default EditCampaignModal;
