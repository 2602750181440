import React, {useState, useEffect} from 'react'
import { toast } from 'react-toastify';
import { useEditPauseCodeQuery, useEditPauseMutation } from '../../../../Services/Telephony/Telephony';

interface IPROPS {
    closeModal: any;
    pauseId: any;
    refetch:any
}

const EditPauseCode: React.FC<IPROPS> = ({ closeModal,
    pauseId,
    refetch}) => {

  const [pauseCodeName, setPauseCodeName] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const {
        data: editPauseCodeData,
        isLoading: editPauseLoading,
        status: editPauseCodeStatus,
    } = useEditPauseCodeQuery(pauseId);
    const [
        trigger,
        {
            isLoading: updatePauseCodeloading,
            status: updatePauseCodeStatus,
        },
    ] = useEditPauseMutation();

    useEffect(() => {
        if (editPauseCodeData) {
            setPauseCodeName(editPauseCodeData?.name);
        }
    }, [editPauseCodeData, pauseId]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const obja = {
            name: pauseCodeName,
        };

        const response = await trigger({ body: obja, id: pauseId });

        try {
            if (response?.data) {
                toast.success("Edited successfully");
                closeModal();

                refetch()

                setIsButtonDisabled(true);

                setTimeout(() => {
                  setIsButtonDisabled(false);
                }, 3000);
            } else if ("data" in response?.error) {
                const errorData = response?.error?.data as {
                    extension: string[];
                };
                const errorMessage = errorData?.extension[0];
                toast.error(errorMessage);

                setIsButtonDisabled(true);

                setTimeout(() => {
                  setIsButtonDisabled(false);
                }, 3000);
            }
        } catch (error) {
            toast.error("An error occured");

            setIsButtonDisabled(true);

            setTimeout(() => {
              setIsButtonDisabled(false);
            }, 3000);
        }
    };


    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <div>
                <label>Pause code</label>
                <input
                    className="form-control"
                    value={pauseCodeName}
                    onChange={(e) => setPauseCodeName(e.target.value)}
                />
            </div>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "end",
                }}
            >
                <button
                 type="reset"
                    className="btn btn-sm btn-white me-2"
                onClick={() => closeModal()}>Cancel</button>
                <button className="btn btn-primary" onClick={handleSubmit} disabled={isButtonDisabled}>
                    Save
                </button>
            </div>
        </div>
    );
}

export default EditPauseCode