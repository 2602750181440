import React, { useEffect, useState } from "react";
import { CardContent , TextField } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../LoadingSpinner";
import { MultiSelect } from "react-multi-select-component";

const StatusLevelAuditReport = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "ticketId",
      headerName: "Ticket ID",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "subject",
      headerName: "Subject",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    { field: "customerEmail", headerName: "Customer Email ID", width: 240 },
    {
      field: "issueType",
      headerName: "Issue Type",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastUpdated",
      headerName: "Last Updated Date and Time",
      width: 240,
    },
    {
      field: "subIssueType",
      headerName: "Sub Issue Type",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    { field: "actionDateTime", headerName: "Action Date and Time", width: 240 },
    {
      field: "tat",
      headerName: "TAT",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "oldStatus",
      headerName: "Old Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "newStatus",
      headerName: "New Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ticketAction",
      headerName: "Ticket Action",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "oldAssignee",
      headerName: "Old Assignee",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "newAssignee",
      headerName: "New Assignee",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "assignedBy",
      headerName: "Assigned By",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ticketCreatedDateTime",
      headerName: "Ticket Created Date and Time",
      width: 240,
    },
    {
      field: "ticketChannel",
      headerName: "Ticket Channel",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ticketType",
      headerName: "Ticket Type",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "originalAssignee",
      headerName: "Original Assignee",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "currentAssignee",
      headerName: "Current Assignee",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "currentDepartment",
      headerName: "Current Department",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "departmentOfTicketCreation",
      headerName: "Department of Ticket Creation",
      width: 240,
    },
    {
      field: "createdBy",
      headerName: "Created by",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ticketCreationEmailId",
      headerName: "Ticket Creation Email ID",
      width: 240,
    },
    {
      field: "alternateNumber",
      headerName: "Alternate Number",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "assetType",
      headerName: "Asset Type",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "dependency",
      headerName: "Dependency",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "jobId",
      headerName: "JOB ID",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "merchantId",
      headerName: "Merchant ID",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "merchantName",
      headerName: "Merchant Name",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "orgName",
      headerName: "Org Name",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "phoneNo",
      headerName: "Phone No",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "priority",
      headerName: "Priority",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "requestor",
      headerName: "Requestor",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "stpNstp",
      headerName: "STP/NSTP",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "source",
      headerName: "Source",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "vom",
      headerName: "VOM",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const rows = [
    {
      id: 1,
      ticketId: "TK001",
      subject: "Issue with login",
      customerName: "John Doe",
      customerEmail: "john.doe@example.com",
      issueType: "Login Issue",
      lastUpdated: "2024-07-17 10:00:00",
      subIssueType: "Forgot Password",
      actionDateTime: "2024-07-17 10:15:00",
      tat: "00:15:00",
      oldStatus: "Open",
      newStatus: "Closed",
      ticketAction: "Resolved",
      oldAssignee: "Agent A",
      newAssignee: "Agent B",
      assignedBy: "Manager X",
      ticketCreatedDateTime: "2024-07-16 09:00:00",
      ticketChannel: "Email",
      ticketType: "Technical",
      originalAssignee: "Agent A",
      currentAssignee: "Agent B",
      currentDepartment: "Support",
      departmentOfTicketCreation: "Technical Support",
      createdBy: "John Doe",
      ticketCreationEmailId: "john.doe@example.com",
      alternateNumber: "1234567890",
      assetType: "Software",
      dependency: "Database",
      jobId: "JOB123",
      merchantId: "M001",
      merchantName: "Merchant A",
      orgName: "Company X",
      phoneNo: "0987654321",
      priority: "High",
      requestor: "Client Y",
      stpNstp: "STP",
      source: "Email",
      vom: "Yes",
    },
    {
      id: 2,
      ticketId: "TK002",
      subject: "Payment issue",
      customerName: "Jane Smith",
      customerEmail: "jane.smith@example.com",
      issueType: "Payment Issue",
      lastUpdated: "2024-07-16 09:30:00",
      subIssueType: "Credit Card Decline",
      actionDateTime: "2024-07-16 09:45:00",
      tat: "00:15:00",
      oldStatus: "Open",
      newStatus: "Closed",
      ticketAction: "Resolved",
      oldAssignee: "Agent C",
      newAssignee: "Agent D",
      assignedBy: "Manager Y",
      ticketCreatedDateTime: "2024-07-15 08:00:00",
      ticketChannel: "Phone",
      ticketType: "Financial",
      originalAssignee: "Agent C",
      currentAssignee: "Agent D",
      currentDepartment: "Billing",
      departmentOfTicketCreation: "Customer Service",
      createdBy: "Jane Smith",
      ticketCreationEmailId: "jane.smith@example.com",
      alternateNumber: "1231231234",
      assetType: "POS Terminal",
      dependency: "Payment Gateway",
      jobId: "JOB456",
      merchantId: "M002",
      merchantName: "Merchant B",
      orgName: "Company Y",
      phoneNo: "9876543210",
      priority: "Medium",
      requestor: "Client Z",
      stpNstp: "NSTP",
      source: "Phone",
      vom: "No",
    },
    // Add more rows as needed
  ];

  const options: any[] = [
    { label: "Insurance Team", value: "Insurance Team" },
    { label: "Collection", value: "Collection" },
  ];

  const [selectedTeams, setSelectedTeams] = useState<any>([]);

  useEffect(() => {
    const dropdownHeadings = document.querySelectorAll(".dropdown-heading");
    dropdownHeadings.forEach((heading: any) => {
      heading.style.height = "50px";
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    // ...
    setLoading(false);
  }, []);

  return (
    <div id="kt_app_content_container" className="app-container mx-5">
      <div className="card card-flush">
        {/* Card Header */}
        <div
          className="card-header align-items-center py-1 gap-2 gap-md-5"
          style={{ justifyContent: "flex-start" }}
        >
          {/* Back button */}
          <img
            src="https://insurance.circleonecrm.com/assets/arrow_back.svg"
            alt="Back"
            style={{ marginBottom: "-2px", cursor: "pointer" }}
            className="ng-star-inserted"
            onClick={() => {
              navigate("/reports/ticket/reports-list");
            }}
          />
          <span className="fs-3 text-gray-800 fw-bold ms-3">
            Status Level Audit Report
          </span>
        </div>

        {/* Toolbar */}
        <div
          className="card-toolbar flex-row-fluid justify-content-start gap-5 d-flex mb-10"
          style={{ marginLeft: "30px" }}
        >
          {/* Date Filters */}
          <div className="w-100 mw-150px">
            <TextField
              id="date-from"
              label="Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
            />
          </div>

          <div className="w-100 mw-150px">
            <MultiSelect
              options={options}
              value={selectedTeams}
              onChange={setSelectedTeams}
              labelledBy="Select"
            />
          </div>

          <div className="ms-auto d-flex gap-3" style={{ marginRight: "20px" }}>
            <button className="btn btn-primary">Submit</button>
            <button className="btn btn-primary">Download</button>
            <button className="btn btn-link">
              <i className="bi bi-funnel fs-2 text-dark"></i>
            </button>
          </div>
        </div>

        {/* DataGrid */}
        <CardContent style={{ width: "100%" }}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              pagination
              loading={loading}
              disableColumnMenu
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "lightgray",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-columnsContainer": {
                  backgroundColor: "lightgray",
                },
                "& .css-16c50h-MuiInputBase-root-MuiTablePagination-select ,.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions":
                  {
                    marginBottom: "10px",
                  },
              }}
            />
          )}
        </CardContent>
      </div>
    </div>
  );
};

export default StatusLevelAuditReport;
