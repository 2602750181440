import React from "react";
import { useUserActivateMutation } from "../../Services/user/Users";
import { toast } from "react-toastify";

interface IProps {
  closeActivateModal: any;
  userData: any;
  refetch: any;
}

const ActivateUserModal: React.FC<IProps> = ({
  closeActivateModal,
  userData,
  refetch,
}) => {
  const [ActivateUser] = useUserActivateMutation(userData.id);

  const handleActivate = async () => {
    try {
      await ActivateUser(userData?.id);
      // Handle successful activation
      toast.success("User activated successfully!"); // Show success toast
      closeActivateModal();
      refetch();
    } catch (error) {
      // Extract error message from API response
      let errorMessage = error?.data?.user?.[0];
  
      // Check if the error message contains a date
      if (errorMessage) {
        const dateMatch = errorMessage.match(/\d{4}-\d{2}-\d{2}/); // Adjust this regex based on your date format in the error message
        if (dateMatch) {
          const dateStr = dateMatch[0];
          const dateObj = new Date(dateStr);
          const formattedDate = new Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          }).format(dateObj).replace(/,/g, ''); // Remove commas from the formatted date
  
          // Update errorMessage to include formatted date
          errorMessage = errorMessage.replace(dateStr, formattedDate);
        }
      }
  
      toast.error(errorMessage || "An error occurred"); // Show error toast
      console.error("Failed to activate user: ", error);
      closeActivateModal();
    }
  };
  

  return (
    <div className="card-body">
      <p className="fs-5 fw-bold">
        Are you sure you want to activate the following user?
      </p>

      <div className="d-flex justify-content-end mt-10">
        <button
          type="reset"
          className="btn btn-sm btn-white me-2"
          onClick={() => closeActivateModal()}
        >
          Cancel
        </button>

        <button
          type="submit"
          className="btn btn-sm btn-primary"
          onClick={handleActivate}
        >
          <span className="indicator-label">Activate</span>
        </button>
      </div>
    </div>
  );
};

export default ActivateUserModal;
