import React, { useState, useEffect } from "react";
import {
    useEditAgentMappingExtensionsQuery,
    useEditAgentMappingQuery,
    useEditAgentMappingUsersQuery,
    useUpdateAgentMappingExtensionsMutation,
} from "../../../../Services/Telephony/Telephony";
import {
    Checkbox,
    CircularProgress,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
} from "@mui/material";
import { toast } from "react-toastify";

interface IPROPS {
    closeModal: any;
    editExtensionId: any;
    refetch: any;
    agentMappingQueueData: any;
}

const EditAgentMapping: React.FC<IPROPS> = ({
    closeModal,
    editExtensionId,
    refetch,
    agentMappingQueueData,
}) => {
    const [agentUsersData, setAgentUsersData] = useState([]);
    const [extensions, setExtensions] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const [selectedExtensions, setSelectedExtensions] = useState("");
    const [selectedQueueIds, setSelectedQueueIds] = useState<number[]>([]);
    const [usersLoader, setUsersLoader] = useState(false);

    const [selectedCallerType, setSelectedCallerType] = useState("");


    //    APIS'
    const { data: editAgentMapData } =
        useEditAgentMappingQuery(editExtensionId);
    const { data: usersData, status: usersDataStatus } = useEditAgentMappingUsersQuery({});
    const { data: extensionData } = useEditAgentMappingExtensionsQuery({});
    const [updateAgentMappingTrigger] =
        useUpdateAgentMappingExtensionsMutation();

    const [selectedUser, setSelectedUser] = useState<string | undefined>("");

    const callerTypee = ["inbound", "autodial"];

    useEffect(() => {
        if (editAgentMapData) {

            setSelectedExtensions(editAgentMapData?.extension_no)
        }

    }, [editAgentMapData])


    useEffect(() => {
        if (editAgentMapData?.user) {
          setSelectedUser(editAgentMapData.user.id || ""); // Use `id` for the value
          setAgentUsersData((prevData) => {
            const updatedData = prevData.some(
              (user) => user.id === editAgentMapData.user.id
            )
              ? prevData
              : [...prevData, { id: editAgentMapData.user.id, username: editAgentMapData.user.username }];
            return updatedData;
          });
        }
      }, [editAgentMapData]);

    useEffect(() => {
        if (usersData) {
            setAgentUsersData(usersData);
        }
    }, [usersData]);



    useEffect(() => {
        if (usersDataStatus === "pending") {
            setUsersLoader(true);
        } else {
            setUsersLoader(false);
        }
    }, [usersDataStatus]);

    useEffect(() => {
        if (usersData) {
            setAgentUsersData(usersData);
        }
    }, [usersData]);

    useEffect(() => {
        if (extensionData) {
            setExtensions(extensionData);
        }
    }, [extensionData, setExtensions]);

    useEffect(() => {
        if (editAgentMapData?.call_type) {
            setSelectedCallerType(editAgentMapData.call_type);
        }
    }, [editAgentMapData]);

    // Handle change event
    const handleChange = (event) => {
        setSelectedCallerType(event.target.value);
        setIsButtonDisabled(false)

    };

    useEffect(() => {
        if (editAgentMapData?.queues) {
            const initialQueueIds = editAgentMapData.queues.map(
                (queue) => queue.id
            );
            setSelectedQueueIds(initialQueueIds);
        }
    }, [editAgentMapData]);

    const handleQueueSelection = (event: any) => {
        const {
            target: { value },
        } = event;
        setSelectedQueueIds(value);
        setIsButtonDisabled(false)
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updateAgentObj = {
            call_type: selectedCallerType,
            extension_no: selectedExtensions,
            queues: selectedQueueIds,
            user_id: selectedUser,
        };

        const response = await updateAgentMappingTrigger({
            body: updateAgentObj,
            id: editExtensionId,
        });

        try {
            if (response?.data) {
                closeModal();
                refetch();
                toast.success("Updated successfully");
            } else if ("data" in response.error) {
                const errorData = response?.error?.data as {
                    queues: string[];
                    extension_no: string[];
                    call_type: string[];
                };

                const errorMessage =
                    errorData?.queues?.[0] ||
                    errorData?.extension_no?.[0] ||
                    errorData?.call_type?.[0] ||
                    "An error occurred";

                toast.error(errorMessage);
            }
        } catch (error) {
            toast.error("An error occured");
        }
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 120,
            },
        },
    };


    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <div>
                <label>Caller type</label>
                <select
                    className="form-control"
                    onChange={handleChange}
                    value={selectedCallerType}
                >
                    {callerTypee?.map((caller, index) => (
                        <option key={index} value={caller}>
                            {caller}
                        </option>
                    ))}
                </select>
            </div>
            {/* <div>
                <label>Select user</label>
                <select
                    className="form-control"
                    value={selectedUser}
                    onChange={(e) => {
                        setSelectedUser(e.target.value)
                    setIsButtonDisabled(false)
                    }}
                >
                    {agentUsersData?.map((user, index) => (
                        <option key={index} value={user?.username}>
                            {user?.username}
                        </option>
                    ))}
                </select>
            </div> */}

<FormControl fullWidth>
  <label>Select User</label>
  <Select
    labelId="user-select-label"
    value={selectedUser}
    onChange={(e) => {
      setSelectedUser(e.target.value);
      setIsButtonDisabled(false);
    }}
    MenuProps={MenuProps}
    displayEmpty
  >
    <MenuItem value="" disabled>
      {usersLoader ? (
        <>
          Searching... <CircularProgress size={20} />
        </>
      ) : (
        "Select User"
      )}
    </MenuItem>
    {!usersLoader &&
      agentUsersData.map((user) => (
        <MenuItem key={user.id} value={user.id}>
          {user.username}
        </MenuItem>
      ))}
  </Select>
</FormControl>


            <FormControl fullWidth>
<label>Select extension</label>

                <Select
                    labelId="user-select-label"
                    value={selectedExtensions}
                    onChange={(e) => {
                        setSelectedExtensions(e.target.value)
                        setIsButtonDisabled(false)
                    
                    }}
                    // label="Select user"
                    MenuProps={MenuProps}
                >
                    <MenuItem value="" disabled>
                      select Entension
                    </MenuItem>

                
                            {extensions.map((extension, index) => (
                                <MenuItem 
                                key={index} value={extension?.extension}>
                            {extension.extension}
                                </MenuItem>
                            ))}
                   
                    {/* Map agentUsersData */}
                </Select>
            </FormControl>

            <FormControl fullWidth>
            <label>Select queue</label>
                {/* <InputLabel id="queue-select-label">Select queue</InputLabel> */}
                <Select
                    labelId="queue-select-label"
                    id="queue-select"
                    multiple
                    value={selectedQueueIds}
                    onChange={handleQueueSelection}
                    MenuProps={MenuProps}
                    renderValue={(selected) =>
                        agentMappingQueueData
                            .filter((queue) => selected.includes(queue.id))
                            .map((queue) => queue.name)
                            .join(", ")
                    }
                >
                    {agentMappingQueueData.map((queue) => (
                        <MenuItem key={queue.id} value={queue.id}>
                            <Checkbox
                                checked={selectedQueueIds.includes(queue.id)}
                            />
                            <ListItemText primary={queue.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <div
                style={{
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "end",
                }}
            >
                <button
                    type="reset"
                    className="btn btn-sm btn-white me-2"
                    onClick={() => closeModal()}
                >
                    Cancel
                </button>
                <button
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    disabled={isButtonDisabled}
                >
                    Save
                </button>
            </div>
        </div>
    );
};

export default EditAgentMapping;
