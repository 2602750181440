import { CloseButton } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { useEffect, useRef, useState } from "react";
import { useNewInProgressStateInWorkflowMutation, useNewClosedStateInWorkflowMutation, useEditInProgressStateInWorkflowMutation, useEditClosedStateInWorkflowMutation } from "../../../../Services/settingsAPIs/TicketSettingsAPI";
import { useDispositionMutation, useLookupByDispositionAndTeamIdMutation, useStatusQuery, useTeamsQuery } from "../../../../Services/settingsAPIs/CommonAPI";
import DispositionSelector from "../../common/dispositionSelector";
import { toast } from "react-toastify";
import { toSentenceCase } from "../../../../utils/functions/toSentenceCase";

const NewStateModal = ({ closeNewStateModal, statusForEdit, selectedWFinfo, assignedMaxId }) => {
  const initialValues = {
    id: statusForEdit.statusEditEnabled ? statusForEdit.statusData.id : assignedMaxId + 1,
    status: statusForEdit.statusEditEnabled ? statusForEdit.statusData?.status : "",
    ticket_name: statusForEdit.statusEditEnabled ? statusForEdit.statusData.ticket_name : -1,
    assignment_team: statusForEdit.statusEditEnabled ? statusForEdit.statusData.assignment_team : 1,
    visibility_team: statusForEdit.statusEditEnabled ? statusForEdit.statusData.visibility_team : 1,
    ticket_name_mapped: statusForEdit.statusEditEnabled ? statusForEdit.statusData.ticket_name_mapped : 0,
    main_status: statusForEdit.statusEditEnabled ? statusForEdit.statusData.main_status : null,
    wip_status: statusForEdit.statusEditEnabled ? statusForEdit.statusData.wip_status : 0,
    closed_status: statusForEdit.statusEditEnabled ? statusForEdit.statusData.closed_status : null,
    disposition: statusForEdit.statusEditEnabled ? statusForEdit.statusData.disposition : 0,
    user: statusForEdit.statusEditEnabled ? statusForEdit.statusData.user : [null],
    round_robin: statusForEdit.statusEditEnabled ? statusForEdit.statusData.round_robin : false,
    FIFO: statusForEdit.statusEditEnabled ? statusForEdit.statusData.FIFO : false
  }

  const newWorkflowSchema = Yup.object().shape({
    status: Yup.string().required('Please provide a ticket name'),
  });

  const { data, isLoading } = useTeamsQuery({});
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formSubmissionStatus, setFormSubmissionStatus] = useState("");
  const [triggerNewInProgressStateInWorkflowMutation] = useNewInProgressStateInWorkflowMutation();
  const [triggerNewClosedStateInWorkflowMutation] = useNewClosedStateInWorkflowMutation();
  const [triggerEditInProgressStatusInWFmutation] = useEditInProgressStateInWorkflowMutation();
  const [triggerEditInClosedStatusInWFmutation] = useEditClosedStateInWorkflowMutation();
  const [selectedTeam, setSelectedTeam] = useState<number>(null)
  const [selectedAssigntypeIsRR, setSelectedAssigntypeIsRR] = useState<boolean>(false)
  const [theTeams, setTheTeams] = useState([])
  const [theStatuses, setTheStatuses] = useState([])
  const [selectedStatus, setSelectedStatus] = useState<number>(null)
  const [theDispositions, setTheDispositions] = useState([])
  const [selectedDisposition, setSelectedDisposition] = useState<any>(null)
  const [theWorkflows, setTheWorkflows] = useState([])
  const [selectedWorkflow, setSelectedWorkflow] = useState<any>(null)
  const [statusType, setStatusType] = useState('progress');
  const [triggerDispositionMutation] = useDispositionMutation()
  const [triggerWorkflowMutation] = useLookupByDispositionAndTeamIdMutation();
  const { data: statusesToPickFrom } = useStatusQuery(selectedWorkflow, { skip: !selectedWorkflow, });
  const [formEditingEnabled, setFormEditingEnabled] = useState(false);

  const submitForm = async (valObj: any) => {

    try {
      let response;

      if (statusForEdit.statusEditEnabled) {
        // Edit existing status
        if (statusForEdit.statusData.statusType === 'wip') {
          response = await triggerEditInProgressStatusInWFmutation({
            statusId: statusForEdit.statusData.id,
            body: valObj,
          });
        } else {
          response = await triggerEditInClosedStatusInWFmutation({
            statusId: statusForEdit.statusData.id,
            body: valObj,
          });
        }

        if (response?.data) {
          toast.success('Edit stage success.');
        } else {
          toast.error('Failed to edit the stage.');
        }
      } else {
        // Add new status
        if (statusType === 'progress') {
          response = await triggerNewInProgressStateInWorkflowMutation(valObj);
        } else {
          response = await triggerNewClosedStateInWorkflowMutation(valObj);
        }

        if (response?.data) {
          toast.success('New stage has been inserted.');
        } else {
          toast.error('Failed to insert a new stage into workflow.');
        }
      }
    } catch (error) {
      console.error('Error making the POST request:', error);
      setFormSubmissionStatus('Error submitting the form!');
      toast.error('An error occurred while processing the request.');
    } finally {
      closeNewStateModal();
      setSubmitting(false); // Enable the form again
    }
  };


  const formik = useFormik({
    initialValues,
    validationSchema: newWorkflowSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      submitForm({
        "id": assignedMaxId + 1,
        "status": toSentenceCase(values.status),
        "ticket_name": selectedWFinfo.id,
        "assignment_team": selectedTeam,
        "visibility_team": selectedTeam,
        "ticket_name_mapped": selectedWorkflow,
        "main_status": selectedWFinfo.all_statuses[1].main_status,
        "wip_status": selectedStatus,
        "closed_status": selectedWFinfo.all_statuses[1].closed_status,
        "disposition": selectedDisposition?.id,
        "user": selectedWFinfo.all_statuses[1].user,
        "round_robin": selectedAssigntypeIsRR,
        "FIFO": false
      })
        .then((res: any) => {
       
          closeNewStateModal()
          if (res.data.signIn.status === 201) {
            closeNewStateModal()
          } else {
            setSubmitting(false)
          }
        })
        .catch((err: any) => {
          /* setLoading(false) */
          setSubmitting(false)
          console.error(err)
        })
    }
  });

  const handleTeamDropdownChange = (evt: any) => { setSelectedTeam(Number(evt.target.value)); triggerDispositionMutation(evt.target.value).then((res: any) => res.data && setTheDispositions(res.data)).catch((err: any) => alert(err)) }
  const toggleStatusType = (evt: any) => { evt.target.checked ? setStatusType('closed') : setStatusType('progress') }
  useEffect(() => { setTheTeams(data) }, [data]);
  useEffect(() => {
    selectedDisposition &&
      triggerWorkflowMutation({ teamId: selectedTeam, disposition: selectedDisposition.id })
        .then((res: any) => {
          if (res.data) Array.isArray(res.data) ? setTheWorkflows(res.data) : setTheWorkflows([res.data])
        })
        .catch((err: any) => alert(err))
  }, [selectedDisposition]);
  useEffect(() => { statusesToPickFrom && setTheStatuses(statusesToPickFrom.all_statuses) }, [statusesToPickFrom])
  return (
    <div>
      <div className="text-end">
        <CloseButton onClick={closeNewStateModal} />
      </div>
      <div className={`${statusForEdit.statusEditEnabled ? 'd-none' : ''}`}>
        <div className="d-flex">
          <label className="form-check-label pe-2" htmlFor="flexSwitchCheckDefault">Toggle stage type: <strong>In progress</strong></label>
          <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={toggleStatusType} />
            <label className="form-check-label" htmlFor="flexSwitchCheckDefault"><strong>Closed</strong></label>
          </div>
        </div>
        <div className="text-start mt-4">
          <label className={`form-label fs-2 fw-bolder card-title d-flex ${statusType === 'progress' ? 'text-danger' : 'text-warning'}`}>
            <i className="bi bi-plus-lg fs-2x text-dark me-2"></i> in {statusType} stage
          </label>
        </div>
      </div>
      <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' noValidate onSubmit={formik.handleSubmit}>
        <div className="row g-5 g-xl-8 mb-6">
          {/*status (description text) feed*/}
          <div className="col-xl-6">
            <div className="d-flex flex-column">
              <label className="required">Add a stage title</label>
              <input
                id='status'
                name='status'
                onChange={formik.handleChange}
                disabled={statusForEdit.statusEditEnabled && !formEditingEnabled}
                value={formik.values.status} type="text" className="form-control" />

              {formik.touched.status && formik.errors.status && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.status}</span>
                </div>
              )}
            </div>
          </div>
          <div className="col-xl-6">
            <label>Select team</label>
            <select
              className="form-select select2-hidden-accessible"
              name="assignment_team"
              onChange={handleTeamDropdownChange}
              value={selectedTeam}
              disabled={statusForEdit.statusEditEnabled && !formEditingEnabled}
            >
              <option value="">Select Team</option>
              {theTeams && theTeams.map((theTeam) => (<option value={theTeam.id}>{theTeam.name}</option>))}
            </select>
            {/* {selectedTeam === null && 
              <div className='fv-plugins-message-container text-danger'>
                <span role='alert'>Team has not been provided</span>
              </div>
            } */}
          </div>
        </div>
        <label className="form-label">Current Disposition: </label>

        <div className="row g-5 g-xl-8 mb-6">
          {/*disposition feed*/}
          <div className={`col-xl-12 d-flex justify-content-start ${selectedTeam ? '' : 'd-none'}`}>
            <DispositionSelector theDispositions={theDispositions} selectedDisposition={selectedDisposition} setSelectedDisposition={setSelectedDisposition} />
          </div>
        </div>
        <div className="row g-5 g-xl-8 mb-6">
          <div className="col-xl-6">
            <label>Select workflow</label>
            <select
              className="form-select select2-hidden-accessible"
              onChange={(evt) => setSelectedWorkflow(evt.target.value)}
              value={selectedWorkflow}
              disabled={statusForEdit.statusEditEnabled && !formEditingEnabled}
            >
              <option value="">Select workflow</option>
              {theWorkflows.length && theWorkflows.map((theWorkflow) => (<option value={theWorkflow.id}>{theWorkflow.name}</option>))}
            </select>
            {/* {selectedWorkflow === "" && 
              <div className='fv-plugins-message-container text-danger'>
                <span role='alert'>Team has not been provided</span>
              </div>
            } */}
          </div>
        </div>

        <div className="row g-5 g-xl-8 mb-6">
          {/*status feed*/}
          <div className="col-xl-12">
            <label>Select Status</label>
            <select
              className="form-select select2-hidden-accessible"
              onChange={(evt) => setSelectedStatus(Number(evt.target.value))}
              value={selectedStatus}
              disabled={statusForEdit.statusEditEnabled && !formEditingEnabled}
            >
              <option value="">Select status</option>
              {theStatuses.map((theStatus) => (
                <option
                  value={theStatus.status_type === "active" || theStatus.status_type === "reopen" ? null : theStatus.status.id}>
                  {theStatus.status_type === "active" || theStatus.status_type === "reopen" ? theStatus.status : theStatus.status.status}
                </option>
              ))
              }
            </select>
            {/* {selectedStatus === null && 
              <div className='fv-plugins-message-container text-danger'>
                <span role='alert'>Status has not been provided</span>
              </div>
            } */}
          </div>
        </div>

        <div className="row g-5 g-xl-8 mb-6">
          {/*ticket name feed*/}
          <div className="col-xl-12">
            <div className="row row-cols-2">
              <div className="col"><input type="radio" disabled={statusForEdit.statusEditEnabled && !formEditingEnabled} name="assignmentType" value="individualassignment" id="indvassgn" className="form-check-input" /><label htmlFor="indvassgn" className="form-label ps-2">Individual Assignment</label></div>
              <div className="col"><input type="radio" disabled={statusForEdit.statusEditEnabled && !formEditingEnabled} name="assignmentType" value="roundrobin" id="roundrobin" className="form-check-input" /><label htmlFor="roundrobin" className="form-label ps-2">Round Robin</label></div>
              <div className="col"><input type="radio" disabled={statusForEdit.statusEditEnabled && !formEditingEnabled} name="assignmentType" value="activelogins" id="activelgn" className="form-check-input" /><label htmlFor="activelgn" className="form-label ps-2">Active Logins(RR)</label></div>
              <div className="col"><input type="radio" disabled={statusForEdit.statusEditEnabled && !formEditingEnabled} name="assignmentType" value="teamassignment" id="teamassgn" className="form-check-input" /><label htmlFor="teamassgn" className="form-label ps-2">Team Assignment</label></div>
            </div>
          </div>
        </div>

        {statusForEdit.statusEditEnabled && (<>
          <div className="text-end mt-6 mb-4">
            <button className={`btn btn-sm btn-secondary ${formEditingEnabled ? 'd-none' : ''}`} type="button" onClick={() => setFormEditingEnabled(true)}>
              Edit
            </button>
          </div>
        </>)}

        <div className="text-end mt-6 mb-4">
          <button className={`btn btn-sm btn-secondary ${statusForEdit.statusEditEnabled && (formEditingEnabled ? '' : 'd-none')}`} type="submit">
            {statusForEdit.statusEditEnabled ? 'Save' : 'Save'}
          </button>
        </div>

      </form>
    </div>
  )
}

export default NewStateModal

/* 
done auto-assigned  "id": assignedMaxId+1,
done form-feed      "status": values.status,
doubtful            "ticket_name": selectedWFinfo.id,   ("is it coming from the workflow we're adding a status to?")
done form-feed      "assignment_team": selectedTeam,
doubtful            "visibility_team": selectedTeam,    ("is it coming from the selected team's id? same getting copied to assignment team?")
doubtful            "ticket_name_mapped": selectedWorkflow, ("is it supposed to refer to the workflow we're adding a status to OR workflow that we've selected from dialog?")
clueless            "main_status": selectedWFinfo.all_statuses[1].main_status, ("where it needs to be updated from? WF being edited/selected from dialog? or elsewhere?")
done form-feed      "wip_status": selectedStatus,
clueless            "closed_status": selectedWFinfo.all_statuses[1].closed_status,  ("where it needs to be updated from? WF being edited/selected from dialog? or elsewhere?")
done form-feed      "disposition": selectedDisposition?.id,
clueless            "user": selectedWFinfo.all_statuses[1].user, ("where it needs to be updated from? WF being edited/selected from dialog? or elsewhere?")
done form-feed      "round_robin": selectedAssigntypeIsRR,
done form-feed      "FIFO": false 
*/