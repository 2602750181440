/* do we need to make the user do a selction of team when marking the new field as mandatory in the add new field to ticket form? */
/* 
not isEditable in user field
toast message caps start
team select menu remove: ticket fields
attention divert to submit button
caps for all radio labels in add modal
automatic conversion to capital case of form feeds
sequencing of fields draggable
drop a mail to Omar to schedule a time to connect to discuss API needed to save & refer nested JSON save
 */

import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import {
  useBulkSortingMutation,
  useDynamicFieldDefinitionsQuery,
  useEditCheckBoxMutation,
  useEditDropwDownMutation,
  useTeamsQuery,
  useWorkflowsQQuery,
} from "../../../../Services/settingsAPIs/CommonAPI";
import {
  defaultModalStyles,
  standardModalStyles,
} from "../../../common/CustomModalStyles";
import {
  useAddChoiceToDynamicFieldDDMutation,
  useAddOptionToDynamicFieldCheckboxMutation,
  useDeleteOptionOfDynamicFieldCheckboxMutation,
  useDeleteTicketFieldChoiceMutation,
  useDeleteTicketFieldMutation,
  useEditChoiceToDynamicFieldDDMutation,
} from "../../../../Services/settingsAPIs/TicketSettingsAPI";
import { toast } from "react-toastify";
import CommonDeleteModal, {
  DeleteMessageBody,
} from "../../common/CommonDeleteModal";
import { toSentenceCase } from "../../../../utils/functions/toSentenceCase";
import LoadingSpinner from "../../../LoadingSpinner";
import { chownSync } from "fs";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Loader } from "react-bootstrap-typeahead";
import AddNewFieldModal from "../../ticket/ticketFields/AddNewFieldModal";
import EditTicketFieldModal from "../../ticket/ticketFields/EditTicketFieldModal";
import DragTicketField from "../../ticket/ticketFields/DragTicketField";
import { useAllCampaignDataQuery } from "../../../../Services/campaign/CampaignMapping";
import AddNewCampaignFieldModal from "./AddNewCampaignFieldModal";
import DraggableAccountField from "../../customer/DragAccountFields";

interface LiftingDropData {
  choice?: string;
  fieldId?: string;
}

const CampaignFields = () => {
  const [ticketFields, setTicketFields] = useState([]);
  const { data: theWorkflowsFetched } = useAllCampaignDataQuery({});
  const [theworkflows, setTheWorkflows] = useState<any>([]);
  const [workflowSelected, setWorkflowSelected] = useState<any>("");
  const [expandForMoreSpecifications, setExpandForMoreSpecifications] =
    useState({ expansionOn: -1, expanded: false, expandForFieldType: "" });
  const [isAddTicketFieldsModalOpen, setIsAddTicketFieldsModalOpen] =
    useState(false);
  const {
    data: dynamicFieldsData,
    refetch,
    isLoading,
  } = useDynamicFieldDefinitionsQuery("campaign", {});
  const [liftingDropData, setLiftingDropData] = useState<LiftingDropData>({});

  const [newDropdownListItem, setNewDropdownListItem] = useState("");
  const [newCheckboxOption, setNewCheckboxOption] = useState("");
  // const [] = useState(false)
  const [editDropdownListItem, setEditDropdownListItem] = useState({
    enabled: false,
    forChoiceId: -1,
    currentVal: "",
    fieldId: "",
  });
  const [editCheckboxListOption, setEditCheckboxListOption] = useState({
    enabled: false,
    forChoiceId: -1,
    currentVal: "",
    fieldId: "",
  });
  const [fieldForDeletion, setFieldForDeletion] = useState<number>();

  const [isDeleteButtonClicked, setIsDeleteButtonClicked] = useState(false);
  const [isModified, setIsModified] = useState(false);

  const [isEditFieldModalOpen, setIsEditFieldModalOpen] =
    useState<boolean>(false);
  const [storingContactData, setStoringContactData] = useState(null);

  const [storingFieldData, setStoringFieldData] = useState(null);

  const [triggerEditChoiceToCheckboxField] =
    useEditChoiceToDynamicFieldDDMutation();
  const [triggerDeleteChoiceFromTicketField] =
    useDeleteTicketFieldChoiceMutation();
  const [triggerDeleteOptionFromCheckboxTicketField] =
    useDeleteOptionOfDynamicFieldCheckboxMutation();
  const [triggerDeletionOfFieldMutation] = useDeleteTicketFieldMutation();
  const [triggerEditDrowDown] = useEditDropwDownMutation();
  const [triggerEditCheckbox] = useEditCheckBoxMutation();
  const [triggerBulkSorting] = useBulkSortingMutation();

  const openAddTicketFieldModal = () => {
    if (workflowSelected) {
      setIsAddTicketFieldsModalOpen(true);
    } else {
      toast.error("Select a workflow first");
    }
  };

  const closeAddTicketFieldModal = () => {
    setIsAddTicketFieldsModalOpen(false);
  };

  const closeEditFieldModal = () => {
    setIsEditFieldModalOpen(false);
  };

  const [isDeleteTicketFieldModalOpen, setIsDeleteTicketFieldModalOpen] =
    useState<boolean>(false);
  const openDeleteTicketFieldModal = (fieldId: number) => {
    setFieldForDeletion(fieldId);
    setIsDeleteTicketFieldModalOpen(true);
  };

  const closeDeleteTicketFieldModal = () => {
    setIsDeleteTicketFieldModalOpen(false);
    setIsDeleteButtonClicked(false)
    refetch();
  };

  const deleteDataHandler = async (field: any) => {
    setIsDeleteButtonClicked(true);
    setStoringContactData(field?.id);
  };

  const editFieldsHandler = (fields: any) => {
    setIsEditFieldModalOpen(true);
    setStoringFieldData(fields);
  };

  useEffect(() => {
    theWorkflowsFetched && setTheWorkflows(theWorkflowsFetched.results);
  }, [theWorkflowsFetched]);
  useEffect(() => {
    workflowSelected &&
      setTicketFields(
        dynamicFieldsData.filter(
          (df: any) => df.campaign_name === workflowSelected
        )
      );
  }, [workflowSelected, dynamicFieldsData]);

  const dropdownOptionDelete = (id: any, choice: any) => {
    if (choice === "checkbox") {
      triggerDeleteOptionFromCheckboxTicketField({ id });
      toast.success("Deletion processed");
      refetch();
    }
    if (choice === "dropdown") {
      triggerDeleteChoiceFromTicketField({ id });
      toast.success("Deletion processed");
      refetch();
    }
  };

  const updateDropdownOption = (choiceId: any, choiceValue: any) => {
    triggerEditChoiceToCheckboxField({
      choice: choiceValue,
      field: choiceId,
    } as any);

    refetch();
  };

  const saveEditFieldHandler = async () => {
    const editObj = {
      choice: editDropdownListItem?.currentVal,
      field: editDropdownListItem?.fieldId,
    };

    try {
      const response = await triggerEditDrowDown({
        body: editObj,
        id: editDropdownListItem?.forChoiceId,
      });
      if (response?.data) {
        toast.success("Field edited");
        refetch();
      } else {
        toast.error("Unable to add field");
      }
    } catch (error) {
      toast.error("An error occured");
    }
  };

  const saveEditCheckBoxdHandler = async () => {
    const editObj = {
      choice: editCheckboxListOption?.currentVal,
      field: editCheckboxListOption?.fieldId,
    };

    try {
      const response = await triggerEditCheckbox({
        body: editObj,
        id: editCheckboxListOption?.forChoiceId,
      });
      if (response?.data) {
        toast.success("Field edited");
        refetch();
      } else {
        toast.error("Unable to add field");
      }
    } catch (error) {
      toast.error("An error occured");
    }
  };

  const moveField = (fromIndex, toIndex) => {
    const updatedFields = [...ticketFields];
    const [movedField] = updatedFields.splice(fromIndex, 1);
    updatedFields.splice(toIndex, 0, movedField);
    setTicketFields(updatedFields);
    setIsModified(true); // Set isModified to true when the fields are modified
  };



  const handleBulkSorting = async () => {
    try {
      const payload = ticketFields.map((field, index) => ({
        id: field.id,
        order_value: index + 1,
      }));
      await triggerBulkSorting({ body: payload });
      toast.success("Sequencing updated successfully");
      setIsModified(false); // Reset isModified to false after successful sorting
    } catch (error) {
      toast.error("Error occurred during sequencing ");
    }
  };

  return (
    <div className="card-body px-0 px-lg-10" style={{ overflowY: "hidden" }}>
      <div className="row">
        <div className="col">
          <span className="input-group-text" id="basic-addon1">
            Campaign name fields
          </span>
        </div>
      </div>

      <div className="row my-4">
        <div className="col-12">
          <div className="card-toolbar d-lg-flex gap-5">
            <div className="d-flex flex-column">
              <div className="min-w-250px">
                <label>Select campaign</label>
                <select
                  className="form-select"
                  onChange={(e) =>
                    setWorkflowSelected(Number.parseInt(e.target.value))
                  }
                >
                  <option value="" selected disabled>
                    Select a campaign
                  </option>
                  {theworkflows &&
                    theworkflows.map((theworkflow: any) => (
                      <option value={theworkflow.id}>{theworkflow.name}</option>
                    ))}
                </select>
              </div>
            </div>
            <span
              className="input-group-text bg-white border-0 ms-auto align-self-start"
              id="basic-addon1"
            >
              <div className="w-100 ms-auto d-flex">
                <button
                  className="input-group-text form-control btn-sm form-control align-items-center fw-bold min-w-100px"
                  onClick={openAddTicketFieldModal}
                >
                  <i className="bi bi-plus-lg me-2"></i>
                  <span>Option</span>
                </button>
              </div>
            </span>
          </div>
        </div>
        <ReactModal
          isOpen={isAddTicketFieldsModalOpen}
          onRequestClose={closeAddTicketFieldModal}
          style={standardModalStyles}
          contentLabel="Add a new campaign field"
        >
          <AddNewCampaignFieldModal
            closeModal={closeAddTicketFieldModal}
            workflowSelected={workflowSelected}
            refetch={refetch}
          />
        </ReactModal>
        <ReactModal
          isOpen={isEditFieldModalOpen}
          onRequestClose={closeEditFieldModal}
          style={defaultModalStyles}
        >
          <EditTicketFieldModal
            closeModal={closeEditFieldModal}
            setFieldsData={setTicketFields}
            fieldsData={ticketFields}
            storingFieldData={storingFieldData}
            refetch={refetch}
          />
        </ReactModal>
        <ReactModal
          isOpen={isDeleteButtonClicked}
          onRequestClose={closeDeleteTicketFieldModal}
          style={defaultModalStyles}
          contentLabel="Delete a selected status"
        >
          <CommonDeleteModal
            closeModal={closeDeleteTicketFieldModal}
            deleteAPItrigger={triggerDeletionOfFieldMutation}
            deletionIdentifier={storingContactData}
            // storingContactData={storingContactData}
            messageBody={
              <DeleteMessageBody
                bodyParams={{
                  descriptionPromptEnabled: false,
                  deletionOf: "ticket field",
                }}
              />
            }
          />
        </ReactModal>
      </div>

      {workflowSelected && (
        <>
          <div className="row" style={{ maxHeight: "70vh" }}>
            {/* <div className="col"> */}
            {/* The Table Header */}
            <div className="row" style={{ paddingRight: "0" }}>
              <div
                className="col px-2"
                style={{ paddingRight: "5px", paddingLeft: "5px" }}
              >
                <div
                  className="card px-2 my-1 min-w-600px"
                  style={{
                    backgroundColor: "#E4E4E47F",
                  }}
                >
                  <div className="row align-items-center py-4">
                    <div className="col-4 text-start ps-lg-10 ps-4">
                      <strong>Label</strong>
                    </div>
                    <div
                      className="col text-start"
                      style={{ textAlign: "justify" }}
                    >
                      <strong>Field Type</strong>
                    </div>
                    <div className="col text-center">
                      <strong>Mandatory</strong>
                    </div>
                    <div className="col text-center">
                      <strong>Unique</strong>
                    </div>
                    <div className="col text-center">
                      <strong>Masking</strong>
                    </div>
                    <div className="col text-center">
                      <strong>Editable</strong>
                    </div>
                    <div className="col text-end">
                      <strong style={{ paddingRight: "10px" }}>Actions</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                maxHeight: "60vh",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              {" "}
              <div className="row mb-0">
                <div className="col px-2 mb-0">
                  <div
                    className="card min-w-600px my-1"
                    style={{ cursor: "grab", padding: "7px" }}
                  >
                    <div className="row align-items-center">
                      <div
                        className="col-4 text-start ps-lg-10 ps-4"
                        style={{
                          justifyContent: "space-between",
                          display: "flex",
                          width: "34%",
                          paddingRight: "0px",
                        }}
                      >
                        <label>Account name</label>
                        <div></div>
                      </div>
                      <div
                        className="col-2 d-flex align-items-end dropdown"
                        style={{
                          textAlign: "justify",
                          justifyContent: "start",
                          width: "10%",
                        }}
                      >
                        <div className="w-100 mw-150px">
                          <label className="form form-label">Text</label>
                        </div>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-4 fs-2 bi bi-x"
                          style={{ cursor: "pointer", color: "black" }}
                        ></i>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-4 fs-2 bi bi-x"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-4 fs-2 bi bi-x"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-4 fs-2 bi bi-check2"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                      <div className="col text-end d-flex"></div>
                    </div>
                  </div>

                  <div
                    className="card min-w-600px my-1"
                    style={{ cursor: "grab", padding: "7px 0px" }}
                  >
                    <div className="row align-items-center">
                      <div
                        className="col-4 text-start ps-lg-10 ps-4"
                        style={{
                          justifyContent: "space-between",
                          display: "flex",
                          width: "34%",
                          paddingRight: "0px",
                        }}
                      >
                        <label>First name</label>
                        <div></div>
                      </div>
                      <div
                        className="col-2 d-flex align-items-end dropdown"
                        style={{
                          textAlign: "justify",
                          justifyContent: "start",
                          width: "10%",
                        }}
                      >
                        <div className="w-100 mw-150px">
                          <label className="form form-label">Text</label>
                        </div>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-4 fs-2 bi bi-x"
                          style={{ cursor: "pointer", color: "black" }}
                        ></i>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-4 fs-2 bi bi-x"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-4 fs-2 bi bi-x"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-5 fs-2 bi bi-check2"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                      <div className="col text-end d-flex"></div>
                    </div>
                  </div>

                  <div
                    className="card min-w-600px my-1"
                    style={{ cursor: "grab", padding: "7px 0px" }}
                  >
                    <div className="row align-items-center">
                      <div
                        className="col-4 text-start ps-lg-10 ps-4"
                        style={{
                          justifyContent: "space-between",
                          display: "flex",
                          width: "34%",
                          paddingRight: "0px",
                        }}
                      >
                        <label>Last name</label>
                        <div></div>
                      </div>
                      <div
                        className="col-2 d-flex align-items-end dropdown"
                        style={{
                          textAlign: "justify",
                          justifyContent: "start",
                          width: "10%",
                        }}
                      >
                        <div className="w-100 mw-150px">
                          <label className="form form-label">Text</label>
                        </div>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-4 fs-2 bi bi-x"
                          style={{ cursor: "pointer", color: "black" }}
                        ></i>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-4 fs-2 bi bi-x"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-4 fs-2 bi bi-x"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-5 fs-2 bi bi-check2"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                      <div className="col text-end d-flex"></div>
                    </div>
                  </div>

                  <div
                    className="card min-w-600px my-1"
                    style={{ cursor: "grab", padding: "7px 0px" }}
                  >
                    <div className="row align-items-center">
                      <div
                        className="col-4 text-start ps-lg-10 ps-4"
                        style={{
                          justifyContent: "space-between",
                          display: "flex",
                          width: "34%",
                          paddingRight: "0px",
                        }}
                      >
                        <label>Email</label>
                        <div></div>
                      </div>
                      <div
                        className="col-2 d-flex align-items-end dropdown"
                        style={{
                          textAlign: "justify",
                          justifyContent: "start",
                          width: "10%",
                        }}
                      >
                        <div className="w-100 mw-150px">
                          <label className="form form-label">Email</label>
                        </div>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-4 fs-2 bi bi-x"
                          style={{ cursor: "pointer", color: "black" }}
                        ></i>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-4 fs-2 bi bi-x"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-4 fs-2 bi bi-x"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-5 fs-2 bi bi-check2"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                      <div className="col text-end d-flex"></div>
                    </div>
                  </div>

                  <div
                    className="card min-w-600px"
                    style={{ cursor: "grab", padding: "7px 0px" }}
                  >
                    <div className="row align-items-center">
                      <div
                        className="col-4 text-start ps-10"
                        style={{
                          justifyContent: "space-between",
                          display: "flex",
                          width: "34%",
                          paddingRight: "0px",
                        }}
                      >
                        <label>Phone number</label>
                        <div></div>
                      </div>
                      <div
                        className="col-2 d-flex align-items-end dropdown"
                        style={{
                          textAlign: "justify",
                          justifyContent: "start",
                          width: "10%",
                        }}
                      >
                        <div className="w-100 mw-150px">
                          <label className="form form-label">Number</label>
                        </div>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-4 fs-2 bi bi bi-check2"
                          style={{ cursor: "pointer", color: "black" }}
                        ></i>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-4 fs-2 bi bi-x"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-4 fs-2 bi bi-x"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                      <div className="col text-center">
                        <i
                          className="me-5 fs-2 bi bi-check2"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                      <div className="col text-end d-flex"></div>
                    </div>
                  </div>
                </div>
              </div>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <DndProvider backend={HTML5Backend}>
                  {workflowSelected && (
                    <div className="row">
                      <div
                        className="col px-2"
                        style={{ flex: 1, overflowY: "auto" }}
                      >
                        {ticketFields.map((field: any, index: any) => (
                          <DraggableAccountField
                            key={index}
                            field={field}
                            index={index}
                            moveField={moveField}
                            expandForMoreSpecifications={
                              expandForMoreSpecifications
                            }
                            setExpandForMoreSpecifications={
                              setExpandForMoreSpecifications
                            }
                            setEditDropdownListItem={setEditDropdownListItem}
                            editDropdownListItem={editDropdownListItem}
                            refetch={refetch}
                            editFieldsHandler={editFieldsHandler}
                            deleteDataHandler={deleteDataHandler}
                            setLiftingDropData={setLiftingDropData}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </DndProvider>
              )}
            </div>
            <div className="text-end mt-4">
              <button
                type="submit"
                className="btn btn-sm btn-primary"
                onClick={handleBulkSorting}
                disabled={!isModified || isLoading} // Disable the button if there are no modifications or if it's loading
              >
                {isLoading ? (
                  <>
                    Saving... <Loader />
                  </>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CampaignFields;
