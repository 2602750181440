/* do we need to make the user do a selction of team when marking the new field as mandatory in the add new field to ticket form? */
/* 
not isEditable in user field
toast message caps start
team select menu remove: ticket fields
attention divert to submit button
caps for all radio labels in add modal
automatic conversion to capital case of form feeds
sequencing of fields draggable
drop a mail to Omar to schedule a time to connect to discuss API needed to save & refer nested JSON save
 */

import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import {
  useBulkSortingMutation,
  useDynamicFieldDefinitionsQuery,
  useEditCheckBoxMutation,
  useEditDropwDownMutation,
  useTeamsQuery,
  useWorkflowsQQuery,
} from "../../../../Services/settingsAPIs/CommonAPI";
import {
  defaultModalStyles,
  standardModalStyles,
} from "../../../common/CustomModalStyles";
import AddNewFieldModal from "./AddNewFieldModal";
import {
  useAddChoiceToDynamicFieldDDMutation,
  useAddOptionToDynamicFieldCheckboxMutation,
  useDeleteOptionOfDynamicFieldCheckboxMutation,
  useDeleteTicketFieldChoiceMutation,
  useDeleteTicketFieldMutation,
  useEditChoiceToDynamicFieldDDMutation,
} from "../../../../Services/settingsAPIs/TicketSettingsAPI";
import { toast } from "react-toastify";
import CommonDeleteModal, {
  DeleteMessageBody,
} from "../../common/CommonDeleteModal";
import NestedDDConf from "../../common/NestedDDConf";
import PagenationUtility from "../../common/PagenationUtility";
import { toSentenceCase } from "../../../../utils/functions/toSentenceCase";
import LoadingSpinner from "../../../LoadingSpinner";
import EditTicketFieldModal from "./EditTicketFieldModal";
import { chownSync } from "fs";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DragTicketField from "./DragTicketField";
import { Loader } from "react-bootstrap-typeahead";

// const initialTicketFields = [
//   {
//     label: "First Name",
//     type: "Text",
//     subtype: "",
//     isMandatory: false,
//     isUnique: false,
//     moreSpecifications: { dropdownoptions: [] },
//   },
//   {
//     label: "Last Name",
//     type: "Text area",
//     subtype: "",
//     isMandatory: false,
//     isUnique: false,
//     moreSpecifications: { dropdownoptions: [] },
//   },
//   {
//     label: "Employee ID",
//     type: "Number",
//     subtype: "",
//     isMandatory: true,
//     isUnique: false,
//     moreSpecifications: { dropdownoptions: [] },
//   },
//   {
//     label: "Mobile number",
//     type: "Number",
//     subtype: "",
//     isMandatory: false,
//     isUnique: false,
//     moreSpecifications: { dropdownoptions: [] },
//   },
// ];

const initialTicketFieldsAPIlike = [
  /* {
    id: 14,
    field_type: "text",
    label: "description",
    is_required: false,
    model_name: "ticket_name",
    ticket_name: 1,
    choices: [],
    identifier: "field_14",
    campaign_name: null,
    multiple_choices: [],
    multiple_level: null,
    is_unique: false,
    is_editable: true,
    team: [],
    field_length: null
  },
  {
    id: 15,
    field_type: "text-area",
    label: "mandate field",
    is_required: true,
    model_name: "ticket_name",
    ticket_name: 1,
    choices: [],
    identifier: "field_15",
    campaign_name: null,
    multiple_choices: [],
    multiple_level: null,
    is_unique: false,
    is_editable: true,
    team: [],
    field_length: null
  } */
];

interface LiftingDropData {
  choice?: string;
  fieldId?: string;
}

const TicketFields = () => {
  const [ticketFields, setTicketFields] = useState(initialTicketFieldsAPIlike);
  const { data: theWorkflowsFetched } = useWorkflowsQQuery({});
  const [theworkflows, setTheWorkflows] = useState<any>([]);
  const [workflowSelected, setWorkflowSelected] = useState<number>();
  const [expandForMoreSpecifications, setExpandForMoreSpecifications] =
    useState({ expansionOn: -1, expanded: false, expandForFieldType: "" });
  const [isAddTicketFieldsModalOpen, setIsAddTicketFieldsModalOpen] =
    useState(false);
  const {
    data: dynamicFieldsData,
    refetch,
    isLoading,
  } = useDynamicFieldDefinitionsQuery("ticket_name", {});
  const [liftingDropData, setLiftingDropData] = useState<LiftingDropData>({});

  const [newDropdownListItem, setNewDropdownListItem] = useState("");
  const [newCheckboxOption, setNewCheckboxOption] = useState("");
  // const [] = useState(false)
  const [editDropdownListItem, setEditDropdownListItem] = useState({
    enabled: false,
    forChoiceId: -1,
    currentVal: "",
    fieldId: "",
  });
  const [editCheckboxListOption, setEditCheckboxListOption] = useState({
    enabled: false,
    forChoiceId: -1,
    currentVal: "",
    fieldId: "",
  });
  const [fieldForDeletion, setFieldForDeletion] = useState<number>();
  const [multiLevelConfiguration, setMultiLevelConfiguration] = useState<any>(
    {}
  );
  const [currentData, setCurrentData] = useState<any>();
  const [isDeleteButtonClicked, setIsDeleteButtonClicked] = useState(false);
  const [isModified, setIsModified] = useState(false);

  const [isEditFieldModalOpen, setIsEditFieldModalOpen] =
    useState<boolean>(false);
  const [storingContactData, setStoringContactData] = useState(null);

  const [storingFieldData, setStoringFieldData] = useState(null);
  const [triggerAddChoiceToDDfield] = useAddChoiceToDynamicFieldDDMutation();
  const [triggerEditChoiceToCheckboxField] =
    useEditChoiceToDynamicFieldDDMutation();
  const [triggerAddOptionToCheckboxField] =
    useAddOptionToDynamicFieldCheckboxMutation();
  const [triggerDeleteChoiceFromTicketField] =
    useDeleteTicketFieldChoiceMutation();
  const [triggerDeleteOptionFromCheckboxTicketField] =
    useDeleteOptionOfDynamicFieldCheckboxMutation();
  const [triggerDeletionOfFieldMutation] = useDeleteTicketFieldMutation();
  const [triggerEditDrowDown] = useEditDropwDownMutation();
  const [triggerEditCheckbox] = useEditCheckBoxMutation();
  const [triggerBulkSorting] = useBulkSortingMutation();

  const openAddTicketFieldModal = () => {
    if (workflowSelected) {
      setIsAddTicketFieldsModalOpen(true);
    } else {
      toast.error("Select a workflow first");
    }
  };

  const closeAddTicketFieldModal = () => {
    setIsAddTicketFieldsModalOpen(false);
  };

  const closeEditFieldModal = () => {
    setIsEditFieldModalOpen(false);
  };

  const [isDeleteTicketFieldModalOpen, setIsDeleteTicketFieldModalOpen] =
    useState<boolean>(false);
  const openDeleteTicketFieldModal = (fieldId: number) => {
    setFieldForDeletion(fieldId);
    setIsDeleteTicketFieldModalOpen(true);
  };
  const closeDeleteTicketFieldModal = () => {
    setIsDeleteTicketFieldModalOpen(false);
    refetch();
  };

  const handleDropdownNewListItemEdit = (event: any) => {
    setNewDropdownListItem(toSentenceCase(event.target.value));
  };
  const handleCheckboxNewOptionEdit = (event: any) => {
    setNewCheckboxOption(toSentenceCase(event.target.value));
  };

  const editFieldsHandler = (fields: any) => {
    setIsEditFieldModalOpen(true);
    setStoringFieldData(fields);
  };

  useEffect(() => {
    theWorkflowsFetched && setTheWorkflows(theWorkflowsFetched.results);
  }, [theWorkflowsFetched]);
  useEffect(() => {
    workflowSelected &&
      setTicketFields(
        dynamicFieldsData.filter(
          (df: any) => df.ticket_name === workflowSelected
        )
      );
  }, [workflowSelected, dynamicFieldsData]);

  const dropdownOptionDelete = (id: any, choice: any) => {
    if (choice === "checkbox") {
      triggerDeleteOptionFromCheckboxTicketField({ id });
      toast.success("Deletion processed");
      refetch();
    }
    if (choice === "dropdown") {
      triggerDeleteChoiceFromTicketField({ id });
      toast.success("Deletion processed");
      refetch();
    }
  };

  const updateDropdownOption = (choiceId: any, choiceValue: any) => {
    triggerEditChoiceToCheckboxField({
      choice: choiceValue,
      field: choiceId,
    } as any);

    refetch();
  };

  const saveEditFieldHandler = async () => {
    const editObj = {
      choice: editDropdownListItem?.currentVal,
      field: editDropdownListItem?.fieldId,
    };

    try {
      const response = await triggerEditDrowDown({
        body: editObj,
        id: editDropdownListItem?.forChoiceId,
      });
      if (response?.data) {
        toast.success("Field edited");
        refetch();
      } else {
        toast.error("Unable to add field");
      }
    } catch (error) {
      toast.error("An error occured");
    }
  };

  const saveEditCheckBoxdHandler = async () => {
    const editObj = {
      choice: editCheckboxListOption?.currentVal,
      field: editCheckboxListOption?.fieldId,
    };

    try {
      const response = await triggerEditCheckbox({
        body: editObj,
        id: editCheckboxListOption?.forChoiceId,
      });
      if (response?.data) {
        toast.success("Field edited");
        refetch();
      } else {
        toast.error("Unable to add field");
      }
    } catch (error) {
      toast.error("An error occured");
    }
  };

  const moveField = (fromIndex, toIndex) => {
    const updatedFields = [...ticketFields];
    const [movedField] = updatedFields.splice(fromIndex, 1);
    updatedFields.splice(toIndex, 0, movedField);
    setTicketFields(updatedFields);
    setIsModified(true); // Set isModified to true when the fields are modified
  };

  const deleteDataHandler = async (field: any) => {
    setIsDeleteButtonClicked(true);
    setStoringContactData(field);
  };

  const handleBulkSorting = async () => {
    try {
      const payload = ticketFields.map((field, index) => ({
        id: field.id,
        order_value: index + 1,
      }));
      await triggerBulkSorting({ body: payload });
      toast.success("Sequencing updated successfully");
      setIsModified(false); // Reset isModified to false after successful sorting
    } catch (error) {
      toast.error("Error occurred during sequencing ");
    }
  };

  return (
    <div className="card-body px-0 px-lg-10" style={{overflowY: "hidden"}}>
      <div className="row">
        <div className="col">
          <span className="input-group-text" id="basic-addon1">
            Ticket name fields
          </span>
        </div>
      </div>

      <div className="row my-4">
        <div className="col-12">
          <div className="card-toolbar d-lg-flex gap-5">
            <div className="d-flex flex-column">
              <div className="min-w-250px">
                <select
                  className="form-select"
                  onChange={(e) =>
                    setWorkflowSelected(Number.parseInt(e.target.value))
                  }
                >
                  <option value={0} selected>
                    Select a Workflow
                  </option>
                  {theworkflows &&
                    theworkflows.map((theworkflow: any) => (
                      <option value={theworkflow.id}>{theworkflow.name}</option>
                    ))}
                </select>
              </div>
            </div>
            <span
              className="input-group-text bg-white border-0 ms-auto align-self-start"
              id="basic-addon1"
            >
              <div className="ms-auto d-flex">
                <button
                  className="input-group-text form-control btn-sm form-control align-items-center fw-bold min-w-150px"
                  onClick={openAddTicketFieldModal}
                >
                  <i className="bi bi-plus-lg me-2"></i>
                  <span>Option</span>
                </button>
              </div>
            </span>
          </div>
        </div>
        <ReactModal
          isOpen={isAddTicketFieldsModalOpen}
          onRequestClose={closeAddTicketFieldModal}
          style={standardModalStyles}
          contentLabel="Add a new Account Field"
        >
          <AddNewFieldModal
            closeModal={closeAddTicketFieldModal}
            workflowSelected={workflowSelected}
            refetch={refetch}
          />
        </ReactModal>
        <ReactModal
          isOpen={isEditFieldModalOpen}
          onRequestClose={closeEditFieldModal}
          style={defaultModalStyles}
        >
          <EditTicketFieldModal
            closeModal={closeEditFieldModal}
            setFieldsData={setTicketFields}
            fieldsData={ticketFields}
            storingFieldData={storingFieldData}
            refetch={refetch}
          />
        </ReactModal>
        <ReactModal
          isOpen={isDeleteTicketFieldModalOpen}
          onRequestClose={closeDeleteTicketFieldModal}
          style={defaultModalStyles}
          contentLabel="Delete a selected status"
        >
          <CommonDeleteModal
            closeModal={closeDeleteTicketFieldModal}
            deleteAPItrigger={triggerDeletionOfFieldMutation}
            deletionIdentifier={fieldForDeletion}
            messageBody={
              <DeleteMessageBody
                bodyParams={{
                  descriptionPromptEnabled: false,
                  deletionOf: "ticket field",
                }}
              />
            }
          />
        </ReactModal>
      </div>

      {workflowSelected && (
        <>
          <div className="row " style={{maxHeight: "60vh"}}>
            {/* <div className="col"> */}
              {/* The Table Header */}
          <div className="row" style={{paddingRight: "0"}}>


              <div
              className="col px-2"
              style={{ paddingRight: "5px", paddingLeft: "5px" }}
            >

              <div
                className="card my-1"
                style={{ backgroundColor: "#E4E4E47F" }}
              >
                <div className="row align-items-center py-4">
                  <div className="col-lg-4 col text-start ps-10">
                    <label>
                      <strong>Label</strong>
                    </label>
                  </div>
                  <div className="col-lg-2 col text-center">
                    <strong>Field Type</strong>
                  </div>
                  <div className="col text-center">
                    <label>
                      <strong>Mandatory</strong>
                    </label>
                  </div>
                  <div className="col text-center">
                    <label>
                      <strong>Unique</strong>
                    </label>
                  </div>
                  <div className="col text-center">
                    <label>
                      <strong>Masking</strong>
                    </label>
                  </div>
                  <div className="col text-center">
                    <label>
                      <strong>Editable</strong>
                    </label>
                  </div>
                  <div
                    className="col text-end mx-4"
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      right: "10",
                    }}
                  >
                    <strong>Actions</strong>
                  </div>
                </div>
              </div>
              </div>
              </div>

              <div style={{maxHeight: "48vh", overflowY: "scroll", overflowX: "hidden"}}>

              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <DndProvider backend={HTML5Backend}>
                  {workflowSelected && (
                    <div className="row mx-0">
                      <div className="" style={{ padding: "0" }}>
                        {ticketFields?.map((field: any, index: any) => (
                          <DragTicketField
                            key={index}
                            field={field}
                            index={index}
                            moveField={moveField}
                            expandForMoreSpecifications={
                              expandForMoreSpecifications
                            }
                            setExpandForMoreSpecifications={
                              setExpandForMoreSpecifications
                            }
                            setEditDropdownListItem={setEditDropdownListItem}
                            editDropdownListItem={editDropdownListItem}
                            refetch={refetch}
                            editFieldsHandler={editFieldsHandler}
                            deleteDataHandler={deleteDataHandler}
                            setLiftingDropData={setLiftingDropData}
                            openDeleteTicketFieldModal={
                              openDeleteTicketFieldModal
                            }
                            setTicketFields={setTicketFields}
                            currentData={currentData}
                            workflowSelected={workflowSelected}
                            ticketFields={ticketFields}
                            setCurrentData={setCurrentData}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </DndProvider>
              )}
            </div>
            <div className="text-end mt-4">
            <button
              type="submit"
              className="btn btn-sm btn-primary"
              onClick={handleBulkSorting}
              disabled={!isModified || isLoading} // Disable the button if there are no modifications or if it's loading
            >
              {isLoading ? (
                <>
                  Saving... <Loader />
                </>
              ) : (
                "Save"
              )}
            </button>
          </div>
          </div>
    
        </>
      )}
    </div>
  );
};

export default TicketFields;
