import React, {useState, useEffect} from 'react'
import { useEditQueueDataQuery, useEditQueueMutation } from '../../../../Services/Telephony/Telephony';
import { toast } from 'react-toastify';
import { Loader } from 'react-bootstrap-typeahead';

interface IPROPS {
    refetch: any;
    closeModal: any;
    queueId:any

}

const EditQueueCreation: React.FC<IPROPS> = ({closeModal, refetch, queueId}) => {

    const [queue, setQueue] = useState('')
    const [penalty, setPenalty] = useState('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loader, setLoader] = useState(false)

 const {data: editQueueData, isLoading, status: editQueueStatus} =  useEditQueueDataQuery(queueId)
 const [editQueueTrigger, {isLoading: editQueueLoading, status: editQueue}] = useEditQueueMutation()


 useEffect(() => {
    if (editQueue === "pending") {
        setLoader(true)
    }
    else {
        setLoader(false)

    }
 }, [])


    const handleSubmit = async (e) => {
        e.preventDefault();

        const obja = {
            name: queue,
            penalty: penalty
        };

        const response = await editQueueTrigger({ body: obja, id: queueId });

        try {
            if (response?.data) {
                toast.success("Edited successfully");
                closeModal();

                refetch()

                setIsButtonDisabled(true);

                setTimeout(() => {
                  setIsButtonDisabled(false);
                }, 3000);
            } else if ("data" in response?.error) {
                const errorData = response?.error?.data as {
                    extension: string[];
                };
                const errorMessage = errorData?.extension[0];
                toast.error(errorMessage);

                setIsButtonDisabled(true);

                setTimeout(() => {
                  setIsButtonDisabled(false);
                }, 3000);
            }
        } catch (error) {
            toast.error("An error occured");

            setIsButtonDisabled(true);

            setTimeout(() => {
              setIsButtonDisabled(false);
            }, 3000);
        }
    };

    
    useEffect(() => {
        if (editQueueData) {
            setQueue(editQueueData?.name)
            setPenalty(editQueueData?.penalty)

        }

    }, [editQueueData])

  return (
    <div style={{display: "flex", flexDirection: "column", gap: "20px"}}>
        <div><label>Queue</label><input className='form-control' value={queue} onChange={(e) => setQueue(e.target.value)}/></div>
        <div><label>Penalty</label><input className='form-control' value={penalty} onChange={(e) => setPenalty(e.target.value)}/></div>
        <div
                style={{
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "end",
                }}
            >
                <button
                
               type="reset"
                    className="btn btn-sm btn-white me-2"
                
                onClick={() => closeModal()}>Cancel</button>
                <button className="btn btn-primary" 
                onClick={handleSubmit} 
                disabled={isButtonDisabled}>
                    {loader ? <>Saving...<Loader /></> : "Save"}
                </button>
            </div>
    </div>
  )
}

export default EditQueueCreation