import React, { useState } from "react";
import Chart from "react-apexcharts";

interface IProps {
}

const ResolutionBeyondSLA: React.FC<IProps> = () => {
  const [chartData, setChartData] = useState<any>({
    series: [44, 55, 41, 17, 15],
    options: {
      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
          dataLabels: {
            offset: 0,
          },
        },
      },
      grid: {
        padding: {
          bottom: -80,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      dataLabels: {
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex]; // Display actual values instead of percentages
        },
        textAnchor: "middle",
        distributed: false,
      },
    },
  });

  return (
    <div className="card card-flush border-0 mb-2">
      {/*begin::Header*/}
      <div className=" d-flex align-items-center card-header">
        {/*begin::Title*/}
        <span className="fs-2 fw-bold text-gray-800 me-2 lh-1 ls-n2">
          Resolution beyond SLA
        </span>
      </div>
      {/*end::Header*/}
      {/*begin::Body*/}
      <div className="card-body d-flex justify-content-between flex-column pt-1 px-0 pb-0">
        {/* begin::Wrapper */}
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="donut"
          height={119}
        />
        {/* end::Wrapper */}
      </div>
      {/*end::Body*/}
    </div>
  );
};

export default ResolutionBeyondSLA;
