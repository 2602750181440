import React from "react";
interface IPROPS {
  emailDashboardData: any
}

const DispositionCards: React.FC<IPROPS> = ({ emailDashboardData }) => {
  // Static data for the table
  const totalDispositionCount = emailDashboardData?.top_five_dispositions?.reduce((total: number, item: any) => total + item.count, 0);


  return (
    <div className="card card-flush h-xl-10">
      {/*begin::Header*/}
      <div className="card-header pt-5 mx-n4">
        <h3 className="card-title align-items-start flex-column">
          <div className="d-flex align-items-center mb-2">
            <span className="fs-2 fw-bold text-dark lh-1 ls-n2">
              Top 5 Dispositions
            </span>
          </div>
        </h3>
      </div>
      {/*end::Header*/}
      {/*begin::Body*/}
      <div className="table-responsive fs-2 py-0 mx-5">
        <table className="table">
          <thead className="table-secondary">
            <tr>
              <th scope="col">Type</th>
              {/* <th scope="col">Sub type</th> */}
              <th scope="col">Count</th>
              <th scope="col">Percentage</th>
            </tr>
          </thead>
          <tbody>
            {emailDashboardData && emailDashboardData?.top_five_dispositions?.map((dispositionInfo: any, index: any) => {
              const percentage = ((dispositionInfo?.count / totalDispositionCount) * 100).toFixed(2);
              return (
                <tr key={index}>
                  <td>{dispositionInfo?.disposition_name}</td>
                  <td>{dispositionInfo?.count}</td>
                  <td>{percentage}%</td>
                </tr>
              );
            })}


          </tbody>
        </table>
      </div>
      {/*end::Body*/}
    </div>
  );
};

export default DispositionCards;
