import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSaveDespositionMutation } from "../../../../Services/general/General";
import { Loader } from "react-bootstrap-typeahead";
import { useAddSubTeleDispositionMutation } from "../../../../Services/Telephony/Telephony";

interface IProps {
    closeUpdateModal: () => void;
    setData: (data: any) => void;
    data: any;
    currentNode: any;
    SetLiftingAddDate: (data: any) => void;
    selectedTeam: any;
    refetch: any;
    dispositionId: any;
}

const UpdateTeleDisposition: React.FC<IProps> = ({
    closeUpdateModal,
    setData,
    data,
    currentNode,
    SetLiftingAddDate,
    selectedTeam,
    refetch,
    dispositionId,
}) => {
    const [topic, setTopic] = useState(currentNode.disposition || "");
    const [subDispositions, setSubDispositions] = useState(
        currentNode.sub_disposition || []
    );
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [trigger, { isLoading }] = useAddSubTeleDispositionMutation();

    // Handle input change for the main topic
    const handleTopicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTopic(event.target.value);
    };

    // Handle input change for a sub-disposition
    const handleSubDispositionChange = (
        index: number,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const updatedSubDispositions = [...subDispositions];
        updatedSubDispositions[index] = event.target.value;
        setSubDispositions(updatedSubDispositions);
    };

    // Add a new sub-disposition
    const addSubDisposition = () => {
        setSubDispositions([...subDispositions, ""]); // Add an empty string for the new sub-disposition
    };

    // Remove a sub-disposition
    const removeSubDisposition = (index: number) => {
        const updatedSubDispositions = subDispositions.filter(
            (_, i) => i !== index
        );
        setSubDispositions(updatedSubDispositions);
    };

    const updateNodeTopic = async () => {
        setIsSubmitting(true); // Disable the button and prevent multiple submissions

        // Construct the updated payload
        const payload = {
            disposition: topic.trim(),
            sub_disposition: subDispositions,
            team: selectedTeam,
            type: currentNode?.type,
        };

        try {
            // Trigger the API call with the constructed payload
            const response = await trigger({
                body: payload,
                id: currentNode?.id,
            });

            if (response?.data) {
                toast.success("Updated Successfully");
                refetch(); // Refresh the data
                closeUpdateModal(); // Close the modal
            } else {
                throw new Error("Failed to update disposition");
            }
        } catch (error) {
            toast.error("An error occurred while updating");
        } finally {
            setIsSubmitting(false); // Re-enable the button after the process
        }
    };

    useEffect(() => {
        if (data) {
            SetLiftingAddDate(data); // Use the original data with levels intact
        }
    }, [data]);

    // Determine if the "Update" button should be disabled
    const isUpdateButtonDisabled = !topic.trim() || isSubmitting;

    return (
        <div>
            <div style={{ maxHeight: "25vh", overflow: "scroll" }}>
                <div className="mt-4">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Topic"
                        value={topic}
                        onChange={handleTopicChange}
                        disabled={isSubmitting} // Disable input while submitting
                    />
                </div>

                <div className="d-flex justify-content-center mt-3">
                    <button
                        type="button"
                        className="btn btn-sm btn-secondary"
                        onClick={addSubDisposition}
                        disabled={isSubmitting}
                    >
                        Add sub disposition
                    </button>
                </div>
                {/* If there are sub-dispositions, render them as editable fields */}
                {subDispositions.length > 0 && (
                    <div className="mt-4">
                        <h5>Sub dispositions</h5>
                        {subDispositions.map((sub, index) => (
                            <div
                                key={index}
                                className="d-flex align-items-center mb-2"
                            >
                                <input
                                    type="text"
                                    className="form-control me-2"
                                    placeholder={`Sub Disposition ${index + 1}`}
                                    value={sub}
                                    onChange={(event) =>
                                        handleSubDispositionChange(index, event)
                                    }
                                    disabled={isSubmitting}
                                />

                                <span>
                                    <i
                                        className="bi bi-x"
                                        style={{color: "black"}}
                                        onClick={() =>
                                            removeSubDisposition(index)
                                        }
                                    ></i>
                                </span>
                                {/* <button
                className="btn btn-sm btn-danger"
                onClick={() => removeSubDisposition(index)}
                disabled={isSubmitting}
              >
                Removeee
              </button> */}
                            </div>
                        ))}
                    </div>
                )}

                {/* Button to add a new sub-disposition */}
               
            </div>

            <div className="d-flex justify-content-end mt-4">
                <button
                    type="reset"
                    className="btn btn-sm btn-white  me-2"
                    onClick={closeUpdateModal}
                    disabled={isSubmitting}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={updateNodeTopic}
                    disabled={isUpdateButtonDisabled}
                >
                    {isLoading ? (
                        <>
                            Saving... <Loader />
                        </>
                    ) : (
                        "Save"
                    )}
                </button>
            </div>
        </div>
    );
};

export default UpdateTeleDisposition;
