import React from "react";

const ChatRoom = ({ setSelectedChatRoom }) => {
  const userData = [
    {
      id: 1,
      name: "Melody Macy",
      email: "melody@altbox.com",
      lastSeen: "1 week",
      avatar: "/media/avatars/300-1.jpg",
    },
    {
      id: 2,
      name: "Max Smith",
      email: "max@kt.com",
      lastSeen: "3 hrs",
      avatar: "/media/avatars/300-2.jpg",
    },
    {
      id: 3,
      name: "Sean Bean",
      email: "sean@dellito.com",
      lastSeen: "2 weeks",
      avatar: "/media/avatars/300-3.jpg",
    },
    {
      id: 4,
      name: "Brian Cox",
      email: "brian@exchange.com",
      lastSeen: "2 weeks",
      avatar: "/media/avatars/300-4.jpg",
    },
    {
      id: 5,
      name: "Mikaela Collins",
      email: "mik@pex.com",
      lastSeen: "1 week",
      avatar: "/media/avatars/300-5.jpg",
    },
  ];

  const handler = (id) => {
    setSelectedChatRoom(id);
  };

  return (
    <div className="card card-flush">
      {/* Card header */}
      <div className="card-header pt-7" id="kt_chat_contacts_header">
        <form className="w-100 position-relative" autoComplete="off">
          <i className="bi bi-search fs-3 text-gray-500 position-absolute top-50 ms-4 translate-middle-y">
            <span className="path1" />
            <span className="path2" />
          </i>
          <input
            type="text"
            className="form-control form-control-solid px-10 mt-1 fs-7"
            name="search"
            placeholder="Search by username or email..."
          />
        </form>
      </div>
      {/* Card body */}
      <div className="card-body pt-5" id="kt_chat_contacts_body">
        {/* User list */}
        <div
          className="scroll-y me-n10 pe-5 h-200px h-lg-auto"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_header, #kt_app_header, #kt_toolbar, #kt_app_toolbar, #kt_footer, #kt_app_footer, #kt_chat_contacts_header"
          data-kt-scroll-wrappers="#kt_content, #kt_app_content, #kt_chat_contacts_body"
          data-kt-scroll-offset="5px"
          style={{ maxHeight: 574 }}
        >
          {userData.map((user, index) => (
            <div className="d-flex flex-stack py-4" key={index}>
              <div className="d-flex align-items-center">
                <div className="symbol symbol-35px symbol-circle">
                  {user.avatar ? (
                    <img alt="Avatar" src={user.avatar} />
                  ) : (
                    <span className="symbol-label bg-light-primary text-primary fs-6 fw-bolder">
                      {user.name[0]}
                    </span>
                  )}
                </div>
                <div className="ms-5">
                  <div
                    className="fs-4 fw-bold text-gray-900 text-hover-primary mb-1"
                    onClick={() => handler(user.id)}
                  >
                    {user.name}
                  </div>
                  <div className="fw-semibold text-muted">{user.email}</div>
                </div>
              </div>
              <div className="d-flex flex-column align-items-end ms-2">
                <span className="text-muted fs-7 mb-1">{user.lastSeen}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChatRoom;
