
import React, { useEffect, useState } from "react";
import { CloseButton } from "react-bootstrap";
import {
    useCampaignChangeStatusQuery,
    useCampignsDynamicFieldsQuery, useSingleLeadMutation
} from "../../Services/campign/campignList";
import { useTeamsQuery } from "../../Services/settingsAPIs/CommonAPI"
import { useInviteUsersMutation } from "../../Services/user/Users";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { toast } from "react-toastify";
import clsx from "clsx";
import moment from "moment";
import axios from "axios";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InfoIcon from "@mui/icons-material/Info";
import {
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    IconButton,
    Button,
    Box,
    Chip, Tooltip,
} from "@mui/material";
import { Loader } from "react-bootstrap-typeahead";
import { capitalizeFirstLetter } from "../../components/reusableComponent/CapitalLetter";
import BulkLeadModel from "./BulkLeadModel";
import { useCampaignWorkflowDetailsQuery } from "../../Services/campaign/CampaignMapping";
import { set } from "lodash";
import { v4 as uuidv4 } from 'uuid';

interface IProps {
    closeModal: () => void;
    isBulk: any;
    setIsBulk: any;
    refetch: any;
    campaignId: any;
    value: any;
    setValue: any;
    setStoreKey: any;
    refetchWorkflow: any;
    selectedCampaign: any


}

interface ErrorState {
    [key: string]: string;
}
const CampaignLeadModel: React.FC<IProps> = ({
    closeModal,
    isBulk,
    setIsBulk,
    refetch,
    campaignId,
    value,
    setValue,
    setStoreKey,
    refetchWorkflow,
    selectedCampaign
}) => {
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    // const [statusType, setstatusType] = useState("active");
    // Ensure statusType is typed as string
    const [statusType, setStatusType] = useState<string>('');

    const { data: DynamicFieldsData } = useCampignsDynamicFieldsQuery({});

    const [singleLead, { isLoading }] = useSingleLeadMutation({});
    const [selectedOption, setSelectedOption] = useState<any>("");
    const [selectedTeams, setSelectedTeams] = useState<any[]>([]);
    const [theTeams, setTheTeams] = useState([]);
    const [branchUser, setBranchUser] = useState("false");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [username, setUsername] = useState("");
    const [accountname, setAccount] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhoneNumber] = useState("");
    const [selectedTeamId, setSelectedTeamId] = useState('');
    const userId = localStorage.getItem("User_Id");
    const [files, setFiles] = useState([]);
    const [selectedWorkflow, setSelectedWorkflow] = useState('');
    const [selectedWorkflowId, setSelectedWorkflowId] = useState<number | null>(null);
    const [workflowStatuses, setWorkflowStatuses] = useState<any[]>([]);
    const teamId = localStorage.getItem("teamId");
    const [users, setUsers] = useState<string[]>([]);
    const [selectedAssignee, setSelectedAssignee] = useState("");
    const [statusOptions, setStatusOptions] = useState([])
    const [status, setStatus] = useState("");
    const [statusId, setStatusId] = useState("");
    const [selectedStatusId, setSelectedStatusId] = useState("")
    console.log(selectedCampaign, "selectedCampaign")
    const [workflowNames, setWorkflowNames] = useState([]);
    const [] = useState(true);

    const [dynamicData, setDynamicData] = useState<any[]>([]);
    const [dynamicFields, setDynamicFields] = useState<any>("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [errors, setErrors] = useState<ErrorState>({
        account_name: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
    });

    console.log(dynamicData, "dynamicdatatt")

    const { data: teamsData } = useTeamsQuery({});
    useEffect(() => {
        if (teamsData) {
            setTheTeams(teamsData);
        }
    }, [teamsData]);

    useEffect(() => {
        if (selectedCampaign) {
            const usernames = selectedCampaign?.users?.map((user: any) => user) || [];
            setUsers(usernames);
        }
    }, [selectedCampaign]);

    useEffect(() => {
        if (DynamicFieldsData) {
            const filteredFields = DynamicFieldsData.filter((field: any) => field.campaign_name === campaignId);
            setDynamicData(filteredFields);
        }
    },

        [DynamicFieldsData, campaignId]);
    const handleToggleChange = () => {
        setIsBulk(!isBulk);
        setSelectedFiles(null);
    };

    const handleDropdownChange = (e: any) => {
        setIsButtonDisabled(false);
        setSelectedOption(e.target.value);
        setErrors({});
    };

    const apiPort = process.env.REACT_APP_API_PROTOCOL;

    const handleInputChange = (label, value) => {
        setIsButtonDisabled(false);
        setErrors({});
        setDynamicFields((prevState) => ({
            ...prevState,
            [label]: value,
        }));
    };

    const handleCheckboxChange = (
        event: React.ChangeEvent<{ value: any }>,
        key: string
    ) => {
        setIsButtonDisabled(false);

        setErrors({});
        setDynamicFields((prevFields) => ({
            ...prevFields,
            [key]: event.target.value,
        }));
    };


    console.log(users, "users")
    // Function to check if all teams are selected
    const isAllSelected = selectedTeams.length === theTeams.length;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const validateForm = () => {
        const newErrors: ErrorState = {};

        dynamicData.forEach((field: any) => {
            const fieldValue = dynamicFields[field.label];

            if (field.is_required && !fieldValue) {
                newErrors[field.label] = `${field.label} is required`;
            }

            // Example: Add phone number validation logic here
            if (
                field.is_required &&
                field.field_type === "tel-phone" &&
                fieldValue
            ) {
                // If you have specific phone validation, you could add it here
                const isValidPhone =
                    /^(?:\+?\d{1,3})?[ -]?\(?\d{1,4}?\)?[ -]?\d{1,4}[ -]?\d{1,4}(?: x\d{1,4})?$/.test(
                        fieldValue
                    );
                if (!isValidPhone) {
                    newErrors[
                        field.label
                    ] = `${field.label} must be a valid phone number`;
                }
            }



            // if (!username) {
            //     newErrors["username"] = "Username/Email is required";
            // }

            if (
                field.field_type === "check-box" &&
                field.is_required &&
                !fieldValue.length
            ) {
                newErrors[field.label] = `${field.label} is required`;
            }

            // if (!selectedTeams.length) {
            //     newErrors["teams"] = "Please select at least one team";
            // }

            // Add validation for role
            // if (!selectedOption) {
            //     newErrors["role"] = "Please select a role";
            // }

            if (!phonenumber) {
                newErrors["phone_number"] = "Mobile Number is required";
            } else {
                const phoneError = validatePhoneNumber(phonenumber);
                if (phoneError) {
                    newErrors["phone_number"] = phoneError;
                }
            }

            // if (!lastName) {
            //     newErrors["last_name"] = "Last name is required";
            // }
            // Check if workflow is selected 
            // if (!selectedWorkflow) { newErrors['workflow'] = 'Workflow is required'; }
            //  // Check if status is selected
            if (!status) {
                newErrors.status_type = "Status is required";
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };
    const validatePhoneNumber = (number) => {
        if (number.length < 10 || number.length > 10) {
            return "Mobile number must be exactly 10 digits";
        } if (!/^\d{10}$/.test(number)) {
            return "Mobile number must contain only digits";
        }
        return "";
    };
    const handlePhoneNumberChange = (e) => {
        const value = e.target.value; if (value.match(/^\d*$/)) { // Only allow numeric input
            setPhoneNumber(value); setErrors(errors?.phone_number ? {} : errors);
        }
    };

    const submitHandler = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            toast.error("Please fill all required fields.");
            setIsButtonDisabled(true);
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, 3000);
            return;
        }
        const phoneNumberError = validatePhoneNumber(phonenumber);
        if (phoneNumberError) {
            setErrors(
                { ...errors, phone_number: phoneNumberError });
            toast.error("Please fill all required fields.");
            return;
        }
        setIsButtonDisabled(true);

        const dynamicFieldData = Object.keys(dynamicFields).reduce((acc, key) => {
            const field = dynamicData.find((field) => field.label === key);
            if (field?.field_type === "date") {
                const formattedDate = new Date(dynamicFields[key]).toISOString().replace("Z", "+00:00");
                acc[key] = formattedDate;
            } else {
                acc[key] = dynamicFields[key] || "";
            }
            return acc;
        }, {});

        // let statusId = null;
        // let closedStatusId = null;
        // if (statusType === "wip") {
        //     statusId = workflowStatuses.find(status => status.status_type === "wip")?.status.id;
        // } else
        //     if (statusType === "closed") {
        //         closedStatusId = workflowStatuses.find(status => status.status_type === "closed")?.status.id;
        //     }
        const obj = {
            account_name: accountname,
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone_number: phonenumber,
            dynamic_fields: dynamicFieldData,
            campaign: campaignId,
            status_type: status,
            assignee: Number(userId),
            attachments: files,
            status: status === "wip" ? statusId : null, // WIP
            closed_status: status === "closed" ? statusId : null, // closed

        };

        singleLead(obj)
            .unwrap()
            .then((response) => {
                toast.success("Lead added successfully!");
                // closeModal();
                refetch();
                refetchWorkflow();
                setEmail('')
                setFirstName("")
                setLastName('')
                setPhoneNumber("")
                setDynamicFields('')
                setAccount('')
                setStatusType("")
                setSelectedAssignee("")
                setFiles([])
                setStatus("")
                setSelectedStatusId("")
            })
            .catch((error) => {
                console.error("API Error:", error);

                if (error?.data) {
                    Object.keys(error.data).forEach((key) => {
                        const messages = error.data[key];
                        const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);

                        if (Array.isArray(messages)) {
                            messages.forEach((message) => {
                                toast.error(` ${message}`);
                            });
                        } else {
                            toast.error(`${capitalizedKey}: ${messages}`);
                        }
                    });
                } else {
                    toast.error("Lead added fail. Please try again.");
                }
            })
            .finally(() => {
                setTimeout(() => {
                    setIsButtonDisabled(false);
                }, 3000);
            });
    };
    const getDataTimeFormatted = (date) => {
        if (date && date.length > 0) {
            const dateObj = new Date(date);

            // Manually format the date to ensure no timezone conversion issues
            const year = dateObj.getUTCFullYear();
            const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
            const day = String(dateObj.getUTCDate()).padStart(2, "0");
            const hours = String(dateObj.getUTCHours()).padStart(2, "0");
            const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");

            const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
            return formattedDate;
        } else {
            return "";
        }
    };
    const handleFileChange = async (event) => {
        const newFiles = Array.from(event.target.files);
        const filePromises = newFiles.map((file: any) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    const fileData = {
                        file_name: file.name,
                        file: e.target.result.split(",")[1], // Extracting Base64 part
                    };
                    resolve(fileData);
                };
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        });

        try {
            const fileDataArray = await Promise.all(filePromises);
            setFiles((prevFiles) => {
                return [...prevFiles, ...fileDataArray];
            });
        } catch (error) {
            toast.error("An error occurred while uploading files. Please try again.");
        }
    };

    const handleDeleteFile = (fileToDelete) => () => {
        setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
    };


    const {
        data: campaignWorkFlowDetails,
        refetch: refetchStatus,
    } = useCampaignChangeStatusQuery(
        campaignId
    );


    const handleInputAssigneeChange = (value: string) => {
        setSelectedAssignee(value);
    };


    useEffect(() => {
        console.log("selectedStatusId", selectedStatusId)

    }, [selectedStatusId])

    const formatStatusData = (status) => {
        const generateUUID = () => uuidv4(); // Generate a UUID

        if (typeof status.status === "string") {
            // Open and Reopen statuses (no ID)
            return {
                status_type: status.status_type,
                status: status.status,
                id: null, // No ID for statuses without ID
                rid: generateUUID(), // Generate a UUID for rid
            };
        } else {
            // WIP and Closed statuses (with ID)
            return {
                id: status?.status?.id,
                status_type: status?.status_type,
                status: status?.status?.status,
                rid: generateUUID(), // Generate a UUID for rid
            };
        }
    };
    useEffect(() => {
        if (campaignWorkFlowDetails?.results[0]?.all_statuses) {
            const statusOptions1 = campaignWorkFlowDetails?.results[0]?.all_statuses?.map((status) =>
                formatStatusData(status)
            );
            setStatusOptions(statusOptions1);
        }


    }, [campaignWorkFlowDetails]);
    console.log(campaignWorkFlowDetails, "campaignWorkFlowDetails...")
    const handleStatusChange = (value) => {
        console.log("selectedStatusId", value)
        if (value) {
            const matchedStatus = statusOptions.find((status) => status.rid === value);
            if (matchedStatus) {
                setSelectedStatusId(value)
                console.log("selectedStatusId", matchedStatus)
                // status: status === "wip" ? statusId : null, // WIP
                // closed_status: status === "closed" ? statusId : null, // closed
                // status_type: status, // Use status type
                setStatus(matchedStatus.status_type)
                setStatusId(matchedStatus.id)
            }
        }
    }
    const activeStatuses = workflowStatuses.filter(status => status.status_type === "active");
    const wipStatuses = workflowStatuses.filter(status => status.status_type === "wip");
    const closedStatuses = workflowStatuses.filter(status => status.status_type === "closed");
    return (
        <div className="custom-style">
            <div className="text-end">
                <CloseButton onClick={closeModal} />
            </div>
            <div className="text-center mb-4">
                <label className="form-label fs-4 fw-bolder text-dark card-title">
                    Lead upload
                </label>
            </div>
            <div className="d-flex justify-content-center mb-5">
                <label className="form-check-label mx-2">Single</label>
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={isBulk}
                        onChange={handleToggleChange}
                    />
                    <label className="card-text">Bulk</label>
                </div>
            </div>

            {isBulk ? (
                <>

                    <BulkLeadModel closeModal={closeModal} refetch={refetch} dynamicData={dynamicData} campaignId={campaignId} refetchWorkflow={refetchWorkflow} />

                </>
            ) : (
                <>
                    <div className="row g-5 g-xl-8 mb-4">
                        <div className="col-xl-4">
                            <InputLabel>
                                Account Name
                            </InputLabel>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={accountname}
                                onChange={(e) => {
                                    setIsButtonDisabled(false);

                                    setErrors(errors?.account_name ? {} : errors);
                                    setAccount(e.target.value);
                                }}
                            />
                            {errors.account_name && (
                                <div className="mt-2" style={{ color: "red" }}>
                                    {capitalizeFirstLetter(errors.account_name)}
                                </div>
                            )}
                        </div>
                        <div className="col-xl-4">
                            <InputLabel >
                                First Name
                            </InputLabel>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={firstName}
                                onChange={(e) => {
                                    setIsButtonDisabled(false);

                                    setErrors(errors?.first_name ? {} : errors);
                                    setFirstName(e.target.value);
                                }}
                            />
                            {errors.first_name && (
                                <div className="mt-2" style={{ color: "red" }}>
                                    {capitalizeFirstLetter(errors.first_name)}
                                </div>
                            )}
                        </div>

                        <div className="col-xl-4">
                            <InputLabel>
                                Last Name
                            </InputLabel>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={lastName}
                                onChange={(e) => {
                                    setIsButtonDisabled(false);

                                    setErrors(errors?.last_name ? {} : errors);
                                    setLastName(e.target.value);
                                }}
                            />
                            {errors.last_name && (
                                <div className="mt-2" style={{ color: "red" }}>
                                    {capitalizeFirstLetter(errors.last_name)}
                                </div>
                            )}
                        </div>


                    </div>

                    <div className="row g-5 g-xl-8 mb-4">
                        <div className="col-xl-4">
                            <InputLabel >
                                Email
                            </InputLabel>
                            <TextField
                                type="email"
                                variant="outlined"
                                fullWidth
                                value={email}
                                onChange={(e) => {
                                    setIsButtonDisabled(false);

                                    setErrors(errors?.email ? {} : errors);
                                    setEmail(e.target.value);
                                }}
                            />
                            {errors.email && (
                                <div className="mt-2" style={{ color: "red" }}>
                                    {capitalizeFirstLetter(errors.email)}
                                </div>
                            )}
                        </div>
                        {/* <div className="col-xl-4">
                            <InputLabel className="required">Select Assignee</InputLabel>
                            <FormControl fullWidth>
                                <Select
                                    labelId="select-label-assignee"
                                    id="select-assignee"
                                    value={selectedAssignee}
                                    onChange={(e: any) => handleInputAssigneeChange(e.target.value)}
                                    required
                                >
                                    <MenuItem value="" disabled>
                                        Select
                                    </MenuItem>
                                    {users.map((user: any) => (
                                        <MenuItem key={user.id} value={user.id}>
                                            {user.username}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div> */}

                        <div className="col-xl-4">
                            <InputLabel className="required">
                                Mobile No
                            </InputLabel>
                            <TextField variant="outlined"
                                fullWidth value={phonenumber}
                                onChange={handlePhoneNumberChange}
                                inputProps={{ maxLength: 10, pattern: "[0-9]*" }} // Restrict input to numbers only
                            />
                            {errors.phone_number && (
                                <div className="mt-2" style={{ color: "red" }}>
                                    {capitalizeFirstLetter(errors.phone_number)}
                                </div>)}
                        </div>

                        {/* Render dynamic fields */}
                        {dynamicData?.map((field: any) => (
                            <React.Fragment key={field?.label}>
                                <div
                                    key={field.label}
                                    className="col-xl-4 mb-3"
                                >
                                    <InputLabel
                                        htmlFor={`dynamic_fields.${field.label}`}
                                    >
                                        {field.label}
                                        {field.is_required && (
                                            <span className="required"></span>
                                        )}
                                    </InputLabel>
                                    {field.field_type === "text" && (
                                        <TextField
                                            id={`dynamic_fields.${field.label}`}
                                            variant="outlined"
                                            fullWidth
                                            // disabled={!field?.is_editable}
                                            value={
                                                dynamicFields[field.label] || ""
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.label,
                                                    e.target.value
                                                )
                                            }
                                            required={field.is_required}
                                        />
                                    )}
                                    {field.field_type === "email" && (
                                        <TextField
                                            type="email"
                                            id={`dynamic_fields.${field.label}`}
                                            // disabled={!field?.is_editable}
                                            variant="outlined"
                                            fullWidth
                                            value={
                                                dynamicFields[field.label] || ""
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.label,
                                                    e.target.value
                                                )
                                            }
                                            required={field.is_required}
                                        />
                                    )}
                                    {field.field_type === "number" && (
                                        <TextField
                                            type="number"
                                            id={`dynamic_fields.${field.label}`}
                                            // disabled={!field?.is_editable}
                                            variant="outlined"
                                            fullWidth
                                            value={
                                                dynamicFields[field.label] || ""
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.label,
                                                    e.target.value
                                                )
                                            }
                                            required={field.is_required}
                                        />
                                    )}
                                    {field.field_type === "date" && (
                                        <TextField
                                            type="date"
                                            id={`dynamic_fields.${field.label}`}
                                            // disabled={!field?.is_editable}
                                            variant="outlined"
                                            fullWidth
                                            value={
                                                dynamicFields[field.label] || ""
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.label,
                                                    e.target.value
                                                )
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            required={field.is_required}
                                        />
                                    )}
                                    {field.field_type === "time" && (
                                        <TextField
                                            type="time"
                                            id={`dynamic_fields.${field.label}`}
                                            // disabled={!field?.is_editable}
                                            variant="outlined"
                                            fullWidth
                                            value={
                                                dynamicFields[field.label] || ""
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.label,
                                                    e.target.value
                                                )
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            required={field.is_required}
                                        />
                                    )}
                                    {field.field_type === "date-time" && (
                                        <TextField
                                            type="datetime-local"
                                            id={`dynamic_fields.${field.label}`}
                                            // disabled={!field?.is_editable}
                                            variant="outlined"
                                            fullWidth
                                            defaultValue={getDataTimeFormatted(
                                                dynamicFields[field.label]
                                            )}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.label,
                                                    e.target.value
                                                )
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            required={field.is_required}
                                        />
                                    )}
                                    {field.field_type === "text-area" && (
                                        <TextField
                                            id={`dynamic_fields.${field.label}`}
                                            // disabled={!field?.is_editable}
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            value={
                                                dynamicFields[field.label] || ""
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.label,
                                                    e.target.value
                                                )
                                            }
                                            required={field.is_required}
                                        />
                                    )}
                                    {field.field_type === "drop-down" && (
                                        <FormControl fullWidth>
                                            <Select
                                                labelId={`select-label-${field.label}`}
                                                // disabled={!field?.is_editable}
                                                id={`select-${field.label}`}
                                                value={
                                                    dynamicFields[
                                                    field.label
                                                    ] || ""
                                                }
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        field.label,
                                                        e.target.value
                                                    )
                                                }
                                                required={field.is_required}
                                            >
                                                <MenuItem selected disabled>
                                                    Select
                                                </MenuItem>
                                                {field?.choices?.map(
                                                    (option: any) => (
                                                        <MenuItem
                                                            key={option.id}
                                                            value={
                                                                option.choice
                                                            }
                                                        >
                                                            {option.choice}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                                    {field.field_type === "check-box" && (
                                        <FormControl fullWidth>
                                            <Select
                                                labelId={`checkbox-label-${field.label}`}
                                                // disabled={!field?.is_editable}

                                                id={`checkbox-${field.label}`}
                                                required={field.is_required}
                                                multiple
                                                value={
                                                    dynamicFields[
                                                    field.label
                                                    ] || []
                                                } // Ensure value is an array of IDs
                                                onChange={(e: any) =>
                                                    handleCheckboxChange(
                                                        e,
                                                        field.label
                                                    )
                                                }
                                                renderValue={(selected) => {
                                                    // Create an array of selected choice labels
                                                    const selectedLabels = (
                                                        field?.multiple_choices ||
                                                        []
                                                    )
                                                        .filter((choice) =>
                                                            selected.includes(
                                                                String(
                                                                    choice.id
                                                                )
                                                            )
                                                        )
                                                        .map(
                                                            (choice) =>
                                                                choice.choice
                                                        );
                                                    return selectedLabels.join(
                                                        ", "
                                                    );
                                                }}
                                            >
                                                {field?.multiple_choices?.map(
                                                    (choice: any) => (
                                                        <MenuItem
                                                            key={choice.id}
                                                            value={String(
                                                                choice.id
                                                            )}
                                                        >
                                                            <Checkbox
                                                                checked={dynamicFields[
                                                                    field.label
                                                                ]?.includes(
                                                                    String(
                                                                        choice.id
                                                                    )
                                                                )}
                                                            />
                                                            <ListItemText
                                                                primary={
                                                                    choice.choice
                                                                }
                                                            />
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}

                                    {field.field_type === "tel-phone" && (
                                        <PhoneInput
                                            defaultCountry="IN"
                                            value={
                                                dynamicFields[field.label] || ""
                                            }
                                            // disabled={!field?.is_editable}

                                            onChange={(value) =>
                                                handleInputChange(
                                                    field.label,
                                                    value
                                                )
                                            }
                                            required={field.is_required}
                                        />
                                    )}
                                    {errors[field.label] && (
                                        <div
                                            className="mt-2"
                                            style={{ color: "red" }}
                                        >
                                            {capitalizeFirstLetter(
                                                errors[field.label]
                                            )}
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="row g-5 g-xl-8 mb-4">
                        <div className="col-xl-4">
                            <label className="required" style={{ color: "rgba(0, 0, 0, 0.6)" }}>Status </label>
                            <select
                                className="form-select form-select-lg form-control-solid"
                                onChange={(e) => handleStatusChange(e.target.value)}
                                value={selectedStatusId || ""}
                            >
                                <option value="" style={{ color: "rgba(0, 0, 0, 0.6)" }} label="Select status" />
                                {statusOptions?.map((statusItem) => (
                                    <option
                                        key={statusItem.rid} // Make sure key corresponds to the unique rid
                                        value={statusItem.rid} // Use rid as the value
                                        label={statusItem.status} // Display the status text
                                    >
                                        {statusItem.status}
                                    </option>
                                ))}
                            </select>
                            {errors.status_type && <div className="error-message" style={{ color: "red" }}>{errors.status_type}</div>}                          </div>
                        {/* )} */}
                    </div>
                    <div className="col-xl-6">
                        <label>Attachments</label>
                        <div className="mt-2">
                            <Button
                                // variant="contained"
                                component="label"
                                className="text-primary"
                                startIcon={<AttachFileIcon />}
                            >
                                Add Attachment
                                <input
                                    type="file"
                                    hidden
                                    multiple
                                    onChange={handleFileChange}
                                />
                            </Button>
                            <Tooltip
                                title="You can add attachments for the following file types: xls, pdf, csv, doc, jpg, png up to a maximum size of 15MB"
                                arrow  >
                                <IconButton
                                    aria-label="info"
                                    style={{
                                        verticalAlign: "middle",
                                        marginLeft: "11px",
                                    }}
                                >
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    {files.length > 0 && (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
                            {files.map((file, index) => (
                                <Chip
                                    key={index}
                                    label={file.file_name}
                                    onDelete={handleDeleteFile(file)}
                                />
                            ))}
                        </Box>
                    )}


                    <div className="text-end mt-3 mb-4">
                        <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            onClick={submitHandler}
                            disabled={isButtonDisabled} // Disable button based on state
                        >
                            {isLoading ? (
                                <>
                                    Submitting... <Loader />
                                </>
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default CampaignLeadModel