import { useGetAuditTrailQuery } from "../../../Services/ticket/Audit-Trail";
import { formatDate } from "../../reusableComponent/DateFormat";

interface IProps {
  data: any;
  auditTrailData: any;
}

const Attachment = ({ data, auditTrailData }: IProps) => {
  const { data: TrailData } = useGetAuditTrailQuery({
    id: auditTrailData?.[0]?.id,
    activity: auditTrailData?.[0]?.ticket_activity,
  });

  const attachments = [data];


  return (
    <div className="table-responsive">
      <table className="table table-row-bordered text-center">
        <thead className="table-light fw-bolder">
          <tr className="bg-primary">
            <th scope="col">Date</th>
            <th scope="col">Name</th>
            <th scope="col">Attachment</th>
            <th scope="col">Download</th>
          </tr>
        </thead>
        <tbody>
          {attachments &&
            attachments.flatMap((attachment, index) =>
              attachment?.attachments.map((file, fileIndex) => (
                <tr key={`${index}-${fileIndex}`}>
                  <td>{formatDate(attachment?.created)}</td>
                  <td>{TrailData?.[0]?.new_assignee || "N/A"}</td>
                  <td className="text-primary">{file?.file_name}</td>
                  <td>
                    <i
                      className="bi bi-download fs-4 text-dark cursor-pointer"
                      onClick={() => window.open(file?.file, "_blank")}
                    ></i>
                  </td>
                </tr>
              ))
            )}
          {!attachments.length && (
            <tr>
              <td colSpan={4}>No attachments found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Attachment;
