import React, { useState } from "react";
import { useEditUserDynamicFieldsMutation } from "../../../../Services/settingsAPIs/CommonAPI";
import { toast } from "react-toastify";
import { Loader } from "react-bootstrap-typeahead";

interface IPROPS {
    closeModal: any;
    setFieldsData: any;
    fieldsData: any;
    storingFieldData: any;
    refetch: any;
}

const EditFieldModal: React.FC<IPROPS> = ({
    closeModal,
    setFieldsData,
    fieldsData,
    storingFieldData,
    refetch,
}) => {
    const [label, setLabel] = useState(storingFieldData?.label);
    const [required, setRequired] = useState(storingFieldData?.is_required);
    const [editable, setEditable] = useState(storingFieldData?.is_editable);
    const [unique, setUnique] = useState(storingFieldData?.is_unique);
    const [masked, setMasked] = useState(storingFieldData?.is_masked);


    console.log(storingFieldData, "storingfieldldlld")
    const [trigger, { isLoading, isError }] =
        useEditUserDynamicFieldsMutation();

    const saveHandler = async () => {
        const fieldObj = {
            // ...storingFieldData,
            label: label,
            is_required: required,
            is_unique: unique,
            is_editable: editable,
            is_masked: masked,
        };


        try {
            const response = await trigger({
                id: storingFieldData.id,
                body: fieldObj,
            });

            if (response?.data) {
                toast.success("Successfully edited");
                closeModal(true);
            } else {
                toast.error("An error occured ");
            }
        } catch (error) {
            toast.error("An error occured");
        }
    };

    const labelhandler = (e: any) => {
        setLabel(e.target.value);
    };

    const requiredChangeHandler = (e: any) => {
        setRequired(e.target.checked);
    };

    const uniqueChangeHandler = (e: any) => {
        setUnique(e.target.checked);
    };

    const editableChangeHandler = (e: any) => {
        setEditable(e.target.checked);
    };

    const maskHandler = (e: any) => {
        setMasked(e.target.checked);
    };


    return (
        <div>
            <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
                <div style={{ width: "60%" }}>
                    <h3 style={{ display: "flex", justifyContent: "end" }}>
                        Edit Fields
                    </h3>
                </div>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                        flex: "1",
                    }}
                >
                    <i
                        style={{
                            fontSize: "26px",
                            color: "black",
                            cursor: "pointer",
                        }}
                        className="bi bi-x"
                        onClick={() => closeModal(true)}
                    ></i>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 0px",
                }}
            >
                <label style={{ padding: "0px 10px", fontSize: "14px" }}>
                    Label
                </label>
                <input
                    className="mx-2 form-control"
                    type="text"
                    placeholder="Label"
                    onChange={labelhandler}
                    value={label}
                />
            </div>

            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "10px",
                    padding: "10px",
                    marginBottom: "10px",
                }}
            >
                <div style={{ display: "flex", width: "100%" }}>
                    <input
                        type="checkbox"
                        onChange={requiredChangeHandler}
                        checked={required}
                    />
                    <label
                        style={{
                            padding: "0px 10px",
                            fontSize: "14px",
                            whiteSpace: "nowrap",
                        }}
                    >
                        Mark as required
                    </label>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                    <input
                        type="checkbox"
                        onChange={uniqueChangeHandler}
                        checked={unique}
                    />

                    <label
                        style={{
                            padding: "0px 10px",
                            fontSize: "14px",
                            whiteSpace: "nowrap",
                        }}
                    >
                        Mark as unique
                    </label>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                    <input
                        type="checkbox"
                        onChange={editableChangeHandler}
                        checked={editable}
                    />

                    <label
                        style={{
                            padding: "0px 10px",
                            fontSize: "14px",
                            whiteSpace: "nowrap",
                        }}
                    >
                        Mark as Editable
                    </label>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                    <input
                        type="checkbox"
                        onChange={maskHandler}
                        checked={masked}
                        disabled={storingFieldData.field_type === "check-box" || storingFieldData.field_type === "drop-down" || storingFieldData.field_type === "multi-level" }

                    />

                    <label
                        style={{
                            padding: "0px 10px",
                            fontSize: "14px",
                            whiteSpace: "nowrap",
                            color: storingFieldData.field_type === "check-box" || storingFieldData.field_type === "drop-down" || storingFieldData.field_type === "multi-level" ? "grey" : "black"

                        }}
                    >
                        Mark as Masked
                    </label>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "end",
                    gap: "20px",
                }}
            >
                <button
                    className="btn btn-light"
                    onClick={() => closeModal(true)}
                >
                    Cancel
                </button>
                <button className="btn btn-primary" onClick={saveHandler}>
                    {isLoading ? (
                        <>
                            Saving... <Loader />
                        </>
                    ) : (
                        "Save"
                    )}
                </button>
            </div>
        </div>
    );
};

export default EditFieldModal;
