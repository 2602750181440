import { useEffect, useState } from 'react';
import conversationsData from './json/Conversation.json'

const Conversation = ({ selectedChatRoom }) => {
    // Conversation data
    const [messageInput, setMessageInput] = useState('');
    const [conversationData, setConversationData] = useState({ conversations: [] });
    const [chatRoomName, setChatRoomName] = useState('');

    useEffect(() => {
        // Filter conversations based on selectedChatRoom
        if (!!selectedChatRoom) {
            const selectedRoom = conversationsData.find(room => room.id === String(selectedChatRoom));
            if (selectedRoom) {
                setConversationData({ conversations: selectedRoom.conversations });
                setChatRoomName(selectedRoom.name);
            } else {
                // Handle case where selected chat room is not found
                setConversationData({ conversations: [] });
                setChatRoomName('');
            }
        } else {
            // Reset conversations if no chat room is selected
            setConversationData({ conversations: [] });
            setChatRoomName('');
        }
    }, [selectedChatRoom]);

    const sendMessage = () => {
        if (messageInput.trim() !== '') {
            const newMessage = {
                sender: 'You',
                timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                message: messageInput,
                fromMe: true
            };
            setConversationData({
                ...conversationData,
                conversations: [...conversationData.conversations, newMessage]
            });
            setMessageInput('');
        }
    };

    const getMessageClass = (messageLength) => {
        // You can adjust this threshold as needed
        const shortMessageThreshold = 20;
        return messageLength <= shortMessageThreshold ? 'rounded-full' : 'rounded-lg';
    };

    return (
        <>
            <div className="card-header align-items-center py-5 gap-5">
                <div className="card-title d-flex align-items-center">
                    <div className="d-flex justify-content-center flex-row">
                        <span className="badge badge-success badge-circle w-10px h-10px mt-1 me-2 " />
                        <a href="#" className="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1">{chatRoomName}</a>
                    </div>
                </div>
            </div>
            <div className="card-body" id="kt_chat_messenger_body">
                <div className="scroll-y me-n5 pe-5 h-300px h-lg-auto" data-kt-element="messages" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer" data-kt-scroll-wrappers="#kt_content, #kt_app_content, #kt_chat_messenger_body" data-kt-scroll-offset="5px" style={{ maxHeight: 426 }}>
                    {conversationData.conversations.map((message, index) => (
                        <div key={index} className={`d-flex justify-content-${message.fromMe ? 'end' : 'start'} mb-10`}>
                            <div className={`d-flex flex-column align-items-${message.fromMe ? 'end' : 'start'}`}>
                                <div className="d-flex align-items-center mb-2">
                                    <div className={`symbol symbol-35px symbol-circle me-2 ${message.fromMe ? 'ms' : 'me'}`}>
                                        <img alt="Pic" src={`/media/avatars/300-${message.fromMe ? '1' : '2'}.jpg`} />
                                    </div>
                                    <div>
                                        <span className="text-muted fs-7 mb-1 mx-2">{message.timestamp}</span>
                                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary">{message.sender}</a>
                                    </div>
                                </div>
                                <div className={`p-5 rounded bg-${message.fromMe ? 'light-primary text-dark text-end' : 'light-info text-dark text-start'} fw-semibold mw-lg-400px ${getMessageClass(message.message.length)}`} data-kt-element="message-text">{message.message}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='card-footer pt-4' id='kt_chat_messenger_footer'>
                <textarea
                    className='form-control form-control-flush mb-3'
                    rows={1}
                    placeholder='Type a message'
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                ></textarea>
                <div className='d-flex flex-stack'>
                    <div className='d-flex align-items-center me-2'>
                        <button
                            className='btn btn-sm btn-icon btn-active-light-primary me-1'
                            type='button'
                            title='Coming soon'
                        >
                            <i className='bi bi-paperclip fs-3'></i>
                        </button>
                        <button
                            className='btn btn-sm btn-icon btn-active-light-primary me-1'
                            type='button'
                            title='Coming soon'
                        >
                            <i className='bi bi-upload fs-3'></i>
                        </button>
                    </div>
                    <button
                        className='btn btn-primary'
                        type='button'
                        onClick={sendMessage}
                    >
                        Send
                    </button>
                </div>
            </div>
        </>
    );
}

export default Conversation;
