import { useState } from "react";
import CampaignIndex from "../../components/settings/campaign/campaignField";
import CampaignMapping from "../../components/settings/campaign/campaignMapping/CampaignMapping";
import CampaignRouting from "../../components/settings/campaign/campaignRouting/CampaignRouting";
import OrderInvoice from "../../components/settings/campaign/orderInvoice/OrderInvoice";
import OrderInvoiceMapping from "../../components/settings/campaign/orderInvoiceMapping/OrderInvoiceMapping";
import LeadUpload from "../../components/settings/campaign/leadUpload/LeadUpload";
import CampaignCreation from "../../components/settings/campaign/campaignCreation/CampaignCreation";
import CampaignWorkFlow from "../../components/settings/campaign/workflow";

const CampaignSetting = () => {
  const [activeTabIndex, setActiveTabIndex] = useState<any>(0);
  const tabTitles = [
    "Campaign creation",
    "Workflow",
    "Campaign mapping",
    // "Order/Invoice mapping",
    "Campaign field",
    // "Order/Invoice fields",
    "Campaign routing",
    "Lead upload",
  ];

  const mappingObj = {
    0: <CampaignCreation />,
    1: <CampaignWorkFlow />,
    2: <CampaignMapping />,
    // 3: <OrderInvoiceMapping />,
    3: <CampaignIndex />,
    // 5: <OrderInvoice />,
    4: <CampaignRouting />,
    5: <LeadUpload />,
  };

  return (
    <div className="text-start">
      <div>
        <ul className="nav nav-stretch nav-line-tabs text-primary border-transparent d-flex flex-nowrap mb-5">
          {tabTitles.map((title, index) => (
            <li className="nav-item" key={Math.random()}>
              <span
                style={{ textAlign: "center" }}
                className={`nav-link cursor-pointer fs-4 fw-bold ${
                  activeTabIndex === index ? "active fw-bolder" : ""
                }`}
                onClick={() => {
                  setActiveTabIndex(index);
                }}
                role="tab"
              >
                {title}
              </span>
            </li>
          ))}
        </ul>
      </div>

      {mappingObj[activeTabIndex]}
    </div>
  );
};

export default CampaignSetting;
