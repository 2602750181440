import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';

interface IProps {
    otherMailDetails: any;
    mailDetails: any[];
}

const CenterCard: React.FC<IProps> = ({ otherMailDetails, mailDetails }) => {
    const [openMails, setOpenMails] = useState<number[]>();

    useEffect(() => {
        setOpenMails(mailDetails.length > 0 ? [mailDetails[0]?.id] : []);
    }, [mailDetails]);

    const toggleMailOpen = (id: number) => {
        setOpenMails(prev =>
            prev.includes(id) ? prev.filter(mailId => mailId !== id) : [...prev, id]
        );
    };

    const getInitials = (name: any) => {
        if (!name) return '';
        const nameParts = name.split(' ');
        return nameParts.map(part => part[0].toUpperCase()).join('');
    };

    return (
        <>
            <div className="d-flex flex-wrap gap-2 justify-content-between mb-8">
                <div className="d-flex align-items-center flex-wrap gap-2">
                    <h2 className="fw-semibold me-3 my-1">{otherMailDetails?.subject}</h2>
                </div>
            </div>

            {mailDetails.map((mail, index) => (
                <div key={mail.id} data-kt-inbox-message="message_wrapper" style={{ marginTop: '10px' }}>
                    <div
                        className="d-flex flex-wrap gap-2 flex-stack cursor-pointer"
                        onClick={() => toggleMailOpen(mail.id)}
                    >
                        <div className="d-flex align-items-center">
                            <div className="symbol symbol-35px me-3">
                                <span className="symbol-label bg-light-dark text-primary">
                                    {getInitials(mail.receive_from)}
                                </span>
                            </div>
                            <div className="pe-5">
                                <div className="d-flex align-items-center flex-wrap gap-1">
                                    <a href="#" className="fw-bold text-dark text-hover-primary">{mail.receive_from}</a>
                                </div>
                                <div data-kt-inbox-message="details">
                                    <span className="text-muted fw-semibold">to {mail.to.join(', ')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center flex-wrap gap-2">
                            <div className="d-flex">
                                <span className="fw-semibold text-muted text-end me-3">{new Date(mail.mail_sent_or_received_on).toLocaleString()}</span>
                            </div>
                        </div>
                    </div>
                    <div className={`collapse fade ${openMails?.includes(mail.id) ? 'show' : ''}`} data-kt-inbox-message="message">
                        <div
                            className="py-5 mail-content-wrapper"
                            style={{ overflow: 'hidden', maxWidth: '100%', wordWrap: 'break-word' }}
                        >
                            <div
                                className="mail-html-content"
                                style={{ padding: '10px', border: '1px solid #e0e0e0', borderRadius: '5px' }}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(mail.html_content, {
                                        ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'p', 'div', 'span', 'ul', 'li', 'ol', 'br', 'img'],
                                        ALLOWED_ATTR: ['style', 'href', 'src', 'alt']
                                    })
                                }}
                            />
                        </div>

                        {mail.attachments && mail.attachments.length > 0 && (
                            <div className="attachments mt-4">
                                <h6>Attachments:</h6>
                                <div className="attachment-list">
                                    {mail.attachments.map((attachment) => (
                                        <div key={attachment.id} className="attachment-item">
                                            {attachment.file_name.match(/\.(jpg|jpeg|png|gif)$/i) ? (
                                                <div className="attachment-preview">
                                                    <img src={attachment.file} alt={attachment.file_name} style={{ maxWidth: '150px', maxHeight: '150px' }} />
                                                </div>
                                            ) : (
                                                <div className="attachment-preview">
                                                    <i className="fa fa-file" aria-hidden="true"></i>
                                                </div>
                                            )}
                                            <div className="attachment-details">
                                                <a href={attachment.file} target="_blank" rel="noopener noreferrer">{attachment.file_name}</a>
                                                <span className="file-size"> {/* Optionally show file size here */} </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </>
    );
};

export default CenterCard;
