import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";
import { AUTH_TOKEN } from "../../constants";

const fetchingTenant = window.location.hostname.split(".")[0];

interface ErrorData {
  message?: string;
  error?: string[];
}

const baseURL = process.env.REACT_APP_BASE_URL;
const apiPort = process.env.REACT_APP_API_PROTOCOL;

export const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args: any, api: any, extraOptions: any) => {
  const tenant = fetchingTenant;

  const fullBaseUrl = `${apiPort}://${tenant}.${baseURL}`;

  const rawBaseQuery = fetchBaseQuery({
    baseUrl: fullBaseUrl,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });

  const result = await rawBaseQuery(args, api, extraOptions);

  if (result?.error) {
    const status = result?.meta?.response?.status;

    if (status === 401) {
      toast.error("Session Expired");
      localStorage.clear();
      window.location.replace("/");
    } 
    else if (status === 403) {
      const teamNotFound = result?.error?.data?.["detail"];
      if (teamNotFound === "Authentication credentials were not provided.") {
        toast.error("Session Expired");
        localStorage.clear();
        window.location.replace("/");
      } else {
      }
    } 
    else if (status === 500) {
      // toast.error("Something went wrong!");
    } else {
    }
  }

  return result;
};
