import { useState } from "react";
import NestedDDConf from "../../components/settings/common/NestedDDConf";
import FieldsPane from "../../components/settings/user/fields/FieldsPane";
import TeamPane from "../../components/settings/user/Team/TeamPane";

const UserSettings = () => {
  const paneTitles = ["Team", "Fields"];
  const [openedPane, setOpenedPane] = useState('Team');
  const [multiLevelConfiguration, setMultiLevelConfiguration] = useState<any>({});
  return (
    <div>
        <div className="text-center">

            <ul className="nav nav-stretch nav-line-tabs text-primary border-transparent d-flex flex-nowrap mb-5">
              {paneTitles.map((title, index)=>(<>
                <li className="nav-item pb-0" key={Math.random()}>
                  <span className={`nav-link fs-4 fw-bold cursor-pointer ${openedPane === title ? "active fw-bolder" : "" }`}
                    onClick={() => { setOpenedPane(title); }}
                    role="tab"
                  > {title}
                  </span>
                </li>
              </>))}
            </ul>

          <div className="card-body px-1 px-lg-10 pt-0">
          {(() => {
            switch (openedPane) {
              case 'Team': return (<TeamPane/>);
              case 'Fields':  return (<FieldsPane/>);
              /* case 'confNestedDD':  return (<><NestedDDConf setOpenedPane= {setOpenedPane}/></>); */
              case 'confNestedDD':  return (<><NestedDDConf setMultiLevelConfiguration={setMultiLevelConfiguration}/></>);
              default:  return <h4>select tab to proceed</h4>;
            }
          })()}
          </div>
        </div>
    </div>
  )
}

export default UserSettings