import React from 'react'
import { useParams } from 'react-router-dom'
import { useLogoutMutation } from '../../../Services/Auth/Auth'
import { toast } from 'react-toastify'
import axios from 'axios'

interface Props {
    closeSaveModal: () => void
    selectedTeam: any
}

const SaveModal: React.FC<Props> = ({
    closeSaveModal,
    selectedTeam
}) => {
    // Call hooks at the top level
    const [triggerLogout] = useLogoutMutation();
    const apiPort = process.env.REACT_APP_API_PROTOCOL;


    const userName = localStorage.getItem('circleone-username')
    const password = localStorage.getItem('password')
    const authDetails: any = localStorage.getItem('user-token')

    const tenant = authDetails ? JSON.parse(authDetails).tenant : null
    const teamNamePrev = authDetails ? JSON.parse(authDetails).teams[0]?.name : null

    const triggerLoginMutationFn = async (payload: any) => {
        return axios.post(
            `${apiPort}://${process.env.REACT_APP_BASE_URL}/v1/auth/`,
            payload
        );
    };



    const SaveChanges = async () => {
        const payload = {
            "username": userName,
            "password": password,
            "tenant": tenant,
            "team": String(selectedTeam?.id)
        }
        try {
            const response = await triggerLogout({});
            if (response.data?.success) {
                const loginResponse = await triggerLoginMutationFn(payload);

                if (loginResponse && loginResponse.data) {
                    const { token } = loginResponse.data;
                    const CIRCLEONE_TEAM = loginResponse.data.teams[0]?.name
                    const teamId = loginResponse.data.teams[0].id

                    if (token) {
                        // Store the token directly
                        localStorage.setItem("user-token", JSON.stringify(loginResponse.data));
                        localStorage.setItem('auth-token', token);
                        localStorage.setItem('circleone-team', CIRCLEONE_TEAM)
                        localStorage.setItem('teamId', teamId)

                        window.location.reload()
                        closeSaveModal()
                        toast.success("Changes saved successfully");
                    } else {
                        toast.error("Login failed: Token not found");
                        closeSaveModal()
                    }
                } else {
                    toast.error("Login failed");
                    closeSaveModal()
                }
            }
        } catch (error) {
            toast.error("Team is not changed");
            closeSaveModal()
        }
    }


    return (
        <div className='card-body'>
            <p className='fs-5 fw-bold' style={{fontWeight:"500px"}}>Are you sure you want to switch teams?</p>
            <div className="card-text">You are about to switch from your {teamNamePrev} to  {selectedTeam?.name}. Please confirm if you want to proceed.</div>

            <div className='d-flex justify-content-end mt-10'>
                <button type='reset' className='btn btn-sm btn-white me-2' onClick={() => closeSaveModal()}>
                    Cancel
                </button>

                <button type='submit' className='btn btn-sm btn-primary' onClick={SaveChanges}>
                    <span className='indicator-label'>Confirm</span>
                </button>
            </div>
        </div>
    )
}

export default SaveModal
