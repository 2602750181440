import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import AddTemplateModal from "./AddTemplateModal";
import {
  useDeleteEmailTemplateMutation,
  useGetEmailTemplatesQuery,
} from "../../../../Services/settingsAPIs/EmailConfiguration";
import {
  defaultModalStyles,
  defaultScrollableModalStyles,
} from "../../../common/CustomModalStyles";
import { useTeamsQuery } from "../../../../Services/settingsAPIs/CommonAPI";
import CommonDeleteModal, {
  DeleteMessageBody,
} from "../../common/CommonDeleteModal";
import { toast } from "react-toastify";

const Template = () => {
  const { data: teamsData } = useTeamsQuery({});
  const [selectedTeam, setSelectedTeam] = useState<number>();
  const { data: emailTemplatesData, refetch } = useGetEmailTemplatesQuery(
    { pageNo: 1, teamId: selectedTeam },
    { skip: !selectedTeam }
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [togglerShowTemplate, setTogglerShowTemplate] = useState({
    status: false,
    templateId: 0,
  });
  const [getTempalteData, setGetTemplateData] = useState([]);
  const [theTeams, setTheTeams] = useState([]);
  const [templateForEdit, setTemplateForEdit] = useState<any>({
    templateEditEnabled: false,
    templateData: null,
  });
  const [templateForDeletion, setTemplateForDeletion] = useState<number>();
  const [triggerDeleteEmailTemplateMutation] = useDeleteEmailTemplateMutation();

  const openModal = () => {
    selectedTeam
      ? setIsModalOpen(true)
      : toast.error("Please select a team to add a new template to");
  };
  const closeModal = () => {
    setTemplateForEdit({ templateEditEnabled: false, templateData: null });
    setIsModalOpen(false);
    refetch();
  };

  const [isDeleteEmailTemplateModalOpen, setIsDeleteEmailTemplateModalOpen] =
    useState<boolean>(false);
  const openDeleteEmailTemplateModal = () => {
    setIsDeleteEmailTemplateModalOpen(true);
  };
  const closeDeleteEmailTemplateModal = () => {
    setIsDeleteEmailTemplateModalOpen(false);
    refetch();
  };

  const handleSelectTeamChange = async (event: any) => {
    setSelectedTeam(Number(event.target.value));
  };
  const initiateTemplateEdit = (objForEdit: any) => {
    setTemplateForEdit({ templateEditEnabled: true, templateData: objForEdit });
    openModal();
  };
  const initiateTemplateDeletion = (templateId: number) => {
    setTemplateForDeletion(templateId);
    openDeleteEmailTemplateModal();
  };

  useEffect(() => {
    if (teamsData) setTheTeams(teamsData);
  }, [teamsData]);
  useEffect(() => {
    emailTemplatesData && setGetTemplateData(emailTemplatesData);
  }, [emailTemplatesData]);
  
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <button
            className="btn btn-primary float-end min-w-150px"
            disabled={!selectedTeam}
            onClick={openModal}
          >
            Add
          </button>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-2">
          <select
            className="form-select select2-hidden-accessible"
            onChange={handleSelectTeamChange}
            value={selectedTeam}
          >
            <option value={0}> Select Team </option>
            {theTeams?.map((el: any) => (
              <option value={el?.id} key={el?.id}>
                {el?.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <span className="input-group-text mt-2" id="basic-addon1">
            {" "}
            Email Template{" "}
          </span>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col">
          {getTempalteData?.map((el: any, index: any) => (
            <>
              <div
                style={{
                  boxShadow:
                    "0 2px 1px -1px grey, 0 1px 1px 0 grey, 0 1px 3px 0 grey",
                  transition: "background-color 0.3s ease",
                }}
                className={`${
                  togglerShowTemplate.status &&
                  togglerShowTemplate.templateId === index
                    ? ""
                    : "bg-light-info d-flex"
                } align-items-center border border-secondary border-2 rounded-2 mt-2 p-2`}
              >
                <p className="my-0">
                  {togglerShowTemplate.status &&
                  togglerShowTemplate.templateId === index ? (
                    <>
                      <p className="my-0"> {el?.content} </p>
                      <br />
                      <strong>{el?.subject}</strong>
                    </>
                  ) : (
                    el?.subject
                  )}
                </p>
                <div className="ms-auto">
                  <button
                    className="btn btn-link text-hover-info"
                    onClick={() => initiateTemplateEdit(el)}
                  >
                    <i className="ms-auto bi bi-pencil fs-2"></i>
                  </button>
                  <button
                    className="btn btn-link text-hover-danger"
                    onClick={() => initiateTemplateDeletion(el.id)}
                  >
                    <i className="bi bi-trash fs-2 mx-2"></i>
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>

      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={defaultScrollableModalStyles}
        contentLabel="Add/Edit a template"
      >
        <AddTemplateModal
          closeModal={closeModal}
          selectedTeam={selectedTeam}
          refetchTemplates={refetch}
          templateForEdit={templateForEdit}
        />
      </ReactModal>
      <ReactModal
        isOpen={isDeleteEmailTemplateModalOpen}
        onRequestClose={closeDeleteEmailTemplateModal}
        style={defaultModalStyles}
        contentLabel="Delete a selected workflow"
      >
        <CommonDeleteModal
          closeModal={closeDeleteEmailTemplateModal}
          deleteAPItrigger={triggerDeleteEmailTemplateMutation}
          deletionIdentifier={templateForDeletion}
          messageBody={
            <DeleteMessageBody
              bodyParams={{
                descriptionPromptEnabled: false,
                deletionOf: "template",
              }}
            />
          }
        />
      </ReactModal>
    </div>
  );
};

export default Template;
