import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import UserSettings from "./UserSettings";
import ListMenu from "./ListMenu";
import CustomerSettings from "./CustomerSettings";
import EmailSettings from "./EmailSettings";
import TicketSettings from "./TicketSettings";
import GeneralSettings from "./GeneralSettings";
import CampaignSetting from "./CampaignSettings";
import ViewMessage from "../../components/settings/email/template/ViewMessage";
import TelephonySettings from "./TelephonySettings";
import Configuration from "./Configuration";

const SettingRoutes = () => {
  return (
    <section className="row mx-lg-10 mx-1">
      <div className="card px-2 col-lg-2 h-lg-800px border border-secondary rounded ">
        <ListMenu />
      </div>
      <div className="card col ms-xl-6 d-flex flex-column h-800px mt-4 mt-lg-0"style={{overflow:'scroll'}}>
        <Routes>
          <Route path="users" element={<UserSettings />} />
          <Route path="customer" element={<CustomerSettings />} />
          <Route path="general" element={<GeneralSettings />} />
          <Route path="email" element={<EmailSettings />} />
          <Route path="/settings/email/:id" element={<ViewMessage />} />
          <Route path="ticket" element={<TicketSettings />} />
          <Route path="campaign" element={<CampaignSetting />} />
          <Route path="telephony/*" element={<TelephonySettings />} />
          <Route path="integration" element={<Configuration />} />
          <Route index element={<Navigate to="users" />} />
        </Routes>
      </div>
    </section>
  );
};

export default SettingRoutes;
