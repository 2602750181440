import React from "react";
import ChatRoom from "./ChatRoom";

const LeftSideChatMenuCards = ({ setSelectedChatRoom }) => {
  return (
    <>
      <div className="card card-flush">
        <div className="card px-2 mb-1">
          <div className="d-flex align-items-center my-2">
            <span className="card-text mx-5">Customer Information</span>
            <button className="btn text-hover-primary">
              <i className="bi bi-pencil-fill mx-n5 fs-3"></i>
            </button>
            <button className="btn text-hover-primary">
              <i className="bi bi-gear mx-n5 fs-3"></i>
            </button>
          </div>
        </div>
        <div className="card px-2">
          <div className="d-flex align-items-center flex-row py-4 gap-1 mx-5">
            <span className="card-text">Account</span>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
              />
            </div>
            <span className="card-text mx-n1">Contact</span>
          </div>
        </div>

        <div className="card px-2">
          <div className="d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1">
            <span className="card-text">Name :</span>
            <div className="card-text">Sam Miller</div>
          </div>
        </div>

        <div className="card px-2">
          <div className="d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1">
            <i className="bi bi-envelope fs-2"></i>
            <span>: </span>
            <div className="card-text text-truncate">sam15@gmail.com</div>
          </div>
        </div>

        <div className="card px-2">
          <div className="d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1">
            <i className="bi bi-telephone fs-2"></i>
            <span>: </span>
            <span className="text-truncate">8790123467</span>
          </div>
        </div>

        <div className="card px-2">
          <div className="d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1">
            <i className="bi bi-whatsapp fs-2"></i>
            <span>: </span>
            <span className="text-truncate">8790123467</span>
          </div>
        </div>
      </div>
      <div className="card mt-5">
        <div className="card px-2">
          <div className=" d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1">
            <span className="card-text fs-5">Source</span>
          </div>
        </div>

        <div className="card px-2">
          <div className=" d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1">
            <span className="card-text">Pricing page</span>
          </div>
        </div>

        <div className="card px-2">
          <div className=" d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1">
            <i className="bi bi-geo-alt fs-2"></i>
            <span> : </span>
            <span> 8790123467 </span>
          </div>
        </div>
      </div>
      <div className=" mt-5">
        <ChatRoom setSelectedChatRoom={setSelectedChatRoom} />
      </div>
    </>
  );
};

export default LeftSideChatMenuCards;
