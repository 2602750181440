import React, { useEffect, useState } from "react";
import { useTeamsQuery } from "../../../Services/settingsAPIs/CommonAPI";
import {
  useDespositionDataQuery,
  useSaveDespositionMutation,
} from "../../../Services/general/General";
import { useTicketMapToDispositionMutation } from "../../../Services/settingsAPIs/TicketSettingsAPI";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { capitalizeFirstLetter } from "../../../components/reusableComponent/CapitalLetter";

// Define the types for cards data
type Card = {
  id: number;
  label: string;
  description?: string;
  steps?: string[];
  priority: string;
  sub_topics: Card[];
};

const PrioritySettings = () => {
  const [selectedTeam, setSelectedTeam] = useState<string>("");
  const [cardsData, setCardsData] = useState<Card[]>([]);
  const [priorityTeamsData, setPriorityTeamsData] = useState<any[]>([]);
  const [storedData, setStoredData] = useState<Card[]>([]);
  const [userRole, setUserRole] = useState<string>("");
  const [expandedNodes, setExpandedNodes] = useState([]);
  const { data: teamsfetched, isLoading: teamsLoading } = useTeamsQuery({});
  const [updateDespositionTrigger] = useTicketMapToDispositionMutation();
  const [storingPirorityData, setStoringPirorityData] = useState([]);
  const [trigger] = useSaveDespositionMutation();
  const {
    data,
    refetch,
    isLoading: priorityLoading,
  } = useDespositionDataQuery(selectedTeam, {
    skip: !selectedTeam,
  });

  // Mock function to get current user role, replace with your actual method
  const fetchUserRole = () => {
    const currentUser = { role: "admin" }; // Example user
    setUserRole(currentUser.role);
  };

  useEffect(() => {
    fetchUserRole(); // Fetch user role on component mount
  }, []);

  useEffect(() => {
    if (userRole === "admin" && teamsfetched) {
      setPriorityTeamsData(teamsfetched);
    } else if (userRole !== "admin" && userRole) {
      const userTeams = [
        { id: 1, name: "Team A" },
        { id: 2, name: "Team B" },
      ]; // Example data
      setPriorityTeamsData(userTeams);
    }
  }, [userRole, teamsfetched]);

  useEffect(() => {
    if (data) {
      setStoredData(data);
      const extractedCards = renderCardData(data);
      setStoringPirorityData(alignFormat(extractedCards));
      setCardsData(extractedCards);
    }
  }, [data]);


  function alignFormat(arr: any[]) {
    function addLevels(subTopics: any, levelPrefix: string) {
      return subTopics.map((item: any, index: number) => {
        let newItem = { ...item, level: `${levelPrefix}${index}` };
        if (newItem.sub_topics && newItem.sub_topics.length > 0) {
          newItem.sub_topics = addLevels(
            newItem.sub_topics,
            `${newItem.level}:`
          );
        }
        return newItem;
      });
    }

    return arr.map((item, index) => {
      let newItem = { ...item, level: `${index}` };
      if (newItem.sub_topics && newItem.sub_topics.length > 0) {
        newItem.sub_topics = addLevels(newItem.sub_topics, `${index}:`);
      }
      return newItem;
    });
  }

  const removeLevels = (arr: any[]) => {
    return arr.map((item) => {
      let newItem = { ...item };
      delete newItem.level;
      if (newItem.sub_topics && newItem.sub_topics.length > 0) {
        newItem.sub_topics = removeLevels(newItem.sub_topics);
      }
      return newItem;
    });
  };

  const saveHandler = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    const updatePriority = (data: Card[], cardsData: Card[]): Card[] => {
      return data.map((item) => {
        const card = cardsData.find((card) => card.id === item.id);
        const updatedItem = card ? { ...item, priority: card.priority } : item;
        if (updatedItem.sub_topics && updatedItem.sub_topics.length > 0) {
          return {
            ...updatedItem,
            sub_topics: updatePriority(updatedItem.sub_topics, cardsData),
          };
        }
        return updatedItem;
      });
    };

    const updatedData = updatePriority(storedData, storingPirorityData);

    try {
      await updateDespositionTrigger({
        body: updatedData,
        teamId: selectedTeam,
      });
      toast.success("Edited successfully");
      refetch();
    } catch (error) {
      toast.error("Failed to update");
    }
  };

  const handleFirstDropdownChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedTeam(event.target.value);
  };

  const handlePriorityChange = (
    id: number,
    newPriority: string,
    cards: Card[]
  ): Card[] => {
    return cards.map((card) =>
      card.id === id
        ? { ...card, priority: newPriority }
        : {
          ...card,
          sub_topics: handlePriorityChange(id, newPriority, card.sub_topics),
        }
    );
  };

  const handlePriorityChangeWrapper = (
    id: number,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newPriority = event.target.value;
    setStoringPirorityData((prevCardsData) =>
      handlePriorityChange(id, newPriority, prevCardsData)
    );
  };

  const renderCardData = (data: any[]): Card[] => {
    const extractCards = (data: any[]): Card[] => {
      return data.map((item) => ({
        id: item.id,
        label: item.topic || "",
        description: item.description || "",
        steps: item.steps || [],
        priority: item.priority || "Low",
        sub_topics: item.sub_topics ? extractCards(item.sub_topics) : [],
      }));
    };

    return extractCards(data);
  };

  //   const renderSubTopics = (subTopics: Card[]) => (
  //     <div className="d-flex flex-row align-items-center gap-5">
  //       {subTopics.map((subTopic, index) => (
  //         <React.Fragment key={subTopic.id}>
  //           <div className="text-start">
  //             {subTopic.label}
  //             {subTopic.sub_topics.length > 0 && renderSubTopics(subTopic.sub_topics)}
  //           </div>
  //           {index < subTopics.length - 1 && (
  //             <i className="bi bi-arrow-right text-primary"></i>
  //           )}
  //         </React.Fragment>
  //       ))}
  //     </div>
  //   );

  const renderSubTopics = (subTopics: Card[]) => (
    <div className="d-flex flex-row align-items-starts gap-3">
      {subTopics.map((subTopic, index) => (
        <React.Fragment key={subTopic.id}>
          <div className="text-start">
            {subTopic.label}
            <div className="">
              {subTopic.sub_topics.length > 0 && (
                <>
                  <i className="bi bi-arrow-down text-primary"></i>
                  {renderSubTopics(subTopic.sub_topics)}
                </>
              )}
            </div>
          </div>
          {index < subTopics.length - 1 && (
            <i
              className={`bi ${subTopics[index + 1].sub_topics.length > 0
                ? ""
                : "bi-arrow-right"
                } text-primary`}
            ></i>
          )}
        </React.Fragment>
      ))}
    </div>
  );

  const toggleNode = (level: string) => {
    if (expandedNodes.includes(level)) {
      setExpandedNodes(expandedNodes.filter((n) => n !== level));
    } else {
      setExpandedNodes([...expandedNodes, level]);
    }
  };

  const renderTreeNode = (node: any) => (

    <div key={node.level} className=" ms-8">
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div
          className="d-flex align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => toggleNode(node?.level)}
        >
          <i
            className={`bi ${node.sub_topics
              ? "bi-chevron-" +
              (expandedNodes.includes(node?.level)
                ? "down"
                : "right")
              : "bi-dash"
              } me-2`}
          ></i>
          <span>{capitalizeFirstLetter(node?.label)}</span>
        </div>

      </div>
      {expandedNodes.includes(node.level) && node.sub_topics && (
        <div className="ms-4 border-start ps-2">
          {node?.sub_topics?.map((subNode: any, index: number) => (
            <div key={index}>{renderTreeNode(subNode)}</div>
          ))}
        </div>
      )}

    </div>

  )

  const renderCard = (card: Card) => (
    <div key={card.id} className="col-12 mt-2">
      <div
        className={`card p-4 ${card.label === "label" ? "bg-secondary" : ""}`}
      >
        <div className="row align-items-center">
          <div className="col-12 col-md-4 col-lg-1 text-start">
            <div className="col text-start d-flex align-items-center gap-5">
              <label htmlFor="" className="ms-2">
                {card.label}
              </label>
            </div>
          </div>
          <div
            className="col-12 col-md-4 col-lg-9 text-start"
            style={{ overflow: "scroll" }}
          >
            {card.description ? (
              <div className="text-start">{card.description}</div>
            ) : card.sub_topics.length > 0 ? (
              renderTreeNode(card)
            ) : null}
          </div>


        </div>
      </div>
    </div>
  );



  return (
    <div className="card-toolbar flex-row-fluid justify-content-end gap-5 p-5">
      {teamsLoading || priorityLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {/* Header */}
          <div className="mb-4">
            <h4 className="text-primary">Priority Settings</h4>
          </div>

          {/* Team Selector */}
          <div className="row mb-4">
            <div className="col-12 mw-200px">
              <select
                className="form-select select2-hidden-accessible"
                onChange={handleFirstDropdownChange}
                value={selectedTeam}
              >
                <option disabled value="">
                  Choose Teams
                </option>
                {priorityTeamsData?.map((team) => (
                  <option key={team.id} value={team.id}>
                    {team.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Cards Display */}
          <div className="mt-5">

            <div style={{ overflow: "scroll" }}>
              {storingPirorityData?.map((tree, index) => (
                <div key={index} className="card p-3 fs-5 mb-2">
                  <div className="d-flex justify-content-between align-items-center">
                    {/* Left Side - Tree content (70%) */}
                    <div style={{ flex: '0 0 90%' }}>
                      {renderTreeNode(tree)}
                    </div>

                    {/* Right Side - Dropdown (30%) */}
                    <div style={{ flex: '0 0 10%' }} className="d-flex justify-content-end">
                      <select
                        value={tree.priority}
                        onChange={(e) => handlePriorityChangeWrapper(tree.id, e)}
                        className="form-select w-100 mw-115px"
                      >
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                        <option value="urgent">Urgent</option>
                      </select>
                    </div>
                  </div>
                </div>
              ))}
              </div>


              {/* Save button */}
              {selectedTeam && (
                <div className="text-end mt-5" onClick={saveHandler}>
                  <button className="btn btn-primary">Save</button>
                </div>
              )}



            </div>
          </>
      )}
        </div>
      );
};

      export default PrioritySettings;
