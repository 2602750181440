import React, { useEffect, useState } from "react";
import { MenuItem, Select, Checkbox, ListItemText } from "@mui/material";
import {
  useGetDynamicFieldForContactQuery,
  useGetAllAccountsQuery,
  useSubmitContactsMutation,
  useGetCustomersTagsQuery,
  useGetAllTeamsByIdQuery,
} from "../../../Services/email/EmailTemplate";
import { Chip, Stack } from "@mui/material";
import { toast } from "react-toastify";
interface IProps {
  closeModalContactDetailsFill: () => void;
  emailNotPresent: any;
}

const ContactDetailsFill: React.FC<IProps> = ({
  closeModalContactDetailsFill,
  emailNotPresent,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState<any>(emailNotPresent);
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [twitter, setTwitter] = useState("");
  const [facebook, setFacebook] = useState("");
  const [account, setAccount] = useState("");
  const [teams, setTeams] = useState<string[]>([]);
  const [customerTags, setCustomerTags] = useState<string[]>([]);
  const [dynamicFieldValues, setDynamicFieldValues] = useState<any>({});
  const [selectedTeam, setSelectedTeam] = useState<any>([]);
  const {
    data: accountList,
    error: errorAccountList,
    isLoading: isLoadingAccountList,
  } = useGetAllAccountsQuery({});
  const { data: dyanmicField } = useGetDynamicFieldForContactQuery({});
  const { data: tags } = useGetCustomersTagsQuery({});
  const { data: allTeam } = useGetAllTeamsByIdQuery(
    localStorage.getItem("User_Id")
  );
  const [submissionData] = useSubmitContactsMutation();
  const allTeams = ["team1", "team2", "team3"]; // Add all your team options here
  useEffect(() => {
    if (allTeam) {
      const transformedTeams = allTeam.teams.map((team) => ({
        id: team.id,
        name: team.name,
      }));
      setSelectedTeam(transformedTeams);
    }
  }, []);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validation for required fields
    if (!name) {
      alert("Please fill in the required field: Name");
      return;
    }
    if (!email) {
      alert("Please fill in the required field: Email");
      return;
    }

    // Transform dynamic fields
    const formattedDynamicFields = dyanmicField?.reduce(
      (acc: any, field: any) => {
        acc[field.label] = dynamicFieldValues[field.id] || "";
        return acc;
      },
      {}
    );

    // Format account ID and accessible_teams as strings
    const accountId =
      accountList.find((acc: any) => acc.name === account)?.id || "";
    const accessibleTeams = teams
      .map(
        (teamName) =>
          selectedTeam.find((team: any) => team.name === teamName)?.id
      )
      .filter((id) => id) // Filter out any undefined values
      .map((id) => String(id)); // Convert all IDs to strings

    const queryParams = {
      account: String(accountId), // Convert account ID to string
      accessible_teams: accessibleTeams, // Already formatted as strings
      name,
      email,
      phone_number: phone,
      facebook_id: facebook,
      whatsapp_number: whatsapp,
      twitter_id: twitter,
      dynamic_fields: formattedDynamicFields,
      tags: customerTags.map((tag: any) => String(tag.id)), // Convert tag IDs to strings
    };

    try {
      const response = await submissionData(queryParams);
      if (response?.data) {
        toast.success("Contact Added Successfully");
        closeModalContactDetailsFill();
      } else {
        toast.error("An error occurred");
      }
    } catch (error) {
      toast.error("Unable to edit");
    }
  };

  const toggleTagSelection = (tag: any) => {
    if (customerTags.find((t: any) => t.id === tag.id)) {
      setCustomerTags(customerTags.filter((t: any) => t.id !== tag.id));
    } else {
      setCustomerTags([...customerTags, tag]);
    }
  };

  const isSelectedTag = (tag: any) => {
    return !!customerTags.find((t: any) => t.id === tag.id);
  };

  const handleTeamsChange: any = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setTeams(event.target.value as string[]);
  };

  const handleDynamicFieldChange = (fieldId: string, value: any) => {
    setDynamicFieldValues({
      ...dynamicFieldValues,
      [fieldId]: value,
    });
  };
  const handleAccountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== "Select no account") {
      const selectedAccount = accountList.find(
        (acc: any) => acc.name === e.target.value
      );
      if (selectedAccount) {
        setSelectedTeam(selectedAccount.accessible_teams);
      } else {
        setSelectedTeam([]);
      }
      setAccount(e.target.value);
    } else {
      const transformedTeams = allTeam.teams.map((team) => ({
        id: team.id,
        name: team.name,
      }));
      setSelectedTeam(transformedTeams);
      setAccount(e.target.value);
    }
  };

  const renderDynamicField = (field: any) => {
    const { id, field_type, label, is_required, multiple_choices } = field;

    const requiredAsterisk = is_required ? (
      <span className="required-asterisk">*</span>
    ) : null;

    switch (field_type) {
      case "check-box":
        return (
          <div className="mui-col-md-12 my-5" key={id}>
            <label
              htmlFor={`field_${id}`}
              className="form-label fw-bolder text-dark fs-6"
            >
              {label} {requiredAsterisk}
            </label>
            {multiple_choices.map((choice: any) => (
              <div key={choice.id}>
                <input
                  type="checkbox"
                  id={`choice_${choice.id}`}
                  name={`field_${id}`}
                  value={choice.choice}
                  checked={
                    dynamicFieldValues[id]?.includes(choice.choice) || false
                  }
                  onChange={() =>
                    handleDynamicFieldChange(
                      id,
                      dynamicFieldValues[id]?.includes(choice.choice)
                        ? dynamicFieldValues[id].filter(
                            (val: any) => val !== choice.choice
                          )
                        : [...(dynamicFieldValues[id] || []), choice.choice]
                    )
                  }
                />
                <label htmlFor={`choice_${choice.id}`}>{choice.choice}</label>
              </div>
            ))}
          </div>
        );
      case "text-area":
        return (
          <div className="mui-col-md-12 my-5" key={id}>
            <label
              htmlFor={`field_${id}`}
              className="form-label fw-bolder text-dark fs-6"
            >
              {label} {requiredAsterisk}
            </label>
            <textarea
              id={`field_${id}`}
              name={`field_${id}`}
              value={dynamicFieldValues[id] || ""}
              onChange={(e) => handleDynamicFieldChange(id, e.target.value)}
              className="form-control form-control-lg form-control-solid"
              rows={4}
            />
          </div>
        );
      case "tel-phone":
        return (
          <div className="mui-col-md-12 my-5" key={id}>
            <label
              htmlFor={`field_${id}`}
              className="form-label fw-bolder text-dark fs-6"
            >
              {label} {requiredAsterisk}
            </label>
            <input
              type="tel"
              id={`field_${id}`}
              name={`field_${id}`}
              value={dynamicFieldValues[id] || ""}
              onChange={(e) => handleDynamicFieldChange(id, e.target.value)}
              className="form-control form-control-lg form-control-solid"
            />
          </div>
        );
      case "text":
        return (
          <div className="mui-col-md-12 my-5" key={id}>
            <label
              htmlFor={`field_${id}`}
              className="form-label fw-bolder text-dark fs-6"
            >
              {label} {requiredAsterisk}
            </label>
            <input
              type="text"
              id={`field_${id}`}
              name={`field_${id}`}
              value={dynamicFieldValues[id] || ""}
              onChange={(e) => handleDynamicFieldChange(id, e.target.value)}
              className="form-control form-control-lg form-control-solid"
            />
          </div>
        );
      case "number":
        return (
          <div className="mui-col-md-12 my-5" key={id}>
            <label
              htmlFor={`field_${id}`}
              className="form-label fw-bolder text-dark fs-6"
            >
              {label} {requiredAsterisk}
            </label>
            <input
              type="number"
              id={`field_${id}`}
              name={`field_${id}`}
              value={dynamicFieldValues[id] || ""}
              onChange={(e) => handleDynamicFieldChange(id, e.target.value)}
              className="form-control form-control-lg form-control-solid"
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h1 className="text-center">Contact</h1>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={closeModalContactDetailsFill}
        ></button>
      </div>

      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_signup_form"
        onSubmit={handleSubmit}
      >
        <div className="single-contact">
          <div className="mui-col-md-12 my-5">
            <label
              htmlFor="account"
              className="form-label fw-bolder text-dark fs-6 "
            >
              Select Account
            </label>
            <select
              className="form-select form-select-solid form-select"
              id="account"
              name="account"
              value={account}
              onChange={handleAccountChange}
            >
              <option value="">Select</option>
              {isLoadingAccountList && <option>Loading...</option>}
              {errorAccountList && <option>Error loading accounts</option>}
              {accountList &&
                accountList.map((acc) => (
                  <option key={acc.id} value={acc.name}>
                    {acc.name}
                  </option>
                ))}
              <option value="Select no account">Select no account</option>
            </select>
          </div>

          <div className="mui-col-md-12 my-5">
            <label
              htmlFor="team"
              className="form-label fw-bolder text-dark fs-6 "
            >
              Teams
            </label>
            <Select
              multiple
              value={teams}
              onChange={handleTeamsChange}
              renderValue={(selected) => (
                <div>
                  {selected.map((value) => (
                    <span key={value}>{value} </span>
                  ))}
                </div>
              )}
              id="team"
              name="team"
              className="form-select form-select-solid form-select"
              style={{ width: "100%", height: "40px" }} // Adjust height here
            >
              {selectedTeam.map((team) => (
                <MenuItem key={team.id} value={team.name}>
                  <Checkbox checked={teams.includes(team.name)} />
                  <ListItemText primary={team.name} />
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="fv-row mb-7">
            <label
              htmlFor="Fullname"
              className="form-label fw-bolder text-dark fs-6 required"
            >
              Name:
            </label>
            <input
              type="text"
              id="Fullname"
              name="Fullname"
              className="form-control form-control-lg form-control-solid"
              placeholder="Full name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="mui-col-md-12 my-5">
            <label
              htmlFor="email"
              className="form-label fw-bolder text-dark fs-6 required"
            >
              Email Address
            </label>
            <input
              type="email"
              id="email"
              className="form-control form-control-lg form-control-solid co-input full-width"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder=""
              style={{
                borderRadius: "0px",
                paddingLeft: "1rem",
                height: "44px",
              }}
            />
          </div>

          <div className="mui-col-md-12 my-5">
            <label
              htmlFor="phone"
              className="form-label fw-bolder text-dark fs-6"
            >
              Mobile No
            </label>
            <input
              type="text"
              id="phone"
              className="form-control form-control-lg form-control-solid co-input full-width"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder=""
              style={{
                borderRadius: "0px",
                paddingLeft: "1rem",
                height: "44px",
              }}
            />
          </div>

          <div className="mui-col-md-12 my-5">
            <label
              htmlFor="whatsapp"
              className="form-label fw-bolder text-dark fs-6"
            >
              WhatsApp No
            </label>
            <input
              type="text"
              id="whatsapp"
              className="form-control form-control-lg form-control-solid co-input full-width"
              value={whatsapp}
              onChange={(e) => setWhatsapp(e.target.value)}
              placeholder=""
              style={{
                borderRadius: "0px",
                paddingLeft: "1rem",
                height: "44px",
              }}
            />
          </div>

          <div className="mui-col-md-12 my-5">
            <label
              htmlFor="twitter"
              className="form-label fw-bolder text-dark fs-6"
            >
              Twitter Id
            </label>
            <input
              type="text"
              id="twitter"
              className="form-control form-control-lg form-control-solid co-input full-width"
              value={twitter}
              onChange={(e) => setTwitter(e.target.value)}
              placeholder=""
              style={{
                borderRadius: "0px",
                paddingLeft: "1rem",
                height: "44px",
              }}
            />
          </div>

          <div className="mui-col-md-12 my-5">
            <label
              htmlFor="facebook"
              className="form-label fw-bolder text-dark fs-6"
            >
              Facebook Id
            </label>
            <input
              type="text"
              id="facebook"
              className="form-control form-control-lg form-control-solid co-input full-width"
              value={facebook}
              onChange={(e) => setFacebook(e.target.value)}
              placeholder=""
              style={{
                borderRadius: "0px",
                paddingLeft: "1rem",
                height: "44px",
              }}
            />
          </div>

          {/*  dynamic field */}
          {dyanmicField?.map((field: any) => renderDynamicField(field))}

          <div className="mui-col-md-12 my-5">
            <label
              htmlFor="tags"
              className="form-label fw-bolder text-dark fs-6"
            >
              Tags
            </label>
            <Stack direction="row" spacing={1} flexWrap="wrap">
              {tags?.map((tag) => (
                <Chip
                  key={tag.id}
                  label={tag.name}
                  style={{
                    background: "#7e92e0d4",
                    color: isSelectedTag(tag) ? "white" : "inherit",
                  }}
                  onClick={() => toggleTagSelection(tag)}
                />
              ))}
            </Stack>
          </div>

          <div className="text-end">
            <button
              type="submit"
              id="kt_sign_up_submit"
              className="btn btn-lg btn-primary"
            >
              <span className="indicator-label">Submit</span>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ContactDetailsFill;
