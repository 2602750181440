import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import EmailTemplateModal from "../../email/EmailTemplateModal";
import { useGetTemplateQuery } from "../../../Services/email/EmailTemplate";
import { useCustomerNotesMutation } from "../../../Services/ticket/Messages";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Loader } from "react-bootstrap-typeahead";
import { Box, Chip } from "@mui/material";

interface IPROPS {
  customerNotes: any;
  fetchingNotes: any;
}

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "700px",
    height: "600px",
  },
};

// Utility function to strip HTML tags
const stripHtmlTags = (html: string) => {
  return html?.replace(/<\/?[^>]+>/gi, "");
};

const ExternalMessage: React.FC<IPROPS> = ({
  customerNotes,
  fetchingNotes,
}) => {
  const [isSendMsgEditOpen, setIsSendMsgEditOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [storeTemplateData, setStoreTemplateData] = useState<any>([]);
  const [selectedTemplateDescription, setSelectedTemplateDescription] =
    useState<any>("");
  const [value, setValue] = useState<string>("");
  const [initials, setInitials] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [files, setFiles] = useState([]);
  const [templateAttachment, setTemplateAttachment] = useState<any[]>([]);

  const { data: getTemplate } = useGetTemplateQuery({});
  const [trigger, { data: customerNotesData, isLoading, isError }] =
    useCustomerNotesMutation();
  const { uuid } = useParams();


  const customerNoteSubmitHandler = async () => {
    setIsSendMsgEditOpen(false);
    const customerNoteObj = {
      attachments: files,
      template_attachments: templateAttachment?.map(
        (attachment) => attachment?.id
      ),
      content: stripHtmlTags(selectedTemplateDescription?.content),
      ticket: uuid,
    };

    try {
      const result = await trigger(customerNoteObj); // Using unwrap to get the payload or error
      if (result) {
        fetchingNotes();
        // Clear all states after successful submission
        setFiles([]);
        setTemplateAttachment([]);
        setSelectedTemplateDescription({});
        setValue("");
        toast.success("Note saved successfully");
      }
    } catch (error) {
      console.error("Error saving customer note:", error);
      toast.error("Failed to save note.");
    }
  };

  const handleFileChange = async (event) => {
    const newFiles = Array.from(event.target.files);
    const filePromises = newFiles.map((file: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const fileData = {
            file_name: file.name,
            file: e.target.result.split(",")[1], // Extracting Base64 part
          };
          resolve(fileData);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });

    try {
      const fileDataArray = await Promise.all(filePromises);
      setFiles((prevFiles) => {
        return [...prevFiles, ...fileDataArray];
      });
    } catch (error) {
      toast.error("An error occurred while uploading files. Please try again.");
    }
  };

  const handleDownloadFile = (fileId) => {
    // Implement the download logic here
    // For example, using an API call to get the file or redirect to the file URL
    window.open(`${fileId}`, "_blank");
  };
  const handleDeleteAttachmentFile = (fileToDeleteId) => async () => {
    // Directly update the local state after the API call
    setTemplateAttachment((prev) => {
      // Filter out the attachment with the matching fileToDeleteId
      const updatedAttachments = prev.filter(
        (file) => String(file.id) !== String(fileToDeleteId)
      );

      return [...updatedAttachments]; // Return a new array
    });
  };

  const handleDeleteFile = (fileToDelete) => () => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };

  function formatDate(dateString: string) {
    const date = new Date(dateString);

    // Extract components
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Format the date and time
    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}`;

    return `${formattedDate} ${formattedTime}`;
  }
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // Retrieve the username from local storage
    const storedUsername = localStorage.getItem("circleone-username") || "";

    // Generate initials
    const nameParts = storedUsername.split(" ");
    const generatedInitials = nameParts
      .map((part) => part.charAt(0))
      .join("")
      .toUpperCase();

    // Set initials and username state
    setInitials(generatedInitials);
    setName(storedUsername);
  }, []);

  useEffect(() => {
    if (getTemplate) {
      setStoreTemplateData(getTemplate);
    }
  }, [getTemplate]);

  useEffect(() => {
    if (selectedTemplateDescription) {
      // Strip HTML tags from content and set value
      setValue(stripHtmlTags(selectedTemplateDescription.content));
    }
  }, [selectedTemplateDescription]);


  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "end" }}
        className={`align-self-end mt-2 mb-4 ${isSendMsgEditOpen ? "d-none" : ""
          }`}
      >
        <button
          className="btn btn-primary btn-sm"
          onClick={() => setIsSendMsgEditOpen(true)}
        >
          + Add note
        </button>
      </div>

      <div
        className={`card card-body gap-2 my-2 ${isSendMsgEditOpen ? "" : "d-none"
          }`}
      >
        <textarea
          className="form-control form-control-lg form-control-solid mb-2"
          name="account_name"
          value={value}
          onChange={(e) =>
            setSelectedTemplateDescription({
              ...selectedTemplateDescription,
              content: e.target.value,
            })
          }
          rows={3}
          placeholder="Type your message here..."
        />

        {files.length > 0 && (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
            {files.map((file, index) => (
              <Chip
                key={index}
                label={file.file_name}
                onDelete={handleDeleteFile(file)}
              />
            ))}
          </Box>
        )}

        {/* {templateAttachment?.length > 0 && (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
            {templateAttachment?.map((file, index) => (
              <Chip
                key={index}
                label={file.file_name}
                onClick={() => handleDownloadFile(file.file)}
                onDelete={handleDeleteAttachmentFile(file.id)}
                sx={{ cursor: "pointer" }} // Adds a pointer cursor on hover
              />
            ))}
          </Box>
        )} */}

        <div className="d-flex justify-content-between align-items-center mt-2">
          <div className="d-flex">
            <label
              htmlFor="attachFileInput"
              className={`btn btn-active-light-primary btn-icon ${value ? "" : "disabled"
                }`}
              title="You can add attachments for the following 
           file types: xls,pdf,csv,doc,jpg,png up to a maximum size of 15MB"
            >
              <i className="bi bi-paperclip align-self-center text-dark fs-2 mx-2 "></i>
              <input
                type="file"
                id="attachFileInput"
                className="d-none"
                multiple
                onChange={handleFileChange}
                disabled={!value}
              />
            </label>

            {/* <i
              className="bi bi-card-checklist text-dark fs-1 mx-2 align-self-center"
              onClick={openModal}
              title="Open Templates"
            ></i> */}
          </div>

          <button
            type="submit"
            className="btn btn-sm btn-primary"
            onClick={customerNoteSubmitHandler}
          >
            {isLoading ? "Sending..." : "Send"}
          </button>
        </div>
      </div>

      {customerNotes?.map((el: any) => (
        <div className="card p-2 my-1 d-flex flex-row">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div className="d-flex flex-column align-items-center ms-4 pt-2">
              <div className="symbol symbol-30px symbol-circle fs-2 me-5">
                <span className="symbol-label bg-primary text-inverse-warning fw-bold">
                  {initials}
                </span>
              </div>
              <span className="mx-5">{name.split("@")[0]}</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <p className="align-items-center mx-10 my-5">{stripHtmlTags(el?.content)}</p>
              <p className="align-self-end text-muted text-nowrap">
                {el?.created_by?.created
                  ? formatDate(el.created)
                  : "Date not available"}
              </p>
            </div>
          </div>
        </div>
      ))}

      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="Email templates"
      >
        <EmailTemplateModal
          closeModal={closeModal}
          setSelectedTemplateDescription={setSelectedTemplateDescription}
          storeTemplateData={storeTemplateData}
          setTemplateAttachment={setTemplateAttachment}
        />
      </ReactModal>
    </div>
  );
};

export default ExternalMessage;
