import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const RecentTasks = createApi({
  reducerPath: "recent_tasks",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    recentTasks: builder.query({
      query: ({ currentPage, selectedFilters1, isFilterOn }) => {
        // Start with the base URL
        let baseUrl = `v1/tickets/activity-tasks/?task_type=recent_tasks`;

        // Conditionally append the page parameter if currentPage is defined
        if (isFilterOn === false) {
          baseUrl += `&page=${currentPage}`;
        }

        // Conditionally append filter parameters if isFilterOn is true
        const filterParams = isFilterOn ? `&filters=on${getFilterParams(selectedFilters1)}` : '';

        // Combine baseUrl with filterParams
        return `${baseUrl}${filterParams}`;
      },
    }),
  }),
});


export const { useRecentTasksQuery } = RecentTasks;

function getFilterParams(selectedFilterAndValue1: object) {
  const filterParams = Object.entries(selectedFilterAndValue1)
    .filter(([key, value]) => value !== "")
    .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  return filterParams ? `&${filterParams}` : "";
}
