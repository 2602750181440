import React, { useState } from "react";
import { useChannelHistoryQuery } from "../../../Services/ticket/ChannelHistory";
import LoadingSpinner from "../../LoadingSpinner";
import {
  getBadgeClasses,
  getIconClasses,
} from "../../reusableComponent/IconAndBadgeClasses";

interface IProps {
  tickets: any;
  refetch: any;
}

const ChannelHistory: React.FC<IProps> = ({ tickets, refetch }) => {
  const { data, isLoading } = useChannelHistoryQuery(tickets.action.contact.id);
  const [showAll, setShowAll] = useState(false);

  const formatDate = (timestamp: string) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  const displayedData = showAll ? data : data?.slice(0, 5);

  return (
    <div className="pt-10">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="circleOnetimeline-label">
          {displayedData?.map((item: any, index: number) => (
            <div
              key={index}
              className="timeline-item ms-15 d-flex align-items-center"
            >
              <div className="circleOnetimeline-label fw-bold text-gray-800 fs-6">
                {item.time}
              </div>
              <div className="timeline-badge">
                <div
                  className={getBadgeClasses(item?.ticket?.ticket_channel)}
                  style={{ padding: "10px" }}
                >
                  <i
                    className={getIconClasses(item?.ticket?.ticket_channel)}
                  ></i>
                </div>
              </div>
              <div className="fw-normal timeline-content bg-secondary p-2 mx-10 overflow-hidden">
                <div>{item.message}</div>
                <div className="text-muted px-2">
                  {item?.ticket?.description}
                </div>
                <div className="text-primary px-2">
                  Ticket ID:
                  {item?.ticket?.id ? item?.ticket?.id : item?.ref_id}
                </div>
                <div className="text-muted px-2">
                  {item?.ticket?.created
                    ? formatDate(item.ticket.created)
                    : item?.created
                    ? formatDate(item.created)
                    : "Null"}
                </div>
                <div className="text-muted px-2">
                  Assignee: {item?.assignee?.username}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="d-flex justify-content-end mt-4 mx-5">
        <p
          className="text-muted  text-hover-primary cursor-pointer"
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? "View Less" : "View More"}
        </p>
      </div>
    </div>
  );
};

export default ChannelHistory;
