import React, {useState, useEffect} from 'react'
import SortingTableUsers from '../../reusableComponent/SortingTableUsers';
import { formatDate } from '../../reusableComponent/DateFormat';
import { capitalizeFirstLetter } from '../../reusableComponent/CapitalLetter';
import { useActiveUserQueryQuery } from '../../../Services/user/Users';
import LoadingSpinner from '../../LoadingSpinner';
import { useServerConfigQuery, useSeverDataQuery } from '../../../Services/Telephony/Telephony';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import EditModal from './EditModal';

const ServerConfiguration = () => {

  const [totalPages, setTotalPages] = useState(1);
  const [serverLoader, setServerLoading] = useState(false)
  const [serverId, setServerId] = useState()
  const [editModalOpem, setEditModalOpen] = useState(false)


  const [serverData, setServerData] = useState([])
  const pageSize = 20;

  const {data: telephonyServerData, refetch: refetchTelephonyServerData,isLoading, status} = useServerConfigQuery({})
  const {data: specificServerData, isLoading: specificServerLoading, status: specifServerStatus} = useSeverDataQuery(serverId, {skip: !serverId})
  
  useEffect(() => {
    if (status === "pending") {
        setServerLoading(true);
    } else {
        setServerLoading(false);
    }
  }, [setServerLoading, status]);


  useEffect(() => {
    if (status === "fulfilled" && telephonyServerData) {
        setServerData(telephonyServerData?.results)
      setTotalPages(Math.ceil(telephonyServerData.count / pageSize));

    }

  }, [status, telephonyServerData])

    const cardsData = [
        { label: "IP address", key: "ip" },
        { label: "SQL port", key: "sql" },
        { label: "Https port", key: "https" },
        { label: "Domain", key: "domain" },
        { label: "DB user", key: "db" },
        { label: "DB name", key: "dbName" },
        { label: "DB password", key: "dbPass" },
        { label: "Recording path", key: "recording" },

      ];

      const isEditModal = (id) => {
        setEditModalOpen(true)
        setServerId(id.id)
      }

      const closeModal = () => {
        setEditModalOpen(false)
      }

      const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "40%",
        overflow: "scroll",
        bgcolor: "background.paper",
        border: "1px solid transparent",
        boxShadow: 2,
        borderRadius: "5px",
        p: 4,
    };
  return (
    <div style={{padding: "30px"}}>

<div className="row" style={{marginBottom: "30px"}}>
        <div className="col">
          <span className="input-group-text mt-2" id="basic-addon1">
          Telephony server configuration
          </span>
        </div>
      </div>
    <div className="row">
    <div className="col">
      <div
        className="card  my-1 p-4"
        style={{ backgroundColor: "#E4E4E47F" }}
      >
        
        
          <div className="row align-items-center">
            {cardsData?.map((card, index) => (
              <div className="col text-start" key={index}>
                <label htmlFor="">
                  <strong
                    style={{
                      display: "flex",
                      gap: "2px",
                    }}
                  >
                    {card?.label}
                    {/* {card.label !== "Branch user" &&
                      card.label !== "Role" &&
                      card.label !== "Last updated date" && (
                        <SortingTableUsers
                          index={
                            card.label === "User"
                              ? "username"
                              : card.label === "Created by"
                              ? "created"
                              : card.label === "User id"
                              ? "id"
                              : card.label
                          }
                          setLiftingColumn={setLiftingColumn}
                        />
                      )} */}
                  </strong>
                </label>
              </div>
            ))}
            <div className="col text-center">
              <label htmlFor="">
                <strong>Action</strong>
              </label>
            </div>
          </div>
        
      </div>
    </div>
  </div>


  <div className="row">
        <div className="col">
          { isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {serverData?.length ? (
                <>
                  {serverData?.map((user: any, index: any) => (
                    <div className="card mt-2 my-1 p-4" key={index}>
                      <div className="row align-items-start">
                      
                        <div className="col text-start">
                          <label
                            htmlFor=""
                            style={{
                              paddingLeft: "10px",
                            }}
                          >
                            {user.ip_addr}
                          </label>
                        </div>
                        <div className="col text-start">
                          <label htmlFor="">{user?.sql_port ? user?.sql_port : 0}</label>
                        </div>
                        <div className="col text-start">
                          <label htmlFor="">
                            {user.https_port ? user.https_port: 0}
                          </label>
                        </div>
                        <div className="col text-start">
                          <label htmlFor="">
                            {user?.domain}
                          </label>
                        </div>
                        <div className="col text-start">
                          <label htmlFor="">{user?.db_user}</label>
                        </div>
                        <div className="col text-start">
                          <label htmlFor="">{user?.db_name}</label>
                        </div>
                      
                        <div className="col text-start">
                          <label htmlFor="">{user?.db_password}</label>
                        </div>
                        <div className="col text-start" data-toggle="tooltip" data-placement="top" title={user?.recording_path}>
                          <label htmlFor="" style={{width: "120px", textOverflow: "ellipsis",   overflow: "hidden",   whiteSpace: "nowrap"}}>{user?.recording_path}</label>
                        </div>
                     
                        <div className="col text-center d-flex" style={{gap: "10px", justifyContent: "center"}}>
                         
                          <button
                            className="text-hover-primary border-0 bg-white me-2"
                            title="Edit"
                            onClick={() => isEditModal(user)}
                          >
                            <i className="bi bi-pencil text-dark fs-4"></i>
                          </button>
                          <button
                            className="text-hover-danger border-0 bg-white me-2"
                            title="Delete"
                            // onClick={() => isDeleteModal(user)}
                          >
                            <i className="bi bi-trash text-dark fs-4"></i>
                          </button>

                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                ""
                // <EmptyRows value={""} />
              )}
            </>
          )}
        </div>
      </div>

      <Modal
                                open={editModalOpem}
                                onClose={closeModal}
                                aria-labelledby="modal-title"
                                aria-describedby="modal-description"
                                BackdropProps={{
                                    style: {
                                        backgroundColor: "rgba(0, 0, 0, 0.2)", // Adjust the opacity here
                                    },
                                }}
                            >
                                <Box sx={{ ...style, position: "absolute" }}>
                                    <IconButton
                                        aria-label="close"
                                        onClick={closeModal}
                                        sx={{
                                            position: "fixed",
                                            top: 16,
                                            right: 16,
                                            zIndex: 1301,
                                            background: "white",
                                        }} // Ensure it's above the modal content
                                    >
                                        <GridCloseIcon />
                                    </IconButton>
                                    <Typography
                                        id="modal-title"
                                        variant="h6"
                                        component="h2"
                                        sx={{ mb: 2 }}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        Edit telephony server configuration

                                    </Typography>

                                    <Box>
                                        <EditModal
                                            closeModal={closeModal}
                                            refetchingData={refetchTelephonyServerData}
                                            specificServerData={specificServerData}
                                        />
                                    </Box>
                                </Box>
                            </Modal>

  </div>
  )
}

export default ServerConfiguration