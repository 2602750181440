import "./clientStyles.scss";
import ChannelChart from "./ChannelChart";
import CustomDate from "../../components/common/CustomDate";
import PieChart from "./PieChart";
import {
    InputLabel,
    Select,
    TextField,
    MenuItem,
    FormControl,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
    useTicketPaginationQuery,
    useUserTicketQuery,
} from "../../Services/ticket/TicketList";
import DateCustom from "../../components/common/DateCustom";
import "./clientStyles.scss";
import { usePieChartAPIQuery } from "../../Services/settingsAPIs/CommonAPI";
import {
    formatDate,
    formatDatee,
} from "../../components/reusableComponent/DateFormat";
import { format } from "date-fns";

interface IPROPS {
    openTicketModal: any;
    setLiftingTicketData: any;
    currentPage: any;
    setRefetchingData: any;
}

const Charts: React.FC<IPROPS> = ({
    openTicketModal,
    // setLiftingTicketData,
    // currentPage,
    // setRefetchingData,
}) => {
    // const [selectedTeamId, setSelectedTeamId] = useState<any>("My Team");
    // const [isLoadingPageChange, setIsLoadingPageChange] =
    //     useState<boolean>(false);
    // const [filterType, setFilterType] = useState("blank");
    // const [data, setData] = useState<any>();
    const [pieChartDetails, setPieChartDetails] = useState([]);
    const [liftingDates, setLiftingDates] = useState([]);

    const datesObj = {
        startDate: formatDate(liftingDates[0]?.startDate),
        endDate: formatDate(liftingDates[0]?.endDate),
    };

    const startDate = liftingDates.length > 0 ? format(new Date(liftingDates[0]?.startDate), 'yyyy-MM-dd') : null;
    const endDate = liftingDates.length > 0 ? format(new Date(liftingDates[0]?.endDate), 'yyyy-MM-dd') : null;

    const { data: pieData } = usePieChartAPIQuery(
      { startDate, endDate },
      { skip: !startDate || !endDate }
  );

  const teamIdFromLocalStorage = localStorage.getItem("teamId") ? localStorage.getItem("teamId") : null;

    const { data: Ticket } = useUserTicketQuery(teamIdFromLocalStorage, {skip: !teamIdFromLocalStorage});

    useEffect(() => {
        if (pieData) {
            setPieChartDetails(pieData?.results);
        }
    }, [pieData]);

    const [selectedWorkFlow, setselectedWorkFlow] = useState(
        Ticket?.results[0]?.id
    );

    // const [selectedFilterAndValue, setSelectedFilterAndValue] = useState<any>({
    //     action__contact__email__icontains: "",
    //     action__assignee__username__icontains: "",
    //     title__icontains: "",
    //     created: "",
    //     id__icontains: "",
    //     merchant_id: "",
    // });

    // const [selectedFilterAndValue1, setSelectedFilterAndValue1] = useState<any>(
    //     {
    //         action__contact__email__icontains: "",
    //         action__assignee__username__icontains: "",
    //         title__icontains: "",
    //         created: "",
    //         id__icontains: "",
    //         merchant_id: "",
    //     }
    // );

    // const { data: fetchedData, refetch } = useTicketPaginationQuery(
    //     {
    //         currentPage,
    //         selectedWorkFlow,
    //         selectedTeamId,
    //         selectedFilterAndValue1,
    //     },
    //     { skip: !selectedWorkFlow }
    // );
    useEffect(() => {
        if (Ticket && Ticket?.results?.length > 0) {
            setselectedWorkFlow(Ticket?.results[0]?.id);
        }
    }, [Ticket]);

    // const handleSearchChange = (event) => {
    //     setIsLoadingPageChange(true);
    //     setSelectedFilterAndValue((prev) => ({
    //         ...prev,
    //         [getFilterField(filterType)]: event.target.value,
    //     }));
    // };

    // const handleDateChange = (event) => {
    //     setSelectedFilterAndValue({
    //         ...selectedFilterAndValue,
    //         created: event.target.value,
    //     });
    // };

    // useEffect(() => {
    //     setIsLoadingPageChange(true);
    //     if (fetchedData?.results) {
    //         setData(fetchedData?.results);
    //     }
    //     setIsLoadingPageChange(false);
    // }, [fetchedData, selectedFilterAndValue]);

    // useEffect(() => {
    //     if (fetchedData) {
    //         setData(fetchedData);
    //     }
    // }, [fetchedData]);

    // const handleTeamChange = (e: any) => {
    //     setSelectedTeamId(e.target.value);
    //     setIsLoadingPageChange(true);
    // };

    // const handleFilterChange = (event) => {
    //     const selectedFilter = event.target.value;
    //     setFilterType(selectedFilter);
    //     setSelectedFilterAndValue({
    //         action__contact__email__icontains: "",
    //         action__assignee__username__icontains: "",
    //         title_icontains: "",
    //         created: "",
    //         id__icontains: "",
    //         merchant_id: "",
    //         [getFilterField(selectedFilter)]: "", // This should be updated correctly
    //     });
    // };

    // const getFilterField = (filterType) => {
    //     switch (filterType) {
    //         case "byCustomerName":
    //             return "action__contact__email__icontains";
    //         case "byAssignee":
    //             return "action__assignee__username__icontains";
    //         case "byTitle":
    //             return "title__icontains";
    //         case "byDate":
    //             return "created";
    //         case "byTicketId":
    //             return "id__icontains";
    //         case "byMerchantId":
    //             return "merchant_id";
    //         default:
    //             return "";
    //     }
    // };

    // const handleSubmit = () => {
    //     setSelectedFilterAndValue1(selectedFilterAndValue);
    // };

    // useEffect(() => {
    //     setLiftingTicketData(data);
    // }, [data, setLiftingTicketData]);

    // useEffect(() => {
    //     setRefetchingData(() => refetch);
    // }, [refetch, setRefetchingData]);

    // const handleWorkflowChange = (event) => {
    //     setselectedWorkFlow(event.target.value);
    // };


    return (
        <div className="charts-header">
            {/* <div className="support">
                <p>Support</p>
            </div> */}
            <div className="charts-combo">
                <div className="charts-left">
                    {/* <div style={{ maxWidth: "150px" }}>
                        <input
                            className="form-control"
                            name="name"
                            placeholder="Enter text"
                            type="text"
                        />
                    </div>
                    <div style={{ maxWidth: "150px" }}>
                        <input
                            className="form-control"
                            name="name"
                            placeholder="Enter text"
                            type="text"
                        />
                    </div> */}
                    {/* <div className="date">
                        <CustomDate />
                    </div> */}
                    <div
                        className="d-flex align-items-center gap-2 me-auto"
                        style={{ marginRight: "0", gap: "20px" }}
                         >
                        <div className="date" style={{marginTop: "10px"}}>
                            <DateCustom setLiftingDates={setLiftingDates} />
                        </div>
                        {/* <>
                        <div className="w-200 mw-200px">
                            <FormControl style={{ width: "150px" }}>
                                <InputLabel
                                    id="team-select-label"
                                    style={{ fontSize: "15px" }}
                                >
                                    Select Assignee
                                </InputLabel>
                                <Select
                                    labelId="team-select-label"
                                    id="team-select"
                                    value={selectedTeamId}
                                    onChange={handleTeamChange}
                                    label="Select Teams"
                                >
                                    <MenuItem key="all" value="My Team">
                                        My Team
                                    </MenuItem>
                                    <MenuItem
                                        key="all"
                                        value={localStorage.getItem("User_Id")}
                                    >
                                        {localStorage.getItem(
                                            "circleone-username"
                                        )}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="w-100 mw-150px">
                            <FormControl style={{ width: "150px" }}>
                                <InputLabel
                                    id="filter-select-label"
                                    style={{ fontSize: "15px" }}
                                >
                                    Filter
                                </InputLabel>
                                <Select
                                    labelId="filter-select-label"
                                    id="filter-select"
                                    value={filterType}
                                    label="Filter By"
                                    onChange={handleFilterChange}
                                >
                                    <MenuItem value={"blank"}>
                                        Select a filter
                                    </MenuItem>
                                    <MenuItem value={"byCustomerName"}>
                                        Customer Email
                                    </MenuItem>
                                    <MenuItem value={"byAssignee"}>
                                        Assignee
                                    </MenuItem>
                                    <MenuItem value={"byTitle"}>Title</MenuItem>
                                    <MenuItem value={"byDate"}>Date</MenuItem>
                                    <MenuItem value={"byTicketId"}>
                                        Ticket ID
                                    </MenuItem>
                                    <MenuItem value={"byMerchantId"}>
                                        Merchant ID
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {filterType === "byDate" ? (
                            <>
                                <div className="w-100 mw-150px">
                                    <TextField
                                        id="date"
                                        label="Date"
                                        type="date"
                                        onChange={handleDateChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                            </>
                        ) : (
                            <div
                                className="w-100 mw-250px"
                                style={{ padding: "5px" }}
                            >
                                <TextField
                                    placeholder="Search..."
                                    onChange={handleSearchChange}
                                    style={{ padding: "2px" }}
                                />
                            </div>
                        )}
                        <div
                            className="w-200 mw-200px"
                            style={{
                                marginRight: "20px",
                                position: "relative",
                                display: "none",
                            }}
                        >
                            <label
                                style={{
                                    position: "absolute",
                                    left: "10px",
                                    top: "-10px",
                                    color: "rgba(0, 0, 0, 0.6)",
                                    overflow: "hidden",
                                }}
                            >
                                Workflow
                            </label>
                            <FormControl style={{ width: "150px", zIndex: 0 }}>
                           
                                <Select
                                    labelId="workflow-select-label"
                                    
                                    value={selectedWorkFlow}
                                    onChange={handleWorkflowChange}
                                    
                                >
                                  
                                    {Ticket &&
                                        Ticket.results.map((workflow) => (
                                            <MenuItem
                                                key={workflow.id}
                                                value={workflow.id}
                                            >
                                                {workflow.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </div>
                        
                        <div>
                            <button
                                style={{ padding: "0" }}
                                className="btn btn-secondary d-flex justify-content-between align-items-center text-hover-dark fw-bold"
                                onClick={handleSubmit}
                            >
                                <span
                                    className="ms-auto"
                                    style={{ padding: "8px 16px" }}
                                >
                                    Search
                                </span>
                            </button>
                        </div>
                        </> */}
                    </div>

                </div>
                <div className="charts-right">
                    <p>
                        {/* <span>
                            <i
                                className="bi bi-arrow-clockwise"
                                style={{ fontSize: "20px", color: "black" }}
                            ></i>
                        </span> */}
                        <span
                            className="create-ticket"
                            onClick={openTicketModal}
                        >
                            Create New Ticket
                        </span>
                    </p>
                </div>
            </div>

            {/* <div className="pie-chart">
                    <PieChart colors={pieChartColors} labels={pieChartLabels} />
                </div> */}
            <div className="bar-chart">
                <ChannelChart pieChartDetails={pieChartDetails} />
            </div>


            {/* <ReactModal
              isOpen={isTicketModalOpen}
              onRequestClose={closeTicketModal}
              style={customModalStyles}
              contentLabel="New Ticket"
            >
              <TicketModal
                closeModal={closeTicketModal}
                setIsLoading={setIsLoading}
                refetch={refetch}
              />
            </ReactModal> */}
        </div>
    );
};

export default Charts;
