import React, { useEffect, useState } from "react";
import { Loader } from "react-bootstrap-typeahead";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { toast } from "react-toastify";
import { useEmailConfigEditMutation } from "../../../../Services/settingsAPIs/EmailConfiguration";
import {
  useEmaildispositionQuery,
  useTeamsQuery,
} from "../../../../Services/settingsAPIs/CommonAPI";
import { useLookup_by_dispositionQuery } from "../../../../Services/disposition/Lookup_by_disposition";

interface IPROS {
  closeModal: any;
  editModalData: any;
  editModal: any;
  emailConfigId: any;
}

const initialValues = {
  email: "",
  password: "",
  hostname: "",
  port: "",
  server: "",
  ssl: false,
  emailServer: "",
  team: [],
  selectDesposition: "",
  workflow: null,
};

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .test(
      "generic-domains",
      "Generic email domains are not allowed",
      function (value) {
        const genericDomains = [
          "gmail.com",
          "yahoo.com",
          "outlook.com",
          "google.com",
        ];
        const enteredDomain = value?.split("@")[1];
        return !genericDomains.includes(enteredDomain);
      }
    )
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Minimum 8 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  hostname: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Hostname is required"),
  port: Yup.number()
    .required("Port is required")
    .typeError("Port must be a number"),
  server: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Server is required"),
  ssl: Yup.string().required("SSL is required"),

  team: Yup.array().of(Yup.string()).required("Team is required"),
  selectDesposition: Yup.string().required("Disposition is required"),
});

const EmailEditModal: React.FC<IPROS> = ({
  closeModal,
  editModalData,
  editModal,
  emailConfigId,
}) => {
  const [roundRobinSelected, setRoundRobinSelected] = useState<any>("");
  const [selectedTeam, setSelectedTeam] = useState<any>("");
  const [selectedDisposition, setSelectedDisposition] = useState<any>(null);
  const [lookUpDispositionVisible, setLookUpDespositionVisible] =
    useState<boolean>(false);
  const [getTeams, setGetTeams] = useState<any>([]);

  const { data } = useTeamsQuery({});
  const { data: emailDisposData } = useEmaildispositionQuery(selectedTeam, {
    skip: !selectedTeam,
  });

  const { data: lookUpData, isLoading } = useLookup_by_dispositionQuery(
    { body: selectedDisposition, teamId: selectedTeam },
    { skip: !lookUpDispositionVisible }
  );

  const [trigger] = useEmailConfigEditMutation();

  const desposition = [
    "Individual Assessment",
    "Round Robin",
    "Active Login(RR)",
    "Team Assignment",
  ];

  const servers = ["IMAP", "POP3"];
  const SSL_OPTIONS = [
    { id: "true", label: "True" },
    { id: "false", label: "False" },
  ];
  const emailServer = ["Gmail", "Microsoft"];

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const emailConfigPayload = {
        FIFO: false,
        SSL: values?.ssl,
        client_id: "",
        client_secret: "",
        disposition: values?.selectDesposition,
        email_server: values.emailServer,
        host_name: values.hostname,
        password: values?.password,
        port: values?.port,
        round_robin: roundRobinSelected === "Round Robin",
        server: values.server,
        server_tenant_id: "",
        team: values?.team,
        user: [null],
        username: values.email,
        workflow: values.workflow,
      };

      try {
        const response = await trigger({
          id: emailConfigId,
          data: emailConfigPayload,
        });
        if (response.data) {
          toast.success("Created Successfully");
          closeModal(true);
        } else if (response.error) {
          if ("status" in response.error && response.error.status === 400) {
            if (
              typeof response.error.data === "object" &&
              response.error.data !== null
            ) {
              const errorData = response.error.data as {
                username?: string[];
              };
              if (errorData.username && errorData.username.length > 0) {
                toast.error(errorData.username[0]);
              } else {
                toast.error("An error occurred");
              }
            }
          } else {
            console.error("Unhandled error:");
          }
        }
      } catch (error) {
        if (error?.response.status === 400) {
          toast.error("An unknown error occured");
        } else {
          toast.error("An error occurred.");
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  const isRoundRobinSelected = (e: any) => {
    setRoundRobinSelected(e.target.value);
  };

  useEffect(() => {
    if (data) {
      setGetTeams(data);
    }
  }, [data]);

  const teamSelectHandler = (e: any) => {
    const value = parseInt(e.target.value, 10);
    setSelectedTeam(value);
    formik.setFieldValue("team", value ? [value] : []);
  };

  const dispositionSelectHandler = (e: any) => {
    const value = parseInt(e.target.value, 10);
    setSelectedDisposition(value);
    formik.setFieldValue("selectDesposition", value);
    setLookUpDespositionVisible(true);
  };

  useEffect(() => {
    if (editModalData) {
      const initialTeam = editModalData.team[0];
      setSelectedTeam(initialTeam);
      formik.setValues({
        ...formik.values,
        email: editModalData.username,
        password: editModalData.password,
        hostname: editModalData.host_name,
        port: editModalData?.port,
        server: editModalData?.server,
        ssl: editModalData?.SSL,
        emailServer: editModalData?.email_server,
        team: [initialTeam],
        workflow: editModalData?.workflow,
      });
    }
  }, [editModalData]);

  return (
    <>
      {editModal && (
        <form
          onSubmit={formik.handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <button
              onClick={closeModal}
              type="button"
              className="close"
              aria-label="Close"
              style={{
                border: "none",
                background: "transparent",
                fontSize: "20px",
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <p
              style={{
                textAlign: "center",
                width: "100%",
                fontSize: "15px",
                fontWeight: "500",
                margin: "0",
              }}
            >
              {" "}
              IMAP/POP3
            </p>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="required" style={{ fontSize: "14px" }}>
              Email
            </label>
            <input
              {...formik.getFieldProps("email")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid": formik.touched.email && formik.errors.email,
                },
                {
                  "is-valid": formik.touched.email && !formik.errors.email,
                }
              )}
              style={{ padding: "10px" }}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert" style={{ color: "red" }}>
                    {formik.errors.email}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="required" style={{ fontSize: "14px" }}>
              Password
            </label>
            <input
              style={{ padding: "10px" }}
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />

            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert" style={{ color: "red" }}>
                    {formik.errors.password}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="required" style={{ fontSize: "14px" }}>
              Host Name
            </label>
            <input
              style={{ padding: "10px" }}
              {...formik.getFieldProps("hostname")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid":
                    formik.touched.hostname && formik.errors.hostname,
                },
                {
                  "is-valid":
                    formik.touched.hostname && !formik.errors.hostname,
                }
              )}
            />

            {formik.touched.hostname && formik.errors.hostname && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert" style={{ color: "red" }}>
                    {formik.errors.hostname}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="required" style={{ fontSize: "14px" }}>
              Port{" "}
            </label>
            <input
              style={{ padding: "10px" }}
              {...formik.getFieldProps("port")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid": formik.touched.port && formik.errors.port,
                },
                {
                  "is-valid": formik.touched.port && !formik.errors.port,
                }
              )}
            />
            {formik.touched.port && formik.errors.port && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert" style={{ color: "red" }}>
                    {formik.errors.port}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="required" style={{ fontSize: "14px" }}>
              Server
            </label>
            <select
              style={{ padding: "10px" }}
              {...formik.getFieldProps("server")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid": formik.touched.server && formik.errors.server,
                },
                {
                  "is-valid": formik.touched.server && !formik.errors.server,
                }
              )}
            >
              {servers?.map((el: any) => (
                <option>{el}</option>
              ))}
            </select>
            {formik.touched.server && formik.errors.server && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert" style={{ color: "red" }}>
                    {formik.errors.server}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="required" style={{ fontSize: "14px" }}>
              SSL
            </label>
            <select
              style={{ padding: "10px" }}
              {...formik.getFieldProps("ssl")}
              onChange={(e) => {
                const value = e.target.value === "true";
                formik.setFieldValue("ssl", value);
              }}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid": formik.touched.ssl && formik.errors.ssl,
                },
                {
                  "is-valid": formik.touched.ssl && !formik.errors.ssl,
                }
              )}
            >
              <option disabled>Choose SSL</option>
              {SSL_OPTIONS?.map((el: any) => (
                <option value={el?.id} key={el.id}>
                  {el.label}
                </option>
              ))}
            </select>
            {formik.touched.ssl && formik.errors.ssl && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert" style={{ color: "red" }}>
                    {formik.errors.ssl}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label style={{ fontSize: "14px" }}>Email Server</label>
            <select
              style={{ padding: "10px" }}
              className="form-control form-control-lg form-control-solid"
            >
              {emailServer?.map((el: any) => (
                <option>{el}</option>
              ))}
            </select>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="required" style={{ fontSize: "14px" }}>
              Team
            </label>
            <select
              onChange={teamSelectHandler}
              value={selectedTeam || ""}
              style={{ padding: "10px" }}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid": formik.touched.team && formik.errors.team,
                },
                {
                  "is-valid": formik.touched.team && !formik.errors.team,
                }
              )}
            >
              {getTeams?.map((t: any) => (
                <option key={t.id} value={t.id}>
                  {t.name}
                </option>
              ))}
            </select>
            {formik.touched.team && formik.errors.team && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert" style={{ color: "red" }}>
                    {formik.errors.team}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <p style={{ margin: "0" }}>
              {" "}
              Current Desposition: {editModalData.disposition[0].topic}
            </p>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ fontSize: "14px" }}>Select Disposition</label>
              <select
                onChange={dispositionSelectHandler}
                value={formik.values.selectDesposition}
                style={{ padding: "10px" }}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid":
                      formik.touched.selectDesposition &&
                      formik.errors.selectDesposition,
                  },
                  {
                    "is-valid":
                      formik.touched.selectDesposition &&
                      !formik.errors.selectDesposition,
                  }
                )}
              >
                <option disabled selected value="">
                  Choose Disposition
                </option>
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    {emailDisposData?.map((t: any) => (
                      <option key={t.id} value={t.id}>
                        {t.topic}
                      </option>
                    ))}
                  </>
                )}
              </select>
              {formik.touched.selectDesposition &&
                formik.errors.selectDesposition && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert" style={{ color: "red" }}>
                        {formik.errors.selectDesposition}
                      </span>
                    </div>
                  </div>
                )}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label>Workflow</label>
              <select
                className="form-control form-control-lg form-control-solid"
                {...formik.getFieldProps("workflow")}
              >
                <option>{editModalData?.workflow}</option>
              </select>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              {desposition?.map((el: any) => (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDisabled"
                    id="flexRadioCheckedDisabled"
                    value={el}
                    onChange={isRoundRobinSelected}
                    checked={roundRobinSelected === el}
                  />
                  <label
                    className="form-check-label"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {el}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div style={{ width: "100%" }}>
            <button
              type="submit"
              style={{ width: "100%", textAlign: "center" }}
              className="btn btn-primary"
            >
              {isLoading ? (
                <>
                  Submitting... <Loader />
                </>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default EmailEditModal;
