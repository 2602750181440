import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import {
  useAccountDataQuery,
  useRegisterDataMutation,
} from "../../../Services/accountLogin/AccountLogin";
import { toast } from "react-toastify";
import EditClientModal from "./EditClientModal";
import { Box, Modal, Typography } from "@mui/material";
import DeleteClientInfo from "./DeleteClientInfo";
import LoadingSpinner from "../../LoadingSpinner";
import { useGetAccountQuery } from "../../../Services/customer/FrontendCustomer";

interface IPROPS {
  refetch: any;
  closeModal: any;
}
const NewClientRegistrationModal: React.FC<IPROPS> = ({
  closeModal,
  refetch,
}) => {
  const [name, setName] = useState<any>("");
  const [password, setPassword] = useState<any>("");
  const [account, setAccount] = useState<any>(null);

  const [trigger] = useRegisterDataMutation();
  const { data: accountData } = useGetAccountQuery({});

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const passwordHandler = (e: any) => {
    setPassword(e.target.value);
  };

  const accountHandler = (e: any) => {
    setAccount(e.target.value);
  };

  const registrationHandler = async (e: any) => {
    e.preventDefault();
    const registrationDetail = {
      username: name,
      password: password,
      account: account,
    };
    try {
      const response = await trigger(registrationDetail);
      if (response.data) {
        toast.success("Successfully registered");
        refetch();
        closeModal();
      } else if (response.error) {
        toast.error("An error occured");
      } else {
        toast.error("An error occured");
      }
    } catch (error) {
      toast.error("An error occured");
    }
  };

  return (
    <>
      <div className="d-flex flex-column">
        <h2 style={{ width: "100%", textAlign: "center" }}>Register</h2>
        <div className="d-flex" style={{ width: "100%", padding: "15px 0" }}>
          <div className="d-flex flex-column" style={{ width: "50%" }}>
            <label
              htmlFor=""
              className="form-label required"
              style={{ fontSize: "15px", marginBottom: "0" }}
            >
              Email/username
            </label>
            <input
              className="form-control"
              type="text"
              onChange={nameHandler}
              value={name}
            />
          </div>
          <div className="d-flex flex-column ms-4" style={{ flex: "1" }}>
            <label
              htmlFor=""
              className="form-label required"
              style={{ fontSize: "15px", marginBottom: "0px" }}
            >
              Password
            </label>
            <input
              className="form-control"
              type="password"
              onChange={passwordHandler}
              value={password}
            />
          </div>
        </div>
        <div className="d-flex flex-column w-50">
          <label
            htmlFor=""
            className="form-label required"
            style={{ fontSize: "15px", marginBottom: "0px" }}
          >
            Select account
          </label>
          <select
            name=""
            id=""
            className="form-select"
            onChange={accountHandler}
            value={account}
          >
            <option>Choose Accounts</option>
            {accountData?.map((accounts) => (
              <option value={accounts.id}>{accounts?.name}</option>
            ))}
          </select>
        </div>
      </div>
      <button
        className="mt-2 btn btn-secondary float-end"
        onClick={registrationHandler}
      >
        Register
      </button>
    </>
  );
};

const ClientLoginPane = () => {
  const [
    isNewClientRegistrationModalOpen,
    setIsNewClientRegistrationModalOpen,
  ] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [accountData, setAccountData] = useState<any>([]);
  const [storingClientData, setStoringClientData] = useState(null);

  const { data, refetch, isLoading } = useAccountDataQuery({});

  const openNewClientRegistrationModal = () => {
    setIsNewClientRegistrationModalOpen(true);
  };
  const closeNewClientRegistrationModal = () => {
    setIsNewClientRegistrationModalOpen(false);
  };

  const openEditModal = (fields: any, account: any) => {
    setStoringClientData({ fields, account });
    setIsEditModalOpen(true);
  };

  const closeClientModal = () => {
    setIsEditModalOpen(false);
  };

  const openDeleteModal = (fields: any, account: any) => {
    setStoringClientData({ fields, account });
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const customModalStyles: ReactModal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-40%, -50%)",
      width: "40%",
    },
  };

  useEffect(() => {
    if (data) {
      setAccountData(data);
    }
  }, [data]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "45%",
    overflow: "scroll",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <span
            className="input-group-text mt-2 bg-white border-0"
            id="basic-addon1"
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                className="input-group-text align-items-center text-hover-primary fw-bold min-w-150px"
                onClick={openNewClientRegistrationModal}
              >
                <i className="bi bi-plus-lg me-2"></i>
                <span>Registration</span>
              </button>
            </div>
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col" style={{ padding: "10px" }}>
          <span className="input-group-text mt-2" id="basic-addon1">
            Client Registration
            <span className="ms-auto mx-4">Action</span>
          </span>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <div className="card my-1" style={{ backgroundColor: "#E4E4E47F" }}>
            <div className="row align-items-center py-3">
              <div className="col-5 text-start ps-10">
                <strong>Account</strong>
              </div>
              <div className="col-5 text-start ps-10">
                <strong>Username</strong>
              </div>
              <div className="col text-start"></div>
              <div className="col text-start"></div>
            </div>
          </div>

          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {accountData.map((field: any, index: any) => (
                <>
                  <div className="card my-1" key={index}>
                    <div className="row align-items-center py-3">
                      <div className="col-5 text-start ps-10">
                        {field?.account?.map((acount: any) => (
                          <label htmlFor="">{acount?.name}</label>
                        ))}
                      </div>
                      <div className="col-5 text-start ps-10">
                        <label htmlFor="">{field?.username}</label>
                      </div>
                      <div className="col text-end">
                        {/* Edit Button */}
                        <button
                          className="text-hover-primary border-0 bg-white me-2"
                          title="Edit"
                          onClick={() => {
                            const account = field?.account?.find((acc: any) => acc.name);
                            openEditModal(field, account);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="bi bi-pencil text-dark fs-4" style={{ color: "black" }}></i>
                        </button>

                        {/* Delete Button */}
                        <button
                          className="text-hover-danger border-0 bg-white me-2"
                          title="Delete"
                          onClick={() => {
                            const account = field?.account?.find((acc: any) => acc.name);
                            openDeleteModal(field, account);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="bi bi-trash text-dark fs-4" style={{ color: "black" }}></i>
                        </button>
                      </div>


                    </div>
                  </div>
                </>
              ))}
            </>
          )}
        </div>
      </div>
      <ReactModal
        isOpen={isNewClientRegistrationModalOpen}
        onRequestClose={closeNewClientRegistrationModal}
        style={customModalStyles}
        contentLabel="Add a new Account Field"
      >
        <NewClientRegistrationModal
          closeModal={closeNewClientRegistrationModal}
          refetch={refetch}
        />
      </ReactModal>

      <Modal
        open={isEditModalOpen}
        onClose={closeClientModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <EditClientModal
              closeModal={closeClientModal}
              clientData={storingClientData}
              refetch={refetch}
            />
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <DeleteClientInfo
              closeModal={closeDeleteModal}
              setAccountData={setAccountData}
              accountData={accountData}
              refetch={refetch}
              clientData={storingClientData}
            />
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default ClientLoginPane;
