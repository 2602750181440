import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const EmailListMenu = () => {
  // State to keep track of the selected menu item
  const [selectedMenuItem, setSelectedMenuItem] = useState('');

  // Function to handle menu item selection
  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };

  // Function to determine if a menu item is selected
  const isMenuItemSelected = (menuItem) => {
    return selectedMenuItem === menuItem ? 'text-primary' : 'text-dark';
  };

  return (
    <div className="d-none d-lg-flex flex-column flex-lg-row-auto w-100 w-lg-250px">
      {/*begin::Sticky aside*/}
      <div className="card card-flush mb-0">
        {/*begin::Aside content*/}
        <div className="card-body">
          {/*begin::Button*/}
          <Link to="/email/compose">
            <div className="btn btn-primary fw-bold w-100 mb-8">
              <span className="menu-icon">
                <i className='bi bi-envelope-fill fs-3 me-3'></i>
              </span>
              New Email</div>
          </Link>

          <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-dark menu-state-icon-dark menu-state-bullet-dark mb-10">

            {/*end::Button*/}
            {/*begin::Menu*/}
            {/*begin::Menu item*/}
            <Link to="/email/inbox" className={isMenuItemSelected('Inbox')} onClick={() => handleMenuItemClick('Inbox')}>
              <div className="menu-item mb-3">
                {/*begin::Inbox*/}
                <span className="menu-link">
                  <span className="menu-icon">
                    <i className="bi bi-envelope fs-2 me-3" />
                  </span>
                  <span className="menu-title fw-bold">Inbox</span>
                  {/* <span className="badge badge-light-success">3</span> */}
                </span>
                {/*end::Inbox*/}
              </div>
            </Link>
            {/*end::Menu item*/}
            {/*begin::Menu item*/}

            <Link to="/email/starred" className={isMenuItemSelected('Starred')} onClick={() => handleMenuItemClick('Starred')}>
              <div className="menu-item mb-3 "  >
                {/*begin::Marked*/}
                <span className="menu-link ">
                  <span className="menu-icon">
                    <i className="bi bi-star fs-2 me-3" />
                  </span>
                  <span className="menu-title fw-bold">Starred </span>
                </span>
                {/*end::Marked*/}
              </div>
            </Link>
            {/*end::Menu item*/}
            {/*begin::Menu item*/}
            {/* <Link to="/email/inbox" className={isMenuItemSelected('Draft')} onClick={() => handleMenuItemClick('Draft')}>
              <div className="menu-item mb-3">  
                <span className="menu-link">
                  <span className="menu-icon"><i className="bi bi-file-earmark fs-2 me-3" />
                  </span>
                  <span className="menu-title fw-bold">Draft</span>
                  <span className="badge badge-light-warning">5</span>
                </span>
            
              </div>
            </Link> */}
            {/*end::Menu item*/}
            {/*begin::Menu item*/}
            <Link to="/email/sent" className={isMenuItemSelected('Sent')} onClick={() => handleMenuItemClick('Sent')}>
              <div className="menu-item mb-3"> {/*begin::Sent*/}
                <span className="menu-link">
                  <span className="menu-icon">
                    <i className="bi bi-send fs-2 me-3" />
                  </span>
                  <span className="menu-title fw-bold">Sent</span>
                </span>
                {/*end::Sent*/}
              </div>
            </Link>
            {/*end::Menu item*/}
            {/*begin::Menu item*/}
            {/* <Link to="/email/bin" className={isMenuItemSelected('Bin')} onClick={() => handleMenuItemClick('Bin')}>
              <div className="menu-item mb-3">
               
                <span className="menu-link">
                  <span className="menu-icon ">
                    <i className="bi bi-trash fs-2 me-3" />
                  </span>
                  <span className="menu-title fw-bold">Bin</span>
                </span>
         
              </div>
            </Link> */}
            {/*end::Menu item*/}
          </div>
          {/*end::Menu*/}
        </div>
        {/*end::Aside content*/}
      </div>
      {/*end::Sticky aside*/}
    </div>
  );
};

export default EmailListMenu;
