import { useState, useEffect } from "react";
import Dialpad from "../../assets/images/dialpad.jpg";
import UserInviteModal from "../../components/user/UserInviteModal";
import { useTeamsQuery } from "../../Services/settingsAPIs/CommonAPI";
import {
  useActiveUserQueryDownloadQuery,
  useActiveUserQueryQuery,
  useReleaseSessionQuery,
  useUsersTeamSelectQuery,
} from "../../Services/user/Users";
import ReactModal from "react-modal";
import DeleteUserModal from "../../components/user/DeleteUserModal";
import EditUserModal from "../../components/user/EditUserModal";
import ResetPasswordModal from "../../components/user/ResetPasswordModal";
import AccountDeactivateModal from "../../components/user/AccountDeactivateModal";
import Activate from "./Activate";
import Deactivate from "./Deactivate";
import LoadingSpinner from "../../components/LoadingSpinner";
import SortingTableUsers from "../../components/reusableComponent/SortingTableUsers";
import { formatDate } from "../../components/reusableComponent/DateFormat";
import "./styles.scss";
import * as XLSX from "xlsx";

import {
  Box,
  Button,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { Loader } from "react-bootstrap-typeahead";
import ActiveUserFilterModal from "./ActiveUserFilterModal";
import { useNavigate } from "react-router-dom";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%", // Default to full width
    maxHeight: "80%",
    maxWidth: "850px", // Max width for larger screens
    margin: "auto",
    Overflow: "hidden",
    OverflowY: "auto",
  },
};

const customEditModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%", // Default to full width
    maxWidth: "1150px", // Max width for larger screens
    height: "80%",
    margin: "auto",
    Overflow: "hidden",
    OverflowY: "auto",
  },
};

const customBulkModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "400px", // Default to full width
    maxWidth: "850px", // Max width for larger screens
    margin: "auto",
    Overflow: "hidden",
    OverflowY: "auto",
  },
};

const customResetPassModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
    maxWidth: "850px", // Max width for larger screens
    margin: "auto",
    Overflow: "hidden",
    OverflowY: "auto",
  },
};

const DeleteModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "2px solid #dde",
    maxWidth: "400px",
    margin: "auto",
    Overflow: "hidden",
    OverflowY: "auto",
  },
};

const UserPage = () => {
  const cardsData = [
    { label: "User", key: "User" },
    { label: "User id", key: "UserId" },
    { label: "Created date", key: "Created Date" },
    { label: "Last updated date", key: "Last updated date" },
    { label: "Role", key: "Role" },
    { label: "Branch user", key: "Branch" },
  ];

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEnabled, setisEnabled] = useState<boolean>(true);
  const [isBulk, setIsBulk] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState<boolean>(false);

  const [isAccountDeactivate, setIsAccountDeactivate] =
    useState<boolean>(false);

  const [theTeams, setTheTeams] = useState([]);
  const [getUsersData, setGetUsersData] = useState<any>([]);
  const [selectedTeam, setSelectedTeam] = useState<any>();
  const { data: teamsData } = useTeamsQuery({});

  const [selectedEditId, setSelectedEditId] = useState();
  const [selectedDeleteId, setSelectedDeleteId] = useState();
  const [selectedReleaseId, setSelectedReleaseId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [liftingColumn, setLiftingColumn] = useState("");
  const [liftingActiveUserFilterData, setLiftingActiveUserFilterData] =
    useState<any>({});
  const [isAccountFilterModalOpen, setIsAccountFilterIsModalOpen] =
    useState<boolean>(false);
  const [accountDownloadButtonClicked, setAccountDownloadButtonClicked] =
    useState<boolean>(false);
  const [accountDownloadedData, setAccountDownloadedData] = useState<any>([]);
  const [accountDownloadLoading, setAccountDownloadLoading] =
    useState<boolean>(false);
  const [userLoading, setUserLoading] = useState(false);
  const pageSize = 20;

  const {
    data: sortData,
    isLoading: sortingLoading,
    refetch,
    status: usersLoading,
  } = useActiveUserQueryQuery(
    {
      page: currentPage,
      pageSize,
      sort: liftingColumn,
      selectedTeam: selectedTeam || "", // use undefined if selectedTeam is falsy
      liftingActiveUserFilterData,
      isEnabled,
    },
    {
      skip: !liftingColumn,
    }
  );

  const {
    data: downloadAccountData,
    isLoading: downloadAccountLoading,
    status: downloadAccountStatus,
  } = useActiveUserQueryDownloadQuery(
    {
      page: currentPage,
      sort: liftingColumn,
      liftingActiveUserFilterData,
      accountDownloadButtonClicked,
      selectedTeam: selectedTeam || "",
      isEnabled,
    },
    { skip: !accountDownloadButtonClicked }
  );

  useEffect(() => {
    if (usersLoading === "pending") {
      setUserLoading(true);
    } else {
      setUserLoading(false);
    }
  }, [usersLoading]);

  useEffect(() => {
    if (usersLoading === "fulfilled" && sortData) {
      setGetUsersData(sortData?.results);
      setTotalPages(Math.ceil(sortData.count / pageSize));
    }
  }, [sortData, usersLoading]);

  useEffect(() => {
    if (downloadAccountStatus === "pending") {
      setAccountDownloadLoading(true);
      setAccountDownloadButtonClicked(true);
    } else {
      setAccountDownloadLoading(false);
    }
  }, [downloadAccountStatus]);

  useEffect(() => {
    if (downloadAccountStatus === "fulfilled" && downloadAccountData) {
      setAccountDownloadedData(downloadAccountData);
    }
  }, [downloadAccountData, downloadAccountStatus]);

  useEffect(() => {
    if (
      downloadAccountStatus === "fulfilled" &&
      accountDownloadButtonClicked &&
      accountDownloadedData?.length >= 0
    ) {
      setAccountDownloadButtonClicked(false);

      setAccountDownloadLoading(false);
      downloadAccountsData();
    }
  }, [
    downloadAccountStatus,
    accountDownloadButtonClicked,
    accountDownloadedData,
  ]);

  const handleFirstDropdownChange = (event: any) => {
    setSelectedTeam(Number.parseInt(event.target.value));
  };

  const downloadAccountsData = async () => {
    setAccountDownloadLoading(true);
    setAccountDownloadButtonClicked(true);

    if (accountDownloadedData && accountDownloadedData.length > 0) {
      const csvData = accountDownloadedData?.map((user) => ({
        User: user.username,
        "User id": user.id,
        "Created date": user.created ? formatDate(user.created) : "",
        "Last updated date": user.last_updated
          ? formatDate(user.last_updated)
          : "",
        Role: user.role,
        "Branch user": user.branch_user ? "Yes" : "No",
        // Roles: user.roles?.map(role => role.name).join(", ") || "No Roles",
      }));

      const worksheet = XLSX.utils.json_to_sheet(csvData);

      const csvOutput = XLSX.utils.sheet_to_csv(worksheet);

      const blob = new Blob([csvOutput], {
        type: "text/csv;charset=utf-8;",
      });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        isEnabled ? "Active_User_data.csv" : "Deactive_User_data.csv"
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setAccountDownloadLoading(false);
      setAccountDownloadButtonClicked(false);
      setAccountDownloadedData([]);
    } else {
      setAccountDownloadLoading(false);
    }
  };

  // const { data: TeamSelectIdData } = useUsersTeamSelectQuery(selectedTeams, {
  //     skip: !selectedTeams,
  // });

  const { data: releaseSession } = useReleaseSessionQuery(selectedReleaseId, {
    skip: !selectedReleaseId,
  });

  const handleReleaseSessionClick = (userId: any) => {
    setSelectedReleaseId(userId);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const getInitials = (name: string) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    if (nameParts.length === 1) return nameParts[0].charAt(0).toUpperCase();
    return (
      nameParts[0].charAt(0).toUpperCase() +
      nameParts[1].charAt(0).toUpperCase()
    );
  };

  const getRandomColor = () => {
    const colors = [
      "bg-primary",
      "bg-success",
      "bg-danger",
      "bg-warning",
      "bg-dark",
    ];

    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const handleToggleActivate = () => {
    setisEnabled(!isEnabled);
  };

  useEffect(() => {
    if (teamsData) {
      setTheTeams(teamsData);
    }
  }, [teamsData]);

  // useEffect(() => {
  //     if (TeamSelectIdData) {
  //         setTeamIdData(TeamSelectIdData);
  //     }
  // }, [TeamSelectIdData]);

  const openAccountsFilterModal = () => {
    setIsAccountFilterIsModalOpen(true);
  };

  const closeAccountsFilterModal = () => {
    setIsAccountFilterIsModalOpen(false);
  };

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const afterOpenEditModal = () => {
    // subtitle.style.color = '#f00'
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const afterOpenDeleteModal = () => {
    // subtitle.style.color = '#f00'
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const openResetPasswordModal = () => {
    setIsResetPasswordModalOpen(true);
  };

  const afterOpenResetPasswordModal = () => {
    // subtitle.style.color = '#f00'
  };

  const closeResetPasswordModal = () => {
    setIsResetPasswordModalOpen(false);
  };

  const openAccountDeactivateModal = () => {
    setIsAccountDeactivate(true);
  };

  const afterAccountDeactivateModal = () => {
    // subtitle.style.color = '#f00'
  };

  const closeAccountDeactivateModal = () => {
    setIsAccountDeactivate(false);
  };

  const isEditModal = (data: any) => {
    setSelectedEditId(data);
    openEditModal();
  };

  const isDeleteModal = (data: any) => {
    setSelectedDeleteId(data);
    openDeleteModal();
  };

  const isResetPassModal = (data: any) => {
    setSelectedDeleteId(data);
    openResetPasswordModal();
  };

  const isDeactivateAccountModal = (data: any) => {
    setSelectedDeleteId(data);
    openAccountDeactivateModal();
  };

  const capitalizeFirstLetter = (str: string) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const customFilterModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100%", // Default to full width
      maxWidth: "30%",
      margin: "auto",
      Overflow: "hidden",
      OverflowY: "auto",
    },
  };

  useEffect(() => {
    setLiftingActiveUserFilterData({
      name: "",
    });
  }, [isEnabled]);

  const navigate = useNavigate(); // Initialize useNavigate
  const handleBackButtonClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="custom-style">
      <div id="kt_app_content_container" className="app-container mx-5">
        <div className="card card-flush">
          <div className="card-header align-items-center py-1 gap-2 gap-md-5">
            <div className="d-flex">
              <button
                className="btn btn-link mb-2"
                onClick={handleBackButtonClick}
              >
                 <i className="bi bi-arrow-90deg-left"     style={{
                                        fontSize: "20px",
                                        color: "black",
                                        cursor: "pointer",
                                    }}></i>
                {/* <i className="bi bi-arrow-left fs-1"></i> */}
              </button>
              <h2 className="p-4">Users</h2>
            </div>
            <div>
              {isEnabled ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Tooltip title="Download Active Data">
                    <IconButton
                      onClick={() => {
                        setAccountDownloadButtonClicked(true);

                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        downloadAccountsData;
                      }}
                    >
                      {accountDownloadLoading ? (
                        <Loader />
                      ) : (
                        <i
                          className="bi bi bi-download"
                          style={{
                            fontSize: "25px",
                            color: "black",
                          }}
                        ></i>
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Tooltip title="Download Deactive Data">
                    <IconButton
                      onClick={() => {
                        setAccountDownloadButtonClicked(true);
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        downloadAccountsData;
                      }}
                    >
                      {accountDownloadLoading ? (
                        <Loader />
                      ) : (
                        <i
                          className="bi bi bi-download"
                          style={{
                            fontSize: "25px",
                            color: "black",
                          }}
                        ></i>
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>

          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {/* Filter */}
            <div
              style={{
                marginLeft: "25px",
                width: "40%",
                display: "flex",
                gap: "10px",
              }}
            >
              <Button
                variant="outlined"
                className="text-hover-primary"
                onClick={openAccountsFilterModal}
                sx={{
                  height: 40,
                  width: "25%",
                  border: "1px solid #ccc",
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                  paddingRight: "0px",
                  paddingLeft: "0px",
                }}
              >
                <i className="bi bi-filter text-dark fs-2" />
                <span
                  className=" me-2 text-dark"
                  style={{
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    // width: "100%",
                    textTransform: "capitalize",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  Search filter
                </span>
              </Button>
              <div className="w-100 mw-150px">
                <select
                  className="form-select select2-hidden-accessible"
                  onChange={handleFirstDropdownChange}
                >
                  <option value="" selected disabled>
                    Select team
                  </option>
                  {theTeams &&
                    theTeams.map((team, index) => (
                      <option key={index} value={team.id}>
                        {team.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            {/* Combo */}
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                flex: "1",
                gap: "50px",
              }}
            >
              <div className="col-12 col-md-2 col-lg-2 d-flex align-items-center justify-content-end mt-2 mt-md-0">
                <label
                  className="form-check-label mx-2"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Active users
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={!isEnabled}
                    onChange={handleToggleActivate}
                  />
                  <label className="card-text" style={{ whiteSpace: "nowrap" }}>
                    Deactivated users
                  </label>
                </div>
              </div>

              <div className="col-12 col-md-3 col-lg-2 mt-2 mt-md-0 d-flex justify-content-end ">
                <button
                  className="form-control align-items-center text-hover-primary fw-bold min-w-100px mw-200px"
                  onClick={openModal}
                >
                  <i className="bi bi-person-plus me-2"></i>
                  <span className="ms-auto">Add user</span>
                </button>
                <ReactModal
                  isOpen={isModalOpen}
                  onRequestClose={closeModal}
                  style={!isBulk ? customModalStyles : customBulkModalStyles}
                  contentLabel="New Ticket"
                >
                  <UserInviteModal
                    closeModal={closeModal}
                    isBulk={isBulk}
                    setIsBulk={setIsBulk}
                    refetch={refetch}
                  />
                </ReactModal>
              </div>
            </div>
          </div>
          {/* </div> */}

          <div className="row gap-5 ms-3" style={{ marginTop: "20px" }}>
            <div className="row">
              <div className="col">
                <div
                  className="card mb-n5 my-1 p-4"
                  style={{ backgroundColor: "#E4E4E47F" }}
                >
                  {isEnabled && (
                    <div className="row align-items-center">
                      {cardsData?.map((card, index) => (
                        <div className="col text-start" key={index}>
                          <label htmlFor="">
                            <strong
                              style={{
                                display: "flex",
                                gap: "2px",
                              }}
                            >
                              {card?.label}
                              {card.label !== "Branch user" &&
                                card.label !== "Role" &&
                                card.label !== "Last updated date" && (
                                  <SortingTableUsers
                                    index={
                                      card.label === "User"
                                        ? "username"
                                        : card.label === "Created date"
                                        ? "created"
                                        : card.label === "User id"
                                        ? "id"
                                        : card.label
                                    }
                                    setLiftingColumn={setLiftingColumn}
                                  />
                                )}
                            </strong>
                          </label>
                        </div>
                      ))}
                      <div className="col text-center">
                        <label htmlFor="">
                          <strong>Action</strong>
                        </label>
                      </div>
                    </div>
                  )}
                  {!isEnabled && (
                    <div className="row align-items-center">
                      {cardsData?.map((card, index) => (
                        <div className="col text-start" key={index}>
                          <label htmlFor="">
                            <strong
                              style={{
                                display: "flex",
                                gap: "2px",
                              }}
                            >
                              {card?.label}
                              {card.label !== "Branch user" &&
                                card.label !== "Role" &&
                                card.label !== "Last updated date" && (
                                  <SortingTableUsers
                                    index={
                                      card.label === "User"
                                        ? "username"
                                        : card.label === "Created by"
                                        ? "created"
                                        : card.label === "User id"
                                        ? "id"
                                        : card.label
                                    }
                                    setLiftingColumn={setLiftingColumn}
                                  />
                                )}
                            </strong>
                          </label>
                        </div>
                      ))}
                      <div className="col text-center">
                        <label htmlFor="">
                          <strong>Action</strong>
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {isEnabled ? (
              <Activate
                usersData={getUsersData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                sortingLoading={sortingLoading}
                getRandomColor={getRandomColor}
                getInitials={getInitials}
                capitalizeFirstLetter={capitalizeFirstLetter}
                formatDate={formatDate}
                isResetPassModal={isResetPassModal}
                isEditModal={isEditModal}
                isDeleteModal={isDeleteModal}
                isDeactivateAccountModal={isDeactivateAccountModal}
                handleReleaseSessionClick={handleReleaseSessionClick}
                usersLoading={userLoading}
              />
            ) : (
              <Deactivate
                getRandomColor={getRandomColor}
                getInitials={getInitials}
                capitalizeFirstLetter={capitalizeFirstLetter}
                totalPages={totalPages}
                refetch={refetch}
                getDeactiveUsersData={getUsersData}
                formatDate={formatDate}
                deactiveCurrentPage={currentPage}
                setCurrentPage={setCurrentPage}
                usersLoading={userLoading}
                sortingLoading={sortingLoading}
              />
            )}
          </div>

          <ReactModal
            isOpen={isEditModalOpen}
            onAfterOpen={afterOpenEditModal}
            onRequestClose={closeEditModal}
            style={customEditModalStyles}
            contentLabel="Edit User"
          >
            <EditUserModal
              closeEditModal={closeEditModal}
              user={selectedEditId}
              refetch={refetch}
            />
          </ReactModal>
          <ReactModal
            isOpen={isDeleteModalOpen}
            onAfterOpen={afterOpenDeleteModal}
            onRequestClose={closeDeleteModal}
            style={DeleteModalStyles}
            contentLabel="delete User"
          >
            <DeleteUserModal
              closeDeleteModal={closeDeleteModal}
              userData={selectedDeleteId}
              refetch={refetch}
            />
          </ReactModal>

          <ReactModal
            isOpen={isResetPasswordModalOpen}
            onAfterOpen={afterOpenResetPasswordModal}
            onRequestClose={closeResetPasswordModal}
            style={customResetPassModalStyles}
            contentLabel="reset password"
          >
            <ResetPasswordModal
              closeResetPasswordModal={closeResetPasswordModal}
              resetPass={selectedDeleteId}
              refetch={refetch}
            />
          </ReactModal>

          <ReactModal
            isOpen={isAccountDeactivate}
            onAfterOpen={afterAccountDeactivateModal}
            onRequestClose={closeAccountDeactivateModal}
            style={DeleteModalStyles}
            contentLabel="deactivate account"
          >
            <AccountDeactivateModal
              closeAccountDeactivateModal={closeAccountDeactivateModal}
              user={selectedDeleteId}
              refetch={refetch}
            />
          </ReactModal>

          <ReactModal
            isOpen={isAccountFilterModalOpen}
            onRequestClose={closeAccountsFilterModal}
            style={customFilterModalStyles}
            contentLabel="Ticket Filter Modal"
          >
            <ActiveUserFilterModal
              closeFilterModal={closeAccountsFilterModal}
              setLiftingContactFilterData={setLiftingActiveUserFilterData}
              setPage={setCurrentPage}
              contactDataa={getUsersData}
              liftingContactFilterData={liftingActiveUserFilterData}
            />
          </ReactModal>
        </div>
      </div>
    </div>
  );
};

export default UserPage;
