import ReactApexChart from "react-apexcharts";
import { useState } from 'react';

const initialData = {
          
    series: [14, 22, 20, 28, 16],
    options: {
      chart: {
        width: '50%',
        type: 'pie',
      },
      labels: ["Google advertisement", "Email campaign", "Linkedin campaign", "Product launch campaign", "Collections campaign"],
      theme: {
        monochrome: {
          enabled: false
        }
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -5
          }
        }
      },
      title: {
        text: "Number of lead per campaign"
      },
        dataLabels: {
            enabled: true,
            enabledOnSeries: undefined,
            /* formatter: function (val, opts) {
                return val */
            formatter(val, opts) {
                const name = opts.w.globals.labels[opts.seriesIndex]
                /* return [val.toFixed(1) + ''] */
                return val;
                },
            textAnchor: 'middle',
            distributed: false,
            offsetX: 0,
            offsetY: 0,
            style: {
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: undefined
            },
            background: {
              enabled: true,
              foreColor: '#fff',
              padding: 4,
              borderRadius: 2,
              borderWidth: 0,
              borderColor: '#fff',
              opacity: 0.9,
              dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45
              }
            },
            dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45
            }
          },
      legend: {
        show: true
      }
    },
  
  
  };
const PieChart = () => {
const [state, setState] = useState<any>(initialData)
  return (
    <div>
              <div id="chart">
                <ReactApexChart options={state.options} series={state.series} type="pie" height="240px" />
              </div>
              <div id="html-dist"></div>
            </div>
  )
}

export default PieChart