import React, { useEffect, useState } from "react";
import { useNewTicketNotificationMutation } from "../../../../Services/settingsAPIs/TicketSettingsAPI";
import { useEditEmailNotificationMutation } from "../../../../Services/settingsAPIs/EmailConfiguration";
import { toast } from "react-toastify";

interface IProps {
  selectedTeam: number;
  ticketNotificationsData: any;
  notificationType: string;
  refetchATND: any;
  refetchMTND: any;
}

const AutoTicketRules: React.FC<IProps> = ({
  selectedTeam,
  ticketNotificationsData,
  notificationType,
  refetchATND,
  refetchMTND,
}) => {
  const initialValues = {
    team: String(selectedTeam),
    status_type: "",
    send_to: [],
    creator_template: "",
    assignee_template: "",
    customer_template: "",
    notification_type: notificationType,
  };

  const [triggerNewTicketNotificationConfig] =
    useNewTicketNotificationMutation();
  const [message, setMessage] = useState("");
  const [notificationConfig, setNotificationConfig] =
    useState<any>(initialValues);
  const [triggerEditCommonNotification] = useEditEmailNotificationMutation();
  const [isNewNotificationConfig, setIsNewNotificationConfig] = useState(false);

  const insertPlaceholder = (placeholder: string) => {
    setMessage(placeholder);
  };

  const removeProperty = (prop) => {
    const newObj = { ...notificationConfig };
    delete newObj[prop];
    setNotificationConfig(newObj);
  };

  function loadTemplateOf(sendto: string) {
    switch (sendto) {
      case "Creator":
        return notificationConfig.creator_template;
      case "Customer":
        return notificationConfig.customer_template;
      case "Assignee":
        return notificationConfig.assignee_template;
    }
  }

  function handleRespectiveFieldtemplateUpdate(evt: any) {
    if (notificationConfig.send_to[0] == "Creator")
      setNotificationConfig({
        ...notificationConfig,
        creator_template: evt.target.value,
        customer_template: "",
        assignee_template: "",
      });
    else if (notificationConfig.send_to[0] == "Customer")
      setNotificationConfig({
        ...notificationConfig,
        creator_template: "",
        customer_template: evt.target.value,
        assignee_template: "",
      });
    else if (notificationConfig.send_to[0] == "Assignee")
      setNotificationConfig({
        ...notificationConfig,
        creator_template: "",
        customer_template: "",
        assignee_template: evt.target.value,
      });
  }

  const handleWhenStatusInputChange = (e: any) => {
    const { name, value } = e.target;
    const existingNotificationFound =
      notificationType === "auto_ticket" && ticketNotificationsData.auto
        ? ticketNotificationsData.auto.find(
            (x: any) =>
              x.status_type === value &&
              x.send_to[0] == notificationConfig.send_to[0]
          )
        : notificationType === "manual_ticket" && ticketNotificationsData.manual
        ? ticketNotificationsData.manual.find(
            (x: any) =>
              x.status_type === value &&
              x.send_to[0] == notificationConfig.send_to[0]
          )
        : null;

    if (existingNotificationFound) {
      console.dir(existingNotificationFound);
      if (notificationConfig.send_to[0] == "Creator")
        setNotificationConfig({
          ...notificationConfig,
          [name]: value,
          creator_template: existingNotificationFound.creator_template,
          id: existingNotificationFound.id,
        });
      else if (notificationConfig.send_to[0] == "Customer")
        setNotificationConfig({
          ...notificationConfig,
          [name]: value,
          customer_template: existingNotificationFound.customer_template,
          id: existingNotificationFound.id,
        });
      else if (notificationConfig.send_to[0] == "Assignee")
        setNotificationConfig({
          ...notificationConfig,
          [name]: value,
          assignee_template: existingNotificationFound.assignee_template,
          id: existingNotificationFound.id,
        });
      else
        setNotificationConfig({
          ...notificationConfig,
          [name]: value,
          id: existingNotificationFound.id,
        });
      setIsNewNotificationConfig(false);
    } else {
      setNotificationConfig({
        ...notificationConfig,
        [name]: value,
        customer_template: "",
        creator_template: "",
        assignee_template: "",
      });
      setIsNewNotificationConfig(true);
    }
  };

  const handleSendToInputChange = (e: any) => {
    const { name, value } = e.target;
    const existingNotificationFound =
      notificationType === "auto_ticket" && ticketNotificationsData.auto
        ? ticketNotificationsData.auto.find(
            (x: any) =>
              x.status_type === notificationConfig.status_type &&
              JSON.stringify(x.send_to) == JSON.stringify([value])
          )
        : notificationType === "manual_ticket" && ticketNotificationsData.manual
        ? ticketNotificationsData.manual.find(
            (x: any) =>
              x.status_type === notificationConfig.status_type &&
              JSON.stringify(x.send_to) == JSON.stringify([value])
          )
        : null;

    if (existingNotificationFound) {
      console.dir(existingNotificationFound);
      if (value === "Creator")
        setNotificationConfig({
          ...notificationConfig,
          [name]: [value],
          creator_template: existingNotificationFound.creator_template,
          id: existingNotificationFound.id,
        });
      else if (value === "Customer")
        setNotificationConfig({
          ...notificationConfig,
          [name]: [value],
          customer_template: existingNotificationFound.customer_template,
          id: existingNotificationFound.id,
        });
      else if (value === "Assignee")
        setNotificationConfig({
          ...notificationConfig,
          [name]: [value],
          assignee_template: existingNotificationFound.assignee_template,
          id: existingNotificationFound.id,
        });
      else
        setNotificationConfig({
          ...notificationConfig,
          [name]: [value],
          id: existingNotificationFound.id,
        });
      setIsNewNotificationConfig(false);
    } else {
      setNotificationConfig({
        ...notificationConfig,
        [name]: [value],
        customer_template: "",
        creator_template: "",
        assignee_template: "",
      });
      setIsNewNotificationConfig(true);
    }
  };

  useEffect(() => {
    function setMessageInRespectivetemplate() {
      if (message)
        setNotificationConfig((prev: any) => {
          const updated =
            prev.send_to[0] === "Creator"
              ? {
                  ...prev,
                  creator_template: prev.creator_template.concat(message),
                }
              : prev.send_to[0] === "Customer"
              ? {
                  ...prev,
                  customer_template: prev.customer_template.concat(message),
                }
              : prev.send_to[0] === "Assignee"
              ? {
                  ...prev,
                  assignee_template: prev.assignee_template.concat(message),
                }
              : prev;
          return updated;
        });
    }
    setMessageInRespectivetemplate();
  }, [message]);

  useEffect(() => {
    isNewNotificationConfig && removeProperty("id");
  }, [isNewNotificationConfig]);
  return (
    <>
      <div className="row mt-3">
        <div className="col">
          <span className="input-group-text bg-secondary mt-5">
            <strong>
              {notificationType === "auto_ticket" ? "Auto" : "Manual"} ticket
              rules
            </strong>
          </span>
        </div>
      </div>

      <div className="row mt-5">
        <div className="w-100 mw-200px">
          <label className="d-flex align-items-start">When Status</label>
          {/*begin::Select2*/}
          <select
            className="form-select select2-hidden-accessible"
            name="status_type"
            onChange={handleWhenStatusInputChange}
            value={notificationConfig.status_type}
          >
            <option value="select team" hidden selected>
              Select Status
            </option>
            <option value="active">Active</option>
            <option value="wip">In Progress</option>
            <option value="closed">Closed</option>
          </select>
          {/*end::Select2*/}
        </div>

        <div className="w-100 mw-200px">
          <label className="d-flex align-items-start">Send to</label>
          {/*begin::Select2*/}
          <select
            className="form-select select2-hidden-accessible"
            name="send_to"
            onChange={handleSendToInputChange}
            value={notificationConfig.send_to[0]}
          >
            <option value="select team" hidden selected>
              Select
            </option>
            <option value="Creator">Creator</option>
            <option value="Customer">Customer</option>
            <option value="Assignee">Assignee</option>
          </select>
          {/*end::Select2*/}
        </div>
      </div>

      <div className="row gap-5">
        <div className="w-100 mw-200px mt-5">
          <div className="d-flex align-items-center">
            <i className="bi bi-person-add me-2 fs-2 text-dark"></i>
            <button
              className="form-control form-control fw-bold"
              onClick={() => insertPlaceholder("{{assigned_by}}")}
            >
              Assigned By
            </button>
          </div>
        </div>

        <div className="w-100 mw-200px mt-5">
          <div className="d-flex align-items-center">
            <i className="bi bi-person-check-fill me-2 fs-2 text-dark"></i>
            <button
              className="form-control form-control fw-bold"
              onClick={() => insertPlaceholder("{{sender_name}}")}
            >
              Sender Name
            </button>
          </div>
        </div>

        <div className="w-100 mw-200px mt-5">
          <div className="d-flex align-items-center">
            <i className="bi bi-ticket-detailed text-dark fs-2 me-2" />
            <button
              className="form-control form-control fw-bold"
              onClick={() => insertPlaceholder("{{ticket_id}}")}
            >
              Ticket ID
            </button>
          </div>
        </div>

        <div className="w-100 mw-225px mt-5">
          <div className="d-flex align-items-center">
            <i className="bi bi-ticket-detailed-fill text-dark fs-2 me-2" />
            <button
              className="form-control form-control fw-bold"
              onClick={() => insertPlaceholder("{{ticket_description}}")}
            >
              Ticket Description
            </button>
          </div>
        </div>
      </div>

      {/* Text area and label */}
      <div className="row mt-5">
        <div className="w-100">
          <div className="mb-0">
            <div className="input-group">
              <span className="input-group-text">Send to:</span>
              <input
                type="email"
                className="form-control"
                aria-label="Send to"
                aria-describedby="basic-addon1"
                value={notificationConfig.send_to[0]}
                readOnly
              />
            </div>
          </div>

          <textarea
            className="form-control"
            rows={4}
            placeholder="Enter Message..."
            value={loadTemplateOf(notificationConfig.send_to[0])}
            onChange={handleRespectiveFieldtemplateUpdate}
          ></textarea>
        </div>

        <div className="text-end mt-5">
          <button
            className="btn btn-primary"
            onClick={(e) => {
              isNewNotificationConfig
                ? triggerNewTicketNotificationConfig(notificationConfig).then(
                    (res: any) => {
                      if (res.data) {
                        toast.success("new notification created!");
                      }
                      refetchATND();
                      refetchMTND();
                    }
                  )
                : triggerEditCommonNotification({
                    notificationId: notificationConfig.id,
                    body: notificationConfig,
                  }).then((res: any) => {
                    if (res.data) {
                      toast.success("notification updated!");
                    }
                    refetchATND();
                    refetchMTND();
                  });
            }}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default AutoTicketRules;
