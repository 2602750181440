import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const MessagesApi = createApi({
  reducerPath: "messages",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    InternalNotes: builder.mutation({
      query: (body) => ({
        url: "v1/tickets/internal-note/",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useInternalNotesMutation } = MessagesApi;
