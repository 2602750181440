import NestedDD from "./NestedDD";

const DispositionSelector = ({
    theDispositions,
    selectedDisposition,
    setSelectedDisposition,
}) => {
    return (
        <div className="card d-flex flex-row p-2">
            <div>
            <input
                id="selecteddispositionviewer"
                type="text"
                className="form-control"
                disabled
                placeholder="select a disposition from menu -->"
                value={selectedDisposition && selectedDisposition.topic}
            />
            </div>
            <div className="bg-primary mx-1 rounded">
            <NestedDD
                menuItemsData={theDispositions}
                setSelectedDisposition={setSelectedDisposition}
            />
            </div>
        </div>
    );
};

export default DispositionSelector;
