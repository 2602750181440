import React, {useState} from "react";
import './clientStyles.scss'
import TopRight from "./TopRight";
import { capitalizeFirstLetter } from "../../components/reusableComponent/CapitalLetter";
import TopRightTools from "../../sections/header/top-right/TopRightTools";

const fetchingTenant = window.location.hostname.split('.')[0];

const ClientLoginHeader = () => {

    return (
        <div className="header">
            <div className="top">
                <div className="header-left">
                    <p>Branch Portal</p>
                </div>
                <div className="header-center">
                    {/* <p className="names-div">
                        <span  className="name">{capitalizeFirstLetter(fetchingTenant)}</span>
                        <span className="testing">User Name</span>
                    </p> */}
                </div>
                <div className="header-right">
                    <TopRightTools />
                    {/* <p className="name">Business Name</p>
          <p className="testing">User Name</p> */}
                </div>
            </div>
            {/* <div className="support">
                <p>Support</p>
            </div> */}
        </div>
    );
};

export default ClientLoginHeader;
