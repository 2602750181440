import React from "react";
import Chart from "react-apexcharts";
import "./Styles.css";
import { format } from "date-fns";

interface IPROPS {
  emailDashboardData: any;
}

const ResponseTime: React.FC<IPROPS> = ({ emailDashboardData }) => {
  // Log the data for debugging

  // Map the data to fit the chart series format
  const dailyResponseData =
    emailDashboardData?.email_response_time?.email_daily_avg__response_time.map(
      (data: { day: string; avg_resolution_time: number }) => ({
        x: format(new Date(data.day), "dd-MM-yyyy"), // Formatting date for x-axis
        y: Math.floor(data.avg_resolution_time / 3600), // Convert to whole hours for y-axis
      })
    );

  const options: any = {
    chart: {
      type: "line", // Use line chart for better visualization of time series data
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
        export: {
          svg: {
            filename: "response_time",
          },
          png: {
            filename: "response_time",
          },
          csv: {
            filename: "response_time",
          },
        },
      },
    },
    xaxis: {
      type: "category", // Using category for date labels
      labels: {
        rotate: -45, // Rotates labels for better readability
        format: "dd MMM", // Short date format
      },
    },
    yaxis: {
      min: 0, // Ensure minimum is
      forceNiceScale: true,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
        colorStops: [
          {
            offset: 0,
            color: "#c251ed", // New purple color
          },
          {
            offset: 100,
            color: "#ffffff", // Original blue color
          },
        ],
      },
    },
    grid: {
      show: false,
      padding: {
        left: 25, // Add padding to the left
        right: 25, // Add padding to the right
      },
    }

  };

  const series = [
    {
      name: "Avg Response Time",
      data: dailyResponseData || [], // Fallback to empty array if no data
    },
  ];
  const responseTimeInSeconds = emailDashboardData?.email_response_time?.email_overall_avg_response_time;

  const formatTime = (totalSeconds: number) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60); // Use Math.floor to ensure seconds are an integer
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };
  return (
    <div className="card card-flush h-md-50 mb-5 mb-xl-10">
      <div className="card-header pt-5">
        <div className="card-title d-flex flex-column">
          <div className="d-flex align-items-center">
            <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
              {responseTimeInSeconds !== undefined ? formatTime(responseTimeInSeconds) : "00:00:00"}
            </span>
            <span className="text-gray-400 pt-1 fw-semibold mx-4">
              {emailDashboardData?.email_response_percentage_change > 0 ? (
                <>
                  <i className="bi bi-arrow-up text-success fs-2"></i>
                  {Number.isInteger(
                    emailDashboardData.email_response_percentage_change
                  )
                    ? emailDashboardData.email_response_percentage_change
                    : emailDashboardData.email_response_percentage_change?.toFixed(
                      2
                    )}
                  %
                </>
              ) : emailDashboardData?.email_response_percentage_change < 0 ? (
                <>
                  <i className="bi bi-arrow-down text-danger fs-2"></i>
                  {Number.isInteger(
                    emailDashboardData.email_response_percentage_change
                  )
                    ? emailDashboardData.email_response_percentage_change
                    : emailDashboardData.email_response_percentage_change?.toFixed(
                      2
                    )}
                  %
                </>
              ) : emailDashboardData?.email_response_percentage_change === 0 ? (
                <span>-</span>
              ) : (
                <span>-</span>
              )}
            </span>
          </div>
          <span className="text-dark pt-1 fs-6">Response time</span>
        </div>
      </div>
      <Chart
        options={options}
        series={series}
        type="area"
        height={220}
        className="custom-apex-chart"
      />
    </div>
  );
};

export default ResponseTime;
