import { useDrag, useDrop } from "react-dnd";
import { capitalizeFirstLetter } from "../../reusableComponent/CapitalLetter";
import {
  useAddChoiceToDynamicFieldDDMutation,
  useAddOptionToDynamicFieldCheckboxMutation,
  useDeleteOptionOfDynamicFieldCheckboxMutation,
  useDeleteTicketFieldChoiceMutation,
} from "../../../Services/settingsAPIs/TicketSettingsAPI";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  useEditCheckBoxMutation,
  useEditDropwDownMutation,
} from "../../../Services/settingsAPIs/CommonAPI";

const ItemType = "FIELD";

interface LiftCheckOption {
  choice?: string;
  fieldId?: string;
}

interface Field {
  label: string;
  field_type: string;
  is_required: boolean;
  is_unique: boolean;
  is_masked: boolean;
  is_editable: boolean;
  id: any;
  choices: any;
  multiple_choices: any;
}

interface DraggableFieldProps {
  field: Field;
  index: number;
  moveField: (fromIndex: number, toIndex: number) => void;
  editFieldsHandler: (field: Field) => void;
  deleteDataHandler: (field: Field) => void;
  expandForMoreSpecifications: any;
  setExpandForMoreSpecifications: any;
  setEditDropdownListItem: any;
  editDropdownListItem: any;
  refetch: any;
  setLiftingDropData: any;
}

const DraggableAccountField: React.FC<DraggableFieldProps> = ({
  setExpandForMoreSpecifications,
  expandForMoreSpecifications,
  field,
  index,
  moveField,
  editFieldsHandler,
  deleteDataHandler,
  setEditDropdownListItem,
  editDropdownListItem,
  refetch,
  setLiftingDropData,
}) => {
  const [newDropdownListItem, setNewDropdownListItem] = useState("");
  const [liftCheckOption, setLiftCheckOption] = useState<LiftCheckOption>({});

  const [editCheckboxListOption, setEditCheckboxListOption] = useState({
    enabled: false,
    forChoiceId: -1,
    currentVal: liftCheckOption?.choice ? liftCheckOption?.choice : "",
    fieldId: "",
  });
  const [newCheckboxOption, setNewCheckboxOption] = useState("");
  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem: { index: number }) => {
      if (draggedItem.index !== index) {
        moveField(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const [triggerAddChoiceToDDfield] = useAddChoiceToDynamicFieldDDMutation();
  const [triggerDeleteChoiceFromTicketField] =
    useDeleteTicketFieldChoiceMutation();
  const [triggerAddOptionToCheckboxField] =
    useAddOptionToDynamicFieldCheckboxMutation();
  const [triggerDeleteOptionFromCheckboxTicketField] =
    useDeleteOptionOfDynamicFieldCheckboxMutation();
  const [triggerEditDrowDown] = useEditDropwDownMutation();
  const [triggerEditCheckbox] = useEditCheckBoxMutation();
  const userRole = localStorage.getItem("user_role");


  const handleDropdownNewListItemEdit = (event: any) => {
    setNewDropdownListItem(event.target.value);
  };

  const handleCheckboxNewOptionEdit = (event: any) => {
    setNewCheckboxOption(event.target.value);
  };

  const saveEditFieldHandler = async () => {
    const editObj = {
      choice: editDropdownListItem?.currentVal,
      field: editDropdownListItem?.fieldId,
    };

    try {
      const response = await triggerEditDrowDown({
        body: editObj,
        id: editDropdownListItem?.forChoiceId,
      });
      if (response?.data) {
        toast.success("Field edited");
        refetch();
      } else {
        toast.error("Unable to add field");
      }
    } catch (error) {
      toast.error("An error occured");
    }
  };

  const saveEditCheckBoxdHandler = async () => {
    const editObj = {
      choice: editCheckboxListOption?.currentVal,
      field: editCheckboxListOption?.fieldId,
    };

    try {
      const response = await triggerEditCheckbox({
        body: editObj,
        id: editCheckboxListOption?.forChoiceId,
      });
      if (response?.data) {
        toast.success("Field edited");
        refetch();
      } else {
        toast.error("Unable to add field");
      }
    } catch (error) {
      toast.error("An error occured");
    }
  };

  return (
    <div
      ref={(node) => ref(drop(node))}
      className="card my-1 min-w-600px"
      style={{ cursor: "grab", padding: "7px" }}
    >
      <div className="row align-items-center">
        <div
          className="col-4 text-start ps-lg-10 ps-4"
          style={{
            justifyContent: "space-between",
            display: "flex",
            width: "34%",
            paddingRight: "0px",
          }}
        >
          {field?.field_type === "drop-down" ? (
            <div className="d-flex align-items-center flex-nowrap">
              <label>
                <div>{capitalizeFirstLetter(field.label)}</div>
              </label>
              <div
                className={`d-flex mx-2 ${expandForMoreSpecifications.expansionOn === index &&
                  expandForMoreSpecifications.expanded
                  ? ""
                  : "d-none"
                  }`}
              >
                <input
                  type="text"
                  placeholder="Add a new item to the list."
                  className="form-control m-1"
                  value={newDropdownListItem}
                  onChange={handleDropdownNewListItemEdit}
                />
                <button
                  className="btn btn-sm btn-primary m-1"
                  onClick={async () => {
                    try {
                      await triggerAddChoiceToDDfield({
                        choice: newDropdownListItem,
                        field: field.id,
                      }).unwrap();  // Unwraps the result or throws an error if it fails

                      setNewDropdownListItem("");  // Clear the input on success
                      refetch();  // Refresh the data
                    } catch (error) {
                      console.log(error);
                      // Extract dynamic error messages
                      const errorMessages = error?.data
                        ? Object.entries(error.data).map(([key, messages]: any) => {
                          return messages.map(msg => `${msg}`).join(", ");
                        }).join(", ")
                        : "An error occurred while adding the item.";

                      toast.error(errorMessages);  // Display the error messages
                    }
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          ) : field.field_type === "check-box" ? (
            <div
              className="d-flex align-items-center flex-nowrap"
              style={{ width: "100%" }}
            >
              <label>
                <div>{capitalizeFirstLetter(field.label)}</div>
              </label>
              <div
                className={`d-flex mx-2 ${expandForMoreSpecifications.expansionOn === index &&
                  expandForMoreSpecifications.expanded
                  ? ""
                  : "d-none"
                  }`}
              >
                <input
                  type="text"
                  placeholder="Add a new checkbox option."
                  className="form-control m-1"
                  value={newCheckboxOption}
                  onChange={handleCheckboxNewOptionEdit}
                />
                <button
                  className="btn btn-sm btn-primary m-1"
                  onClick={async () => {
                    try {
                      await triggerAddOptionToCheckboxField({
                        choice: newCheckboxOption,
                        field: field.id,
                      }).unwrap();  // Unwraps the result or throws an error if it fails

                      setNewCheckboxOption("");  // Clear the input on success
                      refetch();  // Refresh the data
                    } catch (error) {
                      console.log(error);
                      // Extract dynamic error messages
                      const errorMessages = error?.data
                        ? Object.entries(error.data).map(([key, messages]: any) => {
                          return messages.map(msg => `${msg}`).join(", ");
                        }).join(", ")
                        : "An error occurred while adding the option.";

                      toast.error(errorMessages);  // Display the error messages
                    }
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          ) : (
            <label>{capitalizeFirstLetter(field.label)}</label>
          )}

          {field?.field_type === "drop-down" ? (
            <span
              onClick={() =>
                setExpandForMoreSpecifications({
                  expansionOn: index,
                  expanded: !expandForMoreSpecifications.expanded,
                  expandForFieldType: "drop-down",
                })
              }
            >
              <i
                className="fs-2x text-dark bi bi-plus-lg me-2"
                style={{
                  fontSize: "10px",
                  cursor: "pointer",
                  margin: "0",
                }}
              ></i>
            </span>
          ) : field.field_type === "multi-level" ? (
            <button
              className="btn btn-link d-flex me-4"
              onClick={() =>
                setExpandForMoreSpecifications({
                  expansionOn: index,
                  expanded: !expandForMoreSpecifications.expanded,
                  expandForFieldType: "multi-level",
                })
              }
            >
              <i className="text-primary fs-2 bi bi-arrow-bar-left"></i>
              {/* Configure */}
            </button>
          ) : field.field_type === "check-box" ? (
            <span
              onClick={() =>
                setExpandForMoreSpecifications({
                  expansionOn: index,
                  expanded: !expandForMoreSpecifications.expanded,
                  expandForFieldType: "check-box",
                })
              }
            >
              <i
                className="fs-2x text-dark bi bi-plus-lg me-2"
                
                style={{
                  padding: "0",
                  margin: "0",
                  fontSize: "10px",
                  cursor: "pointer",
                  marginRight: "0px",
                }}
              ></i>
            </span>
          ) : (
            <div></div>
          )}
        </div>

        <div
          className="col-2 d-flex align-items-end dropdown"
          style={{
            textAlign: "justify",
            justifyContent: "start",
            width: "10%",
          }}
        >
          <div className="w-100 mw-150px">
            <label className="form form-label">
              {capitalizeFirstLetter(field?.field_type)}
            </label>
          </div>
        </div>

        <div className="col text-center">
          <i
            className={`me-4 fs-2 ${field?.is_required ? "bi bi-check2" : "bi bi-x"
              }`}
            style={{ cursor: "pointer", color: "black" }}
          ></i>
        </div>

        <div className="col text-center">
          <i
            className={`me-4 fs-2 ${field?.is_unique ? "bi bi-check2" : "bi bi-x"
              }`}
            style={{ cursor: "pointer" }}
          ></i>
        </div>

        <div className="col text-center">
          <i
            className={`me-4 fs-2 ${field.is_masked ? "bi bi-check2" : "bi bi-x"
              }`}
            style={{ cursor: "pointer" }}
          ></i>
        </div>

        <div className="col text-center">
          <i
            className={`me-4 fs-2 ${field.is_editable ? "bi bi-check2" : "bi bi-x"
              }`}
            style={{ cursor: "pointer" }}
          ></i>
        </div>

        <div className="col text-end ">
          {/* Edit Button */}
          <button
            className="border-0 bg-transparent text-hover-primary p-0"
            title="Edit"
            onClick={() => editFieldsHandler(field)}
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-pencil fs-4 text-dark"></i>
          </button>
          {/* Delete Button */}
          { userRole !== "agent" ? (
          <button
            className="border-0 bg-transparent text-hover-danger p-0 mx-4"
            title="Delete"
            onClick={() => deleteDataHandler(field)}
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-trash fs-4 text-dark"></i>
          </button>
          ): null}
        </div>

      </div>

      {field?.field_type === "drop-down" &&
        expandForMoreSpecifications.expansionOn === index &&
        expandForMoreSpecifications.expanded && (
          <div className="card my-1 bg-light-primary">
            <ul className="list-group list-group-flush px-5">
              {field.choices?.map((choice: any, choiceIdx: number) => (
                <li
                  key={choiceIdx}
                  className="card-header my-0 min-h-40px d-flex align-items-center justify-content-lg-start"
                >
                  {editDropdownListItem?.enabled &&
                    editDropdownListItem?.forChoiceId === choice.id ? (
                    <div className="d-flex align-items-center">
                      <input
                        className="form-control"
                        type="text"
                        value={editDropdownListItem.currentVal}
                        onChange={(e) =>
                          setEditDropdownListItem({
                            ...editDropdownListItem,
                            currentVal: e.target.value,
                          })
                        }
                      />
                      <button
                        className="btn btn-sm btn-primary mx-2"
                        onClick={() => {
                          // Functionality to update the choice
                          // You might need to implement the API call here
                          setEditDropdownListItem({
                            enabled: false,
                            forChoiceId: -1,
                            currentVal: "",
                            fieldId: "",
                          });
                          saveEditFieldHandler();
                        }}
                      >
                        Save
                      </button>
                      <button
                        className="btn btn-sm btn-secondary"
                        onClick={() =>
                          setEditDropdownListItem({
                            enabled: false,
                            forChoiceId: -1,
                            currentVal: "",
                            fieldId: "",
                          })
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      {/* Edit Button */}
                      <button
                        className="border-0 bg-transparent text-hover-primary p-0 mx-2"
                        title="Edit"
                        onClick={() => {
                          setLiftingDropData(choice);  // Corrected assignment
                          setEditDropdownListItem({
                            enabled: true,
                            forChoiceId: choice.id,
                            currentVal: choice.choice,
                            fieldId: choice?.field,
                          });
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="bi bi-pencil fs-4 text-dark"></i>
                      </button>

                      {/* Delete Button */}
                      <button
                        className="border-0 bg-transparent text-hover-danger p-0 mx-2"
                        title="Delete"
                        onClick={() => {
                          triggerDeleteChoiceFromTicketField({ id: choice.id });
                          refetch();
                        }}
                        style={{
                          cursor: "pointer",
                          fontWeight: "600",
                          color: "black",
                        }}
                      >
                        <i className="bi bi-trash fs-4 text-dark"></i>
                      </button>

                      {/* Choice Text */}
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                        }}
                      >
                        {choice.choice}
                      </span>
                    </div>

                  )}
                </li>
              ))}
            </ul>
          </div>
        )}

      {field?.field_type === "multi-level" &&
        expandForMoreSpecifications.expansionOn === index &&
        expandForMoreSpecifications.expanded && (
          <div className="row py-2 px-5"></div>
        )}

      {field?.field_type === "check-box" &&
        expandForMoreSpecifications.expansionOn === index &&
        expandForMoreSpecifications.expanded && (
          <div className="card my-1 bg-light-primary">
            <div className="list-group list-group-flush px-5">
              {field.multiple_choices?.map((choice: any, choiceIdx: number) => (
                <li
                  key={choiceIdx}
                  className="card-header my-0 min-h-40px d-flex align-items-center justify-content-lg-start"
                >
                  {editCheckboxListOption?.enabled &&
                    editCheckboxListOption?.forChoiceId === choice.id ? (
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "4px" }}
                    >
                      <input
                        className="form-control"
                        type="text"
                        value={editCheckboxListOption.currentVal}
                        onChange={(e) =>
                          setEditCheckboxListOption({
                            ...editCheckboxListOption,
                            currentVal: e.target.value,
                          })
                        }
                      />
                      <button
                        className="btn btn-primary"
                        style={{ padding: "8px 10px" }}
                        onClick={() => {
                          setEditCheckboxListOption({
                            enabled: false,
                            forChoiceId: -1,
                            currentVal: "",
                            fieldId: "",
                          });
                          saveEditCheckBoxdHandler();
                        }}
                      >
                        Save
                      </button>
                      <button
                        className="btn btn-sm btn-secondary"
                        onClick={() =>
                          setEditCheckboxListOption({
                            enabled: false,
                            forChoiceId: -1,
                            currentVal: "",
                            fieldId: "",
                          })
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      {/* Edit Button */}
                      <button
                        className="border-0 bg-transparent text-hover-primary p-0 mx-2"
                        title="Edit"
                        onClick={() => {
                          setLiftCheckOption(choice);
                          setEditCheckboxListOption({
                            enabled: true,
                            forChoiceId: choice.id,
                            currentVal: choice.choice,
                            fieldId: choice?.field,
                          });
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="bi bi-pencil fs-4 text-dark"></i>
                      </button>

                      {/* Delete Button */}
                      <button
                        className="border-0 bg-transparent text-hover-danger p-0 mx-2"
                        title="Delete"
                        onClick={() => {
                          triggerDeleteOptionFromCheckboxTicketField({ id: choice.id });
                          refetch();
                        }}
                        style={{
                          cursor: "pointer",
                          fontWeight: "600",
                          color: "black",
                        }}
                      >
                        <i className="bi bi-trash fs-4 text-dark"></i>
                      </button>

                      {/* Choice Text */}
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                        }}
                      >
                        {choice?.choice}
                      </span>
                    </div>

                  )}
                </li>
              ))}
            </div>
          </div>
        )}
    </div>
  );
};

export default DraggableAccountField;
