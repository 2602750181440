import AverageResolutionTime from "./charts/AverageResolutionTime";
import ResolutionBeyondSLA from "./charts/ResolutionBeyondSLA";
import PieChart from "../email/charts/PieCharts";
import Disposition from "./charts/Disposition";
import ChannelWise from "./charts/ChannelWiseTickets";
import TicketVolume from "../ticket/charts/TicketVolume";
import { useGetAllDashboardDataQuery, useTicketWorkflowIdQuery } from "../../../Services/dashboard/dashboard";
import { useEffect, useState } from "react";
import { useTeamsQuery } from "../../../Services/settingsAPIs/CommonAPI";
import CustomDate from "../../../components/common/CustomDate";
import TotalTickets from "./charts/TotalTickets";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useGetAllTeamsQuery } from "../../../Services/reports/tickets/ReportTickets";

const TicketDashboard = () => {
  const [selectedTeam, setSelectedTeam] = useState(localStorage.getItem('teamId'));
  const [theTeams, setTheTeams] = useState([]);
  const [ticketDashboardData, setTicketDashboardData] = useState<any>();
  const [params, setParams] = useState<any>({ dashboard_type: 'ticket' });
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [loading, setLoading] = useState(false);

  const { data: teamsData, isLoading: teamsLoading } = useGetAllTeamsQuery({});
  const { data: dashboardData } = useGetAllDashboardDataQuery(params, {
    skip: !params.team || !params.start_date || !params.end_date,
  });
  const { data: workflow } = useTicketWorkflowIdQuery(selectedTeam, { skip: !selectedTeam });
  const [selectedWorkFlow, setselectedWorkFlow] = useState('');


  useEffect(() => {
    if (dashboardData) {
      setTicketDashboardData(dashboardData);
      setLoading(false); // Set loading to false once data is received
    }
  }, [dashboardData]);

  useEffect(() => {
    if (teamsData) {
      setTheTeams(teamsData?.teams);
    }
  }, [teamsData]);

  useEffect(() => {
    if (workflow?.results?.length > 0) {
      setselectedWorkFlow(workflow?.results[0]?.id);
    }
  }, [workflow]);

  useEffect(() => {
    if (dateRange) {
      setLoading(true); // Set loading to true when dateRange changes
      const formatToDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      setParams((prevParams) => ({
        ...prevParams,
        start_date: formatToDate(dateRange[0].startDate),
        end_date: formatToDate(dateRange[0].endDate),
      }));
    }
  }, [dateRange]);

  useEffect(() => {
    if (selectedTeam) {
      setParams((prevParams) => ({
        ...prevParams,
        team: selectedTeam,
      }));
      setLoading(true); // Set loading to true when selectedTeam changes
    }
  }, [selectedTeam]);

  const handleWorkflowChange = (event) => {
    setselectedWorkFlow(event.target.value);
  };

  return (
    <div className="card-header align-items-center py-1 my-1 gap-2 gap-md-5">
      <div id="kt_app_toolbar" className="app-toolbar py-3">
        <div id="kt_app_toolbar_container" className="app-container ms-4 me-4 mb-4 d-flex flex-stack">
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <h2>Ticket Dashboard</h2>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-10">
            <div className="w-100 mw-250px" style={{ whiteSpace: "nowrap" }}>
              <CustomDate dateRange={dateRange} setDateRange={setDateRange} />
            </div>
            <div className="w-100 mw-150px">
              <select
                className="form-select"
                value={selectedTeam}
                onChange={(e) => setSelectedTeam(e.target.value)}
              >
                {theTeams.map((team) => (
                  <option key={team.id} value={team.id}>
                    {team.name}
                  </option>
                ))}
              </select>
            </div>
            {/* <div className="w-100 mw-250px">
              <select className="form-select">
                <option value="select team" selected hidden>
                  Select users
                </option>
                <option value="Team 01">Team 01</option>
                <option value="Team 02">Team 02</option>
                <option value="Team 03">Team 03</option>
              </select>
            </div> */}
            <div className="w-100 mw-250px" style={{ position: "relative" }}>
              <div style={{ position: "absolute", top: "-9px", left: "5px", background: "white" }}>
                <p style={{ margin: "0", fontSize: "12px" }}>Workflow</p>
              </div>
              <select
                className="form-select"
                onChange={handleWorkflowChange}
                value={selectedWorkFlow}
              >
                <option value="" selected>
                  Select Workflow
                </option>
                {workflow?.results?.map((team) => (
                  <option key={team.id} value={team.id}>
                    {team.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div className="card-body pt-0">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="row gy-5 g-xl-10 mb-8">
                <div className="col-7">
                  <TotalTickets data={ticketDashboardData} />
                </div>
                <div className="col-5">
                  <AverageResolutionTime />
                  <ResolutionBeyondSLA />
                </div>
              </div>
              <div className="row gx-5 gx-xl-10">
                <div className="col-xl-6 mb-5 mb-xl-10">
                  <PieChart
                    overview={[
                      ticketDashboardData?.tickets_by_status_type?.active || "",
                      ticketDashboardData?.tickets_by_status_type?.closed || "",
                      ticketDashboardData?.tickets_by_status_type?.wip || "",
                      ticketDashboardData?.tickets_by_status_type?.reopen || "",
                    ]}
                  />
                </div>
                <div className="col-xl-6 mb-5 mb-xl-10">
                  <Disposition getDispositionData={ticketDashboardData} />
                </div>
              </div>
              <div className="row gx-5 gx-xl-10">
                <div className="col-xl-6 mb-5 mb-xl-10">
                  <ChannelWise channelWiseData={ticketDashboardData} />
                </div>
                <div className="col-xl-6 mb-5 mb-xl-10">
                  <TicketVolume selectedWorkFlow={selectedWorkFlow} selectedTeam={selectedTeam} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketDashboard;
