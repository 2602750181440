import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Tooltip, IconButton, Chip, Box, Modal } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InfoIcon from "@mui/icons-material/Info";
import { useAuditTrailQuery } from "../../../Services/ticket/Audit-Trail";
import { useCampignsDynamicFieldsQuery } from "../../../Services/campign/campignList";
import { useAddAttachmentsMutation } from "../../../Services/campign/CampaignDetail";
import { Loader } from "react-bootstrap-typeahead";
// import Loader from "";
import DeleteAttchmentModal from "./DeleteAttchmentModal";
import ReactModal from "react-modal";


interface IProps {
    ticket: any;
    refetch: any;
    uuid: any;
}

const AttachmentTab: React.FC<IProps> = ({ ticket, refetch, uuid }) => {
    const { data: ticketDynamicFields } = useCampignsDynamicFieldsQuery({});
    const [dynamicFields, setDynamicFields] = useState<any>({});
    const [accountName, setAccountName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const { data } = useAuditTrailQuery(uuid);
    const [addAttachment] = useAddAttachmentsMutation();
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [files, setFiles] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [isBulkDeleteModalOpen, setIsBulkDeleteModalOpen] = useState(false);
    const userRole = localStorage.getItem("user_role");
    const canViewAction = userRole === "admin" || userRole === "manager";
    
    useEffect(() => {
        if (ticket) {
            setAccountName(ticket.account_name || '');
            setFirstName(ticket.first_name || '');
            setLastName(ticket.last_name || '');
            setEmail(ticket.email || '');
            setPhoneNumber(ticket.phone_number || '');
            setDynamicFields(ticket.dynamic_fields);
        }
        console.log(ticket, "ticket")
    }, [ticket]);

    const handleFileChange = async (event) => {
        const newFiles = Array.from(event.target.files);
        const filePromises = newFiles.map((file: any) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    const fileData = {
                        file_name: file.name,
                        file: e.target.result.split(",")[1], // Extracting Base64 part
                    };
                    resolve(fileData);
                };
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        });

        try {
            const fileDataArray = await Promise.all(filePromises);

            // Call API to save attachments
            const updatedTicket = {
                // account_name: accountName,
                // first_name: firstName,
                // last_name: lastName,
                // email: email,
                // phone_number: phoneNumber,
                // dynamic_fields: dynamicFields,
                attachments: fileDataArray
            };

            setIsLoading(true);
            const response = await addAttachment({ variables: updatedTicket, uuid });

            if (response.data) {
                setIsLoading(false);
                toast.success("Attachment added successfully!");
                refetch();
            } else if (response.error) {
                setIsLoading(false);
                toast.error("Error: Something went wrong. Please try again.");
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while uploading files. Please try again.");
        }
    }
    function formatDate(dateString: string) {
        const date = new Date(dateString);

        // Extract components
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        // Format the date and time
        const formattedDate = `${day}/${month}/${year}`;
        const formattedTime = `${hours}:${minutes}`;

        return `${formattedDate} ${formattedTime}`;
    }
    const handleDeleteFile = (fileToDelete) => () => {
        setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
    };
    const handleDownload = (file) => {
        const link = document.createElement('a');
        link.href = file.file;  // Assuming this contains the correct URL to the file
        link.download = file.file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
      const handleDeleteAttachment = (attachment) => {
        setSelectedAttachment(attachment);
        setIsModalOpen(true);
      };
      const customResetPassModalStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "450px",
          maxWidth: "850px", // Max width for larger screens
          margin: "auto",
          Overflow: "hidden",
          OverflowY: "auto",
        },
      };
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "end" }}>
                <Tooltip title="You can add attachments for the following file types: xls, pdf, csv, doc, jpg, png up to a maximum size of 15MB" arrow>
                    <IconButton aria-label="attach file">
                        <label htmlFor="attachFileInput">
                            <AttachFileIcon style={{ cursor: "pointer" }} />
                            <input
                                type="file"
                                id="attachFileInput"
                                className="d-none"
                                multiple
                                onChange={handleFileChange}
                            />
                        </label>
                    </IconButton>
                </Tooltip>
            </div>
            <div
                className="card p-2 my-1 d-flex flex-column"
                style={{ maxHeight: "300px", height: "100%", overflowX: "hidden", overflowY: "auto"}}
            >
                {/* Header Row */}
                <div
                    className="d-flex bg-light p-2 border-bottom"
                    style={{ fontWeight: "bold", textAlign: "center" }}
                >
                    <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        Date
                    </div>

                    <div style={{ flex: 2, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        Attachments
                    </div>
                    {canViewAction&&(
                    <div style={{ flex: 2, display: "flex", justifyContent: "center", alignItems: "center",marginRight:"-7%" }}>
                        Action
                    </div>
                    )}
                </div>

                {/* Attachments List */}
                {ticket?.attachments?.map((attachment: any, index) => (
                    <div
                        className="d-flex align-items-center"
                        style={{ width: "100%", marginTop: "1%" }}
                        key={index} // Using index as key since there might not be a unique id
                    >
                        {/* Date and Time */}
                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <p className="text-muted text-nowrap">
                                {formatDate(attachment.created)}
                            </p>
                        </div>

                        {/* Attachment */}
                        <div
  style={{
    flex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }}
>
  <Chip
    label={attachment.file_name}
    clickable
    onClick={() => handleDownload(attachment)}
    className="secondary"
  />
</div>

                {/* Action */}
                        {canViewAction&&(
                        <div
                            style={{
                                flex: 2,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight:"-7%" 
                            }}
                        >
                             <a href="#" className="btn btn-link">
                <i
                  className="text-dark fs-2 bi bi-trash"
                  onClick={() =>handleDeleteAttachment(attachment)}
                  ></i>
              </a>
              {/* <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="delete-attachment-modal"
      >
        <Box>
          <DeleteAttachment
            selectedAttachment={selectedAttachment}
            closeDeleteAttachmentModal={() => setIsModalOpen(false)}
            refetch={refetch}
          />
        </Box>
      </Modal> */}

      <ReactModal
               isOpen={isModalOpen}
               onRequestClose={() => setIsModalOpen(false)}
                style={customResetPassModalStyles}
                contentLabel="Delete Campaign"
              >
                <DeleteAttchmentModal
                    selectedAttachment={selectedAttachment}
            closeDeleteAttachmentModal={() => setIsModalOpen(false)}
            refetch={refetch}
                />
              </ReactModal>
                        </div>
                        )}
                    </div>
                ))}
            </div>
            {isLoading && <Loader />}
        </div>
    );
};

export default AttachmentTab;
