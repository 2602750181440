import React, { useEffect, useState } from "react";
import { CloseButton } from "react-bootstrap";
import {
    useAccountsUpdateMutation,
    useCustomerUpdateMutation,
    useTagsQuery,
    useTeamsQuery,
} from "../../../Services/settingsAPIs/CommonAPI";
import { toast } from "react-toastify";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { PhoneInput } from "react-international-phone";
import {
    Checkbox,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    FormControl
} from "@mui/material";
import { capitalizeFirstLetter } from "../../reusableComponent/CapitalLetter";
import { useAccountDynamicFieldQuery, useContactDynamicFieldQuery } from "../../../Services/customer/FrontendCustomer";

interface IProps {
    data: any;
    closeModal: any;
    refetch: any;
}

const AccountInfoEditModal: React.FC<IProps> = ({
    data,
    closeModal,
    refetch,
}) => {
    const { data: tagsData } = useTagsQuery({});
    const { data: teamsData } = useTeamsQuery({});

    const [theTeams, setTheTeams] = useState([]);
    const [theTags, setTheTags] = useState<any>([]);
    const [selectedTagIds, setSelectedTagIds] = useState<number[]>([]);
    const [selectedTeamIds, setSelectedTeamIds] = useState<number[]>([]);
    const [trigger] = useAccountsUpdateMutation();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [name, setName] = useState<string>(data?.account?.name);
    const [number, setNumber] = useState<string>(data.phone_number);
    const { data: dynamicData } = useAccountDynamicFieldQuery({});

    const [dynamicFields, setDynamicFields] = useState({});
    const [errors, setErrors] = useState({});
    const [dynamicFieldsData, setDynamicFieldsData] = useState<any[]>([])

    useEffect(() => {
        if (teamsData) {
            setTheTeams(teamsData);
            setSelectedTeamIds(
                data?.account?.accessible_teams?.map((team: any) => team.id) || []
            );
        }
    }, [teamsData, data?.account?.accessible_teams]);

    useEffect(() => {
        if (tagsData) {
            setTheTags(tagsData);
            setSelectedTagIds(data?.account?.tags?.map((tag: any) => tag.id) || []);
        }
    }, [tagsData, data?.account?.tags]);


    useEffect(() => {
        if (dynamicData) {
            setDynamicFieldsData(dynamicData)
        }

    }, [dynamicData])



    const toggleTagSelection = (tag: any) => {
        if (selectedTagIds.includes(tag.id)) {
            setSelectedTagIds(selectedTagIds.filter((id) => id !== tag.id));
        } else {
            setSelectedTagIds([...selectedTagIds, tag.id]);
        }
    };

    const toggleTeamSelection = (team: any) => {
        if (selectedTeamIds.includes(team.id)) {
            setSelectedTeamIds(selectedTeamIds.filter((id) => id !== team.id));
        } else {
            setSelectedTeamIds([...selectedTeamIds, team.id]);
        }
    };

    useEffect(() => {
        if (data) {
        
            setDynamicFields(data?.account?.dynamic_fields);
        }
    }, [data]);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const response = await trigger({
                id: data?.account?.id,
                name: name,
                dynamic_fields: {},
                tags: selectedTagIds,
                accessible_teams: selectedTeamIds,
            });

            if (response.data) {
                setIsLoading(false);
                closeModal();
                toast.success("Customer information updated successfully!");
                refetch();
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("Failed to update. Please try again.");
            console.error(error);
        }
    };

    const handleInputChange = (label: any, value: any) => {
        setErrors({});
        setDynamicFields((prevState) => ({
            ...prevState,
            [label]: value,
        }));
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="text-end">
                <CloseButton onClick={closeModal} />
            </div>
            <div className="text-center mb-4">
                <label className="form-label fs-4 fw-bolder text-dark card-title">
                    Accounts Update
                </label>
            </div>

            <div className="w-100 mb-4">
                <label>Business Name</label>
                <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    autoComplete="off"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            {dynamicFieldsData?.map((el: any, index) => {
    // Debugging: Check the structure of each element
   

    // Safeguard against undefined or missing 'label'
    if (!el || !el?.label) {
        console.warn(`Skipping element at index ${index} due to missing label or undefined element.`);
        return null;
    }

    const fieldLabel = el.label;

    // Ensure dynamicFields is defined and read value safely
    const fieldValue = dynamicFields && dynamicFields[fieldLabel] ? dynamicFields[fieldLabel] : '';

    // Debugging: Check if the value for the dynamic field is undefined
    if (dynamicFields && fieldValue === undefined) {
        console.warn(`Field value for label '${fieldLabel}' is undefined. Ensure it's initialized correctly.`);
    }

    return (
        <React.Fragment key={fieldLabel}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <label className={el?.is_required ? "required" : ""}>
                    {capitalizeFirstLetter(el?.field_type === "check-box" ? "" : el?.label)}
                </label>
                {el?.field_type === "drop-down" ? (
                    <select
                        className="form-control"
                        value={fieldValue} // Use safe value
                        onChange={(e) => handleInputChange(el.label, e.target.value)}
                    >
                        <option value="" disabled>
                            Select an option
                        </option>
                        {el?.choices?.map((choice: any) => (
                            <option key={choice?.id} value={choice?.choice}>
                                {choice?.choice}
                            </option>
                        ))}
                    </select>
                ) : el?.field_type === "check-box" ? (
                    <FormControl sx={{ width: "100%" }}>
                        <InputLabel id={`checkbox-label-${fieldLabel}`}>
                            {capitalizeFirstLetter(fieldLabel)}
                        </InputLabel>
                        <Select
                            labelId={`checkbox-label-${fieldLabel}`}
                            id={`checkbox-${fieldLabel}`}
                            multiple
                            value={fieldValue || []} // Ensure the value is always an array
                            onChange={(e) => handleInputChange(fieldLabel, e.target.value)}
                            input={<OutlinedInput label={capitalizeFirstLetter(fieldLabel)} />}
                            renderValue={(selected) => selected.join(", ")}
                        >
                            {el?.multiple_choices?.map((choice) => (
                                <MenuItem key={choice?.id} value={choice?.choice}>
                                    <Checkbox
                                        checked={fieldValue?.includes(choice?.choice) || false}
                                    />
                                    <ListItemText primary={choice?.choice} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : el?.field_type === "date-time" ? (
                    <TextField
                        type="datetime-local"
                        className="form-control"
                        value={fieldValue}
                        onChange={(e) => handleInputChange(fieldLabel, e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                ) : el?.field_type === "tel-phone" ? (
                    <div style={{ display: "flex", width: "100%" }}>
                        <PhoneInput
                            style={{ width: "100%" }}
                            defaultCountry="ua"
                            value={fieldValue}
                            onChange={(phone: string) => handleInputChange(fieldLabel, phone)}
                        />
                    </div>
                ) : el?.field_type === "date" ? (
                    <TextField
                        type="date"
                        className="form-control"
                        value={fieldValue}
                        onChange={(e) => handleInputChange(fieldLabel, e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                ) : (
                    <input
                        type={
                            el?.field_type === "date-time"
                                ? "datetime-local"
                                : el?.field_type === "email"
                                ? "email"
                                : el?.field_type === "number"
                                ? "number"
                                : el?.field_type === "date"
                                ? "date"
                                : el?.field_type === "time"
                                ? "time"
                                : "text"
                        }
                        placeholder={
                            el?.field_type === "email"
                                ? "Enter your email"
                                : el?.field_type === "number"
                                ? "Number"
                                : el?.field_type === "date-time"
                                ? "Select date and time"
                                : ""
                        }
                        className="form-control"
                        value={fieldValue}
                        onChange={(e) => handleInputChange(fieldLabel, e.target.value)}
                        onBlur={(e) => {
                            if (el?.field_type === "email") {
                                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                const emailValue = e.target.value.trim();

                                if (!emailPattern.test(emailValue)) {
                                    setErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [el?.label]: "Invalid email address",
                                    }));
                                } else {
                                    setErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [el?.label]: "",
                                    }));
                                }
                            }
                        }}
                    />
                )}
            </div>
            {errors[fieldLabel] && <div style={{ color: "red" }}>{errors[fieldLabel]}</div>}
        </React.Fragment>
    );
})}


            <div className="w-100 mb-4">
                <label>Tags:</label>
                <Grid container spacing={1}>
                    {theTags.map((tag: any) => (
                        <Grid item key={tag.id}>
                            <Chip
                                label={tag.name}
                                color={selectedTagIds.includes(tag.id) ? "primary" : "default"}
                                onClick={() => toggleTagSelection(tag)}
                                style={{ margin: "5px" }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>



            <div className="w-100 mb-4">
                <label>Teams:</label>
                <Grid container spacing={1}>
                    {theTeams.map((team: any) => (
                        <Grid item key={team.id}>
                            <Chip
                                label={team.name}
                                color={
                                    selectedTeamIds.includes(team.id) ? "primary" : "default"
                                }
                                onClick={() => toggleTeamSelection(team)}
                                style={{ margin: "5px" }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>

            <div className="text-end mt-3 mb-4">
                <button
                    type="submit"
                    className="btn btn-sm btn-primary"
                    disabled={isLoading}
                >
                    <span className="indicator-label">Save</span>
                </button>
            </div>
        </form>
    );
};

export default AccountInfoEditModal;
