import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  Select,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  useBulkMailAssigneMutation,
  useGetSelectedTeamUserQuery,
  useGetTeamFromQuery,
} from "../../../Services/email/EmailInbox";
import { toast } from "react-toastify";

interface IProps {
  closeModal: () => void;
  setectedEmail: any;
  refetchData: () => void; // Add this line
  setSelectedRows: any;
}

const AssigneeBulkTeam: React.FC<IProps> = ({
  closeModal,
  setectedEmail,
  refetchData,
  setSelectedRows,
}) => {
  const userToken = JSON.parse(localStorage.getItem("user-token") || "{}");
  const teamId = userToken.teams ? userToken.teams[0].id : null;
  const [selectedTeam, setSelectedTeam] = useState<string>("");
  const [addUser, setAddUser] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>("");

  const { data } = useGetTeamFromQuery({ teamId });
  const { data: selectedTeamUser } = useGetSelectedTeamUserQuery(selectedTeam, {
    skip: !selectedTeam,
  });

  const [addBulkAssigne] = useBulkMailAssigneMutation();

  useEffect(() => {
    if (selectedTeamUser) {
      setAddUser(true);
    }
  }, [selectedTeamUser]);

  const changeTeamHandler: any = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedTeam(event.target.value as string);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 120,
      },
    },
  };

  const assignHandler = () => {
    // Construct the payload
    const payload = {
      assignee_id: String(selectedUserId),
      team_assignee_id: String(selectedTeam),
      thread_ids: setectedEmail,
    };

    addBulkAssigne({ body: payload, setectedEmail })
      .then((res) => {
        toast.success(`Email successfully assigned`);
        refetchData(); // Call refetchData after successful assignment
        setSelectedRows([]);
        closeModal(); // Close the modal after successful assignment
      })
      .catch((err) => {
        console.error("Error assigning user:", err); // Handle errors if any
      });
  };

  return (
    <Modal
      open={true}
      onClose={closeModal}
      aria-labelledby="assign-team-modal-title"
      aria-describedby="assign-team-modal-description"
    >
      <Box
        sx={{
          padding: "1rem",
          backgroundColor: "white",
          margin: "auto",
          width: "400px",
          height: "300px",
          borderRadius: "8px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
          boxShadow: 24,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={closeModal}
            style={{ minWidth: "auto", padding: "0.5rem" }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
        <div>
          <div className="change-assignee-section">
            <div className="change-assignee-section--body mt-4">
              <InputLabel>Team</InputLabel>
              <FormControl variant="standard" className="full-width w-100 mt-4">
                <Select
                  value={selectedTeam}
                  onChange={changeTeamHandler}
                  displayEmpty
                  style={{ paddingLeft: "1rem" }}
                >
                  <MenuItem value="" disabled>
                    Select Teams
                  </MenuItem>
                  {data &&
                    data.length > 0 &&
                    data[0].team.map((team: { id: number; name: string }) => (
                      <MenuItem key={team.id} value={team.id}>
                        {team.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="mt-4">
            <Checkbox
              className="example-margin"
              checked={addUser}
              onChange={() => setAddUser(!addUser)}
              disabled={!selectedTeam}
            />
            Assign to user
          </div>
          {addUser && (
            <div>
              <div className="change-assignee-section">
                <div className="change-assignee-section--body mt-4">
                  <InputLabel>User</InputLabel>
                  <FormControl
                    variant="standard"
                    className="full-width w-100 mt-4"
                  >
                    <Select
                      value={selectedUserId}
                      onChange={(event) =>
                        setSelectedUserId(event.target.value as string)
                      }
                      displayEmpty
                      disabled={!selectedTeam}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="" disabled>
                        Select User
                      </MenuItem>
                      {selectedTeamUser?.map(
                        (user: { id: number; username: string }) => (
                          <MenuItem key={user.id} value={user.id}>
                            {user.username}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            position: "absolute",
            bottom: ".6rem",
            left: "50%",
            transform: "translateX(-50%)",
            width: "90%",
          }}
        >
          <Button
            className="btn btn-primary w-100 "
            onClick={assignHandler}
            disabled={!selectedTeam || (addUser && !selectedUserId)}
          >
            Assign
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AssigneeBulkTeam;
