import React, {
    useEffect,
    useState,
    useContext,
    createContext,
    ReactNode,
    PropsWithChildren,
    useRef,
} from "react";
import { WS_URL } from "../env";
import LeadEventModal from "./LeadEventModal";
import ReactModal from "react-modal";

// Define initial state for the WebSocket
const initialState = {
    socket: null as WebSocket | null,
    isSocketOpen: false,
    numOfTimesWebsocketDisconnected: 0,
    serverResponse: null as any,
    state: {
        enableUserInput: true,
        reconnectChat: false,
    },
};

// Create a context with default values
const defaultChatContext = {
    state: initialState,
    sendMessage: (message: any) => {},
};

const ChatContext = createContext<any>(defaultChatContext);

interface IProps {
    children?: ReactNode;
}

// WebSocketProvider component that will manage WebSocket connection
export const WebSocketProvider: React.FC<PropsWithChildren<IProps>> = ({
    children,
}) => {
    const WebSocket_URL = WS_URL || "";
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [isSocketOpen, setIsSocketOpen] = useState(false);
    const [isNotificationModalOpen, setIsNotificationModalOpen] =
        useState(false);
    const [
        numOfTimesWebsocketDisconnected,
        setNumOfTimesWebsocketDisconnected,
    ] = useState(0);
    const [serverResponse, setServerResponse] = useState<any>(null);
    const [leadEventData, setLeadEventData] = useState<any>(null);

    const [state, setState] = useState({
        enableUserInput: true,
        reconnectChat: false,
    });

    const makeUserOnlineHandlerRef = useRef<(arg0: unknown) => void>(
        (value: unknown) => {
            return;
        }
    );

    useEffect(() => {
        if (leadEventData) {
            setIsNotificationModalOpen(true);
        }
    }, [leadEventData]);

    const initWSResolveHandlerRef = useRef<any>(null);
    const initWSRejectHandlerRef = useRef<any>(null);
    const timerWorker = useRef<any>(null);
    const authToken = localStorage.getItem("auth-token");

    // Initialize WebSocket connection
    useEffect(() => {
        if (!authToken) {
            console.error(
                "Auth token not found. Cannot establish WebSocket connection."
            );
            return;
        }

        const initWS = () => {
            const promise = new Promise((resolve, reject) => {
                initWSResolveHandlerRef.current = resolve;
                initWSRejectHandlerRef.current = reject;
            });

            const ws = new WebSocket(WebSocket_URL);
            ws.onopen = () => onConnOpen(ws);
            ws.onmessage = (event) => onMessage(event);
            ws.onclose = () => onConnClose(ws);
            ws.onerror = (error) => onError(error);

            setSocket(ws);

            return promise;
        };

        if (authToken) {
            initWS();
        }

        return () => {
            if (socket) socket.close();
        };
    }, [authToken]);

    const onConnOpen = (ws: WebSocket) => {
        setIsSocketOpen(true);
        initWSResolveHandlerRef.current(true);

        // Send authentication token after the connection is open
        if (authToken && ws.readyState === WebSocket.OPEN) {
            ws.send(
                JSON.stringify({
                    token: authToken,
                })
            );
        }

        if (numOfTimesWebsocketDisconnected > 0) {
            console.warn("Attempt No: " + numOfTimesWebsocketDisconnected);
            setNumOfTimesWebsocketDisconnected(0);
        }
    };

    const onConnClose = (ws: WebSocket) => {
        setIsSocketOpen(false);
        console.log("WebSocket closed!");
        setState((prevState) => ({
            ...prevState,
            enableUserInput: false,
            reconnectChat: true,
        }));

        setNumOfTimesWebsocketDisconnected((prev) => prev + 1);
    };

    const onMessage = (event: MessageEvent) => {
        console.log("Message from server:", event.data);
        try {
            const response = JSON.parse(event.data);
            console.log("Parsed Response:", response);

            setServerResponse(response);
            if (response.notification_type === "auth-success") {
                makeUserOnlineHandlerRef.current(response);
                console.log("Successfully authenticated with WebSocket.");
            }
            if (Array.isArray(response)) {
                const firstEvent = response[0];
                if (firstEvent.notification_type === "lead-event") {
                    handleLeadEvent(firstEvent.data);
                }
            } else if (response.notification_type === "lead-event") {
                handleLeadEvent(response.data);
            } else if (response.type === "error") {
                console.error("Error received from server:", response.error);
            }
        } catch (error) {
            console.error("Error parsing WebSocket message:", error);
        }
    };

    const handleLeadEvent = (leadData: any) => {
        setLeadEventData(leadData);
        console.log("LEAD_EVENT-DATA:", leadData);

        // Automatically send acknowledgment or follow-up
        sendMessage({ type: "lead-event", id: leadData?.id });
    };

    const onError = (error: Event) => {
        console.error("WebSocket error:", error);
    };

    // Handle reconnection logic
    useEffect(() => {
        if (numOfTimesWebsocketDisconnected > 0) {
            console.log("Attempting to reconnect WebSocket...");
            setTimeout(() => {
                const ws = new WebSocket(WS_URL);
                ws.onopen = () => onConnOpen(ws);
                ws.onmessage = (event) => onMessage(event);
                ws.onclose = () => onConnClose(ws);
                ws.onerror = (error) => onError(error);
                setSocket(ws);
            }, 5000);
        }
    }, [numOfTimesWebsocketDisconnected]);

    const sendMessage = (message: any) => {
        if (socket && isSocketOpen) {
            // Automatically add auth_token to every message
            if (authToken) {
                message.auth_token = authToken;
            }
            socket.send(JSON.stringify(message));
            console.log("Message sent:", message);
        } else {
            console.log("WebSocket not open. Cannot send message.");
        }
    };

    const customModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            height: "350px",
        },
    };

    console.log(leadEventData, "leadEventData");
    return (
        <ChatContext.Provider
            value={{
                state: {
                    socket,
                    isSocketOpen,
                    numOfTimesWebsocketDisconnected,
                    serverResponse,
                    state,
                },
                sendMessage,
            }}
        >
            {children}
            {/* {leadEventData && ( */}
                <ReactModal
                    isOpen={isNotificationModalOpen}
                    onRequestClose={() => setIsNotificationModalOpen(false)}
                    style={customModalStyles}
                    contentLabel="New Contact"
                >
                    <LeadEventModal
                        leadEventData={leadEventData}
                        onClose={() => setIsNotificationModalOpen(false)}
                    />
                </ReactModal>
            {/* )} */}
        </ChatContext.Provider>
    );
};

// Create a custom hook to use the WebSocket context
export const useWebSocket = () => useContext(ChatContext);
