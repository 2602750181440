import ProgressBar from "../ticket/ProgressBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import TicketLeftSideMenuCard from "../../components/ticket/TicketLeftSideMenuCard";
import TicketRightSideMenu from "../../components/ticket/TicketRightSideMenu";
import TicketPaneOpen from "../../components/ticket/TicketPaneOpen";
import TicketListInfoCard from "../../components/ticket/TicketListInfoCard";
import { useDetailTicketQuery } from "../../Services/ticket/TicketDetail";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import CreateTaskModal from "../../components/ticket/ticketListInfo/CreateTaskModal";
import { useCreateTaskMutation } from "../../Services/ticket/CreateTask";
import { useTaskQuery } from "../../Services/ticket/Task";
import { useAuditTrailQuery, useGetForwardedAuditTrailCountQuery } from "../../Services/ticket/Audit-Trail";
import ClientLoginHeader from "./ClientLoginHeader";
import { toAbsoluteUrl } from "../../helpers";
import { useLayout } from "../../layout/core";
import CreateChildTicket from "../../components/ticket/CreateChildTicket";
import LoadingSpinner from "../../components/LoadingSpinner";

const ViewTicket = () => {
    const params = useParams();
    const uuid = params.uuid;
    const { data, refetch, isFetching, status } = useDetailTicketQuery(uuid);
    const [createTask] = useCreateTaskMutation({});
    const [isChildTicketModalOpen, setIsChildTicketModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const {
        data: taskData,
        refetch: refetchTask,
        isLoading,
    } = useTaskQuery(uuid);

    const { data: auditTrailData, refetch: refetchAuditTrailData } =
        useAuditTrailQuery(uuid);
    const { data: auditTrailForwardedData, refetch: refetchAuditTrailForwardedData } =
        useGetForwardedAuditTrailCountQuery(uuid);


    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [getAllStatus, setGetAllStatus] = useState<any>([]);
    const { config, classes, attributes } = useLayout();
    const [paneOpen, setPaneOpen] = useState<String>("LeadInformation");
    const navigate = useNavigate(); // Initialize useNavigate
    const customModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "550px",
        },
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const afterOpenModal = () => {
        // subtitle.style.color = '#f00'
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const customModalStyles1 = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "100%", // Default to full width
            maxWidth: "850px", // Max width for larger screens
            height: "80%",
            margin: "auto",
            Overflow: "hidden",
            OverflowY: "auto",
        },
    };



    useEffect(() => {
        if (status === "pending") {
            setLoading(true);

        } else {
            setLoading(false);
        }

    }, [status]);


    const openChildTicketModal = () => {
        setIsChildTicketModalOpen(true);
    }
    const afterOpenChildTicketModal = () => {
        // subtitle.style.color = '#f00'
    }
    const closeChildTicketModal = () => {
        setIsChildTicketModalOpen(false);
    }
    const handleBackButtonClick = () => {
        navigate(-1); // Navigate back to the previous page
    };



    return (
        <>
            <div
                // id="kt_header"
                className="header align-items-stretch justify-content-between d-none d-lg-flex"
                style={{ left: 0 }}
                {...attributes.headerMenu}
            >
                {/* begin::Wrapper */}
                <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                    {/* begin::Navbar */}
                    <div className="app-header-menu app-header-mobile-drawer align-items-stretch ms-2">
                        <Link to="/client-login">
                            <img
                                alt="Logo"
                                src={toAbsoluteUrl(
                                    "/media/logos/CircleOne-Logo-With-Change.png"
                                )}
                                className="h-60px"
                            />
                        </Link>
                    </div>
                </div>
            </div>
            <ClientLoginHeader />

            <div id="kt_app_content_container" className="app-container mx-5 " style={{ marginTop: "75px" }}>
                {/*begin::Products*/}
                <div className="card card-flush h-100">

                    {/*start:: The 2nd stack */}
                    <div className="card-header align-items-center py-1 gap-2 gap-md-5 mt-4">
                        <div className="d-flex">
                            <button className="btn btn-link mb-2" onClick={handleBackButtonClick}>
                            <i className="bi bi-arrow-90deg-left"     style={{
                                        fontSize: "20px",
                                        color: "black",
                                        cursor: "pointer",
                                    }}></i>
                            </button>
                        </div>
                            <h2 className="p-5">Ticket Informatiosn</h2>

                        {/* Create Ticket Button */}
                        <div className="card w-100 mw-125px">
                            <button className="btn btn-link" onClick={openModal} disabled>
                                <i className="bi bi-plus"></i>
                                Create Task
                            </button>
                        </div>
                        <ReactModal
                            isOpen={isModalOpen}
                            onAfterOpen={afterOpenModal}
                            onRequestClose={closeModal}
                            style={customModalStyles}
                            contentLabel="Create Task"
                        >
                            <CreateTaskModal
                                closeModal={closeModal}
                                ticket={data}
                                createTask={createTask}
                                refetchTask={refetchTask}
                            />
                        </ReactModal>
                    </div>
                    {/*end:: The 2nd stack */}

                    {/*start:: The 3rd stack */}
                    {isLoading ? <LoadingSpinner /> :
                        (
                            <>

                                <div className="container-fluid mt-5">

                                    <div className="row">
                                        <div className="col-xl-2 col-lg-4 col-md-12 mb-4">
                                            <TicketLeftSideMenuCard tickets={data} />
                                        </div>
                                        <div className="col-xl-8 col-lg-8 col-md-12 mb-4">
                                            <TicketListInfoCard
                                                tickets={data}
                                                refetch={refetch}
                                                refetchAuditTrailData={refetchAuditTrailData}
                                                setGetAllStatus={setGetAllStatus}
                                                refetchAuditTrailForwardedData={refetchAuditTrailForwardedData}
                                                openChildTicketModal={openChildTicketModal}
                                            />
                                            {/* end::Item */}

                                            <div className="mt-5">

                                                <div className="mt-2 ">
                                                    <TicketPaneOpen
                                                        tickets={data}
                                                        refetch={refetch}
                                                        taskData={taskData}
                                                        refetchTask={refetchTask}
                                                        isLoading={isLoading}
                                                        refetchAuditTrailForwardedData={refetchAuditTrailForwardedData}
                                                        paneOpen={paneOpen}
                                                        setPaneOpen={setPaneOpen}
                                                        setConfirmationModalOpen={''}
                                                        confirmationModalOpen={''}
                                                    handleButtonClick={''}


                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-4 col-md-12 mb-4">
                                            <TicketRightSideMenu auditTrailForwardedData={auditTrailForwardedData} setPaneOpen={setPaneOpen} />
                                        </div>
                                    </div>
                                </div>

                            </>
                        )

                    }
                    {/*ends:: The 4th stack */}
                </div>


                <ReactModal
                    isOpen={isChildTicketModalOpen}
                    onRequestClose={closeChildTicketModal}
                    style={customModalStyles1}
                    contentLabel="New Ticket"
                >
                    <CreateChildTicket
                        closeModal={closeChildTicketModal}
                        refetch={refetch}
                        uuid={uuid}

                    />
                </ReactModal>
            </div>
        </>
    );
};

export default ViewTicket
