import React, { useState } from "react";
import { useEditTagsMutation } from "../../../Services/customer/Customer";
import { toast } from "react-toastify";

interface IPROPS {
    closeModal: any;
    refetch: any;
    storedData: any;
}

const EditTags: React.FC<IPROPS> = ({ closeModal, refetch, storedData }) => {
    const [nameHandler, setNameHandler] = useState(storedData.name);
    const [errorMessage, setErrorMessage] = useState(false);

    const [trigger, {}] = useEditTagsMutation();

    const tagEditHandler = (e) => {
        setNameHandler(e.target.value);
        setErrorMessage(false);
    };

    const saveEditHandler = async (e) => {
        e.preventDefault();

        if (!nameHandler) {
            setErrorMessage(true);
            return;
        }

        const obj = {
            name: nameHandler,
            id: storedData?.id,
        };

        try {
            const response = await trigger({ body: obj, id: storedData.id });

            if (response.data) {
                toast.success("Successfully updated");
                closeModal(true);
                refetch();
            } else {
                toast.error("An error occurred");
            }
        } catch (error) {
            toast.error("An error occurred");
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                padding: "0",
            }}
        >
            <div style={{ display: "flex", alignItems: "center", width: "100%" , marginBottom: "10px"}}>
                <div style={{ width: "60%" }}>
                    <h3 style={{ display: "flex", justifyContent: "end" }}>Edit Tags</h3>
                </div>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                        flex: "1",
                    }}
                >
                    <i
                        style={{
                            fontSize: "26px",
                            color: "black",
                            cursor: "pointer",
                        }}
                        className="bi bi-x"
                        onClick={() => closeModal(true)}
                    ></i>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ fontSize: "15px" }}>Tags</label>
                <input
                    className="mx-2 form-control"
                    value={nameHandler}
                    onChange={tagEditHandler}
                />
            </div>
            {errorMessage && (
                <p style={{ margin: "0", color: "red" }}>Field is required</p>
            )}
            <div style={{ display: "flex", width: "100%" , marginTop: "20px"}}>
                <button
                    className="btn btn-primary"
                    style={{ width: "100%" }}
                    onClick={saveEditHandler}
                >
                    Save
                </button>
            </div>
        </div>
    );
};

export default EditTags;
