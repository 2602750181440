import { format } from 'date-fns';
import React from 'react'
import ReactApexChart from "react-apexcharts";


interface IPROPS {
    intervalData: any
}

const TicketVolumeChart: React.FC<IPROPS> = ({intervalData}) => {
    const results = intervalData?.results || [];

    const categories = Object.keys(results[0]?.hourly_count || {});
  
    const series = results.map((day) => ({
      name: format(new Date(day.date), "dd-MM-yyyy"),
      data: Object.values(day.hourly_count), 
    }));
  
    const options: any = {
      chart: {
        type: "line",
        height: 400,
        toolbar: {
          show: true,
          tools: {
            download: true,
          },
          export: {
            svg: {
              filename: "total_volume",
            },
            png: {
              filename: "total_volume",
            },
            csv: {
              filename: "total_volume",
            },
          },
        },
      },
      xaxis: {
        type: "category",
        categories: categories,
        title: {
          text: "Hour of Day",
          style : {
            fontWeight: "normal"

          }
        },
      },
      stroke: {
        curve: "smooth",
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
      },
      yaxis: {
        title: {
          text: "Volume Count",
          style: {
            fontWeight: "normal"

          }
        },
        labels: {
          formatter: function (val) {
            return Math.floor(val); // Ensure that only whole numbers are shown
          },
        },
        min: 0, // Ensure minimum is 
        forceNiceScale: true,
      },


      tooltip: {
        x: {
          show: true,
          formatter: function (val) {
            return `Hour: ${val}`;
          },
        },
      },

      colors: [
        "#FF5733", // Red-orange
        "#33FF57", // Green
        "#3357FF", // Blue
        "#F39C12", // Orange
        "#8E44AD", // Purple
        "#FFFF00", // Yellow
        "#3498DB", // Light Blue
      ],
    };
  
    return <ReactApexChart options={options} series={series} type="line" height={450} />
    
}

export default TicketVolumeChart