import React, { useState } from "react";
import {
  useSearchByEmailQuery,
  useUpdateContactsInfoDetailsMutation,
} from "../../../Services/customer/FrontendCustomer";
import "./styles.scss";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface IPROPS {
  // refetch: any;
  closeModal: any;
  existingContactData:any
  refetch:any
  // accountRefetch:any
}

const AddExistingContact: React.FC<IPROPS> = ({ closeModal, existingContactData, refetch }) => {
  const [email, setEmail] = useState<any>("");
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [getUserId, setGetUserId] = useState<any>("");

  const {id} = useParams()



  const { data } = useSearchByEmailQuery(email);
  const [trigger] = useUpdateContactsInfoDetailsMutation();
  const hasSuggestions = data && data.length > 0;

  const handleSuggestionClick = (suggestion: any) => {
    setGetUserId(suggestion?.id);
    setEmail(suggestion?.email);
    setShowSuggestions(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setShowSuggestions(true); // Show suggestions when typing
  };

  const addContactHandler = async (e) => {
    e.preventDefault();
    const obj = {
      account:id,
    };
    try {
      const response = await trigger({ body: obj, id: getUserId });
      if (response?.data) {
        toast.success("Added successfully");
        // accountRefetch()
        refetch();
        closeModal();
      } else if (response.error) {
        toast.error("An error occured");
      }
    } catch (error) {
      toast.error("An error occured");
    }
  };

  return (
    <div className="add-contact-container">
      <div><label><span >Account: </span><span style={{fontSize: "15px", fontWeight: "600", color: "black"}}>{existingContactData?.name}</span></label></div>
      <div className="input-wrapper">
        <input
          className="form-control"
          placeholder="Enter your email"
          onChange={handleInputChange}
          value={email}
        />
        {showSuggestions && hasSuggestions && (
          <ul className="suggestions-list">
            {data?.map((item, index) => (
              <li
                key={index}
                className="suggestion-item"
                onClick={() => handleSuggestionClick(item)}
              >
                {item.email}
              </li>
            ))}
          </ul>
        )}
      </div>
      <button className="btn btn-primary" onClick={addContactHandler}>
        Add Contact
      </button>
    </div>
  );
};

export default AddExistingContact;
