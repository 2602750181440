import React from "react";
import { useParams } from "react-router-dom";
import { useTaskQuery } from "../../../Services/ticket/Task";
import TaskActionMenu from "./TaskActionMenu";
import LoadingSpinner from "../../LoadingSpinner";

const Tasks = ({ taskData, refetchTask, isLoading }) => {
  return (
    <div className="table-responsive">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <table className="table table-row-bordered table-row-gray-300 text-muted align-middle gs-10">
          <thead className="table-light fw-bolder">
            <tr className="bg-primary">
              <th scope="col">Task Name</th>
              <th scope="col">Due Date</th>
              <th scope="col">Owner</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {taskData?.results?.map((task, index) => (
              <tr key={index}>
                <th scope="row">{task.title}</th>
                <td>{task.event_date}</td>
                <td>{task.guests.join(", ")}</td>{" "}
                {/* Join guests with commas */}
                <td>{task.ticket.status_type}</td>
                <td>
                  <TaskActionMenu task={task} index={index} refetchTask={refetchTask}/>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Tasks;
