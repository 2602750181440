import React from 'react'
import { CloseButton } from 'react-bootstrap'

interface IPROPS {
    handleButtonClick:any
    closeModal:any
}

const ConfirmationModal: React.FC<IPROPS> = ({handleButtonClick, closeModal}) => {


    
  return (
    <div style={{display: "flex", flexDirection: "column", gap: "40px"}}>
        <div style={{display: "flex", width: "100%"}}>
            <span style={{width: "80%", display: "flex", justifyContent: "end", fontSize:"18px"}}>Do you want to go to the next lead?</span>
        <span style={{flex: "1", display: "flex", justifyContent: "end"}}><CloseButton onClick={closeModal}/></span>
        </div>
        <div style={{display: "flex", gap: "10px", width: "100%", justifyContent: "end"}}>
            <button className='btn btn-secondary' onClick={() => closeModal()}>No</button>
            <button className='btn btn-primary' data-bs-toggle="tooltip" title="Next Lead" onClick={(event) => handleButtonClick(event,'next')}>Yes</button>
        
        </div>
    </div>
  )
}

export default ConfirmationModal