import React, { useEffect, useRef, useState } from "react";
import { CardContent } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../LoadingSpinner";
import { useGetAbandonedCallReportQuery, useGetAllQueueQuery } from "../../../../Services/reports/tickets/ReportTickets";
import { toast } from "react-toastify";
import CsvDownloader from "react-csv-downloader";

const AbandonedCallsReport = () => {
  const navigate = useNavigate();

  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const { data: queueData, isLoading: queueLoading } = useGetAllQueueQuery({});
  const [params, setParams] = useState<any>(null);
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [localLoading, setLocalLoading] = useState(false);
  const [allQueue, setAllQueue] = useState<any>([])

  const [selectQueue, setSelectQueue] = useState<any>('')

  const {
    data: abandonedCallsReport,
    isLoading: abandonedCallsReportLoading,
    error: abandonedCallsReportError
  } = useGetAbandonedCallReportQuery(params, {
    skip: !params,
  });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });


  const columns: GridColDef[] = [
    {
      field: "sr.no",
      headerName: "Sr.No",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "date_connection",
      headerName: "Date of Call Connection",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "time_connection",
      headerName: "Time of Call Connection",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "caller_no",
      headerName: "Caller No",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "queue_name",
      headerName: "Queue Name",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ringing_duration",
      headerName: "Ringing Duration",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "abandoned_time",
      headerName: "Abandoned Time",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];



  function formatDate(datetime) {
    if (!datetime) return "No data found";
    const dateObj = new Date(datetime);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = String(dateObj.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  }

  function formatTime(datetime) {
    if (!datetime) return "No data found";
    const dateObj = new Date(datetime);
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }


  const handleDateFromChange = (event: any) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event: any) => {
    setDateTo(event.target.value);
  };


  const handleQueueChange = (event: any) => {
    setSelectQueue(event.target.value);
  };


  useEffect(() => {
    const dropdownHeadings = document.querySelectorAll(".dropdown-heading");
    dropdownHeadings.forEach((heading: any) => {
      heading.style.height = "50px";
    });
  }, []);
  useEffect(() => {
    if (abandonedCallsReportError) {
      setDataRows([]);
      setLocalLoading(false);
    }
  }, [abandonedCallsReportError])

  useEffect(() => {
    const transformedData = queueData?.map((queue) => ({
      id: queue.id,
      name: queue.name,
    }));
    if (queueData && Array.isArray(queueData)) {
      setAllQueue(transformedData)
    }
  }, [queueData]);

  const handleSubmit = () => {
    if (!dateFrom) {
      toast.error('Please select "From Date"');
      return;
    }
    if (!dateTo) {
      toast.error('Please select "To Date"');
      return;
    }


    const queryParams: any = {
      gte: dateFrom,
      lte: dateTo,
    };


    if (selectQueue !== 'All') {
      const selectedQueue = allQueue.find((queue: any) => queue.id === selectQueue);
      queryParams.queuename = selectedQueue?.name || selectQueue; // Use name if found, otherwise fallback to ID
    }
    setParams(queryParams);
  };
  useEffect(() => {
    if (abandonedCallsReport === undefined) {
      setLocalLoading(false);
    } else if (Array.isArray(abandonedCallsReport)) {
      const transformedRows = abandonedCallsReport.map((item, index) => {
        return {
          id: index + 1,
          "sr.no": index + 1, // This can be dynamic, or you can calculate it based on your data
          "date_connection": formatDate(item.time) || 'no data found', // Map your fields here,
          "time_connection": formatTime(item.time) || 'no data found', // Map your fields here
          "caller_no": item.customer_no,
          "queue_name": item.queuename,
          "ringing_duration": item.ringing_duration,
          "abandoned_time": item.abandoned_time,
        };
      });

      setDataRows(transformedRows);
    } else {
      setDataRows([]); // Reset if data is not an array
    }

    setLocalLoading(false);
  }, [abandonedCallsReport]);





  const downloaderRef = useRef(null);


  const resetFilters = () => {
    setDateFrom("");
    setDateTo("");
    setParams(null);

  };

  const ITEM_HEIGHT = 60;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };


  const handlePaginationChange = (newPaginationModel: any) => {
    setLocalLoading(true);
    setPaginationModel(newPaginationModel);
    setTimeout(() => {
      setLocalLoading(false);
    }, 1000);
  };


  useEffect(() => {
    if (params) {
      setLocalLoading(true);
    }
  }, [params]);

  console.log(allQueue)



  return (
    <div id="kt_app_content_container" className="app-container mx-5">
      <div className="card card-flush">
        {/* Card Header */}
        <div
          className="card-header align-items-center py-1 gap-2 gap-md-5"
          style={{ justifyContent: "flex-start" }}
        >
          {/* Back button */}
          <img
            src="https://insurance.circleonecrm.com/assets/arrow_back.svg"
            alt="Back"
            style={{ marginBottom: "-2px", cursor: "pointer" }}
            className="ng-star-inserted"
            onClick={() => {
              navigate("/reports/telephony/reports-list");
            }}
          />
          <span className="fs-3 text-gray-800 fw-bold ms-3">
            Abandoned Calls{" "}
          </span>
        </div>

        {/* Toolbar */}
        <div
          className="card-toolbar flex-row-fluid justify-content-start gap-5 d-flex mb-10"
          style={{ marginLeft: "30px" }}
        >
          <div className="w-100 mw-150px">
            <TextField
              id="date-from"
              label="From"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  max: new Date().toISOString().split("T")[0], // Restrict to today or earlier
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateFrom}
              onChange={handleDateFromChange}
            />
          </div>
          <div className="w-100 mw-150px">
            <TextField
              id="date-to"
              label="To"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  max: new Date().toISOString().split("T")[0], // Restrict to today or earlier
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateTo}
              onChange={handleDateToChange}
            />
          </div>




          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="Queue-select-label">Queue</InputLabel>
              <Select
                labelId="Queue-select-label"
                id="Queue-select"
                label="Queue"
                value={selectQueue}
                onChange={handleQueueChange}
                MenuProps={MenuProps}
              >
                {" "}
                <MenuItem key="all" value="All">
                  All
                </MenuItem>
                {allQueue?.map((queue: any) => (

                  <MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>



          <div className="ms-auto d-flex gap-3" style={{ marginRight: "20px" }}>
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>


            {/* CsvDownloader component outside the button */}
            <button className="btn btn-primary">
              <CsvDownloader
                ref={downloaderRef}
                filename={`abandoned-calls-report`}
                datas={dataRows}
                columns={columns.map((col) => ({
                  id: col.field,
                  displayName: col.headerName,
                }))}
                separator=","
                wrapColumnChar={`"`}

              >Download</CsvDownloader>
            </button>

            <button className="btn btn-link" onClick={resetFilters}>
              <i className="bi bi-funnel fs-2 text-dark"></i>
            </button>
          </div>
        </div>



        <CardContent style={{ width: "100%" }}>
          {abandonedCallsReportLoading || localLoading || queueLoading ? (
            <LoadingSpinner />
          ) : (
            <DataGrid
              rows={dataRows}
              columns={
                columns
              }
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              loading={localLoading}
              disableColumnMenu
              pageSizeOptions={[5, 10, 15, 20]}
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "lightgray",
                  "&:last-child": {
                    justifyContent: "flex-end",
                  },
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-columnsContainer": {
                  backgroundColor: "lightgray",
                },
                // Target the pagination select and actions by stable component classes
                "& .MuiTablePagination-select, .MuiTablePagination-actions": {
                  marginBottom: "10px",
                },
                // Target the DataGrid overlay wrapper with its stable class
                "& .MuiDataGrid-overlayWrapper": {
                  height: "50px",
                },
              }}
            />
          )}
        </CardContent>
      </div>
    </div>
  );
};

export default AbandonedCallsReport;
