import React from "react";

interface IProps {
  handleBackToList: any;
  selectedDescriptionTemplate: any;
  setSelectedTemplateDescription: any;
  closeModal: any;
  setTemplateAttachment: any;
  allAttachment: any;
}

const SelectedEmailTemplate: React.FC<IProps> = ({
  handleBackToList,
  selectedDescriptionTemplate,
  closeModal,
  setSelectedTemplateDescription,
  setTemplateAttachment,
  allAttachment,
}) => {
  const usetemplateHandler = (selectedDescriptionTemplate: any) => {
    closeModal(true);
    setSelectedTemplateDescription(selectedDescriptionTemplate);
    setTemplateAttachment(allAttachment);
  };

  return (
    <div className="">
      <div className="card-header bg-primary">
        <div className="d-flex align-items-center">
          <span role="button" onClick={handleBackToList}>
            <i className="bi bi-arrow-left  text-white fs-1"></i>
          </span>
        </div>
      </div>
      <div className="card-body mb-2" style={{ backgroundColor: "#f5f5f5" }}>
        <div
          className="d-flex flex-column align-items-center mb-2 p-2"
          style={{ backgroundColor: "#fff", border: "1px solid #c8c8c8" }}
        >
          <h5 className="card-title text-center">
            {selectedDescriptionTemplate.subject}
          </h5>
          <div
            className="card-text mt-5"
            dangerouslySetInnerHTML={{
              __html: selectedDescriptionTemplate.content,
            }}
          />
        </div>
      </div>

      <div className="d-flex align-items-end justify-content-end mb-2">
        <button
          className="btn btn-primary fs-bold px-6"
          onClick={() => usetemplateHandler(selectedDescriptionTemplate)}
        >
          Use Template
        </button>
      </div>
    </div>
  );
};

export default SelectedEmailTemplate;
