import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import {
    AUTH_TOKEN,
    CALL,
    CALL_BREAK_PAUSE_ID,
    CALL_BREAK_REMARK,
    CALL_BREAK_USER_ID,
    CALL_HISTORY_ID,
    CALLBREAKID,
    EXTENSION,
    INCOMING_CALL,
    OUTGOING_CALL,
    QUEUE,
    TELEPHONEURL,
    TELEPHONY_LOGIN,
} from "../../constants";
import { usePauseCodeDataQuery } from "../../Services/Telephony/Telephony";
import {
    useCallBreakMutation,
    useCallBreakWithoutIdMutation,
    useCallExtensionStateQuery,
    useCallHistoryMutation,
    useCustomerContactNumberQuery,
    useExtensionStateMutation,
    useInboundCallQuery,
    // useTelephonyExtensionQuery,
    useTelephonyPRIQuery,
    useTelephonyServerQuery,
} from "../../Services/Telephony/TelephonyFrontend";
import TelephonyTimer from "./TelephonyTimer";
import DialPad from "./DialPad";
import CallDisconnect from "./CallDisconnect";
import PauseCall from "./PauseCall";
import "./styles.scss";
import TransferCall from "./TransferCall";
import Conference from "./Conference";
import ThirdPartCallDisconnect from "./ThirdPartCallDisconnect";
import { useTelephony } from "../context/TelephonyContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "react-bootstrap-typeahead";
import ReactModal from "react-modal";
import CustomerInfo from "./CustomerInfo";
import { Box, Modal } from "@mui/material";

const TelephonyFrontend = () => {
    const [telephonyPriData, setTelephonyPriData] = useState([]);
    const [call, setCall] = useState(() => {
        const storedCall = localStorage.getItem(CALL);
        return storedCall === "true"; // Convert string to boolean
    });

    const [isCallPaused, setIsCallPaused] = useState<boolean>(false);
    const [getpauseCode, setGetPauseCode] = useState([]);
    const [queueus, setQueueus] = useState([]);
    const [unPauseResponse, setUnPauseResponse] = useState("");
    const [inbound, setInbound] = useState([]);
    const [pauseCodeValue, setPauseCodeValue] = useState("");
    const [openDialPad, setOpenDialpad] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [pri, setPri] = useState("");
    const [callHistoryResponsee, setCallHistoryResponsee] = useState<any>({});
    const [isCallDisconnected, setIsCallDisconnected] = useState(false);
    const [isTransferCallActive, setIsTransferCallActive] =
        useState<boolean>(false);
    const [isConferenceCallActive, setIsConferenceCallActive] =
        useState<boolean>(false);
    const [conferenceNumber, setConferenceNumber] = useState("");
    const [isCallPause, setIsCallPause] = useState(false);
    const [conferenceCallApiCalled, setConferenceCallApiCalled] =
        useState(false);
        const [isPauseCodeSelectedManual, setIsPauseCodeSelectedManual] = useState(false)
        const [checkingExtensionState, setCheckingExtensionState] = useState('')

    const [storingCustomerData, setStoringCustomerData] = useState<any>([]);

    const [gettingCallResumed, setGettingCallResumed] = useState(false);
    const [isCustomerHistoryModalOpen, setIsCustomerHistoryModalOpen] =
        useState(false);


        console.log(isCustomerHistoryModalOpen, "isisisisi")
    const gettingExtension = localStorage.getItem(EXTENSION);
    const gettingTelephonyData = localStorage.getItem(TELEPHONEURL);
    const getingTelephonyLoginStatus = localStorage.getItem(TELEPHONY_LOGIN);

    const callBreakId = localStorage.getItem(CALLBREAKID);
    const callBreakRemark = localStorage.getItem(CALL_BREAK_REMARK);
    const {
        callDisconnected,
        setLifitinMobileNumber,
        liftingMobileNumber,
        setCallDisconnected,
        setCallActive,
        callActive,
        setIsLoading,
        setTelephonyContactLoading,
        storedTelephonyLoginDetails,
        setStoredIncomingCallContactData,
        setInboundCount,
        disconnectCallLoader,
    } = useTelephony();

    const navigate = useNavigate();



    const gettingUserId = localStorage.getItem("user_Id")

    console.log(callDisconnected, "calllll")
    useEffect(() => {
        if (callDisconnected) {
            setLifitinMobileNumber("");
            setConferenceNumber("");
            setIsCustomerHistoryModalOpen(false)
        }
    }, [callDisconnected]);

    const [
        extensionStateTrigger,
        { data: extensionStateData, status: extensionStateStatus },
    ] = useExtensionStateMutation();
    const {
        data: inboundCallData,
        status: inboundCallStatus,
        isLoading: inboundCallLoading,
        refetch: inboundCallRefetch,
    } = useInboundCallQuery(gettingExtension, {
        skip: !gettingExtension || !isCallPaused,
    });
    const [callBreakTrigger, { data: callBreakData, status: callBreakStatus }] =
        useCallBreakMutation();
    const [
        callBreakWithoutIdTrigger,
        { data: callBreakWithoutIdData, status: callBreakWithoutIdStatus },
    ] = useCallBreakWithoutIdMutation();

    // useEffect(() => {
    //     const obj = {
    //         break_remark: pauseCodeValue,
    //         user_id: +getUserId,
    //     }
        
    //     callBreakWithoutIdTrigger(obj)
    // }, [callDisconnected])

    const telephoneData = JSON.parse(gettingTelephonyData);
    const gettingQueues = localStorage.getItem(QUEUE);

    useEffect(() => {
        if (callBreakRemark) {
            setPauseCodeValue(callBreakRemark);
        }
    }, [callBreakRemark]);

    useEffect(() => {
        if (gettingQueues) {
            // Split the comma-separated string into an array
            const queuesArray = gettingQueues.split(",");
            setQueueus(queuesArray);
        }
    }, [gettingQueues]);


    useEffect(() => {
        if (inboundCallData) {
            setInbound(inboundCallData?.results);
            setInboundCount(inboundCallData?.count);
        }
    }, [inboundCallData]);

    const {
        data: telephonyPri,
        status: telephonyPriStatus,
        isLoading: telephonyPriLoading,
    } = useTelephonyPRIQuery({});
    const {
        data: callData,
        status: callStatus,
        isLoading: callLoading,
    } = useTelephonyServerQuery({}, { skip: !call || !gettingTelephonyData });

    const {
        data: pauseCodeData,
        status: pauseCodeStatus,
        isLoading: pauseCodeLoading,
        // refetch: pauseCodeRefetch,
    } = usePauseCodeDataQuery({});

    const { data: callExtemsionData, refetch: callExtensionRefetch } =
        useCallExtensionStateQuery(gettingExtension, {
            skip: !call || !gettingExtension,
        });

        useEffect(() => {
            setCheckingExtensionState(callExtemsionData?.state)

        }, [callExtemsionData?.state])

    const getUserId = localStorage.getItem(CALL_BREAK_USER_ID);

    const fetchingTenant = window.location.hostname.split(".")[0];
    const apiPort = process.env.REACT_APP_API_PROTOCOL;

    const [callHistoryTrigger, { isLoading: callHistoryStatus }] =
        useCallHistoryMutation();

    // useEffect(() => {
    //     if (callHistoryStatus === "pending") {
    //         setIsLoading(true)
    //     }
    //     else if (callHistoryStatus === "fulfilled")  {
    //         setIsLoading(false)
    //     }

    // }, [callHistoryStatus])

    const location = useLocation();

    useEffect(() => {
        if (!call) {
            setLifitinMobileNumber("");
        }
    }, [location.pathname, call]);


    const callHistoryObj = {
        call_type: "inbound",
        called_from_phone_no: inbound[0]?.did_no,
        campaign_ivr_msg: inbound[0]?.queue_name,
        campaign_name: inbound[0]?.queue_name,
        customer_phone_no: inbound[0]?.customer_phone,
        extension_no: 7026,
        inbound_unique_id: inbound[0]?.unique_id,
        language: "",
        user_id: +gettingUserId,
        // user_id: storedTelephonyLoginDetails?.user_id,

    };

    const outgoingCall = localStorage.getItem("telephony_outgoing")
   const incoming =  localStorage.getItem(INCOMING_CALL) === "true"

    useEffect(() => {
        if (outgoingCall === "true" || incoming) {
            const timer = setInterval(() => {
                callExtensionRefetch();
            }, 3000);
            setCallDisconnected(false)
            return () => clearInterval(timer);
        }
    }, [call, callExtensionRefetch, gettingExtension, outgoingCall, incoming, setCallDisconnected]);

    // Add Extension state API here as well with "busy" state

    useEffect(() => {
        const fetchCallHistory = async () => {
            if (inbound[0]) {
                localStorage.setItem(INCOMING_CALL, "true");
                try {
                    setCall(true);
                    localStorage.setItem(CALL, "true");

                    setLifitinMobileNumber(inbound[0]?.customer_phone);

                    localStorage.setItem(
                        "liftingMobileNumber",
                        inbound[0]?.customer_phone
                    );
                    setStoredIncomingCallContactData(inbound[0]?.contacts);

                    const response = await callHistoryTrigger(callHistoryObj);

                    localStorage.setItem("IncomingCallId", response?.data?.id);

                    if (response?.data?.id) {
                        const extensionObj = {
                            extension: gettingExtension,
                            state: "busy",
                        };
                         await extensionStateTrigger(extensionObj);

                    }

                    setIsCustomerHistoryModalOpen(true);
                } catch (error) {
                    console.error("Error fetching call history:", error);
                }
            }
        };

        fetchCallHistory();
    }, [inbound]);

    const closeCustomerHistoryModal = () => {
        setIsCustomerHistoryModalOpen(false);
    };


    useEffect(() => {
        if (gettingExtension && isCallPaused) {
            const timer = setInterval(() => {
                if (!inbound[0]?.id) {
                    inboundCallRefetch();
                } else {
                    clearInterval(timer); 
                }
            }, 5000);

            return () => clearInterval(timer);
        }
    }, [gettingExtension, inboundCallRefetch, isCallPaused, inbound]);


    var interactionId =
        new Date().getFullYear().toString() +
        "" +
        "0" +
        (new Date().getMonth() + 1).toString().slice(-2) +
        "" +
        new Date().getDate().toString() +
        "" +
        new Date().getHours() +
        "" +
        new Date().getMinutes() +
        "" +
        new Date().getSeconds();

    useEffect(() => {
        if (pauseCodeData) {
            setGetPauseCode(pauseCodeData);
        }
    }, [pauseCodeData]);

    useEffect(() => {
        if (telephonyPri) {
            setTelephonyPriData(telephonyPri?.results);
        }
    }, [telephonyPri]);

    useEffect(() => {
        // Retrieve the saved number from localStorage
        const savedNumber = localStorage.getItem("liftingMobileNumber");
        if (savedNumber) {
            setLifitinMobileNumber(savedNumber);
        }
    }, []);

    const handleMobileNumberChange = () => {
        if (!isClosing) {
            setOpenDialpad(true);
        }
    };

    const closeModal = () => {
        setIsClosing(true); // Set the closing flag
        setOpenDialpad(false);

        // Allow time for the modal to fully close before resetting the flag
        setTimeout(() => {
            setIsClosing(false);
        }, 300);
    };

    useEffect(() => {
        const callStatuss = localStorage.setItem(CALL, call.toString());
    }, [call]);

    useEffect(() => {
        if (storingCustomerData?.length === 1) {
            navigate(`/account/info/${storingCustomerData[0]?.id}`, {
                state: gettingObjectData[0],
            });
        } else if (storingCustomerData && storingCustomerData?.length > 1) {
            navigate("/contact/customer/contact");
        }
    }, [storingCustomerData]);
    

    console.log(storingCustomerData, "storingngn")

    const telephonyLoginStatus = localStorage.getItem(TELEPHONY_LOGIN);

    const gettingObjectData = storingCustomerData?.map((el) => ({
        id: el?.id,
        name: el?.name,
        email: el?.email,
        phone_number: el?.phone_number,
        account: el?.account,

    }));


    const callhandler = async () => {
        if (telephonyLoginStatus !== "true") {
            toast.error("Please login to telephony");
            return;
        }
        console.log(isPauseCodeSelectedManual, "dcsdccscsc")

        if (callActive) {
            toast.warning("Call is already active");
            return;
        }

        if (!liftingMobileNumber) {
            toast.error("Mobile number is required")
            return 

        }

        if (liftingMobileNumber.length != 10) {
            toast.error("Mobile number is incorrect");
            return;
        }

        if (!isPauseCodeSelectedManual && isCallPaused !== true) {
            toast.warning("Please resume the call")
            return 

        }

        // if () {

        // }

        try {
            // Step 1: Validate `pauseCodeValue`
            if (pauseCodeValue !== "Manual Dial") {
                toast.warning(
                    `Please select "Manual dial" to do an outbound call`
                );
                return;
            }

            if (!gettingCallResumed || !liftingMobileNumber) {
                toast.warning(
                    !gettingCallResumed
                        ? "Resume the call"
                        : "Mobile number is required"
                );
                return;
            }

            setCall(true);
            localStorage.setItem(CALL, "true");

            // Step 2: Set call state to indicate progress

            // Step 3: Trigger the click-to-call API
            const callResponse = await axios.post(
                `https://${telephoneData?.domain}/click2call/calls.php?CustPhone=91${liftingMobileNumber}&AgentExten=${gettingExtension}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&CallerID=${pri}&interactionid=${interactionId}`
            );

            // Step 4: Fetch customer contact information
            setTelephonyContactLoading(true);

            const gettingUserDataResponse = await axios.get(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/customers/contacts/?phone_number__endswith=${liftingMobileNumber}&`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            AUTH_TOKEN
                        )}`,
                    },
                }
            );
            setTelephonyContactLoading(false);

            if (gettingUserDataResponse) {
                setStoringCustomerData(gettingUserDataResponse?.data);
            }

            // Step 5: Update extension state

            // const extensionObj = {
            //     extension: gettingExtension,
            //     state: "busy",
            // };
            // await extensionStateTrigger(extensionObj);

            // Step 6: Create a call history entry
            const callHistoryObj = {
                call_type: "outbound",
                called_from_phone_no: liftingMobileNumber,
                campaign_name: pauseCodeValue,
                customer_phone_no: liftingMobileNumber,
                extension_no: gettingExtension,
                outbound_account_code: interactionId,
                user_id: +getUserId,
            };
            const callHistoryResponse = await callHistoryTrigger(
                callHistoryObj
            );

            setCallHistoryResponsee(callHistoryResponse?.data);
            localStorage.setItem(
                CALL_HISTORY_ID,
                callHistoryResponse?.data?.id
            );

            //    if ("data" in callHistoryResponse?.error) {
            //     const errorData = callHistoryResponse?.error?.data as {call_type: string[]}
            //     const errorMessage = errorData?.call_type?.[0]
            //     toast.error(errorMessage)
            //     return
            //    }

            const extensionObj = {
                extension: gettingExtension,
                state: "busy",
            };
            await extensionStateTrigger(extensionObj);

            localStorage.setItem(OUTGOING_CALL, "true");
            closeModal();

            setCallDisconnected(false);
            setCallActive(true);
            setIsLoading(true);
            setIsLoading(false);
            toast.success(`Placing call to ${liftingMobileNumber}`);
        } catch (error) {
            setIsLoading(false);

            console.error("Error in callhandler:", error);
            toast.error(
                "An error occurred while making the call. Please try again."
            );
        }
    };

    // PAUSE HANDLER

    const pauseHandler = async () => {
   
        setPauseCodeValue("");

        if ( localStorage.getItem(OUTGOING_CALL) === "true"  || localStorage.getItem(INCOMING_CALL) === "true") {
            toast.warning("Cannot change on the live call")
            return 
           
        }
        setIsCallPaused(true);
        const unpauseBatch = async (batch) => {
            const batchPromise = batch?.map(async (queue) => {
                try {
                    const sanitizedQueue = queue.replace(/^"(.*)"$/, "$1");

                    const response = await axios.post(
                        `https://${
                            telephoneData?.domain
                        }/click2call/acd.php?AgentExten=${+gettingExtension}&Operation=unpause&Queue=${sanitizedQueue}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&PauseCode=${pauseCodeValue}&break_id=${callBreakId}`,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem(
                                    AUTH_TOKEN
                                )}`,
                            },
                        }
                    );

                    setUnPauseResponse(response?.data);
                    setGettingCallResumed(false);

                } catch (error) {
                    console.log(error);
                }
            });

            // Wait for all APIs in this batch to complete
            await Promise.all(batchPromise);

            if (inbound) {
                const extensionObj = {
                    extension: +gettingExtension,
                    state: "unpause",
                };
                const response = await extensionStateTrigger(extensionObj);

                if (response?.data) {
                    const callBreakObj = {
                        action: "resume",
                    };
                    const callBreakResponse = await callBreakTrigger({
                        body: callBreakObj,
                        id: callBreakId,
                    });
                    localStorage.setItem(
                        CALL_BREAK_USER_ID,
                        callBreakResponse?.data?.user_id
                    );
                }
            }
        };
        await unpauseBatch(queueus);
        // await unpauseBatch(queueNamesData, "add");
    };

    // RESUME HANDLER

    console.log(isCallPaused, "ispappdpdpdp")


    const resumeHandler = async () => {

        if (!pauseCodeValue) {
            toast.error("Select break value");
            return;
        }

        setIsCallPaused(false);

        // const getUserId = localStorage.getItem(CALL_BREAK_USER_ID);
        const pauseBatch = async (batch) => {
            const batchPromise = batch?.map(async (queue) => {
                try {
                    const sanitizedQueue = queue.replace(/^"(.*)"$/, "$1");
                    const response = await axios.post(
                        `https://${
                            telephoneData?.domain
                        }/click2call/acd.php?AgentExten=${+gettingExtension}&Operation=pause&Queue=${sanitizedQueue}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&PauseCode=${pauseCodeValue}&break_id=${callBreakId}`,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem(
                                    AUTH_TOKEN
                                )}`,
                            },
                        }
                    );

                    setUnPauseResponse(response?.data);
                } catch (error) {
                    console.log(error);
                }
            });

            await Promise.all(batchPromise);

            if (inbound) {
                const callBreakObj = {
                    break_remark: pauseCodeValue,
                    user_id: +getUserId,
                };
                const callBreakResponse = await callBreakWithoutIdTrigger(
                    callBreakObj
                );

                if (callBreakResponse?.data) {
                    localStorage.setItem(
                        CALLBREAKID,
                        callBreakResponse?.data?.id
                    );

                    if (pauseCodeValue === "Manual Dial") {
                        setIsPauseCodeSelectedManual(true)

                        toast.success(
                            "Status changed to Manual dial successfully"
                        );
                    }

                    const extensionObj = {
                        extension: gettingExtension,
                        state: "pause",
                    };
                    const response = await extensionStateTrigger(extensionObj);

                    if (response?.data) {
                        setGettingCallResumed(true);
                    }
                }
            }
        };
        await pauseBatch(queueus);
    };

    const pauseCodeChangeHandler = (e) => {
        setPauseCodeValue(e.target.value);
    };

    const priHandler = (e) => {
        setPri(e.target.value);
    };


    const conferenceNumberHandler = (e) => {
        setConferenceNumber(e.target.value);
    };

    const [selectedControl, setSelectedControl] = useState(null);

    const handleSelection = (control) => {
        setSelectedControl(control);
    };

    const isSelected = (control) => selectedControl === control;

    const [thirdPartyCallDisconnected, setThirdPartyCallDisconnected] =
        useState(false);
    const [checkTransferCallNumberEntered, setCheckTransferCallNumberEntered] =
        useState(false);
    const [
        checkConferenceCallNumberEntered,
        setCheckConferenceCallNumberEntered,
    ] = useState(false);

    const customModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "450px",
        },
    };

    const muiModalStyles = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: 2, // Optional for rounded corners
      };


    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
            }}
        >
            {/* <button onClick={() => setIsCustomerHistoryModalOpen(true)}>sdcsdc</button> */}
            <div
                style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                        <TelephonyTimer
                            callPaused={isCallPaused}
                            call={call}
                            isCallPause={isCallPause}
                        />
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                   
                            <>
                                {!isConferenceCallActive &&
                                !isTransferCallActive ? (
                                    <input
                                        className="form-control"
                                        placeholder="Mobile No."
                                        onFocus={handleMobileNumberChange}
                                        onChange={handleMobileNumberChange}
                                        value={liftingMobileNumber}
                                        type="text"
                                        disabled={call}
                                    />
                                ) : isConferenceCallActive ? (
                                    <input
                                        className="form-control"
                                        placeholder="Conference No."
                                        onChange={conferenceNumberHandler}
                                        value={conferenceNumber}
                                        type="text"
                                    />
                                ) : null}
                            </>
                 

                        {isTransferCallActive &&
                            !checkTransferCallNumberEntered && (
                                <input
                                    className="form-control"
                                    placeholder="Conference No."
                                    onChange={conferenceNumberHandler}
                                    value={conferenceNumber}
                                    type="number"
                                />
                            )}
                        {/* </>

                    } */}

                        {!call && (
                            <select
                                className="form-select"
                                onChange={priHandler}
                                value={pri}
                                disabled={callActive}
                            >
                                <option value="">Select PRI</option>
                                {telephonyPriData?.map((el) => (
                                    <option value={el?.phone_no}>
                                        {el?.name}
                                    </option>
                                ))}
                            </select>
                        )}
                    </div>
                    

                    {openDialPad && (
                        <DialPad
                            open={openDialPad}
                            setLifitinMobileNumber={(number) => {
                                setLifitinMobileNumber(number);
                                localStorage.setItem(
                                    "liftingMobileNumber",
                                    number
                                );
                            }}
                            onClose={closeModal}
                            callhandler={callhandler}
                            liftingMobileNumber={liftingMobileNumber}
                        />
                    )}
                    {call ? (
                        <div style={{ display: "flex", gap: "10px" }}>
                            {!checkConferenceCallNumberEntered ? (
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "15px",
                                        alignItems: "center",
                                        marginRight: "50px",
                                    }}
                                >
                                    <CallDisconnect
                                        setIsCallDisconnected={
                                            setIsCallDisconnected
                                        }
                                        checkingExtensionState={checkingExtensionState}
                                    setInbound={setInbound}

                                        isCallDisconnected={isCallDisconnected}
                                        setCall={setCall}
                                        call={call}
                                        setIsCallPaused={setIsCallPaused}
                                        onClick={() =>
                                            handleSelection("disconnect")
                                        }
                                        // style={{
                                        //     transform: isSelected("disconnect")
                                        //         ? "scale(1.2)"
                                        //         : "scale(1)",
                                        //     transition: "transform 0.3s ease",
                                        //     cursor: "pointer",
                                        // }}
                                    />
                                    <PauseCall
                                        mobileNumber={liftingMobileNumber}
                                        setIsCallPause={setIsCallPause}
                                        isCallPause={isCallPause}
                                        onClick={() => handleSelection("pause")}
                                        style={{
                                            transform: isSelected("disconnect")
                                                ? "scale(1.2)"
                                                : "scale(1)",
                                            transition: "transform 0.3s ease",
                                            cursor: "pointer",
                                        }}
                                    />
                                    <TransferCall
                                    setIsCallPaused={setIsCallPaused}

                                    setInbound={setInbound}
                                        setCall={setCall}
                                        call={call}
                                        setCheckTransferCallNumberEntered={
                                            setCheckTransferCallNumberEntered
                                        }
                                        setIsConferenceCallActive={
                                            setIsConferenceCallActive
                                        }
                                        setIsTransferCallActive={
                                            setIsConferenceCallActive
                                        }
                                        conferenceNumber={conferenceNumber}
                                        onClick={() =>
                                            handleSelection("transfer")
                                        }
                                        style={{
                                            transform: isSelected("disconnect")
                                                ? "scale(1.2)"
                                                : "scale(1)",
                                            transition: "transform 0.3s ease",
                                            cursor: "pointer",
                                        }}
                                    />
                                    <Conference
                                        setIsCallPause={setIsCallPause}

                                        setCheckConferenceCallNumberEntered={
                                            setCheckConferenceCallNumberEntered
                                        }
                                        checkConferenceCallNumberEntered={
                                            checkConferenceCallNumberEntered
                                        }
                                        setIsConferenceCallActive={
                                            setIsConferenceCallActive
                                        }
                                        conferenceNumber={conferenceNumber}
                                        isConferenceCallActive={
                                            isConferenceCallActive
                                        }
                                        call={call}
                                        callExtensionRefetch={
                                            callExtensionRefetch
                                        }
                                        setConferenceCallApiCalled={
                                            setConferenceCallApiCalled
                                        }
                                        onClick={() =>
                                            handleSelection("conference")
                                        }
                                        style={{
                                            transform: isSelected("disconnect")
                                                ? "scale(1.2)"
                                                : "scale(1)",
                                            transition: "transform 0.3s ease",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "10px",
                                        alignItems: "center",
                                        width: "20%",
                                    }}
                                >

                                    <PauseCall
                                        mobileNumber={liftingMobileNumber}
                                        setIsCallPause={setIsCallPause}
                                        isCallPause={isCallPause}
                                        onClick={() =>
                                            handleSelection("disconnect")
                                        }
                                        style={{
                                            transform: isSelected("disconnect")
                                                ? "scale(1.2)"
                                                : "scale(1)",
                                            transition: "transform 0.3s ease",
                                            cursor: "pointer",
                                        }}
                                    />

                                    <CallDisconnect
                                        setIsCallDisconnected={
                                            setIsCallDisconnected
                                        }
                                        checkingExtensionState={checkingExtensionState}

                                    setInbound={setInbound}

                                        isCallDisconnected={isCallDisconnected}
                                        setCall={setCall}
                                        call={call}
                                        setIsCallPaused={setIsCallPaused}
                                        onClick={() =>
                                            handleSelection("disconnect")
                                        }
                                        // style={{
                                        //     transform: isSelected("disconnect")
                                        //         ? "scale(1.2)"
                                        //         : "scale(1)",
                                        //     transition: "transform 0.3s ease",
                                        //     cursor: "pointer",
                                        // }}
                                    />

                                    <ThirdPartCallDisconnect
                                        setCheckConferenceCallNumberEntered={
                                            setCheckConferenceCallNumberEntered
                                        }
                                        setThirdPartyCallDisconnected={
                                            setThirdPartyCallDisconnected
                                        }
                                        setIsConferenceCallActive={
                                            setIsConferenceCallActive
                                        }
                                        conferenceNumber={conferenceNumber}
                                        onClick={() =>
                                            handleSelection("disconnect")
                                        }
                                        style={{
                                            transform: isSelected("disconnect")
                                                ? "scale(1.2)"
                                                : "scale(1)",
                                            transition: "transform 0.3s ease",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <i
                                    className="bi bi-telephone"
                                    style={{ fontSize: "20px" }}
                                    onClick={callhandler}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Call"
                                ></i>
                            </div>
                        </>
                    )}
                </div>
                <div>
                    {getingTelephonyLoginStatus === "true" ? (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                // width: !isCallPaused ? "30%" : "40%",
                                // justifyContent: "end",
                                // marginLeft: "20%",
                                // marginRight: "70px",
                                width: "30%",
                            }}
                        >
                            <div>
                                {!isCallPaused ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "7px",
                                            border: "1px solid #e4e6ef",
                                            borderRadius: "5px",
                                            padding: "5px",
                                        }}
                                    >
                                        <span style={{ fontSize: "15px" }}>
                                            Pause
                                        </span>
                                        <span>
                                            <i
                                                className="bi bi-pause-circle"
                                                style={{ fontSize: "20px" }}
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Resume"
                                                onClick={pauseHandler}
                                                // onClick={() =>

                                                //     setIsCallPaused(true)
                                                // }
                                            ></i>
                                        </span>
                                    </div>
                                ) : (
                                    <div
                                    onClick={resumeHandler}

                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "5px",
                                            border: "1px solid #e4e6ef",
                                            borderRadius: "5px",
                                            padding: "10px 15px",
                                            cursor: "pointer"
                                        }}
                                    >
                                        <span style={{ fontSize: "15px" }}>
                                            Resume
                                        </span>
                                        <span>
                                            <i
                                                className="bi bi-play-circle-fill"
                                                style={{ fontSize: "20px" }}
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Pause"
                                             
                                            ></i>
                                        </span>
                                    </div>
                                )}
                            </div>
                            {isCallPaused ? (
                                <select
                                    style={{ width: "auto",  maxWidth: "200px" }}
                                    className="form-select"
                                    onChange={pauseCodeChangeHandler}
                                    value={pauseCodeValue}
                                >
                                    <option selected value="">
                                        Select Pause Code
                                    </option>

                                    {getpauseCode?.map((code, index) => (
                                        <option
                                            value={code?.name}
                                            key={code?.id}
                                        >
                                            {code?.name}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <div>
                                    <button
                                        className="btn btn-primary"
                                        style={{
                                            padding: "7px 10px",
                                            width: "100%",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {pauseCodeValue}
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            {/* <CustomerInfo inboundData={"sdcsd"} closeModal={closeModal} /> */}

            {/* <ReactModal
                isOpen={isCustomerHistoryModalOpen}
                onRequestClose={closeCustomerHistoryModal}
                style={customModalStyles}
            >
                <CustomerInfo
                    inboundData={inbound}
                    closeModal={closeCustomerHistoryModal}
                />
            </ReactModal> */}

            <Modal
      open={isCustomerHistoryModalOpen}
      onClose={closeCustomerHistoryModal}
      disableEnforceFocus
      disableAutoFocus
    >
      <Box sx={muiModalStyles}>
        <CustomerInfo
          inboundData={inbound}
          closeModal={closeCustomerHistoryModal}
        />
      </Box>
    </Modal>
            {/* <div>
            <span
              onClick={toggleTelephony}
              style={{padding: isTelephonyVisible ? "4px" : "0 4px"}}
              // className="telephony-toggle-btn btn btn-outline-primary"
            >
              {isTelephonyVisible ? (
                <>
                   <i className="bi bi-chevron-up"></i>
                </>
              ) : (
                <>
                   <i className="bi bi-chevron-down"></i>
                </>
              )}
            </span>
            </div> */}
               {/* <CustomerInfo
                    inboundData={inbound}
                    closeModal={closeCustomerHistoryModal}
                /> */}
        </div>
    );
};

export default TelephonyFrontend;
