import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSaveDespositionMutation } from "../../../../Services/general/General";
import { Loader } from "react-bootstrap-typeahead";

interface IProps {
  closeUpdateModal: () => void;
  setData: (data: any) => void;
  data: any;
  currentNode: any;
  SetLiftingAddDate: (data: any) => void;
  selectedTeam: any;
  refetch: any;
}

const UpdateTopicModal: React.FC<IProps> = ({
  closeUpdateModal,
  setData,
  data,
  currentNode,
  SetLiftingAddDate,
  selectedTeam,
  refetch,
}) => {
  const [topic, setTopic] = useState(currentNode.topic || "");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [trigger, { isLoading }] = useSaveDespositionMutation();

  const handleTopicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTopic(event.target.value);
  };

  const removeLevelProperty = (node: any) => {
    const { level, ...rest } = node;
    if (rest.sub_topics && rest.sub_topics.length > 0) {
      rest.sub_topics = rest.sub_topics.map((subNode: any) =>
        removeLevelProperty(subNode)
      );
    }
    return rest;
  };

  const updateNodeTopic = (
    nodes: any[],
    level: string,
    newTopic: string
  ): any[] => {
    return nodes.map((node) => {
      if (node.level === level) {
        const updatedNode = { ...node, topic: newTopic };
        return removeLevelProperty(updatedNode); // Remove level from updated node
      } else if (node.sub_topics) {
        const updatedNode = {
          ...node,
          sub_topics: updateNodeTopic(node.sub_topics, level, newTopic),
        };
        return removeLevelProperty(updatedNode); // Remove level from non-updated nodes
      } else {
        return removeLevelProperty(node); // Remove level from leaf nodes
      }
    });
  };

  const handleUpdate = async () => {
    setIsSubmitting(true); // Disable the button and prevent multiple submissions

    const updatedData = updateNodeTopic(data, currentNode.level, topic);

    try {
      const response = await trigger({
        body: updatedData,
        id: selectedTeam,
      });

      if (response?.data) {
        toast.success("Updated Successfully");
        refetch();
        closeUpdateModal();
      }
    } catch (error) {
      toast.error("An error occurred while updating");
      setIsSubmitting(false); // Re-enable the button in case of an error
    }

    setData(updatedData);
  };

  useEffect(() => {
    if (data) {
      SetLiftingAddDate(data); // Use the original data with levels intact
    }
  }, [data]);

  // Determine if the "Update" button should be disabled
  const isUpdateButtonDisabled = !topic.trim() || isSubmitting;

  return (
    <div>
      <div className="mt-4">
        <input
          type="text"
          className="form-control"
          placeholder="Topic"
          value={topic}
          onChange={handleTopicChange}
          disabled={isSubmitting} // Disable input while submitting
        />
      </div>
      <div className="d-flex justify-content-end mt-4">
        <button
          type="reset"
          className="btn btn-sm btn-white text-hover-danger me-4"
          onClick={closeUpdateModal}
          disabled={isSubmitting} // Disable button while submitting
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={handleUpdate}
          disabled={isUpdateButtonDisabled} // Disable the button if the topic is empty or if submitting
        >
          {isLoading ? (
            <>
              Updating... <Loader />
            </>
          ) : (
            "Update"
          )}
        </button>
      </div>
    </div>
  );
};

export default UpdateTopicModal;
