import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import './styles.scss'

interface IPROPS {
  open: any;
  setLifitinMobileNumber: any;
  onClose: any;
  callhandler: any;
  liftingMobileNumber: any;
}

const DialPad: React.FC<IPROPS> = ({
  open,
  setLifitinMobileNumber,
  onClose,
  callhandler,
  liftingMobileNumber,
}) => {
  const [dialNumber, setDialNumber] = useState(''); // Dialed number state
  const [highlightKey, setHighlightKey] = useState<string | null>(null); // Highlighted key

  useEffect(() => {
    if (liftingMobileNumber) {
      setDialNumber(liftingMobileNumber)

    }

  }, [liftingMobileNumber])

  const dialPadData = [
    { digit: "1"},
    { digit: "2"},
    { digit: "3"},
    { digit: "4"},
    { digit: "5"},
    { digit: "6"},
    { digit: "7"},
    { digit: "8"},
    { digit: "9"},
    { digit: "*"},
    { digit: "0"},
    { digit: "#"},
  ];

  const handleDial = (value: string) => {
    // Ensure only numbers are allowed and the length does not exceed 10
    if (/^[0-9]$/.test(value) && dialNumber.length < 10) {
      setDialNumber((prev) => prev + value);
    }
  };

  const deleteLastDigit = () => {
    setDialNumber((prev) => prev.slice(0, -1)); 
  };

  const startCall = useCallback(() => {
    callhandler();
    
  }, [callhandler]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const key = event.key;
      if (/^[0-9*#]$/.test(key)) {
        handleDial(key);
        setHighlightKey(key); 
      } else if (key === "Backspace") {
        deleteLastDigit();
      } else if (key === "Enter") {
        startCall();
      }
    },
    [startCall]
  );

  const handleKeyUp = useCallback(() => {
    setHighlightKey(null); // Remove the highlight when key is released
  }, []);

  useEffect(() => {
    if (open) {
      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("keyup", handleKeyUp);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [open, handleKeyDown, handleKeyUp]);

  useEffect(() => {
    setLifitinMobileNumber(dialNumber);
  }, [dialNumber, setLifitinMobileNumber]);

  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "40%",
            left: "20%",
            transform: "translate(-50%, -50%)",
            width: 270,
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            padding: "8px 16px",
            // p: 2,
          }}
        >
       <Typography
  variant="h5"
  textAlign="center"
  sx={{
    // mb: 2,
    borderRadius: "4px",
    padding: "8px",
    position: "relative",
    minHeight: "40px", 
  }}
>
  {dialNumber || (
    <span
      style={{
        display: "inline-block",
        width: "2px", // Vertical line width
        height: "1.5rem", // Line height
        // border: "1px solid green",
        backgroundColor: "#000",
        animation: "blink 1s step-end infinite", // Blinking animation
      }}
    ></span>
  )}
</Typography>

          {/* Dial Pad */}
          <Grid container spacing={2}>
            {dialPadData.map(({ digit }) => (
              <Grid item xs={4} key={digit} style={{paddingTop: "0px"}}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => handleDial(digit)}
                  sx={{
                    height: 70,
                    fontSize: "1.2rem",
                    borderRadius: "50%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor:
                      highlightKey === digit ? "#cfe8fc" : "transparent",
                    transition: "background-color 0.2s ease",
                    border: "none",
                    paddingTop: "0"
                  }}
                >
                  <span>{digit}</span>
                  <Typography
                    variant="caption"
                    sx={{ fontSize: "0.75rem", color: "#666" }}
                  >
                    {/* {letters} */}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              // marginTop: "20px",
              alignItems: "center",
            }}
          >
            <div style={{ width: "60%" }}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <i
                  className="bi bi-telephone-fill"
                  onClick={startCall}
                  style={{
                    color: "white",
                    fontSize: "18px",
                    padding: "20px",
                    borderRadius: "50%",
                    // background: "#rgb(54 193 46)",
                    background: "#2bbf5a"
                  }}
                ></i>
              </div>
            </div>

            <div style={{ flex: "1", justifyContent: "center" }}>
              {dialNumber && (
                <IconButton
                  color="error"
                  onClick={deleteLastDigit}
                  sx={{
                    height: 50,
                    width: 50,
                    padding: 0,
                    borderRadius: "50%",
                    backgroundColor: "transparent",
                    marginLeft: "25px",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DialPad;