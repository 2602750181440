import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import TicketReportList from '../../../components/reports/tickets/TicketReportList'
import TicketLifeCycleReport from '../../../components/reports/tickets/SelectedTicket/TicketLifeCycleReport'
import IssueTypeWiseTatReport from '../../../components/reports/tickets/SelectedTicket/IssueTypeWiseTatReport'
import SubIssueTypeWiseTatReport from '../../../components/reports/tickets/SelectedTicket/SubIssueTypeWiseTatReport'
import DepartmentWiseTatReport from '../../../components/reports/tickets/SelectedTicket/DepartmentWiseTatReport'
import StatusLevelAuditReport from '../../../components/reports/tickets/SelectedTicket/StatusLevelAuditReport'
import AuditTicktetReport from '../../../components/reports/tickets/SelectedTicket/AuditTicktetReport'
import LatestStatusReport from '../../../components/reports/tickets/SelectedTicket/LatestStatusReport'

const TicketRoute = () => {
    return (
        <Routes>

            <Route
                path='/reports-list'
                element={
                    <>
                     <TicketReportList/>
                    </>
                }
            />

            <Route
                path='/ticket-life-cycle-report'
                element={
                    <>
                       <TicketLifeCycleReport/>
                    </>
                }
            />
            <Route
                path='/issue-type-wise-tat-report'
                element={
                    <>
                     <IssueTypeWiseTatReport/>
                    </>
                }
            />

            <Route
                path='/sub-issue-type-wise-tat-report'
                element={
                    <>
                    <SubIssueTypeWiseTatReport/>
                    </>

                }
            />
            <Route
                path='/department-wise-tat-report'
                element={
                    <>
                     <DepartmentWiseTatReport/>
                    </>
                }
            />
            <Route
                path='/status-level-audit-report'
                element={
                    <>
                           <StatusLevelAuditReport/>
                    </>
                }
            />
            <Route
                path='/audit-ticket-report'
                element={
                    <>
                            <AuditTicktetReport/>
                    </>
                }
            />

            <Route
                path='/latest-status-report'
                element={
                    <>
                          <LatestStatusReport/>
                    </>
                }
            />

            <Route index element={<Navigate to='/ticket/reports-list' />} />

        </Routes >
    )
}

export default TicketRoute
