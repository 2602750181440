import React from "react";
import Chart from "react-apexcharts";
import "./Styles.css";
import { format } from "date-fns";

interface IPROPS {
  emailDashboardData: any;
}
const RepeatCustomerChart: React.FC<IPROPS> = ({ emailDashboardData }) => {
  const dailyResponseData =
    emailDashboardData?.repeat_customers?.daily_repeat_customers.map(
      (data: { date: string; repeat_customers: number }) => ({
        x:format(new Date(data.date), "dd-MM-yyyy"), // Formatting date for x-axis
        y: data.repeat_customers, // Average resolution time for y-axis
      })
    );
  const options: any = {
    chart: {
      type: "bar",
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
        export: {
          svg: {
            filename: "repeat_customer",
          },
          png: {
            filename: "repeat_customer",
          },
          csv: {
            filename: "repeat_customer",
          },
        },
      },
    },
    series: [
      {
        data: [
          {
            x: "category A",
            y: 10,
          },
          {
            x: "category B",
            y: 18,
          },
          {
            x: "category C",
            y: 13,
          },
        ],
      },
    ],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
      },
    },
    grid: {
      show: false,
      padding: {
        left: 25, // Add padding to the left
        right: 25, // Add padding to the right
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Math.floor(val); // Ensure that only whole numbers are shown
        },
      },
      min: 0, // Ensure minimum is
      forceNiceScale: true,
    },
  };

  const series = [
    {
      name: "Repear Customers",
      data: dailyResponseData || [], // Fallback to empty array if no data
    },
  ];

  return (
    <div className="card card-flush h-md-50 mb-5 mb-xl-10 m-1">
      <div className="card-header pt-5">
        <div className="card-title d-flex flex-column">
          <div className="d-flex align-items-center">
            <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
              {emailDashboardData?.repeat_customers?.overall_repeat_customers}
            </span>
            <span className="text-gray-400 pt-1 fw-semibold mx-4">
              {emailDashboardData?.repeat_customers_percentage_change > 0 ? (
                <>
                  <i className="bi bi-arrow-up text-success fs-2"></i>
                  {Number.isInteger(
                    emailDashboardData.repeat_customers_percentage_change
                  )
                    ? emailDashboardData.repeat_customers_percentage_change
                    : emailDashboardData.repeat_customers_percentage_change.toFixed(
                        2
                      )}
                  %
                </>
              ) : emailDashboardData?.repeat_customers_percentage_change < 0 ? (
                <>
                  <i className="bi bi-arrow-down text-danger fs-2"></i>
                  {Number.isInteger(
                    emailDashboardData.repeat_customers_percentage_change
                  )
                    ? emailDashboardData.repeat_customers_percentage_change
                    : emailDashboardData.repeat_customers_percentage_change.toFixed(
                        2
                      )}
                  %
                </>
              ) : emailDashboardData?.repeat_customers_percentage_change ===
                0 ? (
                <span>-</span>
              ) : (
                <span>-</span>
              )}
            </span>
          </div>
          <span className="text-dark pt-1 fs-6">Repeat customer </span>
        </div>
      </div>
      <Chart
        options={options}
        series={series}
        type="area"
        height={220}
        className="custom-apex-chart"
      />
    </div>
  );
};

export default RepeatCustomerChart;
