import React from "react";
import ReactApexChart from "react-apexcharts";
import './clientStyles.scss';
import { format } from "date-fns";

interface IStatusCounts {
  active: number;
  closed: number;
  reopen: number;
  wip: number;
}

interface IPieChartDetails {
  date: string;
  status_counts: IStatusCounts;
}

interface IPROPS {
  pieChartDetails: IPieChartDetails[];
}

const ChannelChart: React.FC<IPROPS> = ({ pieChartDetails }) => {
  // Extract dates and status counts
  const dates = pieChartDetails.map(detail => format(new Date(detail.date), "dd-MM-yyyy"));
  const activeCounts = pieChartDetails?.map(detail => detail?.status_counts?.active);
  const closedCounts = pieChartDetails?.map(detail => detail?.status_counts?.closed);
  const reopenCounts = pieChartDetails?.map(detail => detail?.status_counts?.reopen);
  const wipCounts = pieChartDetails?.map(detail => detail?.status_counts?.wip);

  const options: any = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: dates,
    },
    fill: {
      opacity: 1,
    },

    yaxis: {
      labels: {
        formatter: function (val) {
          return Math.floor(val); // Ensure that only whole numbers are shown
        },
      },
      min: 0, // Ensure minimum is 
      forceNiceScale: true,
    },
  };

  const series = [
    {
      name: "Active",
      data: activeCounts, 
    },

    {
      name: "Work in progress",
      data: wipCounts,
    },

    {
      name: "Closed",
      data: closedCounts, 
    },

    {
      name: "Reopen",
      data: reopenCounts,
    },
  ];

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={300}
      />
    </div>
  );
};

export default ChannelChart;
