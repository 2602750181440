/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { CSSProperties, useEffect, useRef } from 'react'

interface IProps {
  menuRef: React.RefObject<HTMLDivElement>
  droppedDown: boolean
  setDroppedDown: React.Dispatch<React.SetStateAction<boolean>>
  width?: number
  height?: number
  classes?: string
  styles?: CSSProperties
  hideOnBlur?: boolean
}

export const Dropdown: React.FC<IProps> = ({
  menuRef,
  droppedDown,
  setDroppedDown,
  width,
  height,
  classes,
  styles,
  hideOnBlur = true,
  children
}) => {
  const menu = menuRef?.current as HTMLDivElement
  const menuRect = menu?.getBoundingClientRect()
  const itemRef = useRef<HTMLDivElement>(null)

  const dropdownStyles = {
    position: 'fixed',
    zIndex: 105,
    width: !!width ? width : 'auto',
    height: !!height ? height : 'auto',
    inset: '0px 0px auto auto',
    margin: 0,
    transform: `translate(${menuRect ? menuRect.right - window.innerWidth : '-49'}px, ${
      menuRect ? menuRect.bottom : '203'
    }px)`,
    // the above default pixels are based on chatDropdownMenu
    ...styles
  }

  const hideDropdown = (e: any) => {
    const item = itemRef?.current as HTMLDivElement
    if (!item) {
      return
    }
    const itemRect = item?.getBoundingClientRect()
    const eventType = e.type
    const x = e.clientX
    const y = e.clientY
    const itemLeft = itemRect.left
    const itemRight = itemRect.right
    const itemTop = itemRect.top
    const itemBottom = itemRect.bottom
    if (eventType === 'click') {
      if (x >= itemLeft && x <= itemRight && y >= itemTop && y <= itemBottom) {
        return null
      } else {
        setDroppedDown(false)
      }
    } else {
      setDroppedDown(false)
    }
  }

  useEffect(() => {
    if (droppedDown && hideOnBlur) {
      window.addEventListener('click', hideDropdown)
      window.addEventListener('scroll', hideDropdown)
    }
    return () => {
      if (droppedDown && hideOnBlur) {
        window.removeEventListener('click', hideDropdown)
        window.removeEventListener('scroll', hideDropdown)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [droppedDown])

  return (
    <div
      className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 ${
        droppedDown ? 'show' : ''
      } ${classes ? classes : ''}`}
      style={dropdownStyles as CSSProperties}
      ref={itemRef}
    >
      {children}
    </div>
  )
}
