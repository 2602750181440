import React, { useState, useEffect } from "react";
import { useUpdateSeverDataMutation } from "../../../Services/Telephony/Telephony";
import { toast } from "react-toastify";

interface IPROPS {
    closeModal: any;
    refetchingData: any;
    specificServerData: any;
}

interface ErrorResponse {
    error?: {
        data?: {
            sql_port?: string[];
        };
    };
}

const EditModal: React.FC<IPROPS> = ({
    closeModal,
    refetchingData,
    specificServerData,
}) => {
    const [ip, setIp] = useState("");
    const [id, setId] = useState("");

    const [sql, setSql] = useState("");
    const [https, setHttps] = useState("");
    const [http, setHttp] = useState("");
    const [domain, setDomain] = useState("");
    const [user, setUser] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [recordingPath, setRecordingPath] = useState("");

    const [trigger, { isLoading }] = useUpdateSeverDataMutation();

    useEffect(() => {
        if (specificServerData) {
            setIp(specificServerData?.ip_addr);
            setSql(specificServerData?.sql_port);
            setHttps(specificServerData?.https_port);
            setHttp(specificServerData?.http_port);
            setDomain(specificServerData?.domain);
            setUser(specificServerData?.db_user);
            setName(specificServerData?.db_name);
            setPassword(specificServerData?.db_password);
            setRecordingPath(specificServerData?.recording_path);
            setId(specificServerData?.id);
        }
    }, [specificServerData]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const serverObj = {
            db_name: name,
            db_password: password,
            db_user: user,
            domain: domain,
            http_port: http,
            https_port: https,
            ip_addr: ip,
            recording_path: recordingPath,
            sql_port: sql,
        };

        const response = await trigger({ body: serverObj, id: id });

        try {
            if (response?.data) {
                closeModal();
                refetchingData();
                toast.success("Edited successfully");
            } else if ("data" in response?.error) {
                const errorData = response.error.data as {
                    sql_port?: string[];
                    https_port?: string[];
                    http_port?: string[];
                }; // Type assertion

                const errorMessage = errorData?.sql_port?.[0]
                    ? "Please add sql port"
                    : errorData?.https_port?.[0]
                    ? "Please add https port"
                    : errorData?.http_port?.[0]
                    ? "Please add http port"
                    : "An unknown error occurred";

                toast.error(errorMessage);
            }
        } catch (error) {
            toast.error("An error occured");
            // if ('data' in response?.error) {
            //     console.log(error, "responsnsns")
            //     const errorData = response.error.data as { sql_port?: string[] }; // Type assertion

            //     console.log(errorData, "errordatata")
            //     if (errorData?.sql_port?.[0]) {
            //         toast.error(errorData.sql_port[0]);
            //     } else {
            //         toast.error("An unknown error occurred");
            //     }
            // } else {
            //     toast.error("An unknown error occurred");
            // }
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    maxHeight: "500px",
                    overflow: "scroll",
                }}
            >
                <div>
                    <label>IP address</label>
                    <input
                        className="form-control"
                        onChange={(e) => setIp(e.target.value)}
                        value={ip}
                    />
                </div>
                <div>
                    <label>SQL port</label>
                    <input
                        className="form-control"
                        onChange={(e) => setSql(e.target.value)}
                        value={sql}
                    />
                </div>
                <div>
                    <label>Https port</label>
                    <input
                        className="form-control"
                        onChange={(e) => setHttps(e.target.value)}
                        value={https}
                    />
                </div>
                <div>
                    <label>Http port</label>
                    <input
                        className="form-control"
                        onChange={(e) => setHttp(e.target.value)}
                        value={http}
                    />
                </div>
                <div>
                    <label>Domain</label>
                    <input
                        className="form-control"
                        onChange={(e) => setDomain(e.target.value)}
                        value={domain}
                    />
                </div>
                <div>
                    <label>DB user</label>
                    <input
                        className="form-control"
                        onChange={(e) => setUser(e.target.value)}
                        value={user}
                    />
                </div>
                <div>
                    <label>DB name</label>
                    <input
                        className="form-control"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                    />
                </div>
                <div>
                    <label>DB password</label>
                    <input
                        className="form-control"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    />
                </div>
                <div>
                    <label>Recording path</label>
                    <input
                        className="form-control"
                        onChange={(e) => setRecordingPath(e.target.value)}
                        value={recordingPath}
                    />
                </div>
            </div>

            <div
                style={{
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "end",
                }}
            >
                <button
                    className="btn btn-sm btn-white me-2"
                    onClick={closeModal}
                    >Cancel</button>
                <button className="btn btn-primary" onClick={handleSubmit}>
                    Save
                </button>
            </div>
        </div>
    );
};

export default EditModal;
