import { useState, useEffect } from "react";
import MultiStepProgressBar from "../../../utils/multiStepProgressBar/MultiStepProgressBar";
import DialpadImage from "../../../assets/images/dialpad.jpg";
import TabbedPanel from "../../../components/common/tabbedPanel";
import KnowledgeManagement from "../../../components/common/KnowledgeManagement";
import ChatBox from "../../../components/common/ChatBox";
import ContactCard from "../../../components/common/ContactCard";
import { useNavigate, useParams } from "react-router-dom";
import Dialpad from "../../../assets/images/dialpad.jpg";
import CampignLeadCardInfo from "../../../components/campaign/LeadCardInfo/CampignLeadCardInfo";
import {
    useCampaignChangeStatusQuery,
    useCampainPaginationQuery,
    useCampignWorkflowQuery,
} from "../../../Services/campign/campignList";
import { useDetailCamapignQuery } from "../../../Services/campign/CampaignDetail";
import { useTaskQuery } from "../../../Services/ticket/Task";
import AddNewTicketContactModal from "../../../components/ticket/AddNewTicketContactModal";
import ReactModal from "react-modal";
import AddNewContactCampaignModal from "../../../components/common/tabbedPanel/AddNewContactCampaignModal";
import CreateTask from "./CreateTask";
import NotificationModal from "./NotificationModal";
import { useWebSocket } from "../../../context/WebSocketContext";
import { Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import CampaignLeadModel from "../CampaignLeadModel";
import { useTelephony } from "../../../components/context/TelephonyContext";
const LeadInfo = () => {
    const params = useParams();
    const uuid = params.uuid;

    const [currentLeadId, setCurrentCamapignId] = useState(uuid);
    const { data, refetch } = useDetailCamapignQuery(currentLeadId);
    // var dd=data.camapign;
    // console.log(dd,"camapignid")

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isNotificationModalOpen, setIsNotificationModalOpen] =
        useState(false);
    const [socketData, setSocketData] = useState({});
    const [liftingTaskData, setLiftingTaskData] = useState("");
    const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false);

    const [getAllStatus, setGetAllStatus] = useState<any>([]);
    const {
        data: taskData,
        refetch: refetchTask,
        isLoading,
    } = useTaskQuery(currentLeadId);
    // const { data: campaignData, refetch: refetchAuditTrail } = useCampaignChangeStatusQuery(uuid);
    const { data: campaignData, refetch: refetchAuditTrailData } =
        useCampaignChangeStatusQuery(data?.campaign?.id, {
            skip: !data?.campaign?.id,
        });
    const customModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "700px",
            height: "600px",
        },
    };
    const [isAddNewContactModalOpen, setAddNewContactModalOpen] =
        useState(false);
    const [isAddNewLeadModalOpen, setIsAddNewLeadModalOpen] = useState(false);

    const navigate = useNavigate();
    const teams = [{ name: "team01" }, { name: "team02" }, { name: "team03" }];
    const listOfLeads = [
        {
            isSelected: false,
            leadId: "00227",
            fname: "Prajakta",
            lname: "joshi",
            company: "Bluewhirl",
            mailid: "prajakta@bluewhirl.io",
            mobileno: "8953519254",
            status: "All",
            createdDate: "04/5/24",
            imageUrl: "/media/avatars/300-1.jpg",
        },
        {
            isSelected: false,
            leadId: "00228",
            fname: "Archana",
            lname: "Jagtap",
            company: "Bluewhirl",
            mailid: "archana@bluewhirl.io",
            mobileno: "6897519254",
            status: "Open",
            createdDate: "05/6/24",
            imageUrl: "/media/avatars/300-2.jpg",
        },
        {
            isSelected: false,
            leadId: "00229",
            fname: "Abhinev",
            lname: "Qasba",
            company: "Botgo",
            mailid: "abhinev@botgo.io",
            mobileno: "9990966200",
            status: "WIP",
            createdDate: "06/6/24",
            imageUrl: "/media/avatars/300-7.jpg",
        },
        {
            isSelected: false,
            leadId: "00230",
            fname: "Kiran",
            lname: "Singh",
            company: "Botgo",
            mailid: "kiran@botgo.io",
            mobileno: "9990099638",
            status: "Ready",
            createdDate: "12/7/24",
            imageUrl: "/media/avatars/300-6.jpg",
        },
        {
            isSelected: false,
            leadId: "00231",
            fname: "Shivam",
            lname: "Shakya",
            company: "Botgo",
            mailid: "shivam@botgo.io",
            mobileno: "8888262122",
            status: "Closed",
            createdDate: "21/12/24",
            imageUrl: "/media/avatars/300-5.jpg",
        },
    ];
    const openModal = () => {
        setIsModalOpen(true);
    };

    const afterOpenModal = () => {
        // subtitle.style.color = '#f00'
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setIsCreateTaskModalOpen(false);
        setIsNotificationModalOpen(false);
    };

    // const SelectedLead = listOfLeads.filter(
    //   (lead, index) => lead.leadId === uuid
    // );
    // useEffect(() => {
    //   const savedOption = sessionStorage.getItem("selectedOption");
    //   if (savedOption) {
    //     setSelectedOption(savedOption);
    //    }
    //   });

    const handleBackButtonClick = () => {
        navigate(-1); // Navigate back to the previous page
    };
    const openContactModal = () => {
        setAddNewContactModalOpen(true);
    };
    const openLeadModal = () => {
        setIsAddNewLeadModalOpen(true);
    };

    const closeContactModal = () => {
        setAddNewContactModalOpen(false);
        setIsAddNewLeadModalOpen(false);
    };
    const [emailNotPresent, setEmailNotPresent] = useState<any>(false);
    const [selectedContact, setSelectedContact] = useState<any>();
    const [storeNewEmailId, setStoreNewEmailId] = useState("");
    const [selectedCampain, setSelectedCampain] = useState<number>();
    const [theCampaign, setTheCampaign] = useState([]);
    const [isBulk, setIsBulk] = useState(false);

    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    // const [campaignId, setCampaignId] = useState<number>();
    // const [currentPage, setCurrentPage] = useState(Number(sessionStorage.getItem("campaignCurrentPage")) || 1);
    const [currentPage, setCurrentPage] = useState<any>(() => {
        const savedPage = sessionStorage.getItem("campaignCurrentPage");
        return Number(savedPage);
    });
    const [campaignId, setCampaignId] = useState<any>(() => {
        const savedPage = sessionStorage.getItem("selectedCampaignId");
        return savedPage ? Number(savedPage) : 1;
    });
    // const [data, setData] = useState<any>();
    const [liftUiData, setLiftUiData] = useState(0);
    const [liftingKey, setLiftingKey] = useState([]);
    const [storeKey, setStoreKey] = useState(liftUiData ? "active" : "All");
    const [pageSize] = useState<number>(5);
    // const [currentIndex, setCurrentIndex] = useState(0);

    // const [workflowName, setWorkflowName] = useState<string>(""); // Add state for workflowName

    const workflowName = localStorage.getItem("workflowName");
    const campaignnId = localStorage.getItem("campaignId");

    const [selectedFilterAndValue1, setSelectedFilterAndValue1] = useState<any>(
        () => {
            const sessionData = sessionStorage.getItem(
                "selectedCampaignFilterAndValue"
            );
            return sessionData
                ? JSON.parse(sessionData)
                : {
                    email__icontains: "",
                    assignee__username__icontains: "",
                    account_name__icontains: "",
                    created: "",
                    first_name__icontains: "",
                    last_name__icontains: "",
                    phone_number__icontains: "",
                };
        }
    );

    const { data: CampaignWorkflow, refetch: refetchWorkflow } =
        useCampignWorkflowQuery(
            {
                selectedWorkFlow: workflowName,
                selectCampaign: campaignnId,
                selectedFilterAndValue1,
            },
            { skip: !campaignnId || !workflowName }
        );
    const [selectedTeamId, setSelectedTeamId] = useState(() => {
        const selectedTeamIdFromSession =
            sessionStorage.getItem("selectedTeamId");
        const userRole = localStorage.getItem("user_role");
        const userId = localStorage.getItem("circleone-username");

        if (selectedTeamIdFromSession) {
            return selectedTeamIdFromSession;
        }

        if (userRole === "admin") {
            return "My Team";
        }

        if (userRole === "agent" || userRole === "manager") {
            return userId;
        }

        return null; // Fallback value if none of the conditions are met
    });

    const [value, setValue] = useState(liftUiData ? "active" : "All");
    const [direction, setDirection] = useState(null);
    const [isTicketLoading, setIsTicketLoading] = useState(false);

    const customResetPassModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "450px",
            maxWidth: "850px", // Max width for larger screens
            margin: "auto",
            maxHeight: "600px",

            // Overflow: "hidden",
            OverflowY: "auto",
        },
    };

    const socket = useWebSocket();

    useEffect(() => {
        const serverResponse = socket?.state?.serverResponse?.[0]?.data;

        if (serverResponse) {
            setIsNotificationModalOpen(true);
            setSocketData(serverResponse);
        }
    }, [socket, socket?.state?.serverResponse]);
    // useEffect(() => { sessionStorage.setItem("campaignCurrentPage", currentPage.toString()); }, [currentPage]);

    const { data: fetchedData, refetch: refetchPagination } =
        useCampainPaginationQuery(
            {
                currentPage,
                selectCampaign: campaignId,
                value,
                liftingKey,
                storeKey,
                selectedFilterAndValue1,
                selectedTeamId,
            },
            { skip: !campaignId }
        );
    // const handleButtonClick = async(direction) => {
    //   const recordsPerPage = 5;
    //   const camapignLead = fetchedData?.results;
    //   const count =fetchedData?.count;
    //   const totalRecords = fetchedData?.count || 0;
    //   const totalPages = Math.ceil(totalRecords / recordsPerPage);
    //   const currentIndex = camapignLead?.findIndex((camapign) => camapign.id === currentLeadId);

    //   if (direction === 'next') {
    //     if (currentIndex < camapignLead.length - 1) {
    //       const nextLeadId = camapignLead[currentIndex + 1].id;
    //       setCurrentCamapignId(nextLeadId);
    //       navigate(`/campaign/lead/${nextLeadId}`, { replace: true });
    //     } else if (currentPage < totalPages) {
    //       setCurrentPage(currentPage + 1);

    //       await refetchPagination(); // Refetch data for the new page
    //       setCurrentCamapignId(fetchedData?.results[0]?.id);
    //     } else {
    //       toast.warning("No more leads available.");
    //     }
    //   } else if (direction === 'previous') {
    //     if (currentIndex > 0) {
    //       const previousLeadId = camapignLead[currentIndex - 1].id;
    //       setCurrentCamapignId(previousLeadId);
    //       navigate(`/campaign/lead/${previousLeadId}`, { replace: true });
    //     } else if (currentPage > 1) {
    //       setCurrentPage(currentPage - 1);

    //       await refetchPagination(); // Refetch data for the new page
    //       const index = fetchedData?.results?.length-1;
    //       setCurrentCamapignId(fetchedData?.results[index]?.id);
    //     } else {
    //       toast.warning("No previous leads available.");
    //     }
    //   }
    // };
    const handleButtonClick = async (event, direction) => {
        event.preventDefault();
        setDirection(direction);
        setIsTicketLoading(true);
        // const recordsPerPage = 5;
        // const tickets = fetchedData?.results;
        // const totalRecords = fetchedData?.count || 0;
        // const totalPages = Math.ceil(totalRecords / recordsPerPage);
        // const currentIndex = tickets?.findIndex(
        //     (ticket) => ticket.id === currentTicketId
        // );
        const recordsPerPage = 5;
        const camapignLead = fetchedData?.results;
        const count = fetchedData?.count;
        const totalRecords = fetchedData?.count || 0;
        const totalPages = Math.ceil(totalRecords / recordsPerPage);
        const currentIndex = camapignLead?.findIndex(
            (camapign) => camapign.id === currentLeadId
        );

        if (direction === "next") {
            if (currentIndex < camapignLead?.length - 1) {
                const nextLeadId = camapignLead[currentIndex + 1].id;
                setCurrentCamapignId(nextLeadId);
                navigate(`/campaign/lead/${nextLeadId}`, {
                    replace: true,
                });
                setConfirmationModalOpen(false);
            } else if (currentPage < totalPages) {
                setCurrentPage((prevPage) => {
                    const newPage = prevPage + 1;
                    sessionStorage.setItem("campaignCurrentPage", newPage);
                    return newPage;
                });
                setConfirmationModalOpen(false);
            } else {
                toast.warning("No more leads available.");
                setConfirmationModalOpen(false);
            }
        } else if (direction === "previous") {
            if (currentIndex > 0) {
                const previousLeadId = camapignLead[currentIndex - 1]?.id;
                setCurrentCamapignId(previousLeadId);
                navigate(`/campaign/lead/${previousLeadId}`, {
                    replace: true,
                });
            } else if (currentPage > 1) {
                setCurrentPage((prevPage) => {
                    const newPage: any = prevPage - 1;
                    sessionStorage.setItem("campaignCurrentPage", newPage);
                    return newPage;
                });
            } else {
                toast.warning("No previous leads available.");
            }
        }
        setIsTicketLoading(false);
    };
    useEffect(() => {
        const refetchData = async () => {
            setIsTicketLoading(true);
            await refetchPagination();
            if (direction === "next") {
                setCurrentCamapignId(fetchedData?.results[0]?.id);
                navigate(`/campaign/lead/${fetchedData?.results[0]?.id}`, {
                    replace: true,
                });
                setConfirmationModalOpen(false);
            } else if (direction === "previous") {
                const lastLeadId =
                    fetchedData?.results[fetchedData.results.length - 1]?.id;
                setCurrentCamapignId(lastLeadId);
                navigate(`/campaign/lead/${lastLeadId}`, {
                    replace: true,
                });
            }
            setIsTicketLoading(false);
        };

        if (direction) {
            refetchData();
        }
    }, [currentPage, fetchedData]);

    const customEditModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "28%", // Default to full width
            maxWidth: "1150px", // Max width for larger screens
            height: "61%",
            margin: "auto",
            Overflow: "hidden",
            OverflowY: "auto",
        },
    };
    const customModalStyles12 = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "100%", // Default to full width
            maxWidth: "850px", // Max width for larger screens
            height: "60%",
            margin: "auto",
            Overflow: "hidden",
            OverflowY: "auto",
        },
    };

    const customModalStyle = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "100%", // Default to full width
            maxWidth: "650px", // Max width for larger screens
            // height: "60%",
            margin: "auto",
            Overflow: "hidden",
            OverflowY: "auto",
        },
    };

    return (
        <div id="kt_app_content_container" className="app-container mx-5">
            {/*begin::Products*/}
            <div className="card card-flush">
                {/*start:: The top stack */}
                {/* <div className="card-header align-items-center py-1 my-1 gap-2 gap-md-5"> */}
                {/* <div className="d-flex align-items-center gap-2 py-1 gap-md-5"> */}
                {/* <i className="bi bi-circle-fill" style={{ color: "red" }}></i>
            <input
              type="text"
              className="mx-2 form-control"
              placeholder="00:03:15"
            />
            <input
              type="text"
              className="form-control"
              placeholder="Mobile no."
            /> */}
                {/* <div className="d-flex align-items-center gap-2 py-1 gap-md-5">
              <button className="btn p-0">
                <img src={Dialpad} />
              </button>
              <i className="mx-1 bi bi-telephone-fill text-dark fs-2"></i>{" "}
            </div> */}
                {/* </div> */}

                {/*begin::Card toolbar*/}

                {/*end::Card toolbar*/}
                {/* </div> */}
                {/*end:: The top stack */}

                {/*start:: The 2nd stack */}
                <div className="card-header align-items-center py-1 gap-2 gap-md-5">
                    <button
                        className="btn btn-link mb-2"
                        onClick={handleBackButtonClick}
                    >
                        <i
                            className="bi bi-arrow-90deg-left"
                            style={{ fontSize: "20px", cursor: "pointer" }}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Back"
                        ></i>
                    </button>
                    <span
                        className="p-4 fs-3 text-gray-800 fw-bold"
                        style={{ marginBottom: "8px", marginLeft: "-21px" }}
                    >
                        Campaign
                    </span>
                    <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                        <div>
                            <button
                                className="form-control form-control align-items-center text-hover-primary fw-bold min-w-120px"
                                style={{ padding: "9px 30px" }}
                                onClick={openLeadModal}
                            >

                                <i className="text-dark bi bi-plus-lg me-2"></i>

                                <span className="ms-auto">Lead</span>

                            </button>
                        </div>
                        <div className="w-100 mw-100px">
                            <button
                                className="form-control form-control d-flex justify-content-between align-items-center text-hover-primary fw-bold"
                                onClick={openContactModal}
                            >
                                <i className="bi bi-plus-lg me-2"></i>
                                <span className="ms-auto">Contact</span>
                            </button>
                            <ReactModal
                                isOpen={isAddNewLeadModalOpen}
                                onRequestClose={closeContactModal}
                                style={
                                    !isBulk
                                        ? customModalStyles12
                                        : customModalStyle
                                }
                                contentLabel="Lead"
                            >
                                <CampaignLeadModel
                                    closeModal={closeContactModal}
                                    isBulk={isBulk}
                                    setIsBulk={setIsBulk}
                                    refetchWorkflow={refetchWorkflow}
                                    refetch={refetch}
                                    campaignId={campaignId}
                                    value={value}
                                    setValue={setValue}
                                    setStoreKey={setStoreKey}
                                    selectedCampaign={campaignData?.
                                        results[0]?.campaign
                                    }
                                />
                            </ReactModal>
                            <ReactModal
                                isOpen={isAddNewContactModalOpen}
                                onRequestClose={() =>
                                    setAddNewContactModalOpen(false)
                                }
                                style={customModalStyles}
                                contentLabel="New Contact"
                            >
                                <AddNewContactCampaignModal
                                    closeModal={() =>
                                        setAddNewContactModalOpen(false)
                                    }
                                // emailNotPresent={emailNotPresent}
                                // setSelectedContact={setSelectedContact}
                                // storeNewEmailId={storeNewEmailId}
                                // setEmailNotPresent={setEmailNotPresent}
                                />
                            </ReactModal>
                        </div>
                        <div
                            className="w-100 mw-100px"
                            onClick={() => setIsCreateTaskModalOpen(true)}
                            style={{ marginRight: "6px" }}
                        >
                            <button className="form-control form-control d-flex justify-content-between align-items-center text-hover-primary fw-bold">
                                <i
                                    className="bi bi-plus-lg me-2"
                                    style={{ marginLeft: "10px" }}
                                ></i>
                                <span
                                    className="ms-auto"
                                    style={{ marginRight: "19px" }}
                                >
                                    Task
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                {/*end:: The 2nd stack */}

                {/* //Next and preivous  Button */}
                {/* <div className="card-header align-items-center py-1 gap-2 gap-md-5">
          <button data-bs-toggle="tooltip" title="Previous Lead" className="form-control form-control d-flex justify-content-between align-items-center text-hover-primary fw-bold" onClick={() => handleButtonClick('previous')} style={{width:"100px"}}>
          <i className="bi bi-arrow-left fs-1" style={{paddingLeft:"18px"}}></i> 
          </button>
          <button data-bs-toggle="tooltip" title="Next Lead" className="form-control form-control d-flex justify-content-between align-items-center text-hover-primary fw-bold" style={{ marginRight: "17%" ,width:"100px"}} onClick={() => handleButtonClick('next')}> <i className="bi bi-arrow-right fs-1" style={{paddingLeft:"26px"}}></i> 
          </button>
        </div> */}
                <div
                    className="card-header align-items-center py-1 gap-2 gap-md-5"
                    style={{
                        width: "99%",
                        display: "flex",
                        justifyContent: "end",
                    }}
                >
                    <span
                        className="btn btn-link"
                        data-bs-toggle="tooltip"
                        title="Previous Lead"
                        onClick={(event) =>
                            handleButtonClick(event, "previous")
                        }
                    >
                        <i
                            className="bi bi-arrow-left-square"
                            style={{ fontSize: "20px", cursor: "pointer" }}
                        ></i>{" "}
                    </span>

                    <span
                        className="btn btn-link"
                        data-bs-toggle="tooltip"
                        title="Next Lead"
                        onClick={(event) => handleButtonClick(event, "next")}
                        style={{ marginRight: "16%" }}
                    >
                        <i
                            className="bi bi-arrow-right-square"
                            style={{ fontSize: "20px", cursor: "pointer" }}
                        ></i>
                    </span>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        {/* <div className="col-xl-2 col-lg-4 col-md-12 mb-4">
              <ContactCard tickets={data} />
            </div> */}
                        <div
                            className="col-xl-8 col-lg-8 col-md-12 mb-4"
                            style={{ width: "83%" }}
                        >
                            <div>
                                <div className="card">
                                    {/* <div className="p-4 d-md-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <a href="#" className="card-link">
                        <img
                          className="rounded-circle w-50px h-50px"
                          src={SelectedLead[0]?.imageUrl}
                          alt="mrJohn"
                        />
                      </a>
                      <div className="d-flex flex-column mx-6">
                        <p>
                          <h6 className="card-subtitle text-body-secondary">
                            Lead Id
                          </h6>
                        </p>
                        <p className="card-text">{SelectedLead[0]?.leadId}</p>
                      </div>
                      <div className="d-flex flex-column">
                        <p>
                          <h6 className="card-subtitle text-body-secondary">
                            Created Date
                          </h6>
                        </p>
                        <p className="card-text">
                          {SelectedLead[0]?.createdDate}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <img
                        className="rounded-circle w-50px h-50px mx-2"
                        src={SelectedLead[0]?.imageUrl}
                        alt="mrJohn"
                      />
                      <h6 className="card-subtitle text-body-secondary mx-4">
                        {SelectedLead[0]?.fname}
                      </h6>
                      <i className="fa fa-solid fa-chevron-down mx-6"></i>
                    </div>
                  </div> */}
                                    <CampignLeadCardInfo
                                        campign={data}
                                        refetch={refetch}
                                        refetchAuditTrailData={
                                            refetchAuditTrailData
                                        }
                                        setGetAllStatus={setGetAllStatus}
                                    />
                                </div>
                            </div>
                            {/* end::Item */}

                            <div className="mt-5">
                                {/* <span>
                  <h6 className="mb-2">Status</h6>
                </span>
                <div className="card mt-2">
                  <MultiStepProgressBar />
                </div> */}
                                <div className="mt-2 ">
                                    <TabbedPanel
                                        tickets={data}
                                        refetch={refetch}
                                        taskData={taskData}
                                        refetchTask={refetchTask}
                                        isLoading={isLoading}
                                        setLiftingTaskData={setLiftingTaskData}
                                        handleButtonClick={handleButtonClick}
                                        setConfirmationModalOpen={
                                            setConfirmationModalOpen
                                        }
                                        confirmationModalOpen={
                                            confirmationModalOpen
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-12 mb-4">
                            <KnowledgeManagement />
                            {/* <ChatBox /> */}
                        </div>
                    </div>
                </div>
                {/*ends:: The 4th stack */}
            </div>

            <ReactModal
                isOpen={isCreateTaskModalOpen}
                onRequestClose={closeModal}
                style={customResetPassModalStyles}
                contentLabel="Delete Campaign"
            >
                <CreateTask
                    taskInfo={data}
                    close={closeModal}
                    liftingTaskDataRefetch={liftingTaskData}
                    campaignData={campaignData}
                />
            </ReactModal>

            {/* <ReactModal
        isOpen={isNotificationModalOpen}
        onRequestClose={closeModal}
        style={customResetPassModalStyles}
        contentLabel="Delete Campaign"
      >
        <NotificationModal
          close={closeModal}
          socketData={socketData}


        />
      </ReactModal> */}
        </div>
    );
};

export default LeadInfo;
