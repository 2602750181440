import React, { useState } from "react";
import CustomerInfoEditModal from "./ticketListInfo/CustomerInfoEditModal";
import ReactModal from "react-modal";
import { useCustomerQuery } from "../../Services/settingsAPIs/CommonAPI";
import AccountInfoEditModal from "./ticketListInfo/AccountInfoEditModal";
import "../../pages/contact/styles.scss";
import { useTelephony } from "../context/TelephonyContext";
import { TELEPHONY_LOGIN } from "../../constants";
import { maskEmail, maskPhoneNumber } from "../reusableComponent/MaskedFields";

interface IProps {
    tickets: any;
}

const customModalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "100%", // Default to full width
        maxWidth: "550px", // Max width for larger screens
        margin: "auto",
        OverflowY: "auto",
        maxHeight: "600px",
    },
};

const TicketLeftSideMenuCard: React.FC<IProps> = ({ tickets }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
    const [showContact, setShowContact] = useState(true);
    const { data: updateCustomer, refetch } = useCustomerQuery(
        tickets?.action?.contact?.id,
        { skip: !tickets?.action?.contact?.id }
    );

    const { setLifitinMobileNumber } = useTelephony();

    const handleToggle = () => {
        setShowContact(!showContact);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openAccountModal = () => {
        setIsAccountModalOpen(true);
    };

    const closeAccountModal = () => {
        setIsAccountModalOpen(false);
    };

  const gettingTelephonyStatus = localStorage.getItem(TELEPHONY_LOGIN);


  const checkUserRole = localStorage.getItem("user_role")
  


    return (
        <div className="card card-flush">
            <div className="card px-2 mb-1">
                <div className="d-flex align-items-center justify-content-between">
                    <span className="card-text mx-5">
                        {showContact
                            ? "Customer Information"
                            : "Account Information"}
                    </span>

                    <div className="d-flex align-items-center">
                        {showContact ||
                        tickets?.action?.contact?.account !== null ? (
                            <button className="btn text-hover-primary">
                                <i
                                    className="bi bi-pencil-fill text-dark mx-n5 fs-3"
                                    onClick={
                                        showContact
                                            ? openModal
                                            : openAccountModal
                                    }
                                ></i>
                            </button>
                        ) : (
                            <div
                                className="btn text-hover-primary"
                                style={{ cursor: "default" }}
                            >
                                <div
                                    className="bi bi-pencil-fill text-dark mx-n5 fs-3"
                                    style={{ visibility: "hidden" }} // Hides the icon but keeps the space
                                ></div>
                            </div>
                        )}

                        <ReactModal
                            isOpen={isModalOpen}
                            onRequestClose={closeModal}
                            style={customModalStyles}
                            contentLabel="Edit Customer Information"
                        >
                            <CustomerInfoEditModal
                                data={updateCustomer}
                                closeModal={closeModal}
                                refetch={refetch}
                            />
                        </ReactModal>

                        <ReactModal
                            isOpen={isAccountModalOpen}
                            onRequestClose={closeAccountModal}
                            style={customModalStyles}
                            contentLabel="Edit Account Information"
                        >
                            {/* Replace this with your account editing modal component */}
                            <AccountInfoEditModal
                                data={tickets?.action?.contact} // Assuming account data is part of updateCustomer
                                closeModal={closeAccountModal}
                                refetch={refetch}
                            />
                        </ReactModal>
                    </div>
                </div>
            </div>
            <div className="custom-style">
                <div className="card px-2">
                    <div className="d-flex align-items-center flex-row py-4 gap-1 mx-5">
                        <span className="card-text">Account</span>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked={showContact}
                                onChange={handleToggle}
                            />
                        </div>
                        <span className="card-text mx-n1">Contact</span>
                    </div>
                </div>
            </div>

            {showContact ? (
                <>
                    <div className="card px-2">
                        <div
                            className="d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1"
                            data-bs-toggle="tooltip"
                            title={updateCustomer?.name}
                        >
                            <span
                                className="card-text"
                                data-bs-toggle="tooltip"
                                title="Name"
                            >
                                Name:
                            </span>
                            <div className="card-text text-truncate">
                                {updateCustomer?.name}
                            </div>
                        </div>
                    </div>

                    <div className="card px-2">
                        <div
                            className="d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1"
                            data-bs-toggle="tooltip"
                            title={updateCustomer?.email}
                        >
                            <i
                                className="bi bi-envelope fs-2"
                                data-bs-toggle="tooltip"
                                title="Email"
                            ></i>
                            <span>: </span>
                            <div className="card-text text-truncate">
                                {updateCustomer?.email}
                            </div>
                        </div>
                    </div>

                    <div className="card px-2">
                        <div
                            className="d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1"
                            data-bs-toggle="tooltip"
                            title={updateCustomer?.phone_number}
                        >
                            <i
                                className="bi bi-telephone fs-2"
                                
                            ></i>
                            <span>: </span>
                            <span
                            data-bs-toggle="tooltip"
                                title={gettingTelephonyStatus === "true" ? "Click to dial" : "Login telephony to call"}
                                className="text-truncate"
                                style={{ cursor: "pointer", color: "#2196F3" }}
                                onClick={() =>
                                    setLifitinMobileNumber(
                                        updateCustomer?.phone_number
                                    )
                                }
                            >
                                {updateCustomer?.phone_number}
                            </span>
                        </div>
                    </div>

                    <div className="card px-2">
                        <div
                            className="d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1"
                            data-bs-toggle="tooltip"
                            title={
                                updateCustomer?.whatsapp_number
                                    ? updateCustomer?.whatsapp_number
                                    : "NA"
                            }
                        >
                            <i
                                className="bi bi-whatsapp fs-2 cursor-pointer"
                                data-bs-toggle="tooltip"
                                title="Whatsapp"
                            ></i>
                            <span>: </span>
                            <span className="text-truncate cursor-pointer">
                                {updateCustomer?.whatsapp_number
                                    ? updateCustomer?.whatsapp_number
                                    : "NA"}
                            </span>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {/* Business Name */}
                    <div className="card px-2">
                        <div
                            className="d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1"
                            data-bs-toggle="tooltip"
                            title={tickets?.action?.contact?.account?.name}
                        >
                            <span
                                className="card-text "
                                data-bs-toggle="tooltip"
                                title="Business Name"
                            >
                                Business Name:
                            </span>
                            <div className="card-text text-truncate">
                                {tickets?.action?.contact?.account?.name ||
                                    "NA"}
                            </div>
                        </div>
                    </div>

                    {/* Tags */}
                    <div className="card px-2">
                        <div
                            className="d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1"
                            data-bs-toggle="tooltip"
                            title={
                                tickets?.action?.contact?.account?.tags
                                    ?.length > 0
                                    ? tickets.action.contact.account.tags
                                          .map((tag) => tag.name)
                                          .join(", ")
                                    : "NA"
                            }
                        >
                            <span
                                className="card-text"
                                data-bs-toggle="tooltip"
                                title="Tags"
                            >
                                Tags:
                            </span>
                            <div className="card-text text-truncate">
                                {tickets?.action?.contact?.account?.tags
                                    ?.length > 0
                                    ? tickets.action.contact.account.tags
                                          .map((tag) => tag.name)
                                          .join(", ")
                                    : "NA"}
                            </div>
                        </div>
                    </div>

                    {/* Team */}
                    <div className="card px-2">
                        <div
                            className="d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1"
                            data-bs-toggle="tooltip"
                            title={
                                tickets?.action?.contact?.account
                                    ?.accessible_teams?.length > 0
                                    ? tickets.action.contact.account.accessible_teams
                                          .map((team) => team.name)
                                          .join(", ")
                                    : "NA"
                            }
                        >
                            <span
                                className="card-text"
                                data-bs-toggle="tooltip"
                                title="Team"
                            >
                                Team:
                            </span>
                            <div
                                className="card-text text-truncate"
                                style={{
                                    maxWidth: "200px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                                title={
                                    tickets?.action?.contact?.account
                                        ?.accessible_teams?.length > 0
                                        ? tickets.action.contact.account.accessible_teams
                                              .map((team) => team.name)
                                              .join(", ")
                                        : "NA"
                                }
                            >
                                {tickets?.action?.contact?.account
                                    ?.accessible_teams?.length > 0
                                    ? tickets.action.contact.account.accessible_teams
                                          .map((team) => team.name)
                                          .join(", ")
                                    : "NA"}
                            </div>
                        </div>
                    </div>

                    {/* Accessible Teams */}
                </>
            )}
        </div>
    );
};

export default TicketLeftSideMenuCard;
