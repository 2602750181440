import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const TicketAccessControl = createApi({
  reducerPath: "ticketControl",
  refetchOnMountOrArgChange: true,

  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    ticketAccessControl: builder.query({
      query: (teamId) => ({
        url: `v1/tickets/ticket-access-control-get/?team_from=${teamId}&`,
        method: "GET",
      }),
    }),

    ticketUpdateControl: builder.mutation({
      query: ({ teamId, body }) => ({
        url: `v1/tickets/ticket-access-control/${teamId}/`,
        method: "PUT",
        body,
      }),
    }),

    ticketNewControl: builder.mutation({
      query: (body) => ({
        url: `v1/tickets/ticket-access-control/`,
        method: "POST",
        body,
      }),
    }),

    ticketControl: builder.query({
      query: (teamId) => ({
        url: `v1/tickets/ticket-access-control-get/?team_from=${teamId}&`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useTicketAccessControlQuery,
  useTicketUpdateControlMutation,
  useTicketNewControlMutation,
  useTicketControlQuery,
} = TicketAccessControl;
