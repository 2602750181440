import React from "react";
import { useDeleteCampaignMutation } from "../../../../Services/campaign/CampaignMapping";
import { CloseButton } from "react-bootstrap";
import { toast } from "react-toastify";

interface IProps {
  selectedCampaign: any;
  closeDeleteCampaignModal: () => void;
  refetch: any;
}

const DeleteCampaignModal: React.FC<IProps> = ({
  selectedCampaign,
  closeDeleteCampaignModal,
  refetch,
}) => {
  const [triggerDeleteCampaign] = useDeleteCampaignMutation(
    selectedCampaign.id
  );

  const handleDeleteCampaign = async () => {
    const id = selectedCampaign.id;
    if (id) {
      try {
        const response = await triggerDeleteCampaign({ id });
        if (response?.error) {
          toast.error("Unable to delete");
        } else {
          toast.success("Campaign deleted successfully");
          closeDeleteCampaignModal();
          refetch();
        }
      } catch (error) {
        toast.error("An error occurred while deleting the campaign");
      }
    } else {
      toast.error("Invalid ID, cannot delete campaign");
    }
  };

  return (
    <>
      <div className="text-end">
        <CloseButton onClick={closeDeleteCampaignModal} />
      </div>

      <div className="card-body">
        <p className="fs-5 fw-bold ">Are you sure?</p>
        <div className="card-text">
          <span className="text-danger">Caution</span> :{" "}
          <strong>
            Are you sure you want to delete the following campaign?
          </strong>
        </div>

        <div className="d-flex justify-content-end mt-10">
          <button
            type="reset"
            className="btn btn-sm btn-white me-2"
            onClick={() => closeDeleteCampaignModal()}
          >
            Cancel
          </button>

          <button
            type="submit"
            className="btn btn-sm btn-danger"
            onClick={handleDeleteCampaign}
          >
            Delete
          </button>
        </div>
      </div>
    </>
  );
};

export default DeleteCampaignModal;
