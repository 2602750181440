import React from 'react'

const ChatHistory = () => {
    return (

        <div className="pt-10">
            {/*begin::Timeline*/}
            <div className="circleOnetimeline-label">
                {/*begin::Item*/}
                <div className="timeline-item ms-16 d-flex align-items-center">
                    {/*begin::Label*/}
                    <div className="circleOnetimeline-label fw-bold text-gray-800 fs-6">
                        {/* 08:42 */}
                    </div>
                    {/*end::Label*/}

                    {/*begin::Badge*/}
                    <div className="timeline-badge">
                        <div className="bg-warning rounded-circle" style={{ padding: '10px' }}>
                            <i className="fa fa-envelope fs-6 text-white"></i>
                        </div>
                    </div>
                    {/*end::Badge*/}

                    {/*begin::Text*/}
                    <div className="fw-mormal timeline-content bg-secondary p-2 mx-10">
                        <span>My Order hasn't arrived yet</span>
                        <span className="text-primary px-2">REF-ID-0020-03309</span>
                        <span className="text-muted">25/06/2021 11:13Am</span>
                        <br />
                        <span className="text-muted">
                            I ordered the top in small size
                        </span>
                    </div>
                    {/*end::Text*/}
                </div>
                {/*end::Item*/}

                {/*begin::Item*/}
                <div className="timeline-item ms-15 d-flex align-items-center">
                    {/*begin::Label*/}
                    <div className="circleOnetimeline-label fw-bold text-gray-800 fs-6">
                        {/* 08:42 */}
                    </div>
                    {/*end::Label*/}

                    {/*begin::Badge*/}
                    <div className="timeline-badge">
                        <div className="bg-success rounded-circle" style={{ padding: '10px' }}>
                            <i className="bi bi-telephone text-white fs-6"></i>
                        </div>
                    </div>
                    {/*end::Badge*/}

                    {/*begin::Text*/}
                    <div className="fw-mormal timeline-content bg-secondary p-2 mx-10">
                        <span>My Order hasn't arrived yet</span>
                        <span className="text-primary px-2">REF-ID-0020-03309</span>
                        <span className="text-muted">25/06/2021 11:13Am</span>
                        <br />
                        <span className="text-muted">
                            I ordered the top in small size
                        </span>
                    </div>
                    {/*end::Text*/}
                </div>
                {/*end::Item*/}

                {/*begin::Item*/}
                <div className="timeline-item ms-15 d-flex align-items-center">
                    {/*begin::Label*/}
                    <div className="circleOnetimeline-label fw-bold text-gray-800 fs-6">
                        {/* 08:42 */}
                    </div>
                    {/*end::Label*/}

                    {/*begin::Badge*/}
                    <div className="timeline-badge">
                        <div className="bg-white border border-dark rounded-circle" style={{ padding: '10px' }}>
                            <i className="bi bi-chat-left-dots"></i>
                        </div>
                    </div>
                    {/*end::Badge*/}

                    {/*begin::Text*/}
                    <div className="fw-mormal timeline-content bg-secondary p-2 mx-10">
                        <span>My Order hasn't arrived yet</span>
                        <span className="text-primary px-2">REF-ID-0020-03309</span>
                        <span className="text-muted">25/06/2021 11:13Am</span>
                        <br />
                        <span className="text-muted">
                            I ordered the top in small size
                        </span>
                    </div>
                    {/*end::Text*/}
                </div>

                {/*begin::Item*/}
                <div className="timeline-item ms-15 d-flex align-items-center">
                    {/*begin::Label*/}
                    <div className="circleOnetimeline-label fw-bold text-gray-800 fs-6">
                        {/* 08:42 */}
                    </div>
                    {/*end::Label*/}

                    {/*begin::Badge*/}
                    <div className="timeline-badge">
                        <div className="bg-danger rounded-circle" style={{ padding: '10px' }}>
                            <i className="bi bi-telephone text-white fs-6"></i>
                        </div>
                    </div>
                    {/*end::Badge*/}

                    {/*begin::Text*/}
                    <div className="fw-mormal timeline-content bg-secondary p-2 mx-10">
                        <span>My Order hasn't arrived yet</span>
                        <span className="text-primary px-2">REF-ID-0020-03309</span>
                        <span className="text-muted">25/06/2021 11:13Am</span>
                        <br />
                        <span className="text-muted">
                            I ordered the top in small size
                        </span>
                    </div>
                    {/*end::Text*/}
                </div>
                {/*end::Item*/}
            </div>
            {/*end::Timeline*/}
            <div className="d-flex flex-row-reverse mt-4">
                <p className="text-muted">view more</p>
            </div>
        </div>

    )
}

export default ChatHistory