import React, { useState, useEffect } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Modal,
    Box,
    Button,
    Select,
    MenuItem,
    Checkbox,
    FormControl,
    InputLabel,
} from "@mui/material";
import { Loader } from "react-bootstrap-typeahead";
import { useWhatsAppConfigMutation } from "../../Services/campign/campignList";
import { toast } from "react-toastify";

interface IProps {
    closeBulkModal: () => void;
    selectedCampaign: any;
    refetch: any;
    campaignSelected:any
}

const BroadcastModal: React.FC<IProps> = ({
    closeBulkModal,
    selectedCampaign,
    refetch,
    campaignSelected
}) => {
    const [addUser, setAddUser] = useState(false);
    const [channel, setChannel] = useState<string>(""); // For channel selection
    const [isLoading, setIsLoading] = useState(false);

    const [haveDetails, setHaveDetails] = useState(false)
    const [haveLeads, setHaveLeads] = useState(false)

    

  const [whatsAppConfigTrigger, {status}] = useWhatsAppConfigMutation()

  useEffect(() => {
    if (status === "pending") {
        setIsLoading(true)
    }
    else {
        setIsLoading(false)
    }

  }, [status])


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 120,
            },
        },
    };

    console.log(selectedCampaign, "Selectedttdtd")
    console.log(campaignSelected, "amnaoaoaoaoa")

    const assignHandler = async () => {
        setIsLoading(true);
        try {
            // const payload = {
            //     selectedCampaign,
            //     channel,
            //     broadcastToAll: selectedCampaign.length === 0, // Broadcast to all leads if no campaign selected
            // };

            const whatsAppConfigObj = {
                lead_ids: selectedCampaign ? selectedCampaign : [],
                campaign:  campaignSelected
            }


         const response =  await whatsAppConfigTrigger(whatsAppConfigObj)

         console.log(response, "sdcdscsc")

         if (response?.data) {
            toast.success("Message sent successfully")

         }
         else if (response?.error) {
            toast.error("Failed to send message")

         }

            // console.log("Broadcast Payload:", payload);

            // Call API or perform logic here...

            refetch(); // Optionally refetch data
            closeBulkModal(); // Close modal after success

        } catch (error) {
            console.error("Error in broadcasting:", error);
        } finally {
            setIsLoading(false);
        }
    };


    console.log(selectedCampaign, "dcsdc")

    useEffect(() => {
        if (selectedCampaign.length > 1 ) {
            setHaveLeads(false)
        }
        else {
            setHaveLeads(true)
        }

    }, [selectedCampaign.length])

    console.log(haveLeads, "sdcscscdcs")


    return (
        <Modal
            open={true}
            onClose={closeBulkModal}
            aria-labelledby="broadcast-modal-title"
            aria-describedby="broadcast-modal-description"
        >
            <Box
                sx={{
                    padding: "1rem",
                    backgroundColor: "white",
                    margin: "auto",
                    width: "400px",
                    height: "350px",
                    borderRadius: "8px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    position: "absolute",
                    boxShadow: 24,
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        onClick={closeBulkModal}
                        style={{ minWidth: "auto", padding: "0.5rem" }}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </div>
                <div>

                    <div className="text-center mb-4"><label className="form-label fs-4 fw-bolder text-dark card-title">Broadcast campaign</label></div>
                    
                    <div className="mt-6">
                        <InputLabel>Channel</InputLabel>
                        <FormControl variant="standard" className="full-width w-100 mt-4">
                            <Select
                                value={channel}
                                onChange={(e) => {
                                    setChannel(e.target.value)
                                    setHaveDetails(true)
                                
                                }}
                                displayEmpty
                                style={{ paddingLeft: "1rem" }}
                            >
                                <MenuItem value="" disabled>
                                    Select Channel
                                </MenuItem>
                                <MenuItem value="whatsapp">WhatsApp</MenuItem>
                                <MenuItem value="email">Email</MenuItem>
                                {/* Add more channels if needed */}
                            </Select>
                        </FormControl>
                    </div>
                    {selectedCampaign.length === 0 && ( // Hide "Select All" checkbox if campaigns are selected
                        <div className="mt-4">
                            <Checkbox
                                className="example-margin"
                                checked={addUser}
                                onChange={() => {
                                    setAddUser(!addUser)
                                // setHaveLeads(true)
                                }}
                            />
                            Broadcast to all leads
                        </div>
                    )}
                </div>
                <div
                    style={{
                        position: "absolute",
                        bottom: ".6rem",
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "90%",
                    }}
                >
                    <Button
                        className="btn btn-primary w-100"
                        onClick={assignHandler}
                        disabled={!haveDetails }
                        // disabled={!channel || (selectedCampaign.length > 0 && !addUser)}
                    >
                        {isLoading ? (
                            <>
                                Broadcasting... <Loader />
                            </>
                        ) : (
                            "Broadcast"
                        )}
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};

export default BroadcastModal;
