import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import EmailReportsList from '../../../components/reports/emails/EmailReportsList'
import EmailAgentPerformanceReport from '../../../components/reports/emails/SelectedEmailList/EmailAgentPerformanceReport'
import EmailSlaReport from '../../../components/reports/emails/SelectedEmailList/EmailSlaReport'


const EmailRoute = () => {
    return (
        <Routes>
            <Route
                path='/reports-list'
                element={
                    <>
                        <EmailReportsList />
                    </>
                }
            />

            <Route
                path='/email-agent-performance-report'
                element={
                    <>
                      <EmailAgentPerformanceReport/>
                    </>
                }
            />
            <Route
                path='/email-sla-report'
                element={
                    <>
                       <EmailSlaReport/>
                    </>
                }
            />


            <Route index element={<Navigate to='/email/reports-list' />} />

        </Routes >
    )
}

export default EmailRoute
