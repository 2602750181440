import React from "react";
import { Container } from "react-bootstrap";

const Footer: React.FC = () => {
  return (
    <footer className="bg-white text-white text-center py-3 mt-auto">
      <Container>
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-bold me-2">
            &copy;{new Date().getFullYear()} &nbsp;
            <a
              href="https://botgo.io/"
              className="text-gray-500 text-hover-primary"
            >
              CircleOne CRM - Powered By BOTGO Technologies &nbsp;
            </a>
            All Rights Reserved
          </span>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
