import React, { useEffect, useState, useCallback } from "react";
import Interval1DateRange from "../Interval1DateRange";
import { useTicketIntervalQuery } from "../../../../Services/dashboard/dashboard";
import TicketVolumeChart from "./TicketVolumeChart";
import LoadingSpinner from "../../../../components/LoadingSpinner";


interface IProps {
  selectedWorkFlow: any;
  selectedTeam: any;
}

const TicketVolume: React.FC<IProps> = ({
  selectedWorkFlow,
  selectedTeam,
}) => {
  const [interval1Dates, setInterval1Dates] = useState<{
    startDate: string;
    endDate: string;
  } | null>(null);
  const [loading, setLoading] = useState(false);

  const { data: intervalData, refetch, isFetching } = useTicketIntervalQuery(
    {
      selectedWorkFlow,
      selectedTeam,
      graphStartDate: interval1Dates?.startDate,
      graphEndDate: interval1Dates?.endDate,
    },
    {
      skip:
        interval1Dates?.startDate === undefined ||
        interval1Dates?.endDate === undefined ||
        !selectedWorkFlow,
    }
  );

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleInterval1DateChange = useCallback(
    (startDate: string, endDate: string) => {
      setLoading(true); // Set loading to true when date changes
      setInterval1Dates({
        startDate: formatDate(new Date(startDate)),
        endDate: formatDate(new Date(endDate)),
      });
    },
    []
  );

  useEffect(() => {
    // Update loading state based on data fetching status
    if (!isFetching) {
      setLoading(false); // Set loading to false when data fetch is complete
    }
  }, [isFetching]);

  return (
    <div className="card card-xxl-stretch">
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="fs-2 fw-bold text-gray-800 me-2 lh-1 ls-n2">
            Total volume
          </span>
        </h3>

        <div className="card-toolbar d-flex align-items-center">
          <Interval1DateRange onDateChange={handleInterval1DateChange} />
        </div>
      </div>

      {loading ? (
        <LoadingSpinner /> // Display the spinner while loading
      ) : (
        <div>
          <TicketVolumeChart intervalData={intervalData} />
        </div>
      )}
    </div>
  );
};

export default TicketVolume;
