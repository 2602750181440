import React from "react";
import { toast } from "react-toastify";
import { useUserDeleteTeamMutation } from "../../../../Services/settingsAPIs/CommonAPI";

interface IPROPS {
      closeModal: any;
      setTheTeams: any;
      theTeams: any;
      storingFieldData: any;
      refetch:any
}

const DeleteTeamModal: React.FC<IPROPS> = ({
      closeModal,
      theTeams,
      setTheTeams,
      storingFieldData,
      refetch
}) => {
      const [trigger] = useUserDeleteTeamMutation();

      const deleteFieldHandler = async () => {
            const id = storingFieldData.id;

            if (id) {
                  try {
                       
                       const response =  await trigger({ id });
              
                       if (response.data) {
                           toast.success("Data deleted successfully");
                           refetch()
                           closeModal(true);

                       } else {
                        toast.error("An error occured")
                       }
                  } catch (error) {
                        console.error("Error deleting data:", error);
                        toast.error(
                              "An error occurred while deleting the data"
                        );
                  }
            } else {
             
                  toast.error("Invalid ID, cannot delete data");
            }
      };

      return (
            <div
                  style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "center",
                  }}
            >
                  <div
                        style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "end",
                        }}
                  >
                        <i
                              style={{
                                    fontSize: "26px",
                                    color: "black",
                                    cursor: "pointer",
                              }}
                              className="bi bi-x"
                              onClick={() => closeModal(true)}
                        ></i>
                  </div>
                  <div style={{ color: "#3071e4", fontSize: "16px" }}>
                        Are you sure?
                  </div>
                  <div style={{ marginBottom: "15px" }}>
                        Do you really want to delete these records? This process
                        cannot be undone.
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                        <button
                              className="btn btn-danger"
                              onClick={deleteFieldHandler}
                        >
                              Delete
                        </button>
                        <button
                              className="btn btn-light"
                              onClick={() => closeModal(true)}
                        >
                              Cancel
                        </button>
                  </div>
            </div>
      );
};

export default DeleteTeamModal;