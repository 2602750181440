import * as React from "react";
import { Pagination, PaginationItem } from "@mui/material";
import {
  useDeleteAccountInfoMutation,
  useGetAccountInfoQuery,
} from "../../../Services/customer/FrontendCustomer";
import { useNavigate } from "react-router-dom";
import ReactModal from "react-modal";
import LoadingSpinner from "../../LoadingSpinner";
import { formatDate } from "../../reusableComponent/DateFormat";
import DeleteContactModal from "../contact/DeleteContactModal";
import EmptyRows from "../../reusableComponent/EmptyRows";
import { useState } from "react";

interface IPROPS {
  setLiftingColumn: any;
  accountCardLoading: any;
  accountData: any;
  totalCount: any;
  refetch: any;
  setPage: any;
  page: any;
  AccountQueryLoading: any;
}

const AccountCards: React.FC<IPROPS> = ({
  setLiftingColumn,
  accountCardLoading,
  accountData,
  totalCount,
  refetch,
  setPage,
  page,
  AccountQueryLoading,
}) => {
  const [accountsData, setAccountData] = React.useState<any[]>([]);
  const [deleteButtonClicked, setDeleteButtonClicked] =
    React.useState<boolean>(false);
  const [storedData, setStoredData] = React.useState<any>(null);
  const [isEditAccountDetailsEnable, setIsEditAccountDetailsEnable] =
    useState(false);

  const rowsPerPage = 20;

  const [trigger] = useDeleteAccountInfoMutation();

  const navigate = useNavigate();

  const deleteHandler = (row: any) => {
    setDeleteButtonClicked(true);
    setStoredData(row);
  };

  const closeDeleteModal = () => {
    setDeleteButtonClicked(false);
  };

  const editHandler = (row: any) => {
    // Set the state to false and store in local storage
    setIsEditAccountDetailsEnable(false);
    localStorage.setItem("isEditAccountDetailsEnable", JSON.stringify(false));
    navigate(`/customer/info/${row.id}`, { state: row });
  };

  const editAccountEnable = (row: any) => {
    // Set the state to true and store in local storage
    setIsEditAccountDetailsEnable(true);
    localStorage.setItem("isEditAccountDetailsEnable", JSON.stringify(true));
    navigate(`/customer/info/${row.id}`, { state: row });
  };

  const customModalStyles1: ReactModal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-40%, -50%)",
      width: "40%",
    },
  };

  const capitalizeFirstLetter = (string: string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const getRandomColor = () => {
    const colors = [
      "bg-primary",
      "bg-success",
      "bg-danger",
      "bg-warning",
      "bg-dark",
    ];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const getInitials = (name: string) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    if (nameParts.length === 1) return nameParts[0].charAt(0).toUpperCase();
    return (
      nameParts[0].charAt(0).toUpperCase() +
      nameParts[1].charAt(0).toUpperCase()
    );
  };

  return (
    <>
      <div className="row">
        <div className="col" style={{ padding: "5px" }}>
          {accountCardLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {!accountCardLoading && accountData?.length ? (
                <>
                  {accountData?.map((row: any, index: any) => (
                    <div
                      className="card mt-2 my-1 p-4"
                      key={index}
                      onClick={() => editHandler(row)}
                    >
                      <div className="row align-items-start">
                        <div className="col text-start">
                          <div className="symbol symbol-25px symbol-circle fs-2 me-3">
                            <span
                              className={`symbol-label ${getRandomColor()} text-inverse-warning fw-bold`}
                            >
                              {getInitials(row?.name)}
                            </span>
                          </div>
                          <label htmlFor="">
                            {capitalizeFirstLetter(row?.name)}
                          </label>
                        </div>

                        <div className="col text-center">
                          <label htmlFor="">{formatDate(row.created)}</label>
                        </div>

                        <div className="col text-center">
                          <label htmlFor="">
                            {capitalizeFirstLetter(
                              row.tags?.map((tag: any) => tag.name).join(", ")
                            )}
                          </label>
                        </div>

                        <div
                          className="col text-center"
                          style={{
                            gap: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            className="text-hover-primary border-0  me-4"
                            onClick={(event) => {
                              event.stopPropagation();
                              editAccountEnable(row);
                            }}
                            style={{ background: "transparent" }}
                          >
                            <i
                              data-placement="top"
                              title={
                                !isEditAccountDetailsEnable ? "Edit" : "Disable"
                              }
                              className="bi bi-pencil text-dark fs-4"
                              style={{
                                cursor: "pointer",
                                background: "transparent",
                                fontSize: "100px",
                              }}
                            ></i>
                          </button>

                          <button
                            className="text-hover-danger border-0 bg-white me-2"
                            onClick={(event) => {
                              event.stopPropagation();
                              deleteHandler(row);
                            }}
                          >
                            <i
                              data-placement="top"
                              title="Delete"
                              className="bi bi-trash text-dark fs-4"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <EmptyRows value="" />
              )}
            </>
          )}
        </div>
      </div>

      <Pagination
        sx={{
          display: "flex",
          justifyContent: "end",
          color: "blue",
        }}
        count={Math.ceil(totalCount / rowsPerPage)}
        page={page}
        color="primary"
        onChange={handlePageChange}
        renderItem={(item) => {
          if (item.type === "page") {
            if (
              (page === 1 && item.page <= 3) ||
              (page === Math.ceil(totalCount / rowsPerPage) &&
                item.page >= Math.ceil(totalCount / rowsPerPage) - 2) ||
              (item.page >= page - 1 && item.page <= page + 1)
            ) {
              return <PaginationItem {...item} />;
            }
            return null;
          }
          return <PaginationItem {...item} />;
        }}
      />

      <ReactModal
        isOpen={deleteButtonClicked}
        onRequestClose={closeDeleteModal}
        style={customModalStyles1}
      >
        <DeleteContactModal
          closeModal={closeDeleteModal}
          setFieldsData={setAccountData}
          fieldsData={accountsData}
          storingFieldData={storedData}
          refetch={refetch}
          trigger={trigger}
        />
      </ReactModal>
    </>
  );
};

export default AccountCards;
