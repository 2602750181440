import React, { createRef, useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import TasksActionMenu from "../common/tabbedPanel/TasksActionMenu";
import {
    Box,
    Tabs,
    Tab,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Checkbox,
    Tooltip,
} from "@mui/material";
import { capitalizeFirstLetter } from "../../components/reusableComponent/CapitalLetter";
import { format } from "date-fns"; // Import format from date-fns .
import LoadingSpinner from "../../components/LoadingSpinner";
import EmptyRows from "../../components/reusableComponent/EmptyRows";
import ReactModal from "react-modal";
import ChangeStatusCampaignModal from "../../pages/campaign/leads/ChangeStatusCampaignModal";
import {
    useChangeStatusQuery,
    useMeeQuery,
} from "../../Services/settingsAPIs/CommonAPI";
import { useCampaignChangeStatusQuery } from "../../Services/campign/campignList";
import { maskPhoneNumber } from "../reusableComponent/MaskedFields";
interface IProps {
    listOfLeads: any;
    taskRes: any;
    CampaignWorkflow: any;
    setStoreKey: any;
    setValue: any;
    value: any;
    currentPage;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    useData: any;
    campign: any;
    setSelectedTickets;
    selectedTickets: any;
    setLiftUiData: any;
    refetch: any;
    selectedWorkFlow;
    selectCampaign;
    selectedOptions;
    checkTenant
}

const customModalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "450px",
    },
};

const CampaignListCardView: React.FC<IProps> = ({
    listOfLeads,
    taskRes,
    CampaignWorkflow,
    setStoreKey,
    setValue,
    value,
    currentPage,
    setCurrentPage,
    campign,
    setSelectedTickets,
    selectedTickets,
    setLiftUiData,
    refetch,
    selectedWorkFlow,
    selectCampaign,
    selectedOptions,
    checkTenant
}) => {
    const taskResActionMenuRef = useRef<any[]>([]);
    taskResActionMenuRef.current = taskRes.map(
        (item: any, i: number) => taskResActionMenuRef.current[i] ?? createRef()
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number>(5);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [selectAll, setSelectAll] = useState(false);
    const [uiData, setUiData] = useState([]);
    const [keyState, setKeyState] = useState([]);
    const [tabLoading, setTabLoading] = useState<boolean>(false); // New state variable to track tab loading
    const [selectedStatus, setSelectedStatus] = useState("");
    const [storingTicketId, setStoringTicketId] = useState("");
    const [storingDispositionId, setStoringDispositionId] = useState("");
    const [originalStatus, setOriginalStatus] = useState("");
    const [isStatusModalOpen, setStatusModalOpen] = useState(false);
    const [selectedAllStatus, setAllSelectedStatus] = useState("");

    const { data: campaignData, refetch: refetchAuditTrail } =
        useCampaignChangeStatusQuery(selectCampaign);

    console.log(campign, "sdcdcscs");

    const checkUserRole = localStorage.getItem("user_role");

    const navigate = useNavigate();
    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };
    const generatePaginationNumbers = () => {
        const numbers = [];
        const maxVisibleNumbers = 5;

        if (totalPages <= maxVisibleNumbers) {
            for (let i = 1; i <= totalPages; i++) {
                numbers.push(i);
            }
        } else {
            const middleIndex = Math.ceil(maxVisibleNumbers / 2);
            let startPage = currentPage - middleIndex + 1;
            let endPage = currentPage + middleIndex - 1;

            if (startPage < 1) {
                endPage = startPage + maxVisibleNumbers - 1;
                startPage = 1;
            } else if (endPage > totalPages) {
                startPage = endPage - maxVisibleNumbers + 1;
                endPage = totalPages;
            }

            for (let i = startPage; i <= endPage; i++) {
                numbers.push(i);
            }
        }

        return numbers;
    };
    const paginate = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setTotalPages(Math.ceil(campign?.count / pageSize));
        }, 3000);
    }, [currentPage, campign, pageSize]);

    const truncateDescription = (description, maxLength) => {
        if (description?.length > maxLength) {
            return description?.slice(0, maxLength) + "...";
        }
        return description;
    };
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return format(date, "dd-MM-yyyy HH:mm:ss");
    };

    const getStatusStyle = (status_type: string) => {
        switch (status_type) {
            case "active":
                return { backgroundColor: "#56d85b", color: "white" }; // $green-300
            case "closed":
                return { backgroundColor: "#f44336", color: "white" }; // $yellow-200 // $cyan-300     { backgroundColor: "#90dcf9", color: "white" };
            case "reopen":
                return { backgroundColor: "#56d85b", color: "white" }; // $red-200   { backgroundColor: "#f44336", color: "white" };
            case "wip":
                return { backgroundColor: "#ffeb3b", color: "black" }; // $yellow-200  { backgroundColor: "#ffeb3b", color: "black" };
            default:
                return { backgroundColor: "#6c757d", color: "white" }; // Default color
        }
    };

    const capitalizeFirstLetter = (string: string | undefined | null) => {
        if (!string) {
            return "";
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    useEffect(() => {
        setLiftUiData(uiData);
    }, [uiData]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 120,
            },
        },
    };
    const processData = async () => {
        const uiFormattedData = [];
        const keysForState = [];

        // const [selectedTab, setSelectedTab] = useState<number>(0);
        if (CampaignWorkflow && typeof CampaignWorkflow === "object") {
            await Object.entries(CampaignWorkflow)?.forEach(([key, value]) => {
                if (
                    typeof value === "object" &&
                    value !== null &&
                    !Array.isArray(value)
                ) {
                    // Handle objects like { Open: 8 }
                    Object.entries(value).forEach(
                        ([nestedKey, nestedValue]) => {
                            uiFormattedData.push({
                                key: nestedKey,
                                value: nestedValue,
                            });
                            keysForState.push(key);
                        }
                    );
                } else if (Array.isArray(value)) {
                    // Handle arrays of objects [{ Closed: 1 }, { ss: 0 }]
                    value.forEach((item) => {
                        if (item && typeof item === "object") {
                            Object.entries(item).forEach(
                                ([nestedKey, nestedValue]) => {
                                    uiFormattedData.push({
                                        key: nestedKey,
                                        value: nestedValue,
                                    });
                                    keysForState.push(key);
                                }
                            );
                        }
                    });
                }
            });

            setUiData(uiFormattedData);
            setKeyState(keysForState);
        } else {
            console.error("Camapign is undefined or null");
        }
    };
    useEffect(() => {
        processData();
    }, [CampaignWorkflow]);

    const handleCampignSelect = (CamapinId: string) => {
        setSelectedTickets((prevSelectedTickets) => {
            const isSelected = prevSelectedTickets.includes(CamapinId);
            if (isSelected) {
                // Unselect the ticket
                const updatedSelection = prevSelectedTickets.filter(
                    (id) => id !== CamapinId
                );
                return updatedSelection;
            } else {
                // Select the ticket
                const updatedSelection = [...prevSelectedTickets, CamapinId];

                return updatedSelection;
            }
        });
    };
    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectAll(event.target.checked);
        if (event.target.checked && campign?.results.length > 0) {
            setSelectedTickets(campign?.results.map((row) => row.id));
        } else {
            setSelectedTickets([]);
        }
    };
    useEffect(() => {
        if (campign?.results.length === 0) {
            setSelectAll(false); // "Select All" checkbox is unchecked if there are no results
        } else if (selectedTickets.length === campign?.results.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [selectedTickets]);

    const handleStatusChangeeee = (campignId) => {
        setStoringDispositionId(campignId?.campaign?.campaign_name?.id);
        setStoringTicketId(campignId?.id);
    };

    const openStatusModal = () => {
        setOriginalStatus(selectedStatus);
        setStatusModalOpen(true);
    };

    const closeStatusModal = () => {
        setSelectedStatus(originalStatus);
        setStatusModalOpen(false);
    };

    const handlerStatus = (allStatus) => {
        setAllSelectedStatus(allStatus);
        setSelectedStatus(allStatus.status_type);
        openStatusModal();
    };

    const formatStatusData = (status) => {
        if (typeof status.status === "string") {
            // Open and Reopen statuses
            return {
                status_type: status.status_type,
                status: status.status,
            };
        } else {
            // WIP and Closed statuses
            return {
                id: status.status.id,
                status_type: status.status_type,
                status: status.status.status,
            };
        }
    };

    const statusOptions = campaignData?.results[0]?.all_statuses?.map(
        (status) => formatStatusData(status)
    );
    useEffect(() => {
        const defaultSelections = campign?.results?.reduce(
            (acc: any, status: any) => {
                acc[status?.id] = status?.status_display || ""; // Assuming 'id' and 'status_display' exist
                return acc;
            },
            {}
        );

        setSelectedStatus(defaultSelections || {});
    }, [campign]);

    const handleStatusUpdate = () => {
        // logic to handle status update goes here
        setStatusModalOpen(false);
    };

    return (
        <>
            {loading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <div
                        className="nav nav-stretch nav-line-tabs text-primary border-transparent d-flex mb-5"
                        style={{ padding: "0 2.25rem", display: "flex" }}
                    >
                        <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                        />
                        <Box
                            sx={{
                                width: "96%",
                                background: "#FFFFFF",
                                borderRadius: "6px",
                                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)", // subtle shadow for better elevation
                                marginLeft: "30px",
                            }}
                        >
                            <Tabs
                                value={uiData.findIndex(
                                    (item) => item.key === value
                                )}
                                onChange={(event, newValue) => {
                                    setStoreKey(keyState[newValue]);
                                    setValue(uiData[newValue].key);
                                    setCurrentPage(1);
                                }}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                                sx={{
                                    "& .MuiTab-root": {
                                        minWidth: 0, // Set minimum width to 0
                                        flexGrow: 1, // Add flexGrow to make tabs responsive
                                        padding: "12px 24px",
                                        textTransform: "none",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                    },
                                    "& .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabScrollButton-horizontal.Mui-disabled.MuiTabs-scrollButtons.MuiTabs-scrollButtonsHideMobile.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root":
                                        {
                                            display: "none",
                                        },
                                    "& .MuiTab-root:not(:last-child)": {
                                        borderRight: "1px solid #e0e0e0", // Thicker, more pronounced divider
                                    },
                                    "& .MuiTab-root:hover": {
                                        backgroundColor: "#f5f5f5", // Adds a hover effect for better interaction
                                    },
                                    "& .Mui-selected": {
                                        fontWeight: 600, // Highlights selected tab more
                                    },
                                }}
                            >
                                {uiData?.map((ticketsInfo, index) => (
                                    <Tooltip
                                        key={index}
                                        title={`${ticketsInfo.key} (${ticketsInfo.value})`}
                                        arrow
                                    >
                                        <Tab
                                            key={index}
                                            label={truncateDescription(
                                                `${ticketsInfo.key} (${ticketsInfo.value})`,
                                                25
                                            )}
                                            sx={{
                                                width: "20%", // Ensure all tabs take full width
                                                maxWidth: "none", // Remove max width restriction
                                                display: "flex", // Ensure flexibility
                                                justifyContent: "center", // Center the text
                                            }}
                                        />
                                    </Tooltip>
                                ))}
                            </Tabs>
                        </Box>
                    </div>

                    {tabLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <div
                            className="card-header align-items-center py-1 my-1 gap-2 gap-md-5 d-flex flex-column flex-md-row mb-5"
                            style={{ border: "none" }}
                        >
                            {campign?.results?.map((campign: any) => (
                                <div
                                    key={campign?.id}
                                    className="d-flex align-items-center w-100 mb-2"
                                >
                                    <input
                                        type="checkbox"
                                        checked={selectedTickets.includes(
                                            campign?.id
                                        )}
                                        onChange={() =>
                                            handleCampignSelect(campign?.id)
                                        }
                                    />

                                    <div className="col-xl-12 mx-auto">
                                        {/* <Link to={`/ticket/information/${campign?.id}`}> */}
                                        <Link
                                            to={`/campaign/lead/${campign?.id}`}
                                        >
                                            <div className="card-body">
                                                <div className="card mb-n10">
                                                    <div>
                                                        <div className="d-flex flex-stack mb-2">
                                                            <div className="d-flex flex-column w-100">
                                                                {/* REF ID Section */}
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="p-2 text-gray-800 text-hover-primary fw-bold">
                                                                            {
                                                                                campign.id
                                                                            }
                                                                        </div>
                                                                        <div className="p-2 fs-3 text-gray-800 text-hover-primary fw-bold">
                                                                            {
                                                                                campign.title
                                                                            }
                                                                        </div>
                                                                        {/* <i className="fs-2 mx-2 bi text-dark"></i> */}
                                                                        <i
                                                                            className="bi bi-menu-up fs-2 me-2 text-dark"
                                                                            style={{
                                                                                paddingTop:
                                                                                    "13px",
                                                                            }}
                                                                        ></i>
                                                                    </div>
                                                                    {/* end::REF ID Section */}

                                                                    {/* Conditionally render Add Attachment Icon Button */}
                                                                    {campign
                                                                        .attachments
                                                                        ?.length >
                                                                        0 && (
                                                                        <button className="btn text-dark justify-content-center">
                                                                            <i
                                                                                className="bi bi-paperclip fs-1 text-dark"
                                                                                style={{
                                                                                    transform:
                                                                                        "rotate(90deg)",
                                                                                }}
                                                                            ></i>
                                                                        </button>
                                                                    )}
                                                                    {checkTenant === "meta" && 
                                                                    <div className="d-flex align-items-center justify-content-between mt-2">
                                                                        <div>
                                                                            {campign.broadcast_msg ===
                                                                            true ? (
                                                                                <i
                                                                                    className="bi bi-check-circle-fill text-success"
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "1.2rem",
                                                                                    }}
                                                                                    title="Message Sent Successfully"
                                                                                ></i>
                                                                            ) : campign.broadcast_msg ===
                                                                              "false" ? (
                                                                                <i
                                                                                    className="bi bi-x-circle-fill text-danger"
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "1.2rem",
                                                                                    }}
                                                                                    title="Message Sending Failed"
                                                                                ></i>
                                                                            ) : (
                                                                                <i
                                                                                    className="bi bi-dash-circle-fill text-muted"
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "1.2rem",
                                                                                    }}
                                                                                    title="Message Not Sent"
                                                                                ></i>
                                                                            )}
                                                                        </div>
                                                                    </div>
                            }
                                                                </div>
                                                                {/* end::Wrapper */}

                                                                {/* New Line */}
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <div className="d-flex align-items-center">
                                                                        <div
                                                                            className="p-0"
                                                                            style={{
                                                                                ...getStatusStyle(
                                                                                    campign.status_type
                                                                                ),
                                                                                width: "150px",
                                                                                textAlign:
                                                                                    "center",
                                                                            }}
                                                                            data-bs-toggle="tooltip"
                                                                            title={
                                                                                campign?.status_display
                                                                            }
                                                                        >
                                                                            <span className="fw-bold">
                                                                                {truncateDescription(
                                                                                    campign.status_display,
                                                                                    10
                                                                                )}
                                                                            </span>
                                                                        </div>

                                                                        <div
                                                                            className="text-gray-400 text-hover-primary fw-bold mx-10"
                                                                            data-bs-toggle="tooltip"
                                                                            title={
                                                                                campign?.description
                                                                            }
                                                                        >
                                                                            {truncateDescription(
                                                                                campign?.description,
                                                                                120
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="card"
                                                        style={{
                                                            backgroundColor:
                                                                "#E4E4E47F",
                                                        }}
                                                    >
                                                        <div className="d-flex flex-wrap">
                                                            <div
                                                                style={{
                                                                    borderRight:
                                                                        "1px solid rgba(112, 112, 112, 0.5)",
                                                                    width: "150px",
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
                                                                    Assignee
                                                                </div>
                                                                <span className="text-center text-gray-800 text-hover-primary fw-bold">
                                                                    {capitalizeFirstLetter(
                                                                        campign
                                                                            ?.assignee
                                                                            ?.username
                                                                    )}
                                                                </span>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    borderRight:
                                                                        "1px solid rgba(112, 112, 112, 0.5)",
                                                                    width: "150px",
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
                                                                    Account name
                                                                </div>
                                                                <span className="text-gray-800 text-hover-primary fw-bold">
                                                                    {capitalizeFirstLetter(
                                                                        campign?.account_name
                                                                    )}
                                                                </span>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    borderRight:
                                                                        "1px solid rgba(112, 112, 112, 0.5)",
                                                                    width: "150px",
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
                                                                    Created by
                                                                </div>
                                                                <span className="text-gray-800 text-hover-primary fw-bold">
                                                                    {
                                                                        campign
                                                                            ?.created_by
                                                                            ?.username
                                                                    }
                                                                </span>
                                                            </div>

                                                            {/* <div
                                style={{
                                  borderRight:
                                    "1px solid rgba(112, 112, 112, 0.5)",
                                  width: "120px",
                                  textAlign: "center",
                                }}
                              >
                                <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
                                  Priority
                                </div>
                                <span className="text-gray-800 text-hover-primary fw-bold">
                                  {capitalizeFirstLetter(campign?.priority)} 
                                </span>
                              </div> */}

                                                            <div
                                                                style={{
                                                                    borderRight:
                                                                        "1px solid rgba(112, 112, 112, 0.5)",
                                                                    width: "120px",
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
                                                                    Workflow
                                                                </div>
                                                                <span className="text-gray-800 text-hover-primary fw-bold">
                                                                    {
                                                                        campign
                                                                            ?.campaign
                                                                            ?.campaign_name
                                                                            ?.name
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    borderRight:
                                                                        "1px solid rgba(112, 112, 112, 0.5)",
                                                                    width: "150px",
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
                                                                    Created at
                                                                </div>
                                                                <span className="text-gray-800 text-hover-primary fw-bold">
                                                                    {formatDate(
                                                                        campign?.created
                                                                    )}
                                                                </span>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    borderRight:
                                                                        "1px solid rgba(112, 112, 112, 0.5)",
                                                                    width: "150px",
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
                                                                    Current team
                                                                </div>
                                                                <span className="text-gray-800 text-hover-primary fw-bold">
                                                                    {
                                                                        campign
                                                                            ?.campaign
                                                                            ?.team
                                                                            ?.name
                                                                    }
                                                                </span>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    borderRight:
                                                                        "1px solid rgba(112, 112, 112, 0.5)",
                                                                    width: "150px",
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
                                                                    Mobile no.
                                                                </div>
                                                                <span className="text-gray-800 text-hover-primary fw-bold">
                                                                    {checkUserRole ===
                                                                    "agent"
                                                                        ? maskPhoneNumber(
                                                                              campign?.phone_number
                                                                          )
                                                                        : campign?.phone_number}
                                                                </span>
                                                            </div>

                                                            {/* Change Status  */}
                                                            <div
                                                                className="text-center d-flex align-items-center mx-5"
                                                                style={{
                                                                    minWidth:
                                                                        "180px",
                                                                }}
                                                            >
                                                                <FormControl
                                                                    className="w-100"
                                                                    onClick={(
                                                                        event
                                                                    ) =>
                                                                        event.stopPropagation()
                                                                    }
                                                                >
                                                                    <InputLabel
                                                                        id="status-select-label"
                                                                        style={{
                                                                            zIndex: 0,
                                                                        }}
                                                                    >
                                                                        Change
                                                                        Status
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId={`status-select-label-${campign.id}`}
                                                                        id={`status-select-${campign.id}`}
                                                                        value={
                                                                            selectedStatus[
                                                                                campign
                                                                                    .id
                                                                            ] ||
                                                                            ""
                                                                        } // Use the selected status for this Campaign
                                                                        onChange={() =>
                                                                            handleStatusChangeeee(
                                                                                campign
                                                                            )
                                                                        }
                                                                        label="Change Status"
                                                                        MenuProps={
                                                                            MenuProps
                                                                        }
                                                                    >
                                                                        {statusOptions?.map(
                                                                            (
                                                                                status: any
                                                                            ) => (
                                                                                <MenuItem
                                                                                    key={
                                                                                        status.id
                                                                                    }
                                                                                    value={
                                                                                        status.status
                                                                                    }
                                                                                    onClick={() =>
                                                                                        handlerStatus(
                                                                                            status
                                                                                        )
                                                                                    } // Existing click handler
                                                                                    data-bs-toggle="tooltip"
                                                                                    title={
                                                                                        status?.status
                                                                                    }
                                                                                >
                                                                                    {truncateDescription(
                                                                                        status?.status,
                                                                                        25
                                                                                    )}
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>

                                                                    <ReactModal
                                                                        isOpen={
                                                                            isStatusModalOpen
                                                                        }
                                                                        onRequestClose={
                                                                            closeStatusModal
                                                                        }
                                                                        style={
                                                                            customModalStyles
                                                                        }
                                                                        contentLabel="Change Status"
                                                                    >
                                                                        <ChangeStatusCampaignModal
                                                                            data={
                                                                                campaignData
                                                                            }
                                                                            closeStatusModal={
                                                                                closeStatusModal
                                                                            }
                                                                            refetch={
                                                                                refetch
                                                                            }
                                                                            refetchAuditTrail={
                                                                                refetchAuditTrail
                                                                            }
                                                                            selectedStatus={
                                                                                selectedStatus
                                                                            }
                                                                            handleStatusUpdate={
                                                                                handleStatusUpdate
                                                                            }
                                                                            // refetchAuditTrailData={
                                                                            //   refetchAuditTrailData
                                                                            // }
                                                                            selectedAllStatus={
                                                                                selectedAllStatus
                                                                            }
                                                                            storingTicketId={
                                                                                storingTicketId
                                                                            }
                                                                            //  refetchAuditTrail={undefined}
                                                                            refetchAuditTrailData={
                                                                                undefined
                                                                            }
                                                                        />
                                                                    </ReactModal>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
            <div className="row mx-4 pt-2 mb-2">
                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                    {/* Display the page count */}
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                </div>
                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="kt_table_users_paginate"
                    >
                        <ul className="pagination">
                            {/* Previous button */}
                            <li
                                className={`paginate_button page-item ${
                                    currentPage === 1 ? "disabled" : ""
                                }`}
                            >
                                <button
                                    type="button"
                                    aria-controls="kt_table_users"
                                    data-dt-idx="0"
                                    className="btn primary page-link"
                                    onClick={prevPage}
                                >
                                    <i className="previous"></i>
                                </button>
                            </li>

                            {/* Page numbers */}
                            {generatePaginationNumbers().map((pageNumber) => (
                                <li
                                    key={pageNumber}
                                    className={`paginate_button page-item ${
                                        currentPage === pageNumber
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <button
                                        type="button"
                                        aria-controls="kt_table_users"
                                        data-dt-idx={pageNumber}
                                        className="btn primary page-link"
                                        onClick={() => paginate(pageNumber)}
                                    >
                                        {pageNumber}
                                    </button>
                                </li>
                            ))}

                            {/* Next button */}
                            <li
                                className={`paginate_button page-item ${
                                    currentPage === totalPages ? "disabled" : ""
                                }`}
                            >
                                <button
                                    type="button"
                                    aria-controls="kt_table_users"
                                    data-dt-idx="0"
                                    className="btn primary page-link"
                                    onClick={nextPage}
                                >
                                    <i className="next"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CampaignListCardView;