import React, { useState } from "react";
import {
    useEditCheckBoxMutation,
    useEditDropwDownMutation,
} from "../../../../Services/settingsAPIs/CommonAPI";
import { toast } from "react-toastify";
import {
    useAddChoiceToDynamicFieldDDMutation,
    useAddOptionToDynamicFieldCheckboxMutation,
    useDeleteTicketFieldChoiceMutation,
} from "../../../../Services/settingsAPIs/TicketSettingsAPI";
import NestedDDConf from "../../common/NestedDDConf";
import { toSentenceCase } from "../../../../utils/functions/toSentenceCase";
import { useDrag, useDrop } from "react-dnd";
import PagenationUtility from "../../common/PagenationUtility";

interface LiftCheckOption {
    choice?: string;
    fieldId?: string;
}

interface Field {
    label: string;
    field_type: string;
    is_required: boolean;
    is_unique: boolean;
    is_masked: boolean;
    is_editable: boolean;
    id: any;
    choices: any;
    multiple_choices: any;
}

interface DraggableFieldProps {
    field: Field;
    index: number;
    moveField: (fromIndex: number, toIndex: number) => void;
    editFieldsHandler: (field: Field) => void;
    deleteDataHandler: (field: Field) => void;
    expandForMoreSpecifications: any;
    setExpandForMoreSpecifications: any;
    setEditDropdownListItem: any;
    editDropdownListItem: any;
    refetch: any;
    setLiftingDropData: any;
    setTicketFields: any;
    openDeleteTicketFieldModal: any;
    currentData: any;
    workflowSelected: any;
    ticketFields: any;
    setCurrentData: any;
}

const DragTicketField: React.FC<DraggableFieldProps> = ({
    setExpandForMoreSpecifications,
    expandForMoreSpecifications,
    field,
    index,
    moveField,
    editFieldsHandler,
    deleteDataHandler,
    setEditDropdownListItem,
    editDropdownListItem,
    refetch,
    setLiftingDropData,
    setTicketFields,
    openDeleteTicketFieldModal,
    currentData,
    workflowSelected,
    ticketFields,
    setCurrentData,
}) => {
    const [newDropdownListItem, setNewDropdownListItem] = useState("");

    const [editCheckboxListOption, setEditCheckboxListOption] = useState({
        enabled: false,
        forChoiceId: -1,
        currentVal: "",
        fieldId: "",
    });
    const [newCheckboxOption, setNewCheckboxOption] = useState("");
    const [fieldForDeletion, setFieldForDeletion] = useState<number>();

    const [isDeleteTicketFieldModalOpen, setIsDeleteTicketFieldModalOpen] =
        useState<boolean>(false);
    const ItemType = "FIELD";

    const [triggerEditDrowDown] = useEditDropwDownMutation();
    const [triggerAddOptionToCheckboxField] =
        useAddOptionToDynamicFieldCheckboxMutation();
    const [triggerEditCheckbox] = useEditCheckBoxMutation();

    const [triggerDeleteChoiceFromTicketField] =
        useDeleteTicketFieldChoiceMutation();
    const [triggerAddChoiceToDDfield] = useAddChoiceToDynamicFieldDDMutation();

    const [, ref] = useDrag({
        type: ItemType,
        item: { index },
    });
    const [, drop] = useDrop({
        accept: ItemType,
        hover: (draggedItem: { index: number }) => {
            if (draggedItem.index !== index) {
                moveField(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    const saveEditFieldHandler = async () => {
        const editObj = {
            choice: editDropdownListItem?.currentVal,
            field: editDropdownListItem?.fieldId,
        };

        try {
            const response = await triggerEditDrowDown({
                body: editObj,
                id: editDropdownListItem?.forChoiceId,
            });
            if (response?.data) {
                toast.success("Field edited");
                refetch();
            } else {
                toast.error("Unable to add field");
            }
        } catch (error) {
            toast.error("An error occured");
        }
    };

    const saveEditCheckBoxdHandler = async () => {
        const editObj = {
            choice: editCheckboxListOption?.currentVal,
            field: editCheckboxListOption?.fieldId,
        };

        try {
            const response = await triggerEditCheckbox({
                body: editObj,
                id: editCheckboxListOption?.forChoiceId,
            });
            if (response?.data) {
                toast.success("Field edited");
                refetch();
            } else {
                toast.error("Unable to add field");
            }
        } catch (error) {
            toast.error("An error occured");
        }
    };

    const handleDropdownNewListItemEdit = (event: any) => {
        setNewDropdownListItem(toSentenceCase(event.target.value));
    };

    const handleCheckboxNewOptionEdit = (event: any) => {
        setNewCheckboxOption(toSentenceCase(event.target.value));
    };

    return (
        <div ref={(node) => ref(drop(node))} style={{ cursor: "grab" }}>
            <div
            //   className="row mx-0"
            //   style={{ overflowY: "scroll", overflowX: "hidden" }}
            >
                <div>
                    {/* The Table Data */}
                    {/* {ticketFields.map((field,index)=>(<> */}

                    <div className="card mb-1" key={index}>
                        <div className="row align-items-center">
                            <div className="col-lg-4 col text-start ps-10">
                                {/* start: simply show the label of the field type or a text box with button before field type label if the field type is drop-down, that too conditionally */}
                                {field.field_type === "drop-down" ? (
                                    <div className="d-flex align-items-center flex-nowrap">
                                        <label>
                                            <div>
                                                {toSentenceCase(field.label)}
                                            </div>
                                        </label>
                                        <div
                                            className={`d-flex mx-2 ${expandForMoreSpecifications.expansionOn ===
                                                index &&
                                                expandForMoreSpecifications.expanded
                                                ? ""
                                                : "d-none"
                                                }`}
                                        >
                                            <input
                                                type="text"
                                                placeholder="Add a new item to the list."
                                                className="form-control m-1"
                                                value={newDropdownListItem}
                                                onChange={
                                                    handleDropdownNewListItemEdit
                                                }
                                            />
                                            <button
                                                className="btn btn-sm btn-primary m-1"
                                                onClick={async () => {
                                                    try {
                                                        await triggerAddChoiceToDDfield({
                                                            choice: newDropdownListItem,
                                                            field: field.id,
                                                        }).unwrap();  // Unwraps the result or throws an error if it fails

                                                        setNewDropdownListItem("");  // Clear the input on success
                                                        refetch();
                                                    } catch (error) {
                                                        console.log(error);
                                                        // Extract dynamic error messages
                                                        const errorMessages = error?.data
                                                            ? Object.entries(error.data).map(([key, messages]: any) => {
                                                                return messages.map(msg => `${msg}`).join(", ");
                                                            }).join(", ")
                                                            : "An error occurred while adding the item.";

                                                        toast.error(errorMessages);  // Display the error messages
                                                    }
                                                }}
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                ) : field.field_type === "check-box" ? (
                                    <>
                                        <div className="d-flex align-items-center flex-nowrap">
                                            <label>
                                                <div>
                                                    {toSentenceCase(
                                                        field.label
                                                    )}
                                                </div>
                                            </label>
                                            <div
                                                className={`d-flex mx-2 ${expandForMoreSpecifications.expansionOn ===
                                                    index &&
                                                    expandForMoreSpecifications.expanded
                                                    ? ""
                                                    : "d-none"
                                                    }`}
                                            >
                                                <input
                                                    type="text"
                                                    placeholder="Add a new checkbox option."
                                                    className="form-control m-1"
                                                    value={newCheckboxOption}
                                                    onChange={
                                                        handleCheckboxNewOptionEdit
                                                    }
                                                />
                                                <button
                                                    className="btn btn-sm btn-primary m-1"
                                                    onClick={async () => {
                                                        try {
                                                            await triggerAddOptionToCheckboxField({
                                                                choice: newCheckboxOption,
                                                                field: field.id,
                                                            }).unwrap();  // Unwraps the result or throws an error if it fails

                                                            refetch();
                                                            setNewCheckboxOption("");  // Clear the input on success
                                                        } catch (error) {
                                                            console.log(error);
                                                            // Extract dynamic error messages
                                                            const errorMessages = error?.data
                                                                ? Object.entries(error.data).map(([key, messages]: any) => {
                                                                    return messages?.map(msg => `${msg}`).join(", ");
                                                                }).join(", ")
                                                                : "An error occurred while adding the option.";

                                                            toast.error(errorMessages);  // Display the error messages
                                                        }
                                                    }}
                                                >
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <label>{toSentenceCase(field.label)}</label>
                                )}
                            </div>

                            <div className="col-lg-2 col justify-content-center align-items-center dropdown d-flex">
                                {/* start: show respective buttons to trigger open the customization sections of drop-down or multi-level respectively just before select menu of field type */}
                                {field.field_type === "drop-down" ? (
                                    <button
                                        className="btn btn-link"
                                        onClick={() =>
                                            setExpandForMoreSpecifications({
                                                expansionOn: index,
                                                expanded:
                                                    !expandForMoreSpecifications.expanded,
                                                expandForFieldType: "drop-down",
                                            })
                                        }
                                    >
                                        <i className="fs-2x text-dark bi bi-plus-lg me-2"></i>
                                    </button>
                                ) : field.field_type === "multi-level" ? (
                                    <>
                                        <button
                                            className="btn btn-link d-flex me-4"
                                            onClick={() =>
                                                setExpandForMoreSpecifications({
                                                    expansionOn: index,
                                                    expanded:
                                                        !expandForMoreSpecifications.expanded,
                                                    expandForFieldType:
                                                        "multi-level",
                                                })
                                            }
                                        >
                                            <i className="text-primary fs-2 bi bi-arrow-bar-left"></i>
                                            Configure
                                        </button>
                                    </>
                                ) : field.field_type === "check-box" ? (
                                    <>
                                        <button
                                            className="btn btn-link d-flex me-2"
                                            onClick={() =>
                                                setExpandForMoreSpecifications({
                                                    expansionOn: index,
                                                    expanded:
                                                        !expandForMoreSpecifications.expanded,
                                                    expandForFieldType:
                                                        "check-box",
                                                })
                                            }
                                        >
                                            <i className="fs-2x text-dark bi bi-plus-lg me-1"></i>
                                        </button>
                                    </>
                                ) : (
                                    <div></div>
                                )}
                                {/* ends */}

                                {/* start: select menu for field type */}
                                <label>
                                    {toSentenceCase(field.field_type)}
                                </label>
                                {/* ends */}
                            </div>

                            {/* start: mandatory, unique, masking, editable togglers */}
                            <div className="col text-end d-flex align-items-center justify-content-end">
                                <div className="col text-center">
                                    <i
                                        className={`me-4 fs-2 bi ${field.is_required
                                            ? "bi-check2"
                                            : "bi bi-x"
                                            }`}
                                        style={{
                                            cursor: "pointer",
                                            color: "black",
                                        }}
                                        onClick={() => {
                                            setTicketFields((prev) => {
                                                const updated = prev.map(
                                                    (thefield) =>
                                                        thefield.label ===
                                                            field.label
                                                            ? {
                                                                ...thefield,
                                                                isMandatory:
                                                                    !thefield.is_required,
                                                            }
                                                            : thefield
                                                );
                                                return updated;
                                            });
                                        }}
                                    ></i>
                                </div>
                            </div>
                            <div className="col text-center">
                                <i
                                    className={`me-4 fs-2 bi ${field.is_unique
                                        ? "bi-check2"
                                        : "bi bi-x"
                                        }`}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setTicketFields((prev) => {
                                            const updated = prev.map(
                                                (thefield) =>
                                                    thefield.label ===
                                                        field.label
                                                        ? {
                                                            ...thefield,
                                                            isUnique:
                                                                !thefield.is_unique,
                                                        }
                                                        : thefield
                                            );
                                            return updated;
                                        });
                                    }}
                                ></i>
                            </div>
                            <div className="col text-center">
                                <i
                                    className={`me-4 fs-2 bi ${field.is_masked
                                        ? "bi-check2"
                                        : "bi bi-x"
                                        }`}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setTicketFields((prev) => {
                                            const updated = prev.map(
                                                (thefield) =>
                                                    thefield.label ===
                                                        field.label
                                                        ? {
                                                            ...thefield,
                                                            isMasked:
                                                                !thefield.is_masked,
                                                        }
                                                        : thefield
                                            );
                                            return updated;
                                        });
                                    }}
                                ></i>
                            </div>
                            <div className="col text-center" style={{ marginRight: "20px" }}>
                                <i
                                    className={`me-4 fs-2 bi ${field.is_editable
                                        ? "bi-check2"
                                        : "bi bi-x"
                                        }`}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setTicketFields((prev) => {
                                            const updated = prev.map(
                                                (thefield) =>
                                                    thefield.label ===
                                                        field.label
                                                        ? {
                                                            ...thefield,
                                                            isEditable:
                                                                !thefield.is_editable,
                                                        }
                                                        : thefield
                                            );
                                            return updated;
                                        });
                                    }}
                                ></i>
                            </div>
                            {/* ends */}

                            {/* start: edit & delete field icons */}
                            <div className="col d-flex justify-content-end" style={{ marginRight: "-10px" }}>
                                <button
                                    className="btn btn-link text-hover-info"
                                    onClick={() => editFieldsHandler(field)}
                                >
                                    <i className="text-dark ms-auto bi bi-pencil fs-4"></i>
                                </button>
                                <button
                                    className="btn btn-link text-hover-danger"
                                    onClick={() =>
                                        openDeleteTicketFieldModal(field.id)
                                    }
                                >
                                    <i className="text-dark bi bi-trash fs-4 mx-3"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* start: The dynamic dropdown/multi-level configuration section that expands to set up options to be listed in dropdown/multi-level */}
                    {expandForMoreSpecifications.expansionOn === index &&
                        expandForMoreSpecifications.expanded &&
                        expandForMoreSpecifications.expandForFieldType ===
                        "drop-down" ? (
                        <>
                            <div
                                className="card my-1 bg-light-primary"
                                key={`${index}-moreSpec`}
                            >
                                {field?.choices?.map((item, msindex) => (
                                    <>
                                        <div
                                            className="card-header my-0 min-h-40px d-flex align-items-center justify-content-lg-start"
                                            key={msindex}
                                        >
                                            {/* <div className="d-flex">
                                    <button
                                      className="btn btn-link"
                                      onClick={() =>
                                        setEditDropdownListItem({
                                          enabled: true,
                                          forChoiceId: item.id,
                                          currentVal: item.choice,
                                          fieldId: item?.field
                                        })
                                      }
                                    >
                                      <i className="ms-auto text-dark text-hover-info bi bi-pencil-fill fs-2"></i>
                                    </button>
                                    <button
                                      className="btn btn-link"
                                      onClick={() => dropdownOptionDelete(item.id, "dropdown")}
                                    >
                                      <i className="text-dark text-hover-danger px-2 fs-4 fa fa-solid fa-trash"></i>
                                    </button>
                                  </div> */}
                                            <div className="ps-4">
                                                {editDropdownListItem.enabled &&
                                                    editDropdownListItem.forChoiceId ===
                                                    item.id ? (
                                                    <>
                                                        <input
                                                            className="form-control-sm"
                                                            type="text"
                                                            placeholder={
                                                                item.choice
                                                            }
                                                            value={
                                                                editDropdownListItem.currentVal
                                                            }
                                                            onChange={(evt) =>
                                                                setEditDropdownListItem(
                                                                    {
                                                                        ...editDropdownListItem,
                                                                        currentVal:
                                                                            evt
                                                                                .target
                                                                                .value,
                                                                    }
                                                                )
                                                            }
                                                        />
                                                        <button
                                                            className="btn btn-sm btn-primary mx-2"
                                                            onClick={() => {
                                                                // Functionality to update the choice
                                                                // You might need to implement the API call here
                                                                setEditDropdownListItem(
                                                                    {
                                                                        enabled:
                                                                            false,
                                                                        forChoiceId:
                                                                            -1,
                                                                        currentVal:
                                                                            "",
                                                                        fieldId:
                                                                            "",
                                                                    }
                                                                );
                                                                saveEditFieldHandler();
                                                            }}
                                                        >
                                                            Save
                                                        </button>
                                                        <button
                                                            className="btn btn-sm btn-secondary"
                                                            onClick={() =>
                                                                setEditDropdownListItem(
                                                                    {
                                                                        enabled:
                                                                            false,
                                                                        forChoiceId:
                                                                            -1,
                                                                        currentVal:
                                                                            "",
                                                                        fieldId:
                                                                            "",
                                                                    }
                                                                )
                                                            }
                                                        >
                                                            Cancel
                                                        </button>
                                                        {/* <button className="btn btn-sm btn-primary m-2" onClick={() => updateDropdownOption(editDropdownListItem.forChoiceId, editDropdownListItem.currentVal)}>Update Option</button> */}
                                                    </>
                                                ) : (
                                                    <div className="d-flex align-items-center">
                                                    <i
                                                      className="bi bi-pencil fs-5 mx-2 text-hover-primary"
                                                      onClick={() =>
                                                        setEditDropdownListItem({
                                                          enabled: true,
                                                          forChoiceId: item.id,
                                                          currentVal: item.choice,
                                                          fieldId: item?.field,
                                                        })
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                        color: "black",
                                                      }}
                                                    ></i>
                                                    <i
                                                      className="bi bi-trash fs-5 mx-2 text-hover-danger"
                                                      onClick={() => {
                                                        triggerDeleteChoiceFromTicketField({
                                                          id: item.id,
                                                        });
                                                        refetch();
                                                      }}
                                                      style={{
                                                        cursor: "pointer",
                                                        fontWeight: "600",
                                                        color: "black",
                                                      }}
                                                    ></i>
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        fontWeight: "500",
                                                      }}
                                                    >
                                                      {item.choice}
                                                    </span>
                                                  </div>
                                                  

                                                    // <label> {item.choice} </label>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </>
                    ) : expandForMoreSpecifications.expansionOn === index &&
                        expandForMoreSpecifications.expanded &&
                        expandForMoreSpecifications.expandForFieldType ===
                        "check-box" ? (
                        <>
                            <div
                                className="card my-1 bg-light-primary"
                                key={`${index}-moreSpec`}
                            >
                                {field.multiple_choices.map(
                                    (item: any, msindex: any) => (
                                        <>
                                            <div
                                                className="card-header my-0 min-h-40px d-flex align-items-center justify-content-lg-start"
                                                key={msindex}
                                            >
                                                {/* <div className="d-flex">
                                    <button
                                      className="btn btn-link"
                                      onClick={() =>
                                        setEditCheckboxListOption({
                                          enabled: true,
                                          forChoiceId: item.id,
                                          currentVal: item.choice,
                                          fieldId: item?.field
                                        })
                                      }
                                    >
                                      <i className="ms-auto text-dark text-hover-info bi bi-pencil-fill fs-2"></i>
                                    </button>
                                    <button
                                      className="btn btn-link"
                                      onClick={() => dropdownOptionDelete(item.id, "checkbox")}
                                    >
                                      <i className="text-dark text-hover-danger px-2 fs-4 fa fa-solid fa-trash"></i>
                                    </button>
                                  </div> */}
                                                <div
                                                    className="ps-4"
                                                    style={{
                                                        gap: "5px",
                                                        display: "flex",
                                                    }}
                                                >
                                                    {editCheckboxListOption.enabled &&
                                                        editCheckboxListOption.forChoiceId ===
                                                        item.id ? (
                                                        <>
                                                            <input
                                                                className="form-control-sm"
                                                                type="text"
                                                                placeholder={
                                                                    item.choice
                                                                }
                                                                value={
                                                                    editCheckboxListOption.currentVal
                                                                }
                                                                onChange={(
                                                                    evt
                                                                ) =>
                                                                    setEditCheckboxListOption(
                                                                        {
                                                                            ...editCheckboxListOption,
                                                                            currentVal:
                                                                                evt
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            // onBlur={() => {
                                                            //   alert(
                                                            //     `PUT api/v1/dynamicFieldUpdate/choices with payload as {choiceId:${item.id}, value:${editCheckboxListOption?.currentVal}}`
                                                            //   );
                                                            //   refetch();
                                                            //   setEditCheckboxListOption({
                                                            //     ...editCheckboxListOption,
                                                            //     enabled: false,
                                                            //   });
                                                            // }}
                                                            />
                                                            <button
                                                                className="btn btn-primary"
                                                                style={{
                                                                    padding:
                                                                        "8px 10px",
                                                                }}
                                                                onClick={() => {
                                                                    // Functionality to update the choice
                                                                    // You might need to implement the API call here
                                                                    setEditCheckboxListOption(
                                                                        {
                                                                            enabled:
                                                                                false,
                                                                            forChoiceId:
                                                                                -1,
                                                                            currentVal:
                                                                                "",
                                                                            fieldId:
                                                                                "",
                                                                        }
                                                                    );
                                                                    saveEditCheckBoxdHandler();
                                                                }}
                                                            >
                                                                Save
                                                            </button>
                                                            <button
                                                                className="btn btn-sm btn-secondary"
                                                                onClick={() =>
                                                                    setEditCheckboxListOption(
                                                                        {
                                                                            enabled:
                                                                                false,
                                                                            forChoiceId:
                                                                                -1,
                                                                            currentVal:
                                                                                "",
                                                                            fieldId:
                                                                                "",
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                Cancel
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <div className="d-flex align-items-center">
                                                        <i
                                                          className="bi bi-pencil fs-6 mx-2 text-hover-primary"
                                                          onClick={() =>
                                                            setEditCheckboxListOption({
                                                              enabled: true,
                                                              forChoiceId: item.id,
                                                              currentVal: item.choice,
                                                              fieldId: item?.field,
                                                            })
                                                          }
                                                          style={{
                                                            cursor: "pointer",
                                                            color: "black",
                                                          }}
                                                        ></i>
                                                        <i
                                                          className="bi bi-trash fs-6 mx-2 text-hover-danger"
                                                          onClick={() => {
                                                            triggerDeleteChoiceFromTicketField({
                                                              id: item.id,
                                                            });
                                                            refetch();
                                                          }}
                                                          style={{
                                                            cursor: "pointer",
                                                            fontWeight: "600",
                                                            color: "black",
                                                          }}
                                                        ></i>
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            fontWeight: "500",
                                                          }}
                                                        >
                                                          {item.choice}
                                                        </span>
                                                      </div>
                                                      
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )
                                )}
                            </div>
                        </>
                    ) : expandForMoreSpecifications.expansionOn === index &&
                        expandForMoreSpecifications.expanded &&
                        expandForMoreSpecifications.expandForFieldType ===
                        "multi-level" ? (
                        <>
                            <div
                                className="card my-1 bg-light-primary"
                                key={`${index}-moreSpec`}
                            >
                                {/* <NestedDDConf
                              setMultiLevelConfiguration={
                                setMultiLevelConfiguration
                              }
                            /> */}
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            {/* {ticketFields?.length ? (
            <div className="row mt-4">
              <div className="col">
                <PagenationUtility
                  data={ticketFields}
                  setCurrentData={setCurrentData}
                />
              </div>
            </div>
          ) : (
            <></>
          )} */}
        </div>
    );
};

export default DragTicketField;
