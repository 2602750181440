import { useState } from "react";
import LeadInformationTab from "./LeadInformationTab";
import ChannelHistoryTab from "./ChannelHistoryTab";
import MessagesTab from "./MessagesTab";
import TrailTab from "./TrailTab";
import TasksTab from "./TasksTab";
// import { useAuditTrailQuery } from "../../Services/ticket/Audit-Trail";
import { useParams } from "react-router-dom";
import { useAuditTrailQuery } from "../../../Services/ticket/Audit-Trail";
import AttachmentTab from "./AttachmentTab";
interface IPROPS {
  tickets: any;
  refetch: any;
  taskData: any;
  refetchTask: any;
  isLoading: any;
  setLiftingTaskData:any
  handleButtonClick:any
  setConfirmationModalOpen:any
  confirmationModalOpen:any
}

const TabbedPanel: React.FC<IPROPS> = ({
  tickets,
  refetch,
  setLiftingTaskData,
  taskData,
  refetchTask,
  isLoading,
  handleButtonClick,
  setConfirmationModalOpen,
  confirmationModalOpen
}) => {
  const params = useParams();
  const uuid = params.uuid;

  //  const { data: auditTrailData, refetch: refetchAuditTrail } =useAuditTrailQuery(uuid);


  const [paneOpen, setPaneOpen] = useState<String>("LeadInformationTab");

  return (
    <>
      <div className="card">
        <div className="d-lg-flex row-col-5">
          <button
            className={`col btn btn-outline-primary ${
              paneOpen === "LeadInformationTab" ? "bg-primary" : ""
            } text-dark rounded-0 justify-content-center border`}
            onClick={() => setPaneOpen("LeadInformationTab")}
          >
            Lead Information
          </button>
          <button
            className={`col btn btn-outline-primary ${
              paneOpen === "ChannelHistoryTab" ? "bg-primary" : ""
            } text-dark rounded-0 justify-content-center border`}
            onClick={() => setPaneOpen("ChannelHistoryTab")}  disabled
          >
            Channel History
          </button>
          <button
            className={`col btn btn-outline-primary ${
              paneOpen === "MessagesTab" ? "bg-primary" : ""
            } text-dark rounded-0 justify-content-center border`}
            onClick={() => setPaneOpen("MessagesTab")}
          >
            Messages
          </button>
          <button
            className={`col btn btn-outline-primary ${
              paneOpen === "TrailTab" ? "bg-primary" : ""
            } text-dark rounded-0 justify-content-center border`}
            onClick={() => setPaneOpen("TrailTab")}  disabled
          >
            Trail
          </button>
          <button
            className={`col btn btn-outline-primary ${
              paneOpen === "TasksTab" ? "bg-primary" : ""
            } text-dark rounded-0 justify-content-center border`}
            onClick={() => setPaneOpen("TasksTab")}
            // disabled
          >
            Tasks
          </button>

          <button
            className={`col btn btn-outline-primary ${
              paneOpen === "AttachmentTab" ? "bg-primary" : ""
            } text-dark rounded-0 justify-content-center border`}
            onClick={() => setPaneOpen("AttachmentTab")}
          >
            Attachment
          </button>
        </div>
      </div>
      <div className="card mt-3">
        {paneOpen === "LeadInformationTab" ? (
          <LeadInformationTab
            ticket={tickets}
            refetch={refetch}
            uuid={uuid}
            handleButtonClick={handleButtonClick}
            setConfirmationModalOpen={setConfirmationModalOpen}
            confirmationModalOpen={confirmationModalOpen}
            // refetch1={refetchAuditTrail}
          />
        ) : paneOpen === "ChannelHistoryTab" ? (
          <ChannelHistoryTab 
          // tickets={tickets} 
          // refetch={refetch} 
          />
        ) : paneOpen === "MessagesTab" ? (
          <MessagesTab 
          notesData={tickets}
           fetchingNotes={refetch}
            />
        ) : paneOpen === "TrailTab" ? (
          <TrailTab
          //  auditTrailData={auditTrailData} 
           />
        ) : paneOpen === "TasksTab" ? (
          <TasksTab
          tasks={tickets}
          setLiftingTaskData={setLiftingTaskData}
            // taskData={taskData}
            // refetchTask={refetchTask}
            // isLoading={isLoading}
          />
        )
         : paneOpen === "AttachmentTab" ? (
          <AttachmentTab  
          ticket={tickets}
          refetch={refetch}
          uuid={uuid}/>
        ) 
        : (
          <>SomethingElse</>
        )}
      </div>
    </>
  );
};

export default TabbedPanel;
