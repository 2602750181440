import { Routes, Route } from "react-router-dom";
import CustomerPage from "./CustomerPage";

const ContactRoutes = () => {
  return (
    <Routes>
      <Route path="customer/*" element={<CustomerPage />} />
    </Routes>
  );
};

export default ContactRoutes;
