import React from 'react';
import { CloseButton } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAddNewWipWorkFlowMutation } from "../../..//../../Services/campaign/CampaignMapping";

interface IProps {
  closeModal: () => void;
  workflowSelected: any // Consider defining a more specific type here.
  refetch: () => any;

}

const AddNewWipModal: React.FC<IProps> = ({ closeModal, workflowSelected, refetch, }) => {
  const initialValues = {
    name: '',
  };
  const [triggeerAddWip] = useAddNewWipWorkFlowMutation();
  const validationSchema = Yup.object({
    name: Yup.string().required('Status Title is required'),
  });





  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const payload = {
        assignment_team: null,
        campaign_name: workflowSelected,
        status: values.name,
        visibility_team: null,
      };

      try {
        // Pass the payload correctly to the API call
        await triggeerAddWip({ body: payload });
        await refetch();
        closeModal();
      } catch (error) {
        console.error('Error saving WIP:', error);
      } finally {
        setSubmitting(false);
      }
    },
  });


  return (
    <div>
      <div className="text-end">
        <CloseButton onClick={closeModal} />
      </div>

      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-4">
          <label className="form-label fs-2 fw-bolder text-dark card-title">
            In progress status
          </label>
        </div>

        <div className="row g-5 g-xl-8 mb-6">
          <div className="col-xl-12">
            <div className="d-flex flex-column">
              <label className="required">Status title</label>
              <input
                id="name"
                name="name"
                type="text"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />

              {formik.touched.name && formik.errors.name && (
                <div className="fv-plugins-message-container text-danger">
                  <span role="alert">{formik.errors.name}</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="text-end mt-6 mb-4">
          <button
            className={`btn btn-sm btn-primary`}
            type="submit"
            disabled={formik.isSubmitting}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNewWipModal;
