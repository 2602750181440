
import Dialpad from '../../assets/images/dialpad.jpg'
import MailHistory from '../../components/email/mail/MailHistory'
import MailSideMenu from '../../components/email/mail/MailRightSideMenu'
import PhoneCard from '../../components/phone/PhoneCard'
import DispositionCard from '../../components/phone/DispositionCard'

const Phone = () => {
    return (
        <div className="app-container mx-5 mb-2">
            {/*begin::Products*/}
            <div className="card card-flush">
                {/*begin::Card header*/}
                <div className="card-header align-items-center py-1 my-1 gap-2 gap-md-5">
                    <div className="d-flex align-items-center gap-2 py-1 gap-md-5">
                        <i className="bi bi-circle-fill" style={{ color: 'lightgreen' }}></i>
                        <input type='text' className="mx-2 form-control" placeholder="00:03:15" />
                        <input type="text" className="form-control" placeholder="Mobile no." />
                        <div className="d-flex align-items-center gap-2 py-1 gap-md-5">
                            <button className="btn p-0">
                                <img src={Dialpad} />
                            </button>
                            <button className="btn p-0">
                                <i className=" bi bi-telephone-fill text-dark fs-2"></i>
                            </button>
                            <button className="btn p-0">
                                <i className="bi bi-telephone-plus text-dark fs-2"></i>
                            </button>
                            <button className="btn p-0" style={{ transform: 'rotate(100deg' }}>
                                <i className="fa fa-solid fa-phone-slash text-dark fs-2"></i>
                            </button>
                        </div>

                    </div>

                    {/*begin::Card toolbar*/}
                    <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                        <div className="w-100 mw-150px">
                            {/*begin::Select2*/}
                            <select
                                className="form-select select2-hidden-accessible"
                            >
                                <option value="select team" selected hidden>Select Team</option>
                                <option value="Team 01">team 01</option>
                                <option value="Team 02">team 02</option>
                                <option value="Team 03">team 03</option>
                            </select>
                            {/*end::Select2*/}
                        </div>

                        <div className="w-100 mw-150px">
                            <button className='form-control form-control d-flex justify-content-between align-items-center fw-bold'>
                                Pause
                                <i className="bi bi-pause-circle text-hover-danger" style={{ fontSize: '20px' }}></i>
                            </button>
                        </div>

                        <div className="w-100 mw-150px">
                            <input className='form-control form-control-lg' type="date" placeholder='Custom Date' />
                        </div>

                        {/*begin::Add product*/}
                        <button className="btn btn-link"> <i className="fs-2 bi bi-gear-wide-connected text-dark"></i> </button>
                        {/*end::Add product*/}
                    </div>
                    {/*end::Card toolbar*/}
                </div>

                <div className="card-header align-items-center py-1 gap-2 gap-md-5">
                    <span className='p-4 fs-3 text-gray-800 fw-bold'>
                        Phone
                    </span>
                </div>
                {/*end::Card header*/}

                {/*begin::Card header*/}
                <div className="d-flex justify-content-between container-fluid mt-4">
                    <div className="row w-100">
                        {/* start:: The left side stack */}
                        <div className="col-lg-2">
                            <div className="card">
                                <div className="card-body p-4">
                                    {/* <h5 className="card-title">Card title</h5>
                                <h6 className="card-subtitle mb-2 text-body-secondary">Card subtitle</h6> */}
                                    <div className="d-xl-flex flex-column">
                                        <div className="d-flex align-items-center my-1">
                                            <div className="me-auto"><p className="card-text">Customer Information</p></div>
                                            <div><i className="bi bi-pencil mx-2"></i><i className="bi bi-gear"></i></div>
                                        </div>
                                        <div className="d-flex align-items-center my-1">
                                            <div className="me-auto"><p className="card-text">Account</p></div>
                                            <div className="form-check form-switch"><input className="form-check-input" type="checkbox" role="switch" /></div>
                                            <div>Contact</div>
                                        </div>
                                        <div className="my-1">Name: Sam Miller</div>
                                        <div className="my-1">Email: sam15@gmail.com</div>
                                        <div className="d-flex my-1"><i className="bi bi-telephone-fill"></i> : 8790123467</div>
                                        <div className="d-flex my-1"><i className="bi bi-whatsapp"></i> : 8790123467</div>
                                        <div className="d-flex flex-row-reverse mt-2"><p className="text-muted">view more</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end:: The left side stack */}
                        {/* start:: The middle stack */}
                        <div className="col-lg-8 mx-auto">
                            <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
                                {/*begin::Card*/}
                                <div className="card">
                                    <div className="card-header align-items-center py-5 gap-5">
                                        {/*begin::Actions*/}
                                        <div className="d-flex">
                                            <span className='fs-5 text-gray-800 fw-bold'>Call Script</span>
                                        </div>
                                        {/*end::Actions*/}
                                        <div className="card-body p-0">
                                            {/*begin::Title*/}
                                            <PhoneCard />
                                            {/*end::Form*/}
                                        </div>

                                    </div>
                                </div>
                                <div className="card mt-5">
                                    <div className="card-header align-items-center py-5 gap-5">
                                        <div className="card-body p-0">
                                            {/*begin::Title*/}
                                            <DispositionCard />
                                            {/*end::Form*/}
                                        </div>

                                    </div>
                                </div>
                                <div className="card mt-5">
                                    <div className="card-header align-items-center py-5 gap-5">
                                        {/*begin::Actions*/}
                                        <div className="d-flex">
                                            <span className='fs-5 text-gray-800 fw-bold'>Call Script</span>
                                        </div>
                                        {/*end::Actions*/}
                                        <div className="card-body p-0">
                                            {/*begin::Title*/}
                                            <PhoneCard />
                                            {/*end::Form*/}
                                        </div>

                                    </div>
                                </div>



                                {/*end::Card*/}
                                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mt-5 p-2">
                                    {/*begin::Title*/}
                                    <h4>History</h4>

                                </div>
                                <div className="card w-100 mt-2">
                                    <MailHistory mailHistory={undefined} />
                                </div>
                            </div>
                        </div>
                        {/* end:: The middle stack */}
                        {/* start:: The right side stack */}
                        <div className="col-lg-2">
                            <MailSideMenu />
                        </div>
                        {/* end:: The right side stack */}
                    </div>
                </div>
                {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
        </div >
    )
}

export default Phone