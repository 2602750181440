import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const emailConfigSettingsApi = createApi({
  reducerPath: "email",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    teamConfig: builder.query({
      query: (team) => {
        return {
          url: `v1/common/email-server-configuration/team-config/${team}/`,
          method: "GET",
        };
      },
    }),
    smtpMailConfigs: builder.query({
      query: (teamId) => {
        return {
          url: `v1/common/smtp-server-configuration/team-config/${teamId}/`,
          method: "GET",
        };
      },
    }),

    emailServerConfig: builder.mutation({
      query: (body) => ({
        url: `v1/common/email-server-configuration/`,
        method: "POST",
        body,
      }),
    }),

    emailConfigEdit: builder.mutation({
      query: ({ id, data }) => ({
        url: `v1/common/email-server-configuration/${id}/`,
        method: "PATCH",
        body: data,
      }),
    }),
    emailConfigDelete: builder.mutation({
      query: (configId) => ({
        url: `v1/common/email-server-configuration/${configId}/`,
        method: "DELETE",
      }),
    }),
    smtpConfigDelete: builder.mutation({
      query: (configId) => ({
        url: `v1/common/smtp-server-configuration/${configId}/`,
        method: "DELETE",
      }),
    }),
    getEmailTemplates: builder.query({
      query: ({ pageNo, teamId }) => ({
        url: `v1/emails/templates/team/?page=${pageNo}&team_id=${teamId}&`,
        method: "GET",
      }),
    }),
    newEmailTemplate: builder.mutation({
      query: (body) => ({
        url: `v1/emails/templates/`,
        method: "POST",
        body,
      }),
    }),
    editEmailTemplate: builder.mutation({
      query: ({ templateId, body }) => ({
        url: `v1/emails/templates/${templateId}/`,
        method: "PUT",
        body,
      }),
    }),
    getActiveEmailNotification: builder.query({
      query: (teamId) => ({
        url: `v1/common/notification/get_notification/${teamId}/email/active/`,
        method: "GET",
      }),
    }),
    getClosedEmailNotification: builder.query({
      query: (teamId) => ({
        url: `v1/common/notification/get_notification/${teamId}/email/closed/`,
        method: "GET",
      }),
    }),
    editEmailNotification: builder.mutation({
      query: ({ notificationId, body }) => ({
        url: `v1/common/notification/${notificationId}/`,
        method: "PUT",
        body,
      }),
    }),
    setEmailNotification: builder.mutation({
      query: (body) => ({
        url: `v1/common/notification/`,
        method: "POST",
        body,
      }),
    }),
    deleteEmailTemplate: builder.mutation({
      query: (templateId) => ({
        url: `v1/emails/templates/${templateId}/`,
        method: "DELETE",
      }),
    }),
    getEmailRules: builder.query({
      query: () => ({
        url: `v1/emails/email-rules/`,
        method: "GET",
      }),
    }),
    setEmailRules: builder.mutation({
      query: (body) => ({
        url: `v1/emails/email-rules/`,
        method: "PATCH",
        body,
      }),
    }),
    smtpMailConfig: builder.mutation({
      query: (body) => ({
        url: `v1/common/smtp-server-configuration/`,
        method: "POST",
        body,
      }),
    }),
    updateSMTPMailConfig: builder.mutation({
      query: ({ configId, body }) => ({
        url: `v1/common/smtp-server-configuration/${configId}/`,
        method: "PATCH",
        body,
      }),
    }),

    deleteAttachment: builder.query({
      query: (attachmentId) => ({
        url: `v1/emails/template-attachments/${attachmentId}/`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useTeamConfigQuery,
  useSmtpMailConfigsQuery,
  useEmailServerConfigMutation,
  useEmailConfigEditMutation,
  useEmailConfigDeleteMutation,
  useSmtpConfigDeleteMutation,
  useGetEmailTemplatesQuery,
  useNewEmailTemplateMutation,
  useEditEmailTemplateMutation,
  useDeleteEmailTemplateMutation,
  useGetActiveEmailNotificationQuery,
  useGetClosedEmailNotificationQuery,
  useEditEmailNotificationMutation,
  useGetEmailRulesQuery,
  useSetEmailRulesMutation,
  useSetEmailNotificationMutation,
  useSmtpMailConfigMutation,
  useUpdateSMTPMailConfigMutation,
  useDeleteAttachmentQuery,
} = emailConfigSettingsApi;
