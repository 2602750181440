import React from 'react';
import { useNavigate } from 'react-router-dom';

const ChatReportList = () => {
    const navigate = useNavigate();

    const handleGoHomeClick = () => {
        navigate('/dashboard/email-dashboard');
    };

    return (
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
            {/*begin::Aside*/}
            <div className="d-flex flex-lg-row-fluid">
                {/*begin::Content*/}
                <div className="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
                    {/*begin::Image*/}
                    <img
                        className="theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20"
                        src="https://preview.keenthemes.com/metronic8/demo39/assets/media/auth/agency.png"
                        alt="Coming Soon"
                    />
                    {/*end::Image*/}

                    {/*begin::Title*/}
                    <h1 className="text-gray-800 fs-2qx fw-bold text-center mb-7">
                        Coming Soon....
                    </h1>
                    {/*end::Title*/}

                    {/*begin::Text*/}
                    <div className="text-gray-600 fs-base text-center fw-semibold">
                        We're thrilled to announce that something special is on its way.
                    </div>

                    <button
                        className="btn btn-primary mt-10"
                        onClick={handleGoHomeClick}
                    >
                        Go Home
                    </button>
                    {/*end::Text*/}
                </div>
                {/*end::Content*/}
            </div>
            {/*end::Aside*/}
        </div>
    );
};

export default ChatReportList;
