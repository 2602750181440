import React, {useState, useEffect} from 'react'
import { useAddExtensionMutation } from '../../../../Services/Telephony/Telephony'
import { toast } from 'react-toastify'


interface IPROPS {
    closeModal:any
    refetch:any
}

const AddExtension: React.FC<IPROPS> = ({closeModal, refetch}) => {

    const [extId, setExtId] = useState('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


    const [addExtensionTrigger] = useAddExtensionMutation()


    const handleSubmit = async(e) => {
        e.preventDefault()

        const addExtensionObj = {
            extension: extId
        }

        const response  = await addExtensionTrigger(addExtensionObj)

        try {

            if (response?.data) {
                toast.success("Extension Added Successfully")
                closeModal()
                refetch()

                setIsButtonDisabled(true);

                setTimeout(() => {
                  setIsButtonDisabled(false);
                }, 3000);

            }
            else if ("data" in response.error) {
                const errorData = response?.error?.data as {extension: string[]}
                const errorMessage = errorData?.extension[0]
                toast.error(errorMessage)
                setIsButtonDisabled(true);

                setTimeout(() => {
                  setIsButtonDisabled(false);
                }, 3000);

            }
            
        } catch (error) {
            setIsButtonDisabled(true);

            setTimeout(() => {
              setIsButtonDisabled(false);
            }, 3000);

            
        }

    }
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
    <div>
        <label>Extension</label>
        <input
            className="form-control"
            value={extId}
            onChange={(e) => setExtId(e.target.value)}
        />
    </div>
    <div
        style={{
            width: "100%",
            display: "flex",
            gap: "10px",
            justifyContent: "end",
        }}
    >
        <button 
        type="reset"
                    className="btn btn-sm btn-white me-2"
        onClick={() => closeModal()}>Cancel</button>
        <button className="btn btn-primary" onClick={handleSubmit} disabled={isButtonDisabled}>
            Save
        </button>
    </div>
</div>  )
}

export default AddExtension