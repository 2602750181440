import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Loader } from "react-bootstrap-typeahead";
import TermsConditionModal from "./TermsConditionModal";
import { Box, Typography, Modal, IconButton } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";

const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  acceptTerms: false,
  businessName: "",
  phoneNumber: null,
};

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("First name is required"),
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .test(
      "generic-domains",
      "Generic email domains are not allowed",
      function (value) {
        const genericDomains = [
          "gmail.com",
          "yahoo.com",
          "outlook.com",
          "google.com",
          "rediff.com",
        ];
        const enteredDomain = value?.split("@")[1];
        return !genericDomains.includes(enteredDomain);
      }
    )
    .required("Email is required"),
  lastname: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Last name is required"),

  businessName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Business name is required"),

  phoneNumber: Yup.string()
    .nullable()
    .matches(/^\d{10}$/, "Phone number must be 10 digits")
    .required("Phone number is required"),

  acceptTerms: Yup.bool().required("You must accept the terms and conditions"),
});

export function Registration() {
  const [loading, setLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const openTermsModal = () => {
    setIsTermsModalOpen(true);
  };

  const closeTermsModal = () => {
    setIsTermsModalOpen(false);
  };

  const apiPort = process.env.REACT_APP_API_PROTOCOL;

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setIsError(false);

      if (!values.acceptTerms) {
        setIsError(true);
        setLoading(false);
        return;
      } else {
        setIsError(false);
      }
      try {
        const response = await axios.post(
          `${apiPort}://${process.env.REACT_APP_BASE_URL}/v1/signup/`,
          {
            sub_domain: values?.businessName,
            admin_username: values?.email,
            admin_phone: values?.phoneNumber,
            agreed: values?.acceptTerms,
          }
        );
        if (response.status === 201) {
          localStorage.setItem("username", values?.email);
          localStorage.setItem("tenant", values?.businessName);
          navigate("/auth/email-sent");
        }
        setLoading(false);
      } catch (error) {
        if (error?.response.status === 400) {
          toast.error(
            error?.response.data?.admin_username?.[0] ||
              error?.response.data?.sub_domain?.[0] ||
              "An unknown error occured"
          );
        } else if (error.message === "Network Error") {
          toast.error("Network error, please check your internet connection.");
        } else {
          toast.error("An error occurred.");
        }

        setLoading(false);
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    overflow: "hidden",
    bgcolor: "background.paper",
    border: "1px solid transparent",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <form
        className="form w-100 h-650px fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_signup_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="mb-10 text-center">
          <h1 className="text-dark mb-3">Create an account</h1>
          <div className="text-gray-400 fw-bold fs-4">
            Already have an account?
            <Link
              to="/auth/login"
              className="link-primary fw-bolder"
              style={{ marginLeft: "5px" }}
            >
              Log In
            </Link>
          </div>
        </div>

        <div className="row fv-row">
          <div className="col-xl-6 mb-5">
            <label className='class="form-label fw-bolder text-dark fs-6'>
              First name
            </label>
            <input
              placeholder="First name"
              type="text"
              autoComplete="off"
              {...formik.getFieldProps("firstname")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid":
                    formik.touched.firstname && formik.errors.firstname,
                },
                {
                  "is-valid":
                    formik.touched.firstname && !formik.errors.firstname,
                }
              )}
            />
            {formik.touched.firstname && formik.errors.firstname && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert" style={{ color: "red" }}>
                    {formik.errors.firstname}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="col-xl-6">
            <div className="fv-row mb-5">
              <label
                className="form-label fw-bolder text-dark fs-6"
                style={{ marginBottom: "0px" }}
              >
                Last name
              </label>
              <input
                placeholder="Last name"
                type="text"
                autoComplete="off"
                {...formik.getFieldProps("lastname")}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid":
                      formik.touched.lastname && formik.errors.lastname,
                  },
                  {
                    "is-valid":
                      formik.touched.lastname && !formik.errors.lastname,
                  }
                )}
              />
              {formik.touched.lastname && formik.errors.lastname && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert" style={{ color: "red" }}>
                      {formik.errors.lastname}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="fv-row mb-5">
          <label className="form-label fw-bolder text-dark fs-6">
            Work Email
          </label>
          <input
            placeholder="Email"
            type="email"
            autoComplete="off"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid": formik.touched.email && formik.errors.email,
              },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert" style={{ color: "red" }}>
                  {formik.errors.email}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="mb-5 fv-row">
          <div className="mb-1">
            <label className="form-label fw-bolder text-dark fs-6">
              Business Name
            </label>
            <div className="input-group">
              <input
                required
                type="text"
                placeholder="Business Name"
                {...formik.getFieldProps("businessName")}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid":
                      formik.touched.businessName && formik.errors.businessName,
                  },
                  {
                    "is-valid":
                      formik.touched.businessName &&
                      !formik.errors.businessName,
                  }
                )}
              />
              <span className="input-group-text" id="basic-addon2">
                circleonecrm.com
              </span>
            </div>
            {formik.touched.businessName && formik.errors.businessName && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert" style={{ color: "red" }}>
                    {formik.errors.businessName}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="fv-row mb-5">
          <label className="form-label fw-bolder text-dark fs-6">
            Contact Number
          </label>
          <input
            required
            placeholder="Contact Number"
            type="number"
            autoComplete="off"
            {...formik.getFieldProps("phoneNumber")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid":
                  formik.touched.phoneNumber && formik.errors.phoneNumber,
              },
              {
                "is-valid":
                  formik.touched.phoneNumber && !formik.errors.phoneNumber,
              }
            )}
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert" style={{ color: "red" }}>
                  {formik.errors.phoneNumber}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="fv-row mb-10">
          <div
            className="form-check form-check-custom form-check-solid"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "baseline",
            }}
          >
            <div>
              <input
                className="form-check-input"
                style={{
                  border: isError ? "1px solid red" : "",
                }}
                type="checkbox"
                id="kt_login_toc_agree"
                {...formik.getFieldProps("acceptTerms")}
              />
              <label
                className="form-check-label fw-bold text-gray-700 fs-6 required"
                htmlFor="kt_login_toc_agree"
              >
                I Agree the{" "}
                <span onClick={openTermsModal}>Terms and Conditions</span>
              </label>
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_up_submit"
            className="btn btn-lg btn-primary w-100 mb-5"
            disabled={formik.isSubmitting || !formik.isValid || !formik.values}
          >
            {loading ? (
              <>
                Submitting <Loader />{" "}
              </>
            ) : (
              "Submit"
            )}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              id="kt_login_signup_form_cancel_button"
              className="btn btn-lg btn-light-primary w-100 mb-5"
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
      <Modal
        open={isTermsModalOpen}
        onClose={closeTermsModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ ...style, position: "absolute" }}>
          <IconButton
            aria-label="close"
            onClick={closeTermsModal}
            sx={{ position: "fixed", top: 16, right: 16, zIndex: 1301 }}
          >
            <GridCloseIcon />
          </IconButton>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            CircleOne Terms & Conditions
          </Typography>

          <Box>
            <TermsConditionModal closeModal={closeTermsModal} />
          </Box>
        </Box>
      </Modal>
    </>
  );
}
