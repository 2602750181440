
export const getIconClasses = (channel: any) => {
    switch (channel) {
      case "Email":
        return "fa fa-envelope fs-6 text-white";
      case "outbound_phone":
      case "inbound_phone":
        return "bi bi-telephone fs-6 text-white";
      case "Chat":
        return "bi bi-chat-left-dots fs-6 text-dark";
      case "Whatsapp":
        return "bi bi-whatsapp fs-4 text-success";
      case "email":
        return "fa fa-envelope fs-6 text-white";
      case "app":
        return "bi bi-google-play fs-6 text-white";
      case "Social-Media":
        return "bi bi-dribbble fs-6 text-white";
      case "Facebook":
        return "bi bi-facebook fs-6 text-white";
      default:
        return "";
    }
  };

  export  const getBadgeClasses = (channel: any) => {
    switch (channel) {
      case "Email":
        return "bg-warning rounded-circle";
      case "outbound_phone":
        return "bg-success rounded-circle";
      case "inbound_phone":
        return "bg-danger rounded-circle";
      case "Chat":
        return "bg-white border border-dark rounded-circle";
      case "Whatsapp":
        return "bg-success rounded-circle";
      case "email":
        return "bg-warning rounded-circle";
      case "app":
        return "bg-success rounded-circle";
      case "Social-Media":
        return "bg-primary rounded-circle";
      case "Facebook":
        return "bg-primary rounded-circle";
      default:
        return "";
    }
  };