import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import ChangeUserModal from "./ticketListInfo/ChangeUserModal";
import { useChangeStatusQuery } from "../../Services/settingsAPIs/CommonAPI";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import ChangeStatusModal from "./ChangeStatusModal";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useUpdateDispositionMutation } from "../../Services/ticket/CreateTicket";
import {
  capitalizeAndFormat,
  capitalizeFirstLetter,
} from "../reusableComponent/CapitalLetter";
import { format } from "date-fns";
import DispositionTicketModal from "./DispositionTicketModal";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
  },
};

const TicketListInfoCard = ({
  tickets,
  refetch,
  refetchAuditTrailData,
  setGetAllStatus,
  refetchAuditTrailForwardedData,
  openChildTicketModal,
}) => {
  const [isAddNewContactModalOpen, setAddNewContactModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [originalStatus, setOriginalStatus] = useState("");
  const [selectedAllStatus, setAllSelectedStatus] = useState("");
  const [isStatusModalOpen, setStatusModalOpen] = useState(false);
  const [defaultSelect, setDefaultSelect] = useState("");
  const [storingTicketId, setStoringTicketId] = useState("");
  const [openDispositionModal, setOpenDispositionModal] = useState(false);
  const [storedDisposition, setStoredDisposition] = useState([]);
  const navigate = useNavigate();

  const storedTicketId = "";

  const { data, refetch: refetchAuditTrail } = useChangeStatusQuery(
    tickets?.action?.disposition?.id,
    {
      skip: !tickets?.action?.disposition?.id,
    }
  );

  const [trigger, { isLoading }] = useUpdateDispositionMutation();

  useEffect(() => {
    if (tickets?.action?.disposition?.disposition) {
      setStoredDisposition(tickets?.action?.disposition?.disposition);
    }
  }, [tickets?.action?.disposition?.disposition]);

  useEffect(() => {
    setDefaultSelect(tickets?.status_display || "");
  }, [tickets]);

  const openContactModal = () => {
    setAddNewContactModalOpen(true);
  };

  const closeContactModal = () => {
    setAddNewContactModalOpen(false);
  };

  const openDispositionModall = () => {
    setOpenDispositionModal(true);
  };

  const closeDispositionModall = () => {
    setOpenDispositionModal(false);
  };

  const openStatusModal = () => {
    setOriginalStatus(selectedStatus);
    setStatusModalOpen(true);
  };

  const closeStatusModal = () => {
    setSelectedStatus(originalStatus);
    setStatusModalOpen(false);
  };

  const handleStatusUpdate = () => {
    // logic to handle status update goes here
    setStatusModalOpen(false);
  };

  const getStatusStyle = (status: string) => {
    switch (status) {
      case "active":
        return { backgroundColor: "#56d85b", color: "white" }; // $green-300
      case "closed":
        return { backgroundColor: "#90dcf9", color: "white" }; // $cyan-300
      case "reopen":
        return { backgroundColor: "#f44336", color: "white" }; // $red-200
      case "wip":
        return { backgroundColor: "#ffeb3b", color: "black" }; // $yellow-200
      default:
        return { backgroundColor: "#6c757d", color: "white" }; // Default color
    }
  };

  const formatStatusData = (status) => {
    if (typeof status.status === "string") {
      // Open and Reopen statuses
      return {
        status_type: status.status_type,
        status: status.status,
      };
    } else {
      // WIP and Closed statuses
      return {
        id: status.status.id,
        status_type: status.status_type,
        status: status.status.status,
      };
    }
  };

  const handlerStatus = (allStatus) => {
    setAllSelectedStatus(allStatus);
    setSelectedStatus(allStatus.status_type);
    openStatusModal();
    refetchAuditTrailForwardedData();
  };

  const statusOptions = data?.all_statuses?.map((status) =>
    formatStatusData(status)
  );

  useEffect(() => {
    setGetAllStatus(data);
  });

  const truncateDescription = (description, maxLength) => {
    if (description?.length > maxLength) {
      return description?.slice(0, maxLength) + "...";
    }
    return description;
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 120,
      },
    },
  };

  const handleNavigate = () => {
    navigate(`/ticket/information/${tickets?.parent_ticket_id}`);
    window.location.reload();
  };

  console.log(tickets, "tickets");

  return (
    <div className="card" id="kt_chat_messenger">
      <div className="d-flex flex-stack mb-2">
        <div className="d-flex flex-column w-100">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="p-2 text-gray-800 text-hover-primary fw-bold">
                {tickets?.id}
              </div>
              <div className="p-2 fs-3 text-gray-800 text-hover-primary fw-bold">
                {tickets?.title}
              </div>
              <i className="fs-2 mx-2 bi bi-telephone"></i>
              <div className="p-2 text-gray-400 text-hover-primary fw-bold">
                {tickets?.createdAt}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div
                className="p-0"
                style={{
                  ...getStatusStyle(tickets?.status_type),
                  width: "220px",
                  textAlign: "center",
                }}
                data-bs-toggle="tooltip"
                title={tickets?.status_display}
              >
                <span className="fw-bold">
                  {truncateDescription(tickets?.status_display, 25)}
                </span>
              </div>

              <div
                className="text-gray-400 text-hover-primary fw-bold mx-10"
                data-bs-toggle="tooltip"
                title={tickets?.description}
              >
                {truncateDescription(tickets?.description, 120)}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <span
            className="text-gray-800 fw-bold fs-4 mw-250px"
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            data-bs-toggle="tooltip"
            title={capitalizeAndFormat(
              tickets?.action?.assignee?.username.split("@")[0] ?? "Unassigned"
            )}
          >
            {capitalizeAndFormat(
              tickets?.action?.assignee?.username.split("@")[0] ?? "Unassigned"
            )}
          </span>
          <i
            className="fa fa-solid fa-chevron-down mx-6"
            onClick={openContactModal}
          ></i>
          <ReactModal
            isOpen={isAddNewContactModalOpen}
            onRequestClose={closeContactModal}
            style={customModalStyles}
            contentLabel="New Contact"
          >
            <ChangeUserModal
              closeModal={closeContactModal}
              refetch={refetch}
              refetchAuditTrailForwardedData={refetchAuditTrailForwardedData}
            />
          </ReactModal>

          <ReactModal
            isOpen={openDispositionModal}
            onRequestClose={closeDispositionModall}
            style={customModalStyles}
            contentLabel="New Contact"
          >
            <DispositionTicketModal
              isLoading={isLoading}
              trigger={trigger}
              closeModal={closeDispositionModall}
              storedDisposition={storedDisposition}
              refetch={refetch}
              ticketId={storedTicketId}
              value={""}
            />
          </ReactModal>
        </div>
        <div style={{ marginRight: "1%" }} onClick={openDispositionModall}>
          <i
            className="bi bi-eye-fill"
            style={{ fontSize: "20px" }}
            data-toggle="tooltip"
            data-placement="top"
            title="View disposition"
          ></i>
        </div>
        {/* {tickets?.is_child_ticket === false && (

          <div style={{ marginRight: "1%" }} onClick={openChildTicketModal}>
            <i
              className="fas fa-sitemap cursor-pointer"
              style={{ fontSize: "15px" }}
              data-toggle="tooltip"
              data-placement="top"
              title="Create child ticket"
            ></i>
          </div>
        )} */}
      </div>

      {/* Buttons */}
      <div className="card" style={{ backgroundColor: "#E4E4E47F" }}>
        <div className="d-flex">
          {tickets?.action?.thread && (
            <Link
              to={`/email/inbox/${tickets?.action?.thread[0]?.id}`}
              className="btn-link-wrapper"
            >
              <button
                className="btn text-dark rounded-0 justify-content-center w-100 h-100"
                style={{
                  borderRight: "1px solid rgba(112, 112, 112, 0.5)",
                }}
                data-bs-toggle="tooltip"
                title="Process Email"
              >
                <i className="text-dark bi-chat-left-fill fs-2"></i>
              </button>
            </Link>
          )}

          {!tickets?.action?.thread && (
            <Link to="/email/compose" className="btn-link-wrapper">
              <button
                className="btn text-dark rounded-0 justify-content-center w-100 h-100"
                style={{
                  borderRight: "1px solid rgba(112, 112, 112, 0.5)",
                }}
                data-bs-toggle="tooltip"
                title="Compose Email"
              >
                <i className="text-dark bi bi-pencil-square fs-2"></i>
              </button>
            </Link>
          )}

          <div
            style={{
              borderRight: "1px solid rgba(112, 112, 112, 0.5)",
              width: "150px",
              textAlign: "center",
            }}
          >
            <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
              Assignee
            </div>
            <span className="text-center text-gray-800 text-hover-primary fw-bold">
              {capitalizeAndFormat(
                tickets?.action?.assignee?.username.split("@")[0] ??
                  "Unassigned"
              )}
            </span>
          </div>

          <div
            style={{
              borderRight: "1px solid rgba(112, 112, 112, 0.5)",
              width: "150px",
              textAlign: "center",
            }}
          >
            <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
              Customer Name
            </div>
            <span className="text-gray-800 text-hover-primary fw-bold">
              {capitalizeFirstLetter(tickets?.action?.contact.name)}
            </span>
          </div>

          <div
            style={{
              borderRight: "1px solid rgba(112, 112, 112, 0.5)",
              width: "150px",
              textAlign: "center",
            }}
          >
            <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
              Raised By
            </div>
            <span className="text-gray-800 text-hover-primary fw-bold">
              {tickets?.created_by?.username}
            </span>
          </div>

          <div
            style={{
              borderRight: "1px solid rgba(112, 112, 112, 0.5)",
              width: "120px",
              textAlign: "center",
            }}
          >
            <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
              Priority
            </div>
            <span className="text-gray-800 text-hover-primary fw-bold">
              {capitalizeFirstLetter(tickets?.priority)}
            </span>
          </div>

          <div
            style={{
              borderRight: "1px solid rgba(112, 112, 112, 0.5)",
              width: "120px",
              textAlign: "center",
            }}
          >
            <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
              Workflow
            </div>
            <span className="text-gray-800 text-hover-primary fw-bold">
              {tickets?.action?.disposition?.workflow?.name}
            </span>
          </div>

          <div
            style={{
              borderRight: "1px solid rgba(112, 112, 112, 0.5)",
              width: "150px",
              textAlign: "center",
            }}
          >
            <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
              Created At
            </div>
            <span className="text-gray-800 text-hover-primary fw-bold">
              {tickets?.created
                ? format(new Date(tickets.created), "dd-MM-yyyy HH:mm:ss")
                : ""}
            </span>
          </div>

          <div
            style={{
              borderRight: "1px solid rgba(112, 112, 112, 0.5)",
              width: "150px",
              textAlign: "center",
            }}
          >
            <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
              Current Team
            </div>
            <span className="text-gray-800 text-hover-primary fw-bold">
              {tickets?.team_assignee?.name}
            </span>
          </div>

          {/* Change Status */}
          <div
            className="text-center d-flex align-items-center mx-5"
            style={{ minWidth: "180px" }}
          >
            <FormControl className="w-100">
              <InputLabel id="status-select-label" style={{ zIndex: 0 }}>
                Change Status
              </InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                value={defaultSelect}
                // onChange={handleStatusChange}
                label="Change Status"
                MenuProps={MenuProps}
              >
                {statusOptions?.map((status) => (
                  <MenuItem
                    key={status.id}
                    value={status.status}
                    onClick={() => handlerStatus(status)}
                    data-bs-toggle="tooltip"
                    title={status?.status}
                  >
                    {truncateDescription(status?.status, 25)}
                  </MenuItem>
                ))}
              </Select>
              <ReactModal
                isOpen={isStatusModalOpen}
                onRequestClose={closeStatusModal}
                style={customModalStyles}
                contentLabel="Change Status"
              >
                <ChangeStatusModal
                  data={data}
                  closeStatusModal={closeStatusModal}
                  refetch={refetch}
                  refetchAuditTrail={refetchAuditTrail}
                  selectedStatus={selectedStatus}
                  handleStatusUpdate={handleStatusUpdate}
                  refetchAuditTrailData={refetchAuditTrailData}
                  selectedAllStatus={selectedAllStatus}
                  storingTicketId={storingTicketId}
                  refetchAuditTrailForwardedData={
                    refetchAuditTrailForwardedData
                  }
                />
              </ReactModal>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketListInfoCard;
