import { useEffect, useRef, useState } from 'react'
import ReactModal from 'react-modal';
import AddTheTagBasedSLAModal from './AddTheTagBasedSLAModal';
import { defaultAddEscalationLevelModalStyles } from '../../../../common/CustomModalStyles';
import { useGetTicketTagSLADetailsQuery, useLazyGetTicketTagSLAEscalationLevelsQuery } from '../../../../../Services/settingsAPIs/CommonAPI';
import { useTagsQuery } from '../../../../../Services/settingsAPIs/TicketSettingsAPI';

const sampleTagList = [
    {
        "id": 1,
        "name": "Gold"
    },
    {
        "id": 3,
        "name": "Silver"
    },
    {
        "id": 2,
        "name": "Platinum"
    },
    {
        "id": 4,
        "name": "changed"
    }
];

const sampleTagSLAEscalations = {
	"id": 3,
	"duration_in_ms": 120000,
	"tag_sla_mail": [
		"archana@botgo.io",
		"test01@yahoo.com"
	],
	"team_id": 1,
	"level": {
		"Escalation Level 2": {
			"email": [
				"tiju@botgo.io"
			],
			"duration_in_ms": 0
		},
		"Escalation Level 3": {
			"email": [
				"tiju@botgo.io"
			],
			"duration_in_ms": 0
		},
		"Escalation Level 4": {
			"email": [
				"archana@bluewhirl.io"
			],
			"duration_in_ms": 0
		},
		"Escalation Level 5": {
			"email": [
				"archana@bluewhirl.io"
			],
			"duration_in_ms": 0
		},
		"Escalation Level 6": {
			"email": [
				"tiju@botgo.io"
			],
			"duration_in_ms": 0
		},
		"Escalation Level 7": {
			"email": [
				"tiju@botgo.io"
			],
			"duration_in_ms": 0
		},
		"Escalation Level 8": {
			"email": [
				"tiju@botgo.io"
			],
			"duration_in_ms": 0
		},
		"Escalation Level 9": {
			"email": [
				"tiju@botgo.io"
			],
			"duration_in_ms": 0
		},
		"Escalation Level 10": {
			"email": [
				"tiju@botgo.io"
			],
			"duration_in_ms": 0
		},
		"Escalation Level 11": {
			"email": [
				"tiju@botgo.io"
			],
			"duration_in_ms": 0
		},
		"Escalation Level 12": {
			"email": [
				"tiju@botgo.io"
			],
			"duration_in_ms": 0
		},
		"Escalation Level 13": {
			"email": [
				"tiju@botgo.io"
			],
			"duration_in_ms": 0
		},
		"Escalation Level 14": {
			"email": [
				"tiju@botgo.io",
				"manvendra@yahoo.com"
			],
			"duration_in_ms": 600000
		},
		"Escalation Level 15": {
			"email": [
				"tiju@botgo.io"
			],
			"duration_in_ms": 0
		}
	}
}

const sampleMyEscalations = [
	{
		"id": 1,
		"duration_in_ms": 300000,
		"tag_sla_mail": [
			"anubha@bluewhirl.io"
		],
		"team_id": 1,
		"level": {
			"Escalation Level 2": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 3": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 4": {
				"email": [
					"archana@bluewhirl.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 5": {
				"email": [
					"archana@bluewhirl.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 6": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 7": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 8": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 9": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 10": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 11": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 12": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 13": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 14": {
				"email": [
					"tiju@botgo.io",
					"manvendra@yahoo.com"
				],
				"duration_in_ms": 600000
			},
			"Escalation Level 15": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			}
		},
		"name": "Platinum",
		"showExpanded": false
	},
	{
		"duration_in_ms": 0,
		"tag_sla_mail": [],
		"level": {},
		"id": 2,
		"name": "Gold",
		"showExpanded": false
	},
	{
		"duration_in_ms": 0,
		"tag_sla_mail": [],
		"level": {},
		"id": 3,
		"name": "Silver",
		"showExpanded": false
	},
	{
		"duration_in_ms": 0,
		"tag_sla_mail": [],
		"level": {},
		"id": 4,
		"name": "HNI",
		"showExpanded": false
	},
	{
		"duration_in_ms": 0,
		"tag_sla_mail": [],
		"level": {},
		"id": 6,
		"name": "frequent buyer",
		"showExpanded": false
	},
	{
		"duration_in_ms": 0,
		"tag_sla_mail": [],
		"level": {},
		"id": 6,
		"name": "frequent buyer",
		"showExpanded": false
	}
]

const manvendraResponse = [
    {
        "id": 1,
        "created": "2024-05-23T06:31:10.298784Z",
        "updated": "2024-08-01T07:33:01.327996Z",
        "duration_in_ms": 240000,
        "level": {},
        "tag_type": "ticket_tag",
        "tag_sla_mail": [
            "botgo495@gmail.com"
        ],
        "tag": 2,
        "team": 1
    },
    {
        "id": 2,
        "created": "2024-05-24T10:18:58.621616Z",
        "updated": "2024-08-02T06:08:01.478556Z",
        "duration_in_ms": 120000,
        "level": {},
        "tag_type": "ticket_tag",
        "tag_sla_mail": [
            "mannu10071996@gmail.com",
            "botgo495@gmail.com"
        ],
        "tag": 1,
        "team": 1
    },
    {
        "id": 3,
        "created": "2024-08-02T06:39:27.533418Z",
        "updated": "2024-08-02T06:39:27.533457Z",
        "duration_in_ms": 60000,
        "level": {
			"Escalation Level 2": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 3": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 4": {
				"email": [
					"archana@bluewhirl.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 5": {
				"email": [
					"archana@bluewhirl.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 6": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 7": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 8": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 9": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 10": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 11": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 12": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 13": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			},
			"Escalation Level 14": {
				"email": [
					"tiju@botgo.io",
					"manvendra@yahoo.com"
				],
				"duration_in_ms": 600000
			},
			"Escalation Level 15": {
				"email": [
					"tiju@botgo.io"
				],
				"duration_in_ms": 0
			}
		},
        "tag_type": "ticket_tag",
        "tag_sla_mail": [
            "ghhf@gmail.com"
        ],
        "tag": 3,
        "team": 1
    },
    {
        "id": 4,
        "created": "2024-08-02T07:02:07.027842Z",
        "updated": "2024-08-02T09:58:50.960366Z",
        "duration_in_ms": 60000,
        "level": {},
        "tag_type": "ticket_tag",
        "tag_sla_mail": [
            "test@gmail.com"
        ],
        "tag": 4,
        "team": 1
    }
]

const TheTagBasedSLA = () => {
    const tempTagIndex = useRef(0);
    const {data: alltags, isLoading: isLoadingAllTags} = useTagsQuery({});
    const [taglist, setTaglist] = useState<any[]>();
    const {data: ticketTagSLAData, isLoading: isLoadingTagsSLAData} = useGetTicketTagSLADetailsQuery(taglist && taglist[tempTagIndex.current]?.id, {skip: taglist===undefined});   /*>> this query has to be repeatatively triggered for each tag id from tag list */
    /* const {data: ticketTagsSLAEscalationsData} = useLazyGetTicketTagSLAEscalationLevelsQuery({}); */
    const [tagInformations, setTagInformations] = useState<any[]>(sampleMyEscalations);
    const [emails, setEmails] = useState<string[]>([]);
    const [emailInput, setEmailInput] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [escalationsForTags, setEscalationsForTags] = useState<any>([
      {tag: "Platinum", escalations: [{level:1, atElapsedTime:{hours:1, minutes:0}, escalationTo:'jay@bluewhirl.io'}],showExpanded:false},
      {tag: "Gold", 
        escalations: [
          {level:1, atElapsedTime:{hours:0, minutes:30}, escalationTo:'coy@bluewhirl.io'},
          {level:2, atElapsedTime:{hours:0, minutes:45}, escalationTo:'boy@bluewhirl.io'},
          {level:3, atElapsedTime:{hours:2, minutes:0}, escalationTo:'joy@bluewhirl.io'},
        ],showExpanded:false},
      {tag: "Silver", escalations: [{level:1, atElapsedTime:{hours:3, minutes:0}, escalationTo:'riya@bluewhirl.io'}],showExpanded:false},
      {tag: "Frequent Buyer", escalations: [{level:1, atElapsedTime:{hours:4, minutes:0}, escalationTo:'joy@bluewhirl.io'}],showExpanded:false}
    ]);

    const toggleExpansion = (tagInfoIndex: number) => {
      setTagInformations((prev:any)=>{
        const updated = prev.map((tagInfo: any, index:number)=>index===tagInfoIndex?{...tagInfo,showExpanded:!tagInfo.showExpanded}: tagInfo);
        return updated;
      })
    }

    function isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }

    const openModal = () => { setIsModalOpen(true) }  
    const closeModal = () => { setIsModalOpen(false) }

    const handleInputKeyPress = (event:any) => {
      if (event.key === 'Enter' && emailInput.trim() !== '') {
        setEmails([...emails, emailInput.trim()]);
        setEmailInput(''); // Clear the input field
      }
    };

	useEffect(()=>{
		if(manvendraResponse?.length>0 && taglist?.length>0){
			const tagConfigObjs = manvendraResponse.map((tagObj:any)=>({...tagObj, showExpanded:false, name: taglist.find(tag=>tag.id===tagObj.tag)?.name}));
			console.dir(tagConfigObjs)
			setTagInformations(tagConfigObjs);
		}
		},[taglist])
    useEffect(()=>{!isLoadingAllTags && alltags && setTaglist(alltags)},[alltags])
    /* useEffect(()=>{!isLoadingTagsSLAData && ticketTagSLAData && 
      setTagInformations((prev:any)=>{return [...prev, {...ticketTagSLAData, ...taglist[tempTagIndex.current-1], showExpanded:false}]})
      tempTagIndex.current<taglist?.length-1 && tempTagIndex.current++;
    },[ticketTagSLAData]) */

    return (
      <div>
        <div className="row mb-2 mt-4 overflow-auto">
          <div className="row">
            <div className="col">
              <div className="card my-1 min-h-40px min-w-600px justify-content-center" style={{backgroundColor: 'rgba(228, 228, 228, 0.498)'}}>
                <div className="row align-items-center">
                  <div className="col-lg-4 col text-start ps-10"><label htmlFor="">Tag</label></div>
                  <div className="col-lg-2 col text-center"><label htmlFor="">Escalation Level</label></div>
                  <div className="col-1 col text-center"><label htmlFor="">Hours</label></div>
                  <div className="col-1 col text-center"><label htmlFor="">Minutes</label></div>
                  <div className="col-lg-2 col text-center"><label htmlFor="">Mail ID</label></div>
                  <div className="col-lg-2 col text-end"><label htmlFor=""></label></div>
                </div>
              </div>
            </div>
          </div>
          {escalationsForTags.map((stage: any, stageIndex)=> (<>
            <div className="row d-none">
              <div className="col">
                <div className="input-group-text mt-2 bg-white min-w-600px">
                  <div className="row w-100 align-items-center">
                  {stage.escalations.map((escalation, escalationIndex) => <>
                    <div className={`row ${escalationIndex>0 && !stage.showExpanded ?'d-none':''}`}>
                    <div className="col-4 text-start">{escalationIndex===0 && stage.tag}</div>
                    <div className="col-2 text-center"><label htmlFor="">{escalation.level}</label></div>
                    <div className="col-1 text-center">
                      <div className="d-flex justify-content-center align-items-center">
                      <input type="number"
                        className="form-control border-0 py-1"
                        min={0}
                        />
                      </div>
                    </div>
                    <div className="col-1 text-center">
                      <div className="d-flex justify-content-center align-items-center">
                      <input type="number"
                        className="form-control border-0 py-1"
                        min={0}
                        />
                      </div>
                    </div>
                    <div className="col-2 text-center">{escalation.escalationTo}</div>
                    <div className="col-2 text-end">
                      <button className="btn btn-link py-0"><i className="text-dark bi bi-plus-lg" onClick={openModal}></i></button>
                      <button className="btn btn-link py-0">
                        <i 
                          className={`ms-2 text-dark ${escalationIndex===0?(stage.showExpanded?'bi bi-chevron-up':'bi bi-chevron-down'):'bi bi-bookmark'}`} 
                          onClick={()=>toggleExpansion(stageIndex)}>
                        </i>
                      </button>
                    </div>
                    </div>
                  </>)}
                  </div>
                </div>  
              </div>
            </div>
          </>))}

          {tagInformations.map((tagInformation: any, tagInfoIndex)=> (<>
            <div className="row">
              <div className="col">
                <div className="input-group-text mt-2 bg-white min-w-600px">
                  <div className="row w-100 align-items-center">
                  {!isEmpty(tagInformation?.level) ? Object.keys(tagInformation.level).map((levelconfig, esclevelindex) => <>
                    <div className={`row ${esclevelindex>0 && !tagInformation.showExpanded ?'d-none':''}`}>
                    <div className="col-4 text-start">{esclevelindex===0 && tagInformation.name}</div>
                    <div className="col-2 text-center"><label htmlFor="">{levelconfig}</label></div>
                    <div className="col-1 text-center">
                      <div className="d-flex justify-content-center align-items-center">
                      <input type="number"
                        className="form-control border-0 py-1"
                        min={0}
                        value={tagInformation.duration_in_ms%60000}
                        />
                      </div>
                    </div>
                    <div className="col-1 text-center">
                      <div className="d-flex justify-content-center align-items-center">
                      <input type="number"
                        className="form-control border-0 py-1"
                        min={0}
                        value={tagInformation.duration_in_ms/60000}
                        />
                      </div>
                    </div>
                    <div className="col-2 text-center">
                      <input type="text"
                        className="form-control border-0 py-1"
                        placeholder={tagInformation.level[levelconfig].email.join('; ')}
                        onKeyPress={handleInputKeyPress}
                        onChange={(evt)=>{setTagInformations((prev:any)=>{
                          const updated = prev.map(tagInfo=>tagInfo.id===tagInformation.id ? tagInfo.level[levelconfig].email=[...tagInfo.level[levelconfig].email,...emails]: tagInfo)
                          return updated;
                        })}}
                        />
                    </div>
                    <div className="col-2 text-end">
                      <button className="btn btn-link py-0"><i className="text-dark bi bi-plus-lg" onClick={openModal}></i></button>
                      <button className="btn btn-link py-0">
                        <i 
                          className={`ms-2 text-dark ${esclevelindex===0?(tagInformation.showExpanded?'bi bi-chevron-up':'bi bi-chevron-down'):'bi bi-bookmark'}`} 
                          onClick={()=>toggleExpansion(tagInfoIndex)}>
                        </i>
                      </button>
                    </div>
                    </div>
                  </>):(<>
                    <div className="row">
                    <div className="col-4 text-start">{tagInformation.name}</div>
                    <div className="col-2 text-center"><label htmlFor="">Escalation Level 1</label></div>
                    <div className="col-1 text-center">
                      <div className="d-flex justify-content-center align-items-center">
                      <input type="number"
                        className="form-control border-0 py-1"
                        min={0}
                        value={tagInformation.duration_in_ms%60000}
                        />
                      </div>
                    </div>
                    <div className="col-1 text-center">
                      <div className="d-flex justify-content-center align-items-center">
                      <input type="number"
                        className="form-control border-0 py-1"
                        min={0}
                        value={tagInformation.duration_in_ms/60000}
                        />
                      </div>
                    </div>
                    <div className="col-2 text-center">
                      <input type="text"
                        className="form-control border-0 py-1"
                        placeholder={tagInformation.tag_sla_mail.join('; ')}
                        onKeyPress={handleInputKeyPress}
                        onChange={(evt)=>{setTagInformations((prev:any)=>{
                          const updated = prev.map(tagInfo=>tagInfo.id===tagInformation.id ? tagInfo.tag_sla_mail=[...tagInfo.tag_sla_mail,...emails]: tagInfo)
                          return updated;
                        })}}
                        />
                    </div>
                    <div className="col-2 text-end">
                      <button className="btn btn-link py-0"><i className="text-dark bi bi-plus-lg" onClick={openModal}></i></button>
                    </div>
                    </div>
                  </>)}
                  </div>
                </div>  
              </div>
            </div>
          </>))}
        </div>
        <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={defaultAddEscalationLevelModalStyles}
          contentLabel="New Ticket"
          >
            <AddTheTagBasedSLAModal closeModal={closeModal}/>
        </ReactModal>
      </div>
    )
  }

export default TheTagBasedSLA