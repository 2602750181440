import React, { useState, useEffect } from "react";
import { useTelephony } from "../context/TelephonyContext";
import { INCOMING_CALL, OUTGOING_CALL, TELEPHONY_LOGIN } from "../../constants";

interface IPROPS {
    callPaused: any;
    call: any;
    isCallPause: any;
}


// Pause
// on Call Disconnect make it 0
const TelephonyTimer: React.FC<IPROPS> = ({ callPaused, call, isCallPause }) => {
    const { callDisconnected } = useTelephony();

    const [timeInSeconds, setTimeInSeconds] = useState<number>(() => {
        const savedTime = localStorage.getItem("telephony-timer");
        return savedTime ? parseInt(savedTime, 10) : 0;
    });

    console.log(timeInSeconds, "timeInSeconds")

    console.log(callDisconnected, "dcsdcdscsdc")

    const incomingCallActive = localStorage.getItem(INCOMING_CALL) === "true"
    const outgoingCallActive = localStorage.getItem(OUTGOING_CALL) === "true"
    // Format seconds into HH:MM:SS
    const formatTime = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
            2,
            "0"
        )}:${String(secs).padStart(2, "0")}`;
    };

    useEffect(() => {
        if (callDisconnected) {
            setTimeInSeconds(0);
            localStorage.removeItem("telephony-timer");
        }
    }, [callDisconnected]);



    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
    
        if (incomingCallActive || outgoingCallActive) {
            timer = setInterval(() => {
                setTimeInSeconds((prev) => {
                    const updatedTime = prev + 1;
                    // localStorage.setItem("telephony-timer", updatedTime.toString());
                    return updatedTime;
                });
            }, 1000);
        }
    
        // Cleanup function to clear the interval
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [incomingCallActive, outgoingCallActive]); // Add dependencies to control re-running the effect
    
    // useEffect(() => {
    //     // Sync state to localStorage whenever it changes
    //     if (timeInSeconds === 0 && callDisconnected) {
    //         localStorage.removeItem("telephony-timer");
    //     } else {
    //         localStorage.setItem("telephony-timer", timeInSeconds.toString());
    //     }
    // }, [timeInSeconds, callDisconnected]);

    // console.log(incomingCallActive, "dcdscsc")
    // useEffect(() => {
    //     let timer: NodeJS.Timeout | undefined;

    //     // eslint-disable-next-line no-mixed-operators
    //     if (incomingCallActive || outgoingCallActive && !isCallPause && !callDisconnected) {
    //         timer = setInterval(() => {
    //             setTimeInSeconds((prev) => {
    //                 const updatedTime = prev + 1;
    //                 localStorage.setItem("telephony-timer", updatedTime.toString());
    //                 return updatedTime;
    //             });
    //         }, 1000);
    //     }

    //     return () => {
    //         if (timer) clearInterval(timer); // Cleanup interval on component unmount
    //     };
    // }, [outgoingCallActive, incomingCallActive, isCallPause, callDisconnected]);

    const gettingTelephonyLoginData = localStorage.getItem(TELEPHONY_LOGIN)

    // useEffect(() => {


    // }, [])


    return (
        <div style={{ display: "flex" }}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <i
                     data-toggle="tooltip"
                     data-placement="top"
                     title={gettingTelephonyLoginData === null ? "logout" : call ? "On Call" : !callPaused ? "On Pause" : "Ready"}
                    className="bi bi-circle-fill me-4"
                    style={{
                        fontSize: "20px",
                        color:gettingTelephonyLoginData === null ? "red" : call ? "orange" : !callPaused ? "yellow" : "#7acf7a",
                    }}
                ></i>
            </div>
            <div style={{ textAlign: "center", height: "100%" }}>
                <span style={{ padding: "10px" }} className="form-control">
                    {formatTime(timeInSeconds)}
                </span>
            </div>
        </div>
    );
};

export default TelephonyTimer;
