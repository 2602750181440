import React, { useEffect, useState } from "react";
import { useInternalNotesMutation } from "../../../Services/Messages/Messages";
import { useParams } from "react-router-dom";
import { Loader } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";
import { Box, Chip, Icon } from "@mui/material";

interface IPROPS {
  internalNotes: any;
  fetchingNotes: any;
}

const InternalMessage: React.FC<IPROPS> = ({
  internalNotes,
  fetchingNotes,
}) => {
  const [isSendMsgEditOpen, setIsSendMsgEditOpen] = useState<boolean>(false);
  const [content, setContent] = useState("");
  const [initials, setInitials] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [files, setFiles] = useState([]);

  const { uuid } = useParams();

  const [trigger, { data: internoteData, isLoading, isError }] =
    useInternalNotesMutation();


  function formatDate(dateString: string) {
    const date = new Date(dateString);

    // Extract components
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Format the date and time
    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}`;

    return `${formattedDate} ${formattedTime}`;
  }

  const internalNoteSubmitHandler = async () => {
    setIsSendMsgEditOpen(false);

    const internalNoteObj = {
      attachments: files,
      content: content,
      ticket: uuid,
    };
    try {
      const result = await trigger(internalNoteObj).unwrap();
      toast.success("Note saved successfully");
      setContent("");
      setFiles([]);
      fetchingNotes();
    } catch (error) {

    }
  };

  useEffect(() => {
    // Retrieve the username from local storage
    const storedUsername = localStorage.getItem("circleone-username") || "";

    // Generate initials
    const nameParts = storedUsername.split(" ");
    const generatedInitials = nameParts
      .map((part) => part.charAt(0))
      .join("")
      .toUpperCase();

    // Set initials and username state
    setInitials(generatedInitials);
    setUsername(storedUsername);
  }, []);

  const handleDeleteFile = (fileToDelete) => () => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };


  const handleFileChange = async (event) => {
    const newFiles = Array.from(event.target.files);
    const filePromises = newFiles.map((file: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const fileData = {
            file_name: file.name,
            file: e.target.result.split(",")[1], // Extracting Base64 part
          };
          resolve(fileData);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });

    try {
      const fileDataArray = await Promise.all(filePromises);
      setFiles((prevFiles) => {
        return [...prevFiles, ...fileDataArray];
      });
    } catch (error) {
      toast.error("An error occurred while uploading files. Please try again.");
    }
  };


  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "end" }}
        className={`align-self-end mt-2 mb-4 ${isSendMsgEditOpen ? "d-none" : ""
          }`}
      >
        <button
          className="btn btn-primary btn-sm"
          onClick={() => {
            setIsSendMsgEditOpen(true);
          }}
        >
          + Add note
        </button>
      </div>
      <div
        className={`card card-body gap-2 my-2 ${isSendMsgEditOpen ? "" : "d-none"
          }`}
      >
        <textarea
          className="form-control form-control-lg form-control-solid mb-2"
          name="account_name"
          placeholder=""
          value={content}
          onChange={(e) => setContent(e.target.value)}
          rows={3}
        />

        {files.length > 0 && (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
            {files.map((file, index) => (
              <Chip
                key={index}
                label={file.file_name}
                onDelete={handleDeleteFile(file)}
              />
            ))}
          </Box>
        )}

        {/* <i className="bi bi-paperclip fs-1 mx-2 align-self-center"></i> */}
        <div className="d-flex justify-content-between align-items-center mt-2">

          <div className="d-flex">
            <label
              htmlFor="attachFileInput"
              className={`btn btn-active-light-primary btn-icon ${content ? "" : "disabled"
                }`}
              title="You can add attachments for the following 
           file types: xls,pdf,csv,doc,jpg,png up to a maximum size of 15MB"
            >
              <i className="bi bi-paperclip align-self-center text-dark fs-2 mx-2 "></i>
              <input
                type="file"
                id="attachFileInput"
                className="d-none"
                multiple
                onChange={handleFileChange}
                disabled={!content}
              />
            </label>

            {/* <i
              className="bi bi-card-checklist text-dark fs-1 mx-2 align-self-center"

              title="Open Templates"
            ></i> */}

          </div>
          <button
            type="submit"
            className="btn btn-sm btn-primary"
            onClick={internalNoteSubmitHandler}
          >
            {isLoading ? (
              <>
                Saving.. <Loader />
              </>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>

      <div
        className="card p-2 my-1 d-flex flex-column"
        style={{ maxHeight: "300px", height: "100%", overflow: "scroll" }}
      >
        {/* Header Row */}
        <div
          className="d-flex bg-light p-2 border-bottom"
          style={{ fontWeight: "bold", textAlign: "center" }}
        >
          <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
            Name
          </div>
          <div style={{ flex: 2, display: "flex", justifyContent: "center", alignItems: "center" }}>
            Notes
          </div>
          <div style={{ flex: 2, display: "flex", justifyContent: "center", alignItems: "center" }}>
            Attachments
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
            Date and time
          </div>
        </div>

        {/* Notes List */}
        {internalNotes?.map((el: any) => (
          <div
            className="d-flex align-items-center"
            style={{ width: "100%" }}
            key={el.id} // Assuming el has a unique id
          >
            {/* Name */}
            <div
              className="d-flex flex-column align-items-center"
              style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <div className="symbol symbol-30px symbol-circle fs-2">
                <span className="symbol-label bg-primary text-inverse-warning fw-bold">
                  {initials}
                </span>
              </div>
              <span>{username.split("@")[0]}</span>
            </div>

            {/* Notes */}
            <div
              style={{
                flex: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="my-2 text-center">{el?.content}</p>
            </div>

            {/* Attachment */}
            <div
              style={{
                flex: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {el.attachments?.length > 0 ? (
                <div className="d-flex flex-wrap gap-2">
                  {el.attachments.map((attachment, index) => (
                    <Chip
                      key={attachment.id}
                      label={`Attachment ${index + 1}`}
                      clickable
                      onClick={() => {
                        const newWindow = window.open(attachment.file, "_blank");
                        if (!newWindow || newWindow.closed || typeof newWindow.closed === "undefined") {
                          alert("Popup blocked! Please allow popups for this site.");
                        }
                      }}
                      className="secondary"
                    />
                  ))}
                </div>
              ) : (
                <span>No attachments</span>
              )}
            </div>

            {/* Date and Time */}
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="text-muted text-nowrap">
                {el?.created ? formatDate(el.created) : "Date not available"}
              </p>
            </div>
          </div>
        ))}
      </div>

    </div>
  );
};

export default InternalMessage;