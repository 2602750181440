import React, { useState } from "react";
interface IProps {
  mailHistory: any;
}

const MailHistory: React.FC<IProps> = ({ mailHistory }) => {
  const [showAll, setShowAll] = useState(false);

  const formatDate = (timestamp: string) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };


  const getIconClasses = (channel: any) => {
    switch (channel) {
      case "Email":
        return "fa fa-envelope fs-6 text-white";
      case "outbound_phone":
      case "inbound_phone":
        return "bi bi-telephone fs-6 text-white";
      case "Chat":
        return "bi bi-chat-left-dots fs-6 text-dark";
      case "Whatsapp":
        return "bi bi-whatsapp fs-4 text-success";
      case "email":
        return "fa fa-envelope fs-6 text-white";
      case "app":
        return "bi bi-google-play fs-6 text-white";
      case "Social-Media":
        return "bi bi-dribbble fs-6 text-white";
      case "Facebook":
        return "bi bi-facebook fs-6 text-white";
      default:
        return "fa fa-envelope fs-6 text-white";
    }
  };

  const getBadgeClasses = (channel: any) => {
    switch (channel) {
      case "Email":
        return "bg-warning rounded-circle";
      case "outbound_phone":
        return "bg-success rounded-circle";
      case "inbound_phone":
        return "bg-danger rounded-circle";
      case "Chat":
        return "bg-white border border-dark rounded-circle";
      case "Whatsapp":
        return "bg-success rounded-circle";
      case "email":
        return "bg-warning rounded-circle";
      case "app":
        return "bg-success rounded-circle";
      case "Social-Media":
        return "bg-primary rounded-circle";
      case "Facebook":
        return "bg-primary rounded-circle";
      default:
        return "bg-warning rounded-circle";
    }
  };
  const displayedData = showAll ? mailHistory : mailHistory?.slice(0, 5);

  return (
    <div className="pt-10">
      <div className="circleOnetimeline-label ">
        {displayedData?.map((item: any, index: number) => (
          <div
            key={index}
            className="timeline-item ms-15 d-flex align-items-center"
          >
            <div className="circleOnetimeline-label fw-bold text-gray-800 fs-6">
              {item.time}
            </div>
            <div className="timeline-badge">
              <div
                className={getBadgeClasses(item?.ticket?.ticket_channel)}
                style={{ padding: "10px" }}
              >
                <i className={getIconClasses(item?.ticket?.ticket_channel)}></i>
              </div>
            </div>
            <div className="fw-normal timeline-content bg-secondary p-2 mx-10 overflow-hidden ">
              <div>{item.message}</div>
              <div className="text-muted px-2">{item?.ticket?.description}</div>
              <div className="text-primary px-2">
                Ticket ID: {item?.ticket?.id ? item?.ticket?.id : item?.ref_id}
              </div>
              <div className="text-muted px-2">
                {item?.ticket?.created
                  ? formatDate(item.ticket.created)
                  : item?.created
                    ? formatDate(item.created)
                    : "Null"}
              </div>
              <div className="text-muted px-2">
                Assignee: {item?.assignee?.username}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className="d-flex flex-row-reverse mt-4 "
        style={{ marginRight: "20px" }}
      >
        <p
          className="text-muted cursor-pointer"
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? "Show Less" : "View More"}
        </p>
      </div>
    </div>
  );
};

export default MailHistory;
