import { Route, Routes, Outlet } from 'react-router-dom'
import TicketDashboard from './ticket/TicketDashboard'
import CampaignDashboard from './campaign/CampaignDashboard'
import Dashboard from './email/EmailDashboard'
import RTMDashboard from './rtm/RTMDashboard'


const DashboardRoutes = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <>
                    <Route path='campaign-dashboard' element={<CampaignDashboard />} />
                    <Route path='email-dashboard' element={<Dashboard />} />
                    <Route path='rtm-dashboard' element={<RTMDashboard />} />
                    <Route path='ticket-dashboard' element={<TicketDashboard />} />
                </>
            </Route>
        </Routes>
    )
}

export default DashboardRoutes
