import { useState } from "react";
import BusinessHours from "./BusinessHours";
import DispositionSettings from "./DispositionSettings";
import PrioritySetting from "./PrioritySetting";

const TicketSettings = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const tabTitles = ["Disposition", "Priority", "Business hours"];

  return (
    <div className="text-start">
      <div>
        <ul className="nav nav-stretch nav-line-tabs text-primary border-transparent d-flex flex-nowrap mb-5">
          {tabTitles.map((title, index) => (
            <li className="nav-item" key={Math.random()}>
              <span
                className={`nav-link cursor-pointer fs-4 fw-bold ${
                  activeTabIndex === index ? "active fw-bolder" : ""
                }`}
                onClick={() => {
                  setActiveTabIndex(index);
                }}
                role="tab"
              >
                {title}
              </span>
            </li>
          ))}
        </ul>
      </div>

      {activeTabIndex === 0 && <DispositionSettings />}

      <div className="scroll-y">
        {activeTabIndex === 1 && <PrioritySetting />}
      </div>

      {activeTabIndex === 2 && <BusinessHours />}
    </div>
  );
};

export default TicketSettings;
