import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import {
  useBulkSortingMutation,
  useUserDynamicFieldsQuery,
} from "../../../../Services/settingsAPIs/CommonAPI";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../LoadingSpinner";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableAccountField from "../DragAccountFields";
import DeleteModal from "../../../reusableComponent/DeleteModal";
import EditModal from "../../../reusableComponent/EditModal";
import AddFieldModal from "../AddFieldModal";
import { Loader } from "react-bootstrap-typeahead";

interface LiftingDropData {
  choice?: string;
  fieldId?: string;
}

const AccountFieldsPane = () => {
  const [isAddAccountFieldsModalOpen, setIsAddAccountFieldsModalOpen] =
    useState(false);
  const [accountFields, setAccountFields] = useState([]);
  const [storingFieldData, setStoringFieldData] = useState(null);
  const [isDeleteButtonClicked, setIsDeleteButtonClicked] = useState(false);
  const [liftingDropData, setLiftingDropData] = useState<LiftingDropData>({});
  const [triggerBulkSorting] = useBulkSortingMutation();

  const [isEditFieldModalOpen, setIsEditFieldModalOpen] = useState(false);
  const [isModified, setIsModified] = useState(false);

  const account = "account";

  const { data, refetch, isLoading } = useUserDynamicFieldsQuery(account);

  const openModal = () => {
    setIsAddAccountFieldsModalOpen(true);
  };
  const closeModal = () => {
    setIsAddAccountFieldsModalOpen(false);
  };

  useEffect(() => {
    if (data) {
      setAccountFields(data);
    }
  }, [data]);

  const [expandForMoreSpecifications, setExpandForMoreSpecifications] =
    useState({ expansionOn: -1, expanded: false });

  const [editDropdownListItem, setEditDropdownListItem] = useState({
    enabled: false,
    forChoiceId: -1,
    currentVal: liftingDropData?.choice ? liftingDropData?.choice : "",
    fieldId: "",
  });

  const customModalStyles: ReactModal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxHeight: "500px",
    },
  };

  const moveField = (fromIndex, toIndex) => {
    const updatedFields = [...accountFields];
    const [movedField] = updatedFields.splice(fromIndex, 1);
    updatedFields.splice(toIndex, 0, movedField);
    setAccountFields(updatedFields);
    setIsModified(true); // Set isModified to true when the fields are modified
  };

  const closeDeleteModal = () => {
    setIsDeleteButtonClicked(false);
  };

  const deleteDataHandler = async (field: any) => {
    setIsDeleteButtonClicked(true);
    setStoringFieldData(field);
  };

  const editFieldsHandler = (fields: any) => {
    setIsEditFieldModalOpen(true);
    setStoringFieldData(fields);
  };

  const closeEditFieldModal = () => {
    setIsEditFieldModalOpen(false);
  };

  const handleBulkSorting = async () => {
    try {
      const payload = accountFields.map((field, index) => ({
        id: field.id,
        order_value: index + 1,
      }));
      await triggerBulkSorting({ body: payload });
      toast.success("Sequencing updated successfully");
      setIsModified(false); // Reset isModified to false after successful
    } catch (error) {
      toast.error("Error occurred during sequencing");
    }
  };

  const deleteCustomModalStyles: ReactModal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      width: "30%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const customModalStyles2: ReactModal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      width: "30%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <span className="input-group-text mt-2" id="basic-addon1">
            Customer account fields
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <span
            className="input-group-text mt-2 bg-white border-0"
            id="basic-addon1"
          >
            <div className="ms-auto" style={{ width: "120px" }}>
              <button
                style={{ display: "flex", justifyContent: "center" }}
                className="input-group-text form-control form-control align-items-center text-hover-primary fw-bold"
                onClick={openModal}
              >
                <i className="bi bi-plus-lg me-2"></i>
                <span>Option</span>
              </button>
            </div>
          </span>
          <ReactModal
            isOpen={isAddAccountFieldsModalOpen}
            onRequestClose={closeModal}
            style={customModalStyles}
            contentLabel="Add a new Tag"
          >
            <AddFieldModal
              closeModal={closeModal}
              refetch={refetch}
              modalName="account"
            />
          </ReactModal>
        </div>
      </div>

      <div className="row"  style={{maxHeight: "60vh"}}>
        {/* <div className="col" style={{ marginRight: "0px", marginLeft: "0" }}> */}
          <div className="row" style={{paddingRight: "0"}}>
            <div
              className="col px-2"
              style={{ paddingRight: "5px", paddingLeft: "5px" }}
              >
              <div
                className="card px-2 my-1 min-w-600px"
                style={{
                  backgroundColor: "#E4E4E47F",
                }}
              >
                <div className="row align-items-center py-4">
                  <div className="col-4 text-start ps-lg-10 ps-4">
                    <strong>Label</strong>
                  </div>
                  <div
                    className="col text-start"
                    style={{ textAlign: "justify" }}
                  >
                    <strong>Field Type</strong>
                  </div>
                  <div className="col text-center">
                    <strong>Mandatory</strong>
                  </div>
                  <div className="col text-center">
                    <strong>Unique</strong>
                  </div>
                  <div className="col text-center">
                    <strong>Masking</strong>
                  </div>
                  <div className="col text-center">
                    <strong>Editable</strong>
                  </div>
                  <div className="col text-end me-2">
                    <strong style={{ paddingRight: "10px" }}>Actions</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{maxHeight: "53vh", overflow: "scroll"}}>
          <div className="row">
            <div className="col px-2">
              <div
                className="card  min-w-600px"
                style={{ cursor: "grab", padding: "7px" }}
              >
                <div className="row align-items-center">
                  <div
                    className="col-4 text-start ps-lg-10 ps-4"
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                      width: "34%",
                      paddingRight: "0px",
                    }}
                  >
                    <label>Business name</label>
                    <div></div>
                  </div>
                  <div
                    className="col-2 d-flex align-items-end dropdown"
                    style={{
                      textAlign: "justify",
                      justifyContent: "start",
                      width: "10%",
                    }}
                  >
                    <div className="w-100 mw-150px">
                      <label className="form form-label">Text</label>
                    </div>
                  </div>
                  <div className="col text-center">
                    <i
                      className="me-4 fs-2 bi bi-x"
                      style={{ cursor: "pointer", color: "black" }}
                    ></i>
                  </div>
                  <div className="col text-center">
                    <i
                      className="me-4 fs-2 bi bi-x"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </div>
                  <div className="col text-center">
                    <i
                      className="me-4 fs-2 bi bi-x"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </div>
                  <div className="col text-center">
                    <i
                      className="me-4 fs-2 bi bi-check2"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </div>
                  <div className="col text-end d-flex"></div>
                </div>
              </div>
            </div>
          </div>

          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <DndProvider backend={HTML5Backend}>
              <div className="row">
                <div
                  className="col px-2"
                  style={{ flex: 1, overflowY: "auto" }}
                >
                  {accountFields.map((field: any, index: any) => (
                    <DraggableAccountField
                      key={index}
                      field={field}
                      index={index}
                      moveField={moveField}
                      expandForMoreSpecifications={expandForMoreSpecifications}
                      setExpandForMoreSpecifications={
                        setExpandForMoreSpecifications
                      }
                      setEditDropdownListItem={setEditDropdownListItem}
                      editDropdownListItem={editDropdownListItem}
                      refetch={refetch}
                      editFieldsHandler={editFieldsHandler}
                      deleteDataHandler={deleteDataHandler}
                      setLiftingDropData={setLiftingDropData}
                    />
                  ))}
                </div>
              </div>
            </DndProvider>
          )}
        </div>
        <div className="text-end mt-4 ">
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          onClick={handleBulkSorting}
          disabled={!isModified || isLoading} // Disable the button if there are no modifications or if it's loading
        >
          {isLoading ? (
            <>
              Saving... <Loader />
            </>
          ) : (
            "Save"
          )}
        </button>
      </div>
      </div>
     

      <ReactModal
        isOpen={isEditFieldModalOpen}
        onRequestClose={closeEditFieldModal}
        style={customModalStyles2}
      >
        <EditModal
          closeModal={closeEditFieldModal}
          setFieldsData={setAccountFields}
          fieldsData={accountFields}
          storingFieldData={storingFieldData}
          refetch={refetch}
        />
      </ReactModal>

      <ReactModal
        isOpen={isDeleteButtonClicked}
        onRequestClose={closeDeleteModal}
        style={deleteCustomModalStyles}
      >
        <DeleteModal
          closeModal={closeDeleteModal}
          setFieldsData={setAccountFields}
          fieldsData={accountFields}
          storingFieldData={storingFieldData}
          refetch={refetch}
        />
      </ReactModal>
    </>
  );
};

export default AccountFieldsPane;
