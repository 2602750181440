import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';



const TicketReportList = () => {
    const [hoveredCard, setHoveredCard] = useState(null);
    const navigate = useNavigate();

    const handleMouseEnter = (index) => {
        setHoveredCard(index);
    };

    const handleMouseLeave = () => {
        setHoveredCard(null);
    };

    const toNavigate = (path) => {
        navigate(`/reports/ticket${path}`);
    };
    const reports = [
        { title: 'Ticket Lifecycle',description:"The entire journey of a ticket from creation to resolution", to: '/ticket-life-cycle-report',image:"https://us.123rf.com/450wm/rastudio/rastudio2009/rastudio200900566/155733411-devops-team-abstract-concept-vector-illustration.jpg?ver=6" },
        { title: 'Category Analytics', description:"The turnaround (TAT) for different issues reported", to: '/issue-type-wise-tat-report',image:"https://www.cflowapps.com/wp-content/uploads/2023/04/turnaround-time-improvement.jpg" },
        { title: 'Subcategory Analytics',description:'Detailed turnaround time for specific subcategories of reported issues', to: '/sub-issue-type-wise-tat-report', image:"https://cdni.iconscout.com/illustration/premium/thumb/hacker-attack-8022691-6460447.png"},
        { title: 'TAT By Department', description:'TAT of departments for resolutions', to: '/department-wise-tat-report',image:"https://d1avenlh0i1xmr.cloudfront.net/9cb73a64-b674-41c1-9992-b6931dd41488/different-departments.jpg" },
        // { title: 'Status Level Audit Report', description:'', to: '/status-level-audit-report',image:"https://cdn.vectorstock.com/i/500p/14/01/market-analytic-report-financial-audit-marketing-vector-26081401.jpg" },
        { title: 'Audit Trail', description:'All ticket related actions and changes', to: '/audit-ticket-report' ,image:"https://cdn.iconscout.com/icon/premium/png-256-thumb/online-ticket-4218834-3500902.png" },
        { title: 'Ticket Status Summary', description:'Overview of the current statuses of all tickets in the respective processes', to: '/latest-status-report',image:"https://cdni.iconscout.com/illustration/premium/thumb/startup-launch-10080536-8184515.png" },
    ];
    const getCardStyle = (index) => ({
        transition: 'transform 0.3s, box-shadow 0.3s',
        transform: hoveredCard === index ? 'translateY(-5px)' : 'none',
        boxShadow: hoveredCard === index ? '0 4px 20px rgba(0, 0, 0, 0.1)' : 'none',
    });
    return (
        <>
        <div id="kt_app_toolbar" className="app-toolbar">
            <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h2 className="page-heading d-flex flex-column justify-content-center my-0">
                   Ticket
                    </h2>
               
                </div>
            
            </div>
        </div>
    
        <div className='row gy-0 gx-12'>
            {reports.map((report, index) => (
                <div className='col-xl-4 mt-5' key={index}>
                    <div
                        className='card card-xl-stretch bg-body border-0'
                        style={getCardStyle(index)}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => toNavigate(report.to)}
                    >
                        <div className='card-body d-flex flex-column flex-lg-row flex-stack'>
                            <div className='d-flex flex-column justify-content-center align-items-center align-items-lg-start me-10 text-center text-lg-start'>
                                <h3 className='fs-1hx line-height-lg mb-5'>
                                    <span className='fw-bold'>{report.title}</span>
                                </h3>
                                <div className='fs-6 text-muted mb-7'>
                                   {report.description}
                                </div>
                                <button
                                    className='btn btn-primary fw-semibold px-6 py-3'
                                    onClick={() => toNavigate(report.to)}
                                >
                                    View Reports
                                </button>
                            </div>
                            <img
                                src={report.image}
                                alt='report-icon'
                                className='mw-200px mw-lg-200px '
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
        </>
    );
};

export default TicketReportList;
