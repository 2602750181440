import { useState } from "react";
// Import Highcharts
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Highcharts3d from "highcharts/highcharts-3d.js";
import Cylinder from "highcharts/modules/cylinder.js";
import Funnel from "highcharts/modules/funnel3d";

Highcharts3d(Highcharts);
Cylinder(Highcharts);
Funnel(Highcharts);

const initialData = {
	options: {
        chart: {
          type: "funnel3d",
          options3d: {
            enabled: true,
            alpha: 10,
            depth: 50,
            viewDistance: 50
          }
        },
        title: {
          text: ""
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          layout: 'vertical',
          x: 0,
          y: 20
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              align: 'center',
              /* format: "<b>{point.name}</b> ({point.y:,.0f})", */
              format: "{point.value} {point.y}",
              style: {
                shadow: 'none'
                },
              allowOverlap: true,
              y: 10
            },
            neckWidth: "30%",
            neckHeight: "25%",
            width: "70%",
            height: "70%"
          }
        },
        series: [
          /* {name:'Open',data: ["Open", 77],showInLegend: true},
          {name:'Product installation',data: ["Product installation", 61],showInLegend: true},
          {name:'Confirmation',data: ["Confirmation", 49],showInLegend: true},
          {name:'Installation done',data: ["Installation done", 38],showInLegend: true},
          {name:'Closed',data: ["Closed", 15],showInLegend: true} */
          {
            name: "Campaign Legends",
            data: [
              ["Open", 77],
              ["Product installation", 61],
              ["Confirmation", 49],
              ["Installation done", 38],
              ["Closed", 15]
            ],showInLegend: true
          }
        ]
      }
}

const HighChartFunnel = () => {
    const [state, ] = useState<any>(initialData)
  return (<HighchartsReact highcharts={Highcharts} options={state.options} />)
}

export default HighChartFunnel
