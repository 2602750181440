import React, { useEffect, useState , useCallback} from "react";
import {
    useGetDispositionStatusQuery,
    useTicketDetailsAfterSelectingDispositionQuery,
    useUpdateTicketWorkflowMutation,
} from "../../Services/ticket/CreateTicket";
import { CloseButton } from "react-bootstrap";
import { useReplyMailMutation, useTicketDynamicFieldsQuery } from "../../Services/email/EmailInbox";
import {
    Button,
    IconButton,
    InputLabel,
    ListItemText,
    Tooltip,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
} from "@mui/material";
import { PhoneInput } from "react-international-phone";
import { capitalizeFirstLetter } from "../reusableComponent/CapitalLetter";
import { toast } from "react-toastify";
import { Loader } from "react-bootstrap-typeahead";

interface IPROPS {
    closeModal: any;
    ticketId: any;
    lastSelectedId: any;
    ticketName: any;
    dynamicData: any;
    fetchDispositionStatus: any;
    refetch:any
    otherMailDetails:any
    cc:any
    bcc:any
    emails:any
    editorHtml:any
    files:any
    templateAttachment:any
    setCheckingForReplyApi:any
    setLiftingReplyLoader:any

}

interface ErrorState {
    [key: string]: string;
}

const EmailDispositionWorkflowModal: React.FC<IPROPS> = ({
    closeModal,
    ticketId,
    dynamicData,
    fetchDispositionStatus,
    refetch,
    otherMailDetails,
    bcc,
    cc,
    emails,
    editorHtml,
    files,
    templateAttachment,
    setCheckingForReplyApi,
    setLiftingReplyLoader
}) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString("en-GB"); // DD/MM/YYYY format
        const formattedTime = date.toLocaleTimeString("en-GB"); // HH:mm:ss format
        return `${formattedDate}, ${formattedTime}`;
    };

    const [internalNotes, setInternalNotes] = useState([]);
    const [gettingStatusValue, setGettingStatusValue] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [workFlowLoader, setWorkflowLoader] = useState(false)

    const [status, setStatus] = useState("");
    const [dynamicFields, setDynamicFields] = useState<any>("");
    const [statusId, setStatusId] = useState("");
    const [errors, setErrors] = useState<ErrorState>({
        // title: "",
        // ticketDescription: "",
        // Email: "",
        // Disposition: "",
    });

    const [originalFields, setOriginalFields] = useState({}); // State to track original values

    const { data } = useTicketDetailsAfterSelectingDispositionQuery(ticketId);

  const [replyMail, {status: replyStatus}] = useReplyMailMutation();

    // const {
    //   data: fetchDispositionStatus, error
    // } = useGetDispositionStatusQuery(lastSelectedId, {
    //   // skip: skipDispositionQuery,
    // });

    const [trigger, { isLoading, status: ticketWorkflow }] = useUpdateTicketWorkflowMutation();



    useEffect(() => {
      if (ticketWorkflow === "pending") {
        setWorkflowLoader(true)
      }
      else {
        setWorkflowLoader(false)
      }

    }, [ticketWorkflow])



    // useEffect(() => {
    //   if (replyStatus === "pending") {
    //     setLiftingReplyLoader(true)

    //   }
    //   else {
    //     setLiftingReplyLoader(false)

    //   }

    // }, [replyStatus, setLiftingReplyLoader])


    // useEffect(() => {
    //   const isFetchBaseQueryError = (error: any): error is { status: number; data: { disposition: string[] } } => {
    //     return error && typeof error.status === 'number' && error.data && Array.isArray(error.data.disposition);
    //   };

    //   if (isFetchBaseQueryError(error) && error.status === 400) {
    //     setLookupData(error.data.disposition[0]);
    //   }
    // }, [error]);

    // const handleInputChange = (label, value) => {
    //     // setErrors({});
    //     if (label === "remarks") {
    //       setDynamicFields((prevState) => ({ ...prevState, remarks: value }));
    //     } else {
    //       setDynamicFields((prevState) => ({ ...prevState, [label]: value }));
    //     }
    //   };

    const handleInputChange = useCallback((label, value) => {
      setIsButtonDisabled(true)
        setErrors({});
        // Update dynamicFields and track original field values
        setDynamicFields((prevState) => ({
            ...prevState,
            [label]: value,
        }));
    }, [])

    useEffect(() => {
        if (data) {
            const dynamicFieldsArray = Object.entries(
                data.dynamic_fields || {}
            ).reduce((acc, [label, value]) => ({ ...acc, [label]: value }), {});

            const fetchingStatusValue = data?.status_display
                ? data?.status_display
                : "";
            setGettingStatusValue(fetchingStatusValue);

            setDynamicFields(dynamicFieldsArray);
            setOriginalFields(dynamicFieldsArray); // Set original fields for comparison

            const formattedInternalNotes = data?.internal_notes?.map((el) => ({
                content: el?.content,
                created: formatDate(el?.created),
                firstName: el?.created_By?.first_name,
                lastName: el?.created_By?.last_name,
            }));
            setInternalNotes(formattedInternalNotes);
        }
    }, [data]);

    const validateForm = () => {
        const newErrors: ErrorState = {};

        // if (!dynamicFields.title) {
        //   newErrors.title = "Title is required";
        // }

        // if (!dynamicFields.description) {
        //   newErrors.ticketDescription = "Ticket description is required";
        // }

        dynamicData.forEach((field: any) => {
            const fieldValue = dynamicFields[field.label];

            if (field.is_required && !fieldValue) {
                newErrors[field.label] = `${field.label} is required`;
            }

            // Example: Add phone number validation logic here
            if (
                field.is_required &&
                field.field_type === "tel-phone" &&
                fieldValue
            ) {
                // If you have specific phone validation, you could add it here
                const isValidPhone =
                    /^(?:\+?\d{1,3})?[ -]?\(?\d{1,4}?\)?[ -]?\d{1,4}[ -]?\d{1,4}(?: x\d{1,4})?$/.test(
                        fieldValue
                    );
                if (!isValidPhone) {
                    newErrors[
                        field.label
                    ] = `${field.label} must be a valid phone number`;
                }
            }

            //   if (!selectedIds || selectedIds.length === 0) {
            //     newErrors.selectedIds = "Disposition is required";
            //   }

            //   if (!storeNewEmailId) {
            //     newErrors.email = "Email is required";
            //   }

            // if(!selectedIds[lastSelectedId]){
            //   newErrors.selectedIds = "Disposition is required";
            // }

            // if (
            //   field?.field_type === "check-box" &&
            //   field?.is_required &&
            //   !fieldValue?.length
            // ) {
            //   newErrors[field?.label] = `${field?.label} is required`;
            // }

            // if (!selectedTeams?.length) {
            //   newErrors["teams"] = "Please select at least one team";
            // }

            // Validation for Channel Field
            //   if (!dynamicFields.ticketChannel) {
            //     newErrors.ticketChannel = "Channel is required";
            //   }
            // Validation for Remarks
            //   if (!dynamicFields.dispositionDescription) {
            //     newErrors.dispositionDescription = "Remarks are required";
            //   }

            if (field.field_type === "text-area" && field.label === "remarks") {
                if (!fieldValue) {
                    newErrors[field.label] = `${field.label} is required`;
                }
            }

            if (!status) {
                newErrors.status_type = "Status is required";
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const saveHandler = async (e) => {

        e.preventDefault();

        if (!validateForm()) {
            toast.error("Please fill all required fields.");
          
            return;
        }

  

        const editedFields = Object.keys(dynamicFields).reduce((acc, key) => {
            // Compare with original fields to see if the value has changed

            const field = dynamicData.find((field) => field.label === key);

            if (dynamicFields[key] !== originalFields[key]) {
                acc[key] = dynamicFields[key];
            } else if (field?.field_type === "date") {
                const formattedDate = new Date(dynamicFields[key])
                    .toISOString()
                    .replace("Z", "+00:00");
                acc[key] = formattedDate;
            }

            // else {
            //   acc[key] = dynamicFields[key] || "";
            // }
            return acc;
        }, {});

        const updateWorkflowObj = {
            status: status === "wip" ? statusId : null, // WIP
            closed_status: status === "closed" ? statusId : null, // closed
            status_type: status, // Use status type
            dynamic_fields: editedFields,
        };

    
        try {
            const response = await trigger({
                id: ticketId,
                body: updateWorkflowObj,
            })

            if (response.data) {
                closeModal()
                refetch()
                toast.success("Edited Successfully")

           
            }
            else if ("data" in response.error) {
                const errorData = response?.error?.data as {detail?: string[]}
                const errorMessage = errorData?.detail?.[0]
                console.log(errorMessage, "errorMesage")
                toast.error(errorMessage)

            }
         }
        catch (error) {
            toast.error("An error occured")


        }

    };







    const getDataTimeFormatted = (date) => {
        if (date && date.length > 0) {
            const dateObj = new Date(date);

            // Manually format the date to ensure no timezone conversion issues
            const year = dateObj.getUTCFullYear();
            const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
            const day = String(dateObj.getUTCDate()).padStart(2, "0");
            const hours = String(dateObj.getUTCHours()).padStart(2, "0");
            const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");

            const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
            return formattedDate;
        } else {
            return "";
        }
    };

    const handleCheckboxChange = useCallback((
        event: React.ChangeEvent<{ value: any }>,
        key: string
    ) => {
        setErrors({});
        setIsButtonDisabled(true)
        setDynamicFields((prevFields) => ({
            ...prevFields,
            [key]: event.target.value,
        }));
    }, [])

    const formatStatusData = useCallback((status) => {
      if (typeof status.status === "string") {
            // Open and Reopen statuses (no ID)
            return {
                status_type: status.status_type,
                status: status.status,
                id: null, // No ID for statuses without ID
            };
        } else {
            // WIP and Closed statuses (with ID)
            return {
                id: status.status.id,
                status_type: status.status_type,
                status: status.status.status,
            };
        }
    }, [])
    const statusOptions = fetchDispositionStatus?.all_statuses?.map((status) =>
        formatStatusData(status)
    );

    return (
        <div style={{ maxHeight: "60vh", marginBottom: "10px" }}>
            <div
                style={{
                    maxHeight: "50vh",
                    overflowX: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                }}
            >
                <div
                    style={{
                        marginTop: "15px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        // marginBottom: "10px",
                    }}
                >
                    {dynamicData?.map((field: any) => (
                        <React.Fragment key={field?.label}>
                            <div
                                key={field.label}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <InputLabel
                                    htmlFor={`dynamic_fields.${field.label}`}
                                >
                                    {field.label}
                                    {field.is_required && (
                                        <span className="required"></span>
                                    )}
                                </InputLabel>
                                {field.field_type === "text" && (
                                    <TextField
                                        id={`dynamic_fields.${field.label}`}
                                        variant="outlined"
                                        fullWidth
                                        // disabled={!field?.is_editable}
                                        value={dynamicFields[field.label] || ""}
                                        onChange={(e) =>
                                            handleInputChange(
                                                field.label,
                                                e.target.value
                                            )
                                        }
                                        required={field.is_required}
                                    />
                                )}
                                {field.field_type === "email" && (
                                    <TextField
                                        type="email"
                                        id={`dynamic_fields.${field.label}`}
                                        // disabled={!field?.is_editable}
                                        variant="outlined"
                                        fullWidth
                                        value={dynamicFields[field.label] || ""}
                                        onChange={(e) =>
                                            handleInputChange(
                                                field.label,
                                                e.target.value
                                            )
                                        }
                                        required={field.is_required}
                                    />
                                )}
                                {field.field_type === "number" && (
                                    <TextField
                                        type="number"
                                        id={`dynamic_fields.${field.label}`}
                                        // disabled={!field?.is_editable}
                                        variant="outlined"
                                        fullWidth
                                        value={dynamicFields[field.label] || ""}
                                        onChange={(e) =>
                                            handleInputChange(
                                                field.label,
                                                e.target.value
                                            )
                                        }
                                        required={field.is_required}
                                    />
                                )}
                                {field.field_type === "date" && (
                                    <TextField
                                        type="date"
                                        id={`dynamic_fields.${field.label}`}
                                        // disabled={!field?.is_editable}
                                        variant="outlined"
                                        fullWidth
                                        value={dynamicFields[field.label] || ""}
                                        onChange={(e) =>
                                            handleInputChange(
                                                field.label,
                                                e.target.value
                                            )
                                        }
                                        InputLabelProps={{ shrink: true }}
                                        required={field.is_required}
                                    />
                                )}
                                {field.field_type === "time" && (
                                    <TextField
                                        type="time"
                                        id={`dynamic_fields.${field.label}`}
                                        // disabled={!field?.is_editable}
                                        variant="outlined"
                                        fullWidth
                                        value={dynamicFields[field.label] || ""}
                                        onChange={(e) =>
                                            handleInputChange(
                                                field.label,
                                                e.target.value
                                            )
                                        }
                                        InputLabelProps={{ shrink: true }}
                                        required={field.is_required}
                                    />
                                )}
                                {field.field_type === "date-time" && (
                                    <TextField
                                        type="datetime-local"
                                        id={`dynamic_fields.${field.label}`}
                                        // disabled={!field?.is_editable}
                                        variant="outlined"
                                        fullWidth
                                        defaultValue={getDataTimeFormatted(
                                            dynamicFields[field.label]
                                        )}
                                        onChange={(e) =>
                                            handleInputChange(
                                                field.label,
                                                e.target.value
                                            )
                                        }
                                        InputLabelProps={{ shrink: true }}
                                        required={field.is_required}
                                    />
                                )}
                                {field.field_type === "text-area" && (
                                    <TextField
                                        id={`dynamic_fields.${field.label}`}
                                        // disabled={!field?.is_editable}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        value={dynamicFields[field.label] || ""}
                                        onChange={(e) =>
                                            handleInputChange(
                                                field.label,
                                                e.target.value
                                            )
                                        }
                                        required={field.is_required}
                                    />
                                )}
                                {field.field_type === "drop-down" && (
                                    <FormControl fullWidth>
                                        <Select
                                            labelId={`select-label-${field.label}`}
                                            // disabled={!field?.is_editable}
                                            id={`select-${field.label}`}
                                            value={
                                                dynamicFields[field.label] || ""
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.label,
                                                    e.target.value
                                                )
                                            }
                                            required={field.is_required}
                                        >
                                            <MenuItem selected disabled>
                                                Select
                                            </MenuItem>
                                            {field?.choices?.map(
                                                (option: any) => (
                                                    <MenuItem
                                                        key={option.id}
                                                        value={option.choice}
                                                    >
                                                        {option.choice}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                )}
                                {field.field_type === "check-box" && (
                                    <FormControl fullWidth>
                                        <Select
                                            labelId={`checkbox-label-${field.label}`}
                                            // disabled={!field?.is_editable}

                                            id={`checkbox-${field.label}`}
                                            required={field.is_required}
                                            multiple
                                            value={
                                                dynamicFields[field.label] || []
                                            } // Ensure value is an array of IDs
                                            onChange={(e: any) =>
                                                handleCheckboxChange(
                                                    e,
                                                    field.label
                                                )
                                            }
                                            renderValue={(selected) => {
                                                // Create an array of selected choice labels
                                                const selectedLabels = (
                                                    field?.multiple_choices ||
                                                    []
                                                )
                                                    .filter((choice) =>
                                                        selected.includes(
                                                            String(choice.id)
                                                        )
                                                    )
                                                    .map(
                                                        (choice) =>
                                                            choice.choice
                                                    );
                                                return selectedLabels.join(
                                                    ", "
                                                );
                                            }}
                                        >
                                            {field?.multiple_choices?.map(
                                                (choice: any) => (
                                                    <MenuItem
                                                        key={choice.id}
                                                        value={String(
                                                            choice.id
                                                        )}
                                                    >
                                                        <Checkbox
                                                            checked={dynamicFields[
                                                                field.label
                                                            ]?.includes(
                                                                String(
                                                                    choice.id
                                                                )
                                                            )}
                                                        />
                                                        <ListItemText
                                                            primary={
                                                                choice.choice
                                                            }
                                                        />
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                )}

                                {field.field_type === "tel-phone" && (
                                    <PhoneInput
                                        defaultCountry="IN"
                                        value={dynamicFields[field.label] || ""}
                                        // disabled={!field?.is_editable}

                                        onChange={(value) =>
                                            handleInputChange(
                                                field.label,
                                                value
                                            )
                                        }
                                        required={field.is_required}
                                    />
                                )}
                                {errors[field.label] && (
                                    <div
                                        className="mt-2"
                                        style={{ color: "red" }}
                                    >
                                        {capitalizeFirstLetter(
                                            errors[field.label]
                                        )}
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    ))}
                </div>

                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <label>Internal Notes</label>
                    <input className="form-control" />
                </div>
                {internalNotes?.map((el, index) => (
                    <div
                        key={index}
                        style={{ display: "flex", flexDirection: "column" }}
                    >
                        <div style={{ display: "flex" }}>
                            <label>
                                <span>{el?.firstName}</span>{" "}
                                <span>{el?.lastName}</span>
                            </label>
                            <label>{el?.created}</label>
                        </div>
                        <div>{el?.content}</div>
                    </div>
                ))}
                <div className="row g-5 g-xl-8 mb-4">
                    <div className="col-xl-6" style={{ width: "100%" }}>
                        <label className="required">Status</label>
                        <select
                            value={status}
                            onChange={(e) => {
                                const selectedStatus = statusOptions.find(
                                    (option) =>
                                        option.status_type === e.target.value
                                );
                                setStatus(e.target.value);
                                setIsButtonDisabled(true)
                                setStatusId(
                                    selectedStatus ? selectedStatus.id : ""
                                );
                                setErrors({});
                            }}
                            className="form-select form-select-lg form-control-solid"
                        >
                            <option value="" label="Select" />
                            {statusOptions?.map((statusItem) => (
                                <option
                                    key={statusItem.status_type}
                                    value={statusItem.status_type}
                                    label={statusItem.status}
                                >
                                    {statusItem.status}
                                </option>
                            ))}
                        </select>
                        {errors.status_type && (
                            <div className="text-danger">
                                {errors.status_type}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "end",
                }}
            >
                <button className="btn btn-primary" onClick={saveHandler} disabled={!isButtonDisabled}>
                    {workFlowLoader ? <>Saving...<Loader /> </> : "Save"}
                </button>
            </div>
        </div>
    );
};

export default EmailDispositionWorkflowModal;
