import React, { useEffect, useState } from "react";

interface IPROPS {
  index: string;
  setLiftingColumn: (column: string) => void;
  // setLastAction: (action: string) => void;
  // setSearch: (search: string) => void;
  // setDeactiveSearch: (deactiveSearch: string) => void;
}

const SortingTableUsers: React.FC<IPROPS> = ({
  index,
  setLiftingColumn,
  // setLastAction,
  // setSearch,
  // setDeactiveSearch,
}) => {
  const [sortedColumn, setSortedColumn] = useState<string>("username");
  const [isAscending, setIsAscending] = useState<boolean>(true);

  const switchArrows = (id: string) => {
    if (sortedColumn === id) {
      setIsAscending(!isAscending);
    } else {
      setSortedColumn(id);
      setIsAscending(true);
    }
    // setSearch("");
    // setDeactiveSearch("");
    
  };

  useEffect(() => {
    setLiftingColumn(`${isAscending ? "" : "-"}${sortedColumn}`);
    // setLastAction("sort");
  }, [sortedColumn, isAscending, setLiftingColumn]);

  return (
    <div>
      <i
        style={{ color: "black", fontSize: "14px", cursor: "pointer" }}
        className={`bi bi-arrow${sortedColumn === index && !isAscending ? "-down" : "-up"}`}
        onClick={() => switchArrows(index)}
        data-placement="top"
        title={
          sortedColumn === index
            ? isAscending
              ? "Sort to descending"
              : "Sort to ascending"
            : "Sort"
        }
      ></i>
    </div>
  );
};

export default SortingTableUsers;
