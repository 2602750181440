import { useEffect, useState } from "react";
import ChatHistory from "../../components/chat/ChatHistory";
import ChatSideMenu from "../../components/chat/ChatSideMenu";
import Conversation from "../../components/chat/Conversation";
import LeftSideChatMenuCards from "../../components/chat/LeftSideChatMenuCards";
import { useNavigate } from "react-router-dom";

const Chat = () => {
  const [selectedChatRoom, setSelectedChatRoom] = useState("0");

  useEffect(() => {}, [selectedChatRoom]);

  const navigate = useNavigate(); // Initialize useNavigate
  const handleBackButtonClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-xl-12">
          <div className="card card-flush h-100">
            <div className="card-header align-items-center py-1 my-1 gap-2 gap-md-5">
              <div className="d-flex">
                <button
                  className="btn btn-link mb-2"
                  onClick={handleBackButtonClick}
                  style={{marginLeft : '30px'}}
                >
               <i className="bi bi-arrow-90deg-left"     style={{
                                        fontSize: "20px",
                                        color: "black",
                                        cursor: "pointer",
                                    }}></i>
                </button>
                <h2 className="p-4">Chat</h2>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-2 col-lg-4 col-md-12 mb-4">
                    <LeftSideChatMenuCards
                      setSelectedChatRoom={setSelectedChatRoom}
                    />
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-12 mb-4">
                    <div className="card" id="kt_chat_messenger">
                      <Conversation selectedChatRoom={selectedChatRoom} />
                    </div>
                    <div className="card mt-4">
                      <ChatHistory />
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-4 col-md-12 mb-4">
                    <ChatSideMenu />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
