import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import LeadInfo from './leads/LeadInfo'
import CampaignList from './CampaignList'
import CampaignTask from '../../components/campaign/CampaignTaskList/CampaignTask'

const CampaignRoutes = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='list'
                    element={
                        <>
                            <CampaignList />
                        </>
                    }
                />
                <Route
                    path='/eventlist'
                    element={
                        <>
                            <CampaignTask />
                        </>
                    }
                />
                <Route path='lead/:uuid' element={<LeadInfo />} />
                {/* <Route index element={<Navigate to='/campaign/list' />} /> */}
            </Route>
        </Routes>
    )
}

export default CampaignRoutes
