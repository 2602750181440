import React, { useState } from "react";
import Template from "../../components/settings/email/template/Template";
import Notification from "../../components/settings/email/notification/Notification";
import SLA from "../../components/settings/email/scheduler/SLA";
import EmailConfiguration from "../../components/settings/email/configuration/EmailConfiguration";
import { Route, Routes } from "react-router-dom";
import ViewMessage from "../../components/settings/email/template/ViewMessage";

const EmailSettings = () => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const tabTitles = [
        "Template",
        "Notification",
        "SLA",
        "Email configuration",
    ];

    const emailObj = {
        0: <Template />,
        1: <Notification />,
        2: <SLA />,
        3: <EmailConfiguration />,
    };
    return (
        <div className="text-start">
            <div>
                <ul className="nav nav-stretch nav-line-tabs text-primary border-transparent d-flex flex-nowrap mb-5">
                    {tabTitles.map((title, index) => (
                        <li className="nav-item" key={Math.random()}>
                            <span
                                className={`nav-link cursor-pointer fs-4 fw-bold ${
                                    activeTabIndex === index
                                        ? "active fw-bolder"
                                        : ""
                                }`}
                                onClick={() => {
                                    setActiveTabIndex(index);
                                }}
                                role="tab"
                            >
                                {title}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>

            <Routes>
                <Route path="/settings/email/:id" element={<ViewMessage />} />
            </Routes>

            {emailObj[activeTabIndex]}
        </div>
    );
};

export default EmailSettings;
