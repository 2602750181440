import React from "react";


interface IPROPS {
  auditTrailForwardedData: any
  setPaneOpen: any

}
const TicketRightSideMenu: React.FC<IPROPS> = ({
  auditTrailForwardedData,
  setPaneOpen

}) => {
  return (
    <div className="d-flex flex-column my-1">
      <div className="card p-1">
        <div className="card-title d-flex justify-content-center">
          <button
            className="btn btn-link"
            type="button"

            disabled
            data-bs-target="#knowledgeManagementCollapse"
            aria-expanded="false"
            aria-controls="knowledgeManagementCollapse"

          >
            Knowledge Management
          </button>
        </div>
        <div className="collapse multi-collapse" id="knowledgeManagementCollapse">
          <div className="card px-2 my-1">
            <p className="my-4 text-muted">First Conversation</p>
          </div>
          <div className="card px-2 my-1">
            <p className="my-4 text-muted">Testing</p>
          </div>
          <div className="card px-2 my-1">
            <p className="my-4 text-muted">Photo</p>
          </div>
          <div className="card px-2 my-1">
            <p className="my-4 text-muted">Lead Status</p>
          </div>
          <div className="card px-2 my-1">
            <p className="my-8"></p>
          </div>
        </div>
      </div>
      <div className="card p-1 my-2">
        <div className="card-title d-flex justify-content-center">
          <button
            className="btn btn-link"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#ourTeamCollapse"
            aria-expanded="false"
            aria-controls="ourTeamCollapse"
            disabled
          >
            Our Team
          </button>
        </div>
        <div className="collapse multi-collapse" >
          <div className="flex-column">
            <div id="kt_chat_contacts_header">
              <form className="w-100 position-relative">
                <div className="d-flex align-items-center position-relative">
                  <i className="bi bi-search fs-3 position-absolute ms-4" />
                  <input
                    type="text"
                    data-kt-inbox-listing-filter="search"
                    className="form-control form-control-sm form-control-solid mw-100 min-w-125px min-w-lg-150px min-w-xxl-200px ps-11"
                    placeholder="Search inbox"
                    disabled
                    style={{ backgroundColor: "#e9ecef", cursor: "not-allowed" }}
                  />
                </div>
                <div className="d-flex justify-content-around py-4">
                  <div className="d-flex align-items-center">
                    <label>Our Team</label>
                  </div>
                  <div className="d-flex flex-column align-items-end ms-2">
                    <div className="d-flex">
                      <span className="text-muted fs-7 mb-1 px-5">
                        <i className="bi bi-person-add"></i>
                      </span>
                      <span className="text-muted fs-7 mb-1">
                        <i className="bi bi-caret-down-square"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div id="kt_chat_contacts_body">
              {/* <div
                className="scroll-y h-lg-auto"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_header, #kt_app_header, #kt_toolbar, #kt_app_toolbar, #kt_footer, #kt_app_footer, #kt_chat_contacts_header"
                data-kt-scroll-wrappers="#kt_content, #kt_app_content, #kt_chat_contacts_body"
                data-kt-scroll-offset="5px"

              > */}
              {/* <div className="d-flex flex-stack py-2 px-1 border">
                  <div className="d-flex align-items-center">
                    <div className="symbol  ps-1 symbol-30px symbol-circle ">
                      <img alt="Pic" src="/media/profilepics/image62x.png" />
                      <div className="symbol-badge bg-success start-100 top-100 border-4 h-8px w-8px ms-n2 mt-n2"></div>
                    </div>
                    <div className="ms-1">
                      <a
                        href="#"
                        className="fs-7 text-gray-900 text-hover-primary mb-2"
                        style={{ pointerEvents: "none", color: "#6c757d" }}
                      >
                        Lukas
                      </a>
                      <div className="fw-semibold text-muted fs-8">
                        Was machst du?
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-end ms-2">
                    <div className="d-flex">
                      <span className="text-muted fs-7 mb-1 px-1">
                        <i className="bi bi-mic-fill pe-1"></i>
                      </span>
                      <span className="text-muted fs-7 mb-1">
                        <i className="bi bi-x-circle-fill pe-2"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-stack py-4 border">
                  <div className="d-flex align-items-center">
                    <div className="symbol  ps-1 symbol-30px symbol-circle ">
                      <span className="symbol-label  bg-light-danger text-danger fs-6 fw-bolder ">
                        R
                      </span>
                    </div>
                    <div className="ms-1">
                      <a
                        href="#"
                        className="fs-7 text-gray-900 text-hover-primary mb-2"
                        style={{ pointerEvents: "none", color: "#6c757d" }}
                      >
                        Roy
                      </a>
                      <div className="fw-semibold text-muted fs-8">
                        Was machst du?
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-end ms-2">
                    <div className="d-flex">
                      <span className="text-muted fs-7 mb-1 px-1">
                        <i className="bi bi-mic-fill pe-1"></i>
                      </span>
                      <span className="text-muted fs-7 mb-1">
                        <i className="bi bi-x-circle-fill pe-3"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-stack py-4 border">
                  <div className="d-flex align-items-center">
                    <div className="symbol  ps-1 symbol-30px symbol-circle ">
                      <span className="symbol-label  bg-light-danger text-danger fs-6 fw-bolder ">
                        BB
                      </span>
                    </div>
                    <div className="ms-1">
                      <a
                        href="#"
                        className="fs-7 text-gray-900 text-hover-primary mb-2"
                        style={{ pointerEvents: "none", color: "#6c757d" }}
                      >
                        Beuger
                      </a>
                      <div className="fw-semibold text-muted fs-8">
                        Was machst du?
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-end ms-2">
                    <div className="d-flex">
                      <span className="text-muted fs-7 mb-1 px-1">
                        <i className="bi bi-mic-fill pe-1"></i>
                      </span>
                      <span className="text-muted fs-7 mb-1">
                        <i className="bi bi-x-circle-fill pe-4"></i>
                      </span>
                    </div>
                  </div>
                </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketRightSideMenu;