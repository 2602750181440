import React from "react";

interface IPROPS {
    value: any
}

const EmptyRows: React.FC<IPROPS> = ({value}) => {

    return (
        <div style={{ height: value === "disposition" ? "15vh" : "50vh", width: "100%", display: "flex" }}>
            <p
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "center",
                    gap: "20px",
                    textAlign: "center",
                }}
            >
                <span
                    style={{
                        fontSize: "20px",
                        fontFamily: "Font Awesome 5 Free",
                    }}
                >
                    No data to show
                </span>
                <span>
                    <i
                        className="bi bi-box2-fill"
                        style={{ fontSize: "20px" }}
                    ></i>
                </span>
            </p>
        </div>
    );
};

export default EmptyRows;
