import React, { useState } from "react";
import { useBulkUploadMutation } from "../../Services/user/Users";
import { toast } from "react-toastify";
import ExcelJS from "exceljs";
import * as XLSX from "xlsx";
import { useBulkLeadUploadMutation } from "../../Services/campign/campignList";

interface IProps {
  closeModal: any;
  refetch: any;
  dynamicData: any;
  campaignId: any;
  refetchWorkflow: any;
}

const BulkLeadModel: React.FC<IProps> = ({
  closeModal,
  refetch,
  dynamicData,
  campaignId,
  refetchWorkflow,
}) => {
  const [selectedCsvFiles, setSelectedCsvFiles] = useState<File[]>([]);
  const [fileToDownload, setFileToDownload] = useState<File | null>(null);
  const [failDetails, setFailDetails] = useState<any[]>([]); // Store fail details here
  const [triggerBulkUpload] = useBulkLeadUploadMutation({});
  const [isAssigneeCheck, setIsAssigneeCheck] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsAssigneeCheck(e.target.checked); // Update state based on checkbox status
  };

  const handleCsvFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      setSelectedCsvFiles(files);
      setFileToDownload(files[0]);
    }
  };

  const handleRemoveCsvFile = (index: number) => {
    const newFiles = Array.from(selectedCsvFiles);
    newFiles.splice(index, 1);
    setSelectedCsvFiles(newFiles);
    if (index === 0 && newFiles.length > 0) {
      setFileToDownload(newFiles[0]); // Update the file to be downloaded if the first file was removed
    } else if (index === 0) {
      setFileToDownload(null); // Clear file to download if no files are left
    }
  };

  const handleCsvDownload = async () => {
    const filteredDynamicData = dynamicData;

    const workbook = new ExcelJS.Workbook();
    const ws: any = workbook.addWorksheet("Test Worksheet");

    let header = [
      "campaign_account_name",
      "campaign_first_name",
      "campaign_last_name",
      "campaign_email",
      "campaign_phone_number",
      ...(isAssigneeCheck ? ["campaign_assignee"] : []) // Add dynamically if isAssigneeCheck is true
    ];

    // Check if dynamicData and fields exist
    // Dynamically add dynamic field headers
    if (filteredDynamicData && filteredDynamicData.length) {
      filteredDynamicData.forEach((field) => {
        header.push(`campaign_${field.label}`); // Dynamic field label in header
      });
    }

    // Add header row to the worksheet
    ws.addRow(header);

    // Set column widths
    ws.columns.map((col) => (col.width = 18));

    filteredDynamicData.forEach((field, index) => {
      if (field.field_type === "check-box") {
        const checkboxOptions = field.multiple_choices
          .map((choice) => choice.choice)
          .join(",");

        // Determine the column index for this field in the header
        const columnIndex =
          header.length - filteredDynamicData.length + index + 1; // Adjust for the current dynamic field
        const columnLetter = String.fromCharCode(64 + columnIndex);

        // Add dropdown validation for this field's column
        for (let i = 2; i <= 100; i++) {
          // Apply validation to rows 2-100
          ws.getCell(`${columnLetter}${i}`).dataValidation = {
            type: "list",
            allowBlank: false,
            formulae: [`"${checkboxOptions}"`], // Using all options without truncation
          };
        }
      } else if (field.field_type === "drop-down") {
        const fieldOptions = field?.choices.map((option: any) => option.choice);

        // Determine the column index for this field in the header
        const columnIndex =
          header.length - filteredDynamicData.length + index + 1; // Adjust for the current dynamic field
        const columnLetter = String.fromCharCode(64 + columnIndex);

        // Add dropdown validation for this field's column
        for (let i = 2; i <= 100; i++) {
          // Apply validation to rows 2-100
          ws.getCell(`${columnLetter}${i}`).dataValidation = {
            type: "list",
            allowBlank: false,
            formulae: [`"${fieldOptions}"`], // Using all options without truncation
          };
        }
      }
    });

    // Prepare a hidden worksheet for dropdown options
    const hiddenSheet = workbook.addWorksheet("DropdownOptions", {
      state: "hidden",
    });

    // Add roles (Agent, Manager) to the hidden sheet
    hiddenSheet.getCell("A1").value = "agent";
    hiddenSheet.getCell("A2").value = "manager";

    // Add branch_campaign options (True, False) to the hidden sheet
    hiddenSheet.getCell("B1").value = "Yes";
    hiddenSheet.getCell("B2").value = "No";
    // Style the header row with blue fill
    ws.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFADD8E6" },
    };

    // Apply specific red fill style to headers
    const redHeaders = [

      "campaign_phone_number",
    ];
    redHeaders.forEach((headerName) => {
      const colIndex = header.indexOf(headerName) + 1; // Find column index for each red header
      ws.getCell(1, colIndex).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF0000" }, // Red color for specific headers
      };
    });

    // Apply general styles to all cells
    ws.eachRow((row) => {
      row.eachCell((cell) => {
        cell.font = {
          name: "Inter",
          size: 8,
        };
        cell.alignment = {
          horizontal: "center",
        };
      });
    });

    // Check if dynamicData and fields exist
    if (filteredDynamicData && filteredDynamicData.length) {
      filteredDynamicData.forEach((field, index) => {
        if (field.is_required) {
          const columnIndex =
            header.length - filteredDynamicData.length + index + 1; // Adjust for the current dynamic field
          const columnLetter = String.fromCharCode(64 + columnIndex);
          ws.getCell(`${columnLetter}1`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF0000" }, // Red color
          };
        }
      });
    }

    // Generate the Excel file as a blob
    const excelBlob = await workbook.xlsx.writeBuffer();
    const excelUrl = URL.createObjectURL(
      new Blob([excelBlob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );

    // Create a link to download the Excel file
    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = "campaign_template.xlsx";
    document.body.appendChild(link);
    link.click();

    // Clean up
    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);
  };

  const handleDownloadFailDetails = async () => {
    const workbook = new ExcelJS.Workbook();
    const ws = workbook.addWorksheet("Failed Uploads");

    // Prepare header for static fields
    const header = [
      "Account_Name", // Static Field
      "First_Name", // Static Field
      "Last_Name", // Static Field
      "Email", // Static Field
      "Phone_Number"
      // Dynamic field headers will be added dynamically
    ];

    // Dynamically add dynamic field headers
    if (dynamicData && dynamicData.length) {
      dynamicData.forEach((field) => {
        const csvKey = field.label.replace(/ /g, "_"); // Replace spaces with underscores
        header.push(`campain_${csvKey}`); // Dynamic field label in header
      });
    }

    // Errors column is added at the last
    header.push("Errors");

    // Add headers to the worksheet
    ws.addRow(header);

    // Iterate through failDetails to populate rows
    failDetails.forEach((fail) => {
      const { account_name, first_name, last_name, email, phone_number, dynamic_fields, error } =
        fail;

      // Prepare dynamic fields data in the same structure as CSV
      const dynamicFieldValues = dynamicData.map((field) => {
        const fieldKey = field.label.replace(/ /g, "_");
        return dynamic_fields[fieldKey] || ""; // Return 'N/A' if value is missing
      });

      // Convert errors into string for easier display in Excel
      const errorString = Array.isArray(error)
        ? error.join(", ")
        : JSON.stringify(error);

      ws.getRow(1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFADD8E6" },
      };

      // Apply styles to all cells
      ws.eachRow((row) => {
        row.eachCell((cell) => {
          cell.font = {
            name: "Inter",
            size: 8,
          };
          cell.alignment = {
            horizontal: "center",
          };
        });
      });

      // Add the row with dynamic fields and the error at the end
      ws.addRow([
        account_name, // Username
        first_name, // Role
        last_name,// Teams as a comma-separated string
        email, // Branch user value (Yes/No)
        phone_number,
        ...dynamicFieldValues, // Spread dynamic field values here
        errorString, // Errors at the end
      ]);
    });

    // Create the Excel file and initiate the download
    const excelBlob = await workbook.xlsx.writeBuffer();
    const excelUrl = URL.createObjectURL(
      new Blob([excelBlob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );

    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = "failed_uploads.xlsx";
    document.body.appendChild(link);
    link.click();

    // Clean up temporary URLs and DOM elements
    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (selectedCsvFiles.length > 0) {
      const file = selectedCsvFiles[0];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();

      const reader = new FileReader();

      reader.onload = async (e) => {
        let csvContent: string | undefined;

        if (e.target?.result) {
          if (fileExtension === "csv") {
            // If the file is a CSV, directly read the content
            csvContent = e.target.result as string;
          } else if (fileExtension === "xlsx") {
            // If the file is an XLSX, convert it to CSV format using the xlsx library
            const binaryStr = e.target.result as string;
            const workbook = XLSX.read(binaryStr, { type: "binary" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            csvContent = XLSX.utils.sheet_to_csv(worksheet); // Convert XLSX to CSV
          }

          if (csvContent) {
            const base64Csv = btoa(csvContent); // Convert CSV content to Base64
            const payload = {
              campaign: campaignId,
              file_name: file.name,
              csv_file: `data:text/csv;base64,${base64Csv}`,
            };

            // Log the payload before making the API call
            try {
              const obj = {
                campaign: campaignId,
                file_name: file.name,
                csv_file: `data:text/csv;base64,${base64Csv}`,
              };

              // Make the API call
              const response = await triggerBulkUpload({ body: obj });

              // Log the response from the API
              if (response) {
                const successCount = response.data.success_count;
                const failCount = response.data.fail_count;
                setFailDetails(response.data.fail_details); // Set fail details from API response

                if (successCount) {
                  toast.success(
                    `${successCount} record(s) uploaded successfully`
                  );
                  closeModal();
                }
                if (failCount > 0) {
                  toast.error(`${failCount} record(s) failed to upload`);
                }

                setSelectedCsvFiles([]);
                setFileToDownload(null);
                refetch();
                refetchWorkflow();
              }
            } catch (error) {
              toast.error("Upload failed. Please try again.");
            }
          }
        }
      };

      if (fileExtension === "csv") {
        reader.readAsText(file); // For CSV files, read as text
      } else if (fileExtension === "xlsx") {
        reader.readAsBinaryString(file); // For XLSX files, read as binary
      } else {
        toast.error(
          "Unsupported file format. Please upload a CSV or XLSX file."
        );
      }
    }
  };

  const checkboxStyle: any = {
    width: "20px",
    height: "20px",
    border: "1px solid #009ef7",
    backgroundColor: isAssigneeCheck ? "#009ef7" : "transparent",
    position: "relative",
    cursor: "pointer",
    display: "inline-block",
    appearance: "none", // Removes default styling
    WebkitAppearance: "none", // For WebKit browsers
  };

  const checkmarkStyle: any = {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) rotate(45deg)",
    width: "5px",
    height: "10px",
    border: "solid white",
    borderWidth: "0 2px 2px 0",
    display: isAssigneeCheck ? "block" : "none", // Only show when checked
  };


  return (
    <form onSubmit={handleFormSubmit}>
      <div className="mb-4">
        <div className="d-flex justify-content-evenly mt-2 mb-8 mx-1">
          {/* CSV Upload Button */}
          <label
            htmlFor="upload-csv-button"
            className="form-control d-flex align-items-center justify-content-center bg-secondary fw-bold mx-2 px-3 py-2 rounded mw-150px"
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-file-earmark-spreadsheet text-dark fs-4 me-2"></i>
            CSV Upload
          </label>
          <input
            type="file"
            id="upload-csv-button"
            accept=".csv, .xlsx"
            style={{ display: "none" }}
            onChange={handleCsvFileChange}
          />

          {/* CSV Download Button */}
          <button
            type="button"
            className="form-control d-flex align-items-center justify-content-center bg-secondary fw-bold mx-2 px-3 py-2 rounded mw-150px"
            onClick={handleCsvDownload}
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-download text-dark fs-4 me-2"></i>
            CSV Download
          </button>
        </div>
        <div className="d-flex justify-content-start mt-2 mb-8 mx-4" style={{paddingLeft:"26px"}}>
          <div
            style={checkboxStyle}
            onClick={() => setIsAssigneeCheck(!isAssigneeCheck)} // Handle toggle
          >
            <div style={checkmarkStyle}></div>
          </div>
          <label htmlFor="show-fail-details" className="ms-2">Assign to user</label>
        </div>
        <div className="mt-3 mb-3" style={{paddingLeft:"38px"}}>
          <label className="fs-6 fw-bolder mb-2">Important Instructions:</label>
          <br />
          <label className="text-danger">
            &bull; Please upload a valid CSV or XLSX file format only.
          </label>
          <br />
          <label className="text-danger">
            &bull; Headers highlighted in red are mandatory fields.
          </label>
        </div>
        {failDetails?.length > 0 && (
          <button
            type="button"
            className="form-control d-flex align-items-center justify-content-center bg-secondary fw-bold mx-6 px-3 py-2 rounded mw-150px"
            onClick={handleDownloadFailDetails}
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-download text-dark fs-4 me-2"></i>
            Error Data
          </button>
        )}

        {/* Display selected CSV files */}
        {selectedCsvFiles?.length > 0 && (
          <div className="mb-4" style={{paddingLeft:"35px"}}>
            <h5>Selected CSV file:</h5>
            <ul className="list-group mt-3">
              {selectedCsvFiles.map((file, index) => (
                <li
                  key={index}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <span>{file.name}</span>
                  <button
                    type="button"
                    className="btn btn-link text-danger p-0"
                    onClick={() => handleRemoveCsvFile(index)}
                  >
                    <i className="bi bi-trash text-danger fs-5"></i>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-end mb-4 pt-2" style={{marginTop:"12%"}}>
        <button
          type="button"
          className="btn btn-sm btn-white  me-2"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button type="submit" className="btn btn-sm btn-primary">
          Submit
        </button>
      </div>
    </form>
  );
};

export default BulkLeadModel;
