import SalesChart from "./salesCharts/SalesCharts";
import InboxChart from "./charts/InboxChart";
import SentChart from "./charts/SentCharts";
import ResponseTime from "./charts/ResponseTime";
import RepeatCustomerChart from "./charts/RepeatCustomerChart";
import FirstResponse from "./charts/FirstResponse";
import SentinmentsCard from "./cards/SentinmentsCard";
import DispositionCards from "./cards/DispositionCards";
import WordCloud from "./charts/WordCloud";
import {
    useDashboardQuery,
    useGetAllDashboardDataQuery,
} from "../../../Services/dashboard/dashboard";
import EmailPieChart from "./charts/EmailPieChart";
import { useState, useEffect, useContext } from "react";
import { useTeamsQuery } from "../../../Services/settingsAPIs/CommonAPI";
import LoadingSpinner from "../../../components/LoadingSpinner";
import EmailCustomDate from "./EmailCustomDate";
import { useGetAllTeamsQuery } from "../../../Services/reports/tickets/ReportTickets";
import {
  useCallBreakMutation,
  useExtensionStateMutation,
    useInboundCallQuery,
    // useTelephonyExtensionQuery,
    useTelephonyPRIQuery,
    useTelephonyServerQuery,
} from "../../../Services/Telephony/TelephonyFrontend";
import { AUTH_TOKEN, CALLBREAKID, EXTENSION, QUEUE, TELEPHONEURL } from "../../../constants";
import { usePauseCodeDataQuery } from "../../../Services/Telephony/Telephony";
import { toast } from "react-toastify";
import axios from "axios";
import { useTelephony } from "../../../components/context/TelephonyContext";
import TelephonyFrontend from "../../../components/TelephonyFrontend/TelephonyFrontend";

const EmailDashboard = () => {
    const [isLoading, setIsLoading] = useState(true);

   const dataee =  useTelephony()

   console.log(dataee, "dataee")
    const { data: teamsData, isLoading: teamsLoading } = useGetAllTeamsQuery(
        {}
    );
    const [selectedTeam, setSelectedTeam] = useState(
        localStorage.getItem("teamId")
    );
    const [theTeams, setTheTeams] = useState([]);
    const { data } = useDashboardQuery(selectedTeam);
    const [params, setParams] = useState<any>({ dashboard_type: "email" });
    const [loading, setLoading] = useState(false);
    const [dateRange, setDateRange] = useState<any>([
        {
            startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
            endDate: new Date(),
            key: "selection",
        },
    ]);
    const { data: emailDashboardData, refetch } = useGetAllDashboardDataQuery(
        params,
        {
            skip: !params.team || !params.start_date || !params.end_date, // Ensure params are complete before querying
        }
    );

    // TELEPHONY DATA

    // const gettingExtension = localStorage.getItem(EXTENSION);
    // const [telephonyPriData, setTelephonyPriData] = useState([]);
    // const [mobileNumberEntered, setMobileNumberEntered] = useState(false);
    // const [call, setCall] = useState<boolean>(false);
    // const [isCallPaused, setIsCallPaused] = useState<boolean>(false);
    // const [getpauseCode, setGetPauseCode] = useState([]);
    // const [queueus, setQueueus] = useState([])
    // const [unPauseResponse, setUnPauseResponse] = useState('')
    // const [inbound, setInbound] = useState([])
    // const gettingTelephonyData = localStorage.getItem(TELEPHONEURL);
    // const callBreakId = localStorage.getItem(CALLBREAKID)


    // useExtensionStateMutation, useInboundCallQuery,useCallBreakMutation
    

    // const [extensionStateTrigger, {data: extensionStateData, status: extensionStateStatus}] = useExtensionStateMutation()
    // const {data: inboundCallData, status: inboundCallStatus, isLoading: inboundCallLoading} = useInboundCallQuery(gettingExtension, {skip: !gettingExtension || !unPauseResponse})
    // const [callBreakTrigger, {data: callBreakData, status: callBreakStatus}] = useCallBreakMutation()

    // console.log(inboundCallData, 'dcscscsc')

    // const telephoneData = JSON.parse(gettingTelephonyData);
    // const gettingQueues = localStorage.getItem(QUEUE);

    // useEffect(() => {

      
    //   if (gettingQueues) {
    //     const queuesArray = gettingQueues.split(',');
    //     setQueueus(queuesArray)
    //   }
    // }, [gettingQueues])


    // useEffect(() => {
    //   if (inboundCallData) {
    //     setInbound(inboundCallData?.results)

    //   }

    // }, [inboundCallData])

 
    // const {
    //     data: telephonyPri,
    //     status: telephonyPriStatus,
    //     isLoading: telephonyPriLoading,
    // } = useTelephonyPRIQuery({}, { skip: !mobileNumberEntered });
    // const {
    //     data: callData,
    //     status: callStatus,
    //     isLoading: callLoading,
    // } = useTelephonyServerQuery({}, { skip: !call || !gettingTelephonyData });
    // const {
    //     data: extemsionData,
    //     status: extemsionStatus,
    //     isLoading: extemsionLoading,
    //     refetch: extemsionRefetch,
    // } = useTelephonyExtensionQuery(gettingExtension, {
    //     skip: !gettingExtension,
    // });
    // const {
    //     data: pauseCodeData,
    //     status: pauseCodeStatus,
    //     isLoading: pauseCodeLoading,
    //     refetch: pauseCodeRefetch,
    // } = usePauseCodeDataQuery({});

    //   useEffect(() => {
    //     const timer = setInterval(() => {
    //         const gettingTelephonyData = localStorage.getItem(TELEPHONEURL);
    //         console.log(gettingTelephonyData, "gettingngng");
    //     }, 5000);

    //     return () => clearInterval(timer);
    // }, []);

    // useEffect(() => {

    //   if (callData?.results) {

    //    const timer =  setInterval(() => {

    //     extemsionRefetch()
    //     }, 2000)

    //     return () => clearInterval(timer)
    //   }

    // }, [callData?.results, extemsionRefetch])

    // useEffect(() => {
    //     if (pauseCodeData) {
    //         setGetPauseCode(pauseCodeData);
    //     }
    // }, [pauseCodeData]);

    // useEffect(() => {
    //     if (telephonyPri) {
    //         setTelephonyPriData(telephonyPri?.results);
    //     }
    // }, [telephonyPri]);

    // const mobileNumberHandler = (e) => {
    //     setMobileNumberEntered(true);
    // };

    // const callhandler = () => {
    //     if (!gettingTelephonyData) {
    //         toast.error("Please Login to telephony");
    //         return;
    //     }

    //     setCall(true);
    // };

    // TELEPHONY DATA END
    useEffect(() => {
        if (data) {
            setIsLoading(false);
        }
    }, [data]);

    useEffect(() => {
        if (teamsData) {
            setTheTeams(teamsData.teams);
        }
    }, [teamsData]);

    useEffect(() => {
        if (selectedTeam) {
            setParams((prevParams) => ({
                ...prevParams,
                team: selectedTeam,
            }));
        }
    }, [selectedTeam]);

    useEffect(() => {
        if (dateRange) {
            const formatToDate = (date) => {
                const d = new Date(date);
                const year = d.getFullYear();
                const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are zero-based
                const day = String(d.getDate()).padStart(2, "0");
                return `${year}-${month}-${day}`;
            };

            setParams((prevParams) => ({
                ...prevParams,
                start_date: formatToDate(dateRange[0].startDate),
                end_date: formatToDate(dateRange[0].endDate),
            }));
        }
    }, [dateRange]);

    useEffect(() => {
        if (params.team && params.start_date && params.end_date) {
            setLoading(true); // Set loading to true before fetching
            refetch().finally(() => setLoading(false)); // Set loading to false after fetching
        }
    }, [params, refetch]);


    // const pauseHandler = async() => {
    //   setIsCallPaused(true)
    //   const unpauseBatch = async (batch) => {
    //     console.log(batch, "batchhh");
    //     const batchPromise = batch?.map(async (queue) => {
    //         try {
    //             const response = await axios.post(
    //                 `https://${telephoneData?.domain}/click2call/acd.php?AgentExten=${gettingExtension}&Operation=unpause&Queue=${queue}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&PauseCode=NotReady&break_id=${callBreakId}`,
    //                 {
    //                     AgentExten: gettingExtension,
    //                     Operation: "unpause",
    //                     Queue: queue,
    //                     SecKey: "2c41c5e95e052e9d03f423e9f578b9b3",
    //                     PauseCode: 'Not%20Ready',
    //                     break_id: callBreakId
    //                 },
    //                 {
    //                     headers: {
    //                         Authorization: `Bearer ${localStorage.getItem(
    //                             AUTH_TOKEN
    //                         )}`,
    //                     },
    //                 }
    //             );

    //             setUnPauseResponse(response?.data)

        

             
    //             // await unpauseBatch(storingTelephonyServerData);
    //             // console.log(`API call for Queue=${queue} response:`, response.data);
    //         } catch (error) {
    //             console.log(error)
    //         }
    //     });

    //     // Wait for all APIs in this batch to complete
    //     await Promise.all(batchPromise);

    //     if (inbound) {
    //       const extensionObj = {
    //         extension: gettingExtension,
    // state: "unpause"
    //       }
    //      const response =  await extensionStateTrigger(extensionObj)
    
    //      console.log(response,"sdcdscscccc")

    //      if (response?.data) {
    //       const callBreakObj = {
    //         action: "resume"
    //       }
    //       const callBreakResponse = await callBreakTrigger({body: callBreakObj, id:callBreakId })

    //       console.log(callBreakResponse, "sdcdscscccsc")

    //      }


    
    //     }
    // };
    // await unpauseBatch(queueus);
    // // await unpauseBatch(queueNamesData, "add");

    // }

    // const resumeHandler = () => {
    //   setIsCallPaused(false)

    // }

    return (
        <div
            className="d-flex app-main flex-column flex-row-fluid"
            id="kt_app_main"
        >
            <div className="d-flex flex-column flex-column-fluid">
                <div className="card-header align-items-center py-1 my-1 gap-2 gap-md-5">
                    <div id="kt_app_toolbar" className="app-toolbar py-3 ">
                        {/*begin::Toolbar container*/}
                        <div
                            className="app-container ms-4 me-4  mb-4 d-flex flex-stack"
                            style={{ width: "calc(100% - 25px)", gap: "25px" }}
                        >
                            {/*begin::Page title*/}
                            <div
                                className="page-title d-flex flex-column justify-content-center flex-wrap me-3"
                                style={{ width: "49%" }}
                            >
                                {/*begin::Title*/}
                                <h2>Email Dashboard</h2>

                                {/* Telephony UI START */}

                                {/* <TelephonyFrontend /> */}
                                {/* <div
                                    style={{
                                        display: "flex",
                                        gap: "20px",
                                        alignItems: "center",
                                    }}
                                 >
                                    <div
                                        style={{ display: "flex", gap: "10px" }}
                                    >
                                        <input
                                            className="form-control"
                                            placeholder="Mobile No."
                                            onBlur={mobileNumberHandler}
                                        />
                                        <select className="form-select">
                                            <option>Select PRI</option>
                                            {telephonyPriData?.map((el) => (
                                                <option>{el?.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <i
                                            className="bi bi-telephone-fill"
                                            style={{ fontSize: "20px" }}
                                            onClick={callhandler}
                                        ></i>
                                        <i
                                            className="bi bi-calculator-fill"
                                            style={{ fontSize: "20px" }}
                                        ></i>
                                    </div>
                                    {gettingTelephonyData ? 
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <div>
                                            {!isCallPaused ? (
                                                <i
                                                    className="bi bi-pause-circle"
                                                    style={{ fontSize: "20px" }}
                                                    data-toggle="tooltip" data-placement="top" title="Resume"
                                                    onClick={pauseHandler}
                                                    // onClick={() =>

                                                    //     setIsCallPaused(true)
                                                    // }
                                                ></i>
                                            ) : (
                                                <i
                                                    className="bi bi-play-circle-fill"
                                                    style={{ fontSize: "20px" }}
                                                    data-toggle="tooltip" data-placement="top" title="Pause"
                                                    onClick={resumeHandler}
                                                    // onClick={() =>
                                                    //     setIsCallPaused(false)
                                                    // }
                                                ></i>
                                            )}
                                        </div>
                                        <select className="form-select">
                                            <option selected value="">
                                                Select Pause Code
                                            </option>

                                            {getpauseCode?.map(
                                                (code, index) => (
                                                    <option>
                                                        {code?.name}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </div>
                                     : ""
                                          }
                                </div> */}
                                {/* TELEPHONY UI END */}
                            </div>

                            <div
                                style={{ flex: "1" }}
                                className="d-flex flex-start align-items-center gap-2 gap-lg-3  justify-content-end"
                            >
                                <div
                                    style={{
                                        whiteSpace: "nowrap",
                                        width: "26%",
                                    }}
                                >
                                    <EmailCustomDate
                                        dateRange={dateRange}
                                        setDateRange={setDateRange}
                                    />
                                </div>
                                <div
                                    className="w-100 mw-250px"
                                    style={{ position: "relative" }}
                                >
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "-9px",
                                            left: "5px",
                                            background: "white",
                                        }}
                                    >
                                        <p
                                            style={{
                                                margin: "0",
                                                fontSize: "12px",
                                            }}
                                        >
                                            Team
                                        </p>
                                    </div>
                                    {/*begin::Select2*/}
                                    <select
                                        className="form-select"
                                        value={selectedTeam}
                                        onChange={(e: any) => {
                                            setSelectedTeam(e.target.value);
                                        }}
                                    >
                                        <option value="">Select team</option>
                                        {theTeams.map((team: any) => (
                                            <option
                                                key={team.id}
                                                value={team.id}
                                            >
                                                {team.name}
                                            </option>
                                        ))}
                                    </select>
                                    {/*end::Select2*/}
                                </div>
                                {/* 
                <div className="w-100 mw-250px">
            
                  <select className="form-select">
                    <option value="select team" selected hidden>
                      Select users
                    </option>
                    <option value="Team 01">team 01</option>
                    <option value="Team 02">team 02</option>
                    <option value="Team 03">team 03</option>
                  </select>
              
                </div> */}
                            </div>
                            {/*end::Actions*/}
                        </div>
                        {/*end::Toolbar container*/}
                    </div>

                    <div
                        id="kt_app_content"
                        className="app-content flex-column-fluid"
                    >
                        <div className="card-body pt-0">
                            {isLoading || loading ? (
                                // Render the loading spinner if isLoading is true
                                <LoadingSpinner />
                            ) : (
                                <>
                                    <div className="row g-5 g-xl-10 mb-xl-10">
                                        <div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
                                            <SalesChart
                                                selectedTeam={selectedTeam}
                                            />
                                        </div>
                                        {/*begin::Col*/}
                                        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
                                            {/*begin::Card widget 4*/}
                                            <InboxChart
                                                emailDashboardData={
                                                    emailDashboardData
                                                }
                                            />
                                            {/*end::Card widget 4*/}

                                            {/*begin::Card widget 5*/}
                                            <ResponseTime
                                                emailDashboardData={
                                                    emailDashboardData
                                                }
                                            />
                                            {/*end::Card widget 5*/}
                                        </div>
                                        {/*end::Col*/}
                                        {/*begin::Col*/}
                                        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
                                            {/*begin::Card widget 6*/}
                                            <SentChart
                                                emailDashboardData={
                                                    emailDashboardData
                                                }
                                            />
                                            {/*end::Card widget 6*/}
                                            {/*begin::Card widget 7*/}

                                            <RepeatCustomerChart
                                                emailDashboardData={
                                                    emailDashboardData
                                                }
                                            />
                                            {/*end::Card widget 7*/}
                                        </div>
                                        {/*end::Col*/}
                                    </div>

                                    <div className="row gx-5 gx-xl-10">
                                        {/*begin::Col*/}
                                        <div className="col-xl-4 mb-5 mb-xl-10">
                                            {/*begin::Card widget 4*/}
                                            <SentinmentsCard />
                                            {/*end::Card widget 4*/}

                                            {/*begin::Card widget 5*/}
                                            <DispositionCards
                                                emailDashboardData={
                                                    emailDashboardData
                                                }
                                            />
                                            {/*end::Card widget 5*/}
                                        </div>
                                        {/*end::Col*/}
                                        <div className="col-xl-8 mb-5 mb-xl-10">
                                            <FirstResponse />
                                        </div>
                                    </div>

                                    <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                                        {/*begin::Col*/}
                                        <div className="col-xl-6">
                                            {/*begin::Card widget 19*/}
                                            <EmailPieChart
                                                overview={[
                                                    emailDashboardData
                                                        ? emailDashboardData
                                                              ?.tickets_by_status_type
                                                              ?.active
                                                        : "",
                                                    emailDashboardData
                                                        ? emailDashboardData
                                                              ?.tickets_by_status_type
                                                              ?.closed
                                                        : "",
                                                    emailDashboardData
                                                        ? emailDashboardData
                                                              ?.tickets_by_status_type
                                                              ?.wip
                                                        : "",
                                                    emailDashboardData
                                                        ? emailDashboardData
                                                              ?.tickets_by_status_type
                                                              ?.closed
                                                        : "",
                                                ]}
                                            />
                                            {/*end::Card widget 19*/}
                                        </div>

                                        <div className="col-xl-6">
                                            {/*begin::Card widget 19*/}
                                            <div className="card card-flush h-lg-100">
                                                <WordCloud />
                                            </div>
                                            {/*end::Card widget 19*/}
                                        </div>
                                        {/*end::Col*/}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailDashboard;
