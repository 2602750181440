import React, { useState, useEffect } from "react";
import DeleteContactModal from "../../../contact/contact/DeleteContactModal";
import { Box, IconButton, Modal, Tooltip, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import {
    useAgentMappingDataQuery,
    useAgentMappingQueueQuery,
    useDeleteAgentMappingCodeMutation,
    useDownloadAgentMappingDataQuery,
} from "../../../../Services/Telephony/Telephony";
import LoadingSpinner from "../../../LoadingSpinner";
import EditAgentMapping from "./EditAgentMapping";
import AddAgentMapping from "./AddAgentMapping";
import "./styles.scss";
import { Loader } from "react-bootstrap-typeahead";
import * as XLSX from "xlsx";


const AgentMapping = () => {
    const [agentMappingData, setAgentMappingData] = useState<any>([]);
    const [downloadAgentTableData, setDownloadAgentMappingTableData] = useState([])
    const [agentId, setAgentId] = useState("");
    const [deleteAgentFieldModalOpen, setDeleteAgentFieldModalOpen] =
        useState(false);
    const [editAgentModalOpen, setEditAgentModalOpen] =
        useState<boolean>(false);
    const [openAddAgentModal, setOpenAddAgentModal] = useState(false);
    const [agentLoader, setAgentLoader] = useState(false);
    const [agentMappingQueueData, setAgentMappingQueueData] = useState([]);
    const [agentMappingDownloadButtonClicked,setAgentMappingDownloadButtonClicked] = useState<boolean>(false)
    const [downloadLoader, setDownloadLoader] = useState(false)

    const extension = [
        { label: "S.No", key: "sno" },
        { label: "User name", key: "user" },
        { label: "Extension", key: "extension" },
        { label: "Caller type", key: "caller" },
        { label: "Queues", key: "queue" },
    ];

    const [deleteExtensionTrigger] = useDeleteAgentMappingCodeMutation();
    const { data: agentQueue } = useAgentMappingQueueQuery(undefined, {
        skip: !(editAgentModalOpen || openAddAgentModal),
    });

    const {
        data: agentMappingTableData,
        isLoading,
        status,
        refetch,
    } = useAgentMappingDataQuery({}, {skip: agentMappingDownloadButtonClicked});

    const {
        data: downloadAgentMappingTableData,
        isLoading: downloadAgentDataLoading,
        status: downloadAgentStatus,
    } = useDownloadAgentMappingDataQuery({}, {skip: !agentMappingDownloadButtonClicked});


    useEffect(() => {
        if (status === "pending") {
            setAgentLoader(true);
            // setAgentMappingDownloadButtonClicked(true)
        } else {
            setAgentLoader(false);
        }
    }, [status]);

    useEffect(() => {
        if (downloadAgentStatus === "pending") {
            setDownloadLoader(true);
            setAgentMappingDownloadButtonClicked(true)
        } else {
            setDownloadLoader(false);
        }
    }, [downloadAgentStatus]);


    useEffect(() => {
        if (agentMappingTableData) {
            setAgentMappingData(agentMappingTableData);
        }
    }, [agentMappingTableData]);

    useEffect(() => {
        if (downloadAgentMappingTableData) {
            setDownloadAgentMappingTableData(downloadAgentMappingTableData);
        }
    }, [downloadAgentMappingTableData]);




    useEffect(() => {
        if (agentQueue) {
            setAgentMappingQueueData(agentQueue);
        }
    }, [agentQueue]);

    const EditModal = (id: any) => {
        setAgentId(id);
        setEditAgentModalOpen(true);
    };

    const closeModal = () => {
        setEditAgentModalOpen(false);
        setDeleteAgentFieldModalOpen(false);
        setOpenAddAgentModal(false);
    };

    const isDeleteModal = (id: any) => {
        setAgentId(id);
        setDeleteAgentFieldModalOpen(true);
    };

    const addExtensionHandler = (e) => {
        e.preventDefault();
        setOpenAddAgentModal(true);
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "40%",
        overflow: "scroll",
        bgcolor: "background.paper",
        border: "1px solid transparent",
        boxShadow: 2,
        borderRadius: "5px",
        p: 4,
        paddingBottom: "0",
    };

    useEffect(() => {
        if (
            downloadAgentStatus === "fulfilled" &&
            agentMappingDownloadButtonClicked &&
            downloadAgentTableData?.length >= 0
        ) {
            setAgentMappingDownloadButtonClicked(false);
    
            setDownloadLoader(false);
            downloadAgentMappingData();
        }
      }, [agentMappingDownloadButtonClicked, downloadAgentStatus, downloadAgentTableData]);

    console.log(downloadAgentTableData, "downloadAgentTableData")
    const downloadAgentMappingData = async () => {
        setAgentMappingDownloadButtonClicked(true)
        setDownloadLoader(true)
        if ( downloadAgentTableData&& downloadAgentTableData.length > 0) {
          const csvData = downloadAgentTableData?.map((user) => ({
            Name: user?.user?.username,
            // Tags: user?.tags?.map((tag) => tag.name).join(","),
            Extension: user?.extension_no,
            "Caller type": user?.call_type,
            Queues: user?.queues?.map((queue) => queue?.name).join(',')
            // "Created date": user.created ? formatDate(user.created) : "",
          }));
    
          const worksheet = XLSX.utils.json_to_sheet(csvData);
    
          const csvOutput = XLSX.utils.sheet_to_csv(worksheet);
    
          const blob = new Blob([csvOutput], {
            type: "text/csv;charset=utf-8;",
          });
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "Agent_mapping.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        //   setAgentLoader(false)
        setAgentMappingDownloadButtonClicked(false)

        setDownloadLoader(false);
        //   setAccountDownloadButtonClicked(false);

        setDownloadAgentMappingTableData([]);
        } else {
            setDownloadLoader(false)
        setAgentMappingDownloadButtonClicked(false)

        //   setAccountDownloadLoading(false);
        }
      };


    return (
        <div className="agent_mapping_cont">
            <div className="row mb-5">
                <div className="col-10">
                    <span className="input-group-text" id="basic-addon1">
                        Agent mapping
                    </span>
                </div>

                <div className="col-2 d-flex justify-content-end gap-5">
                    <div className="card-toolbar d-flex gap-2">
                        <button
                            className="input-group-text form-control btn-sm fw-bold d-flex align-items-center min-w-150px"
                            style={{
                                justifyContent: "center",
                                fontSize: "15px",
                            }}
                            onClick={() => setOpenAddAgentModal(true)}
                        >
                            <i className="bi bi-plus-lg text-dark me-2"></i>
                            <span>Add</span>
                        </button>
                    </div>
                    <Tooltip title="Download agent mapping Data">
                    <IconButton
                      onClick={() => {
                        setAgentMappingDownloadButtonClicked(true);

                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        downloadAgentMappingData;
                      }}
                    >
                      {downloadLoader ? (
                        <Loader />
                      ) : (
                        <i
                          className="bi bi bi-download"
                          style={{
                            fontSize: "25px",
                            color: "black",
                          }}
                        ></i>
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div
                        className="card  my-1 p-4"
                        style={{ backgroundColor: "#E4E4E47F" }}
                    >
                        <div className="row align-items-center">
                            {extension?.map((card, index) => (
                                <div className="col" key={index}>
                                    <label htmlFor="">
                                        <strong
                                            style={{
                                                display: "flex",
                                                gap: "2px",
                                            }}
                                        >
                                            {card?.label}
                                            {/* {card.label !== "Branch user" &&
                      card.label !== "Role" &&
                      card.label !== "Last updated date" && (
                        <SortingTableUsers
                          index={
                            card.label === "User"
                              ? "username"
                              : card.label === "Created by"
                              ? "created"
                              : card.label === "User id"
                              ? "id"
                              : card.label
                          }
                          setLiftingColumn={setLiftingColumn}
                        />
                      )} */}
                                        </strong>
                                    </label>
                                </div>
                            ))}
                            <div className="col text-end">
                                <label
                                    htmlFor=""
                                    style={{ paddingRight: "25px" }}
                                >
                                    <strong>Action</strong>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" style={{ height: "60vh", overflow: "scroll" }}>
                <div className="col" style={{ paddingRight: "0px" }}>
                    {isLoading || agentLoader ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            {agentMappingData?.length ? (
                                <>
                                    {agentMappingData?.map(
                                        (user: any, index: any) => (
                                            <div
                                                className="card mt-2 my-1 p-4"
                                                key={index}
                                            >
                                                <div className="row align-items-start">
                                                    <div className="col text-start">
                                                        <label
                                                            htmlFor=""
                                                            style={{
                                                                paddingLeft:
                                                                    "10px",
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </label>
                                                    </div>

                                                    <div className="col text-start">
                                                        <label htmlFor="" style={{paddingLeft: "10px"}}>
                                                            {
                                                                user?.user
                                                                    ?.username
                                                            }
                                                        </label>
                                                    </div>

                                                    <div className="col text-start">
                                                        <label htmlFor="" style={{paddingLeft: "15px"}}>
                                                            {user?.extension_no}
                                                        </label>
                                                    </div>

                                                    <div className="col text-start">
                                                        <label htmlFor="" style={{paddingLeft: "18px"}}>
                                                            {user?.call_type}
                                                        </label>
                                                    </div>

                                                    <div
                                                        className="col text-start"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title={user?.queues
                                                            ?.map(
                                                                (queue) =>
                                                                    queue?.name
                                                            )
                                                            .join(", ")}
                                                        style={{
                                                            width: "100%",
                                                            whiteSpace:
                                                                "nowrap",
                                                            textOverflow:
                                                                "ellipsis",
                                                            overflow: "hidden",
                                                        }}
                                                    >
                                                        {user?.queues?.map(
                                                            (
                                                                queue: any,
                                                                index: number
                                                            ) => (
                                                                <span style={{paddingLeft: "5px"}}
                                                                    key={
                                                                        queue.id
                                                                    }
                                                                >
                                                                    {
                                                                        queue?.name
                                                                    }
                                                                    {index <
                                                                        user
                                                                            ?.queues
                                                                            .length -
                                                                            1 &&
                                                                        " |"}
                                                                </span>
                                                            )
                                                        )}
                                                    </div>

                                                    <div
                                                        className="col text-center d-flex"
                                                        style={{
                                                            gap: "10px",
                                                            justifyContent:
                                                                "end",
                                                            paddingRight:
                                                                "25px",
                                                        }}
                                                    >
                                                        <button
                                                            className="text-hover-primary border-0 bg-white me-2"
                                                            title="Edit"
                                                            onClick={() =>
                                                                EditModal(
                                                                    user.id
                                                                )
                                                            }
                                                            // onClick={() => isEditModal(user)}
                                                        >
                                                            <i className="bi bi-pencil text-dark fs-4"></i>
                                                        </button>
                                                        <button
                                                            className="text-hover-danger border-0 bg-white me-2"
                                                            title="Delete"
                                                            onClick={() =>
                                                                isDeleteModal(
                                                                    user
                                                                )
                                                            }
                                                        >
                                                            <i className="bi bi-trash text-dark fs-4"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </>
                            ) : (
                                ""
                                // <EmptyRows value={""} />
                            )}
                        </>
                    )}
                </div>
            </div>

            <Modal
                open={editAgentModalOpen}
                onClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...style, position: "absolute" }}>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: "fixed",
                            top: 16,
                            right: 16,
                            zIndex: 1301,
                            background: "white",
                        }} // Ensure it's above the modal content
                    >
                        <GridCloseIcon />
                    </IconButton>
                    <Typography
                        id="modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ mb: 2 }}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Edit agent mapping
                    </Typography>

                    <Box>
                        <EditAgentMapping
                            closeModal={closeModal}
                            // refetchingData={refetchTelephonyServerData}
                            editExtensionId={agentId}
                            refetch={refetch}
                            agentMappingQueueData={agentMappingQueueData}
                        />
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={openAddAgentModal}
                onClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...style, position: "absolute" }}>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: "fixed",
                            top: 16,
                            right: 16,
                            zIndex: 1301,
                            background: "white",
                        }} // Ensure it's above the modal content
                    >
                        <GridCloseIcon />
                    </IconButton>
                    <Typography
                        id="modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ mb: 2 }}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Agent mapping
                    </Typography>

                    <Box>
                        <AddAgentMapping
                            closeModal={closeModal}
                            refetch={refetch}
                            agentMappingQueueData={agentMappingQueueData}
                        />
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={deleteAgentFieldModalOpen}
                onClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...style, position: "absolute" }}>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: "fixed",
                            top: 16,
                            right: 16,
                            zIndex: 1301,
                            background: "white",
                        }} // Ensure it's above the modal content
                    >
                        {/* <GridCloseIcon /> */}
                    </IconButton>
                    {/* <Typography
                                        id="modal-title"
                                        variant="h6"
                                        component="h2"
                                        sx={{ mb: 2 }}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        Edit Extension

                                    </Typography> */}

                    <Box>
                        <DeleteContactModal
                            closeModal={closeModal}
                            // refetchingData={refetchTelephonyServerData}
                            setFieldsData={setAgentMappingData}
                            fieldsData={agentMappingData}
                            storingFieldData={agentId}
                            refetch={refetch}
                            // storingFieldData={extensionId}
                            trigger={deleteExtensionTrigger}
                        />
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default AgentMapping;
