import React, { useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import{useUpdateCamapignMutation} from "../../../Services/campign/CampaignDetail"
interface IProps {
  data: any;
  closeStatusModal: any;
  refetch: any;
  refetchAuditTrail: any;
  selectedStatus: any;
  handleStatusUpdate: any; // Add this prop to handle status update
  refetchAuditTrailData: any;
  selectedAllStatus: any;
  storingTicketId: any;
}

const ChangeStatusCampaignModal = ({
  data,
  closeStatusModal,
  refetch,
  refetchAuditTrail,
  selectedStatus,
  handleStatusUpdate,
  refetchAuditTrailData,
  selectedAllStatus,
  storingTicketId,
}: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const params = useParams();
  const uuid = params.uuid;

  const [updateStatus] = useUpdateCamapignMutation();

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await updateStatus({
        id: storingTicketId ? storingTicketId : `${uuid}`,
        status_type: selectedStatus,
        status:
          selectedAllStatus.status_type === "wip" ? selectedAllStatus.id : null, // WIP
        closed_status:
          selectedAllStatus.status_type === "closed"
            ? selectedAllStatus.id
            : null, // closed
      });

      if (response.data) {
        setIsLoading(false); // Reset loading state
        toast.success("Status updated successfully!");
        handleStatusUpdate(); // Call the function to handle status update
        closeStatusModal();
        refetch();
        // refetchAuditTrail();
        // refetchAuditTrailData();
      } else if (response.error) {
        // Extract error details from the response
        const { status, data }: any = response.error;

        if (data?.detail) {
          // Show detailed error messages dynamically
          data.detail.forEach((message) => {
            toast.error(` ${message}`); // Display each error message
          });
        } else {
          toast.error(
            `Error ${status}: Something went wrong. Please try again.`
          );
        }

        setIsLoading(false); // Reset loading state
      }
    } catch (error) {
      setIsLoading(false); // Reset loading state
      console.error(error); // Log the error
    }
    closeStatusModal();
  };

  return (
    <div className="card-body">
      <span className="fs-5 fw-bold">
        Are you sure you want to change the status?
      </span>
      <div className="d-flex justify-content-end mt-10">
        <button
          type="reset"
          className="btn btn-sm btn-white me-3"
          onClick={closeStatusModal}
          disabled={isLoading}
        >
          Cancel
        </button>

        <button
          type="submit"
          className="btn btn-sm btn-primary"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? "Updating..." : "Update"}
        </button>
      </div>
    </div>
  );
};

export default ChangeStatusCampaignModal;
