import { Box, IconButton, Modal, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import DeleteContactModal from "../../../contact/contact/DeleteContactModal";
import EditDID from "./EditDID";
import LoadingSpinner from "../../../LoadingSpinner";
import {
    useDeleteDIDDataMutation,
    useGetAllDIDDataQuery,
} from "../../../../Services/Telephony/Telephony";
import { GridCloseIcon } from "@mui/x-data-grid";
import AddDID from "./AddDID";

const DID = () => {
    const [getAllData, setGetAllData] = useState([]);
    const [didDataLoading, setDidDataLoading] = useState<boolean>(false);
    const [didEditModal, setDidEditModal] = useState(false);
    const [dIDId, setDIDId] = useState("");
    const [didDeleteModal, setDidDeleteModal] = useState(false);
    const [addDiDModal, setAddDiDModal] = useState(false);

    const {
        data: DIDData,
        refetch,
        isLoading,
        status: didStatus,
    } = useGetAllDIDDataQuery({});
    const [deleteDiDTrigger] = useDeleteDIDDataMutation();

    const didDataHeader = [
        { label: "Sr.No", key: "sr" },
        { label: "DID name", key: "name" },
        { label: "DID number", key: "number" },
    ];

    useEffect(() => {
        if (DIDData) {
            setGetAllData(DIDData?.results);
        }
    }, [DIDData]);

    useEffect(() => {
        if (didStatus === "pending") {
            setDidDataLoading(true);
        } else {
            setDidDataLoading(false);
        }
    }, [didStatus]);

    const isEditModal = (DiDData) => {
        setDIDId(DiDData);
        setDidEditModal(true);
    };

    const deleteModal = (DiDData) => {
        setDIDId(DiDData);
        setDidDeleteModal(true);
    };

    const closeModal = () => {
        setDidEditModal(false);
        setDidDeleteModal(false);
        setAddDiDModal(false);
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "30%",
        overflow: "scroll",
        bgcolor: "background.paper",
        border: "1px solid transparent",
        boxShadow: 2,
        borderRadius: "5px",
        p: 4,
        paddingBottom: "0",
    };

    return (
        <div>
            <div className="row mb-5">
                <div className="col-10">
                    <span className="input-group-text" id="basic-addon1">
                        DID
                    </span>
                </div>

            <div className="col-2 d-flex justify-content-end">
                <div
                    className="card-toolbar d-flex gap-2"
                >
                    <button
              className="input-group-text form-control btn-sm fw-bold d-flex align-items-center min-w-150px"
              style={{ justifyContent: "center",
                fontSize: "15px",}}
              onClick={() => setAddDiDModal(true)}
                    >
                          <i className="bi bi-plus-lg text-dark me-2"></i>
                          <span>Add</span>
                    </button>
                </div>
            </div>
            </div>

            <div className="row">
                <div className="col">
                    <div
                        className="card  my-1 p-4"
                        style={{ backgroundColor: "#E4E4E47F" }}
                    >
                        <div className="row align-items-center">
                            {didDataHeader?.map((card, index) => (
                                <div
                                    className="col"
                                    style={{
                                        textAlign:
                                            card.key === "sr"
                                                ? "start"
                                                : "center",
                                    }}
                                    key={index}
                                >
                                    <label htmlFor="">
                                        <strong
                                            style={{
                                                display: "flex",
                                                gap: "2px",
                                            }}
                                        >
                                            {card?.label}
                                        </strong>
                                    </label>
                                </div>
                            ))}
                            <div className="col text-end">
                                <label
                                    htmlFor=""
                                    style={{ paddingRight: "25px" }}
                                >
                                    <strong>Action</strong>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" style={{ height: "55vh", overflow: "scroll" }}>
                <div className="col" style={{ paddingRight: "0px" }}>
                    {didDataLoading || isLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            {getAllData?.length ? (
                                <>
                                    {getAllData?.map(
                                        (user: any, index: any) => (
                                            <div
                                                className="card mt-2 my-1 p-4"
                                                key={index}
                                            >
                                                <div className="row align-items-start">
                                                    <div className="col text-start" >
                                                        <label
                                                            htmlFor=""
                                                         
                                                        >
                                                            {index + 1}
                                                        </label>
                                                    </div>

                                                    <div className="col text-center" >
                                                        <label htmlFor="">
                                                            {user?.name}
                                                        </label>
                                                    </div>

                                                    <div className="col text-center">
                                                        <label htmlFor="" style={{paddingLeft: "25px"}}>
                                                            {user?.phone_no}
                                                        </label>
                                                    </div>

                                                    <div
                                                        className="col text-center d-flex"
                                                        style={{
                                                            gap: "10px",
                                                            justifyContent:
                                                                "end",
                                                            paddingRight:
                                                                "25px",
                                                        }}
                                                    >
                                                        <button
                                                            className="text-hover-primary border-0 bg-white me-2"
                                                            title="Edit"
                                                            // onClick={() => EditModal(user.id)}
                                                            onClick={() =>
                                                                isEditModal(
                                                                    user
                                                                )
                                                            }
                                                        >
                                                            <i className="bi bi-pencil text-dark fs-4"></i>
                                                        </button>
                                                        <button
                                                            className="text-hover-danger border-0 bg-white me-2"
                                                            title="Delete"
                                                            onClick={() =>
                                                                deleteModal(
                                                                    user
                                                                )
                                                            }
                                                        >
                                                            <i className="bi bi-trash text-dark fs-4"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </>
                            ) : (
                                ""
                                // <EmptyRows value={""} />
                            )}
                        </>
                    )}
                </div>
            </div>

            <Modal
                open={didEditModal}
                onClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...style, position: "absolute" }}>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: "fixed",
                            top: 16,
                            right: 16,
                            zIndex: 1301,
                            background: "white",
                        }} // Ensure it's above the modal content
                    >
                        <GridCloseIcon />
                    </IconButton>
                    <Typography
                        id="modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ mb: 2 }}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Edit DID
                    </Typography>

                    <Box>
                        <EditDID
                            closeModal={closeModal}
                            refetch={refetch}
                            didId={dIDId}
                        />
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={didDeleteModal}
                onClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...style, position: "absolute" }}>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: "fixed",
                            top: 16,
                            right: 16,
                            zIndex: 1301,
                            background: "white",
                        }} // Ensure it's above the modal content
                    >
                        {/* <GridCloseIcon /> */}
                    </IconButton>
                    {/* <Typography
                                            id="modal-title"
                                            variant="h6"
                                            component="h2"
                                            sx={{ mb: 2 }}
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            Edit Extension
    
                                        </Typography> */}

                    <Box>
                        <DeleteContactModal
                            closeModal={closeModal}
                            setFieldsData={setGetAllData}
                            fieldsData={getAllData}
                            storingFieldData={dIDId}
                            refetch={refetch}
                            trigger={deleteDiDTrigger}
                        />
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={addDiDModal}
                onClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...style, position: "absolute" }}>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: "fixed",
                            top: 16,
                            right: 16,
                            zIndex: 1301,
                            background: "white",
                        }} // Ensure it's above the modal content
                    >
                        <GridCloseIcon />
                    </IconButton>
                    <Typography
                        id="modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ mb: 2 }}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Add DID
                    </Typography>

                    <Box>
                        <AddDID closeModal={closeModal} refetch={refetch} />
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default DID;
