import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { Button, CloseButton, Modal } from "react-bootstrap";

interface IPROPS {
  closeFilterModal: any;
  setLiftingContactFilterData: any;
  setPage: any;
  contactDataa: any;
  liftingContactFilterData: any;
}

const AccountFilterModal: React.FC<IPROPS> = ({
  closeFilterModal,
  setLiftingContactFilterData,
  setPage,
  contactDataa,
  liftingContactFilterData,
}) => {
  const [storingFilterObject, setStoringFilterObject] = useState({
    name: "",
    tags: "",
    createdDate: "",
  });

  const [checkedFields, setCheckedFields] = useState({
    name: false,
    tags: false,
    createdDate: false,
  });

  useEffect(() => {
    if (liftingContactFilterData?.filtering) {
      setStoringFilterObject(liftingContactFilterData.filtering);

      setCheckedFields({
        name: !!liftingContactFilterData.filtering.name,
        tags: !!liftingContactFilterData.filtering.tags,
        createdDate: !!liftingContactFilterData.filtering.createdDate,
      });
    }
  }, [liftingContactFilterData]);

  const handleCheckboxChange = (field) => {
    setCheckedFields((prev) => {
      const updatedCheckedFields = {
        ...prev,
        [field]: !prev[field],
      };

      if (!updatedCheckedFields[field]) {
        setStoringFilterObject((prevState) => ({
          ...prevState,
          [field]: "", // Reswt
        }));
      }

      return updatedCheckedFields;
    });
  };

  const searchContactHandler = (e) => {
    e.preventDefault();
    setPage(1);

    const filterObj = {
      filtering: storingFilterObject,
    };

    setLiftingContactFilterData(filterObj);

    if (contactDataa) {
      closeFilterModal(true);
    }
  };

  const resetFilters = (e) => {
    e.preventDefault();

    setCheckedFields({
      name: false,
      tags: false,
      createdDate: false,
    });

    setStoringFilterObject({
      name: "",
      tags: "",
      createdDate: "",
    });

    setLiftingContactFilterData({
      name: "",
      tags: "",
      createdDate: "",
    });
  };

  return (
    <Modal show={true} onHide={closeFilterModal} centered>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Modal.Title className="fw-bold">Filters</Modal.Title>
          <CloseButton onClick={closeFilterModal} />
        </div>

        {/* Checkbox for Name */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            borderTop: "1px solid #eff2f5",
          }}
        >
          <FormControlLabel
            style={{ width: "30%" }}
            control={
              <Checkbox
                checked={checkedFields.name}
                onChange={() => handleCheckboxChange("name")}
              />
            }
            label="Account"
          />
          {checkedFields.name && (
            <input
              style={{ flex: "1" }}
              className="form-control"
              placeholder="Search by account"
              value={storingFilterObject.name}
              onChange={(e) =>
                setStoringFilterObject((prevState) => ({
                  ...prevState,
                  name: e.target.value,
                }))
              }
            />
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            style={{ width: "30%", whiteSpace: "nowrap" }}
            control={
              <Checkbox
                checked={checkedFields.createdDate}
                onChange={() => handleCheckboxChange("createdDate")}
              />
            }
            label="Created date"
          />

          {checkedFields.createdDate && (
            <TextField
              id="created-date"
              type="date"
               style={{ flex: "1" }}
              className="form-control"
              InputProps={{
                inputProps: {
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={storingFilterObject.createdDate}
              onChange={(e) =>
                setStoringFilterObject((prevState) => ({
                  ...prevState,
                  createdDate: e.target.value,
                }))
              }
            />
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            style={{ width: "30%", whiteSpace: "nowrap" }}
            control={
              <Checkbox
                checked={checkedFields.tags}
                onChange={() => handleCheckboxChange("tags")}
              />
            }
            label="Tags"
          />
          {checkedFields.tags && (
            <input
              style={{ flex: "1" }}
              className="form-control"
              placeholder="Search by tags"
              value={storingFilterObject.tags}
              onChange={(e) =>
                setStoringFilterObject((prevState) => ({
                  ...prevState,
                  tags: e.target.value,
                }))
              }
            />
          )}
        </div>

        <div style={{ display: "flex", justifyContent: "end", gap: "20px" }}>
          <Button variant="secondary" onClick={resetFilters}>
            Reset Filters
          </Button>
          <button className="btn btn-primary" onClick={searchContactHandler}>
            Search
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AccountFilterModal;
