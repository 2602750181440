import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const GetUsers = createApi({
  reducerPath: "GetUsers",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    GetUsers: builder.query({
      query: ({ pageSize, page }) => ({
        url: `v1/users/users/?page_size=${pageSize}&page=${page}`,
        method: "GET",
      }),
    }),

    userStatus: builder.query({
      query: (isEnabled) => {
        let convert = !!isEnabled ? "enable" : "disable";
        return {
          url: `v1/users/users/?user_status=${convert}&`,
          method: "GET",
        };
      },
    }),

    sortingTable: builder.query({
      query: ({ id, pageSize, page }) => ({
        url: `/v1/users/users/?ordering=${id}&page_size=${pageSize}&page=${page}`,
        method: "GET",
      }),
    }),

    usersTeamSelect: builder.query({
      query: (selectedTeams) => {
        return {
          url: `v1/users/user-list/${selectedTeams}/?`,
          method: "GET",
        };
      },
    }),

    inviteUsers: builder.mutation({
      query: () => ({
        url: `v1/users/users/`,
        method: "POST",
      }),
    }),

    editUser: builder.query({
      query: (user) => {
        return {
          url: `/v1/users/users/${user.id}?`,
          method: "GET",
        };
      },
    }),

    deleteUser: builder.mutation({
      query: (id) => {
        return {
          url: `/v1/users/users/${id}/`,
          method: "DELETE",
        };
      },
    }),

    updateUser: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `/v1/users/users/${id}/`,
          method: "PATCH",
          body,
        };
      },
    }),

    releaseSession: builder.query({
      query: (selectedEditId) => {
        return {
          url: `/v1/users/release-session/?id=${selectedEditId}&`,
          method: "GET",
        };
      },
    }),

    allUsersData: builder.query({
      query: () => {
        return {
          url: `/v1/users/users/?`,
          method: "GET",
        };
      },
    }),

    passwordReset: builder.mutation({
      query: ({ body, username }) => {
        return {
          url: `v1/users/reset-password/${username}/`,
          method: "PUT",
          body,
        };
      },
    }),

    deactivateAccount: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `v1/users/user-enable-disable/${id}/?user_status=disable&`,
          method: "PUT",
          body,
        };
      },
    }),

    bulkUpload: builder.mutation({
      query: ({ body }) => {
        return {
          url: `v1/users/users/bulk-upload/`,
          method: "POST",
          body,
        };
      },
    }),

    userActivate: builder.mutation({
      query: (id) => {
        return {
          url: `v1/users/user-enable-disable/${id}/?user_status=enable&`,
          method: "PUT",
        };
      },
    }),

    dynamicField: builder.query({
      query: () => {
        return {
          url: `v1/common/dynamic-field-definitions/?model_name=userprofile&`,
          method: "GET",
        };
      },
    }),

    activeUserQuery: builder.query({
      query: ({
        page,
        pageSize,
        sort,
        selectedTeam,
        liftingActiveUserFilterData,
        isEnabled,
      }) => {
        let url = isEnabled
          ? `v1/users/users/?page=${page}&page_size=${pageSize}&teams__id=${selectedTeam}`
          : `v1/users/users/?user_status=disable&page=${page}&page_size=${pageSize}&teams__id=${selectedTeam}`;

        if (sort) {
          url += `&ordering=${sort}`;
        }
        if (liftingActiveUserFilterData?.filtering?.name) {
          url += `&username__icontains=${liftingActiveUserFilterData?.filtering?.name}`;
        }
        if (liftingActiveUserFilterData.filtering?.createdDate) {
          url += `&created__date=${liftingActiveUserFilterData.filtering?.createdDate}`;
        }
        if (liftingActiveUserFilterData.filtering?.email) {
          url += `&id=${liftingActiveUserFilterData?.filtering?.email}`;
        }

        if (liftingActiveUserFilterData.filtering?.Id) {
          url += `&id=${liftingActiveUserFilterData?.filtering?.Id}`;
        }

        if (liftingActiveUserFilterData.filtering?.role) {
          url += `&role=${liftingActiveUserFilterData?.filtering?.role}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
    }),

    activeUserQueryDownload: builder.query({
      query: ({
        //   page,
        sort,
        liftingActiveUserFilterData,
        isEnabled,
        selectedTeam,
        //   downloadButtonClicked,
      }) => {
        let url = isEnabled
          ? `v1/users/users/?teams__id=${selectedTeam}`
          : `v1/users/users/?user_status=disable/?teams__id=${selectedTeam}`;

        //   if (!downloadButtonClicked && page) {
        //     url += `&page=${page}`;
        //   }

        if (sort) {
          url += `&ordering=${sort}`;
        }

        if (liftingActiveUserFilterData.filtering?.createdDate) {
          url += `&created__date=${liftingActiveUserFilterData.filtering?.createdDate}`;
        }

        if (liftingActiveUserFilterData.filtering?.Id) {
          url += `&id=${liftingActiveUserFilterData?.filtering?.Id}`;
        }

        if (liftingActiveUserFilterData.filtering?.name) {
          url += `&username__icontains=${liftingActiveUserFilterData?.filtering?.name}`;
        }

        if (liftingActiveUserFilterData.filtering?.role) {
          url += `&role=${liftingActiveUserFilterData?.filtering?.role}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useActiveUserQueryDownloadQuery,
  useActiveUserQueryQuery,
  useSortingTableQuery,
  useGetUsersQuery,
  useUserStatusQuery,
  useUsersTeamSelectQuery,
  useInviteUsersMutation,
  useEditUserQuery,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useReleaseSessionQuery,
  useAllUsersDataQuery,
  usePasswordResetMutation,
  useDeactivateAccountMutation,
  useBulkUploadMutation,
  useUserActivateMutation,
  useDynamicFieldQuery,
} = GetUsers;
