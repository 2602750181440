import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { AuthPage, Logout } from "../components/auth";
import { AUTH_TOKEN, CIRCLEONE_LOGIN_TYPE } from "../constants";
import { PrivateRoutes } from "./PrivateRoutes";
import App from "../components/app/app";
import UserVerifiedPage from "../components/auth/components/UserVerifiedPage";
import ClientLogin from "../pages/clientLogin/ClientLogin";
import ClinetLoginRoute from "..//pages/clientLogin/index";
import OtpPage from "../components/auth/components/OtpPage";
import ErrorPage from "../components/auth/components/ErrorPage";
import MyProfile from "../pages/profile/Profile";
import { useTelephony } from "../components/context/TelephonyContext";
import LoadingSpinner from "../components/LoadingSpinner";
import ReactModal from "react-modal";
import NotificationModal from "../pages/campaign/leads/NotificationModal";
import { useWebSocket } from "../context/WebSocketContext";
import { useNotification } from "../context/NotificationModalContenxt";
const { PUBLIC_URL } = process.env;

const MainAppRoutes: React.FC = () => {

  const { isNotificationModalOpen, socketData, openNotificationModal, closeNotificationModal } = useNotification();

  console.log(socketData, "ppppppppppddddd")

  const socket = useWebSocket();

  console.log(socket, "pppppppppp")

  useEffect(() => {
    const serverResponse = socket?.state?.serverResponse?.[0]?.data;
    if (serverResponse) {
      openNotificationModal(serverResponse);
    }
  }, [socket, socket?.state?.serverResponse]);


  const isAuthorized = localStorage.getItem(AUTH_TOKEN); // User authentication logic should be inserted here
  const loginType = (() => {
    const item = localStorage.getItem(CIRCLEONE_LOGIN_TYPE);
    if (item) {
      try {
        return JSON.parse(item);
      } catch (error) {
        return false;
      }
    }
    return false;
  })();

  



  return (


    <>

{/* {isLoading ? <LoadingSpinner /> :  */}

    <>
      {isAuthorized ? (
        <BrowserRouter>
          <Routes>
            {loginType === true && (
              <>
                <Route
                  path="/client-login/*"
                  element={<ClinetLoginRoute />}
                ></Route>
                <Route path="/profile" element={<MyProfile />} />
                <Route path="logout" element={<Logout />} />
                <Route path="*" element={<Navigate to="/client-login" />} />
              </>
            )}

            {loginType === false && (
              <>
                <Route element={<App />}>
                  <Route path="/*" element={<PrivateRoutes />} />
                  <Route
                    path="/client-login/*"
                    element={<ClinetLoginRoute />}
                  ></Route>
                  <Route path="/profile" element={<MyProfile />} />
                  {/* <Route
                                    index
                                    element={
                                        <Navigate to="/dashboard/email-dashboard" />
                                    }
                                /> */}
                  <Route path="logout" element={<Logout />} />
                </Route>
                <Route path="*" element={<ErrorPage />} />
              </>
            )}
          </Routes>
        </BrowserRouter>
      ) : (
        <BrowserRouter basename={PUBLIC_URL}>
          <Routes>
            <Route path="auth/*" element={<AuthPage />} />
            <Route path="*" element={<Navigate to="/auth" />} />
            <Route path="verification" element={<UserVerifiedPage />} />
            <Route path="*" element={<Navigate to="/auth/login" />} />
            <Route path="/verify-email" element={<OtpPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </BrowserRouter>
      )}
    </>
    <ReactModal
        isOpen={isNotificationModalOpen}
        onRequestClose={closeNotificationModal}
        // style={{
        //   overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
        //   content: { inset: 'auto', margin: 'auto', width: '50%', height: '50%' },
        // }}
        contentLabel="Notification Modal"
      >
        <NotificationModal close={closeNotificationModal} socketData={socketData} />
      </ReactModal>

    {/* } */}
    </>
  );
};

export { MainAppRoutes };