import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import Phone from '../phone/Phone'
const PhoneRoutes = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route path='phone' element={<Phone />} />
            </Route>
        </Routes>
    )
}

export default PhoneRoutes
