import React, {useState, useEffect} from 'react'
import { toast } from 'react-toastify';
import { useAddPauseCodeMutation } from '../../../../Services/Telephony/Telephony';

interface IPROPS {
    closeModal:any
    refetch:any
    pauseCode:any
}

const AddPauseCode: React.FC<IPROPS> = ({closeModal, refetch, pauseCode}) => {
    const [name, setName] = useState('')
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [addPauseCodeTrigger] = useAddPauseCodeMutation()

    const handleSubmit = async(e) => {
        e.preventDefault()

        const manualDialExists = pauseCode?.some(
            (el) => el?.name?.toLowerCase() === "manual dial"
        );
    
        const notReadyExists = pauseCode?.some(
            (el) => el?.name?.toLowerCase() === "not ready"
        );
    
        if (name.trim().toLowerCase() === "manual dial" && manualDialExists) {
            toast.error("Manual Dial already exists");
            return;
        }
    
        if (name.trim().toLowerCase() === "not ready" && notReadyExists) {
            toast.error("Not Ready already exists");
            return;
        }
        const addExtensionObj = {
            name: name
        }

        const response  = await addPauseCodeTrigger(addExtensionObj)

        // restrict "not ready" as well. 

        try {

            if (response?.data) {
                toast.success("Extension Added Successfully")
                closeModal()
                refetch()

                setIsButtonDisabled(true);

                setTimeout(() => {
                  setIsButtonDisabled(false);
                }, 3000);

            }
            else if ("data" in response.error) {
                const errorData = response?.error?.data as {name: string[]}
                const errorMessage = errorData?.name[0]
                toast.error(errorMessage)
                setIsButtonDisabled(true);

                setTimeout(() => {
                  setIsButtonDisabled(false);
                }, 3000);

            }
            
        } catch (error) {
            setIsButtonDisabled(true);

            setTimeout(() => {
              setIsButtonDisabled(false);
            }, 3000);

            
        }

    }



    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <div>
            <label>Pause Code</label>
            <input
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
        </div>
        <div
            style={{
                width: "100%",
                display: "flex",
                gap: "10px",
                justifyContent: "end",
            }}
        >
            <button 
            
           type="reset"
                    className="btn btn-sm btn-white me-2"
            onClick={() => closeModal()}>Cancel</button>
            <button className="btn btn-primary" onClick={handleSubmit} disabled={isButtonDisabled}>
                Save
            </button>
        </div>
    </div>  )
}

export default AddPauseCode