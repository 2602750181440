import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import ReactModal from "react-modal";
import DeleteContactModal from "./DeleteContactModal";
import EmptyRows from "../../reusableComponent/EmptyRows";
import LoadingSpinner from "../../LoadingSpinner";
import { useDeleteContactsInfoDetailsMutation } from "../../../Services/customer/FrontendCustomer";
import { formatDate } from "../../reusableComponent/DateFormat";
import { useLocation, useNavigate } from "react-router-dom";
import SortingTableContact from "../../reusableComponent/SortingTableContact";
import PaginationItem from "@mui/material/PaginationItem";
import { Box, IconButton, Typography, Modal } from "@mui/material";
import ContactCustomerHistory from "./ContactCustomerHistory";
import { GridCloseIcon } from "@mui/x-data-grid";
import { set } from "lodash";
import { CALL } from "../../../constants";
import { useTelephony } from "../../context/TelephonyContext";
import { useWebSocket } from "../../../context/WebSocketContext";

interface UserDetails {
  name: string;
  email: string;
  created: any;
  last_interaction_date:any;
  tags:any;
  id: number | null;
  account: { name?: string };
}

interface IPROPS {
  setLiftingColumn: any;
  setPage: any;
  contactDataa: any;
  page: any;
  refetch: any;
  contactCardLoading: any;
  contactQueryLoading: any;
  totalCount: any;
  setLiftingContactLocationData:any
}

const ContactCards: React.FC<IPROPS> = ({
  setLiftingColumn,
  setPage,
  page,
  refetch,
  contactCardLoading,
  contactQueryLoading,
  totalCount,
  setLiftingContactLocationData,
  contactDataa,
}) => {
  const [contactData, setContactData] = useState<any[]>([]);
  const [deleteButtonClicked, setDeleteButtonClicked] = useState(false);
  const [storedData, setStoredData] = useState<any>(null);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [isEditCOntactDetailsEnabled, setIsEditContactDetailsEnabled] =
    useState(false);
    const [getContactData, setGetContactData] = useState<UserDetails[]>([])

 const callStatus =  localStorage.getItem(CALL)
 const mobileNumber =  localStorage.getItem("liftingMobileNumber");

 const socket = useWebSocket();
 const {setStoringContactData} = useTelephony()




    const location = useLocation()
    const dataOfContact = location.state ;


    useEffect(() => {
      if (Array.isArray(dataOfContact)) {
        setGetContactData(dataOfContact);
      } else {
        console.error("error");
      }
    }, [dataOfContact]); 


    useEffect(() => {
      if (getContactData) {
        setLiftingContactLocationData(getContactData)

      }

    }, [getContactData, setLiftingContactLocationData])


  const [userId, setUserId] = useState({
    id: null,
    name: "",
    email: "",
    phone_number: "",
    account: {},
  });

  const navigate = useNavigate();
  const [trigger] = useDeleteContactsInfoDetailsMutation();

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const editHandler = (
    id: any,
    name: any,
    email: any,
    phone_number: any,
    account: any,
    whatsapp_number:any,
    twitter_id: any,
    facebook_id:any
  ) => {
    const userDetails = { id, name, email, phone_number, account, whatsapp_number, twitter_id, facebook_id };
    setUserId(userDetails);
    setStoringContactData(userDetails)

    localStorage.setItem("isEditContactDetailsEnable", JSON.stringify(false));
    navigate(`/account/info/${id}`, { state: userDetails });
  };

  const editContactEnable = (
    id: any,
    name: any,
    email: any,
    phone_number: any,
    account: any,
    whatsapp_number:any,
    facebook_id:any,
    twitter_id:any
  ) => {
    const userDetails = { id, name, email, phone_number, account , whatsapp_number,facebook_id, twitter_id };
    setStoringContactData(userDetails)

    // Set isEditContactDetailsEnabled to true and update local storage
    setIsEditContactDetailsEnabled(true);
    setUserId(userDetails);

    localStorage.setItem("isEditContactDetailsEnable", JSON.stringify(true));

    // Navigate to the contact details page
    navigate(`/account/info/${id}`, { state: userDetails });
  };

  console.log(userId, "dcdscscsdcdsc")

  const {telephonyContactLoading} = useTelephony()

  const deleteHandler = (row: any) => {
    setDeleteButtonClicked(true);
    setStoredData(row);
  };

  const closeDeleteModal = () => {
    setDeleteButtonClicked(false);
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const customModalStyles1: ReactModal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-40%, -50%)",
      width: "40%",
    },
  };

  const getRandomColor = () => {
    const colors = [
      "bg-primary",
      "bg-success",
      "bg-danger",
      "bg-warning",
      "bg-dark",
    ];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const getInitials = (name: string) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    if (nameParts.length === 1) return nameParts[0].charAt(0).toUpperCase();
    return (
      nameParts[0].charAt(0).toUpperCase() +
      nameParts[1].charAt(0).toUpperCase()
    );
  };

  return (
    <>
      <div className="row">
        <div className="col" style={{ padding: "5px" }}>
          {contactCardLoading || telephonyContactLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {!contactCardLoading && contactDataa?.length ? (
                <>
                  {contactDataa?.map((row: any, index: any) => (
                    <div
                      className="card mt-2 my-1 p-4"
                      key={index}
                      onClick={() =>
                        editHandler(
                          row?.id,
                          row?.name,
                          row?.email,
                          row?.phone_number,
                          row?.account,
                          row?.whatsapp_number,
                          row?.twitter_id,
                          row?.facebook_id

                        )
                      }
                    >

                      <div className="row align-items-start">
                        <div className="col text-start">
                          <div className="symbol symbol-25px symbol-circle fs-2 me-3">
                            <span
                              className={`symbol-label ${getRandomColor()} text-inverse-warning fw-bold`}
                            >
                              {getInitials(row?.name)}
                            </span>
                          </div>
                          <label htmlFor="">
                            {capitalizeFirstLetter(row?.name)}
                          </label>
                        </div>

                        <div className="col text-start">
                          <label htmlFor="">{row.email}</label>
                        </div>

                        <div className="col text-start">
                          <label htmlFor="">{formatDate(row.created)}</label>
                        </div>

                        <div className="col text-start">
                          <label htmlFor="">
                            {row?.last_interaction_date
                              ? formatDate(row?.last_interaction_date)
                              : ""}
                          </label>
                        </div>

                        <div className="col text-start">
                          <label htmlFor="">
                            {Array.isArray(row?.tags)
                              ? row?.tags
                                  .map((tag) =>
                                    capitalizeFirstLetter(tag.name || "No Tag")
                                  )
                                  .join(", ")
                              : "No Tags"}
                          </label>
                        </div>

                        <div className="col text-start">
                          <label htmlFor="">
                            {row?.account?.name || "Unassigned"}
                          </label>
                        </div>

                        <div
                          className="col text-center d-flex"
                          style={{ gap: "10px", justifyContent: "center" }}
                        >
                          {/* <button
                            onClick={() =>
                              editHandler(
                                row?.id,
                                row?.name,
                                row?.email,
                                row?.phone_number,
                                row?.account
                              )
                            }
                            className="text-hover-primary border-0 bg-white me-2"
                          >
                            <i
                              data-placement="top"
                              title="View"
                              className="bi bi-eye text-dark fs-4"
                            ></i>
                          </button> */}
                          <button
                            className="text-hover-primary border-0 me-4"
                            onClick={(event) => {
                              event.stopPropagation();
                              editContactEnable(
                                row?.id,
                                row?.name,
                                row?.email,
                                row?.phone_number,
                                row?.account,
                                row?.whatsapp_number,
                                row?.facebook_id,
                                row?.twitter_id
                              );
                              
                            }}
                            title={
                              !isEditCOntactDetailsEnabled ? "Edit" : "Disable"
                            }
                            style={{ background: "transparent" }}
                            >
                            {console.log(row, "Dscscscsc")}
                            <i
                              data-placement="top"
                              title="Edit"
                              className="bi bi-pencil text-dark fs-4"
                              style={{
                                cursor: "pointer",
                                background: "transparent",
                              }}
                            ></i>
                          </button>

                          <button
                            className="text-hover-danger border-0 bg-white me-2"
                            onClick={(event) => {
                              event.stopPropagation();
                              deleteHandler(row);
                            }}
                          >
                            <i
                              data-placement="top"
                              title="Delete"
                              className="bi bi-trash text-dark fs-4"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <EmptyRows value="" />
              )}
            </>
          )}
        </div>
      </div>

      {mobileNumber ? "" : 

      <Pagination
        sx={{
          // mt: 2,
          display: "flex",
          justifyContent: "end",
          color: "blue",
        }}
        count={Math.ceil(totalCount / rowsPerPage)}
        page={page}
        color="primary"
        onChange={handlePageChange}
        renderItem={(item) => {
          if (item.type === "page") {
            if (
              (page === 1 && item.page <= 3) ||
              (page === Math.ceil(totalCount / rowsPerPage) &&
                item.page >= Math.ceil(totalCount / rowsPerPage) - 2) ||
              (item.page >= page - 1 && item.page <= page + 1)
            ) {
              return <PaginationItem {...item} />;
            }
            return null;
          }
          return <PaginationItem {...item} />;
        }}
      />

      }

      <ReactModal
        isOpen={deleteButtonClicked}
        onRequestClose={closeDeleteModal}
        style={customModalStyles1}
        contentLabel="Delete Contact"
      >
        <DeleteContactModal
          closeModal={closeDeleteModal}
          setFieldsData={setContactData}
          fieldsData={contactData}
          storingFieldData={storedData}
          refetch={refetch}
          trigger={trigger}
        />
      </ReactModal>
    </>
  );
};

export default ContactCards;
