import { useGetAuditTrailQuery } from "../../../Services/ticket/Audit-Trail";
import { capitalizeAndFormat, capitalizeFirstLetter } from "../../reusableComponent/CapitalLetter";
import { formatDate } from "../../reusableComponent/DateFormat";
import EmptyRows from "../../reusableComponent/EmptyRows";

interface IProps {
    data: any;
    auditTrailData: any;
}

const ChildTickets = ({ data, auditTrailData }: IProps) => {
    const { data: TrailData } = useGetAuditTrailQuery({
        id: auditTrailData?.[0]?.id,
        activity: auditTrailData?.[0]?.ticket_activity,
    });


    console.log(data?.
        child_tickets
    )

    const truncateDescription = (description, maxLength) => {
        if (description?.length > maxLength) {
            return description?.slice(0, maxLength) + "...";
        }
        return description;
    };
    const navigateTo = (childTicket) => {
        if (childTicket?.id) {
            const currentPath = window.location.pathname; // Get the current path
            const basePath = currentPath.substring(0, currentPath.lastIndexOf('/')); // Get everything before the last '/'
            const newUrl = `${basePath}/${childTicket.id}`; // Append the new ID dynamically
            window.location.href = newUrl; // Navigate to the new URL
        }
    };

    return (
        <div className="table-responsive">
            <table className="table table-row-bordered text-center">
                <thead className="table-light fw-bolder">
                    <tr className="bg-primary">
                        <th scope="col">Child ticket ID</th>
                        <th scope="col">Assignee</th>
                        <th scope="col">Status</th>
                        <th scope="col">Customer name</th>
                        <th scope="col">Raised by</th>
                    </tr>
                </thead>
                <tbody className="r">
                    {data?.child_tickets?.length > 0 && (
                        data?.child_tickets?.map((childTicket, index) => (
                            <tr key={index} className="cursor-pointer" onClick={() => navigateTo(childTicket)}   >
                                <td>{childTicket?.id}</td>

                                <td>

                                    {capitalizeAndFormat(
                                        childTicket?.action?.assignee?.username.split("@")[0] ??
                                        "Unassigned"
                                    )}
                                </td>
                                <td>   {truncateDescription(childTicket?.status_display, 25)}</td>
                                <td>  {capitalizeFirstLetter(childTicket?.action?.contact?.name)}</td>
                                <td> {childTicket?.created_by?.username}</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default ChildTickets;
