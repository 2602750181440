import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const GetTemplate = createApi({
  reducerPath: "getTemplates",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    getTemplates: builder.query({
      query: () => ({
        url: `v1/emails/templates/team/?page=1&team_id=1&`,
        method: "GET",
        // body
        
      }),
    }),
  }),
});

export const { useGetTemplatesQuery } = GetTemplate;
