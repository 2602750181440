import React, { useState } from "react";
import { useDeactivateAccountMutation } from "../../Services/user/Users";
import { toast } from "react-toastify";

interface IProps {
  closeAccountDeactivateModal: any;
  user: any;
  refetch: any;
}

const AccountDeactivateModal = ({
  closeAccountDeactivateModal,
  user,
  refetch,
}: IProps) => {
  const [deactivateUser] = useDeactivateAccountMutation(user.id);

  const handleDeactivate = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const response = await deactivateUser({
        id: user.id,
        user_status: "disable",
      });

      if (response.data) {
        closeAccountDeactivateModal();
        toast.success("User account deactivated successfully!");
        refetch();
      } else {
        toast.error("Failed to deactivate user account");
      }
    } catch (error) {
      toast.error("Failed to deactivate user account. Please try again.");
      console.error(error);
    }
    closeAccountDeactivateModal();
  };

  return (
    <div className="card-body">
      <p className="fs-5 fw-bold custom-paragraph">
        Are you sure you want to deactivate this user,{" "}
        <span className="text-primary">{user.username}</span>? If deactivated
        today, this user can access the application only after 7 days.
      </p>

      <div className="d-flex justify-content-end mt-10">
        <button
          type="reset"
          className="btn btn-sm btn-white btn-active-light-primary me-2"
          onClick={() => closeAccountDeactivateModal()}
        >
          No Thanks
        </button>

        <button
          type="submit"
          className="btn btn-sm btn-danger"
          onClick={handleDeactivate}
        >
          <span className="indicator-label">Yes</span>
        </button>
      </div>
    </div>
  );
};

export default AccountDeactivateModal;
