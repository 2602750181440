export const maskEmail = (email) => {
    if (typeof email !== "string") return ""; // Ensure email is a string
    const [localPart, domain] = email.split("@");
    if (localPart && domain) {
        const maskedLocal = localPart[0] + "****";
        const domainStart = domain.slice(0, domain.lastIndexOf(".") - 2).replace(/./g, "*");
        const domainExtension = domain.slice(domain.lastIndexOf(".") - 2); // Ensure the correct domain extension
        return `${maskedLocal}@${domainStart}${domainExtension}`;
    }
    return email; // Return the input as is if invalid
};

export const maskPhoneNumber = (phoneNumber) => {
    if (typeof phoneNumber !== "string" || phoneNumber.length <= 4) return phoneNumber; // Ensure phoneNumber is a string and valid
    return phoneNumber.slice(0, -4).replace(/./g, "*") + phoneNumber.slice(-4);
};


