import { Route, Routes, Outlet } from "react-router-dom";
import TodaysTask from "./TodaysTask";
import RecentTasks from "./RecentTasks";

const ActivityRoutes = () => {
  return (
    <div
      id="kt_app_content_container"
      className="app-container mx-5"
      style={{ overflow: "hidden" }}
    >
      <div className="card card-flush">
        <div className="card-body d-flex flex-column">
          <Routes>
            <Route element={<Outlet />}>
              <Route path="active" element={<TodaysTask />} />
              <Route path="recent" element={<RecentTasks />} />
            </Route>
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default ActivityRoutes;
