import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { LayoutProvider } from "../../layout/core";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../LoadingSpinner";
import "./Fixes.css";

const App: React.FC = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <LayoutProvider>
        <Outlet />
      </LayoutProvider>
    </Suspense>
  );
};

export default App;
