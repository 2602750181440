import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const emailInbox = createApi({
  reducerPath: "emailInbox",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    allThreads: builder.query({
      query: ({
        currentPage,
        perPage,
        selectedTeamId,
        selectedFilter1,
        status,
      }) => {
        let baseUrl = `v1/emails/threads/?page=${currentPage}&status__contains=${status}&ordering=-updated&page_size=${perPage}`;
        baseUrl +=
          selectedTeamId === "My Team"
            ? ""
            : `&action__assignee=${selectedTeamId}`;

        // Add filter parameters only if they have values
        const filterParams = Object.entries(selectedFilter1)
          .filter(([key, value]) => value !== "")
          .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`)
          .join("&");

        return filterParams ? `${baseUrl}&${filterParams}` : baseUrl;
      },
    }),

    getAllTelephonyThreads: builder.query({
      query: ({
        currentPage,
        perPage,
        selectedTeamId,
        selectedFilter1,
        status,
        mobileNumber
      }) => {
        let baseUrl = `v1/emails/threads/?page=${currentPage}&ordering=updated&page_size=${perPage}&action__contact__phone_number__exact=${mobileNumber}&get_thread_for=telephony&`;
        baseUrl +=
          selectedTeamId === "My Team"
            ? ""
            : `&action__assignee=${selectedTeamId}`;

        // Add filter parameters only if they have values
        const filterParams = Object.entries(selectedFilter1)
          .filter(([key, value]) => value !== "")
          .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`)
          .join("&");

        return filterParams ? `${baseUrl}&${filterParams}` : baseUrl;
      },
    }),

    allThreadsSent: builder.query({
      query: ({
        currentPage,
        perPage,
        selectedTeamId,
        sentSelectedFilter1,
        status,
      }) => {
        let baseUrl = `v1/emails/threads/?page=${currentPage}&status__contains=${status}&ordering=-updated&page_size=${perPage}`;
        baseUrl +=
          selectedTeamId === "My Team"
            ? ""
            : `&action__assignee=${selectedTeamId}`;

        // Add filter parameters only if they have values
        const filterParams = Object.entries(sentSelectedFilter1)
          .filter(([key, value]) => value !== "")
          .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`)
          .join("&");

        return filterParams ? `${baseUrl}&${filterParams}` : baseUrl;
      },
    }),

    allThreadsStarred: builder.query({
      query: ({
        currentPage,
        perPage,
        selectedTeamId,
        selectStaredFilterAndValue1,
        status,
      }) => {
        let baseUrl = `v1/emails/threads/?page=${currentPage}&ordering=-updated&page_size=${perPage}&is_starred=true`;
        baseUrl +=
          selectedTeamId === "My Team"
            ? ""
            : `&action__assignee=${selectedTeamId}`;

        // Add filter parameters only if they have values
        const filterParams = Object.entries(selectStaredFilterAndValue1)
          .filter(([key, value]) => value !== "")
          .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`)
          .join("&");

        return filterParams ? `${baseUrl}&${filterParams}` : baseUrl;
      },
    }),

    specificThreads: builder.query({
      query: (id) => ({
        url: `v1/emails/threads/${id}/`,
        method: "GET",
      }),
    }),

    reply: builder.query({
      query: ({ mailId, lastId }) => ({
        url: `v1/emails/reply-email-list/${mailId}/${lastId}`,
        method: "GET",
      }),
    }),
    getTeamFrom: builder.query({
      query: (userId) => {
        return {
          url: `v1/tickets/ticket-access-control-get/?team_from=${userId.teamId}&`,
          method: "GET",
        };
      },
    }),
    getSelectedTeamUser: builder.query({
      query: (teamId) => {
        return {
          url: `v1/users/users/?teams__id=${teamId}&`,
          method: "GET",
        };
      },
    }),

    addUserName: builder.mutation({
      query: ({ body, setectedEmailId }) => {
        return {
          url: `v1/emails/threads/${setectedEmailId}/`,
          method: "PATCH",
          body,
        };
      },
    }),

    getDisposition: builder.query({
      query: (teamId) => {
        return {
          url: `v1/common/disposition/?team_id=${teamId}&`,
          method: "GET",
        };
      },
    }),

    ticketDynamicFields: builder.query({
      query: () => ({
        url: "v1/common/dynamic-field-definitions/?model_name=ticket_name&",
        method: "GET",
      }),
    }),

    createTicketByMailReply: builder.mutation({
      query: ({ body, selectedTicketId }) => {
        return {
          url: `v1/tickets/ticket/${selectedTicketId}/`,
          method: "PATCH",
          body,
        };
      },
    }),

    replyMail: builder.mutation({
      query: ({ body, selectedEmailId }) => {
        return {
          url: `/v1/emails/threads/${selectedEmailId}/reply/`,
          method: "PUT",
          body,
        };
      },
    }),

    bulkMailAssigne: builder.mutation({
      query: ({ body, setectedEmailId }) => {
        return {
          url: `/v1/emails/bulk-assignment/`,
          method: "POST",
          body,
        };
      },
    }),

    mailHistory: builder.query({
      query: (mailId) => {
        return {
          url: `v1/customers/actions/?contact=${mailId}&ordering=-created`,
          method: "GET",
        };
      },
    }),

    updateDisposition: builder.mutation({
      query: ({ body, id }) => ({
        url: `v1/emails/threads/${id}/`,
        method: "PATCH",
        body,
      }),
    }),

    updateStarred: builder.mutation({
      query: ({ body, id }) => ({
        url: `v1/emails/threads/${id}/`,
        method: "PATCH",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllTelephonyThreadsQuery,
  useUpdateDispositionMutation,
  useUpdateStarredMutation,
  useAllThreadsStarredQuery,
  useAllThreadsSentQuery,
  useReplyQuery,
  useSpecificThreadsQuery,
  useAllThreadsQuery,
  useGetTeamFromQuery,
  useGetSelectedTeamUserQuery,
  useAddUserNameMutation,
  useGetDispositionQuery,
  useTicketDynamicFieldsQuery,
  useCreateTicketByMailReplyMutation,
  useReplyMailMutation,
  useBulkMailAssigneMutation,
  useMailHistoryQuery,
} = emailInbox;
