import React from 'react'

const ChatSideMenu = () => {
    return (
        <>
            <div className="card p-1">
                <div className="card-title d-flex justify-content-center">
                    {/* <p className="text-center card-subtitle mb-2 text-body-secondary">KM</p> */}
                    <button className="btn btn-link"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#multiCollapseExample2"
                        aria-expanded="false"
                        aria-controls="multiCollapseExample2">KM</button>
                </div>
                <div className="collapse multi-collapse" id="multiCollapseExample2">
                    <div className="card px-2 my-1">
                        <p className="my-4">First Conversation</p>
                    </div>
                    <div className="card px-2 my-1">
                        <p className="my-4">Testing</p>
                    </div>
                    <div className="card px-2 my-1">
                        <p className="my-4">Photo</p>
                    </div>
                    <div className="card px-2 my-1">
                        <p className="my-4">Lead Status</p>
                    </div>
                    <div className="card px-2 my-1">
                        <p className="my-8"></p>
                    </div>
                </div>
            </div>
            <div className="card p-2 my-4">
                <div className="flex-column">
                    {/*begin::Contacts*/}
                    <div>
                        {/*begin::Card header*/}
                        <div id="kt_chat_contacts_header">
                            {/*begin::Form*/}
                            <form className="w-100 position-relative">
                                {/*begin::Icon*/}
                                <i className="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 ms-1 translate-middle-y">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                                {/*end::Icon*/}

                                {/*<!-- Start Search Bar -->*/}
                                <div className="search-bar">
                                    <form className="search-form d-flex align-items-center" method="POST" action="#">
                                        <input type="text" className="form-control form-control-solid" name="query" placeholder="Search people" title="Enter search keyword" />
                                        <button type="submit" title="Search"><i className="bi bi-search"></i></button>
                                    </form>
                                </div>
                                {/*<!-- End Search Bar -->*/}

                                <div className="d-flex justify-content-around py-4">
                                    {/*begin::Details*/}
                                    <div className="d-flex align-items-center">
                                        {/*begin::Label*/}
                                        <label>Our Team</label>
                                        {/*end::Label*/}
                                    </div>
                                    {/*end::Details*/}

                                    {/*begin::actionbuttons*/}
                                    <div className="d-flex flex-column align-items-end ms-2">
                                        <div className="d-flex">
                                            <span className="text-muted fs-7 mb-1 px-5"><i className="bi bi-person-add"></i></span>
                                            <span className="text-muted fs-7 mb-1"><i className="bi bi-caret-down-square"></i></span>
                                        </div>
                                    </div>
                                    {/*end::actionbuttons*/}
                                </div>
                            </form>
                            {/*end::Form*/}
                        </div>
                        {/*end::Card header*/}

                        {/*begin::Card body*/}
                        <div id="kt_chat_contacts_body">
                            {/*begin::List*/}
                            <div className="scroll-y h-200px h-lg-auto"
                                data-kt-scroll="true"
                                data-kt-scroll-activate="{default: false, lg: true}"
                                data-kt-scroll-max-height="auto"
                                data-kt-scroll-dependencies="#kt_header, #kt_app_header, #kt_toolbar, #kt_app_toolbar, #kt_footer, #kt_app_footer, #kt_chat_contacts_header"
                                data-kt-scroll-wrappers="#kt_content, #kt_app_content, #kt_chat_contacts_body"
                                data-kt-scroll-offset="5px" style={{ maxHeight: '300px' }}>
                                {/*begin::User*/}
                                <div className="d-flex flex-stack py-2 px-1 border">
                                    {/*begin::Details*/}
                                    <div className="d-flex align-items-center">
                                        {/*begin::Avatar*/}
                                        <div className="symbol  ps-1 symbol-30px symbol-circle ">
                                            <img alt="Pic" src="/media/profilepics/image62x.png" />
                                            <div className="symbol-badge bg-success start-100 top-100 border-4 h-8px w-8px ms-n2 mt-n2"></div>
                                        </div>
                                        {/*end::Avatar*/}
                                        {/*begin::Details*/}
                                        <div className="ms-1">
                                            <a href="#" className="fs-7 text-gray-900 text-hover-primary mb-2">Lukas</a>
                                            <div className="fw-semibold text-muted fs-8">Was machst du?</div>
                                        </div>
                                        {/*end::Details*/}
                                    </div>
                                    {/*end::Details*/}

                                    {/*begin::Last seen*/}
                                    <div className="d-flex flex-column align-items-end ms-2">
                                        <div className="d-flex">
                                            <span className="text-muted fs-7 mb-1 px-1"><i className="bi bi-mic-fill pe-1"></i></span>
                                            <span className="text-muted fs-7 mb-1"><i className="bi bi-x-circle-fill pe-2"></i></span>
                                        </div>
                                    </div>
                                    {/*end::Last seen*/}
                                </div>
                                {/*end::User*/}
                                {/*begin::Separator*/}
                                <div className="separator separator-dashed d-none"></div>
                                {/*end::Separator*/}

                                {/*begin::User*/}
                                <div className="d-flex flex-stack py-4 border">
                                    {/*begin::Details*/}
                                    <div className="d-flex align-items-center">
                                        {/*begin::Avatar*/}
                                        <div className="symbol  ps-1 symbol-30px symbol-circle ">
                                            <span className="symbol-label  bg-light-danger text-danger fs-6 fw-bolder ">R</span>
                                        </div>
                                        {/*end::Avatar*/}
                                        {/*begin::Details*/}
                                        <div className="ms-1">
                                            <a href="#" className="fs-7 text-gray-900 text-hover-primary mb-2">Roy</a>
                                            <div className="fw-semibold text-muted fs-8">Was machst du?</div>
                                        </div>
                                        {/*end::Details*/}
                                    </div>
                                    {/*end::Details*/}

                                    {/*begin::Last seen*/}
                                    <div className="d-flex flex-column align-items-end ms-2">
                                        <div className="d-flex">
                                            <span className="text-muted fs-7 mb-1 px-1"><i className="bi bi-mic-fill pe-1"></i></span>
                                            <span className="text-muted fs-7 mb-1"><i className="bi bi-x-circle-fill pe-3"></i></span>
                                        </div>
                                    </div>
                                    {/*end::Last seen*/}
                                </div>
                                {/*end::User*/}
                                {/*begin::Separator*/}
                                <div className="separator separator-dashed d-none"></div>
                                {/*end::Separator*/}

                                {/*begin::User*/}
                                <div className="d-flex flex-stack py-4 border">
                                    {/*begin::Details*/}
                                    <div className="d-flex align-items-center">
                                        {/*begin::Avatar*/}
                                        <div className="symbol  ps-1 symbol-30px symbol-circle ">
                                            <span className="symbol-label  bg-light-danger text-danger fs-6 fw-bolder ">BB</span>
                                        </div>
                                        {/*end::Avatar*/}
                                        {/*begin::Details*/}
                                        <div className="ms-1">
                                            <a href="#" className="fs-7 text-gray-900 text-hover-primary mb-2">Beuger</a>
                                            <div className="fw-semibold text-muted fs-8">Was machst du?</div>
                                        </div>
                                        {/*end::Details*/}
                                    </div>
                                    {/*end::Details*/}

                                    {/*begin::Last seen*/}
                                    <div className="d-flex flex-column align-items-end ms-2">
                                        <div className="d-flex">
                                            <span className="text-muted fs-7 mb-1 px-1"><i className="bi bi-mic-fill pe-1"></i></span>
                                            <span className="text-muted fs-7 mb-1"><i className="bi bi-x-circle-fill pe-3"></i></span>
                                        </div>
                                    </div>
                                    {/*end::Last seen*/}
                                </div>
                                {/*end::User*/}
                                {/*begin::Separator*/}
                                <div className="separator separator-dashed d-none"></div>
                                {/*end::Separator*/}


                                {/*begin::User*/}
                                <div className="d-flex flex-stack py-4 border">
                                    {/*begin::Details*/}
                                    <div className="d-flex align-items-center">
                                        {/*begin::Avatar*/}
                                        <div className="symbol ps-1 symbol-30px symbol-circle ">
                                            <span className="symbol-label  bg-light-danger text-danger fs-6 fw-bolder ">R</span>
                                        </div>
                                        {/*end::Avatar*/}
                                        {/*begin::Details*/}
                                        <div className="ms-1">
                                            <a href="#" className="fs-7 text-gray-900 text-hover-primary mb-2">Raj</a>
                                            <div className="fw-semibold text-muted fs-8">Was machst du?</div>
                                        </div>
                                        {/*end::Details*/}
                                    </div>
                                    {/*end::Details*/}

                                    {/*begin::Last seen*/}
                                    <div className="d-flex flex-column align-items-end ms-2">
                                        <div className="d-flex">
                                            <span className="text-muted fs-7 mb-1 px-1"><i className="bi bi-mic-fill pe-1"></i></span>
                                            <span className="text-muted fs-7 mb-1"><i className="bi bi-x-circle-fill pe-3"></i></span>
                                        </div>
                                    </div>
                                    {/*end::Last seen*/}
                                </div>
                                {/*end::User*/}
                                {/*begin::Separator*/}
                                <div className="separator separator-dashed d-none"></div>
                                {/*end::Separator*/}


                                {/*begin::User*/}
                                <div className="d-flex flex-stack py-4 border">
                                    {/*begin::Details*/}
                                    <div className="d-flex align-items-center">
                                        {/*begin::Avatar*/}
                                        <div className="symbol  ps-1 symbol-30px symbol-circle ">
                                            <span className="symbol-label  bg-light-danger text-danger fs-6 fw-bolder ">P</span>
                                        </div>
                                        {/*end::Avatar*/}
                                        {/*begin::Details*/}
                                        <div className="ms-1">
                                            <a href="#" className="fs-7 text-gray-900 text-hover-primary mb-2">Pratik</a>
                                            <div className="fw-semibold text-muted fs-8">Was machst du?</div>
                                        </div>
                                        {/*end::Details*/}
                                    </div>
                                    {/*end::Details*/}

                                    {/*begin::Last seen*/}
                                    <div className="d-flex flex-column align-items-end ms-2">
                                        <div className="d-flex">
                                            <span className="text-muted fs-7 mb-1 px-1"><i className="bi bi-mic-fill pe-1"></i></span>
                                            <span className="text-muted fs-7 mb-1"><i className="bi bi-x-circle-fill pe-3"></i></span>
                                        </div>
                                    </div>
                                    {/*end::Last seen*/}
                                </div>
                                {/*end::User*/}
                                {/*begin::Separator*/}
                                <div className="separator separator-dashed d-none"></div>
                                {/*end::Separator*/}
                            </div>
                            {/*end::List*/}
                            <div className="d-flex flex-row-reverse mt-4">
                                <p className="text-muted">view more</p>
                            </div>
                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Contacts*/}
                </div>
            </div>
        </>

    )
}

export default ChatSideMenu