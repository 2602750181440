import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import clsx from "clsx";
import { Loader } from "react-bootstrap-typeahead";

const initialValues = {
  email: "",
  password: "",
  changepassword: "",
};

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),

  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
    )
    .required("Password is required"),
  changepassword: Yup.string()
    .required("Password confirmation is required")
    .when("password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and Confirm Password didn't match"
      ),
    }),
});

const OtpPage = () => {
  const [remainingTime, setRemainingTime] = useState<any>(60);
  const [otp, setOtp] = useState<any>("");
  const [newPassword, setNewPassword] = useState<any>("");
  const [confirmPassword, setConfirmPassword] = useState<any>("");
  const [tenant, setTenant] = useState<any>("");
  const [userName, setUserName] = useState<any>("");
  const [error, setError] = useState<boolean>(false);
  const [storingEmail, setStoringEmail] = useState<any>("");
  const [storingTenant, setStoringTenant] = useState<any>("");
  const [optLoading, setOtpLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (remainingTime > 0) {
      const timer = setInterval(() => {
        setRemainingTime(remainingTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [remainingTime]);

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleNavigate = () => {
    navigate("/auth/forgot-password");
  };

  const handleVerifyOtp = async (e: any) => {
    e.preventDefault();
    if (confirmPassword !== newPassword) {
      setError(true);
      return;
    }
    const verifyEmailObj = {
      username: localStorage.getItem("email"),
      otp: otp,
      new_password: newPassword,
      tenant: localStorage.getItem("tenant"),
    };
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_BASE_URL}/v1/update-password/`,
        verifyEmailObj
      );
      if (response?.status === 200) {
        toast.success("Password updated successfully");
        navigate("/auth/login");
      } else {
        toast.error("An error occured");
      }
    } catch (error) {
      toast.error("An error occured");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();
    const forgotPassObj = {
      username: storingEmail,
      tenant: storingTenant,
    };
    setOtpLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_BASE_URL}/v1/forgot-password/`,
        forgotPassObj
      );
      if (response?.status === 200) {
        toast.success("OTP sent successfully");
      } else {
        toast.error("An error occured");
      }
    } catch (error) {
      toast.error("An error occured");
      setOtpLoading(false);
    } finally {
      setOtpLoading(false);
    }
  };

  useEffect(() => {
    setStoringEmail(localStorage.getItem("email"));
    setStoringTenant(localStorage.getItem("tenant"));
  }, []);

  return (
    <>
      <div
        className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        style={{
          backgroundImage: `url('/media/illustrations/sketchy-1/14.png')`,
        }}
      >
        <div
          className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"
          style={{ paddingBottom: "0px" }}
        >
          <div
            className="w-lg-550px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto"
            style={{ paddingBottom: "0" }}
          >
            <form className="form w-100" noValidate>
              <div className="text-center mb-10">
                <h1 className="text-dark mb-3">Reset Password</h1>
              </div>
              <div className="fv-row mb-10">
                <div className="d-flex justify-content-between mt-n5">
                  <div className="d-flex flex-stack mb-2">
                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                      OTP
                    </label>
                  </div>
                </div>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Enter your OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                />
              </div>



              <div className="fv-row mb-10">
                <div className="d-flex justify-content-between mt-n5">
                  <div className="d-flex flex-stack mb-2">
                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                      Password
                    </label>
                  </div>
                </div>
                <input
                  type="password"
                  placeholder="Enter your Password"
                  className="form-control form-control-lg form-control-solid"
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    setError(false);
                  }}
                />
              </div>

              <div className="fv-row mb-10">
                <div className="d-flex justify-content-between mt-n5">
                  <div className="d-flex flex-stack mb-2">
                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                      Confirm Password
                    </label>
                  </div>
                </div>
                <input
                  type="password"
                  placeholder="Confirm Your Password"
                  className="form-control form-control-lg form-control-solid"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setError(false);
                  }}
                />

                {error ? (
                  <span style={{ color: "red" }}>Password doesn't match</span>
                ) : (
                  ""
                )}
              </div>

              <div className="fv-row mb-10">
                <p className="text-gray-400 fw-bold fs-5 d-flex">
                  You can resend OTP after: &nbsp;
                  <span className="link-primary fw-bolder">
                    {formatTime(remainingTime)}
                  </span>
                </p>
                <br />
                <div className="d-flex justify-content-between mt-n5">
                  <div
                    className="d-flex flex-stack mb-2"
                    style={{ gap: "5px" }}
                  >
                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                      Didn't receive the OTP?
                    </label>
                    <span
                      onClick={handleResendOtp}
                      className="link-primary fs-6 fw-bolder"
                      style={{
                        color: remainingTime === 0 ? "#009ef7" : "grey",
                        cursor: remainingTime > 0 ? "not-allowed" : "pointer",
                      }}
                    >
                      {optLoading ? <Loader /> : "Resend OTP"}
                    </span>
                  </div>
                </div>
                {/* )} */}
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-lg btn-primary w-100 mb-5"
                  onClick={handleVerifyOtp}
                >
                  <span className="indicator-label">
                    {loading ? (
                      <>
                        Verifying... <Loader />
                      </>
                    ) : (
                      "Verify"
                    )}
                  </span>
                </button>
              </div>

              <button
                type="button"
                className="btn btn-lg btn-light-primary w-100 mb-5"
                onClick={handleNavigate}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
        <div className="d-flex flex-center flex-column-auto p-10">
          <div className="d-flex align-items-center fw-bold fs-6">
            <button className="btn primary page-link text-muted text-hover-primary px-2">
              About
            </button>
            <button className="btn primary page-link text-muted text-hover-primary px-2">
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpPage;
