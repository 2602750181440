import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { toast } from "react-toastify";

const initialValues = {
  email: "",
  password: "",
  changepassword: "",
};

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),

  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
    )
    .required("Password is required"),
  changepassword: Yup.string()
    .required("Password confirmation is required")
    .when("password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and Confirm Password didn't match"
      ),
    }),
});

const ChangePassword = () => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [loadingOL, setLoading] = useState(false);

  const navigate = useNavigate();

  const fetchingTenant = window.location.hostname.split(".")[0];
  const apiPort = process.env.REACT_APP_API_PROTOCOL;

  const handleSubmitForm = async (values) => {
    setLoading(true);
    await axios
      .put(
        `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/users/reset-password/${values.email}/`,
        {
          password: values.password,
        }
      )
      .then((res: any) => {
        setLoading(false);
        toast.success("Password Reset successfully");
        navigate("/auth/login");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.detail);
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: handleSubmitForm,
  });

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="mb-10 text-center">
        {/* begin::Title */}
        <h1 className="text-dark mb-3">Set Password</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-400 fw-bold fs-4">
          We are excited to have you
        </div>
        {/* end::Link */}
      </div>

      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">Email</label>
        <input
          placeholder="Email"
          type="email"
          autoComplete="off"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className="mb-10 fv-row">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark fs-6">
            Password
          </label>
          <div className="position-relative mb-3 d-flex">
            <input
              type={showNewPassword ? "text" : "password"}
              placeholder="Password"
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
              style={{
                borderRadius: "5px 0px 0px 5px", // Adjust the border radius as needed
              }}
            />
            <div className="input-group-append">
              <span
                className="form-control form-control-lg form-control-solid"
                id="togglePassword"
                onClick={toggleNewPasswordVisibility}
                style={{
                  cursor: "pointer",
                  borderRadius: "0px 5px 5px 0px", // Adjust the border radius as needed
                }}
              >
                {showNewPassword ? (
                  <i className="bi bi-eye-slash text-dark"></i>
                ) : (
                  <i className="bi bi-eye text-dark"></i>
                )}
              </span>
            </div>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className="fv-row mb-5">
        <label className="form-label fw-bolder text-dark fs-6">
          Confirm Password
        </label>
        <div className="position-relative mb-3 d-flex">
          <input
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Password confirmation"
            autoComplete="off"
            {...formik.getFieldProps("changepassword")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid":
                  formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                "is-valid":
                  formik.touched.changepassword &&
                  !formik.errors.changepassword,
              }
            )}
            style={{
              borderRadius: "5px 0px 0px 5px", // Adjust the border radius as needed
            }}
          />
          <div className="input-group-append">
            <span
              className="form-control form-control-lg form-control-solid"
              id="togglePassword"
              onClick={toggleConfirmPasswordVisibility}
              style={{
                cursor: "pointer",
                borderRadius: "0px 5px 5px 0px", // Adjust the border radius as needed
              }}
            >
              {showConfirmPassword ? (
                <i className="bi bi-eye-slash text-dark"></i>
              ) : (
                <i className="bi bi-eye text-dark"></i>
              )}
            </span>
          </div>
        </div>
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}

      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loadingOL && <span className="indicator-label">Submit</span>}
          {loadingOL && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_signup_form_cancel_button"
            className="btn btn-lg btn-light-primary w-100 mb-5"
          >
            Cancel
          </button>
        </Link>
      </div>
    </form>
  );
};

export default ChangePassword;
