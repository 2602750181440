import React, { useEffect, useState } from "react";
import { IconButton, Avatar, Box, Typography, Paper } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faPencil } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@mui/material";
import { isFieldDisabled } from "../../components/reusableComponent/FieldDisabled";
import {
  useMeeQuery,
  useUsersDynamicFieldsQuery,
} from "../../Services/settingsAPIs/CommonAPI";
import { PhoneInput } from "react-international-phone";
import { toast } from "react-toastify";
import { useUpdateUserMutation } from "../../Services/user/Users";
import { capitalizeFirstLetter } from "../../components/reusableComponent/CapitalLetter";

interface ErrorState {
  [key: string]: string;
}

const MyProfile: React.FC = () => {
  const { data: userData, refetch } = useMeeQuery({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [isEnabled, setIsEnabled] = useState(false);
  const [dynamicFields, setDynamicFields] = useState({});
  const [dynamicData, setDynamicData] = useState<any[]>([]);
  const { data: UsersDynamicFields } = useUsersDynamicFieldsQuery({});
  const [errors, setErrors] = useState({});
  const [theTeams, setTheTeams] = useState<any[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const isAdmin = localStorage.getItem("user_role");
  const [initialDynamicFields, setInitialDynamicFields] = useState({});
  const [updateUser] = useUpdateUserMutation(userData);

  const handleInputChange = (key: string, value: any) => {
    setDynamicFields((prevFields) => ({
      ...prevFields,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (UsersDynamicFields) {
      setDynamicData(UsersDynamicFields);
    }
  }, [UsersDynamicFields]);

  useEffect(() => {
    if (userData?.dynamic_fields) {
      setDynamicFields(userData.dynamic_fields);
      setInitialDynamicFields(userData.dynamic_fields); //For Comparison
    }
  }, [userData?.dynamic_fields]);

  useEffect(() => {
    if (
      firstName !== userData?.first_name ||
      lastName !== userData?.last_name
    ) {
      setIsButtonDisabled(false);
    }
  }, [firstName, lastName]);

  useEffect(() => {
    if (userData) {
      setTheTeams(userData.teams || []);
      setDynamicFields(userData?.dynamic_fields || {});
      setFirstName(userData?.first_name || "");
      setLastName(userData?.last_name || "");
      setProfileImage(userData?.profileImage || null);
      setIsLoading(false);
    }
  }, [userData]);

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files ? event.target.files[0] : null;
  //   if (file) {
  //     setProfileImage(URL.createObjectURL(file));
  //   }
  // };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const editDispositionHandler = () => {
    setIsEnabled(!isEnabled);
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<{ value: any }>,
    key: string
  ) => {
    setErrors({});
    setDynamicFields((prevFields) => ({
      ...prevFields,
      [key]: event.target.value,
    }));
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 120,
      },
    },
  };

  const validateForm = () => {
    const newErrors: ErrorState = {};

    dynamicData.forEach((field: any) => {
      const fieldValue = dynamicFields[field.label];

      if (field.is_required && !fieldValue) {
        newErrors[field.label] = `${field.label} is required`;
      }

      if (field.is_required && field.field_type === "tel-phone" && fieldValue) {
        const isValidPhone =
          /^(?:\+?\d{1,3})?[ -]?\(?\d{1,4}?\)?[ -]?\d{1,4}[ -]?\d{1,4}(?: x\d{1,4})?$/.test(
            fieldValue
          );
        if (!isValidPhone) {
          newErrors[
            field.label
          ] = `${field.label} must be a valid phone number`;
        }
      }

      if (
        field?.field_type === "email" &&
        field.is_required &&
        !field.is_masked
      ) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(dynamicFields[field.label])) {
          newErrors[field.label] = "Invalid email format";
        }
      }

      if (
        field.field_type === "check-box" &&
        field.is_required &&
        !fieldValue.length
      ) {
        newErrors[field.label] = `${field.label} is required`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill all required fields.");
      setIsButtonDisabled(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
      return;
    }

    setIsButtonDisabled(false);

    const dynamicFieldData = Object.keys(dynamicFields).reduce((acc, key) => {
      const field = dynamicData.find((field) => field.label === key);

      const isFieldEditableForRole = isAdmin === "admin" || field?.is_editable;

      // Get initial unmasked field value and current unmasked field value
      const initialFieldValue = initialDynamicFields[key]; // Unmasked initial value
      const currentFieldValue = dynamicFields[key]; // Current value (unmasked on focus)

      // Only include the field if it's editable and has been changed (unmasked values are compared)
      if (isFieldEditableForRole && currentFieldValue !== initialFieldValue) {
        // Handle date formatting
        if (field?.field_type === "date") {
          const formattedDate = new Date(currentFieldValue)
            .toISOString()
            .replace("Z", "+00:00");
          acc[key] = formattedDate;
        } else {
          acc[key] = currentFieldValue; // Store the unmasked value
        }
      }

      return acc;
    }, {});

    try {
      const obj = {
        id: userData.id,
        teams: theTeams.map((team) => team.id),
        dynamic_fields: dynamicFieldData, // Only edited fields included
        module_permissions: [],
        role: userData.role,
        first_name: firstName,
        last_name: lastName,
        avatar: profileImage,
      };

      const response = await updateUser({ body: obj, id: userData.id });

      if (response?.data) {
        toast.success("Profile updated successfully!");
        refetch();
      } else if (response?.error) {
        const error = response.error;
        toast.error(error);
      }
    } catch (error) {
      toast.error("An unexpected error occurred");
    } finally {
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
    }
  };

  const getDataTimeFormatted = (date) => {
    if (date && date.length > 0) {
      const dateObj = new Date(date);

      // Manually format the date to ensure no timezone conversion issues
      const year = dateObj.getUTCFullYear();
      const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(dateObj.getUTCDate()).padStart(2, "0");
      const hours = String(dateObj.getUTCHours()).padStart(2, "0");
      const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
      return formattedDate;
    } else {
      return "";
    }
  };

  const filterNumericInput = (value) => {
    return value.replace(/[^0-9.]/g, "");
  };

  const handleMask = (label) => {
    const value = dynamicFields[label];
    if (value) {
      // const maskedValue = value.replace(/^(\d{3})(\d+)/, "$1*****");
      setDynamicFields((prev) => ({
        ...prev,
        [label]: value,
      }));
    }
  };

  const handleUnmask = (label) => {
    const value = initialDynamicFields[label] || dynamicFields[label];
    if (value) {
      setDynamicFields((prev) => ({
        ...prev,
        [label]: value,
      }));
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Box display="flex" flexDirection="column" gap={2} p={2}>
          <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={4}
            >
              <Typography variant="h5" fontWeight="bold">
                Profile Details
              </Typography>
              <IconButton
                onClick={editDispositionHandler}
                title={isEnabled ? "Disperse" : "Edit"}
              >
                <FontAwesomeIcon
                  icon={faPencil}
                  color={isEnabled ? "#0d6efd" : "#6c757d"}
                />
              </IconButton>
            </Box>

            <Box display="flex" justifyContent="center" mb={2}>
              <Box position="relative">
                <Avatar
                  src={profileImage || userData?.avatar}
                  sx={{
                    width: 150,
                    height: 150,
                    border: "4px solid #F8F8F8",
                    boxShadow: 2,
                  }}
                />
                <label htmlFor="imageUpload">
                  <IconButton
                    component="span"
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      bgcolor: "white",
                    }}
                    onClick={() => {
                      if (!isEnabled) {
                        toast.error("Please enable edit mode first.");
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faCamera} />
                  </IconButton>
                </label>
                <input
                  type="file"
                  id="imageUpload"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  disabled={!isEnabled}
                />
              </Box>
            </Box>

            {/* Profile Fields */}
            <div className="row mb-6">
              <div className="col-xl-4 mb-3">
                <InputLabel className="required">First Name</InputLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  InputLabelProps={{ required: true }}
                  disabled={!isEnabled}
                />
              </div>

              <div className="col-xl-4 mb-3">
                <InputLabel className="required">Last Name</InputLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  InputLabelProps={{ required: true }}
                  disabled={!isEnabled}
                />
              </div>

              <div className="col-xl-4 mb-3">
                <InputLabel className="required">Username/Email</InputLabel>
                <TextField
                  type="email"
                  variant="outlined"
                  fullWidth
                  value={userData?.username}
                  disabled
                  InputLabelProps={{ required: true }}
                />
              </div>

              {isEnabled && (
                <>
                  {dynamicData?.map((field: any) => (
                    <React.Fragment key={field?.label}>
                      <div key={field.label} className="col-xl-4 mb-3">
                        <InputLabel htmlFor={`dynamic_fields.${field.label}`}>
                          {field.label}
                          {field.is_required && (
                            <span className="required"></span>
                          )}
                        </InputLabel>
                        {field.field_type === "text" && (
                          <TextField
                            type="text"
                            id={`dynamic_fields.${field.label}`}
                            variant="outlined"
                            disabled={isFieldDisabled(field?.is_editable)}
                            fullWidth
                            value={dynamicFields[field.label] || ""}
                            onChange={(e) =>
                              handleInputChange(field.label, e.target.value)
                            }
                            required={field.is_required}
                          />
                        )}
                        {field.field_type === "email" && (
                          <TextField
                            type="email"
                            id={`dynamic_fields.${field.label}`}
                            disabled={isFieldDisabled(field?.is_editable)}
                            variant="outlined"
                            fullWidth
                            value={dynamicFields[field.label] || ""}
                            onChange={(e) =>
                              handleInputChange(field.label, e.target.value)
                            }
                            required={field.is_required}
                          />
                        )}
                        {field.field_type === "number" && (
                          <TextField
                            type="text" // Allow masked value display
                            id={`dynamic_fields.${field.label}`}
                            variant="outlined"
                            fullWidth
                            disabled={isFieldDisabled(field?.is_editable)}
                            value={dynamicFields[field.label] || ""} // Masked value here
                            onChange={
                              (e) =>
                                handleInputChange(
                                  field.label,
                                  filterNumericInput(e.target.value)
                                ) // Only allow numeric input
                            }
                            onFocus={(e) => handleUnmask(field.label)} // Unmask full number on focus
                            onBlur={(e) => handleMask(field.label)} // Mask the number on blur
                            required={field.is_required}
                          />
                        )}
                        {field.field_type === "date" && (
                          <TextField
                            type="date"
                            id={`dynamic_fields.${field.label}`}
                            variant="outlined"
                            disabled={isFieldDisabled(field?.is_editable)}
                            fullWidth
                            value={dynamicFields[field.label] || ""}
                            onChange={(e) =>
                              handleInputChange(field.label, e.target.value)
                            }
                            InputLabelProps={{ shrink: true }}
                            required={field.is_required}
                          />
                        )}
                        {field.field_type === "time" && (
                          <TextField
                            type="time"
                            id={`dynamic_fields.${field.label}`}
                            variant="outlined"
                            fullWidth
                            disabled={isFieldDisabled(field?.is_editable)}
                            value={dynamicFields[field.label] || ""}
                            onChange={(e) =>
                              handleInputChange(field.label, e.target.value)
                            }
                            InputLabelProps={{ shrink: true }}
                            required={field.is_required}
                          />
                        )}
                        {field.field_type === "date-time" && (
                          <TextField
                            type="datetime-local"
                            id={`dynamic_fields.${field.label}`}
                            variant="outlined"
                            disabled={isFieldDisabled(field?.is_editable)}
                            fullWidth
                            defaultValue={getDataTimeFormatted(
                              dynamicFields[field.label]
                            )}
                            onChange={(e) =>
                              handleInputChange(field.label, e.target.value)
                            }
                            InputLabelProps={{ shrink: true }}
                            required={field.is_required}
                          />
                        )}
                        {field.field_type === "text-area" && (
                          <TextField
                            id={`dynamic_fields.${field.label}`}
                            variant="outlined"
                            disabled={isFieldDisabled(field?.is_editable)}
                            fullWidth
                            multiline
                            value={dynamicFields[field.label] || ""}
                            onChange={(e) =>
                              handleInputChange(field.label, e.target.value)
                            }
                            required={field.is_required}
                          />
                        )}
                        {field.field_type === "drop-down" && (
                          <FormControl fullWidth>
                            <Select
                              labelId={`select-label-${field.label}`}
                              id={`select-${field.label}`}
                              value={dynamicFields[field.label] || ""}
                              disabled={isFieldDisabled(field?.is_editable)}
                              onChange={(e) =>
                                handleInputChange(field.label, e.target.value)
                              }
                              required={field.is_required}
                            >
                              <MenuItem selected disabled>
                                Select
                              </MenuItem>
                              {field?.choices?.map((option: any) => (
                                <MenuItem key={option.id} value={option.choice}>
                                  {option.choice}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        {field.field_type === "check-box" && (
                          <FormControl fullWidth>
                            <Select
                              labelId={`checkbox-label-${field.label}`}
                              id={`checkbox-${field.label}`}
                              disabled={isFieldDisabled(field?.is_editable)}
                              required={field.is_required}
                              multiple
                              value={dynamicFields[field.label] || []} // Ensure value is an array of IDs
                              onChange={(e: any) =>
                                handleCheckboxChange(e, field.label)
                              }
                              renderValue={(selected) => {
                                // Create an array of selected choice labels
                                const selectedLabels = (
                                  field?.multiple_choices || []
                                )
                                  .filter((choice) =>
                                    selected.includes(String(choice.id))
                                  )
                                  .map((choice) => choice.choice);
                                return selectedLabels.join(", ");
                              }}
                            >
                              {field?.multiple_choices?.map((choice: any) => (
                                <MenuItem
                                  key={choice.id}
                                  value={String(choice.id)}
                                >
                                  <Checkbox
                                    checked={dynamicFields[
                                      field.label
                                    ]?.includes(String(choice.id))}
                                  />
                                  <ListItemText primary={choice.choice} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}

                        {field.field_type === "tel-phone" && (
                          <PhoneInput
                            defaultCountry="IN"
                            value={dynamicFields[field.label] || ""}
                            onChange={(value) =>
                              handleInputChange(field.label, value)
                            }
                            required={field.is_required}
                            disabled={isFieldDisabled(field?.is_editable)}
                          />
                        )}
                        {errors[field.label] && (
                          <div className="mt-2" style={{ color: "red" }}>
                            {capitalizeFirstLetter(errors[field.label])}
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                </>
              )}
            </div>

            <Box display="flex" justifyContent="flex-end" mt={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={submitHandler}
                disabled={!isEnabled || isButtonDisabled}
              >
                Save
              </Button>
            </Box>
          </Paper>

          <div className="card mt-4">
            <div className="card-header">
              <h5 className="card-title">Teams</h5>
            </div>
            <div className="card-body">
              <div className="row">
                {theTeams.map((team) => (
                  <div key={team.id} className="col-md-3 mb-2">
                    <button className={`btn btn-primary mr-2 mb-2`}>
                      {team.name}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Box>
      )}
    </>
  );
};

export default MyProfile;
