import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const createTask = createApi({
  reducerPath: "createTask",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    createTask: builder.mutation({
      query: (body) => ({
        url: `v1/tickets/ticket-event/`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useCreateTaskMutation } = createTask;
