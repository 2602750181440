import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useEditEmailTemplateMutation,
  useNewEmailTemplateMutation,
  useDeleteAttachmentQuery,
} from "../../../../Services/settingsAPIs/EmailConfiguration";
import { toast } from "react-toastify";
import { Box, Chip } from "@mui/material";

interface IPROPS {
  closeModal: () => void;
  selectedTeam: number;
  refetchTemplates: any;
  templateForEdit: any;
}

const AddTemplateModal: React.FC<IPROPS> = ({
  closeModal,
  selectedTeam,
  refetchTemplates,
  templateForEdit,
}) => {
  const initialContent = templateForEdit.templateEditEnabled
    ? templateForEdit.templateData.content
    : "";
  const [editorContent, setEditorContent] = useState<string>(initialContent);
  const [triggerNewEmailTemplateMutation] = useNewEmailTemplateMutation();
  const [triggerEditEmailTemplateMutation] = useEditEmailTemplateMutation();
  const [deleteId, setDeleteId] = useState<any>("");
  const { refetch } = useDeleteAttachmentQuery(deleteId, { skip: !deleteId });
  const [files, setFiles] = useState([]);
  const [templateAttachement, setTemplateAttachement] = useState<any>();

  const formik = useFormik({
    initialValues: {
      subject: templateForEdit.templateEditEnabled
        ? templateForEdit.templateData.subject
        : "",
      content: templateForEdit.templateEditEnabled
        ? templateForEdit.templateData.content
        : "",
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("Subject is required"),
    }),
    onSubmit: async (values) => {
      const dummyData = {
        subject: values.subject,
        content: editorContent.replace(/<\/?p>/g, ""),
      };

      if (templateForEdit.templateEditEnabled)
        triggerEditEmailTemplateMutation({
          templateId: templateForEdit.templateData.id,
          body: {
            attachments: files,
            content: dummyData.content,
            subject: dummyData.subject,
            id: templateForEdit.templateData.id,
          },
        })
          .then((res: any) => {
            if (res.data) {
              toast.success("Edit on the template has been saved");
              refetch(); // Call refetch here
              refetchTemplates();
            }
          })
          .catch((err) => toast.error(err));
      else
        triggerNewEmailTemplateMutation({
          attachments: [],
          content: dummyData.content,
          subject: dummyData.subject,
          team: String(selectedTeam),
        })
          .then(
            (res) => res.data && toast.success("New template has been added")
          )
          .catch((err) => toast.error(err));
      setTimeout(() => {
        refetchTemplates();
      }, 3000);

      closeModal();
    },
  });

  const handleFileChange = async (event) => {
    const newFiles = Array.from(event.target.files);
    const filePromises = newFiles.map((file: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const fileData = {
            file_name: file.name,
            file: e.target.result.split(",")[1], // Extracting Base64 part
          };
          resolve(fileData);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });

    try {
      const fileDataArray = await Promise.all(filePromises);
      setFiles((prevFiles) => {
        return [...prevFiles, ...fileDataArray];
      });
    } catch (error) {
      console.error("Error reading files:", error);
      toast.error("An error occurred while uploading files. Please try again.");
    }
  };

  useEffect(() => {
    setTemplateAttachement(templateForEdit);
  }, []);
  const handleEditorChange = (content: string) => {
    setEditorContent(content);
  };
  const handleEditorBlur = () => {
    formik.setFieldTouched("content", true);
  };
  const handleDeleteFile = (fileToDelete) => () => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };
  const handleDeleteAttachmentFile = (fileToDeleteId) => async () => {
    try {
      // Make the API call to delete the attachment
      setDeleteId(fileToDeleteId); // Set the ID to trigger the API call

      // Directly update the local state after the API call
      setTemplateAttachement((prev) => ({
        ...prev,
        templateData: {
          ...prev.templateData,
          attachments: prev.templateData.attachments.filter(
            (file) => String(file.id) !== String(fileToDeleteId)
          ),
        },
      }));

      toast.success("Attachment deleted successfully.");
    } catch (error) {
      console.error("Error deleting attachment:", error);
      toast.error("Failed to delete the attachment. Please try again.");
    }
  };
  const handleDownloadFile = (fileId) => {
    window.open(`${fileId}`, "_blank");
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "20px",
        background: "white",
        borderRadius: "8px",
      }}
    >
      <p
        onClick={closeModal}
        style={{
          float: "right",
          border: "none",
          background: "none",
          cursor: "pointer",
          fontSize: "18px",
        }}
      >
        &times;
      </p>
      <h2>
        {templateForEdit.templateEditEnabled ? (
          <span>Edit a </span>
        ) : (
          <span>Add new </span>
        )}{" "}
        template
      </h2>
      <form onSubmit={formik.handleSubmit}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: "20px",
          }}
        >
          <label>Subject</label>
          <input
            name="subject"
            style={{
              padding: "8px",
              overflow: "hidden",
              width: "100%",
            }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.subject}
          />
          {formik.touched.subject && formik.errors.subject ? (
            <div style={{ color: "red" }}>{formik.errors.subject}</div>
          ) : null}
        </div>
        <ReactQuill
          value={editorContent}
          onChange={handleEditorChange}
          onBlur={handleEditorBlur}
          modules={modules}
          formats={formats}
          placeholder="Compose your email..."
        />
        {formik.touched.content && !editorContent ? (
          <div style={{ color: "red" }}>Content is required</div>
        ) : null}

        {files.length > 0 && (
          <div className="">
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
              {files.map((file, index) => (
                <Chip
                  key={index}
                  label={file.file_name}
                  onDelete={handleDeleteFile(file)}
                />
              ))}
            </Box>
          </div>
        )}
        {templateAttachement?.templateData?.attachments?.length > 0 && (
          <div className="">
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
              {templateAttachement?.templateData?.attachments.map(
                (file, index) => (
                  <Chip
                    key={index}
                    label={file.file_name}
                    onClick={() => handleDownloadFile(file.file)}
                    onDelete={handleDeleteAttachmentFile(file.id)}
                    sx={{ cursor: "pointer" }} // Adds a pointer cursor on hover
                  />
                )
              )}
            </Box>
          </div>
        )}
        <div style={{ marginTop: "20px", textAlign: "left" }}>
          <label
            htmlFor="attachFileInput"
            className="btn btn-active-light-primary btn-icon"
            title="You can add attachments for the following 
                                        file types: xls,pdf,csv,doc,jpg,png upto maximum size of 15MB
            "
          >
            <i className="bi bi-paperclip fs-2"></i>
            <input
              type="file"
              id="attachFileInput"
              className="d-none"
              multiple
              onChange={handleFileChange}
            />
          </label>
        </div>
        <div style={{ marginTop: "20px", textAlign: "right" }}>
          <button
            className="btn btn-light"
            type="button"
            onClick={closeModal}
            style={{ marginRight: "10px" }}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

// Modules object for setting up the Quill editor toolbar
const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["clean"],
  ],
};

// Formats objects for setting up Quill editor formats
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
];

export default AddTemplateModal;
