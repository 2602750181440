import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import {
    AUTH_TOKEN,
    CIRCLEONE_LOGIN_TYPE,
    CIRCLEONE_TEAM,
    CIRCLEONE_USER,
    CIRCLEONE_USERNAME,
    USER_TEAM_ID,
    USER_TOKEN,
} from "../../../constants";
import axios from "axios";
import { toast } from "react-toastify";

import { Loader } from "react-bootstrap-typeahead";
import { CircularProgress, FormControl, MenuItem, Select } from "@mui/material";
import './styless.scss'

const emailOrUsernameValidation = Yup.string().required(
    "Email or Username is required"
);

const loginSchema = Yup.object().shape({
    email: emailOrUsernameValidation,
    password: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Password is required"),
});

const initialValues = {
    email: "",
    password: "",
    tenant: "",
};

export const Login = () => {
    const [loadingOl, setLoading] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [teamName, setTeamName] = useState<any>([]);
    const [authLoading, setAuthLoading] = useState<boolean>(false);
    const [isBranchUserTrue, setIsBranchUserTrue] = useState<boolean>(false);
    const [showSelfReleaseButton, setShowSelfReleaseButton] =
        useState<boolean>(false);
    const [storedErrorUserId, setStoredErrorUserId] = useState("");
    const [loadingSelfRelease, setLoadingSelfRelease] = useState(false);
    const [teamLoader, setTeamLoader] = useState(false);

    const fetchingTenant = window.location.hostname.split(".")[0];
    const apiPort = process.env.REACT_APP_API_PROTOCOL;

    const navigate = useNavigate();

    const triggerAuthMutationFn = async (payload: any) => {
      setTeamLoader(true)


        try {
            setAuthLoading(true);
            const response = await axios.post(
                `${apiPort}://${process.env.REACT_APP_BASE_URL}/v1/authenticate-user/`,
                payload
            );
            localStorage.setItem(
                CIRCLEONE_LOGIN_TYPE,
                response?.data?.branch_user
            );
            localStorage.setItem("password", payload?.password);
            setIsBranchUserTrue(response?.data?.branch_user);
            setAuthLoading(false);
            setTeamLoader(false)

            return response;
          } catch (error) {
            setTeamLoader(false)
            setAuthLoading(false);
            throw error;
        }
    };

    const resetFormAndStates = () => {
        formik.resetForm();
        setLoading(false);
        setShowPassword(false);
        setTeamName([]);
        setIsBranchUserTrue(false);
        setShowSelfReleaseButton(false);
        setStoredErrorUserId("");
    };

    const handleSelfRelease = async () => {
        try {
            const response = await axios.get(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/users/release-session/?id=${storedErrorUserId}&`
            );
            // Handle the response
            if (response.data) {
                setLoadingSelfRelease(true);
                toast.success("Self release successful");
                resetFormAndStates(); // Call the reset function here
            } else {
                toast.error("Self release failed");
            }
        } catch (error) {
            // You might want to handle any potential errors here as well
            toast.error("An error occurred during self release");
        } finally {
            setLoadingSelfRelease(false);
        }
    };
    const triggerLoginMutationFn = async (payload: any) => {
        return axios.post(
            `${apiPort}://${process.env.REACT_APP_BASE_URL}/v1/auth/`,
            payload
        );
    };

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,

        onSubmit: async (values, { setStatus, setSubmitting }) => {
            try {
                setSubmitting(true);
                const { email, ...restValues } = values;
                const payload = {
                    ...restValues,
                    username: values.email,
                    team: values.tenant,
                    tenant: fetchingTenant,
                };

                const response = await triggerLoginMutationFn(payload);

                // if (response?.data) {
                setSubmitting(false);
                if (isBranchUserTrue) {
                    localStorage.setItem(
                        USER_TOKEN,
                        JSON.stringify(response?.data)
                    );
                    localStorage.setItem(AUTH_TOKEN, response?.data?.token);
                    localStorage.setItem(
                        CIRCLEONE_USER,
                        response?.data?.tenant
                    );
                    localStorage.setItem(
                        USER_TEAM_ID,
                        response?.data.teams[0].id
                    );
                    localStorage.setItem(
                        CIRCLEONE_USERNAME,
                        response.data.username
                    );
                    localStorage.setItem(
                        CIRCLEONE_TEAM,
                        response.data.teams[0].name
                    );

                    const resp = await axios.get(
                        `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/users/me/`,
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem(
                                    AUTH_TOKEN
                                )}`,
                            },
                        }
                    );

                    if (resp?.data) {
                        localStorage.setItem("User_Id", resp?.data.id);
                        localStorage.setItem("user_role", resp?.data?.role);
                    }
                    if (resp?.data?.require_password_change) {
                        navigate("/auth/reset-password");
                    } else {
                        navigate("/client-login");
                        // window.location.replace("/dashboard/email-dashboard");
                    }
                    // toast.success("Successfully logged in");
                } else if (!isBranchUserTrue) {
                    localStorage.setItem(
                        USER_TOKEN,
                        JSON.stringify(response?.data)
                    );
                    localStorage.setItem(AUTH_TOKEN, response?.data?.token);
                    localStorage.setItem(CIRCLEONE_USER, response?.data?.tenant);
                    localStorage.setItem(
                        CIRCLEONE_USERNAME,
                        response?.data?.username
                    );
                    localStorage.setItem(
                        CIRCLEONE_TEAM,
                        response?.data?.teams[0]?.name
                    );
                    localStorage.setItem(
                        USER_TEAM_ID,
                        response?.data?.teams[0]?.id
                    );

                    toast.success("Successfully logged in");


                    const resp = await axios.get(
                        `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/users/me/`,
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem(
                                    AUTH_TOKEN
                                )}`,
                            },
                        }
                    );

                    if (resp.data) {
                        localStorage.setItem("User_Id", resp?.data.id);
                        localStorage.setItem("user_role", resp?.data?.role);
                    }
                    if (resp?.data?.require_password_change) {
                        navigate("/auth/reset-password");
                    } else {
                        window.location.replace("/dashboard/email-dashboard");
                    }
                }

                // }
                else {
                    toast.error("please check id and password");
                }
            } catch (error) {
                setStoredErrorUserId(error?.response?.data?.user_id);
                if (error.response.data.team) {
                    setShowSelfReleaseButton(true);
                    toast.error(error.response.data.team[0]); // Show team error message
                } else {
                    setShowSelfReleaseButton(false);
                    toast.error(
                        error.response.data.non_field_errors[0] ||
                            "An error occured"
                    );
                }
                setLoading(false);
                setSubmitting(false);
            }
        },
    });

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    React.useEffect(() => {
        formik.setFieldValue("tenant", "");
    }, [formik.values.email, formik.values.password]);

    const fetchTeams = async () => {
        try {
            const { email: forEmail, password } = formik.values;

            if (password?.length <= 3) {
                toast.error("Password must be longer than 3 characters");

                return;
            }

            const authenticatePayload = {
                username: forEmail,
                password: password,
                tenant: fetchingTenant,
            };

            // Trigger authentication
            const response = await triggerAuthMutationFn(authenticatePayload);

            setTeamLoader(true);

            if (response?.data) {
                // Fetch teams after successful authentication
                const teamsData = await axios.get(
                    `${apiPort}://${process.env.REACT_APP_BASE_URL}/v1/auth/teams/${forEmail}/${fetchingTenant}/`
                );

                setTeamName(
                    teamsData && teamsData?.data && teamsData?.data?.team
                );
                setTeamLoader(false);
            } else {
                setTeamName([]);
                toast.error("Authentication failed");
            }
        } catch (error) {
            setTeamName([]);
            const errorMessage =
                error?.response?.status === 400
                    ? error?.response?.data?.username?.[0] ||
                      error?.response?.data?.password?.[0]
                    : error?.response?.data?.message?.[0] ||
                      "An unknown error occurred";
            toast.error(errorMessage);
        } finally {
            setTeamLoader(false); // Stop loader
        }
    };


    const ITEM_HEIGHT = 50;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 120,
            },
        },
    };

    return (
        <form
            className="form w-100"
            onSubmit={formik.handleSubmit}
            noValidate
            id="kt_login_signin_form"
        >
            <div className="text-center mb-10">
                <h1 className="text-dark mb-3">Sign In</h1>
                <div className="text-gray-400 fw-bold fs-4">
                    New here?{" "}
                    <Link
                        to="/auth/registration"
                        className="link-primary fw-bolder"
                    >
                        Create an account
                    </Link>
                </div>
            </div>

            {formik.status ? (
                <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">
                        {formik.status}
                    </div>
                </div>
            ) : (
                <div></div>
            )}

            <div className="fv-row mb-10">
                <label className="form-label fs-6 fw-bolder text-dark">
                    Email/username
                </label>
                <input
                    placeholder="Enter your email/username"
                    {...formik.getFieldProps("email")}
                    className={clsx(
                        "form-control form-control-lg form-control-solid",
                        {
                            "is-invalid":
                                formik.touched.email && formik.errors.email,
                        }
                    )}
                    type="email"
                    name="email"
                    autoComplete="off"
                />
                {formik.touched.email && formik.errors.email && (
                    <div className="fv-plugins-message-container">
                        <span role="alert" style={{ color: "red" }}>
                            {formik.errors.email}
                        </span>
                    </div>
                )}
            </div>

            <div className="fv-row mb-10">
                <div className="d-flex justify-content-between mt-n5">
                    <div className="d-flex flex-column align-items-baseline flex-stack mb-2">
                        <label className="form-label fw-bolder text-dark fs-6 mb-0">
                            Password
                        </label>
                    </div>
                </div>
                <div className="input-group">
                    <input
                        type={showPassword ? "text" : "password"}
                        autoComplete="off"
                        placeholder="Enter your password"
                        {...formik.getFieldProps("password")}
                        // value={formik.values.password}
                        // onChange={handleChange}
                        // onBlur={passwordHandler}
                        className={clsx(
                            "form-control form-control-lg form-control-solid",
                            {
                                "is-invalid":
                                    formik.touched.password &&
                                    formik.errors.password,
                            }
                        )}
                    />

                    <div className="input-group-append">
                        <span
                            className="form-control form-control-lg form-control-solid"
                            id="togglePassword"
                            onClick={togglePasswordVisibility}
                            style={{
                                cursor: "pointer",
                                borderRadius: "0px 5px 5px 0px",
                            }}
                        >
                            {showPassword ? (
                                <i className="bi bi-eye-slash text-dark"></i>
                            ) : (
                                <i className="bi bi-eye text-dark"></i>
                            )}
                        </span>
                    </div>
                </div>

                <Link
                    to="/auth/forgot-password"
                    className="link-primary fs-6 fw-bolder"
                    style={{ marginLeft: "5px" }}
                >
                    Forgot password ?
                </Link>

                {formik.touched.password && formik.errors.password && (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                            <span role="alert" style={{ color: "red" }}>
                                {formik.errors.password}
                            </span>
                        </div>
                    </div>
                )}
            </div>

            <div className="fv-row mb-10" style={{ marginTop: "-10px" }}>
                <div>
                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                        Team
                    </label>
                </div>

                {/* {teamsLoader ? (
    <div
    style={{
      display: "flex",
      justifyContent: "center",
      width: "100%",
      border: "1px solid #f5f8fa",
      background: "#f5f8fa",
      padding: "15px 10px",
      }}
      >
      <Loader />
      </div>
      ) : ( */}
                {/* <div className="input-group">
          <select
            style={{ width: "100%" }}
            className={clsx("form-control form-control-lg form-control-solid", {
              "is-invalid": formik.touched.tenant && formik.errors.tenant,
            })}
            onFocus={fetchTeams}
            onChange={(event) => {
              formik.setFieldValue("tenant", event.target.value);
            }}
            value={formik.values.tenant}
          >
            <option value="" disabled>
              Select Team
            </option>

            {teamName.length > 0 ? (
              teamName?.map((team: any) => (
                <option key={team?.id} value={team.id}>
                  {team?.name}
                </option>
              ))
            ) : (
              <option disabled value="">
                No Team Found
              </option>
            )}
          </select>
        </div> */}

                <div className="team-select_handler_cont">
                    <FormControl fullWidth>
                        {/* <label>Select Team</label> */}
                        <Select
                            style={{ background: "#f5f8fa", border: "none" }}
                            value={formik.values.tenant || ""}
                            onFocus={fetchTeams}
                            onChange={(event) => {
                                formik.setFieldValue(
                                    "tenant",
                                    event.target.value
                                );
                            }}
                            MenuProps={MenuProps}
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) {
                                    return teamName.length > 0
                                        ? "Select team"
                                        : "Select team";
                                }
                                const selectedTeam = teamName.find(
                                    (team) => team.id === selected
                                );
                                return selectedTeam ? selectedTeam.name : "";
                            }}
                        >
                            <MenuItem value="" disabled>
                                {teamLoader ? (
                                    <>
                                        Searching...{" "}
                                        <CircularProgress size={20} />
                                    </>
                                ) : (
                                    "Select Team"
                                )}
                            </MenuItem>
                            {!teamLoader &&
                                teamName.map((team) => (
                                    <MenuItem key={team.id} value={team.id}>
                                        {team.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </div>

                {/* put the previous code intact. Just open the <selec></selec> after 1 second */}

                {formik.touched.tenant && formik.errors.tenant && (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                            <span role="alert" style={{ color: "red" }}>
                                {formik.errors.tenant}
                            </span>
                        </div>
                    </div>
                )}
            </div>

            <div className="text-center">
                <button
                    type="submit"
                    id="kt_sign_in_submit"
                    className="btn btn-lg btn-primary w-100 mb-1"
                    disabled={
                        formik.isSubmitting ||
                        !formik.isValid ||
                        !formik.values.tenant
                    }
                >
                    {!loadingOl && (
                        <span className="indicator-label">Continue</span>
                    )}
                    {loadingOl && (
                        <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                        >
                            Please wait... <Loader />
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    )}
                </button>
            </div>

            {showSelfReleaseButton && (
                <div className="text-center">
                    <button
                        type="button"
                        className="btn btn-lg btn-secondary w-100 mt-2 mb-2"
                        onClick={handleSelfRelease}
                        disabled={loadingSelfRelease}
                    >
                        {loadingSelfRelease ? (
                            <span
                                className="indicator-progress"
                                style={{ display: "block" }}
                            >
                                Please wait... <Loader />
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        ) : (
                            <span className="indicator-label">
                                Self release
                            </span>
                        )}
                    </button>
                </div>
            )}
            <div
                style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "14px",
                    color: "#009ef7",
                }}
            >
                <p>
                    Access to this portal is restricted to legal employee
                    only.Unauthorized attempts are tracked.
                </p>
            </div>
        </form>
    );
};
