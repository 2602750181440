import { useEffect, useState } from "react";
import {
  useDeleteTicketScheduleMutation,
  useTicketScheduleMutation,
} from "../../../../Services/settingsAPIs/TicketSettingsAPI";
import { toast } from "react-toastify";

interface IProps {
  closeDeleteScheduleModal: any;
  scheduleForDeletion: any;
}

const DeleteScheduleModal: React.FC<IProps> = ({
  closeDeleteScheduleModal,
  scheduleForDeletion,
}) => {
  const [schedule2bDeleted, setSchedule2bDeleted] = useState<any>();
  const [triggerDeleteScheduleMutation] = useDeleteTicketScheduleMutation();
  const [triggerGetScheduleInfoMutation] = useTicketScheduleMutation();

  const confirmDeleteSchedule = async (event: any) => {
    const response = await triggerDeleteScheduleMutation(scheduleForDeletion);
    if (response.data) {
      toast.success("Scheduler deleted successfully!"); // Show success toast
      closeDeleteScheduleModal();
    }
  };

  useEffect(() => {
    async function fetchScheduleInfo() {
      const response = await triggerGetScheduleInfoMutation(
        scheduleForDeletion
      );
      if (response.data) {
        setSchedule2bDeleted(response.data);
      }
    }
    fetchScheduleInfo();
  }, []);

  return (
    <>
      <div className="card-body">
        <p className="fs-5 fw-bold">
          Are you sure you want to delete the following schedule?
        </p>
        <div className="card-text">
          Title: <strong>{schedule2bDeleted && schedule2bDeleted.title}</strong>
        </div>
        <div className="card-text">
          Description:{" "}
          <strong>{schedule2bDeleted && schedule2bDeleted.description}</strong>
        </div>

        <div className="d-flex justify-content-end mt-10">
          <button
            type="reset"
            className="btn btn-sm btn-white btn-active-light-primary me-2"
            onClick={() => closeDeleteScheduleModal()}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-danger"
            onClick={confirmDeleteSchedule}
          >
            Delete Schedule
          </button>
        </div>
      </div>
    </>
  );
};

export default DeleteScheduleModal;
