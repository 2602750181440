import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import UserPage from "./UserPage";

const UserRoute = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route path="user" element={<UserPage />} />
            </Route>
        </Routes>
    );
};

export default UserRoute;
