import React, { useState, useEffect } from "react";
import { useCallHistoryIdMutation } from "../../Services/Telephony/TelephonyFrontend";
import { toast } from "react-toastify";
import { CALL } from "../../constants";
import { useTelephony } from "../context/TelephonyContext";

interface IPROPS {
  setIsCallPaused: any;
    setCall: any;
    setInbound:any
    ticketId: any;
    closeModal: any;
    Disposition: {
        disposition: string;
        id: number;
        sub_disposition: string[];
        team: number;
    }[];
}

const TelephonyDisposition: React.FC<IPROPS> = ({
  setIsCallPaused,
    Disposition,
    ticketId,
    closeModal,
    setCall,
    setInbound
}) => {
    const [selectedDisposition, setSelectedDisposition] = useState<number | null>(null);
    const [selectedDispositionName, setSelectedDispositionName] = useState<string | null>(null); // New state for selected disposition name
    const [subDispositions, setSubDispositions] = useState<string[]>([]);
    const [selectedSubDisposition, setSelectedSubDisposition] = useState<string | null>(null);

    const [remarks, setRemarks] = useState("");

    const [callHistoryTrigger] = useCallHistoryIdMutation();

    const {
        setCallActive,
        setCallDisconnected,
        callDisconnected,
        setLifitinMobileNumber,
        setDisconnectedCallLoader,
    } = useTelephony();

    useEffect(() => {
        // Reset sub-disposition and fetch the name of the selected disposition
        if (selectedDisposition !== null) {
            const disposition = Disposition.find(
                (item) => item.id === selectedDisposition
            );
            setSubDispositions(disposition?.sub_disposition || []);
            setSelectedDispositionName(disposition?.disposition || null); // Set the name of the selected disposition
            setSelectedSubDisposition(null); // Clear previously selected sub-disposition
        }
    }, [selectedDisposition, Disposition]);

    const handleDispositionChange = (
        e: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const value = parseInt(e.target.value, 10);
        setSelectedDisposition(value);
    };

    const handleSubDispositionChange = (
        e: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setSelectedSubDisposition(e.target.value);
    };

    const saveDisposition = async () => {
        const requestBody = {
            action: "dispose-call",
            dispose_remark: selectedDispositionName || "", // Name of the selected disposition
            remarks: remarks || "", // Remarks input
            sub_dispose_remark: selectedSubDisposition || "", // Name of the selected sub-disposition
        };

        const filteredRequestBody = Object.fromEntries(
            Object.entries(requestBody).filter(([_, value]) => value)
        );

        try {
            const response = await callHistoryTrigger({
                body: filteredRequestBody,
                id: ticketId,
            });

            if (response?.data) {
                setCall(false);
                localStorage.removeItem("liftingMobileNumber");
                localStorage.setItem(CALL, "false");
                setCallActive(false);
                localStorage.removeItem("telephony-timer");
                setCallDisconnected(true);
                setLifitinMobileNumber("");
                toast.success("Disposed successfully");
                closeModal();
                setDisconnectedCallLoader(false);
                setIsCallPaused(true);
                setInbound([])
            } else if (response?.error) {
                const errorData = (response?.error as any)?.data
                    ?.dispose_remark?.[0];
                toast.error(
                    errorData
                        ? "Disposition should be provided"
                        : "Unable to edit"
                );
            }
            setCallDisconnected(true)
        } catch (error) {
            toast.error("An error occurred");
        }
    };

    return (
        <div>
            {/* <div className="text-center mb-3">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Disposition
        </label>
      </div> */}

            <div className="row g-5 g-xl-8 mb-6">
                <div className="col-xl-6" style={{ width: "100%" }}>
                    <label
                        className={Disposition?.length > 0 ? "required" : ""}
                    >
                        Disposition
                    </label>
                    <select
                        name="Disposition"
                        className="form-select form-select-lg form-select-solid"
                        onChange={handleDispositionChange}
                        value={selectedDisposition || ""}
                    >
                        <option value="" disabled>
                            {Disposition?.length > 0
                                ? "Select Disposition"
                                : "No disposition found"}
                        </option>
                        {Disposition.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.disposition}
                            </option>
                        ))}
                    </select>
                </div>

                {subDispositions.length > 0 && (
                    <div className="col-xl-6" style={{ width: "100%" }}>
                        <label className="required">Sub-Dispositions</label>
                        <select
                            name="SubDisposition"
                            className="form-select form-select-lg form-select-solid"
                            onChange={handleSubDispositionChange}
                            value={selectedSubDisposition || ""}
                        >
                            <option value="" disabled>
                                Select Sub-Disposition
                            </option>
                            {subDispositions.map((sub, index) => (
                                <option key={index} value={sub}>
                                    {sub}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </div>

            <div className="col-xl-6" style={{ width: "100%" }}>
                <label>Remarks</label>
                <textarea
                    className="form-control form-control-lg form-control-solid"
                    rows={1}
                    autoComplete="off"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                />
            </div>

            <div className="text-end mt-4">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={saveDisposition}
                    disabled={Disposition?.length > 0 && !selectedDisposition}
                >
                    Save
                </button>
            </div>
        </div>
    );
};

export default TelephonyDisposition;
