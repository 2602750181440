import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const BulkAssign = createApi({
  reducerPath: "BulkAssign",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    bulkAssign: builder.mutation({
      query: ({body}) => ({
        url: `v1/tickets/bulk-assignment/`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useBulkAssignMutation } = BulkAssign;
