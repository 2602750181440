/* 
common: PUT api/v1/common/tag-name/1/ticket_tag
level:  PUT api/v1/common/tag-level/1/ticket_tag/Escalation%20Level%2014
{"level":{"Escalation Level 14":{"email":["tiju@botgo.io","manvendra@yahoo.com"],"duration_in_ms":600000}}}

PUT api/v1/common/tag-name/1/ticket_tag

PUT
{
01 [esc01: {email, durationSLA}, esc02: {email, durationSLA}, esc03: {email, durationSLA}] for tag 01 GOLD
02 [esc01: {email, durationSLA}, esc02: {email, durationSLA}, esc03: {email, durationSLA}] for tag 02 SIVER
03 [esc01: {email, durationSLA}, esc02: {email, durationSLA}, esc03: {email, durationSLA}] for tag 03 BRONZE
} */

import { useState } from "react";
import PriorityBasedSLA from "./PriorityBasedSLA";
import WorkflowBasedSLA from "./WorkflowBasedSLA";
import TheTagBasedSLA from "./tagBasedSLA";

const ControlStack = () => {
  const theHeaders = [
    {title: "Tag", space: 4},
    {title: "Hours", space: 1},
    {title: "Minutes", space: 1},
    {title: "Mail ID", space: 6}
  ];

  const picks = ['tag','hours','minutes','mailid'];

  const theRecords = [
    {tag:'Platinum', hours:1,minutes:0, mailid:'prajakta@bluewhirl.io'},
    {tag:'Gold', hours:2,minutes:0, mailid:'joshi@bluewhirl.io'},
    {tag:'Silver', hours:3,minutes:0, mailid:'raj@bluewhirl.io'},
    {tag:'Frequent Buyer', hours:2,minutes:0, mailid:'kulkarni@bluewhirl.io'},
  ];

  return(<>
    <div className="row">
      <div className="col">
        <div className="card my-1 min-h-40px justify-content-center" style={{backgroundColor: 'rgba(228, 228, 228, 0.498)'}}>
          <div className="row align-items-center">
          {theHeaders.map((header:any, index:number)=>(<>
            <div className={`col-${header.space} text-start ${index===0 && 'ps-10'}`}><label htmlFor="">{header.title}</label></div>
          </>))}
          </div>
        </div>
      </div>
    </div>
      {theRecords.map((rec: any)=> (<>
        <div className="row">
          <div className="col">
            <div className="input-group-text mt-2 bg-white">
              <div className="row w-100 align-items-center">
              {theHeaders.map((header:any, index:number)=>(<>
                <div className={`col-${header.space} text-start`}>{rec[picks[index]]}</div>
              </>))}
              </div>
            </div>
          </div>
        </div>
      </>))
      }
      <div className="row">
        <div className="col text-end mt-2">
          <button className="btn border min-w-200px">Add level</button>
          <button className="btn btn-primary ms-4 min-w-100px">Save</button>
        </div>
      </div>
    </>)
  }

const SLA = () => {
  const [openedPane, setOpenedPane] = useState('');
  return (
    <div>
      <div className="text-center">
        <div>
          <ul className="nav card-header-pills d-flex align-items-center">
            <li className="nav-item"> <a className="nav-link active" href="#" onClick={()=>setOpenedPane('tagbasedsla')}>Tag based SLA</a> </li>
            <li className="nav-item"> <a className="nav-link" href="#" onClick={()=>setOpenedPane('prioritybasedsla')}>Priority based SLA</a> </li>
            <li className="nav-item"> <a className="nav-link" href="#" onClick={()=>setOpenedPane('workflowbasedsla')}>Workflow based SLA</a> </li>
          </ul>
        </div>
        <div className="card-body px-0 px-lg-10">
        {(() => {
          switch (openedPane) {
            case 'tagbasedsla': return (<TheTagBasedSLA/>);
            case 'prioritybasedsla': return (<PriorityBasedSLA/>);
            case 'workflowbasedsla': return (<WorkflowBasedSLA/>);
            default: return <div></div>;
          }
        })()} {/* This code block uses an immediately invoked function expression (IIFE) inside the curly braces to switch between different cases based on the value of openedPane and render the corresponding JSX elements accordingly. */}
        </div>
      </div>
    </div>
  )
}

export default SLA