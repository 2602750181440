import { useState, useEffect } from "react";
import AutoTicketRules from "./AutoTicketRules";
import {
  useTeamMutation,
  useTeamsQuery,
  useUpdateTeamMutation,
} from "../../../../Services/settingsAPIs/CommonAPI";
import {
  useGetActiveAutoTicketNotificationQuery,
  useGetActiveManualTicketNotificationQuery,
} from "../../../../Services/settingsAPIs/TicketSettingsAPI";

const Notifications = () => {
  const { data: teamsData } = useTeamsQuery({});
  const [theTeams, setTheTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState<number>();
  const {
    data: activeAutoTicketNotificationsData,
    isLoading: isLoadingActiveAutoTicketNotificationData,
    refetch: refetchATND,
  } = useGetActiveAutoTicketNotificationQuery(selectedTeam, {
    skip: !selectedTeam,
  });
  const {
    data: activeManualTicketNotificationsData,
    isLoading: isLoadingActiveManualTicketNotificationData,
    refetch: refetchMTND,
  } = useGetActiveManualTicketNotificationQuery(selectedTeam, {
    skip: !selectedTeam,
  });
  const [ticketNotificationsData, setTicketNotificationsData] = useState<any>({
    hasActiveAutoND: false,
    hasActiveManualND: false,
  });
  const [selectedTeamInfo, setSelectedTeamInfo] = useState();
  const [autoTicketGenEnabled, setAutoTicketGenEnabled] = useState(false);
  const [autoTicketNotificationEnabled, setAutoTicketNotificationEnabled] =
    useState(false);
  const [manualTicketNotificationEnabled, setManualTicketNotificationEnabled] =
    useState(false);
  const [triggerTeamMutation] = useTeamMutation();
  const [triggerUpdateTeamInfo] = useUpdateTeamMutation();

  const handleTeamDropdownChange = (event: any) => {
    setSelectedTeam(Number(event.target.value));
  };
  const handleAutoTicketGenToggle = () => {
    setAutoTicketGenEnabled(!autoTicketGenEnabled);
    setSelectedTeamInfo((prev: any) => {
      return {
        ...prev,
        auto_ticket_generate_enabled: !prev.auto_ticket_generate_enabled,
      };
    });
  };
  const handleAutoTicketNotificationToggle = () => {
    setAutoTicketNotificationEnabled(!autoTicketNotificationEnabled);
    setSelectedTeamInfo((prev: any) => {
      return {
        ...prev,
        auto_ticket_notification_enabled:
          !prev.auto_ticket_notification_enabled,
      };
    });
  };
  const handleManualTicketNotificationToggle = () => {
    setManualTicketNotificationEnabled(!manualTicketNotificationEnabled);
    setSelectedTeamInfo((prev: any) => {
      return {
        ...prev,
        manual_ticket_notification_enabled:
          !prev.manual_ticket_notification_enabled,
      };
    });
  };

  async function fetchTeamInfo() {
    triggerTeamMutation(selectedTeam)
      .then((res: any) => {
        res.data &&
          setAutoTicketGenEnabled(res.data.auto_ticket_generate_enabled);
        setAutoTicketNotificationEnabled(
          res.data.auto_ticket_notification_enabled
        );
        setManualTicketNotificationEnabled(
          res.data.manual_ticket_notification_enabled
        );
        setSelectedTeamInfo(res.data);
      })
      .catch((err: any) => console.log(err));
  }
  useEffect(() => {
    setTheTeams(teamsData);
  }, [teamsData]);
  useEffect(() => {
    selectedTeam && fetchTeamInfo();
  }, [selectedTeam]);
  useEffect(() => {
    selectedTeamInfo &&
      triggerUpdateTeamInfo({
        teamId: selectedTeam,
        body: selectedTeamInfo,
      });
  }, [selectedTeamInfo]);

  useEffect(() => {
    setTicketNotificationsData((prevState: any) => {
      const newState = { ...prevState };

      if (
        !isLoadingActiveAutoTicketNotificationData &&
        activeAutoTicketNotificationsData?.length
      ) {
        newState.hasActiveAutoND = true;
        newState.auto = activeAutoTicketNotificationsData;
      }

      if (
        !isLoadingActiveManualTicketNotificationData &&
        activeManualTicketNotificationsData?.length
      ) {
        newState.hasActiveManualND = true;
        newState.manual = activeManualTicketNotificationsData;
      }

      return newState;
    });
  }, [
    activeAutoTicketNotificationsData,
    activeManualTicketNotificationsData,
    isLoadingActiveAutoTicketNotificationData,
    isLoadingActiveManualTicketNotificationData,
  ]);

  return (
    <div className="card-toolbar flex-row-fluid justify-content-end gap-5 p-5">
      <div className="row align-items-center">
        <div className="col-md-2">
          <select
            className="form-select select2-hidden-accessible"
            onChange={handleTeamDropdownChange}
            value={selectedTeam}
          >
            <option value={0}>Select Team</option>
            {theTeams &&
              theTeams.map((team, index) => (
                <option key={index} value={team.id}>
                  {team.name}
                </option>
              ))}
          </select>
        </div>

        {selectedTeam !== 0 && (
          <div className="col-md-9 d-flex align-items-center gap-5">
            <label className="me-3">Auto ticket generation</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                disabled={!selectedTeam}
                checked={autoTicketGenEnabled}
                onChange={handleAutoTicketGenToggle}
              />
            </div>
            <label className="me-3">Auto ticket notification</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                disabled={!selectedTeam}
                checked={autoTicketNotificationEnabled}
                onChange={handleAutoTicketNotificationToggle}
              />
            </div>
            <label className="me-3">Manual ticket notification</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                disabled={!selectedTeam}
                checked={manualTicketNotificationEnabled}
                onChange={handleManualTicketNotificationToggle}
              />
            </div>
          </div>
        )}
      </div>
      {selectedTeam !== 0 && autoTicketNotificationEnabled && (
        <AutoTicketRules
          selectedTeam={selectedTeam}
          ticketNotificationsData={ticketNotificationsData}
          notificationType="auto_ticket"
          refetchATND={refetchATND}
          refetchMTND={refetchMTND}
        />
      )}
      {selectedTeam !== 0 && manualTicketNotificationEnabled && (
        <AutoTicketRules
          selectedTeam={selectedTeam}
          ticketNotificationsData={ticketNotificationsData}
          notificationType="manual_ticket"
          refetchATND={refetchATND}
          refetchMTND={refetchMTND}
        />
      )}
    </div>
  );
};

export default Notifications;
