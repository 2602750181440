import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';




const CampaignLists = () => {
    const [hoveredCard, setHoveredCard] = useState(null);
    const navigate = useNavigate();

    const handleMouseEnter = (index) => {
        setHoveredCard(index);
    };

    const handleMouseLeave = () => {
        setHoveredCard(null);
    };

    const toNavigate = (path) => {
        navigate(`/reports/campaign${path}`);
    };

    const reports = [

        // { title: 'Order/Invoice Detail Report', description: 'Comprehensive information on individual orders or invoices', to: '/campaign-deatil-report', image: 'https://cdn.mooninvoice.com/image/images/mi_invoice_fec_banner_6.svg' },
        // { title: 'Campaign Dispositions', description: 'The outcomes and dispositions of leads/prospects', to: '/disposition-report', image: 'https://media.istockphoto.com/id/1208395845/vector/people-planning-concept-entrepreneurship-and-calendar-schedule-planning-vector-business.jpg?s=612x612&w=0&k=20&c=DXy2mn1xa5fr_k_NbTSo0o48PeYFUPoVw9SvUHatYqI=' },
        { title: 'Lead Summary', description: 'Summary of lead generated with efforts and outcomes from respective campaigns', to: '/campaign-lead-report', image: 'https://img.freepik.com/free-vector/generating-new-leads-concept-illustration_114360-7654.jpg?size=626&ext=jpg' },
    ];

    const getCardStyle = (index) => ({
        transition: 'transform 0.3s, box-shadow 0.3s',
        transform: hoveredCard === index ? 'translateY(-5px)' : 'none',
        boxShadow: hoveredCard === index ? '0 4px 20px rgba(0, 0, 0, 0.1)' : 'none',
    });

    return (

        <>
            <div id="kt_app_toolbar" className="app-toolbar">
                <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                        <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                        Leads
                        </h1>
                      
                    </div>

                </div>
            </div>

            <div className='row gy-0 gx-12'>
                {reports.map((report, index) => (
                    <div className='col-xl-4 mt-5' key={index}>
                        <div
                            className='card card-xl-stretch bg-body border-0'
                            style={getCardStyle(index)}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => toNavigate(report.to)}
                        >
                            <div className='card-body d-flex flex-column flex-lg-row flex-stack'>
                                <div className='d-flex flex-column justify-content-center align-items-center align-items-lg-start me-10 text-center text-lg-start'>
                                    <h3 className='fs-1hx line-height-lg mb-5'>
                                        <span className='fw-bold'>{report.title}</span>
                                    </h3>
                                    <div className='fs-6 text-muted mb-7'>
                                        {report.description}
                                    </div>
                                    <button
                                        className='btn btn-primary fw-semibold px-6 py-3'
                                        onClick={() => toNavigate(report.to)}
                                    >
                                        View Reports
                                    </button>
                                </div>
                                <img
                                    src={report.image}
                                    alt='report-icon'
                                    className='mw-200px mw-lg-200px '
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default CampaignLists;
