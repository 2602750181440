import React, { useEffect, useState } from "react";
import { useEditEmailNotificationMutation, useSetEmailNotificationMutation } from "../../../../Services/settingsAPIs/EmailConfiguration";
import { toast } from "react-toastify";

const EmailNotificationOn = ({teamActiveEmailNotificationsData, selectedTeam, refetchMailNotifs}) => {
    const [triggerSetEmailNotificationMutation] = useSetEmailNotificationMutation();
    const [triggerEditEmailNotificationMutation] = useEditEmailNotificationMutation();
    const [sendTo, setSendTo] = useState(null);
    const handleSelectTeamChange = (event: any) => { setSendTo(event.target.value); };

    const selectTeam = [
        { id: "t1", name: "Select" },
        { id: "t2", name: "Creator" },
        { id: "t3", name: "Customer" },
        { id: "t4", name: "Assignee" },
    ];

    const [textareaContent, setTextareaContent] = useState("");
    const handleButtonClick = (text) => { setTextareaContent((prevContent: string) => prevContent + `{{${text}}}`); };
    const handleSave = () => {
        if(teamActiveEmailNotificationsData.length>0) {
            triggerEditEmailNotificationMutation({
                notificationId:teamActiveEmailNotificationsData[0].id, 
                body:{ ...teamActiveEmailNotificationsData[0], 
                    send_to: teamActiveEmailNotificationsData[0].send_to.includes(sendTo)?[...teamActiveEmailNotificationsData[0].send_to]:[...teamActiveEmailNotificationsData[0].send_to, sendTo], 
                    creator_template: sendTo==='Creator'? textareaContent:teamActiveEmailNotificationsData[0].creator_template,
                    assignee_template: sendTo==='Assignee'? textareaContent:teamActiveEmailNotificationsData[0].assignee_template,
                    customer_template: sendTo==='Customer'? textareaContent:teamActiveEmailNotificationsData[0].customer_template,
                }
            }).then((res:any)=>{res.data && toast.success("Updated the email notification"); refetchMailNotifs();}).catch((err:any)=>toast.error(err))
        } else {
            triggerSetEmailNotificationMutation({
                team: selectedTeam,
                status_type: "active",
                send_to: [sendTo],
                creator_template: sendTo==='Creator'? textareaContent:"",
                assignee_template: sendTo==='Assignee'? textareaContent:"",
                customer_template: sendTo==='Customer'? textareaContent:"",
                notification_type: "email"
            }).then((res:any)=>{res.data && toast.success("Added a new email notification"); refetchMailNotifs();}).catch((err:any)=>toast.error(err))
        }
    }

    useEffect(()=>{
        const configDataFound = teamActiveEmailNotificationsData?.find((activeMailNotif:any)=>activeMailNotif.send_to.includes(sendTo))
        if(configDataFound!==undefined) {
            if(sendTo==='Creator') {configDataFound.creator_template ? setTextareaContent(configDataFound.creator_template): setTextareaContent("");}
            else if(sendTo==='Assignee') {configDataFound.assignee_template ? setTextareaContent(configDataFound.assignee_template): setTextareaContent("");}
            else if(sendTo==='Customer') {configDataFound.customer_template ? setTextareaContent(configDataFound.customer_template): setTextareaContent("");}
        } else {setTextareaContent("");}
    },[teamActiveEmailNotificationsData, sendTo])

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }} className="mb-6">
            <div className="col">
                <span className="input-group-text mt-2" id="basic-addon1">
                    Email Notification
                </span>
            </div>
            <div style={{ width: "20%", display: "flex", gap: "10px" }}>
                <select
                    style={{ width: "fitContent" }}
                    className="form-select select2-hidden-accessible"
                    onChange={handleSelectTeamChange}
                    value={sendTo}
                >
                    {selectTeam?.map((el: any) => (
                        <option value={el?.name} key={el?.id}>
                            {el?.name}
                        </option>
                    ))}
                </select>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
                <button
                    onClick={() => handleButtonClick("Sender Name")}
                    className="btn btn-primary"
                    style={{
                        // background: "blue",
                        padding: "10px 20px",
                        borderRadius: "5px",
                        margin: "0",
                    }}
                >
                    Sender Name
                </button>
                <button
                    onClick={() => handleButtonClick("Assigned By")}
                    className="btn btn-primary"
                    style={{
                        padding: "10px 20px",
                        borderRadius: "5px",
                        margin: "0",
                    }}
                >
                    Assigned By
                </button>
            </div>
            <div>
                <div
                    style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                    }}
                >
                    <span>Send to</span>

                    <span
                        style={{
                            padding: "5px 10px",
                            background: "#F0F0F0 ",
                            color: "black",
                            borderRadius: "5px",
                        }}
                    >
                        {sendTo ? sendTo : "Not assigned"}
                    </span>
                </div>
                <textarea
                    className="w-100 border"
                    name="" id="" cols={30} rows={10}
                    value={textareaContent}
                    onChange={(e) => setTextareaContent(e.target.value)}
                ></textarea>
            </div>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "end",
                }}
            >
                <button className="btn btn-primary" onClick={handleSave}>Save</button>
            </div>
        </div>
    );
};

export default EmailNotificationOn;
