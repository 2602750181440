import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import CampaignLists from '../../../components/reports/Campaign/CampaignLists'

import OrderInvoiceDetailReport from '../../../components/reports/telephony/SelectedTelephoneyList/OrderInvoiceDetailReport'
import CampaignDispositionReport from '../../../components/reports/telephony/SelectedTelephoneyList/CampaignDispositionReport'
import CampaignLeadReport from '../../../components/reports/telephony/SelectedTelephoneyList/CampaignLeadReport'

const CampaignRoute = () => {
    return (
        <Routes>

            <Route
                path='/reports-list'
                element={
                    <>
                        <CampaignLists />
                    </>
                }
            />
            <Route
                path='/campaign-deatil-report'
                element={
                    <>
                        <OrderInvoiceDetailReport />
                    </>
                }
            />
            <Route
                path='/disposition-report'
                element={
                    <>
                        <CampaignDispositionReport />
                    </>
                }
            />
            <Route
                path='/campaign-lead-report'
                element={
                    <>
                        <CampaignLeadReport />
                    </>
                }
            />

            <Route index element={<Navigate to='/campaign/reports-list' />} />

        </Routes >
    )
}

export default CampaignRoute
