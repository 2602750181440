import React, { useEffect, useState } from "react";
import { Button, CloseButton, Modal, Row, Col } from "react-bootstrap";
import { TextField, Chip } from "@mui/material";
import { toast } from "react-toastify";
import { useGetLeadFieldsQuery } from "../../../../../Services/reports/tickets/ReportTickets";


interface IProps {
    closeFilterModal: () => void;
    dateFrom: any;
    dateTo: any;
    selectQueue: any;
    campaignSelected: any;

}

const AddEmailToReport: React.FC<IProps> = ({ closeFilterModal, dateFrom, dateTo, selectQueue, campaignSelected }) => {
    const [email, setEmail] = useState<string>("");
    const [emailList, setEmailList] = useState<string[]>([]);
    const [params, setParams] = useState<any>(null);
    const {
        data,
        isLoading,
        error
    } = useGetLeadFieldsQuery(params, {
        skip: !params,
    });

    const handleAddEmail = () => {
        if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            toast.error("Please enter a valid email address!");
            return;
        }
        if (emailList.includes(email)) {
            toast.error("Email already added!");
            return;
        }
        setEmailList((prev) => [...prev, email]);
        setEmail("");
    };

    const validateEmails = (): boolean => {
        const invalidEmails = emailList.filter(
            (email) => !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
        );
        if (invalidEmails.length > 0) {
            toast.error(`Invalid emails: ${invalidEmails.join(", ")}`);
            return false;
        }
        return true;
    };

    const handleDeleteEmail = (emailToDelete: string) => {
        setEmailList((prev) => prev.filter((e) => e !== emailToDelete));
    };

    useEffect(() => {
        if (data) {
            toast.success(data.message);
            closeFilterModal();
        }
    }, [data]);

    const sendMailHandler = () => {
        if (emailList.length === 0) {
            toast.error("Please add at least one email!");
            return;
        }
        if (!validateEmails()) {
            return; // Stop processing if there are invalid emails
        }

        if (!dateFrom) {
            toast.error('Please select from date');
            return;
        }
        if (!dateTo) {
            toast.error('Please select to date');
            return;
        }
        if (campaignSelected.length === 0) {
            toast.error('Please select campaign');
            return;
        }


        const queryParams: any = {
            gte: dateFrom,
            lte: dateTo,
            recepients: emailList
        };

        if (selectQueue !== 'All') {
            const selectedQueue = campaignSelected.find((queue: any) => queue.id === selectQueue);
            queryParams.campaign = selectedQueue?.id || selectQueue; // Use name if found, otherwise fallback to ID
        }

        setParams(queryParams);

        // Add your logic here to send the emails
    }

    return (
        <>
            <Modal show={true} onHide={closeFilterModal} centered>
                <Modal.Header>
                    <Modal.Title className="fw-bold">Enter email id</Modal.Title>
                    <CloseButton onClick={closeFilterModal} />
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-3">
                        <Col xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Email Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyDown={(e) => e.key === "Enter" && handleAddEmail()}
                                onBlur={handleAddEmail} // Trigger add email when the input loses focus
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {emailList.map((email) => (
                                <Chip
                                    key={email}
                                    label={email}
                                    onDelete={() => handleDeleteEmail(email)}
                                    style={{ margin: "5px" }}
                                />
                            ))}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>

                    <Button
                        variant="primary"
                        onClick={sendMailHandler}
                    >
                        Send
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default AddEmailToReport;
