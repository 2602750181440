import React, { useState } from "react";
import ReactModal from "react-modal";
import ActivateUser from "../../components/user/ActivateUserModal";
import LoadingSpinner from "../../components/LoadingSpinner";
import EmptyRows from "../../components/reusableComponent/EmptyRows";
import { Pagination, PaginationItem } from "@mui/material";

// Modal styling
const ActivateModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "2px solid #dde",
    maxWidth: "400px",
    margin: "auto",
    Overflow: "hidden",
    OverflowY: "auto",
  },
};

const Deactivate = ({
  getRandomColor,
  getInitials,
  capitalizeFirstLetter,
  formatDate,
  refetch,
  totalPages,
  getDeactiveUsersData,
  deactiveCurrentPage,
  setCurrentPage,
  usersLoading,
  sortingLoading,
}) => {
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);
  const [selectedActivateId, setSelectedActivateId] = useState(null);
  // Open modal
  const openActivateModal = () => setIsActivateModalOpen(true);

  // Close modal
  const closeActivateModal = () => setIsActivateModalOpen(false);

  // Handle modal activation
  const isActivateModal = (data) => {
    setSelectedActivateId(data);
    openActivateModal();
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <div className="row">
        <div className="col">
          {usersLoading || sortingLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {getDeactiveUsersData?.length ? (
                <>
                  {getDeactiveUsersData?.map((card, index) => (
                    <div className="card mt-2 my-1 p-4" key={index}>
                      <div className="row align-items-center">
                        <div className="col text-start">
                          <div className="symbol symbol-25px symbol-circle fs-2 me-3">
                            <span
                              className={`symbol-label ${getRandomColor()} text-inverse-warning fw-bold`}
                            >
                              {getInitials(card.username)}
                            </span>
                          </div>
                          <label>
                            {capitalizeFirstLetter(card.username.split("@")[0])}
                          </label>
                        </div>
                        <div className="col text-start">
                          <label>{card.id}</label>
                        </div>
                        <div className="col text-start">
                          <label>{formatDate(card.created)}</label>
                        </div>
                        <div className="col text-start">
                          <label>
                            {card.last_updated
                              ? formatDate(card.last_updated)
                              : ""}
                          </label>
                        </div>
                        <div className="col text-start">
                          <label>{capitalizeFirstLetter(card.role)}</label>
                        </div>
                        <div className="col text-start">
                          <label htmlFor="">
                            {card?.branch_user === true ? (
                              <span style={{ color: "green" }}>Yes</span>
                            ) : (
                              <span style={{ color: "red" }}>No</span>
                            )}
                          </label>
                        </div>
                        <div className="col text-start d-flex justify-content-around">
                          <button
                            className="btn btn-primary me-2"
                            title="Activate"
                            onClick={() => isActivateModal(card)}
                          >
                            Activate
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <EmptyRows value={""} />
              )}
            </>
          )}
        </div>
      </div>

      <ReactModal
        isOpen={isActivateModalOpen}
        onRequestClose={closeActivateModal}
        style={ActivateModalStyles}
        contentLabel="Activate User"
      >
        <ActivateUser
          closeActivateModal={closeActivateModal}
          userData={selectedActivateId}
          refetch={refetch}
        />
      </ReactModal>

      {getDeactiveUsersData.length > 0 ? (
        <>
          {/* Render your data here */}

          {/* MUI Pagination */}
          <Pagination
            sx={{
              display: "flex",
              justifyContent: "end",
              color: "blue",
            }}
            count={totalPages} // Total number of pages
            page={deactiveCurrentPage} // Current page number
            onChange={handlePageChange} // Page change handler
            color="primary" // Adjust as needed
            renderItem={(item) => {
              // Custom rendering logic for Pagination items
              if (item.type === "page") {
                if (
                  (deactiveCurrentPage === 1 && item.page <= 3) || // Show first 3 pages if on page 1
                  (deactiveCurrentPage === totalPages &&
                    item.page >= totalPages - 2) || // Show last 3 pages if on the last page
                  (item.page >= deactiveCurrentPage - 1 &&
                    item.page <= deactiveCurrentPage + 1) // Show current page and 1 sibling on each side
                ) {
                  return <PaginationItem {...item} />;
                }
                return null;
              }
              return <PaginationItem {...item} />;
            }}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Deactivate;
