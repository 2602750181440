
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker, createStaticRanges, defaultStaticRanges } from 'react-date-range';
import { useEffect, useRef, useState } from 'react';
import "../../../components/common/styles.scss";

const EmailCustomDate = ({ dateRange, setDateRange }) => {

    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const calendarRef = useRef(null);

    const handleSelect = (ranges: any) => {
        const { startDate } = ranges.selection;
        const today = new Date();

        const newEndDate = new Date(startDate);
        newEndDate.setDate(startDate.getDate() + 6);

        setDateRange([
            {
                startDate,
                endDate: newEndDate > today ? today : newEndDate,
                key: "selection",
            },
        ]);
    };
    const toggleCalendar = () => {
        setIsCalendarOpen(!isCalendarOpen);
    };

    const handleClickOutside = (event: any) => {
        if (calendarRef.current && !calendarRef.current.contains(event.target)) {
            setIsCalendarOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const formatDate = (date: any) => {
        return date.toLocaleDateString("en-GB");
    };

    const staticRanges = createStaticRanges(
        defaultStaticRanges.filter(
            (range) => range.label !== "This Month" && range.label !== "Last Month"
        )
    );

    return (
        <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", top: "-9px", left: "5px", background: "white" }}><p style={{ margin: "0" }}>Select Date</p></div>
            <div onClick={toggleCalendar} style={{ border: "1px solid #ccc", padding: " 9px", borderRadius: "4px", cursor: "pointer", overflow: "hidden", fontSize: "11px" }}>
                {`${formatDate(dateRange[0].startDate)} - ${formatDate(dateRange[0].endDate)}`}
            </div>
            {isCalendarOpen && (
                <div
                    ref={calendarRef}
                    style={{
                        position: "absolute",
                        zIndex: 1000,
                        padding: "5px",
                        right: 0 // Added to move the date picker to the left side of the container
                    }}
                >
                    <DateRangePicker
                        editableDateInputs={true}
                        onChange={handleSelect}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRange}
                        staticRanges={staticRanges}
                        maxDate={new Date()} // Prevent selecting a date after today
                    />
                </div>
            )}
        </div>
    );
};

export default EmailCustomDate;