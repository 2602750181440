import Apexchart from "../charts/ApexChart"
const LeadCountCard = () => {
    return (<>
      {/*<!-- Lead Count Card -->*/}
      <div className="card">
        <div className="card-body pb-0">
          <h5 className="card-title">Lead count</h5>
            <div id="budgetChart" style={{ minHeight: "400px", userSelect: "none" }} className="echart">
              <div style={{ position: "relative", padding: "0px", margin: "0px", borderWidth: "0px", cursor: "default" }}>
                <Apexchart />
              </div>
            </div>
          </div>
        </div>
      {/*<!-- End Lead Count Card -->*/}
  </>)
  }

export default LeadCountCard