import React, { useState, useEffect } from "react";
import {
    useEditExtensionDataQuery,
    useUpdateEditExtensionDataMutation,
} from "../../../../Services/Telephony/Telephony";
import { toast } from "react-toastify";

interface IPROPS {
    closeModal: any;
    editExtensionId: any;
    refetch:any
}

const EditExtensionModal: React.FC<IPROPS> = ({
    closeModal,
    editExtensionId,
    refetch
}) => {
    const [extId, setExtId] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


    const {
        data: editExtensionData,
        isLoading: editExtensionLoading,
        status: editExtensionStatus,
    } = useEditExtensionDataQuery(editExtensionId);
    const [
        trigger,
        {
            isLoading: updateEditExtensionloading,
            status: updateEditExtensionStatus,
        },
    ] = useUpdateEditExtensionDataMutation();

    useEffect(() => {
        if (editExtensionData) {
            setExtId(editExtensionData?.extension);
        }
    }, [editExtensionData, editExtensionId]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const obja = {
            extension: extId,
        };

        const response = await trigger({ body: obja, id: editExtensionId });

        try {
            if (response?.data) {
                toast.success("Edited successfully");
                closeModal();

                refetch()

                setIsButtonDisabled(true);

                setTimeout(() => {
                  setIsButtonDisabled(false);
                }, 3000);
            } else if ("data" in response?.error) {
                const errorData = response?.error?.data as {
                    extension: string[];
                };
                const errorMessage = errorData?.extension[0];
                toast.error(errorMessage);

                setIsButtonDisabled(true);

                setTimeout(() => {
                  setIsButtonDisabled(false);
                }, 3000);
            }
        } catch (error) {
            toast.error("An error occured");

            setIsButtonDisabled(true);

            setTimeout(() => {
              setIsButtonDisabled(false);
            }, 3000);
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <div>
                <label>Extension</label>
                <input
                    className="form-control"
                    value={extId}
                    onChange={(e) => setExtId(e.target.value)}
                />
            </div>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "end",
                }}
            >
                <button 
                
                type="reset"
                    className="btn btn-sm btn-white me-2"
                
                onClick={() => closeModal()}>Cancel</button>
                <button className="btn btn-primary" onClick={handleSubmit} disabled={isButtonDisabled}>
                    Save
                </button>
            </div>
        </div>
    );
};

export default EditExtensionModal;
