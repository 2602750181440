import React, { useState } from "react";
import { Loader } from "react-bootstrap-typeahead";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import {
    useWhatsAppConfigurationMutation, useUpdateWhatsAppConfigurationMutation
} from "../../../../Services/settingsAPIs/CommonAPI";
import { toast } from "react-toastify";

interface IPROS {
    closeModal: () => void;
    editModalData: any; // Assuming editModalData is an object with form fields
    modalOpenStatus: string; // Either 'add' or 'edit'
    editModal: any;
    refetch: any

}

const initialValues = {
    phone_number_id: "",
    api_version: "",
    user_access_token: "",
};

const registrationSchema = Yup.object().shape({
    phone_number_id: Yup.string().required("Phone number id is required"),
    api_version: Yup.string().required("API version is required"),
    user_access_token: Yup.string().required("User access token is required"),
});

const WhatsAppModal: React.FC<IPROS> = ({
    closeModal,
    editModalData,
    modalOpenStatus,
    refetch
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [trigger] = useWhatsAppConfigurationMutation();
    const [updateConfiguration] = useUpdateWhatsAppConfigurationMutation();
    const formik = useFormik({
        initialValues:
            modalOpenStatus === "edit" && editModalData
                ? editModalData
                : { phone_number_id: "", api_version: "", user_access_token: "" },
        enableReinitialize: true,
        validationSchema: registrationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setIsLoading(true);

                if (modalOpenStatus === "edit") {
                    // Call updateConfiguration for edit
                    const body = {
                        phone_number_id: values.phone_number_id,
                        api_version: values.api_version,
                        user_access_token: values.user_access_token,
                    };

                    const response = await updateConfiguration({
                        id: editModalData.id,
                        body,
                    });

                    refetch();
                    console.log("Update Response:", response);
                    toast.success("Configuration updated successfully!");
                } else {
                    // Call trigger for new configuration
                    const response = await trigger({
                        phone_number_id: values.phone_number_id,
                        api_version: values.api_version,
                        user_access_token: values.user_access_token,
                    });

                    refetch();
                    console.log("Create Response:", response);
                    toast.success("Configuration created successfully!");
                }

                setIsLoading(false);
                closeModal(); // Close modal on success
            } catch (error) {
                setIsLoading(false);
                console.error("Submission error:", error);
                toast.error("An error occurred while submitting the form. Please try again.");
            }
        },
    });


    return (
        <div>
            <form
                onSubmit={formik.handleSubmit}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }}
            >
                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                    <button
                        onClick={closeModal}
                        type="button"
                        className="close"
                        aria-label="Close"
                        style={{
                            border: "none",
                            background: "transparent",
                            fontSize: "20px",
                        }}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                    <p
                        style={{
                            textAlign: "center",
                            width: "100%",
                            fontSize: "15px",
                            fontWeight: "500",
                            margin: "0",
                        }}
                    >
                        {modalOpenStatus === "edit"
                            ? "Update WhatsApp Configuration"
                            : "Create WhatsApp Configuration"}
                    </p>
                </div>

                {/* Form Fields */}
                {[
                    { label: "Phone number id", name: "phone_number_id" },
                    { label: "API version", name: "api_version" },
                    { label: "User access token", name: "user_access_token" },
                ].map((field) => (
                    <div style={{ display: "flex", flexDirection: "column" }} key={field.name}>
                        <label className="required" style={{ fontSize: "14px" }}>
                            {field.label}
                        </label>
                        <input
                            {...formik.getFieldProps(field.name)}
                            className={clsx(
                                "form-control form-control-lg form-control-solid",
                                {
                                    "is-invalid": formik.touched[field.name] &&
                                        formik.errors[field.name],
                                },
                                {
                                    "is-valid": formik.touched[field.name] &&
                                        !formik.errors[field.name],
                                }
                            )}
                            style={{ padding: "10px" }}
                        />
                        {formik.touched[field.name] && formik.errors[field.name] && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block" style={{ color: "red" }}>
                                    {formik.errors[field.name]}
                                </div>
                            </div>
                        )}
                    </div>
                ))}

                {/* Submit Button */}
                <div style={{ width: "100%" }}>
                    <button
                        type="submit"
                        style={{ width: "100%", textAlign: "center" }}
                        className="btn btn-primary"
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <>
                                Submitting... <Loader />
                            </>
                        ) : (
                            modalOpenStatus === "edit" ? "Update" : "Submit"
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default WhatsAppModal;
