import React, { useEffect, useState } from "react";
import { Button, CloseButton } from "react-bootstrap";
import { useTeamsQuery } from "../../Services/settingsAPIs/CommonAPI";
import {
  useDynamicFieldQuery,
  useEditUserQuery,
  useUpdateUserMutation,
} from "../../Services/user/Users";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { capitalizeFirstLetter } from "../reusableComponent/CapitalLetter";
import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  FormControl,
} from "@mui/material";
import { PhoneInput } from "react-international-phone";
import { Loader } from "react-bootstrap-typeahead";
import { isFieldDisabled } from "../reusableComponent/FieldDisabled";
import LoadingSpinner from "../LoadingSpinner";
import './styles.scss'

interface IProps {
  closeEditModal: () => void;
  user: {
    [x: string]: any;
    role: string | number | readonly string[];
    dynamic_fields: Record<string, string>;
  };
  refetch: () => void;
}

interface ErrorState {
  [key: string]: string;
}

const EditUserModal: React.FC<IProps> = ({ closeEditModal, user, refetch }) => {
  const { data: editUser } = useEditUserQuery(user);
  const [theTeams, setTheTeams] = useState<any[]>([]);
  const [branchUser, setBranchUser] = useState(
    editUser?.branch_user ? "true" : "false"
  );
  const { data: teamsData } = useTeamsQuery({ skip: !theTeams });
  const [updateUser, { isLoading }] = useUpdateUserMutation(editUser);

  const [dynamicData, setDynamicData] = useState<any[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errors, setErrors] = useState<ErrorState>({});
  const [dynamicFields, setDynamicFields] = useState<any>("");
  const { data: DynamicFieldsData } = useDynamicFieldQuery({});
  const [initialDynamicFields, setInitialDynamicFields] = useState({});
  const [dynamicLoader, setDynamicLoader] = useState<boolean>(false)
  const [firstName, setFirstName] = useState(user.first_name)
  const [lastName, setLastName] = useState(user.last_name)
  const isAdmin = localStorage.getItem("user_role")

  const [formData, setFormData] = useState({
    role: user.role,
    dynamic_fields: { ...user.dynamic_fields },
  });


  useEffect(() => {
    if (editUser) {
      setTheTeams(editUser.teams || []);
    }
  }, [editUser]);

  useEffect(() => {

     if(firstName !== user.first_name || lastName !== user.last_name){

       setIsButtonDisabled(false)
       
     }
  }, [firstName, lastName])

  useEffect(() => {
    setBranchUser(editUser?.branch_user ? "true" : "false");
  }, [editUser]);

  useEffect(() => {
    if (user?.dynamic_fields) {
      setDynamicFields(user.dynamic_fields);
      setInitialDynamicFields(user.dynamic_fields); //For Comparison
    }
  }, [user?.dynamic_fields]);



  useEffect(() => {
    setDynamicLoader(true)
    if (DynamicFieldsData) {
      setDynamicData(DynamicFieldsData);



    }
    setDynamicLoader(false)
  }, [DynamicFieldsData, dynamicLoader]);



  const handleTeamSelection = (teamId: number) => {
    setIsButtonDisabled(false)

    if (user.role === "admin") {
      return;
    }

    const updatedTeams = [...theTeams];
    const index = updatedTeams.findIndex((team) => team.id === teamId);
    if (index !== -1) {
      updatedTeams.splice(index, 1);
    } else {
      const selectedTeam = teamsData?.find((team: any) => team.id === teamId);
      if (selectedTeam) {
        updatedTeams.push(selectedTeam);
      }
    }
    setTheTeams(updatedTeams);
  };

  const handleDropdownChange = (e: any) => {
    setIsButtonDisabled(false)

    const selectedRole = e.target.value;
    setFormData({
      ...formData,
      role: selectedRole,
    });
  };

  const theTeamIds = theTeams.map((team) => team.id);
  const filteredTeamsData = teamsData?.filter(
    (team: any) => !theTeamIds.includes(team.id)
  );
  const handleInputChange = (label, value) => {
    setIsButtonDisabled(false)

    setErrors({});
    setDynamicFields((prevState) => ({
      ...prevState,
      [label]: value,
    }));
  };
  const handleCheckboxChange = (
    event: React.ChangeEvent<{ value: any }>,
    key: string
  ) => {
    setIsButtonDisabled(false)

    setErrors({});
    setDynamicFields((prevFields) => ({
      ...prevFields,
      [key]: event.target.value,
    }));
  };

  const validateForm = () => {
    const newErrors: ErrorState = {};

    dynamicData.forEach((field: any) => {
      const fieldValue = dynamicFields[field.label];


      if (field.is_required && !fieldValue) {
        newErrors[field.label] = `${field.label} is required`;
      }

      if (field.is_required && field.field_type === "tel-phone" && fieldValue) {
        const isValidPhone =
          /^(?:\+?\d{1,3})?[ -]?\(?\d{1,4}?\)?[ -]?\d{1,4}[ -]?\d{1,4}(?: x\d{1,4})?$/.test(
            fieldValue
          );
        if (!isValidPhone) {
          newErrors[
            field.label
          ] = `${field.label} must be a valid phone number`;
        }
      }

      if (field?.field_type === "email" && field.is_required && !field.is_masked) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(dynamicFields[field.label])) {
          newErrors[field.label] = "Invalid email format";
        }
      }

      if (
        field.field_type === "check-box" &&
        field.is_required &&
        !fieldValue.length
      ) {
        newErrors[field.label] = `${field.label} is required`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill all required fields.");
      setIsButtonDisabled(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
      return;
    }

    setIsButtonDisabled(false);

    const dynamicFieldData = Object.keys(dynamicFields).reduce((acc, key) => {
      const field = dynamicData.find((field) => field.label === key);


      const isFieldEditableForRole = isAdmin === "admin" || field?.is_editable;

      // Get initial unmasked field value and current unmasked field value
      const initialFieldValue = initialDynamicFields[key]; // Unmasked initial value
      const currentFieldValue = dynamicFields[key]; // Current value (unmasked on focus)

      // Only include the field if it's editable and has been changed (unmasked values are compared)
      if (isFieldEditableForRole && currentFieldValue !== initialFieldValue) {
        // Handle date formatting
        if (field?.field_type === "date") {
          const formattedDate = new Date(currentFieldValue)
            .toISOString()
            .replace("Z", "+00:00");
          acc[key] = formattedDate;
        } else {
          acc[key] = currentFieldValue; // Store the unmasked value
        }
      }

      return acc;
    }, {});

    try {
      const obj = {
        id: editUser.id,
        teams: theTeams.map((team) => team.id),
        dynamic_fields: dynamicFieldData, // Only edited fields included
        module_permissions: [],
        role: formData.role,
        branch_user: branchUser,
        first_name: firstName,
        last_name: lastName

      };

      const response = await updateUser({ body: obj, id: editUser.id });

      if (response?.data) {
        closeEditModal();
        toast.success("User updated successfully!");
        refetch();
      } else if (response?.error) {
        const error = response.error[0];
        // const errorEmail = error?.data?.email?.[0];
        // const errorName = error?.data?.name?.[0];
        // const errorMessage = errorEmail || errorName;

        toast.error(error);
      }
    } catch (error) {
      toast.error("An unexpected error occurred");
    } finally {
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
    }
  };


  const getDataTimeFormatted = (date) => {
    if (date && date.length > 0) {
      const dateObj = new Date(date);

      // Manually format the date to ensure no timezone conversion issues
      const year = dateObj.getUTCFullYear();
      const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(dateObj.getUTCDate()).padStart(2, "0");
      const hours = String(dateObj.getUTCHours()).padStart(2, "0");
      const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
      return formattedDate;
    } else {
      return "";
    }
  };

  const filterNumericInput = (value) => {
    return value.replace(/[^0-9.]/g, "");
  };


  const handleMask = (label) => {
    const value = dynamicFields[label];
    if (value) {
      // const maskedValue = value.replace(/^(\d{3})(\d+)/, "$1*****");
      setDynamicFields((prev) => ({
        ...prev,
        [label]: value,
      }));
    }
  };

  const handleUnmask = (label) => {
    const value = initialDynamicFields[label] || dynamicFields[label];
    if (value) {
      setDynamicFields((prev) => ({
        ...prev,
        [label]: value,
      }));
    }
  };


  return (
    <>
      {dynamicLoader ? <LoadingSpinner /> : <>
        <div className="text-end">
          <CloseButton onClick={closeEditModal} />
        </div>
        <div className="text-center mb-4">
          <label className="form-label fs-4 fw-bolder text-dark card-title">
            Edit user
          </label>
        </div>

        <div className="row g-5 g-xl-8 mb-4">
          <div className="col-xl-4">
            <InputLabel className="required">First Name</InputLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={firstName}

              onChange={(e) => setFirstName(e.target.value)}
              InputLabelProps={{ required: true }}
            />
          </div>

          <div className="col-xl-4">
            <InputLabel className="required">Last Name</InputLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              InputLabelProps={{ required: true }}
            />
          </div>

          <div className="col-xl-4">
            <InputLabel className="required">Username/Email</InputLabel>
            <TextField
              type="email"
              variant="outlined"
              fullWidth
              value={editUser?.username}
              disabled
              InputLabelProps={{ required: true }}
            />
          </div>
        </div>

        <div className="row g-5 g-xl-8 mb-4">
          <div className="col-xl-4">
            <InputLabel className="required">Role</InputLabel>
            {user.role === "admin" ? (
              <TextField
                variant="outlined"
                fullWidth
                value={capitalizeFirstLetter(editUser?.role)}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <Select
                  value={formData?.role}
                  onChange={handleDropdownChange}
                  displayEmpty
                  required
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="agent">Agent</MenuItem>
                  <MenuItem value="manager">Manager</MenuItem>
                </Select>
              </FormControl>
            )}
          </div>

          {/* Branch User Field */}
          <div className="col-xl-4">
            <InputLabel className="required">Branch User</InputLabel>
            <FormControl fullWidth>
              <Select
                value={branchUser}
                onChange={(e) => setBranchUser(e.target.value)}
                displayEmpty
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </div>

          {/* Render dynamic fields */}
          {dynamicData?.map((field: any) => (

            <React.Fragment key={field?.label}>
              <div key={field.label} className="col-xl-4 mb-3">
                <InputLabel htmlFor={`dynamic_fields.${field.label}`}>
                  {field.label}
                  {field.is_required && <span className="required"></span>}
                </InputLabel>
                {field.field_type === "text" && (
                  <TextField
                    type="text"
                    id={`dynamic_fields.${field.label}`}
                    variant="outlined"
                    disabled={isFieldDisabled(field?.is_editable)}
                    style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}
                    fullWidth
                    value={dynamicFields[field.label] || ""}
                    onChange={(e) => handleInputChange(field.label, e.target.value)}
                    required={field.is_required}
                  />
                )}
                {field.field_type === "email" && (
                  <TextField
                    type="email"
                    id={`dynamic_fields.${field.label}`}
                    disabled={isFieldDisabled(field?.is_editable)}
                    style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}
                    variant="outlined"
                    fullWidth
                    value={dynamicFields[field.label] || ""}
                    onChange={(e) =>
                      handleInputChange(field.label, e.target.value)
                    }
                    required={field.is_required}
                  />
                )}
                {field.field_type === "number" && (
                  <TextField
                    type="text" // Allow masked value display
                    id={`dynamic_fields.${field.label}`}
                    variant="outlined"
                    fullWidth
                    disabled={isFieldDisabled(field?.is_editable)}
                    style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}
                    value={dynamicFields[field.label] || ""} // Masked value here
                    onChange={(e) =>
                      handleInputChange(field.label, filterNumericInput(e.target.value)) // Only allow numeric input
                    }
                    onFocus={(e) => handleUnmask(field.label)} // Unmask full number on focus
                    onBlur={(e) => handleMask(field.label)} // Mask the number on blur
                    required={field.is_required}
                  />
                )}
                {field.field_type === "date" && (
                  <TextField
                    type="date"
                    id={`dynamic_fields.${field.label}`}
                    variant="outlined"
                    disabled={isFieldDisabled(field?.is_editable)}
                    style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}
                    fullWidth
                    value={dynamicFields[field.label] || ""}
                    onChange={(e) =>
                      handleInputChange(field.label, e.target.value)
                    }
                    InputLabelProps={{ shrink: true }}
                    required={field.is_required}
                  />
                )}
                {field.field_type === "time" && (
                  <TextField
                    type="time"
                    id={`dynamic_fields.${field.label}`}
                    variant="outlined"
                    fullWidth
                    disabled={isFieldDisabled(field?.is_editable)}
                    style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}
                    value={dynamicFields[field.label] || ""}
                    onChange={(e) =>
                      handleInputChange(field.label, e.target.value)
                    }
                    InputLabelProps={{ shrink: true }}
                    required={field.is_required}
                  />
                )}
                {field.field_type === "date-time" && (
                  <TextField
                    type="datetime-local"
                    id={`dynamic_fields.${field.label}`}
                    variant="outlined"
                    disabled={isFieldDisabled(field?.is_editable)}
                    style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}
                    fullWidth
                    defaultValue={getDataTimeFormatted(
                      dynamicFields[field.label]
                    )}
                    onChange={(e) =>
                      handleInputChange(field.label, e.target.value)
                    }
                    InputLabelProps={{ shrink: true }}
                    required={field.is_required}
                  />
                )}
                {field.field_type === "text-area" && (
                  <TextField
                    id={`dynamic_fields.${field.label}`}
                    variant="outlined"
                    disabled={isFieldDisabled(field?.is_editable)}
                    style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}
                    fullWidth
                    multiline
                    value={dynamicFields[field.label] || ""}
                    onChange={(e) =>
                      handleInputChange(field.label, e.target.value)
                    }
                    required={field.is_required}
                  />
                )}
                {field.field_type === "drop-down" && (
                  <FormControl fullWidth>
                    <Select
                      labelId={`select-label-${field.label}`}
                      id={`select-${field.label}`}
                      value={dynamicFields[field.label] || ""}
                      disabled={isFieldDisabled(field?.is_editable)}
                      style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}
                      onChange={(e) =>
                        handleInputChange(field.label, e.target.value)
                      }
                      required={field.is_required}
                    >
                      <MenuItem selected disabled>
                        Select
                      </MenuItem>
                      {field?.choices?.map((option: any) => (
                        <MenuItem key={option.id} value={option.choice}>
                          {option.choice}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {field.field_type === "check-box" && (
                  <FormControl fullWidth>
                    <Select
                      labelId={`checkbox-label-${field.label}`}
                      id={`checkbox-${field.label}`}
                      disabled={isFieldDisabled(field?.is_editable)}
                      style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}
                      required={field.is_required}
                      multiple
                      value={dynamicFields[field.label] || []} // Ensure value is an array of IDs
                      onChange={(e: any) =>
                        handleCheckboxChange(e, field.label)

                      }

                      renderValue={(selected) => {
                        // Create an array of selected choice labels
                        const selectedLabels = (field?.multiple_choices || [])
                          .filter((choice) =>
                            selected.includes(String(choice.id))
                          )
                          .map((choice) => choice.choice);
                        return selectedLabels.join(", ");
                      }}
                    >
                      {field?.multiple_choices?.map((choice: any) => (
                        <MenuItem key={choice.id} value={String(choice.id)}>
                          <Checkbox
                            checked={dynamicFields[field.label]?.includes(
                              String(choice.id)
                            )}
                          />
                          <ListItemText primary={choice.choice} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}


                {field.field_type === "tel-phone" && (
                                        <div className="telephony_container">

                  <PhoneInput
                    defaultCountry="IN"
                    value={dynamicFields[field.label] || ""}
                    onChange={(value) => handleInputChange(field.label, value)}
                    required={field.is_required}
                    disabled={isFieldDisabled(field?.is_editable)}
                    style={{background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent"}}
                  />
                  </div>
                )}
                {errors[field.label] && (
                  <div className="mt-2" style={{ color: "red" }}>
                    {capitalizeFirstLetter(errors[field.label])}
                  </div>
                )}
              </div>
            </React.Fragment>
          ))}
        </div>

        <div className="card mt-4">
          <div className="card-header">
            <h5 className="card-title">Teams</h5>
          </div>
          <div className="card-body">
            <div className="row">
              {theTeams.map((team) => (
                <div key={team.id} className="col-md-3 mb-2">
                  <button
                    className={`btn btn-secondary mr-2 mb-2`}
                    onClick={() => handleTeamSelection(team.id)}
                  >
                    {team.name}
                  </button>
                </div>
              ))}
              {filteredTeamsData?.map((team: any) => (
                <div key={team.id} className="col-md-3 mb-2">
                  <button
                    className={`btn text-muted text-decoration-line-through`}
                    onClick={() => handleTeamSelection(team.id)}
                  >
                    {team.name}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="text-end mt-3 mb-4">
          <button
            type="submit"
            className="btn btn-sm btn-primary"
            onClick={submitHandler}
            disabled={isButtonDisabled} // Disable button based on state
          >
            {isLoading ? (
              <>
                Submitting... <Loader />
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </>}
    </>
  );
};

export default EditUserModal;
