import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Bin = () => {
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState<boolean>(false);

    const handleHeaderCheckboxChange = () => {
        setSelectAll(!selectAll);
        const allRows = document.querySelectorAll<HTMLInputElement>('input[type="checkbox"]');
        const selectedIds = Array.from(allRows)
            .filter(row => row.value !== "")
            .map(row => row.value);
        setSelectedRows(!selectAll ? selectedIds : []);
    };

    const handleRowCheckboxChange = (e) => {
        const selectedId = e.target.value;
        if (e.target.checked) {
            setSelectedRows(prevState => [...prevState, selectedId]);
        } else {
            setSelectedRows(prevState => prevState.filter(id => id !== selectedId));
        }
    };

    return (
        <div className="card card-flush">
            <div className="card-header align-items-center py-1 gap-2 gap-md-5">
                <span className='fs-3 text-gray-800 fw-bold'>
                    Bin
                </span>
            </div>
            <hr className='seperator mt-0'></hr>

            <div className="card-header align-items-center py-5 gap-2 gap-md-5 ">
                {/* begin::Actions */}
                <div className="d-flex flex-wrap gap-2">
                    {/* begin::Checkbox */}
                    <div className="form-check form-check-sm form-check-custom form-check-solid me-4 me-lg-7">
                        <input
                            className="form-check"
                            type="checkbox"
                            onChange={handleHeaderCheckboxChange}
                            checked={selectAll}
                        />
                    </div>
                    {/* end::Checkbox */}
                    {/* begin::Reload */}
                    <a className="btn btn-sm btn-icon btn-light btn-active-light-primary" title='Refresh'>
                        <i className="bi bi-arrow-clockwise fs-2" />
                    </a>
                    {/* end::Reload */}
                    {/* begin::Archive */}
                    <a className="btn btn-sm btn-icon btn-light btn-active-light-primary" title='Mark as read'>
                        <i className="bi bi-envelope-fill fs-2" />
                    </a>
                    {/* end::Archive */}
                    {/* begin::Delete */}
                    <a className="btn btn-sm btn-icon btn-light btn-active-light-danger" title='delete'>
                        <i className="bi bi-trash fs-2" />
                    </a>
                    {/* end::Delete */}
                </div>
                {/* end::Actions */}
                {/* begin::Actions */}
                <div className="d-flex align-items-center flex-wrap gap-2">
                    {/* begin::Search */}
                    <div className="d-flex align-items-center position-relative">
                        <i className="bi bi-search fs-3 position-absolute ms-4" />
                        <input type="text" data-kt-inbox-listing-filter="search" className="form-control form-control-sm form-control-solid mw-100 min-w-125px min-w-lg-150px min-w-xxl-200px ps-11" placeholder="Search inbox" />
                    </div>
                    {/* end::Search */}
                </div>
                {/* end::Actions */}
            </div>

            <div className="card-body p-0">
                {/* begin::Table */}
                <div id="kt_inbox_listing_wrapper" className="dt-container dt-bootstrap5 dt-empty-footer">
                    <div className="table-responsive">
                        <table className="table table-hover table-row-dashed fs-6 gy-5 my-0 dataTable" id="kt_inbox_listing">
                            <tbody>
                                <tr>
                                    <td className="ps-9">
                                        <div className="form-check form-check-sm form-check-custom form-check-solid mt-3">
                                            <input
                                                className="form-check"
                                                type="checkbox"
                                                value={1}
                                                onChange={handleRowCheckboxChange}
                                                checked={selectedRows.includes("1")}
                                            />
                                        </div>
                                    </td>
                                    <td className="min-w-80px">
                                        <a className="btn btn-icon btn-color-gray-500 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Star" data-bs-original-title="Star" data-kt-initialized={1}>
                                            <i className="bi bi-star fs-3" />
                                        </a>
                                    </td>
                                    <td className="w-150px w-md-175px">
                                        <Link to="/email/mail" className="d-flex align-items-center text-gray-900">
                                            <div className="symbol symbol-35px me-3">
                                                <span className="symbol-label" style={{ backgroundImage: 'url(/media/avatars/300-1.jpg)' }} />
                                            </div>
                                            <span className="fw-semibold">Max Smith</span>
                                        </Link>
                                    </td>
                                    <td>
                                        <div className="text-gray-900 gap-1 pt-2">
                                            <Link to="/email/mail" className="text-gray-900">
                                                <span className="fw-bold">Your iBuy.com grocery shopping confirmation</span>
                                                <span className="fw-bold d-none d-md-inline"> - </span>
                                                <span className="d-none d-md-inline text-muted">Please make sure that you have one of the following cards with you when we deliver your order...</span>
                                            </Link>
                                        </div>
                                    </td>
                                    <td className="w-100px text-end fs-7 pe-9">
                                        <span className="fw-semibold">a day ago</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="ps-9">
                                        <div className="form-check form-check-sm form-check-custom form-check-solid mt-3">
                                            <input
                                                className="form-check"
                                                type="checkbox"
                                                value={2}
                                                onChange={handleRowCheckboxChange}
                                                checked={selectedRows.includes("2")}
                                            />
                                        </div>
                                    </td>
                                    <td className="min-w-80px">
                                        <a className="btn btn-icon btn-color-gray-500 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Star" data-bs-original-title="Star" data-kt-initialized={1}>
                                            <i className="bi bi-star fs-3" />
                                        </a>
                                    </td>
                                    <td className="w-150px w-md-175px">
                                        <Link to="/email/mail" className="d-flex align-items-center text-gray-900">
                                            <div className="symbol symbol-35px me-3">
                                                <span className="symbol-label" style={{ backgroundImage: 'url(/media/avatars/300-1.jpg)' }} />
                                            </div>
                                            <span className="fw-semibold">Max Smith</span>
                                        </Link>
                                    </td>
                                    <td>
                                        <div className="text-gray-900 gap-1 pt-2">
                                            <Link to="/email/mail" className="text-gray-900">
                                                <span className="fw-bold">Your iBuy.com grocery shopping confirmation</span>
                                                <span className="fw-bold d-none d-md-inline"> - </span>
                                                <span className="d-none d-md-inline text-muted">Please make sure that you have one of the following cards with you when we deliver your order...</span>
                                            </Link>
                                        </div>
                                    </td>
                                    <td className="w-100px text-end fs-7 pe-9">
                                        <span className="fw-semibold">a day ago</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="ps-9">
                                        <div className="form-check form-check-sm form-check-custom form-check-solid mt-3">
                                            <input
                                                className="form-check"
                                                type="checkbox"
                                                value={3}
                                                onChange={handleRowCheckboxChange}
                                                checked={selectedRows.includes("3")}
                                            />
                                        </div>
                                    </td>
                                    <td className="min-w-80px">
                                        <a className="btn btn-icon btn-color-gray-500 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Star" data-bs-original-title="Star" data-kt-initialized={1}>
                                            <i className="bi bi-star fs-3" />
                                        </a>
                                    </td>
                                    <td className="w-150px w-md-175px">
                                        <Link to="/email/mail" className="d-flex align-items-center text-gray-900">
                                            <div className="symbol symbol-35px me-3">
                                                <span className="symbol-label" style={{ backgroundImage: 'url(/media/avatars/300-1.jpg)' }} />
                                            </div>
                                            <span className="fw-semibold">Max Smith</span>
                                        </Link>
                                    </td>
                                    <td>
                                        <div className="text-gray-900 gap-1 pt-2">
                                            <Link to="/email/mail" className="text-gray-900">
                                                <span className="fw-bold">Your iBuy.com grocery shopping confirmation</span>
                                                <span className="fw-bold d-none d-md-inline"> - </span>
                                                <span className="d-none d-md-inline text-muted">Please make sure that you have one of the following cards with you when we deliver your order...</span>
                                            </Link>
                                        </div>
                                    </td>
                                    <td className="w-100px text-end fs-7 pe-9">
                                        <span className="fw-semibold">a day ago</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* end::Table */}

            </div>
        </div>
    );
};

export default Bin;
