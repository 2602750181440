import React, { useEffect, useState } from "react";
import { CloseButton } from "react-bootstrap";
import {
  useGetTaskQuery,
  useUpdateTaskMutation,
} from "../../../Services/ticket/Task";
import { toast } from "react-toastify";
import { useGetSearchByEmailQuery } from "../../../Services/email/EmailTemplate";
import { Autocomplete, TextField, Chip, Stack } from "@mui/material";

const TaskEditModal = ({ task, closeEditModal, refetchTask }) => {
  const { data: taskData, refetch } = useGetTaskQuery(task.id);
  const [updateTask] = useUpdateTaskMutation({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [title, setTitle] = useState(task.title || "");
  const [description, setDescription] = useState(task.event_description || "");
  const [date, setDate] = useState(task.event_date || "");
  const [time, setTime] = useState(task.start_time || "");

  const [emails, setEmails] = useState<string[]>(task.guests || []);
  const [inputValue, setInputValue] = useState("");
  const [queryParam, setQueryParam] = useState<string | undefined>();
  const [emailOptions, setEmailOptions] = useState<any[]>([]);

  const { data, error } = useGetSearchByEmailQuery(queryParam, {
    skip: !queryParam,
  });

  useEffect(() => {
    if (data) {
      setEmailOptions(extractEmailOptions(data));
    }
  }, [data]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Create the payload object
    const obj = {
      id: task.id,
      title,
      event_date: date,
      start_time: time,
      event_description: description,
      created_by: task.created_by,
      guests: emails,
      ticket: task.ticket.id,
    };

    // Set loading state
    setIsLoading(true);

    try {
      // Make the API call
      const response = await updateTask({ body: obj, id: task.id });

      // Check for success response
      if (response.data) {
        setIsLoading(false);
        closeEditModal();
        toast.success("Success!");
        refetch();
        refetchTask();
      } else {
        toast.error("Failed to update task");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to assign. Please try again.");
      console.error(error);
    } finally {
      // Close the modal
      closeEditModal();
    }
  };

  const extractEmailOptions = (data: any) => {
    if (!data) return [];
    return data.map((item) => ({ label: item.email }));
  };

  const handleInputChange = (event, newInputValue) => {
    if (event?.type === "change") {
      const queryParams: any = {
        email: newInputValue,
      };

      setInputValue(newInputValue);
      setQueryParam(queryParams);
    }
  };

  const handleAddEmail = (email) => {
    if (email && isValidEmail(email)) {
      if (!emails.includes(email.trim())) {
        setEmails([...emails, email.trim()]);
        setInputValue("");
      }
    }
  };

  const handleDeleteEmail = (emailToDelete) => {
    setEmails(emails.filter((email) => email !== emailToDelete));
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="text-end">
        <CloseButton onClick={closeEditModal} />
      </div>
      <div className="text-center mb-4">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Edit Task
        </label>
      </div>

      <div className="w-100 mb-4">
        <label>Title</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="text"
          autoComplete="off"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <div className="w-100 mb-4">
        <label>Add Description</label>
        <textarea
          className="form-control form-control-lg form-control-solid"
          rows={3}
          autoComplete="off"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>

      <div className="w-100 mb-4">
        <label>Date</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="date"
          autoComplete="off"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
      </div>

      <div className="w-100 mb-4">
        <label>Time</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="time"
          autoComplete="off"
          value={time}
          onChange={(e) => setTime(e.target.value)}
        />
      </div>

      <div className="w-100 mb-4">
        <label>Guests</label>
        <div style={{ width: "100%" }}>
          <Autocomplete
            freeSolo
            options={emailOptions}
            getOptionLabel={(option) => option.label}
            onChange={(event, value) => {
              if (value && isValidEmail(value.label)) {
                handleAddEmail(value.label);
              }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) =>
              handleInputChange(event, newInputValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                sx={{ border: "none" }} // Remove border
                fullWidth
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleAddEmail(inputValue);
                    e.preventDefault(); // Prevent form submission or default behavior
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      flexWrap="wrap" // Allows wrapping if needed
                    >
                      {emails.map((email, index) => (
                        <Chip
                          key={index}
                          label={email}
                          onDelete={() => handleDeleteEmail(email)}
                          className="mat-chip-custom"
                          style={{ marginLeft: 5 }}
                        />
                      ))}
                    </Stack>
                  ),
                }}
              />
            )}
          />
        </div>
      </div>

      <div className="text-end mt-3 mb-4">
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          disabled={isLoading}
        >
          <span className="indicator-label">Save</span>
        </button>
      </div>
    </form>
  );
};

export default TaskEditModal;
