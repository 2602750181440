import React from "react";
import EmptyRows from "../../../../components/reusableComponent/EmptyRows";

interface IPROPS {
  getDispositionData: any;
}



const Disposition: React.FC<IPROPS> = ({ getDispositionData }) => {
  const totalDispositionCount = getDispositionData?.top_five_dispositions?.reduce(
    (total: number, item: any) => total + item.count,
    0
  );

  const ticketDashboardDisposition = "disposition"

  return (
    <div className="card card-flush h-xl-100">
      {/*begin::Header*/}
      <div className="card-header pt-5 mx-n4">
        <h3 className="card-title align-items-start flex-column">
          <div className="d-flex align-items-center mb-2">
            <span className="fs-2 fw-bold text-gray-800 me-2 lh-1 ls-n2">
              Top 5 Dispositions
            </span>
          </div>
        </h3>
      </div>
      {/*end::Header*/}

      {/*begin::Body*/}
      <div className="table-responsive fs-2 py-0 mx-5">
        <table className="table">
          <thead className="table-secondary">
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Count</th>
              <th scope="col">Percentage</th>
            </tr>
          </thead>
          <tbody>
            {getDispositionData?.top_five_dispositions?.length ? (
              <>
                {getDispositionData?.top_five_dispositions?.map(
                  (dispositionInfo: any, index: any) => {
                    const percentage = (
                      (dispositionInfo?.count / totalDispositionCount) *
                      100
                    ).toFixed(2);
                    return (
                      <tr key={index}>
                        <td>{dispositionInfo?.disposition_name}</td>
                        <td>{dispositionInfo?.count}</td>
                        <td>{percentage}%</td>
                      </tr>
                    );
                  }
                )}
              </>
            ) : (
              <tr>
                {/* Set colspan to span all table columns */}
                <td colSpan={3} className="text-center">
                  <EmptyRows value={ticketDashboardDisposition} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/*end::Body*/}
    </div>
  );
};

export default Disposition;
