import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const Activity_Active = createApi({
  reducerPath: "activity_active",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    active: builder.query({
      query: ({ currentPage, selectedActiveFilters1, isFilterOn }) => {
        // Start with the base URL
        let baseUrl = `v1/tickets/activity-tasks/?task_type=active_tasks`;
        // Conditionally append the page parameter if currentPage is defined
        if (isFilterOn === false) {
          baseUrl += `&page=${currentPage}`;
        }
        // Conditionally append filter parameters if isFilterOn is true
        const filterParams = isFilterOn ? `&filters=on${getFilterParams(selectedActiveFilters1)}` : '';

        // Combine baseUrl with filterParams
        return `${baseUrl}${filterParams}`;
      },
    }),
  }),
});



export const { useActiveQuery } = Activity_Active;

function getFilterParams(selectedFilterAndValue1: object) {
  const filterParams = Object.entries(selectedFilterAndValue1)
    .filter(([key, value]) => value !== "")
    .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  return filterParams ? `&${filterParams}` : "";
}
