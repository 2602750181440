import styled from "@emotion/styled";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

interface IProps {
  data: any; // Define a type for your data if possible
}

const TotalTickets: React.FC<IProps> = ({ data }) => {
  const [graphOptions, setGraphOptions] = useState<any>({});
  const [graphSeries, setGraphSeries] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>("All");


  const updateGraphData = (status: string) => {
    const seriesColors: { [key: string]: string } = {
      Open: "#00b300",
      WIP: "#FEB019",
      Closed: "#3ABEF9",
      Reopen: "#FE0000",
    };

    const colorOptions = {
      All: [
        { name: "Active", color: "#00b300" }, // Changed from 'Open' to 'Active'
        { name: "WIP", color: "#FEB019" },
        { name: "Closed", color: "#3ABEF9" },
        { name: "Reopen", color: "#FE0000" },
      ],
    };

    let seriesData: any[] = [];
    let xAxisCategories: string[] = [];

    if (status === "All") {
      const statuses = [
        "active_ticket_count_hourwise",
        "wip_ticket_count_hourwise",
        "closed_ticket_count_hourwise",
        "reopen_ticket_count_hourwise",
      ];

      statuses.forEach((key, index) => {
        const statusName = colorOptions["All"][index].name; // Use pre-defined names
        const dataForStatus = data?.ticket_line_graph[0][key];
        const seriesColor = colorOptions["All"][index].color;

        const formattedData = Object.entries(dataForStatus).map(
          ([hour, count]) => {
            const hourInt = parseInt(hour.split(" ")[0], 10);
            const formattedHour = hourInt.toString().padStart(2, "0") + ":00";
            if (!xAxisCategories.includes(formattedHour)) {
              xAxisCategories.push(formattedHour);
            }
            return { x: formattedHour, y: count };
          }
        );

        seriesData.push({
          name: statusName, // Use proper status name here
          data: formattedData,
          color: seriesColor,
        });
      });
    } else {
      const statusKeyMap: { [key: string]: string } = {
        Open: "active_ticket_count_hourwise",
        WIP: "wip_ticket_count_hourwise",
        Closed: "closed_ticket_count_hourwise",
        Reopen: "reopen_ticket_count_hourwise",
      };

      const statusData = data?.ticket_line_graph[0][statusKeyMap[status]];
      const seriesColor = seriesColors[status];

      const formattedData = Object.entries(statusData).map(([hour, count]) => {
        const hourInt = parseInt(hour.split(" ")[0], 10);
        const formattedHour = hourInt.toString().padStart(2, "0") + ":00";
        if (!xAxisCategories.includes(formattedHour)) {
          xAxisCategories.push(formattedHour);
        }
        return { x: formattedHour, y: count };
      });

      seriesData.push({
        name: status, // Set the selected status as name (e.g., "Open", "WIP", etc.)
        data: formattedData,
        color: seriesColor,
      });
    }


    const maxDataValue = Math.max(
      ...seriesData.flatMap((series) => series?.data?.map((point) => point.y)),
      0
    );

    setGraphOptions({
      chart: {
        type: "area",
        toolbar: {
          show: true,
          tools: {
            download: true,
          },
          export: {
            svg: {
              filename: "total_ticket",
            },
            png: {
              filename: "total_ticket",
            },
            csv: {
              filename: "total_ticket",
            },
          },
        },
      },
      xaxis: {
        categories: xAxisCategories,
        title: {
          text: "Hours",
          style: {
            fontWeight: "normal"

          }
        },
      },
      yaxis: {
        title: {
          text: "Ticket Count",
          style: {
            fontSize: "13px", // Adjust title font size
            fontWeight: "normal"
          },
        },
        labels: {
          formatter: function (val: number) {
            return Math.round(val).toString(); // Ensure proper rounding and formatting
          },
        },
        tickAmount: maxDataValue,
        min: 0,
        max: maxDataValue,
      },
      tooltip: {
        x: {
          formatter: function (val: string, opts: any) {
            const index = opts.dataPointIndex;
            const hour = xAxisCategories[index];
            return hour;
          },
        },
        y: {
          formatter: function (val: number) {
            return `Tickets: ${Math.round(val)}`; // Round ticket count in tooltip
          },
        },
      },
      dataLabels: {
        enabled: false, // Hide data labels
      },
      colors: seriesData.map((series) => series.color),
      legend: {
        formatter: function (seriesName: string) {
          return seriesName.charAt(0).toUpperCase() + seriesName.slice(1);
        },
      },
    });
    
    setGraphSeries(seriesData);
  };

  useEffect(() => {
    if (data && data?.ticket_line_graph && data?.ticket_line_graph.length > 0) {
      updateGraphData(selectedStatus);
    }
  }, [data, selectedStatus]);

  return (
    <div className="card card-xxl-stretch mb-5 mb-xxl-8">
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="fs-2 fw-bold text-gray-800 me-2 lh-1 ls-n2">
            Total tickets: {data?.total_tickets}
          </span>
        </h3>

        {/* begin::Toolbar */}
        <div className="card-toolbar" data-kt-buttons="true">
          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className="form-select"
          >
            <option value="All">All</option>
            <option value="Open">Active</option>
            <option value="WIP">WIP</option>
            <option value="Closed">Closed</option>
            <option value="Reopen">Reopen</option>
          </select>
        </div>
        {/* end::Toolbar */}
      </div>


      {/* begin::Body */}
      <div className="card-body">
        <ReactApexChart
          options={graphOptions}
          series={graphSeries}
          type="area"
          height={300}
          style={{ zIndex: "0" }}
        />
      </div>
      {/* end::Body */}
    </div>
  );
};

export default TotalTickets;
