import React from 'react'
import loader from '../assets/gif/loader.gif'

const LoadingSpinner: React.FC = () => {
  return (
    <div
      style={{
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <img
        src={loader}
        alt=''
        style={{
          width: '7rem',
          margin: '11rem auto 15rem'
        }}
      />
    </div>
  )
}

export default LoadingSpinner
