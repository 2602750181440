import { Link } from "react-router-dom";
import { useState } from "react";

const SideBarWrapper = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {setIsOpen(!isOpen);};
    return (
        <div className="d-lg-none">
            <button className="btn btn-primary w-100 rounded-0" onClick={toggleMenu}>
                Toggle Header
            </button>
            {isOpen && (
                <div className="bg-light p-4">
                <ul className="list-group">
                    <li className="list-group-item">
                    <Link to="/dashboard/email-dashboard" className="dropdown-item">
                        Email Dashboard
                    </Link>
                    </li>
                    <li className="list-group-item">
                    <Link to="/dashboard/ticket-dashboard" className="dropdown-item">
                        Ticket Dashboard
                    </Link>
                    </li>
                    <li className="list-group-item">
                    <Link
                        to="/dashboard/campaign-dashboard"
                        className="dropdown-item"
                    >
                        Campaign Dashboard
                    </Link>
                    </li>
                    <li className="list-group-item">
                    <Link to="/dashboard/rtm-dashboard" className="dropdown-item">
                         Dashboard
                    </Link>
                    </li>
                    <li className="list-group-item">
                    <Link to="/settings" className="dropdown-item d-flex align-items-center justify-content-between p-3">
                        Settings
                    </Link>
                    </li>
                </ul>
                </div>
            )}
        </div>
  )
}

export default SideBarWrapper
