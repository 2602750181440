import React, { useEffect, useState } from "react";
import { CardContent, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../LoadingSpinner";
import {
  useGetAllAssigneeBySelectedTeamIdQuery,
  useGetAllTeamsQuery,
  useGetAllWorkflowSelectedTeamIdQuery,
  useGetTicketLifeCycleReportQuery,
} from "../../../../Services/reports/tickets/ReportTickets";
import { toast } from "react-toastify";
import CsvDownloader from "react-csv-downloader";

const TicketLifeCycleReport = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetAllTeamsQuery({});
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [localLoading, setLocalLoading] = useState(false);
  const { data: assigneeData, isLoading: assigneeLoading } =
    useGetAllAssigneeBySelectedTeamIdQuery(selectedTeamId, {
      skip: !selectedTeamId,
    });
  const { data: workflowData, isLoading: workflowLoading } =
    useGetAllWorkflowSelectedTeamIdQuery(selectedTeamId, {
      skip: !selectedTeamId,
    });

  const [optionsTeams, setOptionsTeams] = useState<any[]>([]);
  const [optionsAssignee, setOptionsAssignee] = useState<any[]>([]);
  const [optionsWorkflow, setOptionsWorkflow] = useState<any[]>([]);
  const [selectedAssignee, setSelectedAssignee] = useState<string | null>(null);
  const [selectedWorkflow, setSelectedWorkflow] = useState<string | null>(null);
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [params, setParams] = useState<any>(null);

  const {
    data: lifeCycleReport,
    isLoading: lifeCycleReportLoading,
  } = useGetTicketLifeCycleReportQuery(params, {
    skip: !params,
  });

  useEffect(() => {
    if (data && data.teams?.length > 0) {
      const transformedOptions = data.teams.map((team: any) => ({
        label: team.name,
        value: team.id,
      }));
      setOptionsTeams(transformedOptions);
    }
  }, [data]);

  useEffect(() => {
    if (assigneeData) {
      const transformedAssignees = assigneeData.map((assignee: any) => ({
        label: `${assignee.username}`,
        value: assignee.id,
      }));
      setOptionsAssignee(transformedAssignees);
    }
  }, [assigneeData]);

  useEffect(() => {
    if (workflowData && Array.isArray(workflowData.results)) {
      const transformedWorkflows = workflowData.results.map(
        (workflow: any) => ({
          label: workflow.name,
          value: workflow.id,
        })
      );
      setOptionsWorkflow(transformedWorkflows);
    }
  }, [workflowData]);

  const handleTeamChange = (event: any) => {
    setSelectedTeamId(event.target.value);
  };

  const handleAssigneeChange = (event: any) => {
    setSelectedAssignee(event.target.value);
  };

  const handleWorkflowChange = (event: any) => {
    setSelectedWorkflow(event.target.value);
  };

  const handleDateFromChange = (event: any) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event: any) => {
    setDateTo(event.target.value);
  };

  const handleSubmit = () => {
    if (!dateFrom) {
      toast.error('Please select "From Date"');
      return;
    }
    if (!dateTo) {
      toast.error('Please select "To Date"');
      return;
    }
    if (!selectedTeamId) {
      toast.error('Please select "Team"');
      return;
    }

    const queryParams: any = {
      gte: dateFrom,
      lte: dateTo,
    };

    if (selectedWorkflow) {
      queryParams.action__disposition__get_ticket_name__name = selectedWorkflow;
    }
    if (selectedTeamId !== "All" && selectedTeamId) {
      queryParams.team = selectedTeamId;
    }
    if (selectedAssignee !== "All" && selectedAssignee) {
      queryParams.action__assignee = selectedAssignee;
    }

    setParams(queryParams);
  };

  const resetFilters = () => {
    setSelectedTeamId(null);
    setSelectedAssignee(null);
    setSelectedWorkflow(null);
    setDateFrom("");
    setDateTo("");
    setParams(null);
  };

  const formatRows = (data1: any[]) => {
    return data1.map((item, index) => ({
      id: index + 1,
      createdDate: item.created || "No data found",
      title: item.ticket_id || "No data found",
      created: item.created || "No data found",
      status_type: item.status || "No data found",
      description: item.agent.username || "No data found",
      "created_by.username": item.created_by.username || "No data found",
      totalTransactionTime: item.total_transaction_time || "No data found",
      priority: item.workflow_name || "No data found",
      dueDate:
        item.transaction_time.reduce(
          (acc, curr) => acc + (curr.transaction_time || "00:00:00"),
          "00:00:00"
        ) || "No data found",
    }));
  };

  const columns: GridColDef[] = [
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "title",
      headerName: "Ticket Id",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "created",
      headerName: "Last Updated",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status_type",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "description",
      headerName: "Agent",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "created_by.username",
      headerName: "Creator",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "totalTransactionTime",
      headerName: "Total Transaction Time (HH:MM:SS)",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "priority",
      headerName: "Ticket Name",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "dueDate",
      headerName: "Transaction Time (HH:MM:SS)",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const rows: any = formatRows(lifeCycleReport || []);
  const handlePaginationChange = (newPaginationModel: any) => {
    setLocalLoading(true);
    setPaginationModel(newPaginationModel);
    setTimeout(() => {
      setLocalLoading(false);
    }, 1000);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 120,
      },
    },
  };
  return (
    <div id="kt_app_content_container" className="app-container mx-5">
      <div className="card card-flush">
        {/* Card Header */}
        <div
          className="card-header align-items-center py-1 gap-2 gap-md-5"
          style={{ justifyContent: "flex-start" }}
        >
          <img
            src="https://insurance.circleonecrm.com/assets/arrow_back.svg"
            alt="Back"
            style={{ marginBottom: "-2px", cursor: "pointer" }}
            className="ng-star-inserted"
            onClick={() => {
              navigate("/reports/ticket/reports-list");
            }}
          />
          <span className="fs-3 text-gray-800 fw-bold ms-3">
            Ticket Lifecycle
          </span>
        </div>

        {/* Toolbar */}
        <div
          className="card-toolbar flex-row-fluid justify-content-start gap-5 d-flex mb-10"
          style={{ marginLeft: "30px" }}
        >
          {/* Date Filters */}
          <div className="w-100 mw-150px">
            <TextField
              id="date-from"
              label="From"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateFrom}
              onChange={handleDateFromChange}
            />
          </div>
          <div className="w-100 mw-150px">
            <TextField
              id="date-to"
              label="To"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateTo}
              onChange={handleDateToChange}
            />
          </div>

          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="team-select-label">Select Teams</InputLabel>
              <Select
                labelId="team-select-label"
                id="team-select"
                value={selectedTeamId}
                onChange={handleTeamChange}
                label="Select Teams"
              >
                {optionsTeams.map((team) => (
                  <MenuItem key={team.value} value={team.value}>
                    {team.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="workflow-select-label">Workflow</InputLabel>
              <Select
                labelId="workflow-select-label"
                id="workflow-select"
                value={selectedWorkflow}
                onChange={handleWorkflowChange}
                label="Workflow"
              >
                {optionsWorkflow.map((workflow) => (
                  <MenuItem key={workflow.value} value={workflow.value}>
                    {workflow.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="Assignee-select-label">Assignee</InputLabel>
              <Select
                labelId="Assignee-select-label"
                id="Assignee-select"
                value={selectedAssignee}
                onChange={handleAssigneeChange}
                label="Assignee"
                MenuProps={MenuProps}
              >
                {optionsAssignee.map(
                  (assignee) =>
                    assignee.label.length > 1 && (
                      <MenuItem key={assignee.value} value={assignee.value}>
                        {assignee.label}
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
          </div>

          <div className="ms-auto d-flex gap-3" style={{ marginRight: "20px" }}>
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>

            <button className="btn btn-primary">
              <CsvDownloader
                filename="Ticket Lifecycle"
                datas={rows}
                columns={columns.map((col) => ({
                  id: col.field,
                  displayName: col.headerName,
                }))}
                separator=","
                wrapColumnChar={`"`}
              >
                Download
              </CsvDownloader>
            </button>

            <button className="btn btn-link" onClick={resetFilters}>
              <i className="bi bi-funnel fs-2 text-dark"></i>
            </button>
          </div>
        </div>

        {/* DataGrid */}
        <CardContent style={{ width: "100%", minHeight: "400px" }}>
          {isLoading ||
          assigneeLoading ||
          workflowLoading ||
          lifeCycleReportLoading ? (
            <LoadingSpinner />
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              loading={localLoading}
              disableColumnMenu
              pageSizeOptions={[5, 10, 15, 20]}
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "lightgray",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-columnsContainer": {
                  backgroundColor: "lightgray",
                },
                // Target the pagination select and actions by stable component classes
                "& .MuiTablePagination-select, .MuiTablePagination-actions": {
                  marginBottom: "10px",
                },
                // Target the DataGrid overlay wrapper with its stable class
                "& .MuiDataGrid-overlayWrapper": {
                  height: "50px",
                },
              }}
            />
          )}
        </CardContent>
      </div>
    </div>
  );
};

export default TicketLifeCycleReport;
