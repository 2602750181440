import React, { useState } from "react";
import InternalMessage from "./InternalMessage";
import ExternalMessage from "./ExternalMessage";

interface NOTES {
  notesData: any;
  fetchingNotes: any;
}

const Messages: React.FC<NOTES> = ({ notesData, fetchingNotes }) => {
  const [radioValue, setRadioValue] = useState("Internal Notes");

  const notesButton = ["Internal Notes", "External Notes"];

  const buttonChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue(e.target.value);
  };

  return (
    <>
      <div className="card">
        <div className="d-flex flex-column card-body">
          <div style={{ display: "flex", gap: "30px" }}>
            {notesButton?.map((el: string) => (
              <div
                key={el}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  className="mx-2 form-check-input"
                  type="radio"
                  onChange={(e) => buttonChangeHandler(e)}
                  value={el}
                  checked={radioValue === el}
                  // disabled={el === "External Notes"} // Disable External Notes
                />
                <p style={{ margin: "0" }}>{el}</p>
              </div>
            ))}
          </div>

          {radioValue === "External Notes" ? (
            <ExternalMessage
              customerNotes={notesData?.customer_notes}
              fetchingNotes={fetchingNotes}
            />
          ) : (
            <InternalMessage
              internalNotes={notesData?.internal_notes}
              fetchingNotes={fetchingNotes}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Messages;
