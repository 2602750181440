import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import TelephonyReportList from '../../../components/reports/telephony/TelephonyReportList'
import UserLoginReport from '../../../components/reports/telephony/SelectedTelephoneyList/UserLoginReport'
import AgentPerformanceReport from '../../../components/reports/telephony/SelectedTelephoneyList/AgentPerformanceReport'
import ContactDetailReport from '../../../components/reports/telephony/SelectedTelephoneyList/ContactDetailReport'
import InboundCallDetailReport from '../../../components/reports/telephony/SelectedTelephoneyList/InboundCallDetailReport'
import BreakReport from '../../../components/reports/telephony/SelectedTelephoneyList/BreakReport'
import AbandonedCallsReport from '../../../components/reports/telephony/SelectedTelephoneyList/AbandonedCallsReport'
import OrderInvoiceDetailReport from '../../../components/reports/telephony/SelectedTelephoneyList/OrderInvoiceDetailReport'
import CampaignDispositionReport from '../../../components/reports/telephony/SelectedTelephoneyList/CampaignDispositionReport'
import CampaignLeadReport from '../../../components/reports/telephony/SelectedTelephoneyList/CampaignLeadReport'

const TelephonyRoute = () => {
    return (
        <Routes>

            <Route
                path='/reports-list'
                element={
                    <>
                        <TelephonyReportList />
                    </>
                }
            />

            <Route
                path='/phone-login'
                element={
                    <>
                        <UserLoginReport />
                    </>
                }
            />
            <Route
                path='/inbound-login'
                element={
                    <>
                        <AgentPerformanceReport />
                    </>
                }
            />

            <Route
                path='/contactdetails-report'
                element={
                    <>
                        <ContactDetailReport />
                    </>

                }
            />
            <Route
                path='/campign-wise-report'
                element={
                    <>
                        <InboundCallDetailReport />
                    </>
                }
            />
            <Route
                path='/phone-break-report'
                element={
                    <>
                        <BreakReport />
                    </>
                }
            />
            <Route
                path='/abandoned-report'
                element={
                    <>
                        <AbandonedCallsReport />
                    </>
                }
            />

            {/* <Route
                path='/campaign-deatil-report'
                element={
                    <>
                        <OrderInvoiceDetailReport />
                    </>
                }
            />
            <Route
                path='/disposition-report'
                element={
                    <>
                        <CampaignDispositionReport />
                    </>
                }
            />
            <Route
                path='/campaign-lead-report'
                element={
                    <>
                        <CampaignLeadReport />
                    </>
                }
            /> */}

            <Route index element={<Navigate to='/telephony/reports-list' />} />

        </Routes >
    )
}

export default TelephonyRoute
