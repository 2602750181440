import { useRef, useState } from "react";
import NestedDD from "./multiLevelMenu/NestedDD";

interface IProps {
  setMultiLevelConfiguration: any;
}

interface NestedThingProps {
  items: any;
  setItems: any;
  level: number;
  reload: any;
  lastAssignedFieldId: any;
}

const NestedDDconfigure: React.FC<NestedThingProps> = ({
  items,
  setItems,
  level,
  reload,
  lastAssignedFieldId,
}) => {
  function nestInTheFieldWithId(fieldId: number, menuItems: any) {
    for (let menuItem of menuItems) {
      if (menuItem.theFieldId === fieldId) {
        menuItem.submenu = [
          {
            theFieldId: lastAssignedFieldId.current + 1,
            title: "sample",
            url: "",
          },
        ];
        lastAssignedFieldId.current++;
        return menuItems;
      }
      if (menuItem.submenu) {
        const updatedSubmenu = nestInTheFieldWithId(fieldId, menuItem.submenu);
        if (updatedSubmenu) {
          menuItem.submenu = updatedSubmenu; // Update submenu if needed
          return menuItems;
        }
      }
    }
  }

  function addToTheFieldWithId(fieldId: number, menuItems: any) {
    for (let menuItem of menuItems) {
      if (menuItem.theFieldId === fieldId) {
        menuItems.push({
          theFieldId: lastAssignedFieldId.current + 1,
          title: "sample",
          url: "",
        });
        lastAssignedFieldId.current++;
        return menuItems;
      }
      if (menuItem.submenu) {
        const updatedSubmenu = addToTheFieldWithId(fieldId, menuItem.submenu);
        if (updatedSubmenu) {
          menuItem.submenu = updatedSubmenu; // Update submenu if needed
          return menuItems;
        }
      }
    }
  }

  function updateTheFieldWithId(
    fieldId: number,
    menuItems: any,
    newTitle = null
  ) {
    for (const menuItem of menuItems) {
      if (menuItem.theFieldId === fieldId) {
        if (newTitle !== null) {
          menuItem.title = newTitle;
        }
        return menuItems; // Return the entire array with the updated object
      }
      if (menuItem.submenu) {
        const updatedSubmenu = updateTheFieldWithId(
          fieldId,
          menuItem.submenu,
          newTitle
        );
        if (updatedSubmenu) {
          menuItem.submenu = updatedSubmenu; // Update submenu if needed
          return menuItems; // Return the entire array with the updated submenu
        }
      }
    }
    return null; // Field with specified ID not found
  }

  const addBlankItem = (nextToTheTheFieldWithId: number) => {
    setItems((prev: any) => {
      return addToTheFieldWithId(nextToTheTheFieldWithId, prev);
    });
    reload();
  };

  const addSubArray = (underTheFieldWithId: number) => {
    setItems((prev: any) => {
      return nestInTheFieldWithId(underTheFieldWithId, prev);
    });
    reload();
  };

  const handleInputChange = (forFieldWithId: number, e: any) => {
    setItems((prev: any) => {
      return updateTheFieldWithId(forFieldWithId, prev, e.target.value);
    });
    reload();
  };
  return (
    <div>
      {items?.map((item: any, index: number) => {
        return (
          <>
            <div
              className="d-flex align-items-center"
              key={index}
              style={{ marginLeft: 150 * level }}
            >
              <input
                className="form-control w-200px"
                type="text"
                placeholder={item.title}
                onChange={(e) => handleInputChange(item.theFieldId, e)}
              />
              <button
                className="btn btn-link"
                onClick={() => addBlankItem(item.theFieldId)}
              >
                <i className="mx-2 fs-2 bi bi-plus-lg"></i>
              </button>
              <button
                className="btn btn-link"
                onClick={() => addSubArray(item.theFieldId)}
              >
                <i className="mx-2 fs-2x bi bi-node-plus-fill"></i>
              </button>
            </div>
            {item.submenu && (
              <NestedDDconfigure
                items={item.submenu}
                setItems={setItems}
                level={level + 1}
                reload={reload}
                lastAssignedFieldId={lastAssignedFieldId}
              />
            )}
          </>
        );
      })}
    </div>
  );
};

const NestedDDConf: React.FC<IProps> = ({ setMultiLevelConfiguration }) => {
  const [items, setItems] = useState([
    { theFieldId: 0, title: "sample", url: "" },
  ]);
  const [reload, setReload] = useState(false);
  const handleReload = () => {
    setReload(!reload);
  };
  const lastAssignedFieldId = useRef(0);
  return (
    <div>
      <div className="row">
        <div className="col-6 d-flex justify-content-center bg-secondary">
          <div className="my-8 overflow-auto">
            <NestedDDconfigure
              items={items}
              setItems={setItems}
              level={0}
              reload={handleReload}
              lastAssignedFieldId={lastAssignedFieldId}
            />
          </div>
        </div>
        <div className="col-6 d-flex justify-content-center bg-primary">
          <div className="my-8">
            <NestedDD menuItemsData={items} />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col px-0">
          <button
            className="btn btn-success w-100"
            onClick={() => setMultiLevelConfiguration(items)}
          >
            Save dropdown for the field
          </button>
        </div>
      </div>
    </div>
  );
};

export default NestedDDConf;
