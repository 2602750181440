import React, { useState, useEffect } from "react";
import { useAllAccountsQuery } from "../../Services/customer/FrontendCustomer";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { Button, CloseButton, Modal } from "react-bootstrap";
import { Loader } from "react-bootstrap-typeahead";

interface IPROPS {
  closeFilterModal: any;
  selectAccount: any;
  setSelectAccount: any;
  setLiftingContactFilterData: any;
  setPage: any;
  contactDataa: any;
  liftingContactFilterData: any;
  contactCardLoading: any;
}

const ContactFilterModal: React.FC<IPROPS> = ({
  closeFilterModal,
  selectAccount,
  setSelectAccount,
  setLiftingContactFilterData,
  setPage,
  contactDataa,
  liftingContactFilterData,
  contactCardLoading,
}) => {
  const [gettingAllAccounts, setGettingAllAccounts] = useState([]);
  const [storingFilterObject, setStoringFilterObject] = useState({
    name: "",
    email: "",
    tags: "",
    createdDate: "",
  });

  const [checkedFields, setCheckedFields] = useState({
    name: false,
    email: false,
    tags: false,
    createdDate: false,
  });

  const { data: getAccounts } = useAllAccountsQuery({});

  useEffect(() => {
    if (getAccounts) {
      setGettingAllAccounts(getAccounts);
    }
  }, [getAccounts]);

  useEffect(() => {
    if (liftingContactFilterData?.filtering) {
      setStoringFilterObject(liftingContactFilterData.filtering);

      setCheckedFields({
        name: !!liftingContactFilterData.filtering.name,
        email: !!liftingContactFilterData.filtering.email,
        tags: !!liftingContactFilterData.filtering.tags,
        createdDate: !!liftingContactFilterData.filtering.createdDate,
      });
    }

    // Set selected account if available
    if (liftingContactFilterData?.account) {
      setSelectAccount(liftingContactFilterData.account);
    }
  }, [liftingContactFilterData, setSelectAccount]);

  const handleCheckboxChange = (field) => {
    setCheckedFields((prev) => {
      const updatedCheckedFields = {
        ...prev,
        [field]: !prev[field], // Toggle the checkbox state
      };

      if (!updatedCheckedFields[field]) {
        setStoringFilterObject((prevState) => ({
          ...prevState,
          [field]: "", // Reswt
        }));
      }

      return updatedCheckedFields;
    });
  };

  const accountChangeHadler = (event) => {
    const {
      target: { value },
    } = event;
    setSelectAccount(value);
  };

  const searchContactHandler = (e) => {
    e.preventDefault();
    setPage(1);

    const filterObj = {
      filtering: storingFilterObject,
      account: selectAccount,
    };

    setLiftingContactFilterData(filterObj);
    if (contactDataa) {
      closeFilterModal(true);
    }
  };

  const resetFilters = (e) => {
    e.preventDefault();

    setCheckedFields({
      name: false,
      email: false,
      tags: false,
      createdDate: false,
    });

    setSelectAccount("");
    setStoringFilterObject({
      name: "",
      email: "",
      tags: "",
      createdDate: "",
    });

    setLiftingContactFilterData({
      name: "",
      email: "",
      tags: "",
      createdDate: "",
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Modal.Title className="fw-bold">Filters</Modal.Title>
        <CloseButton onClick={closeFilterModal} />
      </div>

      {/* Checkbox for Name */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <FormControlLabel
          style={{ width: "30%", whiteSpace: "nowrap" }}
          control={
            <Checkbox
              checked={checkedFields.name}
              onChange={() => handleCheckboxChange("name")}
            />
          }
          label="Contact"
        />
        {checkedFields.name && (
          <input
            style={{ flex: "1" }}
            className="form-control"
            placeholder="Search by contact"
            value={storingFilterObject.name}
            onChange={(e) =>
              setStoringFilterObject((prevState) => ({
                ...prevState,
                name: e.target.value,
              }))
            }
          />
        )}
      </div>

      {/* Checkbox for Email */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <FormControlLabel
          style={{ width: "30%", whiteSpace: "nowrap" }}
          control={
            <Checkbox
              checked={checkedFields.email}
              onChange={() => handleCheckboxChange("email")}
            />
          }
          label="Email"
        />
        {checkedFields.email && (
          <input
            style={{ flex: "1" }}
            type="email"
            className="form-control"
            placeholder="Search by email"
            value={storingFilterObject.email}
            onChange={(e) =>
              setStoringFilterObject((prevState) => ({
                ...prevState,
                email: e.target.value,
              }))
            }
          />
        )}
      </div>

      {/* Checkbox for Created Date */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <FormControlLabel
          style={{ width: "30%", whiteSpace: "nowrap" }}
          control={
            <Checkbox
              checked={checkedFields.createdDate}
              onChange={() => handleCheckboxChange("createdDate")}
            />
          }
          label="Created date"
        />
        {checkedFields.createdDate && (
          <TextField
            id="created-date"
            type="date"
            style={{ flex: "1" }}
            className="form-control"
            InputProps={{
              inputProps: {
                style: { textTransform: "uppercase" }, // Optional: uppercase input text
              },
            }}
            fullWidth
            value={storingFilterObject.createdDate}
            onChange={(e) =>
              setStoringFilterObject((prevState) => ({
                ...prevState,
                createdDate: e.target.value,
              }))
            }
          />
        )}
      </div>

      {/* Checkbox for Tags */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <FormControlLabel
          style={{ width: "30%", whiteSpace: "nowrap" }}
          control={
            <Checkbox
              checked={checkedFields.tags}
              onChange={() => handleCheckboxChange("tags")}
            />
          }
          label="Tags"
        />
        {checkedFields.tags && (
          <input
            style={{ flex: "1" }}
            className="form-control"
            placeholder="Search by tags"
            value={storingFilterObject.tags}
            onChange={(e) =>
              setStoringFilterObject((prevState) => ({
                ...prevState,
                tags: e.target.value,
              }))
            }
          />
        )}
      </div>

      {/* Multi-select for Accounts */}
      <div style={{ width: "100%" }}>
        <label>Search by account</label>
        <select
          onChange={accountChangeHadler}
          value={selectAccount}
          className="form form-select"
        >
          <option value="">All</option>
          {gettingAllAccounts?.map((results) => (
            <option key={results?.id} value={results?.id}>
              {results?.name}
            </option>
          ))}
        </select>
      </div>

      <div style={{ display: "flex", justifyContent: "end", gap: "20px" }}>
        <Button variant="secondary" onClick={resetFilters}>
          Reset Filters
        </Button>
        <button className="btn btn-primary" onClick={searchContactHandler}>
          {contactCardLoading ? (
            <>
              Searching...
              <Loader />
            </>
          ) : (
            "Search"
          )}
        </button>
      </div>
    </div>
  );
};

export default ContactFilterModal;
