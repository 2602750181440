import React from "react";
import Chart from "react-apexcharts";
import "./Styles.css";
import { format } from "date-fns";

interface Iprops {
  emailDashboardData: any;
}

const InboxChart: React.FC<Iprops> = ({ emailDashboardData }) => {
  // Transform daily_stats to match the format required by ApexCharts
  const dailyStats = emailDashboardData?.email_stats?.daily_stats;
  const chartData = dailyStats?.map((stat) => ({
    x: format(new Date(stat?.date), "dd-MM-yyyy"), // Using date for x-axis
    y: stat.inbox_box_count, // Using inbox count for y-axis
  }));

  const options: any = {
    chart: {
      type: "line",
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
        export: {
          svg: {
            filename: "inbox_email",
          },
          png: {
            filename: "inbox_email",
          },
          csv: {
            filename: "inbox_email",
          },
        },
      },
    },
    xaxis: {
      type: "category", // Using category for date labels
      labels: {
        rotate: -45, // Rotates labels for better readability
        format: "dd MMM", // Short date format
        offsetX: 0, // Adjust horizontal position if needed
        offsetY: 10, // Adjust vertical position if needed
      },
      tickPlacement: "on", // Ensure ticks are placed correctly
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Math.floor(val); // Ensure that only whole numbers are shown
        },
      },
      min: 0, // Ensure minimum is
      forceNiceScale: true,
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
    grid: {
      show: false,
      padding: {
        left: 25, // Add padding to the left
        right: 25, // Add padding to the right
      },
    },
  };

  const series = [
    {
      name: "Inbox Count",
      data: chartData, // Use the transformed data
    },
  ];

  return (
    <div className="card card-flush h-md-50 mb-5 mb-xl-10 ">
      <div className="card-header pt-5">
        <div className="card-title d-flex flex-column">
          <div className="d-flex align-items-center">
            <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
              {emailDashboardData?.email_stats?.current_inbox}
            </span>
            <span className="text-gray-400 pt-1 fw-semibold mx-4">
              {emailDashboardData?.email_stats?.inbox_percentage_change > 0 ? (
                <>
                  <i className="bi bi-arrow-up text-success fs-2"></i>
                  {Number.isInteger(
                    emailDashboardData.email_stats.inbox_percentage_change
                  )
                    ? emailDashboardData.email_stats.inbox_percentage_change
                    : emailDashboardData.email_stats.inbox_percentage_change?.toFixed(
                        2
                      )}
                  %
                </>
              ) : emailDashboardData?.email_stats?.inbox_percentage_change <
                0 ? (
                <>
                  <i className="bi bi-arrow-down text-danger fs-2"></i>
                  {Number.isInteger(
                    emailDashboardData.email_stats.inbox_percentage_change
                  )
                    ? emailDashboardData.email_stats.inbox_percentage_change
                    : emailDashboardData.email_stats.inbox_percentage_change?.toFixed(
                        2
                      )}
                  %
                </>
              ) : emailDashboardData?.email_stats?.inbox_percentage_change ===
                0 ? (
                <span>-</span>
              ) : (
                <span>-</span>
              )}
            </span>
          </div>
          <span className="text-dark pt-1 fs-6 mb-2">Inbox</span>
        </div>
      </div>
      <div></div>
      <Chart
        options={options}
        series={series}
        type="area"
        height={220}
        className="custom-apex-chart"
      />

      {/* Add any additional content or labels here if needed */}
    </div>
  );
};

export default InboxChart;
