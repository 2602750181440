import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useBulkAssignMutation } from "../../Services/ticket/BulkAssign";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  useGetSelectedTeamUserQuery,
  useGetTeamFromQuery,
} from "../../Services/email/EmailInbox";

import {
  Modal,
  Box,
  Button,
  Select,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useTeamsQuery } from "../../Services/settingsAPIs/CommonAPI";
import { Loader } from "react-bootstrap-typeahead";

interface IProps {
  closeBulkModal: () => void;
  selectedTickets: any;
  refetch: any;
}

const BulkAssigneeModal: React.FC<IProps> = ({
  closeBulkModal,
  selectedTickets,
  refetch,
}) => {
  const userToken = JSON.parse(localStorage.getItem("user-token") || "{}");
  const teamId = userToken.teams ? userToken.teams[0].id : null;
  const [selectedTeam, setSelectedTeam] = useState<string>("");
  const [addUser, setAddUser] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [isLoading1, setIsLoading1] = useState<boolean>(false);
  const [isUserLoading, setIsUserLoading] = useState(false);

  const { data: teamsData } = useTeamsQuery({});

  const { data, error, isLoading } = useGetTeamFromQuery({ teamId });
  const {
    data: selectedTeamUser,
    error: selectedTeamUserError,
    isLoading: selectedTeamUserIsLoading,
    status: usersStatus,
  } = useGetSelectedTeamUserQuery(selectedTeam, {
    skip: !selectedTeam,
  });

  const [UpdateBulkAssignee] = useBulkAssignMutation();

  useEffect(() => {
    if (selectedTeamUser) {
      setAddUser(true);
    }
  }, [selectedTeamUser]);

  useEffect(() => {
    if (usersStatus === "pending") {
      setIsUserLoading(true);
    } else {
      setIsUserLoading(false);
    }
  }, [usersStatus]);

  const changeTeamHandler: any = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedTeam(event.target.value as string);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 120,
      },
    },
  };

  const assignHandler = async () => {
    setIsLoading1(true);
    try {
      const payload = {
        ticket_ids: selectedTickets,
        assignee_id: selectedUserId,
        team_assignee_id: selectedTeam,
      };

      const response:any = await UpdateBulkAssignee({ body: payload, selectedTickets });

      // Check for an error in the response
      if (response?.error?.status >= 400) {
        const errorMessage =
          response.error.data?.detail?.join(", ") ||
          "Failed to assign. Please try again.";
        throw new Error(errorMessage);
      }

      toast.success("Ticket successfully assigned");
      refetch();
      closeBulkModal();
    } catch (err) {
      // Display the error message from the API or a generic fallback
      const errorMessage = err.message || "Failed to assign. Please try again.";
      toast.error(errorMessage);
      console.error("Error assigning user:", err);
    } finally {
      setIsLoading1(false);
    }
  };


  return (
    <Modal
      open={true}
      onClose={closeBulkModal}
      aria-labelledby="assign-team-modal-title"
      aria-describedby="assign-team-modal-description"
    >
      <Box
        sx={{
          padding: "1rem",
          backgroundColor: "white",
          margin: "auto",
          width: "400px",
          height: "300px",
          borderRadius: "8px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
          boxShadow: 24,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={closeBulkModal}
            style={{ minWidth: "auto", padding: "0.5rem" }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
        <div>
          <div className="change-assignee-section">
            <div className="change-assignee-section--body mt-4">
              <InputLabel>Team</InputLabel>
              <FormControl variant="standard" className="full-width w-100 mt-4">
                <Select
                  value={selectedTeam}
                  onChange={changeTeamHandler}
                  displayEmpty
                  style={{ paddingLeft: "1rem" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="" disabled>
                    Select Team
                  </MenuItem>
                  {teamsData &&
                    teamsData.length > 0 &&
                    teamsData.map((team: { id: number; name: string }) => (
                      <MenuItem key={team.id} value={team.id}>
                        {team.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="mt-4">
            <Checkbox
              className="example-margin"
              checked={addUser}
              onChange={() => setAddUser(!addUser)}
              disabled={!selectedTeam}
            />
            Assign to user
          </div>
          {addUser && (
            <div>
              <div className="change-assignee-section">
                <div className="change-assignee-section--body mt-4">
                  <InputLabel>User</InputLabel>
                  {isUserLoading ? (
                    <div>
                      <Loader />
                    </div>
                  ) : (
                    <FormControl
                      variant="standard"
                      className="full-width w-100 mt-4"
                    >
                      <Select
                        value={selectedUserId}
                        onChange={(event) =>
                          setSelectedUserId(event.target.value as string)
                        }
                        displayEmpty
                        disabled={!selectedTeam}
                        MenuProps={MenuProps}
                        style={{ paddingLeft: "1rem" }}
                      >
                        <MenuItem value="" disabled>
                          Select User
                        </MenuItem>
                        {selectedTeamUser?.map(
                          (user: { id: number; username: string }) => (
                            <MenuItem key={user.id} value={user.id}>
                              {user.username}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            position: "absolute",
            bottom: ".6rem",
            left: "50%",
            transform: "translateX(-50%)",
            width: "90%",
          }}
        >
          <Button
            className="btn btn-primary w-100 "
            onClick={assignHandler}
            disabled={!selectedTeam || !addUser || !selectedUserId}
          >
            {isLoading1 ? (
              <>
                Assigning... <Loader />
              </>
            ) : (
              "Assign"
            )}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default BulkAssigneeModal;
