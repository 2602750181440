import React, { createRef, useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import TasksActionMenu from "../common/tabbedPanel/TasksActionMenu";
import {
  Box,
  Tabs,
  Tab,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { capitalizeAndFormat, capitalizeFirstLetter } from "../../reusableComponent/CapitalLetter";
import { format, parseISO } from "date-fns"; // Import format from date-fns .
import LoadingSpinner from "../../LoadingSpinner";
import EmptyRows from "../../reusableComponent/EmptyRows";
import ReactModal from "react-modal";
import {
  useChangeStatusQuery,
  useMeeQuery,
} from "../../../Services/settingsAPIs/CommonAPI";
import { useCampaignChangeStatusQuery } from "../../../Services/campign/campignList"
import ChangeStatusCampaignModal from "../../../pages/campaign/leads/ChangeStatusCampaignModal";
import ChangeUserModal from "../../ticket/ticketListInfo/ChangeUserModal";
import UserChangeModal from "../../common/tabbedPanel/UserChangeModal";
import ChangeStatusModal from "../../ticket/ChangeStatusModal";


const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
  },
};

const CampignLeadCardInfo = ({
  campign,
  refetch,
  refetchAuditTrailData,
  setGetAllStatus,
}) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [selectAll, setSelectAll] = useState(false);
  const [uiData, setUiData] = useState([]);
  const [keyState, setKeyState] = useState([]);
  const [tabLoading, setTabLoading] = useState<boolean>(false); // New state variable to track tab loading
  const [selectedStatus, setSelectedStatus] = useState("");
  const [storingTicketId, setStoringTicketId] = useState("");
  const [storingDispositionId, setStoringDispositionId] = useState("");
  const [originalStatus, setOriginalStatus] = useState("");
  const [isStatusModalOpen, setStatusModalOpen] = useState(false);
  const [selectedAllStatus, setAllSelectedStatus] = useState("");
  const [isAddNewContactModalOpen, setAddNewContactModalOpen] = useState(false);
  const [statusOptions, setStatusOptoions] = useState([])
  const { data: campaignData, refetch: refetchAuditTrail } = useCampaignChangeStatusQuery(
    campign?.campaign?.id,
    {
      skip: campign?.campaign?.id === undefined,
    }
  );



  const truncateDescription = (description, maxLength) => {
    if (description?.length > maxLength) {
      return description?.slice(0, maxLength) + "...";
    }
    return description;
  };


  const getStatusStyle = (status_type: string) => {
    switch (status_type) {
      case "active":
        return { backgroundColor: "#56d85b", color: "white" }; // $green-300
      case "closed":
        return { backgroundColor: "#f44336", color: "white" };    // $yellow-200 // $cyan-300     { backgroundColor: "#90dcf9", color: "white" }; 
      case "reopen":
        return { backgroundColor: "#56d85b", color: "white" };; // $red-200   { backgroundColor: "#f44336", color: "white" };
      case "wip":
        return { backgroundColor: "#ffeb3b", color: "black" }; // $yellow-200  { backgroundColor: "#ffeb3b", color: "black" }; 
      default:
        return { backgroundColor: "#6c757d", color: "white" }; // Default color
    }
  };

  const capitalizeFirstLetter = (string: string | undefined | null) => {
    if (!string) {
      return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 120,
      },
    },
  };


  const handleStatusChange = (campignId) => {
    setStoringDispositionId(campignId?.campaign?.campaign_name?.id);
    setStoringTicketId(campignId?.id);
  };

  const openStatusModal = () => {
    setOriginalStatus(selectedStatus);
    setStatusModalOpen(true);
  };

  const closeStatusModal = () => {
    setSelectedStatus(originalStatus);
    setStatusModalOpen(false);
    refetch()
  };

  const handlerStatus = (allStatus) => {
    setAllSelectedStatus(allStatus);
    setSelectedStatus(allStatus.status_type);
    openStatusModal();
  };

  const formatStatusData = (status) => {
    if (typeof status.status === "string") {
      // Open and Reopen statuses
      return {
        status_type: status.status_type,
        status: status.status,
      };
    } else {
      // WIP and Closed statuses
      return {
        id: status.status.id,
        status_type: status.status_type,
        status: status.status.status,
      };
    }
  };

  // const statusOptions = campaignData?.results[0]?.all_statuses?.map((status) =>
  //   formatStatusData(status)
  // );
  useEffect(() => {

    const defaultSelections = campign?.status_display

    console.log("defaultSelections", defaultSelections);

    setSelectedStatus(defaultSelections || {});
  }, [campign]);

  useEffect(() => {

    if (campaignData) {
      const statusOptions = campaignData?.results[0]?.all_statuses?.map((status) =>
        formatStatusData(status)
      );

      setStatusOptoions(statusOptions)
    }

  }, [campaignData])

  const handleStatusUpdate = () => {
    // logic to handle status update goes here
    setStatusModalOpen(false);
  };
  // useEffect(() => {
  //   setGetAllStatus(campaignData);
  // });
  const openContactModal = () => {
    setAddNewContactModalOpen(true);
  };

  const closeContactModal = () => {
    setAddNewContactModalOpen(false);
  };
  const getInitials = (name) => {
    const nameParts = name.split(" ");
    return nameParts.map((part) => part[0]).join("");
  };
  const assigneeName = campign?.assignee?.username
    ? `${campign?.assignee?.username} `
    : "Unassigned";
  const formatDate = (dateString: string) => {
    try {
      const date = parseISO(dateString); // Parse ISO date strings
      return format(date, "dd-MM-yyyy HH:mm:ss");
    } catch (error) {
      console.error("Invalid date:", dateString);
      return "Invalid date";
    }
  };

  return (
    <div className="card" id="kt_chat_messenger">
      <div className="d-flex flex-stack mb-2">
        <div className="d-flex flex-column w-100">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="p-2 text-gray-800 text-hover-primary fw-bold">
                {campign?.id}
              </div>
              <div className="p-2 fs-3 text-gray-800 text-hover-primary fw-bold">
                {campign?.title}
              </div>
              <i className="bi bi-menu-up fs-2 me-2 text-dark"></i>

            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div
                className="p-0"
                style={{
                  ...getStatusStyle(campign?.status_type),
                  width: "220px",
                  textAlign: "center",
                }}
                data-bs-toggle="tooltip"
                title={campign?.status_display}
              >
                <span className="fw-bold">
                  {truncateDescription(campign?.status_display, 25)}
                </span>
              </div>

              <div
                className="text-gray-400 text-hover-primary fw-bold mx-10"
                data-bs-toggle="tooltip"
                title={campign?.description}
              >
                {truncateDescription(campign?.description, 120)}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <span
            className="text-gray-800 fw-bold fs-4 mw-250px"
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            data-bs-toggle="tooltip"
            title={capitalizeAndFormat(
              assigneeName ?? "Unassigned"
            )}
          >
            {capitalizeAndFormat(
              assigneeName.split("@")[0] ?? "Unassigned"
            )}
          </span>
          <i
            className="fa fa-solid fa-chevron-down mx-6"
            onClick={openContactModal}
          ></i>
          <ReactModal
            isOpen={isAddNewContactModalOpen}
            onRequestClose={closeContactModal}
            style={customModalStyles}
            contentLabel="New Contact"
          >
            <UserChangeModal closeModal={closeContactModal} refetch={refetch} />
          </ReactModal>

        </div>
      </div>

      {/* Buttons */}
      <div className="card" style={{ backgroundColor: "#E4E4E47F" }}>
        <div className="d-flex">
          <div
            style={{
              borderRight: "1px solid rgba(112, 112, 112, 0.5)",
              width: "150px",
              textAlign: "center",
            }}
          >
            <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
              Assignee
            </div>
            <span className="text-center text-gray-800 text-hover-primary fw-bold">
              {capitalizeAndFormat(
                campign?.assignee?.username.split("@")[0] ??
                "Unassigned"
              )}
            </span>
          </div>

          <div
            style={{
              borderRight: "1px solid rgba(112, 112, 112, 0.5)",
              width: "150px",
              textAlign: "center",
            }}
          >
            <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
              Account name
            </div>
            <span className="text-gray-800 text-hover-primary fw-bold">
              {capitalizeFirstLetter(campign?.account_name)}
            </span>
          </div>

          <div
            style={{
              borderRight: "1px solid rgba(112, 112, 112, 0.5)",
              width: "150px",
              textAlign: "center",
            }}
          >
            <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
              Created by
            </div>
            <span className="text-gray-800 text-hover-primary fw-bold">
              {capitalizeFirstLetter(campign?.created_by?.username)}
            </span>
          </div>

          <div
            style={{
              borderRight: "1px solid rgba(112, 112, 112, 0.5)",
              width: "120px",
              textAlign: "center",
            }}
          >
            <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
              Workflow
            </div>
            <span className="text-gray-800 text-hover-primary fw-bold">
              {capitalizeFirstLetter(campign?.campaign?.campaign_name?.name)}
            </span>
          </div>

          <div
            style={{
              borderRight: "1px solid rgba(112, 112, 112, 0.5)",
              width: "150px",
              textAlign: "center",
            }}
          >
            <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
              Created at
            </div>
            <span className="text-gray-800 text-hover-primary fw-bold">
              {formatDate(campign?.created)}
            </span>
          </div>

          <div
            style={{
              borderRight: "1px solid rgba(112, 112, 112, 0.5)",
              width: "150px",
              textAlign: "center",
            }}
          >
            <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
              Current team
            </div>
            <span className="text-gray-800 text-hover-primary fw-bold">
              {capitalizeFirstLetter(campign?.campaign?.team?.name)}

            </span>
          </div>

          <div
            style={{
              borderRight: "1px solid rgba(112, 112, 112, 0.5)",
              width: "150px",
              textAlign: "center",
            }}
          >
            <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
              Mobile no.
            </div>
            <span className="text-gray-800 text-hover-primary fw-bold">
              {campign?.phone_number}
            </span>
          </div>

          {/* Change Status */}
          <div
            className="text-center d-flex align-items-center mx-5"
            style={{ minWidth: "180px" }}
          >
            <FormControl className="w-100">
              <InputLabel id="status-select-label" style={{ zIndex: 0 }}>
                Change Status
              </InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                value={selectedStatus}
                onChange={handleStatusChange}
                label="Change Status"
                MenuProps={MenuProps}
              >
                {statusOptions?.map((status) => (
                  <MenuItem
                    key={status.id}
                    value={status.status}
                    onClick={() => handlerStatus(status)}
                    data-bs-toggle="tooltip"
                    title={status?.status}
                  >
                    {truncateDescription(status?.status, 25)}
                  </MenuItem>
                ))}
              </Select>



              <ReactModal
                isOpen={isStatusModalOpen}
                onRequestClose={closeStatusModal}
                style={customModalStyles}
                contentLabel="Change Status"
              >
                <ChangeStatusCampaignModal
                  data={campaignData}
                  closeStatusModal={closeStatusModal}
                  refetch={refetch}
                  refetchAuditTrail={refetchAuditTrail}
                  selectedStatus={selectedStatus}
                  handleStatusUpdate={handleStatusUpdate}
                  // refetchAuditTrailData={
                  //   refetchAuditTrailData
                  // }
                  selectedAllStatus={selectedAllStatus}
                  storingTicketId={storingTicketId}
                  //  refetchAuditTrail={undefined}
                  refetchAuditTrailData={undefined}
                />
              </ReactModal>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampignLeadCardInfo;
