import React, { useEffect, useState, useCallback } from "react";
import TimezoneSelect, { ITimezoneOption } from "react-timezone-select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useTeamsQuery } from "../../../Services/settingsAPIs/CommonAPI";
import {
    useBusinessHoursDataQuery,
    useUpdatedBusinessHoursDataMutation,
} from "../../../Services/general/General";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import "../../contact/styles.scss";

const BusinessHours = () => {
    const defaultTimezone: ITimezoneOption = {
        value: Intl.DateTimeFormat().resolvedOptions().timeZone,
        label: Intl.DateTimeFormat().resolvedOptions().timeZone,
        offset: 0,
        abbrev: "",
        altName: "",
    };

    const [selectedTeam, setSelectedTeam] = useState("");
    const [teams, setTeams] = useState([]);
    const [selectedTimezone, setSelectedTimezone] = useState<
        ITimezoneOption | string
    >(defaultTimezone);
    const [toggleSwitch, setToggleSwitch] = useState(false);
    const [hoursData, setHoursData] = useState([]);
    const [daySwitches, setDaySwitches] = useState({
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: false,
        Sunday: false,
    });

    const { data: teamsfetched } = useTeamsQuery({});
    const { data } = useBusinessHoursDataQuery(selectedTeam, {
        skip: !selectedTeam,
    });
    const [trigger] = useUpdatedBusinessHoursDataMutation();

    useEffect(() => {
        if (teamsfetched) {
            setTeams(teamsfetched);
        }
    }, [teamsfetched]);

    useEffect(() => {
        if (data) {
            setHoursData(data.business_hours);
            if (data.business_hours.length > 0) {
                setToggleSwitch(data?.business_hours[0]?.team?.open_24_7);
                setSelectedTimezone(data.business_hours[0].team.time_zone);
            }
        }
    }, [data]);

    const handleDaySwitchChange = useCallback((day) => {
        setDaySwitches((prevSwitches) => ({
            ...prevSwitches,
            [day]: !prevSwitches[day],
        }));
    }, []);

    const handleTimeChange = useCallback((id, field, value) => {
        setHoursData((prevData) =>
            prevData.map((hour) => {
                if (hour.id === id) {
                    return {
                        ...hour,
                        [`${field}_hours`]: value.hour(),
                        [`${field}_minutes`]: value.minute(),
                    };
                }
                return hour;
            })
        );
    }, []);

    const handleFirstDropdownChange = (event) => {
        setSelectedTeam(event.target.value);
    };

    const handleToggleSwitchChange = () => {
        setToggleSwitch(!toggleSwitch);
    };

    const submitHandler = async (e) => {
        e.preventDefault();

        const formattedHoursData = hoursData.map(({ team, ...rest }) => ({
            ...rest,
            team: team.id,
            is_holiday: toggleSwitch,
        }));

        const timeZoneValue =
            typeof selectedTimezone === "string"
                ? selectedTimezone
                : selectedTimezone.value;

        const obj = {
            business_hours: formattedHoursData,
            open_24_7: toggleSwitch,
            time_zone: timeZoneValue,
        };

        const response = await trigger({ body: obj, id: selectedTeam });
        if (response?.data) {
            toast.success("Updated Successfully");
        }
    };

    const days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];

    return (
        <div className="card-toolbar flex-row-fluid justify-content-end gap-5 p-5">
            <div className="row align-items-center" style={{ gap: "10px" }}>
                <div className="row">
                    <div className="w-100 mw-200px">
                        <select
                            className="form-select select2-hidden-accessible"
                            onChange={handleFirstDropdownChange}
                            value={selectedTeam}
                        >
                            <option disabled value="">
                                Choose Teams
                            </option>
                            {teams?.map((team) => (
                                <option key={team.id} value={team.id}>
                                    {team.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                {selectedTeam && (
                    <>
                        <div className="w-100 mw-200px">
                            <TimezoneSelect
                                value={selectedTimezone}
                                onChange={(timezone) =>
                                    setSelectedTimezone(timezone)
                                }
                            />
                        </div>

                        <div className="col-md-12 col-lg-4 d-flex align-items-center">
                            <label className="form-check-label mx-4 me-3">
                                Specific hours
                            </label>
                            <div className="custom-style">
                            <div className="form-check form-switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={toggleSwitch}
                                    onChange={handleToggleSwitchChange}
                                />
                                <label className="card-text ms-3">24/7</label>
                            </div>
                        </div>
                </div>
                    </>
                )}
            </div>

            {selectedTeam && !toggleSwitch && (
                <div className="card-body py-5 mt-5">
                    {days.map((day, index) => (
                        <div key={index} className="row mb-3 align-items-center">
                            <div className="col-md-3 col-lg-1 text-start">
                                <a
                                    href="#"
                                    className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6"
                                >
                                    {day}
                                </a>
                            </div>
                            <div className="col-md-3 col-lg-1 text-start form-check form-switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id={`flexSwitchCheckChecked${index}`}
                                    checked={daySwitches[day]}
                                    onChange={() =>
                                        handleDaySwitchChange(day)
                                    }
                                />
                                <label className="form-check-label">
                                    {daySwitches[day] ? "Open" : "Close"}
                                </label>
                            </div>
                            <div className="col-md-6 col-lg-5 text-center">
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <div className="d-flex align-items-center gap-5">
                                        <TimePicker
                                            value={dayjs()
                                                .hour(
                                                    hoursData[index]
                                                        ?.start_hours || 0
                                                )
                                                .minute(
                                                    hoursData[index]
                                                        ?.start_minutes || 0
                                                )}
                                            onChange={(newValue) =>
                                                handleTimeChange(
                                                    hoursData[index]?.id,
                                                    "start",
                                                    newValue
                                                )
                                            }
                                            sx={{
                                                width: "30%",
                                                "& .MuiInputBase-root": {
                                                    width: "100%",
                                                },
                                            }}
                                        />
                                        <span>To</span>
                                        <TimePicker
                                            value={dayjs()
                                                .hour(
                                                    hoursData[index]
                                                        ?.end_hours || 0
                                                )
                                                .minute(
                                                    hoursData[index]
                                                        ?.end_minutes || 0
                                                )}
                                            onChange={(newValue) =>
                                                handleTimeChange(
                                                    hoursData[index]?.id,
                                                    "end",
                                                    newValue
                                                )
                                            }
                                            sx={{
                                                width: "30%",
                                                "& .MuiInputBase-root": {
                                                    width: "100%",
                                                },
                                            }}
                                        />
                                    </div>
                                </LocalizationProvider>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {selectedTeam && (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "start",
                        padding: "10px 20px",
                    }}
                >
                    <button className="btn btn-primary" onClick={submitHandler}>
                        Save
                    </button>
                </div>
            )}
        </div>
    );
};

export default BusinessHours;
