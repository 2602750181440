import React, { useCallback, useState, useEffect } from "react";
import Inbox from "./Inbox";
import Sent from "./Sent";
import Interval1DateRange from "../../ticket/Interval1DateRange";
import { useEmailVolumeQuery } from "../../../../Services/dashboard/dashboard";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import "../../../../pages/contact/styles.scss";

interface IPROPS {
  selectedTeam: any;
}

const SalesChart: React.FC<IPROPS> = ({ selectedTeam }) => {
  const [params, setParams] = useState({
    email_type: localStorage.getItem("email_type") || "closed", // Set default email_type from local storage or default
    start_date: "", // Default start date
    end_date: "", // Default end date
    team: selectedTeam, // Default team_id
  });
  const [interval1Dates, setInterval1Dates] = useState<{
    startDate: string;
    endDate: string;
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const [isInbox, setIsInbox] = useState<any>(
    params.email_type === "active" ? true : false
  );
  const { data: intervalData, refetch } = useEmailVolumeQuery(params, {
    skip: !params.start_date || !params.end_date || !params.team,
  });

  // Update params when team or date changes
  useEffect(() => {
    if (interval1Dates && selectedTeam) {
      setParams((prevParams) => ({
        ...prevParams,
        start_date: interval1Dates.startDate,
        end_date: interval1Dates.endDate,
        team_id: selectedTeam,
      }));
    }
  }, [interval1Dates, selectedTeam]);

  // Fetch data when params change
  useEffect(() => {
    if (params.start_date && params.end_date && params.team) {
      setLoading(true);
      refetch().finally(() => setLoading(false));
    }
  }, [params, refetch]);

  // Toggle between Inbox and Sent
  const handleSwitchToggle = () => {
    setIsInbox((prevIsInbox) => {
      const newIsInbox = !prevIsInbox;
      const newEmailType = newIsInbox ? "active" : "closed"; // Determine new email_type
      setParams((prevParams) => ({
        ...prevParams,
        email_type: newEmailType,
      }));
      localStorage.setItem("email_type", newEmailType); // Store the new email_type in local storage
      return newIsInbox;
    });
  };

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleInterval1DateChange = useCallback(
    (startDate: string, endDate: string) => {
      setInterval1Dates({
        startDate: formatDate(new Date(startDate)),
        endDate: formatDate(new Date(endDate)),
      });
    },
    []
  );

  return (
    <div className="custom-style">
      <div className="card card-flush overflow-hidden h-md-100">
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start">
            <span className="fs-2 fw-bold text-dark me-2">Email Volume</span>
            <div className="col-md-3 col-lg-2 d-flex align-items-center">
              <label className="form-check-label mx-2">Inbox</label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={handleSwitchToggle}
                  checked={isInbox} // Ensure the checkbox reflects the current state
                />
                <label className="card-text">Sent</label>
              </div>
            </div>
          </h3>
          {/* begin::Toolbar */}
          <div className="card-toolbar d-flex align-items-center">
            <Interval1DateRange onDateChange={handleInterval1DateChange} />
          </div>
          {/* end::Toolbar */}
        </div>

        <div className="card-body">
          {loading ? (
            <div className="text-center">
              <LoadingSpinner />
            </div>
          ) : isInbox ? (
            <Sent intervalData={intervalData} />
          ) : (
            <Inbox intervalData={intervalData} />
          )}
        </div>
        {/* end::Body */}
      </div>
    </div>
  );
};

export default SalesChart;
