import React, { useEffect, useState } from "react";
import {
    useDispositionQuery,
    useTicketDynamicFieldsQuery,
} from "../../Services/ticket/CreateTicket";
import { USER_TEAM_ID } from "../../constants";
import { CloseButton } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Loader } from "react-bootstrap-typeahead";


interface IPROPS {
    closeModal: any;
    storedDisposition: any;
    refetch: any;
    trigger: any;
    isLoading: any;
    ticketId: any;
    value:any

}

const DispositionTicketModal: React.FC<IPROPS> = ({
    closeModal,
    storedDisposition,
    refetch,
    trigger,
    isLoading,
    ticketId,
    value

}) => {
    const getTeamId = localStorage.getItem(USER_TEAM_ID);
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [lastSelectedId, setLastSelectedId] = useState<number | null>(null);
    const [selectedId, setSelectedId] = useState("");
    const [checkForValue, setCheckForValue] = useState(false);
    const [isEditDispositionTrue, setIsEditDispositionTrue] = useState(false);
    // const [statusModalOpen, setStatusModalOpen] = useState(false);
    const [checkValue, setCheckValue] = useState<any>([]);
    const [skipDispositionQuery, setSkipDispositionQuery] =
        useState<boolean>(true);
    const [showAdditionalField, setShowAdditionalField] = useState(false);
    const [ticketName, setTicketName] = useState<any>("");
    const [dynamicData, setDynamicData] = useState<[string, any][]>([]);

    const { data: ticketDynamicFields } = useTicketDynamicFieldsQuery({});

    const { data: Disposition } = useDispositionQuery(getTeamId);

    const [options, setOptions] = useState(Disposition || []);
    const { uuid } = useParams();


    // Initialize selectedIds based on storedDisposition
    useEffect(() => {
        if (Disposition && storedDisposition) {
            let initialSelectedIds: number[] = [];
            let currentOptions = Disposition;

            storedDisposition.forEach((storedValue: string) => {
                const matchingOption = currentOptions.find(
                    (option) => option.topic === storedValue
                );
                if (matchingOption) {
                    initialSelectedIds.push(matchingOption.id);
                    currentOptions = matchingOption.sub_topics || [];
                } else {
                    initialSelectedIds.push(-1);
                }
            });

            setSelectedIds(initialSelectedIds);
            setOptions(currentOptions);
        }
    }, [Disposition, storedDisposition]);

    useEffect(() => {
        if (options[0]?.sub_topics) {
            setCheckForValue(options[0]?.sub_topics.length === 0);
        }
    }, [options]);

    const renderDropdowns = (data, level) => {
        if (!data || data.length === 0) {
            return null;
        }

        return (
            <div className="disposition_modal_cont">
                {level > 0 && (
                    <label className=" required mt-4">Sub Disposition</label>
                )}
                <select
                    name={`Level${level}`}
                    className="form-select form-select-lg form-select-solid"
                    onChange={handleChange}
                    value={selectedIds[level] || ""}
                    disabled={value !== "nonDispositionValue" && !isEditDispositionTrue}
                    style={{
                        background: value === "nonDispositionValue" ? "" : isEditDispositionTrue
                            ? "#fbfbfb"
                            : "rgb(233 233 233)",
                    }}
                >
                    <option value="" disabled>
                        Select
                    </option>
                    {data?.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.topic}
                        </option>
                    ))}
                </select>
                {selectedIds[level] &&
                    renderDropdowns(
                        data.find((item) => item.id === selectedIds[level])
                            ?.sub_topics || [],
                        level + 1
                    )}
            </div>
        );
    };

    useEffect(() => {
        if (lastSelectedId) {
            const dispositionItem = checkValue?.find(
                (item) =>
                    item.id === lastSelectedId ||
                    item.sub_topics.some((sub) => sub.id === lastSelectedId)
            );
            setTicketName(dispositionItem?.ticket_name);

            if (dispositionItem) {
                if (dispositionItem?.sub_topics.length > 0) {
                    const matchingSubTopic = dispositionItem?.sub_topics;
                    setCheckValue(matchingSubTopic);
                    setSkipDispositionQuery(true);
                    setShowAdditionalField(false);
                } else {
                    setSkipDispositionQuery(false);
                    setShowAdditionalField(true);
                }
            }
        }
    }, [lastSelectedId]);

    useEffect(() => {
        if (ticketDynamicFields) {
            setDynamicData(ticketDynamicFields);
        }
    }, [ticketDynamicFields]);

    useEffect(() => {
        if (ticketName) {
            let filterDataById = ticketDynamicFields?.filter(
                (name) => name?.ticket_name === ticketName
            );

            setDynamicData(filterDataById);
        }
    }, [ticketDynamicFields, ticketName]);

    const handleChange = (e) => {
        setSelectedId(e.target.value);

        const { name, value } = e.target;
        const level = parseInt(name.replace("Level", ""));

        const newSelectedIds = [...selectedIds];
        newSelectedIds[level] = parseInt(value);
        newSelectedIds.length = level + 1;
        setSelectedIds(newSelectedIds);
        setLastSelectedId(parseInt(value));

        let currentOptions = Disposition;
        for (let i = 0; i < level; i++) {
            const selectedId = newSelectedIds[i];
            if (selectedId !== undefined) {
                const found = currentOptions.find(
                    (item) => item.id === selectedId
                );
                if (found) {
                    currentOptions = found.sub_topics || [];
                } else {
                    currentOptions = [];
                    break;
                }
            }
        }
        setOptions(currentOptions);

        const dispositionItem = currentOptions.find(
            (item) => item.id === parseInt(value)
        );
        if (dispositionItem) {
            if (dispositionItem.sub_topics.length > 0) {
                setCheckValue(dispositionItem.sub_topics);
                setSkipDispositionQuery(true);
                setShowAdditionalField(false);
            } else {
                setTicketName(dispositionItem.ticket_name);
                setSkipDispositionQuery(false);
                setShowAdditionalField(true);
            }
            // setErrors({});
        }
    };

    const findSelectedOption = (data, selectedId) => {
        if (!data) return null;
        for (const option of data) {
            if (option.id === selectedId) {
                return option;
            }
            const found = findSelectedOption(option.sub_topics, selectedId);
            if (found) {
                return found;
            }
        }
        return null;
    };
    const isAllLevelsSelected = () => {
        return selectedIds.every((id) => id !== undefined && id !== null);
    };

    const editHandler = async (e) => {
        e.preventDefault();

        if (!isAllLevelsSelected()) {
            toast.error("Please select a disposition for each level.");
            return;
        }

        const selectedOption = findSelectedOption(Disposition, lastSelectedId);
        if (!selectedOption || !checkForValue) {
            toast.error("Kindly fill all the disposition");
            return;
        }

        const requestBody = { disposition: lastSelectedId };

        try {
            const response = await trigger({ body: requestBody, id: uuid });
            if (response?.data) {
                toast.success("Edited Successfully");
                refetch();

                closeModal();
                // await setStatusModalOpen(true);
            } else if (response?.error) {
                const errorData = (response?.error as any)?.data?.detail?.[0];
                toast.error(errorData || "Unable to edit");
            }
        } catch (error) {
            toast.error("An error occurred");
        }
    };


    const isLastDropdownSelected =
        selectedIds.length > 0 &&
        selectedIds[selectedIds.length - 1] !== undefined;

    const editDispositionHandler = () => {
        setIsEditDispositionTrue(!isEditDispositionTrue);
    };


    return (
        <div>
            <div
                className="modal-header"
                style={{
                    paddingLeft: "5px",
                    paddingRight: "0px",
                    paddingTop: "5px",
                    borderBottom: "0px",
                }}
            >
                <h2>Dispositions</h2>
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                    }}
                >

                    {value !== "nonDispositionValue" && 
                    <i
                        className={`bi ${
                            isEditDispositionTrue
                                ? "bi-pencil-fill text-primary"
                                : "bi-pencil-fill"
                        }`}
                        onClick={editDispositionHandler}
                        data-placement="top"
                        title={isEditDispositionTrue ? "Disable" : "Enable"}
                        style={{
                            fontSize: "16px",
                            color: isEditDispositionTrue
                                ? "#0d6efd"
                                : "#6c757d",
                        }}
                    ></i>
}
                    <CloseButton onClick={closeModal} />
                </div>
            </div>
            <div>
                <label className="required">Disposition</label>

                {renderDropdowns(Disposition, 0)}
            </div>
            <div
                style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "end",
                }}
            >
                <button
                    className="btn btn-primary"
                    onClick={editHandler}
                    disabled={
                        !isLastDropdownSelected || !selectedId || !checkForValue
                    }
                    style={{
                        cursor:
                            !isLastDropdownSelected || !selectedId
                                ? "not-allowed"
                                : "pointer",
                    }}
                >
                    {isLoading ? (
                        <>
                            Saving...
                            <Loader />
                        </>
                    ) : (
                        "Save"
                    )}
                </button>
            </div>

            {/* <ReactModal
          isOpen={statusModalOpen}
          // onAfterOpen={afterOpenDispositionModal}
          onRequestClose={closeStatusModal}
          style={customModalStyles2}
          contentLabel="Disposition"
        >
          <WorkflowModal
            closeModal={closeStatusModal}
            ticketId={ticketId}
            lastSelectedId={lastSelectedId}
            ticketName={ticketName}
            dynamicData={dynamicData}
          />
        </ReactModal> */}

      
        </div>
    );
};

export default DispositionTicketModal;
