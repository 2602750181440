// contexts/TelephonyContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface TelephonyContextProps {
  serverConfig: any;
  setServerConfig: (config: any) => void;
  extensionNo: string | null;
  setExtensionNo: (ext: string | null) => void;
  userName: string | null;
  setUserName: (name: string | null) => void;
  queueNames: any[];
  setQueueNames: (queues: any[]) => void;
  setCallBreakId: (config: any) => void;
  callBreakId: any;
  setIsLoading: any;
  isLoading: any
  setCallDisconnected:any
  callDisconnected:any
  setCallActive:any
  callActive:any
  openTelephonyDispositionModal:any
  setOpenTelephonyDispositionModal:any
  setLifitinMobileNumber:any
  liftingMobileNumber:any
  setDisconnectedCallLoader:any
  disconnectedCallLoader:any
  setTelephonyContactLoading:any
  telephonyContactLoading:any
  setStoredTelephonyLoginDetails:any
  storedTelephonyLoginDetails:any
  setStoredIncomingCallContactData:any
  storedIncomingCallContactData:any
  setInboundCount:any
  inboundCount:any
  setDisconnectCallLoader:any
  disconnectCallLoader: any
  setStoringContactData:any
  storingContactData:any
  
  // CampaigLead
  setLiftingCampaignWorkflow:any
  liftingCampaignWorkflow:any
  setLiftingCampaignId:any
  liftingCampaignId:any
  
}

const TelephonyContext = createContext<TelephonyContextProps | undefined>(undefined);

export const TelephonyProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [serverConfig, setServerConfig] = useState<any>(null);
  const [extensionNo, setExtensionNo] = useState<string | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [queueNames, setQueueNames] = useState<any[]>([]);
  const [callBreakId, setCallBreakId] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [callDisconnected, setCallDisconnected] = useState(false)
  const [callActive, setCallActive] = useState(false)
  const [openTelephonyDispositionModal,setOpenTelephonyDispositionModal] = useState(false)
  const [liftingMobileNumber, setLifitinMobileNumber] = useState("");
  const [disconnectedCallLoader, setDisconnectedCallLoader] = useState(false)
  const [telephonyContactLoading, setTelephonyContactLoading] = useState(false)
  const [storedTelephonyLoginDetails, setStoredTelephonyLoginDetails] = useState("");
  const [storedIncomingCallContactData, setStoredIncomingCallContactData] = useState<any>([])
  const [inboundCount, setInboundCount] = useState('')
  const [storingContactData, setStoringContactData] = useState({})
  const [disconnectCallLoader, setDisconnectCallLoader] = useState(false)

  // Campaig Lead STate
  const [liftingCampaignWorkflow, setLiftingCampaignWorkflow] = useState('')
  const [liftingCampaignId, setLiftingCampaignId] = useState<number>()







  

  return (
    <TelephonyContext.Provider
      value={{setLiftingCampaignId,liftingCampaignId, liftingCampaignWorkflow, setLiftingCampaignWorkflow, storingContactData, setStoringContactData,disconnectCallLoader, setDisconnectCallLoader,setInboundCount,inboundCount,storedIncomingCallContactData,setStoredIncomingCallContactData,storedTelephonyLoginDetails,setStoredTelephonyLoginDetails,telephonyContactLoading,setTelephonyContactLoading,disconnectedCallLoader,setDisconnectedCallLoader,setLifitinMobileNumber,liftingMobileNumber,setOpenTelephonyDispositionModal, openTelephonyDispositionModal,setCallActive, callActive,setCallDisconnected, callDisconnected, serverConfig, setServerConfig, extensionNo, setExtensionNo, userName, setUserName, queueNames, setQueueNames, setCallBreakId, callBreakId, setIsLoading, isLoading }}
    >
      {children}
    </TelephonyContext.Provider>
  );
};

export const useTelephony = (): TelephonyContextProps => {
  const context = useContext(TelephonyContext);
  if (!context) {
    throw new Error('useTelephony must be used within a TelephonyProvider');
  }
  return context;
};
