import React from 'react'

interface IPROPS {
  closeModal:any
}


const TermsConditionModal: React.FC<IPROPS> = ({closeModal}) => {
  return (
    <div style={{marginBottom: "100px"}}>

<div style={{overflow: "scroll", height: "60vh", marginBottom: "100px", overflowX: "hidden" }}>
<h3>Terms Of Service Agreement</h3>

Effective Date:-01st November 2019
<br></br>
<br></br>

THE AGREEMENT: The use of this website and services on this website provided by BOTGO TECHNOLOGIES PRIVATE LIMITED (hereinafter referred to as “owner”) are subject to the following Terms and Conditions (hereinafter the “Terms of Service”), all parts and sub- parts of which are specifically incorporated by reference here together with the Privacy Policy. Following are the Terms of Service governing your use of www.botgo.io (the “Website”), all pages on the Website and any services provided by or on this Website (“Services”).
<br></br><br></br>

Please read these Terms of Service and Our Privacy Notice (together, these “Terms”) carefully as they form a contract between you and us and govern use of and access to the Services and the Website by You, Your Affiliates, Users and End-Users. In the event of a conflict between these Terms of Service and Our Privacy Notice, these Terms of Service shall prevail.<br></br><br></br>

By accessing or using the Service(s) or Websites, or authorizing or permitting any User or End-User to access or use the Service(s) or Websites, You agree to be bound by these Terms. If You are entering into these Terms on behalf of a company, organization or another legal entity (an “Entity”), You are agreeing to these Terms for that Entity and representing to Us that You have the authority to bind such Entity and its Affiliates to these Terms, in which case the terms, “You“, “Your” or related capitalized terms used herein shall refer to such Entity and its Affiliates. If you do not have such authority, or if you do not agree with these Terms, You must not accept these Terms and may not access or use the Service(s) or Websites.<br></br><br></br>

This Terms of Service Agreement (the “Agreement”) governs your use of this website, [Website Address] (the “Website”). This Agreement includes, and incorporates by this reference, the policies and guidelines referenced below. Owner reserves the right to change or revise the terms and conditions of this Agreement at any time by posting any changes or a revised Agreement on this Website. Owner will alert you that changes or revisions have been made by indicating on the top of this Agreement the date it was last revised. The changed or revised agreement will be effective immediately after it is posted on this website. Your use of the Website following the posting any such changes or of a revised Agreement will constitute your acceptance of any such changes or revisions. Owner encourages you to review this agreement whenever you visit the Website to make sure that you understand the terms and conditions governing use of the Website. If you do not agree to this agreement (including any referenced policies or guidelines), please immediately terminate your use of the Website. If you would like to print this agreement, please click the print button on your browser toolbar.<br></br><br></br>

Age Restriction<br></br><br></br>

You as an individual, must be of 18 (Eighteen) years of age to use this Website and Services contained in it. By using website, you represent and warrant that you are at least of 18 years of age and legally agree to this Terms and may be legally bound for any misrepresentation of age. The owner assumes no responsibility or liability for any misrepresentation of you age.<br></br><br></br>

1. Your Rights<br></br>
1. These Terms are applicable during your free trial and during your subscription to the Service(s) through a Service Plan of Your choice.<br></br>
2. Using Our Service(s): Subject to Your compliance with the terms and solely during the subscription Term, You have the limited, non-exclusive, and revocable right to access and use the Service(s) for your internal business purposes. You shall be responsible for use of the Service(s) through Your Account by any third parties. You may subscribe to one or more of the Service(s). They may be subject to separate and distinct Service Plans.<br></br>
3. Using our APIs: Where applicable, our APIs must be used according to the API Policies We implement in this regard.<br></br><br></br>

2. Your Responsibilities<br></br><br></br>
License, sublicense, sell, resell, rent, lease, transfer, assign, distribute, time share or otherwise commercially exploit or make the Service(s) available to any third party, other than Users and End-Users in furtherance of Your internal business purposes as expressly permitted by these Terms;<br></br>
1. Your use of the Service(s): You agree not to<br></br><br></br>
Use the Service(s) to Process data on behalf of any third party other than Your Users and End-Users;<br></br><br></br>
Modify, adapt, or hack the Service(s) or otherwise attempt to gain or gain unauthorized access to the Service(s) or related systems or networks;<br></br><br></br>
Falsely imply any sponsorship or association with Us;<br></br><br></br>
Use the Service(s) in any unlawful manner, including but not limited to violation of any person’s privacy rights;<br></br><br></br>
Use the Service(s) to send unsolicited communications junk mail, spam, pyramid schemes or other forms of duplicative or unsolicited messages;<br></br><br></br>
Use the Service(s) to store or transmit any content that infringes upon any person’s intellectual property rights;<br></br><br></br>
Use the Service(s) in any manner that interferes with or disrupts the integrity or performance of the Service(s) and its components;<br></br>
Attempt to decipher, decompile, reverse engineer, disassemble, reproduce, or copy or otherwise access or discover the source code or underlying program of any Software making up the Service(s);<br></br><br></br>
Use the Service(s) to knowingly post, transmit, upload, link to, send or store any content that is unlawful, racist, hateful, abusive, libelous, obscene, or discriminatory;<br></br><br></br>
Use the Service(s) to store or transmit any “protected health information” as that term is defined in 45 C.F.R. 160.103 unless expressly agreed to otherwise in writing by Us;<br></br><br></br>
Use the Service(s) to knowingly post, transmit, upload, link to, send or store any viruses, malware, Trojan horses, time bombs, or any other similar harmful software (“Malicious Software”);<br></br><br></br>
Establish a link to Our Websites in such a way as to suggest any form of association, approval or endorsement on Our part where none exists;<br></br><br></br>
Use the Service(s) for the purposes of cookie tracking, ad exchanges, ad networks, data brokerages, or sending electronic communications (including e-mail) in violation of applicable law;<br></br><br></br>
Use of the Service(s) for any purpose prohibited by applicable export laws and regulations, including without limitation, nuclear, chemical, or biological weapons proliferation, or development of missile technology;<br></br><br></br>
Try to use, or use the Service(s) in violation of these Terms.<br></br>
2. Your Account: Subject to any limitation on the number of individual users available under the service plan to which you subscribed, access and use of the service(s) is restricted to the specified number of individual users permitted under your subscription to the service(s). User has to be a business user. Each business user shall be identified using unique login information such as usernames and           passwords (“user login”) and such user login shall be used only by one individual. If you are a managed service provider and you wish to         use the same user login across accounts that you manage for your clients, you acknowledge that it is your sole responsibility to obtain           necessary consents from such clients. Without prejudice to our obligations under sections 9 and 10 of these terms, you are solely                       responsible for the confidentiality of service data and user login at your end. You should, therefore, not share your user login with any third     parties. In any event, unless you notify us of any unauthorized use or suspicious activity in your account, you are responsible for all                     activities that occur under your account. We will not be liable for any damage or loss that may result from your failure to protect your             login   information, including your password. Without limiting the foregoing, you are solely responsible for ensuring that your use of the             service(s) to store and transmit service data is compliant with all applicable laws and regulations. You also maintain all responsibility for         determining whether the service(s) or the information generated thereby is accurate or sufficient for your purposes.

3. You shall be responsible for any loss of data or attempted or actual access or use of the Service(s) through Your Account in violation of          these Terms.
4. If we inform you that a specified activity or purpose is prohibited with respect to the Service(s), you will ensure that you immediately                cease use of the Service(s) for such prohibited activity or purpose.

3. Access To The Service(S)
1. You may not be able to access or use the Service(s): During planned downtime for upgrades and maintenance to the Service(s) (of which     we will use commercially reasonable efforts to notify you in advance through Our Service(s)) (“Planned Downtime”), or during any                     unavailability caused by circumstances beyond Our reasonable control, such as, but not limited to, acts of God, acts of government, acts     of terror or civil unrest, technical failures beyond Our reasonable control (including, without limitation, inability to access the internet), or         acts undertaken by third parties, including without limitation, distributed denial of service attacks..
2. We will use commercially reasonable efforts to schedule Planned Downtime for weekends and other off-peak hours.

4. Changes To The Service(S) And Websites
1. Our service(s): We may update the service(s) from time to time and you may receive notifications of such upgrades, enhancements or           updates (“updates”). any new or modified features added to or augmenting or otherwise modifying the service(s) or other updates,                 modifications or enhancements to the service(s) are also subject to these terms and we reserve the right to deploy updates at any time.
2.Websites: We may also change content on our websites at any time. However, please note that any of the content on our websites may         be  out of date at any given time, and we are under no obligation to update it. For clarity, this sub-section refers to our websites excluding     the service(s). We may discontinue or change any part of our websites, which does not affect the service(s), without notifying you. Our             websites may contain links to websites, content and resources provided by third parties (“third party links”). These third party links are               governed by their own terms and privacy policies and you agree that we have no control over these third party links and are not                       responsible for your access or use of these third party links.

5. Intellectual Property Rights
1. Ownership of IPR: Except for the rights granted to you under section 1, all rights, title and interest in and to all our patents, inventions,                   copyrights, trademarks, domain names, trade secrets, know-how and any other intellectual property and/or proprietary rights in or                   related    to the service(s), including the websites, and any part of it (collectively, “intellectual property rights”) shall belong to and remain          exclusively    with us. We are the owner or the licensee of all intellectual property rights in our websites, and the content or material                    published on it.   Those works are protected by copyright laws and treaties around the world. You must not use any part of the content on      our websites for commercial purposes without obtaining a license to do so from us or our licensors. Further, we claim no intellectual                property rights over the  content you upload or provide to the service(s).
2. Grant of License to us: We shall have a royalty-free, worldwide, transferable, sub-licensable, irrevocable and perpetual license to                         incorporate into the Service(s) or Websites or otherwise use any suggestions, enhancement requests, recommendations or other                     feedback we receive from you.
3. Grant of License to You: Our product and service names, and logos used or displayed on the Service(s) or Websites are our registered or         unregistered trademarks (collectively, “Marks”), and you may only use such Marks to identify you as a user of the Service(s) You have                 subscribed to.
4.  Reservation of Rights: All rights not expressly provided to you herein are reserved.

6. Other Services:
Certain other services (“Other Services”) such as integrations, Apps and Custom Apps may be available to you through the Market Place or other forums where applications are developed for their integration with the Service(s). These Other Services are governed by their own terms and privacy policies and you agree that we are not responsible for your use of these other services where you choose to enable these other services and integrate them into Our Service(s). By enabling the other services, You understand and agree that we do not provide any warranties whatsoever for other services and we are not liable for any damage or loss caused or alleged to be caused by or in connection with your enablement, access or use of any such other services, or your reliance on the privacy practices, data security processes or other policies of such other services. You understand that we are not responsible for providing technical support for other services and that we are not responsible for the data hosting and data transfer practices followed by providers of such Other Services. To this extent, you shall address any comments, queries, complaints or feedback about such Other Services to the respective developers or publishers as specified in the Market Place or other forums.

7. Plan Modifications, Biling And Payments
Service Plans:-
There are different subscription Plans which we provide to our users depending upon their needs and the details of plans are updated on www.botgo.io/pricing

Subscription To A Plan: –
Any of the business user can subscribe to the plans by creating the unique account. The account can be created by entering user id and password the user id should be the e-mail id of the business user and password should be unique combination of six characters. For 15 days the services shall be free of charge and on completion of the 15 days the user will have to pay the requisite service charges which may vary from one service to another service.

Subscription Charges:
Unless otherwise specified in the Supplementary terms, except during your free trial, all charges associated with Your Account (“Subscription Charges”) are due in full and payable in advance, in accordance with Section 7.2, when you subscribe to the Service(s). Unless specified otherwise in a Form, the Subscription Charges are based on the Service Plans you choose and are payable in full until you terminate Your Account in accordance with Section 8. You will receive a receipt upon each receipt of payment by us. You may also obtain a payment receipt from within the Service(s).

Payment Methods:
You may pay the subscription charges through your credit card, or other accepted payment methods as specified in a form. For credit card payments, your payment is due immediately upon your receipt of our invoice. You hereby authorize us or our authorized agents, as applicable, to bill your credit card upon your subscription to the service(s) (and any renewal thereof). For payments through other accepted methods, your payment is due within 30 days of our invoice date unless otherwise stated in a form.

Renewal:
Your subscription to the service(s) will renew automatically for a subscription term equivalent in length to the then expiring subscription term. Unless otherwise provided for in any form, the subscription charges applicable to your subscription to the service(s) for any such subsequent subscription term shall be our standard subscription charges for the service plan to which you have subscribed as of the time such subsequent subscription term commences. You acknowledge and agree that, unless you terminate your account in accordance section 8, your credit card will be charged automatically for the applicable subscription charges.

We may use a third party service provider to manage credit card and other payment processing; provided, that such service provider is not permitted to store, retain or use Your payment account information except to process your credit card and other payment information for us. You must notify us of any change in your credit card or other payment account information, either by updating your Account or by e-mailing us at info@botgo.io

Refunds:
Unless otherwise specified in these terms or a form or a service plan, all subscription charges are non-refundable. No refunds shall be issued for partial use or non-use of the service(s) by you provided however you shall be eligible for a pro-rated refund of the subscription charges for the remainder of the subscription term if you terminate your account as a result of a material breach of these terms by us.

Late Payments/Non-Payment Of Subscription Charges:
We will notify you if we do not receive payment towards the Subscription Charges within the due date for your Account. For payments made through credit cards, we must receive payments due within a maximum of five (5) days from the date of our notice and for payments through other accepted methods; we must receive payments within a maximum of fifteen (15) days from the date of our notice. If we do not receive payment within the foregoing time period, in addition to our right to other remedies available under law, We may

1. Charge an interest for late payment @ 2% per month and/or;
2. Suspend Your access to and use of the Service(s) until We receive Your payment towards the Subscription Charges as specified                   herein   and/or;
3. Terminate Your Account in accordance with Section 8.2.

Upgrades And Downgrades:
You may upgrade or downgrade within a service plan or between two service plans. You understand that downgrading may cause loss of content, features, or capacity of the service(s) as available to you before downgrading your account. We will not be liable for such loss. When you upgrade or downgrade, the new subscription charges become immediately applicable. Upon upgrade, the new subscription charges for the subsisting month would be charged on pro-rated basis and your credit card will be charged automatically. Subsequent months will be charged in full according to the new subscription charges. Upon downgrade, you will be offered a refund for the payment made for the subsisting month in the form of credits credited to your account. These credits will be offset against the new subscription charges payable in the subsequent months.

Applicable Taxes:
Unless otherwise stated, the subscription charges do not include any taxes, levies, duties or similar governmental assessments, including value-added, sales, use or withholding taxes assessable by any local, state, provincial or foreign jurisdiction (collectively “taxes”). You are responsible for paying the taxes that would be levied against you by government authorities. We will invoice you for such taxes if we believe we have a legal obligation to do so and you agree to pay such taxes if so invoiced.

User Benefits:
Apart from the credits provided to you when you downgrade, we may, at our sole discretion, offer you certain benefits such as discounts on subscription charges, extension in subscription term for no extra payments from you, with regard to the service(s). Users are provided 15 days free trial .These benefits are specific to your account and the service(s) identified while offering these benefits. They are not transferrable. The benefits may have an expiry date. If they do not have an expiry date, they will expire upon completion of twelve (12) months from their date of offer.

8. Suspension And Termination
We shall not be liable to you or any other third party for suspension or termination of your Account, or access to and use the Service(s), if such suspension or termination is in accordance with these Terms.

Free Trial Customers:
The free trial service will be available to user for a period of 15 days and after the expiry of the said period the service shall be chargeable as per rates mentioned in the plan which you have subscribed

If you are on a free trial for any of Our Service(s), Your Account may be suspended or terminated in the following manner:

1. We may suspend your access to and use of Your Account or the Service(s) if you are in violation of the Terms. We will notify You of Your     activities that violate these Terms and, at our sole discretion, provide you with a period of fifteen (15) days (“Cure Period”) to cure or             cease such activities. If you do not cure or cease such activities within said Cure Period or if we believe that your breach of these                 Terms   cannot be cured, Your Account shall be terminated and all associated Service Data shall be deleted immediately and                      permanently.
2. You may terminate your Account at any time on or before the expiry of your free trial. In such cases, all associated Service Data shall          be deleted immediately and permanently.
3.Where you do not terminate Your Account or renew Your Account on or before the expiry of your free trial, we may suspend Your                 Account. We shall retain any associated Service Data for a period of 6 months beyond which Your Account shall be terminated and all     associated Service shall be deleted immediately and permanently

Customers On A Service Plan:
If you are on a Service Plan for any of Our Service(s), Your Account may be suspended or terminated in the following manner:

1.  In addition to suspension for late payment or non-payment of Subscription Charges, We may suspend your access to and use of Your      Account or the Service(s) if you are in violation of the Terms. We will notify You of Your activities that violate these Terms and, at our           sole  discretion, provide you with a period of fifteen (15) days (“Cure Period”) to cure or cease such activities. If you do not cure or cease     such activities within said Cure Period or if we believe that your breach of these Terms cannot be cured, your Account shall be                     terminated. Any associated Service Data shall be retained for a period of 14 days from the date of termination of Your Account beyond     which it shall be deleted during the normal course of operation.
2. You may elect to terminate your account at any time from within our service(s), if you pay for your account through credit card. If                payment for your account is made through other accepted payment methods as specified in the form, you may request to terminate      your account by writing to info@botgo.io Any associated service data shall be retained for a period of 14 days from the date of              termination of your account beyond which it shall be deleted during the normal course of operation.
3. We may suspend your account upon expiry or non-renewal of your subscription term. We shall retain any associated service data for        a period of 6 months beyond which your account shall be terminated and all associated service shall be deleted immediately and            permanently.

Effect Of Terminating Your Account:
1. Data export: We strongly recommend that you export all service data before you terminate your account. In any event, following the        termination of your account either by you or us, unless otherwise specified elsewhere herein or in the supplemental terms, service data    will be retained or deleted in accordance with sections 8.2 or 8.3 as applicable to you. Where the service data is retained as described      herein, you may contact us within such data retention period to export your service data. Service data cannot be recovered once it is        deleted. Further, when service data is migrated from one data center to another upon your request, we shall delete service data from       the original data center after 14 days from such migration.
2.Charges: If your account is terminated in accordance with sections 8.2 or 8.3 of these terms, in addition to other amounts you may            owe us, you must immediately pay any then unpaid subscription charges associated with the remainder of such subscription term,          unless waived by us in writing. This amount will not be payable by you, or you may be eligible for a pro-rated refund of the subscription     charges, as the case may be, where you terminate your subscription to the service(s) or terminate your account as a result of a                 material breach of these terms by us, provided that you provide notice of such breach to us and afford us not less than thirty (30) days     to reasonably cure such breach.


9. Confidentiality
1. If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security          procedures, you must treat such information as confidential. You must not disclose it to any third party. We have the right to disable          any   user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion, you have    failed to comply with any of the provisions of these terms..
2.Confidentiality obligations: Each of us will protect the other’s Confidential Information from unauthorized use, access or disclosure in         the same manner as each of us protects our own Confidential Information, and in any event, no less than reasonable care. Except as       otherwise expressly permitted pursuant to these Terms, each of us may use the other’s Confidential Information solely to exercise our       respective rights and perform our respective obligations under these Terms and shall disclose such Confidential Information solely to       those of our respective employees, representatives and agents who have a need to know such Confidential Information for such               purposes and who are bound to maintain the confidentiality of, and not misuse, such Confidential Information. The provisions of this           sub-section shall supersede any non-disclosure agreement by and between you and us entered prior to these Terms that would               purport to address the confidentiality of Service Data and such agreement shall have no further force or effect with respect to Service     Data.


10. Data Privacy And Security
Security Of Service Data:
We use appropriate technical and organizational measures to protect the Service Data that we Process. The measures we use are designed to provide a level of security appropriate to the risk of processing your Service Data.

You understand that we shall Process Service Data in accordance with Applicable Data Protection Laws You acknowledge and agree that we may access or disclose information about you, Your Account, Users or End-Users, including Service Data, in order to:

1.  Comply with the law or respond to lawful requests or legal process;
2. Protect Companies’ or our customers’ or partners’ rights or property, including enforcement of these Terms or other policies                          associated  with the Service(s);
3. Act on a good faith belief that such disclosure is necessary to protect personal safety or avoid violation of applicable law or                          regulation.  Further, at our sole discretion, any suspected fraudulent, abusive, or illegal activity by you may be referred to law                          enforcement authorities.

11. Data Migration
During Your Subscription Term, You may request us to import data into Your Account (“Data Migration”). You hereby understand and acknowledge that we may access and process your data in connection with providing you support during such Data Migration.

12. Communications From Us
We may contact you directly via e-mail to notify you if

1.  You are in violation of these Terms;
2. A specific activity or purpose is prohibited with respect to the Service(s), so that you immediately cease use of the Service(s) for such        prohibited activity or purpose; or
3. You maintain an exceptionally high number of Users, an unusually high monthly ticket ratio per Users, an unusually high level of open          tickets or other excessive stress on the Service(s).

13. Disclaimer Of Warranties
The websites and the service(s), including all server and network components are provided on an “as is” and “as available” basis, without any warranties of any kind to the fullest extent permitted by applicable law. We expressly disclaim any and all conditions, representations, warranties or other terms, whether express or implied, including, but not limited to, any implied warranties of merchant ability, title, fitness for a particular purpose, and non infringement.

You acknowledge that we do not warrant that the service(s) or websites will be uninterrupted, timely, secure or error-free and you further acknowledge that we do not warrant that the access to the service(s), which is provided over internet and various telecommunications networks, all of which are beyond our control, will be uninterrupted, timely, secure, error-free or free from viruses or other malicious software.
The content on our websites is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our websites. No information or advice obtained by you from us or through the service(s) or websites shall create any warranty not expressly stated in these terms.

14. Limitation Of Liability
To the fullest extent permitted by applicable law, in no event will we, our affiliates, officers, directors, employees, agents, suppliers or licencors be liable to any person for any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, lost revenue, lost sales, lost goodwill, loss of use or lost content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if we have been advised as to the possibility of such damages or could have foreseen such damages. To the maximum extent permitted by applicable law, our aggregate liability and that of our affiliates, officers, employees, agents, suppliers and licencors, relating to the service(s), will be limited to an amount equal to the lower of

1.  Twelve months of the subscription charges for the service(s) to which the claim relates; or
2. The subscription charges paid by you, for the service(s) to which the claim relates prior to the first event or occurrence giving rise to         such liability. You acknowledge and agree that to provide you with the rights to access and use the service(s) in accordance with               section 1, we have limited our potential liability and allocated risks based on the subscription charges, which would have been                     substantially higher if we were to assume any further liability other than as set forth herein.

In jurisdictions which do not permit the exclusion of implied warranties or limitation of liability for incidental or consequential damages, our liability will be limited to the greatest extent permitted by law.

The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose.

15. Indemnification
If use of the service(s) by you has become, or in our opinion is likely to become, the subject of any IP Claim (defined below), we may at Our own option and expense

1.  Procure for You the right to continue using the Service(s) as set forth here under;
2. Replace or modify the Service(s) to make it non-infringing; or
3. If options (a) or (b) are not commercially and reasonably practicable as determined by us, terminate your subscription to the                      Service(s) and repay you, on a pro-rated basis, any subscription charges you have previously paid us for the corresponding unused          portion.

Indemnification By Us:
Subject to your compliance with these terms, We will indemnify and hold you harmless, from and against any claim brought against you by a third party alleging that the service(s) you subscribed to infringes or misappropriates such third party’s valid patent, copyright, or trademark (an “IP Claim”). We shall, at our expense, defend such IP Claim and pay damages finally awarded against you in connection therewith, including the reasonable fees and expenses of the attorneys, provided that:-

1.  You promptly notify us of the threat or notice of such IP Claim;
2. We have or will have the sole and exclusive control and authority to select defense attorneys, defend and/or settle any such IP Claim;        and
3. You fully cooperate with Us in connection therewith.
     We will have no liability or obligation with respect to any IP Claim if such claim is caused in whole or in part by
4.  Compliance with designs, data, instructions or specifications provided by You;
5.  Modification of the Service(s) by anyone other than us; or
6.  The combination, operation or use of the service(s) with other hardware or software where the Service(s) would not by themselves be       infringing.

Sections 15.1 and 15.2 state our sole, exclusive and entire liability to you and constitute your sole remedy with respect to an IP Claim brought by reason of access to or use of the Service(s) by you.

Indemnification By You:
You will release, indemnify, defend and hold harmless BOTGO TECHNOLOGIES PRIVATE LIMITED contractors, agents, employees, officers, directors, shareholders, affiliates and assigns from all liabilities, claims, damages, costs and expenses, including reasonable attorneys’ fees and expenses, of third parties relating to or arising out of

1.   This Agreement or the breach of your warranties, representations and obligations under this Agreement;
2.  The Website content or your use of the Website content;
3.  The Products or your use of the Products (including Trial Products);
4.  Any intellectual property or other proprietary right of any person or entity;
5.  Modification of the Service(s) by anyone other than us; or
6.  The combination, operation or use of the service(s) with other hardware or software where the Service(s) would not by themselves be       infringing.

When we are threatened with suit or sued by a third party, We may seek written assurances from you concerning your promise to indemnify us; your failure to provide such assurances may be considered by us to be a material breach of this Agreement. We will have the right to participate in any defense by you of a third-party claim related to your use of any of the Website content or Products of owner. We will reasonably cooperate in any defense by you of a third-party claim at your request and expense. You will have sole responsibility to defend against any claim, but you must receive prior written consent from us regarding any related settlement. The terms of this provision will survive any termination or cancellation of this Agreement or your use of the Website or Products.

16. Assignment; Entire Agreement; Revisions
You shall not, directly or indirectly, assign all or any your rights under these terms or delegate performance of your duties under these terms without our prior written consent. We may, without your consent, assign our agreement with you under these terms to any member of the companies in connection with any merger or change of our control or the sale of all or substantially all of our assets provided that any such successor agrees to fulfil its obligations pursuant to these terms. Subject to the foregoing restrictions, these terms will be fully binding upon, inure to the benefit of and be enforceable by the parties and their respective successors and assigns.

These terms, together with any form(s) and supplemental terms, constitute the entire agreement, and supersede any and all prior agreements between you and us with regard to the subject matter hereof. These terms and any form(s) shall prevail over the terms or conditions in any purchase order or other order documentation you or any entity you represent provides (all such terms or conditions being null and void), and, except as expressly stated herein, there are no other agreements, representations, warranties, or commitments which may be relied upon by either party with respect to the subject matter hereof. In the event of a conflict between any form and these terms, these terms shall prevail.

We may amend these terms from time to time, in which case the new terms will supersede prior versions. Please read these terms of use carefully before you start to use our service(s) or websites, as these will apply to your use of the service(s) and our websites. Please check these terms from time to time to take notice of any changes we made, as they will be binding on you. We will notify you not less than ten (10) days prior to the effective date of any amendments to these terms of service and your continued use of the service(s) following the effective date of any such amendment may be relied upon by us as your acceptance of any such amendment. With respect to amendments only to the supplemental terms, we will notify you as aforementioned only if the supplemental terms are applicable to you. Our failure to enforce at any time any provision of these terms does not constitute a waiver of that provision or of any other provision of the terms.

17. Severability; No Waiver
If any provision in these Terms is held by a court of competent jurisdiction to be unenforceable, such provision shall be modified by the court and interpreted so as to best accomplish the original provision to the fullest extent permitted by applicable law, and the remaining provisions of these Terms shall remain in effect. Our non-exercise of any right under or provision of these Terms does not constitute a waiver of that right or provision of the Terms.

18. Export Compliance And Use Restrictions; Federal Government End Use Provisions
The Service(s) and other Software or components of the Service(s) which we may provide or make available to you or Users may be subject to Indian (or other territories) export control and economic sanctions laws. You agree to comply with all such laws and regulations as they relate to access to and use of the Service(s), Software and such other components by you and users. You shall not access or use the Service(s) if you are located in any jurisdiction in which the provision of the Service(s), Software or other components is prohibited under India or other applicable laws or regulations (a “Prohibited Jurisdiction”) and you shall not provide access to the Service(s) to any government, entity or individual located in any Prohibited Jurisdiction. You represent, warrant and covenant that

(i) You are not named on any Indian Government (or other government) list of persons or entities prohibited from receiving Indian exports, or transacting with any Indian person,

(ii) You are not a national of, or a company registered in, any Prohibited Jurisdiction,

(iii) You shall not permit Users to access or use the Service(s) in violation of any Indian or other applicable export embargoes, prohibitions or restrictions, and

(iv) You shall comply with all applicable laws regarding the transmission of technical data exported from the India and the country in which You and Your Users are located.

19. Relationship Of The Parties
The parties are independent contractors. These terms do not create a partnership, franchise, joint venture, agency, fiduciary or employment relationship among the parties.

20. Survival
Sections 2 (your responsibilities), 5 (intellectual property rights), 7 (billing, plan modification and payments), 8 (suspension and termination), 9 (confidentiality), 10 (data privacy and security), 13 (disclaimer of warranties), 14 (limitation of liability), 15 (indemnification), 20 (survival), 21 (notices; consent to electronic communication) and 23 (governing law and dispute resolution) shall survive any termination of our agreement with respect to use of the service(s) by you. Termination of such agreement shall not limit your or our liability for obligations accrued as of or prior to such termination or for any breach of these terms.

21. Notices; Consent To Electronic Communications
All notices to be provided by us to you under these Terms may be delivered in writing (i) or by “Courier” or mail to the contact mailing address provided by you while subscribing to the Service(s); or (ii) electronic mail to the e-mail address provided for your Account.

Our address for sending notice to us in writing by Courier is 6th Floor, Pentagon 2, Magarpatta – 400013 IN or Mailing address is : info@botgo.io by electronic mail.

All notices shall be deemed to have been given immediately upon delivery by electronic mail, or if otherwise delivered upon receipt or, if earlier, two (2) business days after being deposited in the mail or with a Courier as permitted above.

22. Anti-Corruption
You agree that you have not received or been offered any illegal or improper bribe, kickback, payment, gift, or thing of value from any of our employees or agents in connection with these Terms. Reasonable gifts and entertainment provided in the ordinary course of business do not violate the above restriction. If you learn of any violation of the above restriction, you will use reasonable efforts to promptly notify us at info@botgo.io by electronic mail.

23. Governing Law And Jurisdiction
This Agreement will be governed by the (Indian) Contract Act, 1872 and The Information Technology Act, 2000.

Neither you nor us will commence or prosecute any suit, proceeding or claim to enforce the provisions of this Agreement, to recover damages for breach of or default of this Agreement, or otherwise arising under or by reason of this Agreement, other than in courts located in State of Maharashtra.

By using this Website or ordering Products, you consent to the jurisdiction and venue of such courts in connection with any action, suit, proceeding or claim arising under or by reason of this Agreement. You hereby waive any right to trial by jury arising out of this Agreement and any related documents.

24. Statute of Limitation.
You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Website or Products or this Agreement must be filed within one (1) year after such claim or cause of action arose or be forever barred.

25. Dispute Resolution
1.  If there is there is any dispute in regard to or related to or arising out of this Agreement the party shall try to resolve the dispute in mutual        trust and goodwill.
2.  In the event of such dispute or difference is not settled amicably by negotiation, the same shall be referred to a sole Arbitrator and the          matter will be settled as per the provisions of Indian Arbitration Act.
3. The decision of the Arbitration shall be final and binding on both the parties.

26. Definitions
When used in these Terms with the initial letters capitalized, in addition to terms defined elsewhere in these Terms, the following terms have the following meanings:

Account: Means any accounts or instances created by or on behalf of You for access and use of any of the Service(s).

Affiliate: Means, with respect to a party, any entity that directly or indirectly controls, is controlled by, or is under common control with such party, whereby “control” (including, with correlative meaning, the terms “controlled by” and “under common control”) means the possession, directly or indirectly, of the power to direct, or cause the direction of the management and policies of such person, whether through the ownership of voting securities, by contract, or otherwise.

Agent: Means an individual authorized to use the Service(s) through Your Account for such Service(s) as an agent and/or administrator as identified through a User Login.

API: Means the application programming interfaces developed, enabled by or licensed to Us that permits a User to access certain functionality provided by the Service(s).

API Policies: Means the policies published on the Websites https://botgo.io/ that govern the use of APIs, as updated from time to time.

Confidential Information: Means all information disclosed by you to us or by us to you which is in tangible form and labelled “confidential” (or with a similar legend) or which a reasonable person would understand to be confidential given the nature of the information and circumstances of disclosure. For purposes of these terms, service data shall be deemed confidential information. Notwithstanding the foregoing, confidential information shall not include any information which:

1.  was publicly known and made generally available in the public domain prior to the time of disclosure by the disclosing party;
2. becomes publicly known and made generally available after disclosure by the disclosing party to the receiving party through no action         or   inaction of the receiving party;
3.  is already in the possession of the receiving party at the time of disclosure by the disclosing party as shown by the receiving party’s files        and records prior to the time of disclosure;
4.  is obtained by the receiving party from a third party without a breach of such third party’s obligations of confidentiality;
5.  is independently developed by the receiving party without use of or reference to the disclosing party’s confidential information, as shown      by documents and other competent evidence in the receiving party’s possession; or
6.  Is required by law to be disclosed by the receiving party, provided that the receiving party shall, to the extent legally permitted, give the          disclosing party written notice of such requirement prior to disclosing so that the disclosing party may seek a protective order or other            appropriate relief.

Custom Apps: Means an application developed specifically for a User of the Service(s) and not listed in the Market Place.

Documentation: Means any written or electronic documentation, images, video, text or sounds specifying the functionalities of the Service(s) provided or made available by Us to You or Your Users through the Service(s) or otherwise.

End-User: Means any person or entity other than you or Your Users with whom you interact using the Service(s).

Form: Means any service order form referencing these Terms and executed or approved by you and us with respect to your subscription to the Service(s), which form may detail, among other things, the number of Users authorized to use the Service(s) under your subscription to the Service(s) and the Service Plan(s) applicable to your subscription to the Service(s).

Personal Data: Means data relating to a living individual who is or can be identified either from the data or from the data in conjunction with other information that is in, or is likely to come into, the possession of the data controller (as defined under Applicable Data Protection Law)..

Processing/To Process: Means any operation or set of operations which is performed upon Personal Data, whether or not by automatic means, such as collection, recording, organization, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, blocking, erasure or destruction.

Service Data: Means all electronic data, text, messages or other materials, including Personal Data of Users and End-Users, submitted to the Service(s) by You through Your Account in connection with Your use of the Service(s), including, without limitation, Personal Data.

Service(S): We may introduce as a Service to which you may subscribe to and any updates, modifications or improvements to the including individually and collectively, Software, the API and any Documentation. You may subscribe to one or more of the Service(s). They may be subject to separate and distinct Service Plans.

Service Plan(s): Means the pricing plan(s) and the functionality and services associated therewith (as detailed on the Websites) for which you subscribe with respect to any User.

Software: Means software provided by us (either by download or access through the internet) that allows you to use any functionality in connection with the Service(s) and includes a Mobile Application.
Subscription Term: means the period during which you have agreed to subscribe to the Service(s) with respect to any individual User.

Us/Owner: Means BOTGO TECHNOLOGIES PRIVATE LIMITED, or any of its successors or assignees. In these Terms, us may also be referred to as “We”, and “Our”.

User: Means those who are designated users within the Service(s), including an Account administrator, Agents and other designated users.

Websites: means the websites for various Service(s) and other websites that Owner operates

27. Supplemental Terms
The Supplemental Terms below may contain terms that are specific to one or more Service(s). For avoidance of doubt, in the event of a conflict or inconsistency between the rest of the Terms and these Supplemental Terms, these Supplemental Terms shall prevail.

A. For Use Of Apps, Integrations; Modification Of Existing Features & New Feature Release You Acknowledge That
1.  When you install any of the Apps or enable integrations or
2. Where (a) a feature is modified or (b) a new feature is released within the Service(s), you may be shown additional terms governing            their usage. Your continued usage of such Apps or integrations or such features may be relied upon by us as your acceptance of such      additional terms.

B. For Use Of Phone Service/Support Services
If You use the phone service as part of our services you understand and agree that

(a) Unless You choose to turn off the recording feature, all calls made using the phone service are recorded;

(b) The phone service is not intended to support or carry emergency calls to any emergency services such as public safety answering points,

(c) We will not be held liable for any claim, damages or loss (and You hereby waive any and all such claims or causes of action), arising from or relating to Your (or Users or End-Users) inability to use the phone service to make such emergency calls,

(d) You are solely responsible for Your operation of the phone service in compliance with all applicable laws in all jurisdictions governing use of the Service(s) by You, Your Affiliates, Users and End-Users, including but not limited to telephone recording and wiretapping laws, and

(e) You will defend, hold harmless and indemnify Us from and against any third party claim arising from any of the foregoing. We may disable the phone numbers provided to You if

1.   Your subscription to the Service(s), Account or rights to access and/or use the Service(s) are otherwise suspended, or terminated;                 and/or
2. You violate these Terms. In the event that You wish to port-out Your phone number upon termination of Your subscription to the                    Service(s) or for any other reason, You agree to notify info@botgo.io Upon receipt of such request, We shall use reasonable efforts          to  assist You in the port-out to the third-party service provider of Your choice. Use of this phone service is subject to the payment of            additional fees and charges, including, without limitation, the phone call rates as detailed on the Websites. When enabling the phone          service, You are consenting, on behalf of You and Your Users and End-Users to the Processing of Service Data (as generated by or                necessary for the provision or operation of the phone service) by the third-party service provider We utilize to provide the phone s                service.

Please note that upon a request for deletion of a contact, information such as name of the contact, call recordings of that contact and any notes pertaining to such call recordings shall be deleted. However, information such as logs containing actual numbers making and receiving the calls may be retained for audit, fraud and reporting purposes in accordance with applicable law.

C. For Use Of Service
1 . If You use our Service(s), and if You have raised a request for deletion of a User, please note that, for business continuity purposes,               deleting the User does not delete business-specific organization-owned data created and contributed to by the User including without     limitation, knowledgeable articles, notes, forum topics/comments, support calls, surveys, automation and dispatcher rules, canned               responses, ticket templates, contacts, companies, tags, conversations in the tickets, etc. The deleted User’s Personal Data will then be         anonymized within the Service. You acknowledge and agree that you will access and use our services only for your internal business           purposes and that You will not provide any third party with access to any of the services through Your Account. You further agree that       we will not be liable for any loss or corruption of Service Data, or for any costs or expenses associated with backing up or restoring any       of Service Data or any claims, losses, expenses, costs or damages arising from such unauthorized use. If you wish to raise a request for       deletion of an Agent, please write to info@botgo.io. Please note that for the purpose of business continuity, the name of the deleted       Agent will be retained within Your Account.
2. We may offer certain services on Our Websites as closed or open beta services (“Beta Services”) during the testing and evaluation               period. You agree that we have the sole authority and discretion to determine the period of time for testing and evaluation of Services.       You will be under no obligation to acquire a subscription to use any paid services as a result of your subscription to any Beta Service.            We  reserve the right to fully or partially discontinue, at any time and from time to time, temporarily or permanently, any of the Beta              Services with or without notice to you. We also reserve the right to make the Beta Services available for a subscription fee (which may        or may not be lower than the Subscription Charges payable for paid services).
3. For Your use of Services, all references to Service Plan(s) under the Terms shall be construed as referenced to Pricing Subscription(s).          “Pricing Subscription(s)” means the pricing and number of monthly unique visitors (“MUV”) associated therewith (as detailed on the              Websites) for which you subscribe with respect to any User.
     iv If you use the sessions replay feature in, you acknowledge and agree that it is your responsibility to obtain necessary consents and        authorizations required under applicable law in connection with the use of this feature.
    v If you are a Customer of Our Service, You may at any time delete data associated with a Candidate from within Service. As a                      customer you may delete a User’s data. Upon Your deletion of User’s data, we will retain information like business e-mail address,                  identification number, and designation of such User (“Retained User Information”) in order to not break any old associations relating to        such User within Your Account. Except Retained User Information, all the other data that you delete will be deleted. Retained User                  Information will be deleted in accordance with the Terms. If you wish to permanently delete a User’s Retained User Information, You              can   write to info@botgo.io.

D. Data
“Data” means any data that is collected from Your Users and Candidates through Your Account in connection with your use of services, including but not limited to the following:

1. Directory. Contact Information, such as name, email address, mailing address, phone number, date of birth, gender, emergency                  contact   information of the Users collected at the time of employment and creation of employee profile. Subject to the Service Plan to      which you have subscribed, you may have the option to collect additional information, including sensitive or personal information, from    Users. The Service allows the Users to modify, rectify and update their personal data within the Service
  is a block of text. Double-click this text to edit it.
2. Application Form. Candidates’ contact information, such as name, email address, mailing address, phone number, links to Candidates’     social networking profiles or any custom field that you may add to the form to be filled by Candidates at the time of submitting their           resumes.

E. Data Retention
We strongly recommend that you export all Service Data before you terminate Your Account. In any event, following the termination of Your Account either by you or us:

1. If you have subscribed to any of the Service Plan, Service Data will be retained for a period of 15 days (“Data Retention Period”) from               such   termination within which you may contact us to export Service Data. Beyond this Data Retention Period, We reserve the right to         delete all Service Data in the normal course of operation. Service Data cannot be recovered once it is deleted.
2. If you have signed-up to any of our services but not activated Your Account within 7 days from such sign-up, we reserve the right to            delete Your Account immediately upon the expiry of the aforementioned 7 days.

F. Data Export.
You acknowledge that we do not have any feature to export Service Data upon termination of Your Account. If you wish to permanently delete Service Data upon termination of Your Account, You can write to info@botgo.io

G. Additional Terms For Certain Features
1. Third Party Platforms. If a Candidate shares Personal Data with you through Your Account, You shall be solely responsible for such                 Personal Data whether it is publicly posted or privately transmitted through Your Account, on third party platforms such as Facebook,         Twitter, Google+ and LinkedIn (“Third Party Platforms”). These Third Party Platforms are governed by their own terms and privacy policies     and You agree that We have no control over these Third Party Platforms and that We are not responsible for your access or use of               these   Third Party Platforms, or for any loss or corruption of Service Data, or for any costs or expenses associated with backing up or r         restoring any of Service Data, or any claims, losses, expenses, costs or damages arising from such use of the Third Party Platforms.
2. Rating. Our Services has a Rating feature through which you will have the ability to rate the Candidates. You acknowledge and agree          that we do not have any control on the ratings that you provide to the Candidates. Further, you agree to use this feature in compliance     with all applicable laws and if your use of this feature violates any applicable law, you shall immediately cease using this feature.
3. If any Candidate shares Personal Data with You through any of the services, You acknowledge that the information is used solely for            the  for the purposes of providing various feature within services (if available as part of Your Service Plan), We use a third party service        provider to enhance, refine or otherwise improve the information and to extract relevant information from the data shared. The results      derived by this tool shall be displayed for your review. You understand that the analysis presented is based on the accuracy of the              data   shared and we shall not be liable to you for any inability to use or interpret the analysis.
4. In the event you choose to include Personal Data in such posts, you agree that we have no control over how such third parties handle        Your Personal Data and that we are not responsible for any loss or corruption of such Personal Data.
5. If you use the advanced email editor feature (“Email Feature”), the following shall apply to you:

Your Rights. We hereby grant you the limited right to access, execute, display, perform and otherwise use the Email Feature for Your respective internal business purposes. Except for the limited usage rights granted to you, the license rights granted herein are not transferable and not assignable to third parties.

During Your use of the Email feature, You agree not to:

Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of others;

Use the Email Feature to upload, or otherwise make available, files that contain images, photographs, software or other material protected by intellectual property laws, including, by way of example, and not as limitation, copyright, trademark or privacy laws unless You own or control the rights thereto or have received all necessary consent to do the same.

Use any material of information, including images or photographs, which are made available through the Email Feature in any manner that infringes any copyright, trademark, patent, trade secret, or other proprietary right of any third party;

Damage, destroy, disrupt, disable, impair, interfere with or otherwise impede or harm in any manner the Email Feature;

Falsify or delete any copyright management information, such as author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of the Email Feature or other material contained in a file that is uploaded;

Otherwise access or use the Email Feature or relevant Documentation beyond the scope of the authorization granted herein.

6. If You use the e-mail campaign feature, in addition to your obligations under Section 5 above, the following shall apply to You:

You hereby agree that you shall be solely responsible and liable to comply with all applicable laws (including without limitation, anti-spam and data privacy legislations) and secure all necessary consents and permissions to send communications via Email Campaign.

In addition to any restrictions under the Terms, You hereby agree that You shall not send electronic communications:

Containing any content relating to or links to sites involved in any activities prohibited herein, including without limitation, pornographic content;

Impersonating or misrepresenting any individual or entity or that in any manner misleads recipients of the origin of the communication transmitted through our services;

For the purpose of or in relation to any scamming activity, or gathering or storing Personal Data of any individual, including without limitation, other Users of the Service(s) for use in connection with any activities prohibited herein;

For distributing or selling any illegal or counterfeit products;

For any purpose that is dangerous or harmful in nature.

7. If You become aware of any actual or threatened activity prohibited by section 5 or 6 above, You shall immediately:
   (a) notify us of any such actual or threatened activity and disclose all relevant information to Us;
   (b) take all reasonable and lawful measures within Your respective controls that are necessary to stop the activity and
   (c) fully cooperate with Us to mitigate its effects (including, where applicable, by discontinuing and preventing any unauthorized                   access     to the Email Feature and/or Email Campaign and permanently erasing from Your systems and destroying any data to which       You may   have gained unauthorized access).
8. If You become aware of any actual or threatened activity prohibited by section 5 or 6 above, You shall immediately:
   (a) notify us of any such actual or threatened activity and disclose all relevant information to Us;
   (b) take all reasonable and lawful measures within Your respective controls that are necessary to stop the activity and
   (c) fully cooperate with Us to mitigate its effects (including, where applicable, by discontinuing and preventing any unauthorized                   access   to the Email Feature and/or Email Campaign and permanently erasing from Your systems and destroying any data to which         You may have gained unauthorized access).
9. Definitions Candidates: means any person or entity other than you or Your Users with whom you interact using any of our services.
    Users: as defined in the Terms shall be deemed to also include Your employees;

H. Organisation Supplemental Terms
The terms below apply to the use of the Organisation feature. In case of a conflict between the terms below and the Terms, the terms below shall prevail.

1. For easy administration of Your Account(s), You acknowledge and agree that the security management of Your Account(s) across               Service(s) hosted in the same region (“Associated Accounts”) may be grouped together under a common dashboard (“Organisation”).       The Organisation’s dashboard is accessible only to the administrator of the Organisation as designated by you (“Organisation Admin”).
2. Subject to the Terms, You have the following rights if You are the Organisation Admin:

To delete an Agent from the Organisation, which in turn will automatically delete the Agent from all Associated Account(s) within 15 days from the date of deletion from the Organisation;

To modify the profile of an Agent who is part of the Organisation, which in turn will automatically modify the respective information across all Associated Account(s) immediately;

To de-link any or all the Associated Account(s) from the Organisation by reaching out to info@botgo.io;

3. Deletion of an Agent by Account Administrator. Subject to the Privacy Notice, you may delete an Agent from Your Account where you          are an administrator of that Account. However, you acknowledge and agree that such a deletion is specific to that Account only and         separate deletions need to be initiated across other Associated Account(s) in an Organisation for deletion of the Agent from all                   Accounts. To delete an Agent from the Organisation, please contact Your Organisation Admin. However, if an Agent is deleted from an       Account and if such Agent is not a part of any other Account in an Organisation, such Agent will be automatically deleted from the             Organisation after 15 days from the date of deletion of the Agent from that Account.
4. Termination of an Account by Account Administrator. Subject to the Terms, you may terminate an Account for which you are the                 administrator. Such a termination will also be updated in the Organisation after 15 days of effective termination of that Account. You           acknowledge and agree that such a termination is specific to that Account only and shall not affect the other Associated Accounts. An     Account, once terminated by the administrator of that Account, cannot be restored by the Organisation Admin. The Service Data of an      Account that is terminated shall be retained and deleted as specified in the Terms. Agent data of the Account terminated shall be              retained in the Organisation for a period of 15 days after which it is permanently deleted.
5. Deletion of an Organisation. If You are the Organisation Admin, you may request for deletion of your Organisation at any time from              within the Organisation settings. Deletion of an Organisation shall result in the deletion of all Associated Accounts within 15 days from          the date of deletion of the Organisation. In any event, an Organisation will be deleted after 30 days from the date of effective                        termination of the last subsisting Account under that Organisation. The Service Data of an Account that is terminated shall be retained      and deleted as specified in the Terms. Organisation Data will be deleted after 30 days from the effective deletion on an Organisation.          Organisation Data shall mean the name, logo, address, security configuration and custom SSO of the Organisation.
6. Modification of an existing functionality or release of a new functionality. You acknowledge that when an existing functionality is                    modified or a new functionality is released within the Organisation feature, you may be shown additional terms governing such                   modified or new functionality. Your continued usage of such modified or new functionality may be relied upon by us as your                           acceptance of such additional terms.

By Using This Website Or Ordering Products From This Website You Agree To Be Bound By All Of The Terms And Conditions Of This Agreement.

About Botgo
The founders come from hands on customer experience background having managed customer facing processes for decades.
Read more.
Products
-AI / NLP Solutions
- Email Ticket Management Solutions
Pricing
About Us
Contact Us
Contact
Botgo Technologies Pvt. Ltd.B-67, 2nd Floor, CESPL Building, Sector 67,
Noida, 201301, Uttar Pradesh, India,
info@botgo.io
+91 9579131948
+1-302-440-1396
Copyright © 2024 Botgo Technologies P Ltd. All Rights Reserved.
Terms of Service | Privacy Policy
</div>
    </div>
  )
}

export default TermsConditionModal