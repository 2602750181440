import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';



const EmailReportsList = () => {
    const [hoveredCard, setHoveredCard] = useState(null);
    const navigate = useNavigate();

    const handleMouseEnter = (index) => {
        setHoveredCard(index);
    };

    const handleMouseLeave = () => {
        setHoveredCard(null);
    };

    const toNavigate = (path) => {
        navigate(`/reports/email${path}`);
    };
    const reports = [
        {
            title: 'Email Agent Performance Report',
            description: "A comprehensive analysis of agent performance metrics.",
            to: '/email-agent-performance-report',
            image: "https://fonolo.com/wp-content/uploads/2023/09/Agent-Performance-IMAGE.png"
        },
        {
            title: 'Email SLA Report',
            description: "An in-depth look at email service level agreements and response times.",
            to: '/email-sla-report',
            image: "https://www.venminder.com/hubfs/Blog_Images/2023_Blog_Posts/04.04.2023-tracking-vendor-performance-with-service-level-agreements-FEATURED.jpg"
        }
    ];
    const getCardStyle = (index) => ({
        transition: 'transform 0.3s, box-shadow 0.3s',
        transform: hoveredCard === index ? 'translateY(-5px)' : 'none',
        boxShadow: hoveredCard === index ? '0 4px 20px rgba(0, 0, 0, 0.1)' : 'none',
    });
    return (
        <>
            <div id="kt_app_toolbar" className="app-toolbar">
                <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                        <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                            Email
                        </h1>
                      
                    </div>

                </div>
            </div>

            <div className='row gy-0 gx-12'>
                {reports.map((report, index) => (
                    <div className='col-xl-4 mt-5' key={index}>
                        <div
                            className='card card-xl-stretch bg-body border-0'
                            style={getCardStyle(index)}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => toNavigate(report.to)}
                        >
                            <div className='card-body d-flex flex-column flex-lg-row flex-stack'>
                                <div className='d-flex flex-column justify-content-center align-items-center align-items-lg-start me-10 text-center text-lg-start'>
                                    <h3 className='fs-1hx line-height-lg mb-5'>
                                        <span className='fw-bold'>{report.title}</span>
                                    </h3>
                                    <div className='fs-6 text-muted mb-7'>
                                        {report.description}
                                    </div>
                                    <button
                                        className='btn btn-primary fw-semibold px-6 py-3'
                                        onClick={() => toNavigate(report.to)}
                                    >
                                        View Reports
                                    </button>
                                </div>
                                <img
                                    src={report.image}
                                    alt='report-icon'
                                    className='mw-200px mw-lg-200px mt-lg-n10'
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default EmailReportsList;
