import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import AddNewDispositionModal from "../../../components/settings/General/DispositionSettingsModals/AddNewDispositionModal";
import AddSubMenuModal from "../../../components/settings/General/DispositionSettingsModals/AddSubMenuModal";
import DeleteMenuModal from "../../../components/settings/General/DispositionSettingsModals/DeleteTopicModal";
import EditTopicModal from "../../../components/settings/General/DispositionSettingsModals/UpdateTopicModal";
import UpdateTopicModal from "../../../components/settings/General/DispositionSettingsModals/UpdateTopicModal";
import DispositionSelector from "../../../components/settings/common/dispositionSelector";
import {
    useEmaildispositionQuery,
    useTeamsQuery,
} from "../../../Services/settingsAPIs/CommonAPI";
import { defaultModalStyles } from "../../../components/common/CustomModalStyles";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useSaveDespositionMutation } from "../../../Services/general/General";
import { toast } from "react-toastify";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { capitalizeFirstLetter } from "../../../components/reusableComponent/CapitalLetter";

const DeleteModalStyles = {
    content: {
        top: "40%",
        left: "40%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-15%, -45%)",
        border: "2px solid #dde",
    },
};

const DispositionSetting = () => {
    const { data: teamsfetched, isLoading } = useTeamsQuery({});
    const [theTeams, setTheTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState<number>();
    const {
        data: dispositionData,
        isLoading: despositionLoading,
        refetch,
    } = useEmaildispositionQuery(selectedTeam, { skip: !selectedTeam });

    const [storingDespositionData, setStoringDespositionData] = useState([]);
    const [selectedDisposition, setSelectedDisposition] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [expandedNodes, setExpandedNodes] = useState([]);
    const [currentNode, setCurrentNode] = useState(null);
    const [isNewDispositionModalOpen, setIsNewDispositionModalOpen] =
        useState(false);
    const [liftingAddData, SetLiftingAddDate] = useState([]);

    const [trigger] = useSaveDespositionMutation();

    const openDispositionModal = () => {
        setIsNewDispositionModalOpen(true);
    };

    const closeDispositionModal = () => {
        setIsNewDispositionModalOpen(false);
    };

    const openModal = (node) => {
        setCurrentNode(node);
        setIsModalOpen(true);
    };


    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentNode(null);
    };

    const openDeleteModal = (node) => {
        setCurrentNode(node);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
    };
    const openUpdateModal = (node) => {
        setCurrentNode(node);
        setIsUpdateModalOpen(true);
    };

    const closeUpdateModal = () => {
        setIsUpdateModalOpen(false);
    };

    const handleFirstDropdownChange = (event) => {
        setSelectedDisposition(event.target.value);
    };
    const toggleNode = (level: string) => {
        if (expandedNodes.includes(level)) {
            setExpandedNodes(expandedNodes.filter((n) => n !== level));
        } else {
            setExpandedNodes([...expandedNodes, level]);
        }
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "30%",
        // height: "30%",
        overflow: "hidden",
        bgcolor: "background.paper",
        border: "1px solid transparent",
        boxShadow: 2,
        borderRadius: "5px",
        p: 4,
    };

    const Deletestyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "25%",
        // height: "30%",
        overflow: "hidden",
        bgcolor: "background.paper",
        border: "1px solid transparent",
        boxShadow: 2,
        borderRadius: "5px",
        p: 4,
    };

    function alignFormat(arr: any[]) {
        function addLevels(subTopics: any, levelPrefix: string) {
            return subTopics.map((item: any, index: number) => {
                let newItem = { ...item, level: `${levelPrefix}${index}` };
                if (newItem.sub_topics && newItem.sub_topics.length > 0) {
                    newItem.sub_topics = addLevels(
                        newItem.sub_topics,
                        `${newItem.level}:`
                    );
                }
                return newItem;
            });
        }

        return arr.map((item, index) => {
            let newItem = { ...item, level: `${index}` };
            if (newItem.sub_topics && newItem.sub_topics.length > 0) {
                newItem.sub_topics = addLevels(newItem.sub_topics, `${index}:`);
            }
            return newItem;
        });
    }

    const removeLevels = (arr: any[]) => {
        return arr.map((item) => {
            let newItem = { ...item };
            delete newItem.level;
            if (newItem.sub_topics && newItem.sub_topics.length > 0) {
                newItem.sub_topics = removeLevels(newItem.sub_topics);
            }
            return newItem;
        });
    };

    const cleanedData = removeLevels(liftingAddData);
    const despositionSaveHandler = async (e) => {
        e.preventDefault();
        if (cleanedData.length < 1) {
            toast.error("Nothing to save");
            return;
        }

        try {
            const response = await trigger({
                body: cleanedData,
                id: selectedTeam,
            });
            if (response.data) {
                toast.success("Added successfully");
                refetch();
                SetLiftingAddDate([]);
            }
        } catch (error) {
            toast.error("An error occured");
        }
    };

    const handleTeamDropdownChange = (event: any) => {
        setSelectedTeam(Number(event.target.value));
    };

    useEffect(() => {
        if (dispositionData) {
            setStoringDespositionData(dispositionData);
        }
    }, [dispositionData]);

    useEffect(() => {
        dispositionData?.length &&
            setStoringDespositionData(alignFormat(dispositionData));
    }, [dispositionData]);
    useEffect(() => {
        setTheTeams(teamsfetched);
    }, [teamsfetched]);

    const renderTreeNode = (node: any) => (
        <div key={node.level} className=" ms-3">
            <div className="d-flex align-items-center justify-content-between mb-2">
                <div
                    className="d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => toggleNode(node?.level)}
                >
                    <i
                        className={`bi ${
                            node.sub_topics
                                ? "bi-chevron-" +
                                  (expandedNodes.includes(node?.level)
                                      ? "down"
                                      : "right")
                                : "bi-dash"
                        } me-2`}
                    ></i>
                    <span>{capitalizeFirstLetter(node?.topic)}</span>
                </div>
                <div className="d-flex">
                    <button
                        className="btn text-hover-primary border-0 bg-white me-2"
                        onClick={() => openModal(node)}
                    >
                        <i className="bi bi-plus text-dark fs-2"></i>
                    </button>

                    <Modal
                        open={isModalOpen}
                        onClose={closeModal}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                        BackdropProps={{
                            style: {
                                backgroundColor: "rgba(0, 0, 0, 0.2)", // Adjust the opacity here
                            },
                        }}
                    >
                        <Box sx={{ ...style, position: "absolute" }}>
                            <IconButton
                                aria-label="close"
                                onClick={closeModal}
                                sx={{
                                    position: "fixed",
                                    top: 16,
                                    right: 16,
                                    zIndex: 1301,
                                    background: "white",
                                }} // Ensure it's above the modal content
                            >
                                <GridCloseIcon />
                            </IconButton>
                            <Typography
                                id="modal-title"
                                variant="h6"
                                component="h2"
                                sx={{ mb: 2 }}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                Add Sub Disposition
                            </Typography>

                            <Box>
                                <AddSubMenuModal
                                    closeModal={closeModal}
                                    setData={setStoringDespositionData}
                                    data={storingDespositionData}
                                    currentNode={currentNode}
                                    SetLiftingAddDate={SetLiftingAddDate}
                                    selectedTeam={selectedTeam}
                                    refetch={refetch}
                                />
                            </Box>
                        </Box>
                    </Modal>
                    {/* <ReactModal
                        isOpen={isModalOpen}
                        onRequestClose={closeModal}
                        style={defaultModalStyles}
                        contentLabel="Add SubMenu Disposition"
                    >
                        <AddSubMenuModal
                            closeModal={closeModal}
                            setData={setStoringDespositionData}
                            data={storingDespositionData}
                            currentNode={currentNode}
                            SetLiftingAddDate={SetLiftingAddDate}
                        />
                    </ReactModal> */}

                    <button
                        className="btn text-hover-primary border-0 bg-white me-2"
                        onClick={() => openUpdateModal(node)}
                    >
                        <i className="bi bi-pencil text-dark fs-4"></i>
                    </button>

                    <Modal
                        open={isUpdateModalOpen}
                        onClose={closeUpdateModal}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                        BackdropProps={{
                            style: {
                                backgroundColor: "rgba(0, 0, 0, 0.2)", // Adjust the opacity here
                            },
                        }}
                    >
                        <Box sx={{ ...style, position: "absolute" }}>
                            <IconButton
                                aria-label="close"
                                onClick={closeUpdateModal}
                                sx={{
                                    position: "fixed",
                                    top: 16,
                                    right: 16,
                                    zIndex: 1301,
                                    background: "white",
                                }} // Ensure it's above the modal content
                            >
                                <GridCloseIcon />
                            </IconButton>
                            <Typography
                                id="modal-title"
                                variant="h6"
                                component="h2"
                                sx={{ mb: 2 }}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                Update Disposition
                            </Typography>

                            <Box>
                                <UpdateTopicModal
                                    closeUpdateModal={closeUpdateModal}
                                    setData={setStoringDespositionData}
                                    data={storingDespositionData}
                                    currentNode={currentNode}
                                    SetLiftingAddDate={SetLiftingAddDate}
                                    selectedTeam={selectedTeam}
                                    refetch={refetch}
                                />
                            </Box>
                        </Box>
                    </Modal>

                    <button
                        className="btn text-hover-danger border-0 bg-white"
                        onClick={() => openDeleteModal(node)}
                    >
                        <i className="bi bi-trash text-dark fs-4"></i>
                    </button>

                    <Modal
                        open={isDeleteModalOpen}
                        onClose={closeDeleteModal}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                        BackdropProps={{
                            style: {
                                backgroundColor: "rgba(0, 0, 0, 0.2)",
                            },
                        }}
                    >
                        
                        <Box sx={{ ...Deletestyle, position: "absolute" }}>
                            <IconButton
                                aria-label="close"
                                onClick={closeDeleteModal}
                                sx={{
                                    position: "fixed",
                                    top: 16,
                                    right: 16,
                                    zIndex: 1301,
                                    background: "white",
                                }} // Ensure it's above the modal content
                            >
                                <GridCloseIcon />
                            </IconButton>
                            <Typography
                                id="modal-title"
                                variant="h6"
                                component="h2"
                                sx={{ mb: 2 }}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                {/* Delete Disposition */}
                            </Typography>

                            <Box>
                                <DeleteMenuModal
                                    closeDeleteModal={closeDeleteModal}
                                    data={storingDespositionData}
                                    setData={setStoringDespositionData}
                                    currentNode={currentNode}
                                    SetLiftingAddDate={SetLiftingAddDate}
                                    refetch={refetch}
                                    selectedTeam={selectedTeam}
                                />
                            </Box>
                        </Box>
                    </Modal>
                    {/* <ReactModal
                        isOpen={isDeleteModalOpen}
                        onRequestClose={closeDeleteModal}
                        style={DeleteModalStyles}
                        contentLabel="Delete Menu"
                    >
                        <DeleteMenuModal
                            closeDeleteModal={closeDeleteModal}
                            data={storingDespositionData}
                            setData={setStoringDespositionData}
                            currentNode={currentNode}
                            SetLiftingAddDate={SetLiftingAddDate}
                            refetch={refetch}
                        />
                    </ReactModal> */}
                </div>
            </div>
            {expandedNodes.includes(node.level) && node.sub_topics && (
                <div className="ms-4 border-start ps-2">
                    {node?.sub_topics?.map((subNode: any, index: number) => (
                        <div key={index}>{renderTreeNode(subNode)}</div>
                    ))}
                </div>
            )}
        </div>
    );

    return (
        <div className="card-toolbar flex-row-fluid justify-content-end gap-5 p-5">
            <div className="row mb-4">
                <div className="w-100 mw-200px mb-5">
                    <select
                        className="form-select select2-hidden-accessible"
                        onChange={handleTeamDropdownChange}
                        value={selectedTeam}
                    >
                        <option value="" disabled selected>
                            Select Team
                        </option>
                        {theTeams &&
                            theTeams?.map((team, index) => (
                                <option key={index} value={team.id}>
                                    {capitalizeFirstLetter(team?.name)}
                                </option>
                            ))}
                    </select>
                </div>

                {selectedTeam && (
                    <div className="w-100 mw-200px">
                        <button
                            className="form-control form-control text-hover-primary fw-bold"
                            onClick={openDispositionModal}
                        >
                            <i className="bi bi-plus-lg me-2"></i>
                            <span className="ms-auto">New Disposition</span>
                        </button>

                        <Modal
                            open={isNewDispositionModalOpen}
                            onClose={closeDispositionModal}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-description"
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.2)", // Adjust the opacity here
                                },
                            }}
                        >
                            <Box sx={{ ...Deletestyle, position: "absolute" }}>
                                <IconButton
                                    aria-label="close"
                                    onClick={closeDispositionModal}
                                    sx={{
                                        position: "fixed",
                                        top: 16,
                                        right: 16,
                                        zIndex: 1301,
                                        background: "white",
                                    }} // Ensure it's above the modal content
                                >
                                    <GridCloseIcon />
                                </IconButton>
                                <Typography
                                    id="modal-title"
                                    variant="h6"
                                    component="h2"
                                    sx={{ mb: 2 }}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    Add Disposition
                                </Typography>

                                <Box>
                                    <AddNewDispositionModal
                                        closeModal={closeDispositionModal}
                                        setData={setStoringDespositionData}
                                        SetLiftingAddDate={SetLiftingAddDate}
                                        //   currentLevel={node.level}
                                        data={storingDespositionData}
                                        selectedTeam={selectedTeam}
                                        refetch={refetch}
                                    />
                                </Box>
                            </Box>
                        </Modal>
                        {/* <ReactModal
                            isOpen={isNewDispositionModalOpen}
                            onRequestClose={closeModal}
                            style={defaultModalStyles}
                            contentLabel="Add new Disposition"
                        >
                            <AddNewDispositionModal
                                closeModal={closeDispositionModal}
                                setData={setStoringDespositionData}
                                SetLiftingAddDate={SetLiftingAddDate}
                                //   currentLevel={node.level}
                                data={storingDespositionData}
                            />
                        </ReactModal> */}
                    </div>
                )}
            </div>

            {despositionLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    {storingDespositionData &&
                        storingDespositionData?.length > 0 && (
                            <div style={{ overflow: "scroll" }}>
                                {storingDespositionData?.map((tree, index) => (
                                    <div
                                        key={index}
                                        className=" card p-3 fs-5 mb-2"
                                    >
                                        {renderTreeNode(tree)}
                                    </div>
                                ))}
                            </div>
                        )}
                </>
            )}

            {/* {selectedTeam && (
                <div className="text-end mt-5">
                    <button
                        disabled={liftingAddData.length === 0}
                        className="btn btn-primary"
                        onClick={despositionSaveHandler}
                    >
                        Save
                    </button>
                </div>
            )} */}

            {/* <div className="row mb-4 mt-4">
                <div className="col border d-flex justify-content-center align-items-center">
                    <DispositionSelector
                        theDispositions={storingDespositionData}
                        selectedDisposition={selectedDisposition}
                        setSelectedDisposition={setSelectedDisposition}
                    />
                </div>
            </div> */}
        </div>
    );
};

export default DispositionSetting;
