import React from "react";
import { Skeleton, Box, Typography } from "@mui/material";

const ButtonLoadingSkeleton = () => {
    return (
        // <Box sx={{ width: "100%", padding: 2, display: "flex", gap: "20px"
        //  }}>

        //   {/* <Skeleton variant="rectangular" width="10%" height={10}  /> */}

        // </Box>
<Skeleton variant="circular" width={40} height={40} />    );
};

export default ButtonLoadingSkeleton;
