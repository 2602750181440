const CampaignDispositionCard = () => {
  return (
    <div className="card text-bg-light mb-3">
                    <div className="card-body" style={{ minHeight: "250px", userSelect: "none" }}>
                      <h5 className="card-title">Disposition</h5>
                      <table className=" table table-responsive fs-2 py-0 mx-5">
                        <tbody>
                          <tr>
                            <td>Enquiry product related</td>
                            <td>30%</td>
                          </tr>
                          <tr>
                            <td>Enquiry price related</td>
                            <td>15%</td>
                          </tr>
                          <tr>
                            <td>Request demo related</td>
                            <td>20%</td>
                          </tr>
                          <tr>
                            <td>Enquiry feature related</td>
                            <td>15%</td>
                          </tr>
                          <tr>
                            <td>Chat feature related</td>
                            <td>20%</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
    </div>
  )
}

export default CampaignDispositionCard
