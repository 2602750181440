import React, { useState } from 'react';
import { CloseButton } from 'react-bootstrap';
import EmailTemplateLists from './EmailTemplateLists';
import SelectedEmailTemplate from './SelectedEmailTemplate';

interface IProps {
    closeModal: () => void;
    setSelectedTemplateDescription: any
    storeTemplateData: any
    setTemplateAttachment:any
}

const EmailTemplateModal: React.FC<IProps> = ({ closeModal , setSelectedTemplateDescription, storeTemplateData,setTemplateAttachment }) => {
    const [selectedTemplate, setSelectedTemplate] = useState<any>(false);
    const [selectedDescriptionTemplate, setSelectedDescriptionTemplate] = useState('')
    const [allAttachment, setAllAttachment] = useState<any>([])

    const handleTemplateSelect = () => {
        setSelectedTemplate(true);
    };

    const handleBackToList = () => {
        setSelectedTemplate(false);
    };


    return (
        <div>
            <div className='text-end'>
                <CloseButton onClick={closeModal} />
            </div>
            <div className='text-center mb-4'>
                <label className='form-label fs-2 fw-bolder text-dark card-title'>Email Templates</label>
            </div>
            <div className='row'>
                {selectedTemplate ? (
                    <SelectedEmailTemplate handleBackToList={handleBackToList}  selectedDescriptionTemplate={selectedDescriptionTemplate} closeModal={closeModal} setSelectedTemplateDescription={setSelectedTemplateDescription} setTemplateAttachment={setTemplateAttachment} allAttachment={allAttachment}/>
                ) : (
                    <EmailTemplateLists onSelectTemplate={handleTemplateSelect} setSelectedDescriptionTemplate={setSelectedDescriptionTemplate} storeTemplateData={storeTemplateData} setAllAttachment={setAllAttachment}/>
                )}

            </div>
        </div>
    );
}

export default EmailTemplateModal;
