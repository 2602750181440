import React from "react";

const MailRightSideMenu = () => {
  return (
    <div className="d-flex flex-column my-2">
      <div className="card p-1">
        <div className="card-title d-flex justify-content-center">
          <button
            className="btn btn-link"
            type="button"
            data-bs-target="#knowledgeManagementCollapse"
            aria-expanded="false"
            aria-controls="knowledgeManagementCollapse"
            disabled
          >
            Knowledge Management
          </button>
        </div>
        <div
          className="collapse multi-collapse"
          id="knowledgeManagementCollapse"
        >
          <div className="card px-2 my-1">
            <p className="my-4 text-muted">First Conversation</p>
          </div>
          <div className="card px-2 my-1">
            <p className="my-4 text-muted">Testing</p>
          </div>
          <div className="card px-2 my-1">
            <p className="my-4 text-muted">Photo</p>
          </div>
          <div className="card px-2 my-1">
            <p className="my-4 text-muted">Lead Status</p>
          </div>
          <div className="card px-2 my-1">
            <p className="my-8"></p>
          </div>
        </div>
      </div>
      <div className="card p-1 my-2">
        <div className="card-title d-flex justify-content-center">
          <button
            className="btn btn-link"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#ourTeamCollapse"
            aria-expanded="false"
            aria-controls="ourTeamCollapse"
            disabled
          >
            Our Team
          </button>
        </div>
        <div className="collapse multi-collapse">
          <div className="flex-column">
            <div id="kt_chat_contacts_header">
              <form className="w-100 position-relative">
                <div className="d-flex align-items-center position-relative">
                  <i className="bi bi-search fs-3 position-absolute ms-4" />
                  <input
                    type="text"
                    data-kt-inbox-listing-filter="search"
                    className="form-control form-control-sm form-control-solid mw-100 min-w-125px min-w-lg-150px min-w-xxl-200px ps-11"
                    placeholder="Search inbox"
                    disabled
                    style={{
                      backgroundColor: "#e9ecef",
                      cursor: "not-allowed",
                    }}
                  />
                </div>
                <div className="d-flex justify-content-around py-4">
                  <div className="d-flex align-items-center">
                    <label>Our Team</label>
                  </div>
                  <div className="d-flex flex-column align-items-end ms-2">
                    <div className="d-flex">
                      <span className="text-muted fs-7 mb-1 px-5">
                        <i className="bi bi-person-add"></i>
                      </span>
                      <span className="text-muted fs-7 mb-1">
                        <i className="bi bi-caret-down-square"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div id="kt_chat_contacts_body"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailRightSideMenu;
