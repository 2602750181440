import React, { useEffect, useState } from "react";
import EmailNotificationOn from "./EmailNotificationOn";
import {
  useSetTeamInfoMutation,
  useTeamConfigQuery,
  useTeamMutation,
  useTeamQQuery,
  useTeamsQuery,
} from "../../../../Services/settingsAPIs/CommonAPI";
import { useGetActiveEmailNotificationQuery } from "../../../../Services/settingsAPIs/EmailConfiguration";
import AutoNotificationOn from "./AutoNotificationOn";

const Notification = () => {
  const [theTeams, setTheTeams] = useState([]);
  const { data: teamsData } = useTeamsQuery({});
  const [selectedTeam, setSelectedTeam] = useState<number>();
  const { data: smtpMailConfigsData, refetch: refetchSMTPConfigs } =
    useTeamConfigQuery(selectedTeam, { skip: !selectedTeam });
  const { data: teamActiveEmailNotificationsData, refetch: refetchMailNotifs } =
    useGetActiveEmailNotificationQuery(selectedTeam, { skip: !selectedTeam });
  const [triggerTeamMutation] = useTeamMutation();
  const [smtpMailConfigurations, setSMTPmailConfigurations] = useState([]);
  const [selectedSMTPEmailConfig, setSelectedSMTPEmailConfig] =
    useState<number>();
  const [teamInfoPayload, setTeamInfoPayload] = useState<any>();
  const [isAutoResponseEnabled, setIsAutoResponseEnabled] = useState(false);
  const [isEmailNotificationOn, setIsEmailNotificationOn] = useState(false);
  const [triggerEmailNotificationUpdateMutation] = useSetTeamInfoMutation();

  const handleSelectTeamChange = (event: any) => {
    setSelectedTeam(Number(event.target.value));
  };
  const handleEmailNotificationTogglerChange = () => {
    setTeamInfoPayload({
      ...teamInfoPayload,
      email_notification_enabled: !teamInfoPayload.email_notification_enabled,
    });
  };

  async function fetchTeamInfo() {
    triggerTeamMutation(selectedTeam)
      .then((res: any) => {
        res.data &&
          setIsAutoResponseEnabled(res.data.auto_ticket_generate_enabled);
        setTeamInfoPayload(res.data);
      })
      .catch((err: any) => console.log(err));
  }

  useEffect(() => {
    if (teamsData) setTheTeams(teamsData);
  }, [teamsData]);
  useEffect(() => {
    if (smtpMailConfigsData?.length) {
      setSMTPmailConfigurations(smtpMailConfigsData);
    } else {
      setSMTPmailConfigurations([]);
      setSelectedSMTPEmailConfig(null);
      setIsEmailNotificationOn(false);
    }
  }, [smtpMailConfigsData]);
  useEffect(() => {
    selectedTeam && fetchTeamInfo();
  }, [selectedTeam]);
  useEffect(() => {
    teamInfoPayload &&
      triggerEmailNotificationUpdateMutation({
        teamId: selectedTeam,
        body: teamInfoPayload,
      });
  }, [teamInfoPayload]);

  return (
    <div
      className="mx-6"
    >
      <div className="row">
        <div className="col">
          <span className="input-group-text mt-2" id="basic-addon1">
            Auto Response
          </span>
        </div>
      </div>

      <div className="row my-2">
        <div className="col-2 d-flex">
          <select
            style={{ width: "fitContent" }}
            className="form-select select2-hidden-accessible min-w-250px"
            onChange={handleSelectTeamChange}
            value={selectedTeam}
          >
            <option value={0}> Select Team </option>
            {theTeams?.map((el: any) => (
              <option value={el?.id} key={el?.id}>
                {el?.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <AutoNotificationOn
        smtpMailConfigurations={smtpMailConfigurations}
        selectedSMTPEmailConfig={selectedSMTPEmailConfig}
        setSelectedSMTPEmailConfig={setSelectedSMTPEmailConfig}
        refetchSMTPConfigs={refetchSMTPConfigs}
      />

      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {selectedTeam && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginTop: "30px",
            }}
          >
            <p style={{ margin: "0", fontSize: "14px", fontWeight: "600" }}>
              {" "}
              Email Notification{" "}
            </p>
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <span style={{ fontSize: "16px" }}>Off</span>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={teamInfoPayload?.email_notification_enabled}
                  onChange={handleEmailNotificationTogglerChange}
                />
              </div>
              <span style={{ fontSize: "16px" }}>On</span>
            </div>
          </div>
        )}
      </div>

      {teamInfoPayload?.email_notification_enabled && (
        <EmailNotificationOn
          teamActiveEmailNotificationsData={teamActiveEmailNotificationsData}
          selectedTeam={selectedTeam}
          refetchMailNotifs={refetchMailNotifs}
        />
      )}
    </div>
  );
};

export default Notification;
