export const maskString = (str: string, maskCharacter: string) => {
    let maskedString = ''
    if (str.length > 4) {
      for (let i = 0; i < str.length; i += 5) {
        const chunk = str.slice(i, i + 5)
        if (chunk.length === 5) {
          maskedString += chunk.slice(0, 2) + maskCharacter.repeat(3)
        } else {
          maskedString += chunk
        }
      }
    } else if (str.length <= 4 && str.length > 1) {
      maskedString = maskCharacter.repeat(str.length - 1) + str.slice(str.length - 1, str.length)
    } else {
      maskedString = maskCharacter
    }
    return maskedString
  }
  
  export const maskEmail = (email: string, maskCharacter: string = '*') => {
    const [username, domain] = email.split('@')
  
    if (!domain) {
      return maskString(username, maskCharacter)
    }
  
    const [domainName, ...topLevelDomain] = domain.split('.')
  
    const maskedUsername = maskString(username, maskCharacter)
  
    const maskedDomainName = maskString(domainName, maskCharacter)
  
    const maskedDomain =
      topLevelDomain.length > 0 ? `${maskedDomainName}.${topLevelDomain.join('.')}` : maskedDomainName
  
    const maskedEmail = `${maskedUsername}@${maskedDomain}`
  
    return maskedEmail
  }
  
  export const maskPhoneNum = (num: string, maskCharacter: string = '*') => {
    const VISIBLE_NUM_LENGTH_FROM_END = 4
  
    const visibleNumLength = Math.min(VISIBLE_NUM_LENGTH_FROM_END, num?.length)
  
    const maskedNum =
      maskCharacter.repeat(num?.length - visibleNumLength) +
      num?.slice(num?.length - visibleNumLength, num?.length)
  
    return maskedNum
  }
  