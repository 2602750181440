import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import ChatReportList from '../../../components/reports/chats/ChatReportList'

const ChatRoute = () => {
    return (
        <Routes>
            <Route
                path='/reports-list'
                element={
                    <>
                        <ChatReportList />
                    </>
                }
            />

            <Route index element={<Navigate to='/chat/reports-list' />} />

        </Routes >
    )
}

export default ChatRoute
