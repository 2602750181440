import React from 'react';
import { Routes, Route, Navigate, Link } from 'react-router-dom';
import ClientLogin from './ClientLogin';
import ViewTicket from './ViewTicket';

const Index = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<ClientLogin />} />
                <Route path= "/:uuid"  element={<ViewTicket/>}/>
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </>
    );
};

export default Index;
