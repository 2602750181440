import React, { createRef, useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import TasksActionMenu from "../common/tabbedPanel/TasksActionMenu";
import {
  Box,
  CardContent,
  Checkbox,
  Pagination,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
} from "@mui/material";
import { capitalizeFirstLetter } from "../../components/reusableComponent/CapitalLetter";
import { formatDate } from "../../components/reusableComponent/DateFormat";
import LoadingSpinner from "../../components/LoadingSpinner";
import EmptyRows from "../../components/reusableComponent/EmptyRows";
import { maskEmail, maskPhoneNumber } from "../reusableComponent/MaskedFields";
import { useWhatsAppConfigMutation } from "../../Services/campign/campignList";
interface IProps {
  listOfLeads: any;
  taskRes: any;
  CampaignWorkflow: any;
  setStoreKey: any;
  setValue: any;
  value: any;
  currentPage,
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  useData: any;
  campign: any;
  setSelectedTickets,
  selectedTickets: any;
  selectedOptions: any
  checkTenant:any
}

const CampaignListView: React.FC<IProps> = ({ listOfLeads, checkTenant,taskRes, CampaignWorkflow, setStoreKey,
  setValue, value, currentPage, setCurrentPage, campign, setSelectedTickets, selectedTickets, selectedOptions }) => {
  const taskResActionMenuRef = useRef<any[]>([]);
  taskResActionMenuRef.current = taskRes.map(
    (item: any, i: number) => taskResActionMenuRef.current[i] ?? createRef()

  );
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(5);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [selectAll, setSelectAll] = useState(false);
  const [uiData, setUiData] = useState([]);
  const [keyState, setKeyState] = useState([]);

  const [whatsAppConfigTrigger] = useWhatsAppConfigMutation()

  const checkUserRole = localStorage.getItem('user_role')

  console.log(checkUserRole, "dcscdsccd")

  const navigate = useNavigate();
  const rows =
    campign?.results?.map((campign: any) => ({
      id: campign?.id,
      first_name: campign?.first_name,
      // created: format(new Date(ticket?.created), "yyyy-MM-dd HH:mm"),
      last_name: campign?.last_name,
      account_name: campign?.account_name,
      email: campign?.email,
      phone_number: campign?.phone_number,
      category: campign?.category,
      dynamic_fields: campign?.dynamic_fields,
      assignee: campign?.assignee?.username,
      outstanding_amount: campign?.outstanding_amount


    })) || [];
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const handleSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const newSelectedTickets = event.target.checked
      ? [...selectedTickets, id]
      : selectedTickets.filter((ticketId) => ticketId !== id);

    setSelectedTickets(newSelectedTickets);

    // Uncheck "Select All" if any checkbox is unchecked
    if (!event.target.checked) {
      setSelectAll(false);
    }
  };
  const generatePaginationNumbers = () => {
    const numbers = [];
    const maxVisibleNumbers = 5;

    if (totalPages <= maxVisibleNumbers) {
      for (let i = 1; i <= totalPages; i++) {
        numbers.push(i);
      }
    } else {
      const middleIndex = Math.ceil(maxVisibleNumbers / 2);
      let startPage = currentPage - middleIndex + 1;
      let endPage = currentPage + middleIndex - 1;

      if (startPage < 1) {
        endPage = startPage + maxVisibleNumbers - 1;
        startPage = 1;
      } else if (endPage > totalPages) {
        startPage = endPage - maxVisibleNumbers + 1;
        endPage = totalPages;
      }

      for (let i = startPage; i <= endPage; i++) {
        numbers.push(i);
      }
    }

    return numbers;
  };
  const processData = async () => {
    const uiFormattedData = [];
    const keysForState = [];

    // const [selectedTab, setSelectedTab] = useState<number>(0);
    if (CampaignWorkflow && typeof CampaignWorkflow === "object") {
      await Object.entries(CampaignWorkflow)?.forEach(([key, value]) => {
        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          // Handle objects like { Open: 8 }
          Object.entries(value).forEach(([nestedKey, nestedValue]) => {
            uiFormattedData.push({
              key: nestedKey,
              value: nestedValue,
            });
            keysForState.push(key);
          });
        } else if (Array.isArray(value)) {
          // Handle arrays of objects [{ Closed: 1 }, { ss: 0 }]
          value.forEach((item) => {
            if (item && typeof item === "object") {
              Object.entries(item).forEach(([nestedKey, nestedValue]) => {
                uiFormattedData.push({
                  key: nestedKey,
                  value: nestedValue,
                });
                keysForState.push(key);
              });
            }
          });
        }
      });

      setUiData(uiFormattedData);
      setKeyState(keysForState);
    } else {
      console.error("Camapign is undefined or null ");
    }

  };
  useEffect(() => {
    processData();
  }, [CampaignWorkflow]);

  const truncateDescription = (description, maxLength) => {
    if (description?.length > maxLength) {
      return description?.slice(0, maxLength) + "...";
    }
    return description;
  };
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setTotalPages(Math.ceil(campign?.count / pageSize));
    }, 3000);
  }, [currentPage, campign, pageSize]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedTickets(campign?.results.map((row) => row.id));
    } else {
      setSelectedTickets([]);
    }
  };
  useEffect(() => {
    // Check if all rows are selected to update the "Select All" checkbox
    if (rows.length > 0 && selectedTickets.length === rows.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedTickets, rows]);


  return (
    <div className="card-body pt-0">
      <div
        className="nav nav-stretch nav-line-tabs text-primary border-transparent d-flex mb-5"
        // style={{ padding: "0 2.25rem", display: "flex", }}
        style={{ marginLeft: "-23px" }}
      >
        <Box
          sx={{
            width: "100%",
            background: "#FFFFFF",
            borderRadius: "6px",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)", // subtle shadow for better elevation
            marginLeft: "25px"
          }}
        >
          <Tabs
            value={uiData.findIndex((item) => item.key === value)}
            onChange={(event, newValue) => {
              setStoreKey(keyState[newValue]);
              setValue(uiData[newValue].key);
              setCurrentPage(1);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            sx={{
              "& .MuiTab-root": {
                minWidth: 0, // Set minimum width to 0
                flexGrow: 1, // Add flexGrow to make tabs responsive
                padding: "12px 24px",
                textTransform: "none",
                fontWeight: 500,
                fontSize: "12px",
              },
              "& .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabScrollButton-horizontal.Mui-disabled.MuiTabs-scrollButtons.MuiTabs-scrollButtonsHideMobile.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root":
              {
                display: "none",
              },
              "& .MuiTab-root:not(:last-child)": {
                borderRight: "1px solid #e0e0e0", // Thicker, more pronounced divider
              },
              "& .MuiTab-root:hover": {
                backgroundColor: "#f5f5f5", // Adds a hover effect for better interaction
              },
              "& .Mui-selected": {
                fontWeight: 600, // Highlights selected tab more
              },
            }}
          >
            {uiData?.map((ticketsInfo, index) => (
               <Tooltip key={index} title={`${ticketsInfo.key} (${ticketsInfo.value})`} arrow>
              <Tab
                key={index}
                label={truncateDescription(
                  `${ticketsInfo.key} (${ticketsInfo.value})`,
                  25
                )}
                sx={{ 
                  width: "20%", // Ensure all tabs take full width 
                 maxWidth: "none", // Remove max width restriction 
                 display: "flex", // Ensure flexibility 
                justifyContent: "center", // Center the text
                 }}
              />
              </Tooltip>
            ))}
          </Tabs>
        </Box>
      </div>

      

      <TableContainer component={Paper} style={{cursor:"pointer"}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                  inputProps={{ "aria-label": "select all" }}
                />
              </TableCell>

              <TableCell
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Account name
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                First name
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Last name
              </TableCell>

              <TableCell
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Email
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Mobile no.
              </TableCell>
              {selectedOptions.length > 0
                ? selectedOptions.map((option: any) => (
                  <TableCell
                    key={option.id}
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    {option.name}
                  </TableCell>
                ))
                : null}
              {/* <TableCell
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Action
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={10} style={{ textAlign: "center" }}>
                  <LoadingSpinner />
                </TableCell>
              </TableRow>
            ) : rows?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={10}>
                  <EmptyRows value={""} />
                </TableCell>
              </TableRow>
            ) : (
              rows?.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => navigate(`/campaign/lead/${row.id}`)}
                >
                  <TableCell
                    padding="checkbox"
                    onClick={(event) => event.stopPropagation()}
                  >
                    <Checkbox
                      checked={selectedTickets.includes(row.id)}
                      onChange={(event) => handleSelect(event, row.id)}
                      inputProps={{ "aria-label": "select" }}
                    />
                  </TableCell>

                  <TableCell>
                    {capitalizeFirstLetter(row?.account_name)}
                  </TableCell>
                  <TableCell>{capitalizeFirstLetter(row?.first_name)}</TableCell>
                  <TableCell>{capitalizeFirstLetter(row?.last_name)}</TableCell>
                  {/* <TableCell>
                    {capitalizeFirstLetter(row.account_name)}
                  </TableCell> */}
                  <TableCell>{checkUserRole === "agent" ? maskEmail(row?.email) : capitalizeFirstLetter(row?.email)}</TableCell>
                  <TableCell>{checkUserRole === "agent" ? maskPhoneNumber(row?.phone_number) : row?.phone_number}</TableCell>
                  {/* <TableCell>
                    {capitalizeFirstLetter(row?.account_name)}
                  </TableCell> */}

                  {selectedOptions.map((option) => {
                    // Dynamically render table cells based on selected headers
                    const key = option.name;
                    return (
                      <TableCell key={key}>
                        {key === "Lead Id" && row?.id}
                        {/* {key === "Outstanding Amount" && (row?.outstanding_amount ?? 0)} */}
                        {key === "Assignee" && (row?.assignee ?? "Unassigned")}
                        {/* Default case for dynamic fields */}
                        {!["Lead Id", "Outstanding Amount", "Assignee"].includes(key) && row?.dynamic_fields[key]}
                      </TableCell>
                    );
                  })}




                </TableRow>
              ))
            )}
        
          </TableBody>
        </Table>
      </TableContainer>
      {/* <div style={{display: "flex", justifyContent: "end"}}>
                                                                            {campign.broadcast_msg ===
                                                                            true ? (
                                                                                <i
                                                                                    className="bi bi-check-circle-fill text-success"
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "1.2rem",
                                                                                    }}
                                                                                    title="Message Sent Successfully"
                                                                                ></i>
                                                                            ) : campign.broadcast_msg ===
                                                                              "false" ? (
                                                                                <i
                                                                                    className="bi bi-x-circle-fill text-danger"
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "1.2rem",
                                                                                    }}
                                                                                    title="Message Sending Failed"
                                                                                ></i>
                                                                            ) : (
                                                                                <i
                                                                                    className="bi bi-dash-circle-fill text-muted"
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "1.2rem",
                                                                                    }}
                                                                                    title="Message Not Sent"
                                                                                ></i>
                                                                            )}
                                                                        </div> */}
      {!loading && (
        <div className="row mx-4 pt-2 mb-2 mt-3">
          <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
            <span>
              Page {currentPage} of {totalPages}
            </span>
          </div>
          <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="kt_table_users_paginate"
            >
              <ul className="pagination">
                <li
                  className={`paginate_button page-item ${currentPage === 1 ? "disabled" : ""
                    }`}
                >
                  <button
                    type="button"
                    className="btn primary page-link"
                    onClick={prevPage}
                  >
                    <i className="previous"></i>
                  </button>
                </li>

                {generatePaginationNumbers().map((pageNumber) => (
                  <li
                    key={pageNumber}
                    className={`paginate_button page-item ${currentPage === pageNumber ? "active" : ""
                      }`}
                  >
                    <button
                      type="button"
                      className="btn primary page-link"
                      onClick={() => paginate(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  </li>
                ))}

                <li
                  className={`paginate_button page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <button
                    type="button"
                    className="btn primary page-link"
                    onClick={nextPage}
                  >
                    <i className="next"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignListView;