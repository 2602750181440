import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const accountLogin = createApi({
  reducerPath: "accountLogin",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    accountData: builder.query({
      query: () => ({
        url: "v1/customers/account_login/",
        method: "GET",
      }),
    }),

    registerData: builder.mutation({
      query: (body) => ({
        url: "v1/customers/account_login/",
        method: "POST",
        body,
      }),
    }),

    clientEditInfo: builder.query({
      query: () => ({
        url: "v1/customers/accounts/",
        method: "GET",
      }),
    }),

    editClientInfo: builder.mutation({
      query: ({ body, id }) => ({
        url: `v1/customers/account_login/${id}/`,
        method: "PUT",
        body,
      }),
    }),

    deleteClientInfo: builder.mutation({
      query: (id) => {
        return {
          url: `v1/customers/account_login/${id?.id}/`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useAccountDataQuery,
  useRegisterDataMutation,
  useClientEditInfoQuery,
  useEditClientInfoMutation,
  useDeleteClientInfoMutation,
} = accountLogin;
