import { CloseButton, FormLabel } from "react-bootstrap";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Loader } from "react-bootstrap-typeahead";
import {
  useCampaignTeamsQuery,
  useAllCampaignDataQuery,
  useUpdateCampaignConfigurationMutation,
  useCampaignTeamsDataQuery,
} from "../../../../Services/campaign/CampaignMapping";
import { useUsersByTeamIdQuery } from "../../../../Services/ticket/users/UsersByTeamId";

interface IProps {
  EditCampaign: any;
  closeEditModal: () => void;
  isLoading: any;
  refetch: any;
}

const EditCampaignRoutingModal = ({
  EditCampaign,
  closeEditModal,
  isLoading,
  refetch,
}: IProps) => {
  const [campaignData, setCampaignData] = useState([]);
  const [theUser, setTheUser] = useState([]);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<number | string>(""); // Changed to number or string
  const [theTeams, setTheTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState<any>();
  const [selectedCampaign, setSelectedCampaign] = useState<any>("");

  // Separate radio button states
  const [isRoundRobin, setIsRoundRobin] = useState(false);
  const [isFIFO, setIsFIFO] = useState(false);
  const [isManualRouting, setIsManualRouting] = useState(false);
  const [isDefaultRouting, setIsDefaultRouting] = useState(false);

  const { data: teamsData } = useCampaignTeamsQuery({});
  const { data: campaignTeamsData } = useCampaignTeamsDataQuery(selectedTeam, {
    skip: !selectedTeam,
  });

  const { data: usersData, status: usersStatus } = useUsersByTeamIdQuery(
    selectedTeam,
    {
      skip: !selectedTeam,
    }
  );

  const [triggerUpdate] = useUpdateCampaignConfigurationMutation();
  console.log(EditCampaign.campaign?.id, "Campaign");

  console.log(selectedCampaign, "selectedCampaign");

  // Set campaign data
  useEffect(() => {
    if (campaignTeamsData?.results) {
      setCampaignData(campaignTeamsData.results);
    }
  }, [campaignTeamsData]);

  // Set team data
  useEffect(() => {
    if (teamsData) {
      setTheTeams(teamsData);
    }
  }, [teamsData]);

  // Initialize values from EditCampaign
  useEffect(() => {
    if (EditCampaign) {
      setSelectedTeam(EditCampaign.campaign?.team?.id);
      setSelectedCampaign(EditCampaign.campaign?.id);

      // Initialize radio button states
      setIsRoundRobin(EditCampaign.round_robin || false);
      setIsFIFO(EditCampaign.FIFO || false);
      setIsManualRouting(EditCampaign.manual_routing || false);
      setIsDefaultRouting(EditCampaign.default_routing || false);

      if (EditCampaign.manual_routing && EditCampaign.user) {
        setSelectedUser(EditCampaign.user.id);
      }
    }
  }, [EditCampaign]);

  // Set users when team is selected
  useEffect(() => {
    if (usersData) {
      setTheUser(usersData);
    }
    setIsUserLoading(usersStatus === "pending");
  }, [usersData, usersStatus]);

  // Handlers for dropdown changes
  const handleTeamDropdown = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTeam(parseInt(e.target.value, 10));
    setSelectedUser(""); // Reset selected user when changing team
  };

  const handleWorkflowChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCampaign(e.target.value);
  };

  // Separate handlers for radio button changes
  const handleRoundRobinChange = () => {
    setIsRoundRobin(true);
    setIsFIFO(false);
    setIsManualRouting(false);
    setIsDefaultRouting(false);
  };

  const handleFIFOChange = () => {
    setIsRoundRobin(false);
    setIsFIFO(true);
    setIsManualRouting(false);
    setIsDefaultRouting(false);
  };

  const handleManualRoutingChange = () => {
    setIsRoundRobin(false);
    setIsFIFO(false);
    setIsManualRouting(true);
    setIsDefaultRouting(false);
  };

  const handleDefaultRoutingChange = () => {
    setIsRoundRobin(false);
    setIsFIFO(false);
    setIsManualRouting(false);
    setIsDefaultRouting(true);
  };

  // Submit handler
  const handleSubmit = async () => {
    const obj = {
      FIFO: isFIFO,
      campaign: selectedCampaign,
      default_routing: isDefaultRouting,
      manual_routing: isManualRouting,
      round_robin: isRoundRobin,
      user: isManualRouting ? selectedUser : null,
    };

    try {
      const response = await triggerUpdate({ body: obj, id: EditCampaign?.id });

      console.log("response", obj);

      if (response?.data) {
        console.log("Campaign routing updated successfully");
        await refetch();
        closeEditModal();
      } else {
        console.error(
          "Failed to update campaign routing:",
          response?.data?.message
        );
      }
    } catch (error: any) {
      console.error(
        "Error updating campaign routing:",
        error?.message || error
      );
      alert(`Failed to update: ${error?.message || "Unknown error"}`);
    }
  };

  return (
    <>
      <div className="text-end">
        <CloseButton onClick={closeEditModal} />
      </div>
      <div className="text-center mb-4">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Edit Campaign
        </label>
      </div>

      {/* Team and Workflow Selection */}
      <div style={{ display: "flex", gap: "30px", flexDirection: "column" }}>
        <select
          className="form-select"
          onChange={handleTeamDropdown}
          value={selectedTeam || ""}
        >
          <option value="" disabled>
            Select Team
          </option>
          {theTeams.map((team) => (
            <option key={team.id} value={team.id}>
              {team.name}
            </option>
          ))}
        </select>

        <select
          className="form-select"
          onChange={handleWorkflowChange}
          value={selectedCampaign}
        >
          <option value="" disabled>
            Select a Workflow
          </option>
          {campaignData.map((campaign) => (
            <option key={campaign.id} value={campaign.id}>
              {campaign.name}
            </option>
          ))}
        </select>
      </div>

      {/* Radio Button Group */}
      <div className="mt-5">
        <FormControl>
          <RadioGroup>
            <FormControlLabel
              value="round_robin"
              control={
                <Radio
                  checked={isRoundRobin}
                  onChange={handleRoundRobinChange}
                />
              }
              label="Round Robin"
            />
            <FormControlLabel
              value="default_routing"
              control={
                <Radio
                  checked={isDefaultRouting}
                  onChange={handleDefaultRoutingChange}
                />
              }
              label="Team Assignment"
            />
            <FormControlLabel
              value="FIFO"
              control={<Radio checked={isFIFO} onChange={handleFIFOChange} />}
              label="Active Logins (RR)"
            />
            <FormControlLabel
              value="manual_routing"
              control={
                <Radio
                  checked={isManualRouting}
                  onChange={handleManualRoutingChange}
                />
              }
              label="Individual Assignment"
            />
          </RadioGroup>
        </FormControl>
      </div>

      {/* Manual Routing User Selection */}
      {isManualRouting && (
        <div className="row mt-3">
          <div className="col">
            <label className="form-label mx-2">User</label>
            {isUserLoading ? (
              <Loader />
            ) : (
              <select
                className="form-select"
                value={selectedUser}
                onChange={(e) => setSelectedUser(parseInt(e.target.value, 10))}
              >
                <option value="" disabled>
                  Select User
                </option>
                {theUser.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.username}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
      )}

      {/* Submit Button */}
      <div className="text-end mt-5 mb-4">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Update"}
        </button>
      </div>
    </>
  );
};

export default EditCampaignRoutingModal;
