import React, { useEffect, useState } from "react";
import {
  useContactHistoryQuery,
  useOldestcontactHistoryQuery,
} from "../../../Services/customer/FrontendCustomer";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { formatDate } from "../../reusableComponent/DateFormat";
import EmptyRows from "../../reusableComponent/EmptyRows";
import { useParams } from "react-router-dom";

interface IPROPS {
}

const CustomerHistory:React.FC<IPROPS> = () => {
  const { id } = useParams();


  const [historyData, setHistoryData] = useState([]);
  const [detailsHistory, setDetailsHistory] = useState<boolean>(false);
  const [storedData, setStoredData] = useState<any>({});
  const [category, setCategory] = useState("newest");

  const { data: contactHistoryData, isLoading: isLoadingNewest } =
    useContactHistoryQuery(id, { skip: category !== "newest" });
  const { data: oldestContactHistoryData, isLoading: isLoadingOldest } =
    useOldestcontactHistoryQuery(undefined, {
      skip: category !== "oldest",
    });

  const isLoading = category === "oldest" ? isLoadingOldest : isLoadingNewest;

  useEffect(() => {
    if (category === "oldest" && oldestContactHistoryData) {
      setHistoryData(oldestContactHistoryData);
    } else if (category !== "oldest" && contactHistoryData) {
      setHistoryData(contactHistoryData);
    }
  }, [category, contactHistoryData, oldestContactHistoryData]);

  const gettingUserId = (details) => {
    setDetailsHistory(true);
    setStoredData(details);
  };

  const categoryChangeHandler = (e) => {
    setCategory(e.target.value);
  };

  return (
    <>
      {historyData ? (
        <div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              gap: "10px",
            }}
          >
            <span>Sort by: </span>
            <span style={{ width: "30%" }}>
              <select
                onChange={categoryChangeHandler}
                value={category}
                className="form form-select"
              >
                <option value="newest">Newest</option>
                <option value="oldest">Oldest</option>
              </select>
            </span>
          </div>
          <div>
            {!detailsHistory ? (
              <div style={{ height: "50vh", overflow: "scroll" }}>
                {isLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead className="bg-secondary">
                        <TableRow>
                          <TableCell>Channel</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Assignee</TableCell>
                          <TableCell>Customer</TableCell>
                          <TableCell>Priority</TableCell>
                          <TableCell>Ticket Id</TableCell>
                          <TableCell>Created</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {historyData?.map((accountHistory: any) => (
                          <TableRow
                            onClick={() => gettingUserId(accountHistory)}
                            className="card"
                            key={accountHistory?.id}
                          >
                            <TableCell component="th" scope="row">
                              {accountHistory?.action_type === "email" ? (
                                <i className="bi bi-envelope-exclamation-fill"></i>
                              ) : (
                                ""
                              )}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {formatDate(accountHistory?.contact?.created)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {
                                accountHistory?.assignee?.username?.split(
                                  "@"
                                )[0]
                              }
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {accountHistory?.contact?.email}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {accountHistory?.ticket?.priority || "N/A"}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {accountHistory?.ticket?.id}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {formatDate(
                                accountHistory?.ticket?.created || ""
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </div>
            ) : (
              <div style={{ maxHeight: "30%", height: "30%" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    background: "#009ef7",
                    color: "white",
                    padding: "10px 0",
                  }}
                >
                  <p
                    style={{
                      margin: "0",
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      <i
                        onClick={() => setDetailsHistory(false)}
                        className="bi bi-arrow-left"
                        style={{
                          color: "white",
                          fontSize: "20px",
                        }}
                      ></i>
                    </span>
                    <span style={{ fontSize: "17px" }}>Email Title: </span>
                  </p>
                  <span
                    style={{
                      maxWidth: "60%",
                      width: "50%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "17px",
                    }}
                  >
                    {storedData?.thread?.subject}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "40vh",
                    overflowY: "scroll",
                  }}
                >
                  {storedData?.thread?.emails?.map((email) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                      }}
                      key={email?.id}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          flexDirection: "column",
                          marginBottom: "70px",
                        }}
                      >
                        <div
                          className="d-flex justify-content-between bg-light p-2"
                          style={{ gap: "10px" }}
                        >
                          <p className="mb-0">
                            <strong>Test@bluewhirl.com</strong>
                            <br />
                            <span className="text-muted">To: {email.to}</span>
                            {email?.cc?.length > 0 && (
                              <>
                                <br />
                                <span className="text-muted">
                                  cc: {email.cc}
                                </span>
                              </>
                            )}
                            {email?.bcc?.length > 0 && (
                              <>
                                <br />
                                <span className="text-muted">
                                  bcc: {email.bcc}
                                </span>
                              </>
                            )}
                          </p>
                          <p className="mb-0">
                            {formatDate(email?.mail_sent_or_received_on)}
                          </p>
                        </div>
                        <div
                          className="py-3 p-2"
                          dangerouslySetInnerHTML={{
                            __html: email.html_content,
                          }}
                        />
                      </div>
                      {email?.attachments?.length > 0 && (
                        <div>
                          <p>
                            <span
                              style={{
                                fontSize: "15px",
                              }}
                            >
                              Attachments
                            </span>
                          </p>
                          {email?.attachments?.map((file) => (
                            <p
                              key={file?.id}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Click to Download"
                              style={{
                                borderRadius: "20px",
                                background: "#e0e0e0",
                                width: "fit-content",
                                padding: "5px",
                                cursor: "pointer",
                              }}
                            >
                              {file?.file_name}
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <EmptyRows value={""}/>
      )}
    </>
  );
};

export default CustomerHistory;
