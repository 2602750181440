import React from "react";

export const standardModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "500px",
  },
};

export const defaultModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-30%, -60%)",
  },
};

export const defaultDeleteDialogModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%", // Default to full width
    maxWidth: "600px", // Max width for larger screens
    margin: "auto",
    Overflow: "hidden",
    OverflowY: "auto",
  },
};

export const defaultScrollableModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -46%)",
    width: "100%", // Default to full width
    maxWidth: "600px", // Max width for larger screens
    // height: '80%',
    margin: "auto",
    Overflow: "hidden",
    OverflowY: "auto",
    // background:'#f1faff'
    background: "#ffffff",
  },
};

export const defaultAddEscalationLevelModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    margin: "auto",
    Overflow: "hidden",
    OverflowY: "auto",
  },
};

export const defaultCompactFormModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    maxWidth: "850px", // Max width for larger screens
    height: "70%",
    margin: "auto",
    Overflow: "hidden",
    OverflowY: "auto",
    background: "#ffffff",
  },
};
